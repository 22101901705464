import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from "./constant";

@Injectable({
    providedIn: 'root'
})
export class ModuleListService {
    constructor(private http: HttpClient) { }
    getModules() {
        var data = {"where":{"parent":"0","status":"1"}};
        return this.http.get(API.MENU_LIST_BY_FILTER + '?filter=' + JSON.stringify(data));
        // return this.http.get(API.GET_PARENT_MODULES + '?filter[where][parent]=0');
    }
    getSubscirbedModulesByName(moduleNames) {
        var data = {"moduleNames":moduleNames}
        return this.http.get(API.MODULE_LIST_BY_NAME + '?filter=' + JSON.stringify(data));
    }
}
