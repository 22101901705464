
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-class">Lead Source Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create new lead source into `tbl_lead_source` by leadSourceName. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_add"><u>{{path}}explorer/#!/LeadSource/LeadSource_add</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p> 
  <ul>
      <li>
            This API will delete the lead source from `tbl_lead_source` by leadSourceId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_delete"><u>{{path}}explorer/#!/LeadSource/LeadSource_delete</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>getDashboardLeadsSource</code></i></p> 
  <ul>
      <li>
            This API is used to fetch the list of leadSource from `tbl_lead_source` for dashboard module. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_getDashboardLeadsSource"><u>{{path}}explorer/#!/LeadSource/LeadSource_getDashboardLeadsSource</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>List</code></i></p> 
  <ul>
      <li>
            This API will fetch all the lead sources from `tbl_lead_source` for dropdown purpose. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_list"><u>{{path}}explorer/#!/LeadSource/LeadSource_list</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>listById</code></i></p> 
  <ul>
      <li>
            This API will fetch the details of particular lead source from `tbl_lead_source` by leadSourceId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_listById"><u>{{path}}explorer/#!/LeadSource/LeadSource_listById</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
      <li>
            This API is used to modify the lead source name by leadSourceId if exists. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadSource/LeadSource_modify"><u>{{path}}explorer/#!/LeadSource/LeadSource_modify</u></a> 

      </li>
  </ul>
  </div>
</mat-card>
