import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  AfterViewInit, OnInit, ElementRef,
  inject
} from '@angular/core';
import {NavService} from '../../../service/nav-service.service';
import {ConfigService} from '../../../service/config.service';
import { Subscription, Subject } from 'rxjs';
import {SettingsService} from '../../../service/settings.service';
import { CurrentUserService } from '../../softphone/shared/services/current-user.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { SoftphoneComponent } from '../../softphone/softphone/softphone.component';
import { TaskTimerComponent } from '../../shared/task-timer/task-timer.component';
import { NgIf, TitleCasePipe } from '@angular/common';
import { SpinnerComponent } from '../../shared/spinner.component';
import { ScrollTopButtonComponent } from '../../shared/scroll-top-button/scroll-top-button.component';
import { FooterComponent } from './footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { AppHeaderComponent } from './header/header.component';
import { MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { MatSidenavContainer, MatSidenav, MatSidenavContent } from '@angular/material/sidenav';

/** @title Responsive sidenav */
@Component({
    standalone: true,
    selector: 'app-full-layout',
    templateUrl: 'full.component.html',
    imports: [MatSidenavContainer, MatSidenav, AppSidebarComponent, MatSidenavContent, MatToolbar, MatIconButton, MatIcon, MatLabel, AppHeaderComponent, RouterOutlet, FooterComponent, ScrollTopButtonComponent, SpinnerComponent, NgIf, TaskTimerComponent, SoftphoneComponent, TitleCasePipe]
})
export class FullComponent implements OnDestroy, AfterViewInit, OnInit {
  public toastr: ToastrService = inject(ToastrService);
  mobileQuery: MediaQueryList;
  @ViewChild('snav') appDrawer: ElementRef;
  userId = localStorage.getItem('userId');

  private _mobileQueryListener: () => void;
  public companyName: any ;
  private subscription: Subscription;
  private pbxSubscription: Subscription[] = [];
  private isUserLoggedSubscription: Subscription;
  public configServiceSetting: ConfigService;
  public isUserLoggedIn = false;
  public isScrollTopButtonShow = false;
  public showSoftphone = false;
  private destroy$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public configService: ConfigService,
    private navService: NavService,
    private settingService: SettingsService,
    private currentUserService: CurrentUserService
  ) {
    this.configServiceSetting = this.configService;
    this.isUserLoggedIn = this.configService.isUserLogged;
    this.initilaStup();
    this.showSoftphone = this.configService.displayWebphone === 1;
    this.isUserLoggedSubscription = this.configService.updateIsUserLogged.subscribe((isUserLogged: boolean) => {
      this.isUserLoggedIn = isUserLogged;
    });
    this.isUserLoggedIn = this.configService.isUserLogged;
    this.subscription = this.settingService.websiteUpdateEventListener.subscribe((val: string) => {
      if (val !== '') {
        this.companyName = val;
        this.initilaStup();
        this.settingService.websiteUpdateEventListener.next('');
      }
    });
    this.currentUserService.displayWebphone$
    .pipe(takeUntil(this.destroy$))
    .subscribe((webphoneStatus) => {
      this.showSoftphone = webphoneStatus === 1;
    });
  }
  ngOnInit() {
    this.configService.getAllConfigDetails();
    if (this.configService.logoOrIcon !== '' && this.configService.logoOrIcon != null && this.configService.logoOrIcon !== undefined) {
      if (this.configService.logoOrIcon === 'Logo' || this.configService.logoOrIcon === 'logo') {
        this.companyName = '';
      } else {
        if (this.configService.websiteName) {
          this.companyName = this.configService.websiteName;
        } else {
          this.companyName = this.configService.companyName;
        }
      }
    } else {
      if (this.configService.websiteName) {
        this.companyName = this.configService.websiteName;
      } else {
        this.companyName = this.configService.companyName;
      }
    }
  }
  initilaStup(): void {
    this.mobileQuery = this.media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => {
      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscription.unsubscribe();
    if (this.pbxSubscription.length > 0) {
      this.pbxSubscription.forEach((e) => e.unsubscribe());
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  moveToTop() {
    const target = document.getElementById('pageBodyContent');
    target.scrollIntoView();
  }

  onScroll(event) {
    this.isScrollTopButtonShow = event.target.scrollTop > 100;
  }
}
