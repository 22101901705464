
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Deadline Model</h2>

  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create a new deadline for particular opportunity into `tbl_deadline`. 
            addDeadline method is used here
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_add"><u>{{path}}explorer/#!/Deadline/Deadline_add</u></a> 
      </li>
  </ul>
  <br />
  <p><i><code>Delete</code></i></p> 
  <ul>
      <li>
            This API will delete the deadline from `tbl_deadline` by deadlineId. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_delete"><u>{{path}}explorer/#!/Deadline/Deadline_delete</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>getOverdueDeadlinesList</code></i></p> 
  <ul>
      <li>
            This API is used to get the overdue deadline list for dashboard module if the below condition satisfies. 
            tbl_deadline.mark_as_done = 0 and tbl_deadline.deadline_date AS DATE &lt; CURDATE() 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_getOverdueDeadlinesList"><u>{{path}}explorer/#!/Deadline/Deadline_getOverdueDeadlinesList</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>List</code></i></p>
  <ul>
      <li>
            This API will fetch the deadline list from `tbl_deadline` by opportunityId. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_list"><u>{{path}}explorer/#!/Deadline/Deadline_list</u></a> 
      </li>
  </ul> 
 <br />  
  <p><i><code>listById</code></i></p> 
  <ul>
      <li>
            This API is used to get the details of particular deadline by deadlineId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_listById"><u>{{path}}explorer/#!/Deadline/Deadline_listById</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
      <li>
            This API is used to modify the details of particular deadline into `tbl_deadline` by deadlineId. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Deadline/Deadline_modify"><u>{{path}}explorer/#!/Deadline/Deadline_modify</u></a> 
      </li>
  </ul>

</div>
</mat-card>
