
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Opportunities State Reason Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create new opportunity stage into `tbl_opportunities_state_reason` by <strong>oppPipeId</strong> and <strong>opportunityStageName</strong>. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_add"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_add</u></a> 

      </li>
  </ul>
   <br />
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API will delete the opportunity stage from `tbl_opportunities_state_reason` by <strong>OpportunityStageId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_delete"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_delete</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the list of opportunity stages from `tbl_opportunities_state_reason` by <strong>opportunityPipeId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_list"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_list</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listById</code></i></p> 
   <ul>
        <li>
                This API is used to get the details of particular opportunity stage from `tbl_opportunities_state_reason` by <strong>OpportunityStageId</strong>. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById</u></a> 

        </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the details of opportunity stage into `tbl_opportunities_state_reason` by <strong>OpportunityStageId</strong> If exists. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_modify"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_modify</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>orderPipelineStages</code></i></p> 
   <ul>
       <li>
            This API is used to change the order of opportunity pipeline stages into `tbl_opportunities_state_reason` by <strong>opportunityPipeId</strong> and <strong>stageList[&ldquo;stage1&rdquo;,&rdquo;stage2&rdquo;,&rdquo;stage3&rdquo;,..]</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_orderPipelineStages"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_orderPipelineStages</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>stagePipeDDList</code></i></p> 
   <ul>
       <li>
            This API is used to fetch all the list of opportunity pipeline stages from `tbl_opportunities_state_reason` by <strong>opportunityPipeId</strong> for dropdown purpose. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_stagePipeDDList"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_stagePipeDDList</u></a> 

       </li>
   </ul>
   </div>
</mat-card>
