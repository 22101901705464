
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
   </div>
   
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Users Model</h2>
 <p><i><code> Ban</code></i></p> 
 <ul>
     <li>
            This API is used to ban/unban the user into `tbl_users` by <strong>Ban/unban reason</strong>, <strong>Ban/unban user (1:Ban 0:Unban)</strong> and <strong>user id</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Users/Users_ban"><u>{{path}}explorer/#!/Users/Users_ban</u></a> 

     </li>
 </ul>
 <br />     
     <p><i><code> changeUserPassword</code></i></p> 
     <ul>
         <li>
                This API is used to change the password of the user into `tbl_users`. 

         </li>
         <li>
                Before the password into database, it will be encrypted using hashPassword(refer commonHelper.js) method. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_changeUserPassword"><u>{{path}}explorer/#!/Users/Users_changeUserPassword</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> contactEmailExists</code></i></p> 
     <ul>
         <li>
                This API is used to check whether email is exists or not for client and vendor from `tbl_users`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_contactEmailExists"><u>{{path}}explorer/#!/Users/Users_contactEmailExists</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the user from `tbl_users` by giving userId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_delete"><u>{{path}}explorer/#!/Users/Users_delete</u></a> 

         </li>
     </ul>
     <br />
 <p><i><code> getUserActivityData</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the statistics count of users such as calls, activeTasksList, wonSum,lostSum,newSum, wonDeals, lostDeals, compLunch, addedLunch, Deadlines, emails, profile, winRatio  etc.by <strong>userId</strong> and <strong>filter type as </strong>(<strong>all, thisWeek</strong>). 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Users/Users_getUserActivityData"><u>{{path}}explorer/#!/Users/Users_getUserActivityData</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code> getUserAllDetails</code></i></p>
     <ul>
         <li>
                This API will fetch the details of logged in user from by <strong>userId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_getUserAllDetails"><u>{{path}}explorer/#!/Users/Users_getUserAllDetails</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code> getUserStatistics</code></i></p> 
     <ul>
         <li>
                This API is used to fetch particular statistics details of the user by <strong>Type of Statistics, </strong> <strong>userId</strong> and <strong>filterType</strong>. 

         </li>
         <li>
                oppStatisticFilterDeals : this method will fetch the opportunity details of won, lost and new deals with filter option. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_getUserStatistics"><u>{{path}}explorer/#!/Users/Users_getUserStatistics</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API will fetch all the users list from `tbl_users` based on the permission. 

         </li>
         <li>
                If logged in user is owner(isUserOwner method called), it will fetch all the users list. Otherwise list will be fetched based on the departments. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_list"><u>{{path}}explorer/#!/Users/Users_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Login</code></i></p> 
     <ul>
         <li>
                Login API has been created based on the remote hooks in loopback. 
                <ul>
                    <li>
                            Refer hooks here: <a href="https://loopback.io/doc/en/lb3/Remote-hooks.html">https://loopback.io/doc/en/lb3/Remote-hooks.html</a> 

                    </li>
                </ul>
         </li>
         <li>
                beforeRemote(<strong>'login'</strong>): before doing login, email, password(using hasPassword) and domain(if not a <strong>'localhost'</strong>) will be verified. 

         </li>
         <li>
                afterRemote(<strong>'login'</strong>): if the login is successful, steps below will be executed. 
                <ul>
                    <li>
                            User and company details will be fetched from `tbl_users`, `tbl_account_details` and `tbl_tenant_companies`. 

                    </li>
                    <li>
                            userInitialDetails API will be called to get the config details, permissions list, menulink list etc., 

                    </li>
                    <li>
                            Finally, based on the checkExecutiveAdmin(return true if user is executive_admin, else false) method user details will be fetched and session will be created for that user in `tbl_session`. 

                    </li>
                    <li>
                            Access token will be generated using JWT(JSON Web Token). 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_login"><u>{{path}}explorer/#!/Users/Users_login</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Logout</code></i></p> 
     <ul>
         <li>
                This API is used to destroy the session by finding the access token of the logged in user in `tbl_session`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_logout_post_Users_logout"><u>{{path}}explorer/#!/Users/Users_logout_post_Users_logout</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Mailbox</code></i></p>
     <ul>
         <li>
                This API is used to update the mailbox settings values into `tbl_config`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_mailbox"><u>{{path}}explorer/#!/Users/Users_mailbox</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code> refreshToken</code></i></p>
     <ul>
         <li>
                This API is used to update the access token into `tbl_session` if it is expired. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_refreshToken"><u>{{path}}explorer/#!/Users/Users_refreshToken</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code> sendMailboxEmail</code></i></p> 
     <ul>
         <li>
                This API is used to check the whether mailbox settings configured id correct or not by sending the email. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_sendMailboxEmail"><u>{{path}}explorer/#!/Users/Users_sendMailboxEmail</u></a> 

         </li>
     </ul>
      <br />
     <p><i><code> updateProfile</code></i></p> 
     <ul>
         <li>
                This API is used to update the user profile details into `tbl_account_details` and `tbl_users`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_updateProfile"><u>{{path}}explorer/#!/Users/Users_updateProfile</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> updateTheme</code></i></p>
     <ul>
         <li>
                This API is used to update the theme settings values into `tbl_users`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Users/Users_updateTheme"><u>{{path}}explorer/#!/Users/Users_updateTheme</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code> userInitialDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the details of the logged in user such as user and company details from `tbl_users`, `tbl_account_details`, menuLink list using getLinkFromDynamicMenu mand resource access list using getUserResourceAccessList method. 

         </li>
         <li>
                getLinkFromDynamicMenu : 
                <ul>
                    <li>
                            It will fetch all the Subscribed Apps/menus of that user by getDynamicMenu(refer commonHelper.js) method. 

                    </li>
                    <li>
                            Once above method executed successfully, menu link will be fetched from getLinkFromMenuList through iteration. 

                    </li>
                </ul>
         </li>
         <li>
                getUserResourceAccessList : return the access rules list of the logged in user based on the permissions(isOwner/not isOwner). 

         </li>
     </ul>
     </div>
</mat-card>
