<div class="testcase-attachments-section">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup (selectedTabChange)="onTabChange($event)">
    <mat-tab label="All Attachments">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let attachment of attachmentListArr; let in = index" [expanded]="indexExpanded  === in"  (opened)="expand(attachment, attachment.taskAttachmentId, in)" hideToggle="true">
          <mat-expansion-panel-header style="max-height: 48px;">
            <mat-panel-title class="attachmentListName"><a style="cursor: pointer">{{attachment.title}}</a></mat-panel-title>
            <div class="_action_btns">
              <a class="btn_edit" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Edit"><i class="far fa-edit edit-bug" (click)="getAttachmentData(attachment.taskAttachmentId)"></i></a>
              <a class="btn_delete" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Delete" (click)="deleteAttachments(attachment)"><i class="fai fa-trash-o text-danger fa-lg " aria-hidden="true"></i></a>
            </div>
          </mat-expansion-panel-header>
          <div class="_attachments_wrap">
            <div class="_attachment_box" *ngFor="let _tfs of attachment.fileattachement; let j = index;">
              <ng-container *ngIf="_tfs.ext === '.jpg' || _tfs.ext === '.jpeg' || _tfs.ext === '.png' || _tfs.ext === 'jpg' || _tfs.ext === 'jpeg' || _tfs.ext === 'png' ">
                <span class="mailbox-attachment-icon has-img clearfix">
                  <img src="{{_tfs.fileUploadedPath ? _tfs.fileUploadedPath : _tfs.uploadedPath}}" (click)="open(_tfs.fileName)" alt="No File Found" class="uploadAttImg img-responsive"/>
                </span>
                <div class="mailbox-attachment-info clearfix">
                  <span class="mailbox-attachment-size clearfix m-t-5">
                      <span>{{_tfs.fileName}}</span>
                    <a (click)="open(_tfs.fileName)" class="btn btn-default btn-xs m-r-5" title="View"><i class=" fa fa-eye text-primary"></i></a>
                    <a href="{{ _tfs.fileUploadedPath ? _tfs.fileUploadedPath : _tfs.uploadedPath}}" class="btn btn-default btn-xs m-r-5" title="Download"><i class="fas fa-cloud-download-alt"></i></a>
                    <a (click)="deleteUploadFile(_tfs.uploadedFilesId, _tfs.files)" *ngIf="fileCount > 1"
                        class="btn btn-default btn-xs "><i class="fai fa-trash-o text-danger "></i></a>
                  </span>
                </div>
              </ng-container>
      
              <ng-container *ngIf="_tfs.ext === '.pdf' || _tfs.ext === 'pdf'">
                <span class="mailbox-attachment-icon has-img clearfix _pdf_icon">
                  <i class="far fa-file-pdf medium-red"></i>
                </span>
                <div class="mailbox-attachment-info clearfix">
                  <span class="mailbox-attachment-size clearfix m-t-5">
                      <span>{{_tfs.fileName}}</span>
                    <a (click)="viewFiles(_tfs.fileUploadedPath, _tfs.fileName)" class="btn btn-default btn-xs m-r-5" title="View" *ngIf="attachment.docsignEncId==null ">
                      <i class=" fa fa-eye text-primary"></i></a>
                      <a (click)="viewUtilikoDocsign(attachment.docsignEncId)" class="btn btn-default btn-xs m-r-5" title="View" *ngIf="attachment.docsignEncId!=null ">
                        <i class=" fa fa-eye text-primary"></i></a>
                    <a href="{{ _tfs.fileUploadedPath ? _tfs.fileUploadedPath : _tfs.uploadedPath}}" class="btn btn-default btn-xs m-r-5" title="Download"><i class="fas fa-cloud-download-alt"></i></a>
                    <a (click)="deleteUploadFile(_tfs.uploadedFilesId, _tfs.files)" *ngIf="fileCount > 1"
                        class="btn btn-default btn-xs "><i class="fai fa-trash-o text-danger "></i></a>
                  </span>
                </div>
              </ng-container>
      
              <ng-container *ngIf="_tfs.ext === '.doc' || _tfs.ext === '.docx' || _tfs.ext === 'doc' || _tfs.ext === 'docx'">
                <span class="mailbox-attachment-icon has-img clearfix">
                  <i class="word-icon dark-blue"></i>
                </span>
                <div class="mailbox-attachment-info clearfix ">
                  <span class="mailbox-attachment-size clearfix m-t-5">
                    <span>{{_tfs.fileName}}</span>
                    <a (click)="viewFiles(_tfs.fileUploadedPath, _tfs.fileName)" class="btn btn-default btn-xs m-r-5" title="View"><i class=" fa fa-eye text-primary"></i></a>
                    <a href="{{ _tfs.fileUploadedPath ? _tfs.fileUploadedPath : _tfs.uploadedPath}}" class="btn btn-default btn-xs  m-r-5" title="Download"><i class="fas fa-cloud-download-alt "></i></a>
                    <a (click)="deleteUploadFile(_tfs.uploadedFilesId, _tfs.files)" *ngIf="fileCount > 1"
                        class="btn btn-default btn-xs "><i class="fai fa-trash-o text-danger "></i></a>
                  </span>
                </div>
              </ng-container>
      
              <ng-container *ngIf="(_tfs.ext !== '.doc') && ( _tfs.ext !== '.docx') && (_tfs.ext !== '.jpg') && (_tfs.ext !== '.jpeg') && (_tfs.ext !== '.png') && (_tfs.ext !== '.pdf') && (_tfs.ext !== 'doc') && ( _tfs.ext !== 'docx') && (_tfs.ext !== 'jpg') && (_tfs.ext !== 'jpeg') && (_tfs.ext !== 'png') && (_tfs.ext !== 'pdf')">
                <span class="mailbox-attachment-icon has-img clearfix">
                  <img src="assets/img/attachment.png" alt="{{_tfs.uploadedPath}}" class="uploadAttImg img-responsive"/>
                </span>
                <div class="mailbox-attachment-info clearfix ">
                  <span class="mailbox-attachment-size clearfix m-t-5">
                    <span>{{_tfs.fileName}}</span>
                    <a (click)="viewFiles(_tfs.fileUploadedPath, _tfs.fileName)" class="btn btn-default btn-xs m-r-5" title="View"><i class=" fa fa-eye text-primary"></i></a>
                    <a  href="{{ _tfs.fileUploadedPath ? _tfs.fileUploadedPath : _tfs.uploadedPath}}" class="btn btn-default btn-xs  m-r-5" title="Download"><i class="fas fa-cloud-download-alt "></i></a>
                    <a (click)="deleteUploadFile(_tfs.uploadedFilesId, _tfs.files)" *ngIf="fileCount > 1"
                      class="btn btn-default btn-xs "><i class="fai fa-trash-o text-danger "></i></a>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>
    <mat-tab [label]="mode == 'edit' ? 'Update Attachment' : 'New Attachment'">
      <div class="attachment">
        <form class="form-material" [formGroup]="attachmentForm">
          <div class="row new-contact-div">
            <div class="col-md-3">
              <span class="field_label">File Information</span>
            </div>
            <div class="col-md-9">
              <div class="form-group custom_width">
                <mat-form-field class="example-full-width" appearance="outline">
                  <input matInput placeholder="E.g Xyz" name="fileTitle" formControlName="title" required>
                  <mat-label>File Title</mat-label>
                </mat-form-field>
              </div>
              <div class="form-group custom_width">
                <mat-form-field class="example-full-width" appearance="outline">
                  <textarea matInput placeholder="Description" name="description" formControlName="description"></textarea>
                  <mat-label>Description</mat-label>
                </mat-form-field>
              </div>
              <div class="form-group custom_file_input">
                  <button name="selectBtn" type="button" mat-raised-button color="primary" (click)="inputFile.click()">Select</button>
                  <input #inputFile #myFileInput class="input-file" type="file" id="my-file" name="files" (change)="onSelectFile($event)" multiple>
              </div>
              <div class="_attachments_wrap" *ngIf="totalCompressedImg.length > 0">
                <div class="_attachment_box" *ngFor="let upFile of totalCompressedImg; index as i">
                  <ng-container *ngIf="upFile?.extension === 'jpg' || upFile?.extension === 'jpeg' || upFile?.extension === 'png'">
                    <span class="mailbox-attachment-icon has-img clearfix">
                      <img [src]="_albums[i].thumb" (click)="showImage(upFile?.name)" alt="No image found"
                        class="uploadAttImg img-responsive" />
                    </span>
                    <div class="mailbox-attachment-info clearfix">
                      <span class="mailbox-attachment-size clearfix m-t-5">
                        <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                        <a (click)="showImage(upFile?.name)" class="btn btn-default btn-xs m-r-5" title="View"><i
                            class=" fa fa-eye text-primary"></i></a>
                        <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                            class="fai fa-trash-o text-danger "></i></a>
                      </span>
                    </div>
                  </ng-container>
  
                  <ng-container *ngIf="upFile?.extension === 'pdf'">
                    <span class="mailbox-attachment-icon has-img clearfix">
                      <img src="assets/img/pdf.png" alt="No File Found" class="uploadAttImg pdfImg img-responsive" />
                    </span>
                    <div class="mailbox-attachment-info clearfix">
                      <span class="mailbox-attachment-size clearfix m-t-5">
                        <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                        <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                            class="fai fa-trash-o text-danger "></i></a>
                      </span>
                    </div>
                  </ng-container>
  
                  <ng-container *ngIf="upFile?.extension === 'doc' || upFile?.extension === 'docx'">
                    <span class="mailbox-attachment-icon has-img clearfix">
                      <img src="assets/img/doc.png" alt="No File Found" class="uploadAttImg docImg img-responsive" />
                    </span>
                    <div class="mailbox-attachment-info clearfix">
                      <span class="mailbox-attachment-size clearfix m-t-5">
                        <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                        <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                            class="fai fa-trash-o text-danger "></i></a>
                      </span>
                    </div>
                  </ng-container>
  
                  <ng-container *ngIf="(upFile?.extension !== 'doc') && (upFile?.extension !== 'docx') && (upFile?.extension !== 'jpg') && (upFile?.extension !== 'jpeg') && (upFile?.extension !== 'png') && (upFile?.extension !== 'pdf')">
                    <span class="mailbox-attachment-icon has-img clearfix">
                      <img src="assets/img/attachment.png" alt="No File Found" class="uploadAttImg img-responsive" />
                    </span>
                    <div class="mailbox-attachment-info clearfix">
                      <span class="mailbox-attachment-size clearfix m-t-5">
                        <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                        <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                            class="fai fa-trash-o text-danger "></i></a>
                      </span>
                    </div>
                  </ng-container>
  
                </div>
              </div>
              <div class="form-group form_btns_actions">
                <button name="submitBtn" *ngIf="mode == 'add'" type='submit' class="upload-btn" mat-raised-button
                color="primary" (click)="onSubmitAttachments('add')">Upload File</button>
                <button name="submitBtn" *ngIf="mode == 'edit'" type='submit' class="upload-btn" mat-raised-button
                  color="primary" (click)="onSubmitAttachments('update')">Upload File</button>
                <button type="reset" class="cancel-btn" mat-raised-button
                (click)="cancel()">Cancel</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
  <ngx-gallery class="custom-dialog" [options]="galleryOptions" [images]="galleryImages" #onlyPreviewGallery></ngx-gallery>
  <ngx-gallery class="custom-dialog" [options]="galleryOptions" [images]="listGalleryImages" #listPreviewGallery></ngx-gallery>
</div>