import { FormControl, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import {ConfigService} from "../../service/config.service";
import {ActivatedRoute} from "@angular/router";
import { SettingsService} from '../../service/settings.service';
import CryptoJS from 'crypto-js';
import { COMMON } from '../../service/constant';
import { Address } from 'ngx-google-places-autocomplete';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { AppComponentBase } from '../../admin/shared/AppComponentBase';
declare const google;
import { Subscription } from 'rxjs';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { MatButton } from '@angular/material/button';
import { GooglePlaceDirective as GooglePlaceDirective_1 } from '../../../../../libs/third-party-deprecated/src/lib/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, NgClass, ExtendedModule, MatFormField, MatInput, MatLabel, GooglePlaceDirective_1, MatButton, SpinnerComponent]
})
export class FormsComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  leadForm: FormGroup;
  formId : any;
  formControlArray = new Array();
  formTitle:any;
  salt:any;
  secret:any;
  saltData:any;
  secretData:any;
  formDescription:any;
  fieldsCtrls:any = {};
  city = '';
  state = '';
  zip = '';
  addressLine = '';
  address = '';
  zipCode = '';
  generatedLeadName: string ='';
  country;
  public availabilityFlag: boolean;
  public showError1: boolean;
  public billShowError: boolean;
  public billErrorMsg: string;
  public billShowError1: boolean;
  public billErrorMsg1: string;
  public shipShowError1: boolean;
  public showError: boolean;
  public errorMsg: string;
  public errorMsg1: string;

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  constructor(inject: Injector, public configService: ConfigService,
    public route: ActivatedRoute,
    public settingsService : SettingsService) {
      super(inject);
      this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {

    this.formId = this.route.snapshot.paramMap.get('id');
    this.salt = this.route.snapshot.paramMap.get('salt');
    this.secret = this.route.snapshot.paramMap.get('secret');
    var originalsalt = decodeURIComponent(this.salt);
    var originalsecret = decodeURIComponent(this.secret);
    this.saltData = JSON.parse(CryptoJS.AES.decrypt(originalsalt.toString(), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8));
    this.secretData = JSON.parse(CryptoJS.AES.decrypt(originalsecret.toString(), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8));
    this.getFormsDetails();
    this.generateRandomString();
  }

  getFormsDetails(){
    const getAFormADetails : Subscription = this.settingsService.getFormDetails(Number(this.formId), this.saltData, this.secretData).subscribe((formInfo:any)=>{
      if (formInfo.info.code == 200) {
        if (formInfo.data.isDeleted) {
          this.availabilityFlag = false;
        } else{
          this.availabilityFlag = true;
          this.formControlArray = JSON.parse(formInfo.data.form.formFields);
          this.formTitle = formInfo.data.form.formTitle;
          this.formDescription = formInfo.data.form.formDescription;
          this.createFormControls();
        }
      }
    })
    this.subscriptions.push(getAFormADetails);
  }

  createFormControls(){
    for (let f of this.formControlArray) {
      if (f.required && (f.fieldName == 'companyNameField' || f.fieldName == 'companyWebsiteField' || f.fieldName == 'companyFbField' || f.fieldName == 'contactNameField' || f.fieldName == 'messageField' || f.fieldName == 'nameField')){
        this.fieldsCtrls[f.fieldName] = new FormControl('', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required]));
      } else if (f.required && (f.fieldName == 'companyEmailField' || f.fieldName == 'contactEmailField')) {
        this.fieldsCtrls[f.fieldName] = new FormControl('', [Validators.required, Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)]);
      } else if (!f.required && (f.fieldName == 'companyEmailField' || f.fieldName == 'contactEmailField')) {
        this.fieldsCtrls[f.fieldName] = new FormControl('', [Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)]);
      } else if (f.required && (f.fieldName == 'contactPhoneField')) {
        this.fieldsCtrls[f.fieldName] = new FormControl('', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE),Validators.pattern(COMMON.PHONE_PATTERN),Validators.required]));
      } else if (!f.required && (f.fieldName == 'contactPhoneField' || f.fieldName == 'companyFaxField')) {
        this.fieldsCtrls[f.fieldName] = new FormControl('', [Validators.pattern(COMMON.PHONE_PATTERN)]);
      } else if (f.required && (f.fieldName == 'addressField')) {
        this.fieldsCtrls[f.fieldName] = new FormControl('', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required]));
        this.fieldsCtrls['cityStateZip'] = new FormControl('', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required]));
        this.fieldsCtrls['country'] = new FormControl('', Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required]));
      } else if(f.required) {
        this.fieldsCtrls[f.fieldName] = new FormControl('',Validators.pattern(COMMON.EMPTY_VALUE));
      }
      else{
        this.fieldsCtrls[f.fieldName] = new FormControl('');
      }
    }
    this.leadForm = new FormGroup(this.fieldsCtrls)
  }

  cancelForm(){
    this.leadForm.reset();
  }

  generateRandomString(){
    return this.generatedLeadName = 'OF-'+Math.random().toString(18).substring(2,4).slice(0,3) + new Date().getTime().toString(18).substr(2,6).slice(1,5);
  }

  saveForm(){
    if (this.leadForm.valid) {
      var formObj = this.leadForm.value;
      this.generateRandomString();
      formObj.nameField = this.leadForm.value.nameField + ' '+'('+this.generatedLeadName+')';
      var obj = JSON.stringify(formObj);
      var leadData = {
        leadData: obj,
        secret:this.secretData,
        salt:this.saltData
      };
      const createLeadFromCli : Subscription = this.leadsService.createLeadFromClient(leadData).subscribe((leadResponse: any) =>{
        if (leadResponse.info.code == 200) {
          this.leadForm.reset();
          this.toastr.success("Enquiry sent successfully");
        } else {
          this.toastr.error("Something went wrong! Please try again later");
        }
      })
      this.subscriptions.push(createLeadFromCli);
    }
  }

  public handleAddressChange(address: Address) {
    this.city = '';
    this.state = '';
    this.zipCode = '';
    this.address = address.name;
    this.addressLine = '';

    address.address_components.forEach(element => {
      if(element.types[0]=='route'){
        this.address = this.address+', '+element.long_name;
      } else if(element.types[0]=='sublocality_level_1' || element.types[0]=='sublocality' ){
        this.address = this.address+', '+element.long_name;
      } else if(element.types[0]=='country'){
        this.country = element.long_name;
      }else if(element.types[0]=='locality'){
        this.city = element.long_name;
      } else if (element.types[0] =='administrative_area_level_1'){
        this.state = element.long_name;
      } else if (element.types[0] =='postal_code'){
        this.zipCode = element.long_name;
      } else{
        if (this.address == '') {
          this.address = element.long_name;
        }else{
          this.address = this.address;//+", "+element.long_name;
        }
      }
    });
    if (this.city) {
      this.addressLine = this.city;
      if (this.state) {
        this.addressLine = this.addressLine + ',' + this.state;
        if (this.zipCode) {
          this.addressLine = this.addressLine + ',' + this.zipCode;
        }
      }
    } else if (this.state) {
      this.addressLine = this.state;
      if (this.zipCode) {
        this.addressLine = this.addressLine + ',' + this.zipCode;
      }
    } else if (this.zipCode) {
      this.addressLine = this.zipCode;
    }
    this.leadForm.patchValue({
      country: this.country,
      cityStateZip: this.addressLine,
      addressField: this.address,
    });
    this.checkCityStateZip();
  }

  checkCityStateZip() {
    if (this.leadForm.value.cityStateZip) {
      let addressdata, billCityAddress, billState;
      let stateError = true;
      this.showError1 = false;
      this.leadForm.value.cityStateZip = this.leadForm.value.cityStateZip.trim();
      addressdata = this.leadForm.value.cityStateZip.split(',');
      if (addressdata.length > 1 && addressdata[0]) {
        this.showError = false;
      } else {
        this.showError = true;
        this.errorMsg = 'Please enter in City, State, Zip format';
      }

      if (addressdata.length === 3) {
        if (!addressdata[1]) {
          this.showError = true;
          this.errorMsg = 'Please enter Zip code.';
        }
        addressdata[2] = Number(addressdata[2]);
        if (addressdata[2] && addressdata[2].toString().length >= 4) {
          this.showError = false;
        } else {
          this.errorMsg = 'Please enter in City, State, Zip format';
          this.showError = true;
        }
      } else if (addressdata.length === 2) {
        billCityAddress = addressdata[1].split(' ');
        const billAddressLength = billCityAddress.length - 1;
        billState = billCityAddress[billAddressLength - 1];
        if (billCityAddress[billAddressLength]) {
          billCityAddress[billAddressLength] = Number(billCityAddress[billAddressLength]);
          if (isNaN(billCityAddress[billAddressLength])) {
            stateError = false;
          } else {
            stateError = true;
          }
        } else {
          billCityAddress[billAddressLength] = Number(billCityAddress[billAddressLength]);
        }
        if (billState && billCityAddress[billAddressLength] && billCityAddress) {
          let newBillingAddress = '';
          billCityAddress.forEach(function (address, index) {
            if (billAddressLength > index) {
              newBillingAddress += address + ' ';
            }
          });
          this.showError1 = false;
        } else {
          this.showError1 = stateError;
          this.errorMsg1 = 'Please enter in City, State Zip format';
        }
        if (billCityAddress[billAddressLength] && billCityAddress[billAddressLength].toString().length >= 4) {
          this.showError = false;
        } else {
          this.errorMsg = 'Please enter in City, State Zip format';
          this.showError = this.showError1 ? false : true;
        }
      }
    } else {
      this.showError = false;
      this.showError1 = false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

}
