import { ToastrService } from 'ngx-toastr';
import { ConfigService } from './../../service/config.service';
import { ReleaseNoteService } from './../../service/release-note.service';
import { EStatusCode } from './../../service/Utils/Interfaces.class';
import { Component, OnInit, HostListener, inject } from '@angular/core';
import { from } from 'rxjs';
import { StripDots } from './dot.pipe';
import { SafePipe } from '../../admin/shared/pipes/safe.pipe';
import { MatDivider } from '@angular/material/divider';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-release-notes-global',
    templateUrl: './release-notes-global.component.html',
    styleUrls: ['./release-notes-global.component.scss'],
    standalone: true,
    imports: [NgFor, MatDivider, SafePipe, StripDots]
})
export class ReleaseNotesGlobalComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  constructor(private releaseNoteService : ReleaseNoteService,
              private configService: ConfigService) {
    this.configService.updateIsUserLogged.next(false);
   }

  finaljsonarray :any = [];
  flag = false;
  id = '';
  obj = '';
  parentdiv;
  pageCount = 1;
  start =0;
  limit = 15;
  releaseNotearr;
  scrollTop;
  scrollLeft;
  public isChanged: boolean;
  limitData: any;
  count = 0;
  counts=1;
  i=0;
  j=0;
  size;




  @HostListener('window:mousewheel', ['$event'])
  @HostListener('window:keyup', ['$event'])


  ngOnInit() {
    this.getreleaseNoteList();
    this.scrollCheck();
  }


  @HostListener('window:mousewheel', ['$event'])
  @HostListener('window:keyup', ['$event'])

  //Scrolling functionality
  scorllData(e) {

   // calculate the percentage of scroll
   var pos = showPosition(this.parentdiv);
   if(pos!=null){
    var scrollPercent = Math.round(pos) + '%';
  }
    function showPosition(elm) {
        if(pos!=null){
          return pos;
        }
     };
  var scrollPercentage = '60%';
    if(scrollPercent == scrollPercentage){
      if(this.releaseNotearr.data.allData.length !== 0){
            this.start = this.limit;
            this.limit = this.limit + 15;
          }
      this.getreleaseNoteList();
    }


    //OnScroll Active class
   var count = document.querySelectorAll('.sectionnav').length;
     for (var i = 0; i < count; i++) {
      var totalCount = document.getElementsByClassName('sectionnav');
      if (totalCount!=null) {
            var lielement = document.querySelectorAll('a');
            lielement.forEach(function (el) {
              el.classList.remove("activeScroll");
            });
       }
     };

    // Default Bottom scroll Active
    var elements = document.getElementById('releaseDetailsWrapper');
    if(elements!=null){
      if (elements.offsetHeight + elements.scrollTop == elements.scrollHeight) {
        var lielement = document.querySelectorAll('a');
         lielement.forEach(function (el) {
           el.classList.remove("activeScroll");
        });
        var lastelement = document.querySelector(''+'.'+totalCount[totalCount.length -2].id+'');
        lastelement.classList.add('activeScroll');
       }
    }

    var clickId = document.getElementById("sectionnav"+this.id);
    if(clickId!=null){
        //remove padding from scrolling
      if(this.flag == true){
        this.flag = false;
      }else{
        document.getElementById("sectionnav"+this.id).classList.remove('addSpace');
      }
    }

  }

  //Default Active tab
  scrollCheck() {
    var sideDivs = document.getElementById('sideDivs');
    if(sideDivs !=null){
      var hasVerticalScrollbar = sideDivs.scrollHeight > sideDivs.clientHeight;
      if (hasVerticalScrollbar == true) {
        sideDivs.setAttribute("style", "border-right:none;");
      } else {
        sideDivs.setAttribute("style", "border-right:2px solid e4e4e4;");
      }
    }

  }

  getreleaseNoteList() {
    this.isChanged = false;
    this.limitData = {
      limit: this.limit,
      start:this.start,
    };
   if(this.releaseNotearr == undefined || this.releaseNotearr.data.allData.length == 15){
    this.releaseNoteService.getreleaseNoteList(this.limitData).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          this.releaseNotearr = res;
         let checkJsonflag = false;
         let flag = false;
          res.data.sidebarList.forEach(ele => {
            let finaljson = {};
            let montharray = []
            finaljson['year'] = ele.year;
            ele.months.forEach(monthele => {
              let monthjson = {}
              monthjson['monthName'] = monthele.monthName;
             let dayarray = [];
              monthele.dates.forEach(dayele => {
                res.data.allData.forEach(rightsideele => {

                  if (rightsideele.versionStamp == dayele.day && rightsideele.year == ele.year) {
                    if(this.finaljsonarray.length !== 0){
                      this.finaljsonarray.forEach(eleyear=>{
                         if(eleyear.year == ele.year){
                           eleyear.montharr.forEach(elemonthyear=>{
                              if(elemonthyear.monthName == rightsideele.monthName){
                                rightsideele.counter=this.count;
                                this.counts++;
                                elemonthyear.daydata.push(rightsideele);
                                checkJsonflag = false;
                                flag = true;
                              }
                           })
                         }
                      })
                   }
                   if(flag == false){
                    dayarray.push(rightsideele);
                    checkJsonflag = true;
                   }else{
                     flag = false;
                   }
                  }
                })

              })
              monthjson['daydata'] = dayarray;
              this.j++;
               montharray.push(monthjson);
           })
            finaljson['montharr'] = montharray;
            if(checkJsonflag == true){
              this.finaljsonarray.push(finaljson);
              checkJsonflag = false;
            }else{
              checkJsonflag = false;
            }
          })
         let finalarray = this.finaljsonarray[0].year;
         setTimeout(function(){
            let years = document.querySelector(".parentYear"+finalarray);
              years.classList.add("activeScroll");
          }, 2000);
        } else {
          this.start = 0;
          this.limit = 0;
        }
      }, error => {
        this.toastr.error('Data is not available');
      });
    }
  }

}
