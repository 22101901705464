
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
  Meetings Model
</h2>
   <p><i><code>Add</code></i></p>  
   <ul>
     <li>
        This API is used to create a new meeting with required details into `tbl_meetings`.  

     </li>
     <li>
        addMeeting method is used to create the meeting.  

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_add"><u>{{path}}explorer/#!/Meetings/Meetings_add</u></a>  

     </li>
   </ul>
  <br />   
    <p><i><code>Delete</code></i></p>  
    <ul>
      <li>
          This API will delete the meetings from `tbl_meetings` by meetingsId and type(<strong>opportunities or leads</strong>).  

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_delete"><u>{{path}}explorer/#!/Meetings/Meetings_delete</u></a>  

      </li>
    </ul>
     
    <p><i><code>Details</code></i></p>  
    <ul>
      <li>
          It will fetch the details of particular meeting from `tbl_meetings` by meetingsId.  

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_details"><u>{{path}}explorer/#!/Meetings/Meetings_details</u></a>  

      </li>
    </ul>
  <br />   
    <p><i><code>getMeetingOverdueList</code></i></p>  
    <ul>
      <li>
          This API will fetch the overdue list of meetings from `tbl_meetings` if the below condition satisfies:  

      </li>
      <li>
          <strong>tbl_meetings.mark_as_done=0 AND CAST(tbl_meetings.end_date)&lt;CURDATE()</strong>  

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_meetingOverDueList"><u>{{path}}explorer/#!/Meetings/Meetings_meetingOverDueList</u></a>  

      </li>
    </ul>
  <br />   
    <p><i><code>List</code></i></p>  
    <ul>
      <li>
          This API will fetch the meetings list from `tbl_meetings` by moduleId and moduleType.  

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_list"><u>{{path}}explorer/#!/Meetings/Meetings_list</u></a>  

      </li>
    </ul>
  <br />   
    <p><i><code>Modify</code></i></p>  
    <ul>
      <li>
          This API is used to modify the details of meetings into `tbl_meetings` by meetingsId.  

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Meetings/Meetings_modify"><u>{{path}}explorer/#!/Meetings/Meetings_modify</u></a>  

      </li>
    </ul>
    </div>
</mat-card>
