import { COMMON } from './../../service/constant';
import { LoginService } from './../../service/login.service';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {StorageKey} from "../../service/constant";
import { Router, RouterLink } from "@angular/router";
import {EStatusCode} from "../../service/Utils/Interfaces.class";
import { take } from 'rxjs/operators';
import { BaseResponse, CommonResponse } from '../../service/Interfaces/login.interface';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../../admin/shared/spinner.component';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatIcon,
        MatPrefix,
        NgIf,
        MatError,
        RouterLink,
        SpinnerComponent,
    ],
})
export class ForgotPasswordComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  forgotPasswordForm: FormGroup;
  submitted = false;
  public data: any;
  isDisabled: boolean = false;
  show: boolean = false;
  public version: any;
  private domainName;
  public currYear: any;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {
    this.version = COMMON.VERSION;
    if(localStorage.getItem(StorageKey.ACCESSTOKEN)){
      this.router.navigate(["/"], { replaceUrl: true });
    }
    this.currYear = COMMON.CURRENT_YEAR;
    
  }

  ngOnInit() {
    this.domainName = location.origin.split('/');
    this.forgotPasswordForm = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)])]
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return this.forgotPasswordForm.controls;
    }
    this.data = {
      email: this.forgotPasswordForm.value.email,
      domain : this.domainName[2]
    };

    const passwordRecoveryResponse: BaseResponse<CommonResponse> = await this.loginService.passwordRecovery(this.data)
    .pipe(take(1)).toPromise();

    if (passwordRecoveryResponse.info.code == EStatusCode.OK) {
      this.toastr.success(passwordRecoveryResponse.data.responseMsg);
      this.router.navigate(['/login'], { replaceUrl: true });
    } else {
      this.toastr.error(passwordRecoveryResponse.data.responseMsg);
    }
  }

  async checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      const emailExistsResponse: any = await this.loginService.userEmailExistsForgotPassword(email,  this.domainName[2])
      .pipe(take(1)).toPromise();
      if (emailExistsResponse.data.emailExists) {
        this.isDisabled = false;
        this.show = false;
      } else {
        this.show = true;
        this.isDisabled = true;
      }
    }
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

}
