<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Comments Model </h2>

<p><i><code>Add</code></i></p>
<ul>
<li>
    This API is used to create a new comment into `tbl_task_comment` by moduleId and moduleType.
</li>
<li>
    For reference: <a href="{{path}}explorer/#!/Comments/Comments_add" >
      {{path}}explorer/#!/Comments/Comments_add</a>
  </li>
</ul>
<br />

<p><i><code>Delete</code> </i></p>
<ul>
  <li>
      This API will delete the comment from `tbl_task_comment` by taskCommentId if exists.
  </li>
<li>
    For reference: <a href="{{path}}explorer/#!/Comments/Comments_delete" >
      {{path}}explorer/#!/Comments/Comments_delete</a>
  </li>
</ul>
<br />

<p><i><code>List</code></i></p>
<ul>
  <li>
      This API will fetch comments list from `tbl_task_comment` by moduleId and moduleType.
  </li>
<li>
    For reference: <a href="{{path}}explorer/#!/Comments/Comments_list" >
      {{path}}explorer/#!/Comments/Comments_list</a>
  </li>
</ul>
</div>
</mat-card>
