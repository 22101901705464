import { TenantCompaniesService } from './../../../../../service/tenant-companies.service';
import { Jobs } from './../../../../../service/models/Jobs';
import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '../../../../shared/AppComponentBase';
import { COMMON } from '../../../../../service/constant';
import { ActivatedRoute } from '@angular/router';

import CryptoJS from 'crypto-js';
import {JobcircularService} from "../../../../../service/jobcircular.service";
import { JsonFilterByPipe } from '../../json-filter-by.pipe';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../../shared/spinner.component';
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-all-job-post',
    templateUrl: './all-job-post.component.html',
    styleUrls: ['./all-job-post.component.scss'],
    preserveWhitespaces: true,
    standalone: true,
    imports: [MatToolbar, NgClass, ExtendedModule, NgIf, MatFormField, MatLabel, MatInput, FormsModule, NgFor, MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle, SpinnerComponent, CommonDatePipe, SafePipe, JsonFilterByPipe]
})
export class AllJobPostComponent extends AppComponentBase implements OnInit {
  public version: string;
  public companyLogo: string;
  public themeData: string;
  public companyName: string;
  hostname: any;
  host: any;
  showResultLabel: boolean = false;
  jobs: Jobs[];
  salt: any;
  secret: any;
  saltData: any;
  secretData: any;
  url: any;
  isTrimmed: boolean = false;

  constructor(inject: Injector, private jobcircularService: JobcircularService, private route: ActivatedRoute, private tenantCompaniesService: TenantCompaniesService) {
    super(inject);
    this.hostname = window.location.hostname
    this.version = COMMON.VERSION;
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    this.getAllJobs();
    this.version = COMMON.VERSION;
    this.companyLogo = this.configService.companyLogo;
    this.companyName = this.configService.companyName;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';

  }

  getSaltAndSecret() {
    this.tenantCompaniesService.getCompanyDetails().subscribe((res: any) => {
      if (res.info.code == 200) {
        this.secret = res.data.companySecret;
        this.salt = res.data.saltKey;
        this.getAllJobs();
      }
    })
  }

  getAllJobs() {
    // uncomment this for local testing
    // if (this.hostname == 'localhost') {
    //   this.host = 'koders.xyz';
    // } else {
      this.host = this.hostname;
    // }
    this.jobcircularService.openListView(this.host).subscribe(
      (jobRes: any) => {
        if (jobRes.info.code == 200) {
          if (jobRes['data'].list.length > 0) {
            this.secret = jobRes['data'].companyInfo.companySecret;
            this.salt = jobRes['data'].companyInfo.saltKey;
            this.jobs = jobRes['data'].list;
            var maxLength = 300
            jobRes['data'].list.forEach(element => {
              var ciphertextSalt = CryptoJS.AES.encrypt(JSON.stringify(this.salt), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
              var encSalt = encodeURIComponent(ciphertextSalt);
              var ciphertextSecret = CryptoJS.AES.encrypt(JSON.stringify(this.secret), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
              var encSecret = encodeURIComponent(ciphertextSecret);
              element.url = '/careers/' + element.jobCircularId + '/' + encSalt + '/' + encSecret;
              if (element.description.length > 300) {
                var trimmedString = element.description.substr(0, maxLength);
                trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
                trimmedString = trimmedString + "...";
              } else {
                var trimmedString = element.description;
              }
              element.description = trimmedString;
            });
            this.companyLogo = jobRes['data'].companyInfo.companyLogo;
            this.companyName = jobRes['data'].companyInfo.companyName;
            this.showResultLabel = false;
          } else {
            this.showResultLabel = true;
          }
        }
        else {
          this.showResultLabel = true;
        }
      }
    );
  }

  viewDetailPage(jobCircularId, $event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    var ciphertextSalt = CryptoJS.AES.encrypt(JSON.stringify(this.salt), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
    this.salt = encodeURIComponent(ciphertextSalt);
    var ciphertextSecret = CryptoJS.AES.encrypt(JSON.stringify(this.secret), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
    this.secret = encodeURIComponent(ciphertextSecret);
    this.url = '/careers/' + jobCircularId + '/' + this.salt + '/' + this.secret;
    let iframeLink = window.location.origin + this.url;
    var urlData = {
      url: iframeLink
    }
    this.routerDialog.navigateByUrl(this.url);
  }

}
