import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TinyMceConfigComponent } from '../../../../../admin/tinymce-config';
import { ConfigService } from '../../../../../service/config.service';
import { TaskService } from '../../../../../service/task.service';
import { TicketsService } from '../../../../../service/tickets.service';
import { EStatusCode, IResponseList } from '../../../../../service/Utils/Interfaces.class';
import { ToastrService } from 'ngx-toastr';
import { SafePipe } from '../../../../../admin/shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../../../admin/shared/pipes/common-date.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { TinymceConfigDirective } from '../../../../../admin/shared/custom-directives/tinymce-config.directive';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatDivider } from '@angular/material/divider';


@Component({
    selector: 'app-testcase-comments',
    templateUrl: './testcase-comments.component.html',
    styleUrls: ['./testcase-comments.component.scss'],
    standalone: true,
    imports: [MatDivider, NgIf, FormsModule, ReactiveFormsModule, TinymceConfigDirective, NgSelectModule, MatCheckbox, MatButton, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgClass, ExtendedModule, MatPaginator, CommonDatePipe, SafePipe]
})
export class TestcaseCommentsComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  TemplateArr = [];
  TemplateCategoryArr = [];
  tempTemplateCatArr = [];
  hoursMinutesArray = [];
  viewType: string;
  commentPermission = true;
  testCaseCommentForm: FormGroup;
  tenant_name: any;
  config: any;
  commentList = [];
  totalRecords: number;
  prevPageIndex = 0;
  start: number = 0;
  limit : number = 15
  pageSize:number = 15;
  pageSizeArray = [15, 50, 100];
  billable = false;
  testCaseId:any;
  taskType : string = "testCases";
  searchValue:any;
  showResultLabel:boolean=false;
  toResult: boolean = false;
  event: any;
  pageIndex:any;
  private subscriptionCommentList: Subscription;


  constructor(public fb: FormBuilder, public mceConfig: TinyMceConfigComponent, public configService: ConfigService, public ticketsService: TicketsService,private taskService: TaskService) { }

  ngOnInit() {
    this.testCaseId = this.taskService.testCaseId
    this.tenant_name = this.configService.tenantName;
    this.getTinymceConfig();
    this.getValidations();
    this.getCategoryList();
    this.getTestCasesCommentList(0,0,0);
    this.testCaseCommentForm.controls['logHours'].reset();
  }

  getValidations() {
    this.testCaseCommentForm = this.fb.group({
      comment: [''],
      logHours: [''],
    });
  }

  getTinymceConfig() {
    this.config = this.mceConfig.getTinyMCEConfig(this.tenant_name);
  }

  changeBillStatus(){
    this.billable = !this.billable;
  }

  saveComment() {
    this.taskService.addTaskComment(
      this.testCaseId,
      String(this.taskType),
      String(this.testCaseCommentForm.value.comment),
    ).subscribe(res => {
      this.toastr.success("Comment Created Successfully");
      this.testCaseCommentForm.reset();
      this.getTestCasesCommentList(0,0,0,)
    })


  }

  getTestCasesCommentList(id , count?, previousPageIndex?) {
    if (count === 0) {
      if (count <= previousPageIndex) {
        this.start = 0;
      } else {
        this.start += this.pageSize;
      }
    } else {
      this.start = count * this.pageSize;
    }
    if (this.subscriptionCommentList) {
      this.subscriptionCommentList.unsubscribe();
    }
    if(this.searchValue != undefined){
      this.start = 0;
    }else{
      this.start = this.start;
    }
     let filter = {
      limit: this.limit,
      start:  this.start,
      search: this.searchValue
    };
    this.subscriptionCommentList = this.taskService.taskCommentList(this.testCaseId, this.taskType, filter).subscribe((res: IResponseList) => {
      this.subscriptionCommentList.unsubscribe();
      this.commentList = [];
      if (res.info.code === EStatusCode.OK && res.data.list !== undefined) {
        res.data.list.map((comment: any) => {
          this.commentList.push(comment);
        });
        this.totalRecords = res.data.totalCount;
      } else {
        this.totalRecords = 0;
        this.commentList = [];
      }
    });
  }

  search(searchValue) {
    this.showResultLabel = true;
    this.searchValue = searchValue;
    this.getTestCasesCommentList(0,0);
    this.toResult = true;
  }

  getCategoryList(){
    this.ticketsService.templateCategoryList().subscribe((res:any) => {
      if (res.info.code === EStatusCode.OK) {
        if (res.data.list && res.data.list.length) {
          this.tempTemplateCatArr = res.data.list;
          this.getAllTemplateList();
        } else {
          let none = {categoryId: 0, categoryName: "None", deptName: null, parentId: null}
          this.TemplateCategoryArr = [];
          this.TemplateCategoryArr.push(none);
        }
      }
    })
  }

  getAllTemplateList(){
    let filter = {};
    this.ticketsService.ticketTemplateList(filter).subscribe((res: IResponseList) => {
      if (res.info.code === EStatusCode.OK) { 
        let _templateArr = res.data.list;
        this.TemplateCategoryArr = this.tempTemplateCatArr.map((e) => {
          let findMatchedTemplates = _templateArr.filter((el) => el.categoryId == e.categoryId);
          e['templateArr'] = findMatchedTemplates.length > 0 ? findMatchedTemplates : [];
          return e;
        });
       }else {
        this.TemplateCategoryArr = this.tempTemplateCatArr.map((e) => {
          e['templateArr'] = [];
          return e;
        }); 
       }
    })
  }

  patchTemplate(templateData){
    if(templateData.templateId !== 0){
      this.testCaseCommentForm.patchValue({
        "comment": templateData.body,
      })
    }
  }

 

  onChange(event) {
    this.event = event.pageIndex;
    if (this.limit !== event.pageSize) {
      this.start -= event.pageSize;
      this.limit = event.pageSize;
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex
    }
    this.prevPageIndex = event.previousPageIndex;
    this.getTestCasesCommentList(this.testCaseId, this.pageIndex, this.prevPageIndex);
  }

  deleteComment(id){
        this.taskService.deleteComments(this.testCaseId, id, this.taskType).subscribe((commentdeleteRes: any) => {
          console.log("commentdeleteRes =================>",commentdeleteRes)
          if(commentdeleteRes){
            
            this.toastr.success("Comment Deleted Successfully");
            this.getTestCasesCommentList(0,0,0)
          }
        })
      }
}