<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>
  
<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
    Attachments Model  </h2>
  <p> <code><i>Upload</i> </code></p>
  <ul>
  <li>
      This API is used to upload the files into google cloud storage.
  </li>
  <li>
      require('multiparty') - To upload the files from form.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_upload" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_upload</a>
  </li>
</ul>
<br />


<p> <code><i>List</i></code></p>
  <ul>
  <li>
      This API is used to fetch list of attachments by 
      moduleId and moduleType from `tbl_task_attachments`.
  </li>
  <li>
      getAttachmentList - method will fetch the list of attachment and uploaded file details from 
      `tbl_task_attachment` and `tbl_task_uploaded_files`.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_list" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_list</a>
  </li>
</ul>
<br />


<p> <code><i>Add</i> </code></p>
  <ul>
  <li>
      This API is used to add multiple files in the same directory into GCP.
  </li>
  <li>
      It will add the moduleId, title of the attachment, uploaded time in `tbl_task_attachment` and the files 
      and each file details will be saved in `tbl_task_uploaded_files` with taskAttachmentId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_add" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_add</a>
  </li>
</ul>
<br />

<p> <code><i>Delete</i> </code></p>
  <ul>
  <li>
      This API will delete the attachments and the files present in the attachment from 
      the database(`tbl_task_attachment`,`tbl_task_uploaded_files`).
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_delete" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_delete</a>
  </li>
</ul>
<br />


<p> <code><i>deleteCloud</i> </code></p>
  <ul>
  <li>
      This API will delete the files from cloud storage.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_deleteCloud" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_deleteCloud</a>
  </li>
</ul>
<br />


<p> <code><i>getFolderName</i></code></p>
  <ul>
  <li>
      This API is used to get the folder name of particular module by moduleId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_getFoldername" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_getFoldername</a>
  </li>
</ul>
<br />

<p> <code><i>Modify</i> </code></p>
  <ul>
  <li>
      This API is used to add more files in the same attachment by taskAttachmentId if exists.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Attachment/Attachment_modify" >
        {{currentUrl}}explorer/#!/Attachment/Attachment_modify</a>
  </li>
</ul>
<br />
</div>
</mat-card>

