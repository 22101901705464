
<div class="api-docs-back-btn">
        <section class="content-header">
          <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
                <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                  <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
              </a>    
          </button>
        </section>
  </div>
   
<mat-card>
    <div class="api-docs">
        <h2 class="content-header-name">Global Model</h2>
    <p><i><code>addAch</code>  </i></p>  
<ul>
    <li>
            This API is used to add an ach account into `tbl_payment_info` if account not exists.  
    </li>
    <li>
            Before creating the account getTsysUrl method is called to get the ach url.  
            <strong>require</strong>(<strong>"request"</strong>) package is used to authenticate the ach account.            
    </li>
    <li>
            For reference: <a href="{{path}}explorer/#!/Global/Global_addAch"><u>{{path}}explorer/#!/Global/Global_addAch</u></a>  

    </li>
</ul>
  <br />   
  <h4> Types of participants  </h4>
  <ul>
      <li>
            For leads, opportunities, tasks, bugs, client, accounts module participants will be &lsquo;projectManager&rsquo;(can select multiple users) and &lsquo;owner&rsquo;(can select multiple users) flag = 0.  

      </li>
      <li>
            For tickets, projects module participants will be &lsquo;myself&rsquo;, &lsquo;custom&rsquo;(can select multiple users) and &lsquo;everyone&rsquo; flag = 1.  

      </li>
  </ul>
  <br />   
    <p><i><code>addMember</code>  </i></p>  
  <ul>
      <li>
            This API is used to add the members(participants) by using moduleId, name(<strong>Enter name ex. opportunities name, tickets name, etc</strong>), type(<strong>Enter module name ie. opportunities, tickets, bugs, projects, tasks or leads (all types are case sensitive)</strong>) and departmentId.  

      </li>
      <li>
            checkGroups method is used here.  

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Global/Global_addMember"><u>{{path}}explorer/#!/Global/Global_addMember</u></a>  

      </li>
  </ul>
  <br />   
    <p><i><code>cardAuthentication</code>  </i></p>  
    <ul>
        <li>
                This API is used to authenticate the credit card while adding a new credit card method into `tbl_payment_info`.  

        </li>
        <li>
                getTsysUrl method is used to get tsysUrl.  

        </li>
        <li>
                If url is present, <strong>require</strong>(<strong>"request"</strong>) package is used to authenticate the credit card. Once authentication is passed, cardType can be retrieved from response.  

        </li>
        <li>
                Once credit card is authenticated successfully, card details will be saved in `tbl_payment_info`.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_cardAuthentication"><u>{{path}}explorer/#!/Global/Global_cardAuthentication</u></a>  

        </li>
    </ul>
    <br /> 
    <p><i><code>checkCCExists</code>  </i></p>
    <ul>
        <li>
                This API is used to check whether the credit card number is already exists or not for that clientId from `tbl_payment_info` while doing payments.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_checkCCExists"><u>{{path}}explorer/#!/Global/Global_checkCCExists</u></a>  

        </li>
    </ul>  
  <br />   
    <p><i><code>checkSubscriptionCCExists</code>  </i></p>  
    <ul>
        <li>
                This API is used to check whether the credit card number is already exists or not for that userId from `tbl_subscribe_payment_info` while doing subscription.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_checkSubscriptionCCExists"><u>h</u></a><a href="{{path}}explorer/#!/Global/Global_checkSubscriptionCCExists"><u>{{path}}explorer/#!/Global/Global_checkSubscriptionCCExists</u></a>  

        </li>
    </ul>
  <br />   
    <p><i><code>findLeadDetailsByCompanykey</code>  </i></p> 
    <ul>
        <li>
                This API is used to fetch the lead details by companyKey from `tbl_leads`.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_findLeadDetailsByCompanyKey"><u>{{path}}explorer/#!/Global/Global_findLeadDetailsByCompanyKey</u></a>  

        </li>
    </ul> 
  <br />   
    <p><i><code>makeSubscribeCCPayment</code>  </i></p>  
    <ul>
        <li>
                This API is used to make a payment using credit card while doing subscription.  

        </li>
        <li>
                Before making payment it will authenticate the credit card by using <strong>tsys_cc_api_url</strong> from getTsysUrl method if credit card exists.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_makeSubscribeCCPayment"><u>{{path}}explorer/#!/Global/Global_makeSubscribeCCPayment</u></a>  

        </li>
    </ul>
  <br />   
    <p><i><code>tenantSetup</code>  </i></p> 
    <ul>
        <li>
                This API is used to setup the default/initial values for new user.  
        </li>
        <li>
                Setup will follow the below steps.  
<ul>
    <li>
            It will fetch the fullName, companyName etc., of the tenant from `tbl_tenant_companies` and `tbl_tenants`   

    </li>
    <li>
            getAllModules method is used to get all the subscribed Apps from `tbl_menu`.  

    </li>
    <li>
            Once modules are fetched from (2), it will insert or update the initial values for each module into database through iteration by following steps.  
        <ul>
            <li>
                    <strong>Query here</strong> - it will fetch the resources for each module from  `tbl_resources` and `tbl_access_rules`.  

            </li>
            <li>
                    <strong>Query here</strong> - it will create a new policy for each module by tenant_company_key and access_rule_id  fetched from (A).  

            </li>
            <li>
                    <strong>Query </strong>- it will update the access_rule_id  and module_name into `tbl_default_policy`.  

            </li>
            <li>
                    If (B) is a success, it will insert the roles for that particular policy(from B) into `tbl_roles`.  

            </li>
            <li>
                    If (D) resultant in success, it will update the roles into `tbl_users` by userId.  

            </li>
            <li>
                    <strong>Query </strong>- to fetch all the users from `tbl_users`.  

            </li>
            <li>
                    <strong>Query </strong>- it will check whether groupNo is exists or not for the users from (F). if no, it will generate random groupNo and it will be inserted into `tbl_groups` if groupNo not exists.  

            </li>
            <li>
                    Once group created successfully, it will insert the below values into database.  

            </li>
            <li>
                    <strong>Query here</strong> to insert the user details and user contact details into `tbl_leads`, `tbl_contacts` and `tbl_account_details`.  

            </li>
            <li>
                    Default working days values will be inserted in `tbl_working_days`.  

            </li>
            <li>
                    Once working days set successfully, the following methods will be called. (for method definition refer defaultSettings.js).  
                    <p>createDefaultEmailTemplates</p>
                    <p>createDefaultPaymentMethods</p>
                    <p>createDefaultCOA</p>
                    <p>createDefaultConfig</p>
                    <p>createDefaultLeadsCategory</p>
                    <p>createDefaultLeaveCategory</p>
                    <p>createDefaultLeadSource</p>
                    <p>createDefaultOpportunitySource</p>
                    <p>createDefaultTicketCategory</p>
                    <p>createDefaultProjectCategory</p>
                    <p>createDefaultOpportunitiesPipelineAndStages</p>
                    <p>createDefaultProjectPipelineAndStages</p>
                    <p>createDefaultAgreementTemplate</p>
                    <p>createDefaultDepartment</p> 
            </li>
            <li>
                    <strong>Query </strong>-  it will update the primary_contact and leads_id into `tbl_leads`.  

            </li>
            <li>
                    <strong>Query </strong>to insert the leads activities into `tbl_activities`.  

            </li>
            <li>
                    <strong>Query </strong>- default company details will be inserted into `tbl_config` through iteration.  

            </li>
            <li>
                    Once default config values are inserted into database, finally, it will update the subscribed_modules and isSetupDone as 1 in `tbl_tenant_companies` to complete the application setup.  

            </li>
        </ul>
    </li>
</ul>
        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_tenantSetup"><u>{{path}}explorer/#!/Global/Global_tenantSetup</u></a>  

        </li>
    </ul> 
  <br />   
    <p><i><code>updateSubscribtionPaymentsForCC</code>  </i></p>  
    <ul>
        <li>
                This API is used to update the subscription payment for credit card using the below steps.  

        </li>
        <li>
                It can be done for more than once invoice through iteration.  

        </li>
        <li>
                <strong>Query </strong>- to fetch the invoice details.  

        </li>
        <li>
                Once the invoice details fetched, payment will be created for that invoice in `tbl_payments`.  

        </li>
        <li>
                If payment creation done, it will fetch configKey: 'default_account'  value(accountId) from `tbl_config`.  

        </li>
        <li>
                updateTransactionDataByAccountId:  
            <ul>
                <li>
                        <strong>Query </strong>- it will fetch the account details from `tbl_account_details` by accountId if exists.  

                </li>
                <li>
                        If accountId exists, balance amount will be updated in `tbl_accounts` and `tbl_chart_of_accounts`.  

                </li>
                <li>
                        Once the above process done, new transaction will be created for the accountId into `tbl_transactions`.  

                </li>
            </ul>
        </li>
        <li>
                getCOAInfoForClientInvoices: (returns COAInfo)  
            <ul>
                <li>
                        <strong>Query </strong>will fetch the invoice details by invoiceId(1).  

                </li>
                <li>
                        calculateTo method is called here to get the total amount for that invoiceId.  

                </li>
                <li>
                        If invoiceData exists, it will fetch the list of items(invoiceItems) from `tbl_items` by invoiceId.  

                </li>
                <li>
                        If items list exists, it will fetch item details(savedItems) from `tbl_saved_items` by item_name through iteration(vItem).  

                </li>
                <li>
                        If savedItems exists, vItem.tax_name != 0.00 , it will fetch the tax rate details from `tbl_tax_rates` by vItem.tax_name.  

                </li>
                <li>
                        Items tax will be calculated based on the tax_rate_in value for each invoiceItems(returns itemTaxes).  

                </li>
                <li>
                        Once itemTaxes calculation is done, it will fetch the tax rate values(2) from `tbl_tax_rates` by tax_id of the invoice(from 1).  

                </li>
                <li>
                        Returns itemTaxes with (2).  

                </li>
            </ul>
        </li>
        <li>
                updateCOAInfo:  
            <ul>
                <li>
                        If valCOA.<strong>tax_coa_id</strong> , <strong>Query </strong>will fetch the COA details from `tbl_chart_of_accounts` by <strong>coa_id</strong> = valCOA.<strong>tax_coa_id</strong>. (returns taxCOAData)  

                </li>
                <li>
                        If taxCOA data is present, it will check the account_type and coa_id.  

                </li>
                <li>
                        If account_type == <strong>'16' </strong>&amp;&amp; coa_id == 1 , following steps will be executed. Else, it will return the balance amount.  
                    <ul>
                        <li>
                                <strong>Query </strong>- it will fetch the sales tax invoice details from `tbl_sales_tax_invoice` by clientId and invoiceId.  
                        </li>
                        <li>
                                If data is present, it will update the amount values into `tbl_sales_tax_invoice` else it will insert the values with clientId and invoiceId.   

                        </li>
                    </ul>
                </li>
                <li>
                        taxCOAData is updated into `tbl_chart_of_accounts` by coa_id.  

                </li>
                <li>
                        After completion of the above steps, <strong>Query</strong> will fetch the COA details by <strong>coa_id</strong> = valCOA.<strong>income_coa_id</strong>.   

                </li>
                <li>
                        If data is present, balance amount and total sales price will updated into `tbl_chart_of_accounts` by coa_id.  
                    <ul>
                        <li>
                                <strong>Query</strong> will fetch income, expense details from `tbl_income_expense_record` by invoiceId.  

                        </li>
                        <li>
                                If data exists, income_amount will be updated with invoiceId and coaId into `tbl_income_expense_record`, else the data will be inserted into the database.  

                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
                After executing updateCOAInfo method, invoice status will be updated into `tbl_invoice`.  

        </li>
        <li>
                The above process will executed for other invoices through iteration.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_updateSubscribtionPaymentsForCC"><u>{{path}}explorer/#!/Global/Global_updateSubscribtionPaymentsForCC</u></a>   

        </li>
    </ul>
  <br />   
    <p><i><code>updateSubscriptionModules</code>  </i></p> 
    <ul>
        <li>
                This API is used to update(add/remove) the Subscribed Apps of the users.  

        </li>
        <li>
                API will be executed based on the below steps:  
            <ul>
                <li>
                        Get the tenant company details from `tbl_tenant_companies` by companyKey.  

                </li>
                <li>
                        Fetch the list of menus from `tbl_menu` by using provided modules in the request.  

                </li>
                <li>
                        getAllModules will be called.  

                </li>
                <li>
                        Get the resources and accessRules of each module(menu) from `tbl_resources` and `tbl_access_rules` through iteration.  

                </li>
                <li>
                        Get the policy details from `tbl_policy` by <strong>policyName</strong>: companyName, <strong>tenantCompanyKey</strong>: companyKey.  

                </li>
                <li>
                        If policyInfo exists:  
                    <ul>
                        <li>
                                Update the current accessRule into `tbl_policy` by policyId and Subscribed Apps into `tbl_tenant_companies` by companyKey.  

                        </li>
                    </ul>
                </li>
                <li>
                        If policyInfo not exists:  
                    <ul>
                        <li>
                                Create new policy with the companyName into `tbl_policy` and update Subscribed Apps into `tbl_tenant_companies` by companyKey.  

                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_updateSubscriptionModules"><u>{{path}}explorer/#!/Global/Global_updateSubscriptionModules</u></a>  

        </li>

    </ul> 
  <br />   
    <p><i><code>userCardAuthentication</code>  </i></p>  
    <ul>
        <li>
                This API is used to authenticate the credit card while doing payment by userId.  

        </li>
        <li>
                getTsysUrl method is used here to get tsysUrl.  

        </li>
        <li>
                If url is present, <strong>require</strong>(<strong>"request"</strong>) package is used to authenticate the credit card.  

        </li>
        <li>
                Once CC has been authenticated successfully, it will check whether it is defaultCard. If yes, all the default CC list will be fetched from `tbl_subscribe_payment_info` and <strong>isDefaultCard </strong>= 0 will be updated for each paymentInfoId into `tbl_subscribe_payment_info` through iteration.  

        </li>
        <li>
                Finally, new CC details will be added into `tbl_subscribe_payment_info`.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_userCardAuthentication"><u>{{path}}explorer/#!/Global/Global_userCardAuthentication</u></a>  

        </li>
    </ul>
  <br />   
    <p><i><code>validateACH</code>  </i></p>  
    <ul>
        <li>
                This API is used to verify the ACH account using routing number is exists or not for that particular paymentInoId from `tbl_payment_info`.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_validateACH"><u>{{path}}explorer/#!/Global/Global_validateACH</u></a>  

        </li>
    </ul>
  <br />   
    <p><i><code>validateCC</code>  </i></p> 
    <ul>
        <li>
                This API is used to verify the credit card by checking cvc using paymentInfoId from `tbl_payment_info`.  

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Global/Global_validateCC"><u>{{path}}explorer/#!/Global/Global_validateCC</u></a>  

        </li>
    </ul> 
</div>
</mat-card>
