<div class="testcase-activities-section">
  <div class="headerDiv">Activities </div>
  <mat-divider class="primary-color"></mat-divider>
  <div class="_activities_section">
    <div class="infinite-scroll">
      <ul id="comments-list" class="comments-list" [class._show_timeline]="activitiesListArr.length > 0">
        <li *ngFor="let element of activitiesListArr;let index = i">
          <div class="comment-main-level">
            <div class="comment-avatar" *ngIf="element.cronType == '0'"><img src={{element.avatar}} onError="this.src='assets/img/default_avatar.jpg'"
              alt="{{element.fullname}}" title="{{element.fullname}}"></div>
             <div class="comment-avatar" *ngIf="element.cronType == '1'"><img src='assets/img/default_avatar.jpg' onError="this.src='assets/img/default_avatar.jpg'"
              alt="Automation" title="Automation"></div>
            <div class="comment-box">
              <div class="comment-head">
                <span class="_date">{{element.activityDate | commonDate : 'convert_date_time_SS'}}</span>
                <span class="separator"></span>
                <span *ngIf="element.cronType == '0'">{{element.fullname}}<span class="text-danger"> {{configService.isOwner ? '(admin)' : element.userType}}</span></span>
              </div>
              <div class="activity-content">
                <span class="contentHtml tinyClass" [innerHTML]="element.activity | safe: 'html'"></span>
                <span class="contentHtml">
                <em *ngIf="(element.oldValue && !element.currentValue)">
                  from "{{element.oldValue}}"
                </em>
                <em *ngIf="element.oldValue && element.currentValue">
                  from "{{element.oldValue}}" to
                </em>
                <em *ngIf="element.currentValue">
                  "{{element.currentValue}}"
                </em>
                  </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <mat-paginator *ngIf="totalRecordes !== 0" #paginator [pageSizeOptions]="pageSizeArray" (page)="onChange($event)" [length]="totalRecordes"
                     [pageSize]="limit" showFirstLastButtons></mat-paginator>
  </div>
</div>

