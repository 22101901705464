import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from '../../../../service/menu.service';
import { IResponse } from '../../../../service/Utils/Interfaces.class';
import { SettingsService } from '../../../../service/settings.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../service/config.service';
import { TourServiceService } from '../../../../service/tour-service.service';
import { MenuListItemComponent } from '../menu-list-item/menu-list-item.component';
import { AccordionDirective } from '../../../shared/accordion/accordion.directive';
import { MatNavList } from '@angular/material/list';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [],
    standalone: true,
    imports: [NgIf, MatNavList, AccordionDirective, NgFor, MenuListItemComponent]
})
export class AppSidebarComponent implements OnInit, OnDestroy {
    myData: any;
    displayList = false;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    public companyLogo: any;
    navItems = [];
    config = {
        paddingAtStart: true,
        listBackgroundColor: `#3A3F51`,
        backgroundColor: `#445137`,
        interfaceWithRoute: true,
        highlightOnSelect: true,
        selectedListFontColor: `#ffffff`,
        fontColor: `rgb(8, 54, 71)`,
        collapseOnSelect: true,
        classname: 'my-custom-class',
        rtlLayout: false
    };

    private subscription: Subscription;

    constructor(private menuService: MenuService,
                private router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                private configService: ConfigService,
                private media: MediaMatcher,
                private settingService: SettingsService,
                private tourService: TourServiceService) {
        this.initilaStup();
        this.subscription = this.settingService.menuUpdateEventListener.subscribe((val: boolean) => {
            if (val) {
                this.initilaStup();
                this.settingService.menuUpdateEventListener.next(false);
            }
        });
        this.intSetup();
        this.subscription = this.settingService.logoUpdateEventListener.subscribe((val: string) => {
            if (val !== '') {
                this.companyLogo = val;
                this.intSetup();
                this.settingService.logoUpdateEventListener.next('');
            }
        });
    }

    ngOnInit() {
        this.companyLogo = this.configService.companyLogo;
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.subscription.unsubscribe();
    }

    selectedItem($event) {
    }

    selectedLabel($event) {
    }

    redirect(link) {
        this.router.navigate([link]);
        setTimeout(() => {
            this.displayList = true;
        }, 100);
    }

    intSetup(): void {
        this.mobileQuery = this.media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => {
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        };
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    initilaStup(): void {
        this.mobileQuery = this.media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => {
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        };
        this.mobileQuery.addListener(this._mobileQueryListener);

        const setupDone = localStorage.getItem('setup');
        if (setupDone === '1') {
            if (this.configService.userType !== 2) {
                this.menuService.dynamicMenu().subscribe((allMenus: IResponse) => {
                    if (allMenus.info.code === 200) {
                        allMenus.data.forEach(function (ele, menuIndex) {
                            if (ele.label === 'accounting') {
                                allMenus.data[menuIndex]['items'].forEach(function (MenuEle, menuEleIndex) {
                                    if (MenuEle.label === 'transactions') {
                                        (allMenus.data[menuIndex]['items'])[menuEleIndex].items.sort((a, b) => (a.sort > b.sort) ? 1 : -1);
                                    }
                                });
                            }
                        });
                        this.navItems = allMenus.data;
                        this.setCustomerTourData();
                    }
                });
            } else {

                const menuItem = [{
                    'label': 'dashboard',
                    'icon': 'fa fa-tachometer-alt',
                    'link': '/client/dashboard',
                    'menuId': 0,
                    'parent': 0,
                    'status': 0,
                    'items': []
                },
                    {
                        'label': 'project',
                        'icon': 'fas fa-project-diagram',
                        'link': '/client/project',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'bugs',
                        'icon': 'fa fa-bug',
                        'link': '/client/bugs',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'invoice',
                        'icon': 'fas fa-file-invoice',
                        'link': '/client/invoice',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'estimates',
                        'icon': 'fas fa-equals',
                        'link': '/client/estimates',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'payment',
                        'icon': 'fa fa-money',
                        'link': '/client/payment',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'RMA',
                        'icon': 'fa fa-copy',
                        'link': '/client/rma',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'tickets',
                        'icon': 'fa fa-ticket-alt',
                        'link': '/client/tickets',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    },
                    {
                        'label': 'user',
                        'icon': 'fa fa-user',
                        'link': '/client/user',
                        'menuId': 0,
                        'parent': 0,
                        'status': 0,
                        'items': []
                    }];
                this.navItems = menuItem;
                this.setCustomerTourData();
            }
        } else {
            localStorage.removeItem('accessToken');
            this.router.navigate(['/login'], { replaceUrl: true });
        }

    }

    setCustomerTourData() {
        const tourStaticSlides = this.tourService.customerTourData.filter((e) => !e.isMenuSlide);
        const tourDyanamicSlides = this.tourService.customerTourData.filter((e) => e.isMenuSlide);
        const tempData = tourDyanamicSlides.reduce((accum, el) => {
            const itemFound = this.navItems.reduce((acc, navEl) => {
                if (navEl.items.length > 0) {
                    const item = navEl.items.find((e) => el.label.toLowerCase() === e.label.toLowerCase());
                    if (item) {
                        el['routerLink'] = item.link;
                        acc.push(el);
                    }
                } else {
                    if (navEl.label.toLowerCase() === el.label.toLowerCase()) {
                        el['routerLink'] = navEl.link;
                        acc.push(el);
                    }
                }
                return acc;
            }, []);
            accum = [...accum, ...itemFound];
            return accum;
        }, []);

        const tourData = [...tourStaticSlides, ...tempData];
        this.tourService.customerTourData = tourData.map((e, i) => {
            e.id = i + 1;
            e.link = i < tourData.length - 1 && tourData[i + 1].routerLink ? tourData[i + 1].routerLink : '';
            return e;
        });
    }
}
