import { Component, OnInit } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'app-attachments-model',
    templateUrl: './attachments-model.component.html',
    styleUrls: ['./attachments-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class AttachmentsModelComponent implements OnInit {

  public currentUrl:any;
  constructor() { }

  ngOnInit() {
    this.currentUrl = location.origin;
  }

}
