<div class="thankyou_page">
  <div class="thankyou-page-wrapper">
    <div class="thankyou-page-inner">
        <div class="image-outer-box">
            <h1 class="display-3">Thank You</h1>
            <div class="img-wrapper">
                <img src="assets/images/background/centerlayer.png">
            </div>
        </div>
        <div class="content-box">
          <p class="thankYouText tw-mt-4">{{message}}</p>
          <div class="homeBtn" *ngIf="!hideGoToHomeButton">
            <a name="homePage" class="btn btn-primary" href="{{domain}}" role="button" *ngIf="!isLive">Continue to
              Homepage</a>
            <a name="homePage" class="btn btn-primary" [routerLink]="['/login']" role="button"
              *ngIf="isLive">Continue to Homepage</a>
          </div>
        </div>
    </div>
    <div class="footer-box-body">
      <div> © <a href="https://www.utiliko.io/" target="_blank">Utiliko - Business Management Platform.</a>All rights reserved.
      </div>
    </div>
  </div>
</div>