<!-- <div class="api-docs">
    <section class="content-header">
        <div class="row">
          <div class="col-md-6">
              <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/api-docs">
                <h1 class="content-header-name"><mat-icon>arrow_back_ios</mat-icon>Back to API Docs</h1>
              </a>     
          </div>
        </div>          
    </section>
    </div> -->
<div class="api-docs-back-btn">
<section class="content-header">
<button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
      <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
        <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
    </a>    
</button>
</section>
</div>

  <mat-card>
      <div class="api-docs">
  <h2 class="content-header-name">Introduction</h2>
  <p>This API docs will be useful for developers to understand the flow of API. 
    This is a complete reference for developers which contains flow chart, 
    each API description, request and response formats.</p> 

    <h2 class="content-header-name">Requirements</h2>
    <h2 class="content-header-name">Folder structure</h2>
    <h2 class="content-header-name">Packages</h2>
    <div class="highlight"></div>
    </div>
    </mat-card>
