
<div class="api-docs-back-btn">
   <section class="content-header">
     <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
           <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
             <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
         </a>    
     </button>
   </section>
 </div>

<mat-card>
   <div class="api-docs">
<h2 class="content-header-name">Commission Calculation Model</h2>
<p><i><code>dropdownList</code></i></p>
<ul>
  <li>
     This API will fetch the list of commissions from `tbl_commission_calculation`.
  </li>
  <li>
     For reference: <a href="{{path}}explorer/#!/commissionCalculation/commissionCalculation_dropdownList">
      {{path}}explorer/#!/commissionCalculation/commissionCalculation_dropdownList</a>
  </li>
  </ul>
</div>
  </mat-card>
  