
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Bills Model</h2>
<p> <code><i>Add</i></code></p>
<ul>
  <li>
      This API is used to create a bill for the list of purchase order by vendorId.
  </li>
</ul>

<figure>
    <img src='../../../../assets/docs/fig1.png' alt='missing' class="images"/>
    <figcaption>Fig 1: To validate the request and generate the billNo and checkNo</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig2.png' alt='missing' class="images" />
    <figcaption>Fig 2: If credit is not applied the above iteration will be executed</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig3.png' alt='missing' class="images" />
    <figcaption>Fig 3: If credit is applied the above iteration will be executed</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig4.png' alt='missing' class="images" />
    <figcaption>Fig 4: updatePaymentsForAddBills method flow</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig5.png' alt='missing' class="images" />
    <figcaption>Fig 5: updatePOForGreaterCreditAmount method flow</figcaption>
</figure>
<br />
<ul>
  <li>
    For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_add" >
      {{currentUrl}}explorer/#!/Bills/Bills_add</a>
  </li>
</ul>


<p> <code><i>listByVendor</i></code></p>
<ul>
  <li>
      This API is used to fetch the bill list by vendorId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_listByVendor" >
        {{currentUrl}}explorer/#!/Bills/Bills_listByVendor</a>
    </li>
</ul>

<p> <code><i>Refund</i></code></p>
<ul>
  <li>
      This API is used to refund the bill amount of a vendor.
  </li>
  <li>
      For more Info, refer the flowchart below.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_refund" >
        {{currentUrl}}explorer/#!/Bills/Bills_refund</a>
    </li>
</ul>

<br />
<figure>
    <img src='../../../../assets/docs/fig6.png' alt='missing' class="images" />
    <figcaption>Fig 1: refund API from vendor, purchase order and bills</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig7.png' alt='missing' class="images" />
    <figcaption>Fig 2: updateOrCreateRefundModel method flow</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig8.png' alt='missing' class="images" />
    <figcaption>Fig 3: updateRefundDetForRefundType method flow</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig9.png' alt='missing' class="images" />
    <figcaption>Fig 4: updateVendorCreditAmount method flow</figcaption>
</figure>
<br />
<figure>
    <img src='../../../../assets/docs/fig10.png' alt='missing' class="images" />
    <figcaption>Fig 5: updatePurchaseOrderTableForRefund method flow</figcaption>
</figure>


<p> <code><i>billsToPay</i></code></p>
<ul>
  <li>
      This API will fetch the list of bills for each purchase order which are not paid fully( ie.,
      tbl_purchase_order.bill_status != 1 and tbl_purchase_order.status != 'Paid_In_Full').
  </li>
  <li>
      This API is used in dashboard module.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_billsToPay" >
        {{currentUrl}}explorer/#!/Bills/Bills_billsToPay</a>
    </li>
</ul>

<p> <code><i>List</i></code></p>
<ul>
  <li>
      This API is used to fetch the list of bills which satisfies the following conditions. 
  </li>
  <li>
      `tbl_vendor`.vendor_id = `tbl_bills`.vendor_id and <br />
      `tbl_purchase_order`.purchase_order_id = `tbl_bills`.po_id 
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_list" >
        {{currentUrl}}explorer/#!/Bills/Bills_list</a>
    </li>
</ul>

<p> <code><i>Details</i></code></p>
<ul>
  <li>
      This API is used to fetch the details of bill by billId from `tbl_bills` and `tbl_vendor`.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_details" >
        {{currentUrl}}explorer/#!/Bills/Bills_details</a>
    </li>
</ul>

<p> <code><i>getPOListByBillNo </i></code></p>
<ul>
  <li>
      This API is used to fetch the purchase order list by using billNo as transactionId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Bills/Bills_getPOListByBillNo" >
        {{currentUrl}}explorer/#!/Bills/Bills_getPOListByBillNo</a>
    </li>
</ul>
</div>
</mat-card>
