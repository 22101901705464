export enum SoftphoneStatus {
    NO_DEVICE,
    NOT_CONNECTED,
    NOT_REGISTERED,
    ANOTHER_REGISTRATION_FOUND,
    DISCONNECTED,
    STAND_BY,
    CALLING,
    CALL_IN_PROGRESS,
    ADD_CALL,
    TRANSFER_CALL,
    VOICEMAIL_TRANSFER_CALL,
    ATTENDED_TRANSFER,
    CALL_TAGGING,
    FORCED_CALL_TAGGING,
    SEND_DTMF,
    INCOMING_CALL,
    SETTINGS,
    TRANSFERS_MENU,
    ATTENDED_TRANSFER_IN_PROGRESS,
}
