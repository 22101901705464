import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from "../../../../../service/constant";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient
  ) { }

  search(searchvalue, type , start , limit) {
    return this.http.get(API.GLOBAL_SEARCH + "?searchvalue=" + searchvalue + "&type=" + type +"&start="+ start+"&limit="+limit);
  } 
}
