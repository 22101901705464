import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API, StorageKey } from './constant';
import { Observable, Subject } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(private http: HttpClient) { }
  setup(setupData){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    // let body = JSON.stringify(setupData);
    return this.http.post(API.TENANT_SETUP, setupData, httpOptions);
  }
}
