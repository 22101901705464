import { Component } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { LoginService } from '../service/login.service';
import { FullComponent } from './layouts/full/full.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
    standalone: true,
    imports: [NgClass, ExtendedModule, FullComponent]
})
export class AdminComponent {
  // theme = 'theme';
  accessToken = localStorage.getItem('accessToken');
  constructor(private router: Router,public loginService: LoginService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // (<any>window).ga('send', 'pageview');
      }
    });
  }
}
