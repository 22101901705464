
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">PinAction Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API will add create and update the pinaction(<strong>add to the TODO list</strong>) for bugs, invoices, estimates, project, tasks etc., using moduleId(<strong>ie. opportunities_id, estimates_id, tickets_id,task_id, bug_id, project_id or leads_id</strong>) and moduleType(<strong>ie. opportunities, estimates, tickets, bugs,task, project or leads (all types are case sensitive)</strong>) into `tbl_pinaction`. 

     </li>
     <li>
            If moduleId exists, create pinaction else update pinaction. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Pinaction/Pinaction_add"><u>{{path}}explorer/#!/Pinaction/Pinaction_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the pinaction(<strong>remove from TODO list</strong>) from `tbl_pinaction` by moduleId and moduleType. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Pinaction/Pinaction_delete"><u>{{path}}explorer/#!/Pinaction/Pinaction_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getBugDetails, getEstimatesDetails, getInvoiceDetails, getLeadDetails, getOpportunitiesDetails, getProjectDetails, getPurchaseOrderDetails, getTaskDetails, getTicketDetails</code></i></p>
     
     <ul>
         <li>
                These APIs will fetch the list of pinned details of each module(<strong>opportunities, estimates, tickets, bugs,task, project or leads</strong>) from `tbl_pinaction` and the respective table of that module(<strong>leads - </strong>`tbl_leads`, <strong>project  - </strong>`tbl_project`, <strong>bugs - </strong>`tbl_bug`, <strong>task- </strong>`tbl_task` etc.,). 

         </li>
         <li>
                For reference: 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getBugDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getBugDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getEstimatesDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getEstimatesDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getInvoiceDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getInvoiceDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getLeadDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getLeadDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getOpportunitiesDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getOpportunitiesDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getProjectDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getProjectDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getPurchaseOrderDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getPurchaseOrderDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getTaskDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getTaskDetails</u></a> 
                <a href="{{path}}explorer/#!/Pinaction/Pinaction_getTicketDetails"><u>{{path}}explorer/#!/Pinaction/Pinaction_getTicketDetails</u></a> 
            <br />  

         </li>
     </ul>
 
     <p><i><code>getPinnedDetailsCount</code></i></p>
     <ul>
         <li>
                This API will fetch the count of pinned records of each module from `tbl_pinaction`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Pinaction/Pinaction_getPinnedDetailsCount"><u>{{path}}explorer/#!/Pinaction/Pinaction_getPinnedDetailsCount</u></a> 

         </li>
     </ul> 
     </div>
</mat-card>
