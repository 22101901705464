 
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Project Reason Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create new project pipeline stage into `tbl_project_state_reason` by providing <strong>projectPipelineStageName</strong> and <strong>projectPipelineId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_add"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_add</u></a> 

     </li>
 </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API will delete the project pipeline stage from `tbl_project_state_reason` by <strong>projectStateReasonId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_delete"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_delete</u></a> 

       </li>
   </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of project pipeline stages from `tbl_project_state_reason`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_list"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                It is used to get the details of particular project pipeline stage from `tbl_project_state_reason` by <strong>projectStateReasonId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_listById"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p>
     <ul>
         <li>
                This API is used to modify the project stages into `tbl_project_state_reason` by <strong>projectStateReasonId</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_modify"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_modify</u></a> 

         </li>
     </ul> 
     <br />
     <p><i><code>orderPipelineStages</code></i></p> 
     <ul>
         <li>
                This API is used to change the order of project pipeline stages into `tbl_project_state_reason` by <strong>projectStateReasonId</strong> and <strong>stageList[&ldquo;stage1&rdquo;,&rdquo;stage2&rdquo;,&rdquo;stage3&rdquo;,..]</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_orderPipelineStages"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_orderPipelineStages</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>stagesByPipeId</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the list of project pipeline stages from `tbl_project_state_reason` by <strong>projectPipeId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_stagesByPipeId"><u>{{path}}explorer/#!/ProjectStateReason/ProjectStateReason_stagesByPipeId</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
