import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '../../../shared/AppComponentBase';
import { COMMON } from '../../../../service/constant';
import { ActivatedRoute } from '@angular/router';
import { RmaService } from '../../../../service/rma.service';
import { EStatusCode, IResponse } from '../../../../service/Utils/Interfaces.class';
import { TranslateModule } from '@ngx-translate/core';
import { AddressFormatPipe } from '../../../shared/pipes/address-format.pipe';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../shared/spinner.component';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-app-rma-view',
    templateUrl: './app-rma-view.component.html',
    styleUrls: ['./app-rma-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatTooltip, MatDivider, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, SpinnerComponent, CommonDatePipe, AddressFormatPipe, TranslateModule]
})
export class AppRmaViewComponent extends AppComponentBase implements OnInit {
  public version: string;
  public rmaId: any;
  public rmaDetList: any;
  public dataSource: any;
  public error: boolean = false;
  public showMessage: boolean = true;
  public themeData: string;
  public companyName: string;
  public path: any;
  public dataRma;
  public retType: string;
  public rmaAccepted: boolean = false;
  public rmaPending: boolean = false;
  public rmaRejected: boolean = false;
  public rmaStatus: string;
  public showDetail: boolean;
  public companyLogo: string;
  public show: boolean;

  constructor(inject: Injector, private route: ActivatedRoute, public rmaService: RmaService) { 
    super(inject);
    this.displayedColumns = ['item_name', 'item_desc', 'quantity', 'reason', 'requested_quantity'];
    this.version = COMMON.VERSION;
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    this.rmaId = this.route.snapshot.paramMap.get('id');
    this.getRmaDetails(this.rmaId);
    this.version = COMMON.VERSION;
    this.companyLogo = this.configService.companyLogo;
    this.companyName = this.configService.companyName;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
  }

  getRmaDetails(rmaId) {
    this.rmaService.getRmaViewDetails(rmaId).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        const str = JSON.stringify(res.data);
        this.rmaDetList = JSON.parse(str);
        this.getCityStateZipValues();
        this.dataSource = res.data.items;
        if (this.rmaDetList.returnType == 3) {
          this.retType = "Exchange";
        } else if (this.rmaDetList.returnType == 1) {
          this.retType = "Credit";
        } else if (this.rmaDetList.returnType == 2) {
          this.retType = "Refund";
        }
        if (this.rmaDetList.status == 0) {
          this.rmaPending = true
          this.rmaRejected = false;
          this.rmaAccepted = false;
          this.rmaStatus = "Pending";
        } else if (this.rmaDetList.status == 1) {
          this.rmaStatus = "Rejected";
          this.rmaPending = false;
          this.rmaAccepted = false;
          this.rmaRejected = true;
        } else if (this.rmaDetList.status == 2) {
          this.rmaStatus = "Accepted";
          this.rmaAccepted = true;
          this.rmaRejected = false;
          this.rmaPending = false;
        } else if (this.rmaDetList.status == 3) {
          this.rmaStatus = "Received";
          this.rmaAccepted = true;
          this.rmaRejected = false;
          this.rmaPending = false;
        } else if (this.rmaDetList.status == 4) {
          this.rmaStatus = "Completed";
          this.rmaAccepted = true;
          this.rmaRejected = false;
          this.rmaPending = false;
        }
      } else {
        this.showDetail = true;
        this.error = true;
        this.toastr.error('RMA has been deleted, you are unable to make any changes.');
      }

    })
  }

  getClassByStatus(){
    if (this.rmaDetList.status == 0) {
      return 'warningButton';
      } else if (this.rmaDetList.status == 1) {
      return 'dangerButton';
    } else if (this.rmaDetList.status == 2) {
      return 'successButton';
    } else if (this.rmaDetList.status == 3) {
      return 'successButton';
    } else if (this.rmaDetList.status == 4) {
      return 'successButton';
    }
  }

  getCityStateZipValues() {
    this.dataRma = {
      city: this.rmaDetList.billingCity,
      state: this.rmaDetList.billingState,
      zip: this.rmaDetList.billingZipCode,
    };
  }

  downloadPDF(rmaId, clientName) {
    var obj = {
      companyLogo: this.companyLogo,
      companyName: this.companyName,
      companyAddress: this.configService.companyAddress + ',' + this.configService.companyCity + ',' + this.configService.companyState ? this.configService.companyState + ',' : '' + this.configService.companyZipCode + ',' + this.configService.companyCountry,
      phone: this.configService.companyPhone,
      website: this.configService.companyWebsite ? this.configService.companyWebsite : this.configService.companyDomain,
      email: this.configService.companyEmail,
      clientAddress: this.dataRma,
    }
    this.rmaService.getRmaPdfCV(rmaId, clientName, obj).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        if (res.data) {
          this.path = res.data.filename;
          window.open(res.data[0].url);
        }
      }
    });
  }

  printDetail(rmaId,clientName) {
    var filename = 'client_' + clientName.toLowerCase() + '_rma_' + rmaId;
    this.show = true;
    var innerContents = document.getElementById('print').innerHTML;
    this.show = false;
    var popupWinindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><title>'+filename+'</title><style>.page-footer {left: 238px;right: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;}' +
      '.inv-sub-header{padding: 10px;font-weight: bold;padding-left: 20px;color: black;text-align: right;margin-top: 60px}.inv-sub-main-content {background-color: #f3f4f6;margin-top: 10px;}' +
      '.headerName {display: inline-block;text-align: center;\n' + 'width: 90%;\n' + 'position: relative;\n' + 'bottom: 25px;\n' +
      'text-transform: capitalize;}.inv-sub-detail-content{background-color: white;min-height: 530px;color: black;font-weight: 600;}.light-font{font-weight: lighter;}.tag-btn {font-size: 12px;font-weight: 500;border-radius: 3px;padding: 0px 5px !important;background-color: transparent;text-transform: uppercase;}' +
      '.pull-left {float:left;}.col-md-3 {width: 25%;}.addressAlign{float: left!important;width: 25%;margin-top: 5px;}.clearfix{width:100% margin-bottom: 5px;}.statusAlign{width: 35%;float: right!important;margin: 10px 10px 0 0;}.lightFont{font-weight: lighter;margin-top: 5px;}' +
      '.DateRight{float: right;margin: 20px -5px 6px 0;}.inv-line {border-top: solid 2px darkgreen;}.inv-foot-detail {width: 100%;min-height: 200px;display: inline-block;padding-right:5px}.inv-notes{border: solid 1px #c9c9c9;max-height: 250px;min-height: 216px;\n' +
      '\tpadding: 10px;font-weight: lighter;overflow-y: auto;}.left-notes{border: solid 1px #c9c9c9;max-height: 168px;min-height: 168px;padding: 10px;font-weight: lighter;overflow-y: auto;}' +
      '.inv-payment-detail{width: 33%;float: right;display: inline-block;}.inv-payment-sub-detail{border: solid 2px #c9c9c9;}.left-panel{display: inline-block;width:calc(100% - 135px);border-bottom: solid 1px #c9c9c9;border-right: solid 1px #c9c9c9;\n' +
      '\t\tpadding: 10px;font-weight: lighter;}.right-panel{display: inline-block;width: 96px;margin-left: -3px;text-align: right;padding: 10px;border-bottom: solid 1px #c9c9c9;word-break: break-all;\n' +
      '\t\tbackground-color: #f1f5f8;-webkit-print-color-adjust: exact;}.baldue{border-bottom: none;color: #fa5858}.baldueright{border: none;color: #fa5858}.themeColor{-webkit-print-color-adjust: exact;height: 60px;-webkit-print-color-adjust: exact;padding-bottom: 15px}' +
      '.footer{position: fixed;bottom: 0;width: 100%;height: 90px;margin-bottom: 10px}.page{margin: 0 15px;font-size: 14px;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.bottomFooter{text-align: left; width: 33.33333333%; float: left;}.leftFooter{margin: 0 0 10px;}.rightFooter{text-align: right;width: 33.33333333%;float: left;}.centerFooter{width: 33.33333333%;float: left;}hr{width: 100%;}.notes{width:62%;padding-left: 15px;display: inline-block;}' +
      '.tableHead{width: calc(100% - 30px);margin-left: 15px;box-shadow: none;font-weight: 600;border-spacing: 0px}.tableRow{height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left; padding-left: 15px; color: #000000de; font-size: 12px; font-weight: 400;}.tableColumn{font-size:14px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left; padding-left: 15px; color: #000000de;font-family: "Open Sans", sans-serif;height: 48px;font-weight: 500 !important;}.inv-item-table{padding: 5px;}' +
      '.nameAlign{display: inline-block}.clientAlign{display: inline-block;margin: 16px 0;}.clearfix{clear: both}.border{border: 2px solid black}.waterMark{text-align: center;position: relative;top: 85px;height: 0;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.styleWtareMarkRed{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: red;color: red;}' +
      '.f-r {float:right;}.successButton {color:rgb(0, 150, 60);border:1px solid rgb(0, 150, 60);}.dangerButton{color:rgb(200, 16, 16);border:1px solid rgb(200, 16, 16);}.invdate{padding: 10px 0px;padding-bottom: 3px;font-size: 14px}.styleWtareMarkGreen{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: green;color: green;}' +
      '.page-footer, .page-footer-space {height: 50px;.page-footer {position: fixed;bottom: 0;width: 100%;border-top: 1px solid black;background: yellow;}@media print {tfoot {display: table-footer-group;}button {display: none;} body {margin: 0;}}' +
      '</style><script>function myFunction() {window.close();}</script></head><body onafterprint="myFunction()" onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

}
