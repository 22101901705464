import { Component, OnInit } from '@angular/core';
import { AppsLayoutsComponent } from './apps-layouts/apps-layouts.component';
import { SubHeaderComponent } from '../layouts/full/sub-header/sub-header.component';

@Component({
    selector: 'app-integrations-apps',
    templateUrl: './integrations-apps.component.html',
    styleUrls: ['./integrations-apps.component.scss'],
    standalone: true,
    imports: [SubHeaderComponent, AppsLayoutsComponent]
})
export class IntegrationsAppsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
