
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Invoice Model</h2>

<h4>Commonly used methods in invoice:</h4>
<ul>
    <li>
            generateReferenceNo method is used to fetch the last created invoiceId.
    </li>
    <li>
            invoiceReferenceNoExists method will create new invoice reference number(unique) with invoice_prefix from `tbl_config` and last created invoiceId+1. If referenceNo exists, it will generate new number else it will return the generated refNo. 
    </li>
    <li>
            getInvoiceAllData is used to get the invoice amount details includes tax, discount, totalAmount, due etc., by calling calculateTo method.
    </li>
    <li>
            getCOAInfoForClientInvoices method will fetch the invoice item taxes from `tbl_items`, `tbl_saved_items` and `tbl_tax_rates` through iteration. 
    </li>
    <li>
            chkPaymentFullyPaidWithCcOrAch method will fetch the payment details of an invoice by invoiceId, refundApplied : 0 and paymentMethodId. 
    </li>
</ul>
<br />  
  <p><i><code>Add </code></i></p> 
 <ul>
     <li>
            This API is used to create new invoice into `tbl_invoices` with clientId. 
     </li>
     <li>
            Before creating an invoice generateReferenceNo and invoiceReferenceNoExists method will be called. 

     </li>
     <li>
            Once the invoice created successfully, steps below will be executed: 
        <ul>
            <li>
                    If recurringFrequency exists, getRecuringFrequency method will be called to update the recurring data into `tbl_invoices` by created invoiceId. 

            </li>
            <li>
                    getRecuringFrequency - method will return the recurring data for the invoice with recurringFrequency by getCalculateRecurringDays method. 

            </li>
        </ul>
     </li>
     <li>
            If items present for the created invoice, it will execute the steps below: 
        <ul>
            <li>
                        If recurringFrequency exists, it will fetch the item details from `tbl_saved_items` by saved_items_id (returns itemInfo). 
            </li>
            <li>
                    If itemInfo exists, totalSalesPrice value updated into `tbl_chart_of_accounts`. 
            </li>
            <li>
                    Once COA is updated, items for the created invoice will be saved in `tbl_items` . 
            </li>
            <li>
                    Finally, Invoice due amount will be calculated using calculateTo method. If result amount is 0, invoice status(<strong>Fully Paid</strong>) will be updated into `tbl_invoices`. 
            </li>
        </ul>    
    </li>
    <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_add"><u>{{path}}explorer/#!/Invoice/Invoice_add</u></a> 
    </li>
 </ul>
 <br />  
  <p><i><code>addSubscriptionInvoice </code></i></p> 
 <ul>
     <li>
            This API is used to create the new invoice while doing subscription. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_addSubscriptionInvoice"><u>{{path}}explorer/#!/Invoice/Invoice_addSubscriptionInvoice</u></a> 
     </li>
 </ul>
 <br /> 
  <p><i><code>allInvoiceAmount </code></i></p> 
 <ul>
     <li>
            This API is used to fetch the totalAmount of all invoices from `tbl_invoices` using. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_allInvoiceAmount"><u>{{path}}explorer/#!/Invoice/Invoice_allInvoiceAmount</u></a> 
     </li>
 </ul>
 <br />  
  <p><i><code>allInvoiceList </code></i></p> 
 <ul>
     <li>
            This API is used to fetch the invoice list with filter(optional). 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_allInvoiceList"><u>{{path}}explorer/#!/Invoice/Invoice_allInvoiceList</u></a> 
     </li>
 </ul>
 <br />  
  <p><i><code>batchEmail </code></i></p> 
 <ul>
     <li>
            This API is used to send an invoice overdue reminder email for more than one invoiceId through iteration using <strong>shootEmail</strong> method. 

     </li>
     <li>
            The templates values have been fetched from `tbl_config` by <strong>configKey</strong>:<strong>'company_name'</strong>and from `tbl_email_templates` by <strong>emailGroup</strong>:<strong>'inform_client_invoice_overdue'</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_batchEmail"><u>{{path}}explorer/#!/Invoice/Invoice_batchEmail</u></a> 

     </li>
 </ul>
<br />

  <p><i><code>calculateTo </code></i></p> 
 <ul>
     <li>
            This API is used, if the module name is invoice, purchase order or estimate. <br />
            <br />
            <br />
            <mat-card>
            <figure >
                    <img src="../../../../assets/docs/fig11.png">
            </figure>
              </mat-card>
     </li>
     <br />
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_calculateTo"><u>{{path}}explorer/#!/Invoice/Invoice_calculateTo</u></a> 
     </li>
 </ul>
 <br />  
  <p><i><code>Clone </code></i></p>
 <ul>
     <li>
            This API is used clone the existing invoice into `tbl_invoices` with clientId. 
     </li>
     <li>
            The process of cloning an invoice is same as creating new invoice with new invoiceId and clientId from request parameter. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_clone"><u>{{path}}explorer/#!/Invoice/Invoice_clone</u></a> 
     </li>
 </ul> 
 <br />  
  <p><i><code>Delete </code></i></p>
 <ul>
     <li>
            This API is used to delete an invoice and the items related to that invoiceId from `tbl_invoices` and `tbl_items`. 
     </li>
     <li>
            If the invoice is converted from the estimate, it will update <strong>convertedInvoiceId</strong>: <strong>null</strong> for that invoiceId into `tbl_estimates`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_delete"><u>{{path}}explorer/#!/Invoice/Invoice_delete</u></a> 
     </li>
 </ul> 
 <br />  
  <p><i><code>Details </code></i></p> 
<ul>
    <li>
            This API is used to fetch the invoice details by invoiceId from `tbl_invoices` using getInvoiceDetails method. 

    </li>
    <li>
            This method will be called while creating or updating the invoice. 
    </li>
    <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_details"><u>{{path}}explorer/#!/Invoice/Invoice_details</u></a> 
    </li>
</ul>
 <br />  
  <p><i><code>Email </code></i></p> 
 <ul>
     <li>
            This API is used to send an invoice through email by type of invoice. 
     </li>
     <li>
            3 types of invoice email - &lsquo;overDue&rsquo;, &lsquo;reminder&rsquo; and &lsquo;email&rsquo; invoice. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_email"><u>{{path}}explorer/#!/Invoice/Invoice_email</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>invoiceDetailPdf </code></i></p>
 <ul>
     <li>
            This API is used to generate the detail page of an invoice in pdf format using  <i><code><strong>require</strong> </code></i>(<strong>'html-pdf'</strong>). 
     </li>
     <li>
            Eg: https://storage.googleapis.com/milagro-test/vivant%2FInvoice%2FE%3A%2FUtiliko%2Futiliko_API%2F-%2Fclient%2FManjula%20test%2Finvoice%2F%2F1560750560619-manjula%20test_invoice_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4671150564&amp;Signature=AcHtc%2F74FsMbBvXpf9Bs2RUAG3kante6W9t37Rc5DIsktXigXqWw2%2F8r62D0ztDJhbTrjakxbqrFBFqUOq3rLZZckNFzc2q%2FUucCKa10SpqXU9aksP%2Bh7kW40CimJFBLjn%2B7MJHo%2BMwpdqDtiRxVacQUYiTpS%2FJJxem2jrq2dRT3NTI310QB4cuEC4%2FhAPt46YHkXj1LhMoVku0yRQwOpp4ynCiHgvqEedTW52FTwVwa9R3ZE3wdcdiQkZrurGT8tjx7Erms6DVq%2FF8MblhKFOdMwZPI%2BeBQwWcuC3KlaCzVRR1PTOiR1X7OScykQcNNLiapAgPNvfGt7w4cd3TyVA%3D%3D 

     </li>
     <li>
            Template for the invoice detail page will be fetched from the `tbl_email_templates` using emailGroup: &lsquo;<strong>invoice_detail_pdf</strong>&rsquo;. 
     </li>
     <li>
            uploadFilesTOCloud method is used here. 
     </li>
     <li>
            Fs.unlink method will delete the file from the local path once it has been uploaded into cloud storage. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_invoiceDetailPdf"><u>{{path}}explorer/#!/Invoice/Invoice_invoiceDetailPdf</u></a> 
     </li>
 </ul> 
 <br />  
  <p><i><code>List </code></i></p> 
 <ul>
     <li>
            This API will fetch the invoice list by moduleId(Enter id like clientId, projectId, ticketId) from `tbl_invoices`. 

     </li>
     <li>
            getInvoiceAllData method is used here for each invoice through iteration. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_list"><u>{{path}}explorer/#!/Invoice/Invoice_list</u></a> 
     </li>
 </ul>
 <br />
  <p><i><code>Modify</code></i></p> 
 <ul>
     <li>
            This API is used to modify the invoice details by <i><code>invoiceId </code></i> and  <i><code>clientId </code></i>. 
     </li>
     <li>
            Process of updating invoice is same as like add invoice. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_modify"><u>{{path}}explorer/#!/Invoice/Invoice_modify</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>paymentForRefund </code></i></p> 
 <ul>
     <li>
            This API is used to fetch payment details of an invoice for refund while deleting an invoice by invoiceId and clientId. 

     </li>
     <li>
            API will be called, if invoice status is <strong>'Fully-Paid'</strong> or <strong>'Partially-Paid'</strong>. 
     </li>
     <li>
            payment details will be fetched from `tbl_payments` and refund amount will be fetched from `tbl_refunds`. 
     </li>
     <li>
            To check whether payment is fully paid, chkPaymentFullyPaidWithCcOrAch method will be called. 
     </li>
     <li>
            chkPaymentFullyPaidWithCcOrAch: 
     </li>
     <li>
            getInvoicePaidAmount method is used here(refer invoiceHelper.js). 
     </li>
     <li>
            If paidAmount and cost amount is same, invoice is fully paid else it is not fully paid.
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_paymentForRefund"><u>{{path}}explorer/#!/Invoice/Invoice_paymentForRefund</u></a> 
     </li>
 </ul>
 <br />  
  <p><i><code>recentlyPaidInvoices </code></i></p>
 <ul>
     <li>
            This API will fetch the list of paid invoices if the below condition satisfied. 
     </li>
     <li>
            <strong>tbl_payments.status = 'APPROVED' AND (tbl_payments.refund_status != 'APPROVED' OR tbl_payments.refund_status IS NULL)</strong> 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_recentlyPaidInvoices"><u>{{path}}explorer/#!/Invoice/Invoice_recentlyPaidInvoices</u></a> 
     </li>
 </ul> 
 <br />  
  <p><i><code>refundFromCCorACH </code></i></p> 
 <ul>
     <li>
            This API is used to refund the received payment of an invoice if payment method is credit card or ACH. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_refundFromCCorACH"><u>{{path}}explorer/#!/Invoice/Invoice_refundFromCCorACH</u></a> 
            <br />
            <br />
            <mat-card>
            <figure class="images">
                    <img src='../../../../assets/docs/fig12.png' alt='missing' />
                    <br />
                    <figcaption>Fig 1: refundFromCCorACH API flow</figcaption>
            </figure>
            </mat-card>
            <mat-card>
            <br />
            <figure class="images">
                    <img src='../../../../assets/docs/fig13.png' alt='missing' />
                    <br />
                    <figcaption>Fig 2: getTaxUpdate method flow</figcaption>
            </figure>
            </mat-card>
     </li>
 </ul>
 <br />  
  <p><i><code>requestRefundFromInvoiceDelete </code></i></p> 
 <ul>
     <li>
            This API is used to fetch the refund status of a particular invoice by invoiceId. 
     </li>
     <li>
            chkPaymentFullyPaidWithCcOrAch is used here. 
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_requestRefundFromInvoiceDelete"><u>{{path}}explorer/#!/Invoice/Invoice_requestRefundFromInvoiceDelete</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>saveRefund </code></i></p> 
 <ul>
     <li>
            This API is used to refund the invoice from client/invoice/receivePayments. 
     </li>
     <li>
            For more info about this API refer the below flowchart. 
     </li>
     <br />
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_saveRefund"><u>{{path}}explorer/#!/Invoice/Invoice_saveRefund</u></a> 
            <br />
            <br />
            <mat-card>
            <figure class="images">
                    <img src='../../../../assets/docs/fig14.png' alt='missing' />
                    <br />
                    <figcaption>Fig 1: saveRefund API flow</figcaption>
            </figure>
            </mat-card>
            <br />
            <mat-card>
            <figure class="images">
                    <img src='../../../../assets/docs/fig15.png' alt='missing' />
                    <br />
                    <figcaption>Fig 2: Step1 - refundFromClient or refundFromVendor</figcaption>
            </figure>
            </mat-card>
            <br />
            <figure class="images">
                    <img src='../../../../assets/docs/fig16.png' alt='missing' />
            </figure>
    </li>
 </ul>
<br />
  <p><i><code>Status </code></i></p> 
 <ul>
     <li>
            This API is used to change the status of an invoice by invoiceId if exists. 

     </li>
     <li>
            3 types of status: `hide`, `show` and `recur`. 

     </li>
     <li>
            If status = `hide` - <strong>'showClient'</strong>: <strong>'No'</strong> else if status = `hide` - <strong>'showClient'</strong>: <strong>'Yes'</strong> else if status = `recur` - recurring data will be updated into `tbl_invoices`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_status"><u>{{path}}explorer/#!/Invoice/Invoice_status</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>View </code></i></p>
 <ul>
     <li>
            This API is used to fetch the invoice details from `tbl_invoices` by invoiceId for view page. 
     </li>
     <li>
            getInvoiceAllData, getItemsData is used to fetch the amount details and item details from `tbl_invoices` and `tbl_items`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Invoice/Invoice_view"><u>{{path}}explorer/#!/Invoice/Invoice_view</u></a> 
     </li>
 </ul> 
 </div>
</mat-card>



