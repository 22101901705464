
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Tenant Companies Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new company into `tbl_tenant_companies`. 

     </li>
     <li>
            To create the new company, logged in user details should be present in the `tbl_tenant_companies`, `tbl_tenants` and `tbl_users`. 

     </li>
     <li>
            Once tenant/company is created successfully, default values will be created for that tenant using companySetup(refer myModel.js) method and activation email will be sent to logged in user using sendMail method. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_add"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_add</u></a> 

     </li>
 </ul>
 <br />  
    <p><i><code>addSeats</code></i></p> 
    <ul>
        <li>
                This API is used to add a number of users for particular company by adding the seats into `tbl_tenant_companies` by providing <strong>No of Seats to add</strong>. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_addSeats"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_addSeats</u></a> 

        </li>
    </ul>
 <br />  
     <p><i><code>companyDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the company details of the logged in user from `tbl_tenant_companies` by tenantCompanyKey. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_CompanyDetails"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_CompanyDetails</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Details</code></i></p> 
     <ul>
         <li>
                This API will fetch the particular company details from `tbl_tenant_companies` by companyId and tenantKey. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_details"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_details</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>domainExists</code></i></p> 
     <ul>
         <li>
                This API will check whether domain is exists or not in `tbl_tenant_companies`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_domainExists"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_domainExists</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getSeats</code></i></p>
     <ul>
         <li>
                This API is used to fetch the reserved seats of logged in user from `tbl_tenant_companies`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_getSeats"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_getSeats</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch list of companies exists for the tenantKey in `tbl_tenant_companies`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_list"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>removeSeats</code></i></p> 
     <ul>
         <li>
                This API will remove the number of seats(users) of particular company from `tbl_tenant_companies` by providing <strong>No of Seats to delete</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_removeSeats"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_removeSeats</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Subscription</code></i></p> 
     <ul>
         <li>
                This API will update the subscription plan values into `tbl_tenant_companies` by tenantCompanyKey. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TenantCompanies/TenantCompanies_subscription"><u>{{path}}explorer/#!/TenantCompanies/TenantCompanies_subscription</u></a> 

         </li>
     </ul>
     </div>
</mat-card>