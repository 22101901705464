
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">MailBox Model</h2>
  <p><i><code>deleteMail</code></i></p> 
  <ul>
      <li>
            This API is used to delete the email from the mailbox list using imap configuration( <i><code><strong>require</strong></code></i>(<strong>'emailjs-imap-client'</strong>)) with the below steps. 

      </li>
      <li>
            Get the imap configuration details such as <strong>u.imap_host_name, u.imap_port, u.smtp_username, u.smtp_password</strong> from `tbl_users` by userId. 

      </li>
      <li>
            Before deleting the mail, imap will be authenticated with the provided host, port number, email address and password. 

      </li>
      <li>
            selectMailbox(path) is used to fetch the list of emails in the given path(Inbox, Draft etc.,). 

      </li>
      <li>
            Finally mail will be deleted using the <strong>imapClient</strong>.deleteMessages() by using path and id. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_deleteMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_deleteMail</u></a> 

      </li>
  </ul>
  <br />
   <p><i><code>getEmailSuggestions</code></i></p> 
   <ul>
       <li>
            This API is used to get the email suggestions when typing the email address using `get_email_suggestions` procedure. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_getEmailSuggestions"><u>{{path}}explorer/#!/Mailbox/Mailbox_getEmailSuggestions</u></a> 

       </li>
   </ul>
   <br />
   <p><i><code>getMailSignature</code></i></p> 
   <ul>
       <li>
            This API will fetch the signature of the user from `tbl_users` by userId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_getMailSignature"><u>{{path}}explorer/#!/Mailbox/Mailbox_getMailSignature</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>getRelatedMails</code></i></p>
   <ul>
       <li>
            This API is used to get the related emails of each module from `tbl_sent` by moduleId(<strong>opportunitiesId</strong>,<strong>leadsId</strong>, <strong>clientId</strong>, <strong>projectId</strong>, <strong>ticketsId</strong>). 

       </li>
       <li>
            After getting the related emails and count, message body of the email and getReadCount method will be called. 

       </li>
       <li>
            getReadCount : To get the read information like (<strong>openCount</strong>, <strong>firstSeen</strong>, <strong>lastSeen</strong>) using trackToken. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_getRelatedMails"><u>{{path}}explorer/#!/Mailbox/Mailbox_getRelatedMails</u></a> 

       </li>
   </ul> 
 <br />  
   <p><i><code>getScheduledMails</code></i></p>
   <ul>
       <li>
            This API is used to fetch the list of scheduled emails of the particular user from `tbl_sent` by userId, if the below condition satisfied. 

       </li>
       <li>
            <strong> 'schedule': 1, 'deleted':'No', 'scheduleTime':{{htmlStr}}</strong> 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_getScheduledMails"><u>{{path}}explorer/#!/Mailbox/Mailbox_getScheduledMails</u></a> 

       </li>
   </ul> 
 <br />  
   <p><i><code>getUserInfo</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the user information when composing the email using <strong>get_email_info(?)</strong> procedure. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_getUserInfo"><u>{{path}}explorer/#!/Mailbox/Mailbox_getUserInfo</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listMailBoxes</code></i></p> 
   <ul>
       <li>
            This API is used to get the list of mailbox(INBOX, [Gmail], All Mail, Drafts, Important, Sent Mail etc.,) details using imap configuration. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_listMailBoxes"><u>{{path}}explorer/#!/Mailbox/Mailbox_listMailBoxes</u></a> 

       </li>
   </ul>
 <br />   
   <p><i><code>listMessages</code></i></p> 
   <ul>
       <li>
            This API is used to fetch list of messages of particular mailbox using imap configurations. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_listMessages"><u>{{path}}explorer/#!/Mailbox/Mailbox_listMessages</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>readMail</code></i></p> 
   <ul>
       <li>
            This API is used to read/unread the particular email in imap by providing path(mailbox) and the id of that email/message. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_readMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_readMail</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>searchMail</code></i></p> 
   <ul>
       <li>
            This API is used to search and get the list of messages from the specified mailbox/path, query and fetchCount. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_searchMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_searchMail</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>sendMailboxMail</code></i></p> 
   <ul>
       <li>
            This API is used to send the email from mailbox using the form parameters. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_sendMailboxMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_sendMailboxMail</u></a> 
            <br />
            <br />
            <figure class="images">
                    <img src='../../../../assets/docs/fig17.png' alt='missing' />
            </figure>
       </li>
   </ul>
<br />
  <p><i><code>staredMail</code></i></p> 
  <ul>
      <li>
            This API is used to make the stared email by providing path, uid and isStared(<strong>true</strong>/<strong>false</strong>). 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_staredMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_staredMail</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>updateScheduledMail</code></i></p>
  <ul>
      <li>
            This API is used to update the details of scheduled emails into `tbl_sent` by sentId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Mailbox/Mailbox_updateScheduledMail"><u>{{path}}explorer/#!/Mailbox/Mailbox_updateScheduledMail</u></a> 

      </li>
  </ul> 
  </div>
</mat-card>
