import { Component, OnInit, Injector, OnDestroy, inject } from '@angular/core';
import { AppComponentBase } from "../../AppComponentBase";
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer'
import { Router, ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { EStatusCode } from '../../../../service/Utils/Interfaces.class';
import { COMMON } from '../../../../service/constant';
import { NgxUiLoaderService, NgxUiLoaderModule } from 'ngx-ui-loader';
import moment from 'moment';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProjectService } from '../../../../service/project.service';
import { TicketsService } from '../../../../service/tickets.service';
import { DocsignDialogPopup } from '../upload-docsign/docsign-dialog-popup';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../../spinner.component';
import { MatSuffix } from '@angular/material/form-field';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatCard } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass, NgFor, NgStyle } from '@angular/common';

// Date formats
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
    selector: 'app-client-docsign',
    templateUrl: './client-docsign.component.html',
    styleUrls: ['./client-docsign.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatToolbar,
        NgClass,
        ExtendedModule,
        MatButton,
        MatCard,
        PdfViewerModule,
        FormsModule,
        NgFor,
        NgStyle,
        MatInput,
        MatDatepickerInput,
        ReactiveFormsModule,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepicker,
        NgxUiLoaderModule,
        SpinnerComponent,
    ],
})


export class ClientDocsignComponent extends AppComponentBase implements OnInit, OnDestroy {

  public offset: any;
  public position: boolean = false;
  public getpdfdata: any;
  public isclientsign: boolean;
  public indexsign: number;
  public arryposition: any = [];
  public initialposition: any = [];
  public dateposition: any = [];
  public datposition: any = [];
  public dateArr: any = [];
  public lenArr: any = [];
  public offsetdesc: any;
  public descposition: any = [];
  public isclientdesc: boolean;
  public descArr: any = [];
  public deposition: any = [];
  public iniposition: any = [];
  public iniArr: any = [];
  public offsetini: any;
  public isclientInitial: boolean;
  public offsetdate: any;
  public isclientDate: boolean;
  public datevalue: any;
  public pdffileSrc = "../../../../../assets/joblog1.pdf";
  public isSrcLoaded = false;
  public isImage = true;
  public IsValue = true;
  public isInitialValue = true;
  public fontRule = {};
  public fontRuleIntial = {};
  public newinput: any[] = [];
  public issign: boolean;
  public docSignId: any;
  public docSignEncrptyId: any;
  public projectId: any;
  public themeData: string;
  public companyTemplate: string;
  public version: string;
  public merged: boolean = false;
  public fileURL: string;
  public date = new FormControl(moment());
  public newdesc: any[] = [];
  public isdesc: boolean = false;
  public isInitial: boolean = false;
  public boxposition = [];
  public isDate: boolean = false;
  public initial: any[] = [];
  public error: boolean = false;
  public adminsignatureImageCopy = "../../../../../assets/img/signature-with-a-pen.svg";

  constructor(inject: Injector, public route: Router, public projectService:ProjectService, public ticketsService:TicketsService) {
    super(inject);
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    const docSignId = this.docSignEncrptyId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDocsignData(docSignId);
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.companyTemplate = this.configService.companyTemplate;
    this.setWindowTitle('Utiliko Docsign');
  }

  // Getting data for particular docsign Id
  getDocsignData(data) {
    this.ngxLoader.startLoader('loader-docsign');
    this.projectService.getDocSignByID(data).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.pdffileSrc = res.data[0].pdfPath;
        this.projectId = res.data[0].projectId;
        this.docSignId = res.data[0].docSignId;
        this.boxposition = res.data[0].boxposition;
        this.deposition = res.data[0].descposition;
        this.iniposition = res.data[0].initialposition;
        this.datposition = res.data[0].date;
        this.getpdfdata = res.data[0];
        if (res.data[0].signlength !== 0) {
          for (var i = 0; i < res.data[0].signlength; i++) {
            this.newinput.push({
              id: i,
              selected: '',
              signatureImage: '',
              adminselected: '',
              adminsignatureImage: '',
            });
          }
        }
        if (this.getpdfdata.isOwner == 1 && res.data[0].signlength !== 0) {
          for (let i = 0; i < this.boxposition.length; i++) {
            this.lenArr.push(i);
          }
          this.isclientsign = true;
        }
        if (this.getpdfdata.isOwner == 1 && this.deposition.length !== 0) {
          for (let i = 0; i < this.deposition.length; i++) {
            this.descArr.push(i);
            this.newdesc.push({
              id: i,
              decs: '',
            });
          }
          this.isclientdesc = true;
        }
        if (this.getpdfdata.isOwner == 1 && this.iniposition.length !== 0) {
          for (let i = 0; i < this.iniposition.length; i++) {
            this.iniArr.push(i);
            this.initial.push({
              id: i,
              initial: '',
            });
          }
          this.isclientInitial = true;
        }
        if (this.getpdfdata.isOwner == 1 && this.datposition.length !== 0) {
          for (let i = 0; i < this.datposition.length; i++) {
            this.dateArr.push(i);
            this.isclientDate = true;
          }
        }
        this.ngxLoader.stopLoader('loader-docsign');
        this.fileURL = this.pdffileSrc.replace(/"/g, "'");
        this.isSrcLoaded = true;
      }
      else {
        this.toastr.error(res.data.responseMsg);
        this.error = true;
      }
    });

  }
  ngOnDestroy() {

  }
  //Merge the pdf and other option
  mergePdf() {
    // 
    let docSignId = this.docSignId;
    let projectId = this.projectId;
    var pdfcreate = new JsPDF;
    var data = document.getElementById('pdfSend');
    this.ngxLoader.startLoader('loader-docsign');
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new JsPDF('p', 'mm', 'a4', true);
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST')
      const formData: FormData = new FormData();
      const file = pdf.output('blob');
      var nowday = new Date();
      let filename = "docsign" + nowday.getTime() + ".pdf";
      formData.append("directory", "Utiliko Test/project/The Kartik Shetty/project attachments");
      formData.append("file", file, filename);
      this.ticketsService.uploadDocFile(formData).subscribe((res) => {
        if (res) {
          if (this.getpdfdata.isOwner === 1) {
            this.projectService.updateDocSign(projectId, (<any>res).data.list[0].url, docSignId).subscribe((res2: any) => {
              if (res2.info.code === EStatusCode.OK) {
                this.toastr.success("Pdf Merged  successfully");
                this.getDocsignData(this.docSignEncrptyId)
                this.merged = true;
                this.position = true;
                this.resetboolean();
                this.ngxLoader.stopLoader('loader-docsign');
              }
              else {
                this.toastr.error(res2.data.responseMsg);
                this.ngxLoader.stopLoader('loader-docsign');
              }
            });
          }
        }
        else {
          this.toastr.error("Unable to upload file");
        }
      });
    });

  }
  //reset the boolean values
  resetboolean() {
    this.issign = false;
    this.isdesc = false;
    this.isInitial = false;
    this.isDate = false;
    this.position = true;
  }

  // Capture the option values
  openModal(Image, id, value) {
    const dialogRef = this.dialog.open(DocsignDialogPopup
      ,
      {
        data:
          { name: value, value: Image, }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (this.projectService.IsValue && value === 'write') {
        this.newdesc[id].desc = this.projectService.desc;
        this.fontRule = this.projectService.fontStyle;
      }
      if (this.projectService.isInitialValue && value === 'initial') {
        this.initial[id].initial = this.projectService.initial;
        this.fontRuleIntial[id] = this.projectService.fontStyle;
      }
      if (this.projectService.isImage && value === 'sign') {
        this.newinput.forEach(res => {
          if (res.id === id) {
            if (this.newinput[id].signatureImage) {
              this.newinput[id].signatureImage = this.newinput[id].signatureImage;
            }
            else {
              this.newinput[id].signatureImage = this.projectService.selected ? this.projectService.selected : '';
            }
            if (this.newinput[id].signatureImage === '') {
              this.isImage = false;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].selected = "";
              this.newinput[id].signatureImage = "";
            }
            else {
              this.isImage = this.projectService.isImage;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].selected = "";
            }

          }
          this.fontRule = this.projectService.fontStyle;
        })
      }
      if (this.projectService.isadminImage && value === 'adminsign') {
        this.newinput.forEach(res => {
          if (res.id === id) {
            if(res.adminsignatureImage === this.newinput[id].adminsignatureImage){
              this.newinput[id].adminsignatureImage = this.projectService.adminselected;
            }
            else{

            if (res.adminsignatureImage) {
              this.newinput[id].adminsignatureImage = res.adminsignatureImage;
            }
            else {
              this.newinput[id].adminsignatureImage = this.projectService.adminselected ? this.projectService.adminselected : '';
            }
            if (this.newinput[id].adminsignatureImage === '') {
              this.isImage = false;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].adminselected = "";
              this.newinput[id].adminsignatureImage = "";
            }
            else {
              this.isImage = this.projectService.isadminImage;
              this.IsValue = true;
              this.isInitialValue = true;
              this.newinput[id].adminselected = "";
            }
          }
          }
        })
      }
      else  if (this.projectService.isadminImage === false && value === 'adminsign') {
        this.newinput[id].adminsignatureImage === '';
        this.isImage = true;
      }
    });
  }

  onConvertFile() {
    let img: any = document.querySelector("#convertfile");

    if (typeof (FileReader) !== 'undefined') {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdffileSrc = e.target.result;
      }
      reader.readAsArrayBuffer(img.files[0]);
    }
  }

  callEditPdf(pdf: PDFDocumentProxy) {
    console.log(pdf.getData());
    // FIXME: do we really need this ??
    // console.log(pdf.dataLoaded);
  }

  //auto growth of text area
  autogrow() {
    let textArea = document.getElementById("textarea")
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

}


