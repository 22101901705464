<div *ngIf="action === 'EstimateSignature'" class="panel-heading">
  <h1 mat-dialog-title class="modal-title">{{data.title}}</h1>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div mat-dialog-content>
      <form [formGroup]="sendEstimateForm" (ngSubmit)="onSubmitEmailDetails()">
        <mat-form-field>
          <input matInput type="text" placeholder="First name" formControlName="firstName" id="firstName" [(ngModel)]="data.firstName" required name="firstName" cdkFocusInitial>
          <mat-error *ngFor="let validation of validation_messages.firstName">
            <mat-error class="error-message"
                       *ngIf="sendEstimateForm.get('firstName').hasError(validation.type)|| sendEstimateForm.get('firstName').touched">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" placeholder="Last name" formControlName="lastName" id="lastName" [(ngModel)]="data.lastName" required name="lastName">
          <mat-error *ngFor="let validation of validation_messages.lastName">
            <mat-error class="error-message"
                       *ngIf="sendEstimateForm.get('lastName').hasError(validation.type) || sendEstimateForm.get('lastName').touched">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-content >
      <signature-pad [options]="signaturePadOptions">
      </signature-pad>
    </div>

  </div>
  <div class="modal-footer">
    <div mat-dialog-actions class="pull-right">
      <button mat-raised-button (click)="clear()" class="cancelbtn">Clear</button>
      <span class="description" data-status-text-color="primary" style="padding: 0 50px;">Sign above</span>
      <button  color="primary" mat-raised-button (click)="saveSign()" class="save-btn" [disabled]="!sendEstimateForm.valid">Capture & then Upload</button>
      <button mat-raised-button (click)="closeModel()" class="cancelbtn">Cancel</button>
    </div>
  </div>
</div>
