import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDatePipe } from '../admin/shared/pipes/common-date.pipe';
import { AddressFormatPipe } from '../admin/shared/pipes/address-format.pipe';
import { AmountToWordsPipe } from '../admin/shared/pipes/amount-to-words.pipe';
import { SafePipe } from '../service/models/safe.pipe';
import { JsonFilterByPipe } from '../admin/hr/job-circular/json-filter-by.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { provideNativeDateAdapter } from '@angular/material/core';
import { AgmCoreModule } from 'agm';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'date-time-picker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { LightboxModule } from 'ngx-lightbox';

const PIPES: Provider[] = [
    CommonDatePipe,
    AddressFormatPipe,
    DatePipe,
    AmountToWordsPipe,
    SafePipe,
    JsonFilterByPipe,
];

export function withCoreProviders(): Array<Provider | EnvironmentProviders> {
    return [
        ...PIPES,

        provideNativeDateAdapter(),
        importProvidersFrom(NgCircleProgressModule.forRoot({
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        })),
        importProvidersFrom(AgmCoreModule.forRoot()),
        importProvidersFrom(LightboxModule),

        importProvidersFrom(OwlDateTimeModule),
        importProvidersFrom(OwlNativeDateTimeModule),
        importProvidersFrom(NgxUiLoaderModule),
        importProvidersFrom(DlDateTimeDateModule),
        importProvidersFrom(DlDateTimePickerModule),
    ];
}
