import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {API} from "./constant";
import { SettingData } from './Interfaces/app-integration';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppIntegrationsService {

  constructor(private http: HttpClient) {
   }

   createApp(data : SettingData) : Observable<any> {
      return this.http.post<any>(API.ADD_APP_INTEGRATIONS_SETTING,data)
   }

   getApps() : Observable<any> {
     return this.http.get<any>(API.GET_APP_LIST)
   }

   
}
