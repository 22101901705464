
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Timesheet Model</h2>
 <p><i><code> Add</code></i></p> 
 <ul>
     <li>
            This API is used to create the manual timer entries into `tbl_tasks_timer` by moduleType(<strong>project,task, tickets</strong>) and moduleId(<strong>projectId, taskId, ticketId</strong>). 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Timesheet/Timesheet_add"><u>{{path}}explorer/#!/Timesheet/Timesheet_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code> Delete </code></i></p> 
     <ul>
         <li>
                This API is used to delete the timesheet values from `tbl_tasks_timer` by <strong>Timesheet Id</strong>. 
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Timesheet/Timesheet_delete"><u>{{path}}explorer/#!/Timesheet/Timesheet_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of timesheet details from `tbl_tasks_timer` by moduleType(<strong>project,task, tickets</strong>) and moduleId(<strong>projectId, taskId, ticketId</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Timesheet/Timesheet_list"><u>{{path}}explorer/#!/Timesheet/Timesheet_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> listById</code></i></p> 
     <ul>
         <li>
                This API will fetch the details of particular timesheet from `tbl_tasks_timer` by <strong>taskTimerId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Timesheet/Timesheet_listById"><u>{{path}}explorer/#!/Timesheet/Timesheet_listById</u></a> 

         </li>
     </ul>
     <br />
     <p><i><code> Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify the details of timesheet by providing <strong>taskTimerId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Timesheet/Timesheet_modify"><u>{{path}}explorer/#!/Timesheet/Timesheet_modify</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
