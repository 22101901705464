<div class="knowledgebase-view">
  <mat-toolbar>
      <div class="comp-logo">
        <img src="{{companyLogo}}"
          onError="this.src='/assets/img/default_company_logo.png'"
        />
      </div>

  </mat-toolbar>

  <div class="breadcrumbs_section">
    <div class="breadcrumbs_wrapper">
      <div class="titles_wrapper">
        <div class="title_wrap">
          <h3 class="cat_name">{{artDetails?.name}} </h3>
          <ul class="breadcrumbs">
            <li>Knowledgebase</li>
            <li><a>{{artDetails?.name}}</a></li>
          </ul>
        </div>
        <div class="search_box">
          <div class="searchbar">
            <i class="fa fa-search" aria-hidden="true"></i>
            <input placeholder="Search by Keyword" class="search-input" [(ngModel)]="searchText">
          </div>
        </div>
      </div>
      <div class="back_button">
        <a (click)="goback()"> <i class="fa fa-arrow-left"></i> Back Page</a>
        <mat-star-rating [rating]="rating" class="star-class" [starCount]="starCount" [color]="starColor" (ratingUpdated)="onRatingChanged($event)"></mat-star-rating>
      </div>
    </div>
  </div>

<div class="kb_content_section bg-white" *ngIf="!privateFlag">
    <div class="kb_content_wrapper">
      <div class="row kb_row">
        <div class="col-md-3 col-lg-2 col-xs-12 px-0 kb_col">
          <div class="section_article_wrap">
            <div class="_title">Articles in this section</div>
            <ul class="article_list" *ngIf="sectionArticleList.length > 0">
              <li *ngFor="let article of sectionArticleList; trackBy:trackSectionArticles" [class.active]="article.artId == artId">
                <a [routerLink]="article.routerLink" [queryParams]="article.queryParams" (click)="changePage(article.categotyName,article.artName,article?.artId, catId)"> <i class="fa fa-chevron-right" aria-hidden="true"></i> {{article.artName}}</a>
              </li>
            </ul>
            <div class="empty-related-articles-list" *ngIf="sectionArticleList.length === 0">
              No articles found!
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-8 col-xs-12 kb_col">
          <div class="article-details">
              <h3 class="article_title">{{artDetails?.artName}}</h3>
              <div class="article_data">
                <div class="article_col">Date Updated: <span> {{dateUpdated | commonDate : 'date'}} </span></div>
                <div class="article_col">Date Added: <span> {{dateAdded | commonDate : 'date'}} </span></div>
                <div class="article_col">Category: <span> {{artDetails?.name}} </span></div>
                <div class="article_col">Views: <span> {{artDetails?.views == null ? 0 : artDetails?.views}} </span></div>
              </div>
              <div class="article-body">
                <div [innerHTML]="fullText | highlight: searchText" class="tinyClass article-body"></div>
              </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-2 col-xs-12 px-0 kb_col">
          <div class="related_article_wrap">
            <div class="_title">Related Articles</div>
            <ul class="article_list" *ngIf="relatedArticlesFlag">
              <li *ngFor="let item of relatedArticleList; trackBy:trackRelatedArticles">
                <a [routerLink]="item.routerLink" [queryParams]="item.queryParams" name="leadNameLink" *ngIf="permissionService.knowldgeBase.view" style="cursor: pointer" (click)="changePage(item.name,item.artName,item?.artId, item.artCategoryId)"
                >
                  <i class="fa fa-chevron-right" aria-hidden="true"></i> {{item.artName}}
                </a>
              </li>
            </ul>
            <div class="empty-related-articles-list" *ngIf="!relatedArticlesFlag">
              No related articles found!
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

  <div *ngIf="privateFlag">
    <section class="content">
      <div class="container">
        <div class="col-xs-12 p-l-10 p-r-10 p-t-10">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row no-art-cls">
                <span class="detail-view-title-name private-art-cls">{{message}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</div>

<footer class="page-footer">
  <div class="footer_inner">
    <!-- Copyright -->
    <div class="footer-copyright">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a> All rights reserved.
    </div>
    <!-- Copyright -->
    <div class="_version">{{version}}</div>
  </div>
</footer>
