import {Component, inject, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog'
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Estimate} from '../../../../service/models/Estimate';
import {EstimateService} from "../../../../service/estimate.service";
import { ToastrService } from 'ngx-toastr';
import { SignaturePad } from 'angular2-signaturepad';
import { MatButton } from '@angular/material/button';
import { SignaturePad as SignaturePad_1 } from '../../../../../../../libs/third-party-deprecated/src/lib/signaturepad/angular2-signaturepad.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatError } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-estimate-popup',
    templateUrl: './estimate-popup.component.html',
    styleUrls: ['./estimate-popup.component.css'],
    standalone: true,
    imports: [NgIf, MatDialogTitle, MatDivider, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, NgFor, MatError, SignaturePad_1, MatDialogActions, MatButton]
})
export class EstimatePopupComponent implements OnInit {
  @Input() DialogData;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  public toastr: ToastrService = inject(ToastrService);

  public validation_messages: any;
  action: string;
  sendEstimateForm: FormGroup;
  public signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 500,
    'canvasHeight':200
  };

  constructor(public dialogRef: MatDialogRef<EstimatePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Estimate, public  estimateService: EstimateService, public fb: FormBuilder) {}

  ngOnInit() {
    if (this.data.eGroup === 'EstimateSignature') {
      this.action = this.data.eGroup;
      this.addSignatureForm();
      this.getSignatureErrorMessage();
    }
  }

  closeModel() {
    this.data.msg = 'un done';
    this.data.open = false;
    this.dialogRef.close(this.data);
  }
  clear() {
    this.signaturePad.clear();
  }

  saveSign() {
    this.data['signature'] = this.signaturePad.toDataURL('image/png', 0.5);
    this.data['toData'] = this.signaturePad.toData();
    this.data.open = true;
    if (!this.signaturePad.isEmpty()) {
      this.dialogRef.close(this.data);
    } else {
      this.toastr.info('Please provide signature.');
    }
  }

  private addSignatureForm() {
    this.sendEstimateForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
    });
  }

  getSignatureErrorMessage() {
    this.validation_messages = {
      'firstName': [
        {type: 'required', message: 'First Name is required'},
      ],
      'lastName': [
        {type: 'required', message: 'Last Name is required'},
      ],
    };
  }
}
