
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Employees Model</h2>
  <p><i><code>List</code></i></p> 
  <ul>
      <li>
            This API is used to fetch the list of employee details from `tbl_users` and `tbl_account_details` based on the permissions. 
            isUserOwner method is used here.
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_list"><u>{{path}}explorer/#!/Employees/Employees_list</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>Status</code></i></p>
  <ul>
      <li>
            This API is used to change the status of an employee into `tbl_users` using status and userId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_status"><u>{{path}}explorer/#!/Employees/Employees_status</u></a> 
      </li>
  </ul> 
 <br />  
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create a new employee into `tbl_users`. 
      </li>
      <li>
            Unique fields - email 
      </li>
      <li>
            checkUpdate method is used here. 
      </li>
      <li>
            removeDuplicates method is used here. 
      </li>
      <li>
            Once an employee has been created successfully, below steps will be followed: 
            <ul>
                <li>
                        Created userId will be saved in employeeId column in the `tbl_users`. 

                </li>
                <li>
                        Employee basic details(name, lang, phone, employeeId etc.,) will be created in `tbl_account_details`. 

                </li>
                <li>
                        Finally, using sendMail, send_welcome_email and shootEmail method welcome email will be sent to that employee. 

                </li>
            </ul>
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_add"><u>{{path}}explorer/#!/Employees/Employees_add</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>detailsById</code></i></p> 
  <ul>
      <li>
            This API is used to get the details of particular employee from `tbl_users` and `tbl_account_details` based on the permission by userId. 
            isUserOwner - method is used here.
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_detailsById"><u>{{path}}explorer/#!/Employees/Employees_detailsById</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
      <li>
            This API is used to update the employee details in `tbl_users` and `tbl_account_details` by userId of employee if exists. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_modify"><u>{{path}}explorer/#!/Employees/Employees_modify</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p>
  <ul>
      <li>
            This API is used to delete the employee from database by using userId of that employee. 
            Before deleting the employee from `tbl_users`, data related to that employee will be deleted. (For ex: tickets, projects, opportunities, tasks, bugs and automation). 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_delete"><u>{{path}}explorer/#!/Employees/Employees_delete</u></a> 
      </li>
  </ul> 
 <br />  
  <p><i><code>Ban</code></i></p>
  <ul>
      <li>
            This API is used to ban the particular employee by using userId of that employee, ban<strong>(1:Ban 0:Unban)</strong> , banReason. It will update these values into `tbl_users`. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_ban"><u>{{path}}explorer/#!/Employees/Employees_ban</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>dropdownList</code></i></p>
  <ul>
      <li>
            This API is used to get the list of employees for dropdown purpose with the following conditions to be satisfied: 
      </li>
      <ul>
          <li>
                If module === <strong>'bug'</strong>,&nbsp; <strong>(tbl_account_details.company IS NULL OR tbl_account_details.company = "0") AND (tbl_account_details.vendor_id IS NULL OR tbl_account_details.vendor_id = 0) AND `tbl_users`.`user_type` != 3 AND `tbl_users`.`activated` = 1</strong>
          </li>
          <li>
                Else if <strong>userType</strong> is present, <strong>(tbl_account_details.company IS NULL OR tbl_account_details.company = "0") AND (tbl_account_details.vendor_id IS NULL OR tbl_account_details.vendor_id = 0) AND `tbl_users`.`user_type` !=</strong><strong>userType</strong> <strong>AND `tbl_users`.`activated` = 1</strong>
          </li>
          <li>
                Else it will list the employees with <strong>`user_type`</strong> as 1. 

          </li>
      </ul>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_dropdownList"><u>{{path}}explorer/#!/Employees/Employees_dropdownList</u></a> 

      </li>
  </ul> 
 <br />  
  <p><i><code>restorePassword</code></i></p> 
  <ul>
      <li>
            This API is used to reset the password for an employee by using emailId. 
            <strong>'forgot_password'</strong> template will be fetched from database to send an email to that particular employee using send_reset_password_email and shootEmail method. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_restorePassword"><u>{{path}}explorer/#!/Employees/Employees_restorePassword</u></a> 
      </li>
  </ul>
 <br /> 
  <p><i><code>rowCount</code></i></p> 
  <ul>
      <li>
            This API will return the total count of employees for the logged in user based on the user permissions. 
            isUserOwner method is used here.
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Employees/Employees_rowCount"><u>{{path}}explorer/#!/Employees/Employees_rowCount</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>userEmailExists</code></i></p> 
  <ul>
  <li>
        This API is used to check whether email of an employee is exists or not while creating the employee. 
  </li>
  <ul>
      <li>
            0 - 'Email does not exists'; 
      </li>
      <li>
            1 - 'Email exists'; 
      </li>
  </ul>
  <li>
        For reference: <a href="{{path}}explorer/#!/Employees/Employees_userEmailExists"><u>{{path}}explorer/#!/Employees/Employees_userEmailExists</u></a> 
  </li>
</ul>

</div>
</mat-card>
