
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>
  
<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Agreements Model </h2>
        <h4>Note</h4>
      <ul>
        <li>In the current system, agreement has been implemented only for client and vendor module.
          </li>
        <li>For client, `customAgreementTemplateType` will be 1,2,3 and for vendor it will be 3 </li>
        <li>agreementType = ‘1’- custom template.</li>
        <li>
          `getMergeFields` - This method is used to replace the merge_field values from 
          (`tbl_merge_fields`) by config_key values from (`tbl_config`).
        </li> 
      </ul>
      <br />

      <p><code><i>listById</i></code></p>
  <ul>
  <li>
      This API is used to fetch list of agreements with respect to moduleType (client/vendor) 
      and moduleId (clientId/vendorId).
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_listById" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_listById</a>
  </li>
</ul>
<br />
<p><code><i>Delete</i></code></p>
   
  <ul>
  <li>
      This API is used to delete the agreement from 
      `tbl_custom_agreement_templates`and `tbl_agreement` by agreementId if exists.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_delete" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_delete</a>
  </li>
</ul>
<br />

<p><code><i>New</i></code></p>
  <ul>
  <li>
      This API will fetch the new agreement template for a client from `tbl_email_templates` 
      by emailGroup as custom_agreement_template.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_new" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_new</a>
  </li>
</ul>
<br />

<p><code><i>clientNewAgreement</i></code></p>
   
  <ul>
  <li>
      This API is used to get the client details for the new agreement template by 
      clientId(required), action(optional) and emailTemplatesId(optional).
  </li>
  <li>
      `getMergeFields` method is used here.
  </li>
  <li>
      The above method will be called when action is ‘customTemplate’.
  </li>
  <li>
      Id - required field when action = ‘customTemplate’.
  </li>

  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_ClientNewAgreement" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_ClientNewAgreement</a>
  </li>
</ul>
<br />

<p><code><i>Add</i></code></p>
   
  <ul>
  <li>
      This API is used to create the new agreement template in `tbl_agreement`.
  </li>
  <li>
      Type of agreement template should be custom to create new agreement
  </li>
  <li>
      `replaceValuesInCustomTemplateFrontUser` - This method will 
      replace the form values in agreement template.
  </li>

  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_add" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_add</a>
  </li>
</ul>
<br />


<p><code><i>Modify</i></code></p>
   
  <ul>
  <li>
      This API is used to update the agreement template values by agreementId if exists.
  </li>
  <li>
      Type of agreement template should be custom to create new agreement
  </li>
  <li>
      `replaceValuesInCustomTemplateFrontUser` - This method will 
      replace the form values in agreement template.
  </li>

  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_add" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_add</a>
  </li>
</ul>
<br />


<p><code><i>Details</i></code></p>
   
  <ul>
  <li>
      This API is to fetch the agreement template and the template details from 
      `tbl_agreement`, `tbl_custom_agreement_templates` and `tbl_email_templates`.
  </li>

  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_details" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_details</a>
  </li>
</ul>
<br />


<p><code><i>updateAgreement</i></code></p>
   
  <ul>
  <li>
      This API is used to email the agreement to user.
  </li>
<li>
    Address, date, email format - `tbl_config`
</li>
<li>
    `shootEmail` - It is used to send an email using nodemailer.  
</li>
<li>
     After sending email, mailSentStatus will be updated in `tbl_agreement`.
</li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_email" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_email</a>
  </li>
</ul>
<br />


<p><code><i>Email</i></code></p>
  <ul>
  <li>
      This API is used to update the agreement templates values in 
      `tbl_custom_agreement_templates` and `tbl_agreement` if agreementId exists.
  </li>
<li>
    `replaceValuesInCustomTemplateFrontUser` - 
    This method will replace the form values in agreement template.
</li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_updateAgreement" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_updateAgreement</a>
  </li>
</ul>
<br />


<p><code><i>adminSign</i></code></p>
   
  <ul>
  <li>
      This API is used to save the companyRepresentativeSignature and 
      phoneList values in the agreement template by agreementId.
  </li>
<li>
    `replaceValuesInCustomTemplateFrontUser` - 
    This method will replace the form values in agreement template.
</li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_adminSign" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_adminSign</a>
  </li>
</ul>
<br />


<p><code><i>saveViewAgreement</i></code></p>
   
  <ul>
  <li>
      This API is used to save the details of agreement template from the 
      view page into `tbl_custom_agreement_templates` by agreementId.
  </li>
<li>
    If agreement exists, it will fetch the email templates from by defaultTemplateId 
    from the `tbl_custom_agreement_templates` response.
</li>
<li>
    replaceValuesInCustomTemplateView - This method will replace all the input tag with span 
    tag in agreement_body column in  `tbl_custom_agreement_templates`.
</li>
<li>
    The above method will be used to update the values into loa_body column in 
    `tbl_custom_agreement_templates` when the customAgreementTemplateType value is ‘1’.
</li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_saveViewAgreement" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_saveViewAgreement</a>
  </li>
</ul>
<br />


<p><code><i>clientPdf</i></code></p> 
  <ul>
  <li>
      This API is used to generate the pdf of an agreement.
  </li>
<li>
    To generate pdf, the below conditions should be satisfied.
  <ul>
  <li>
     Cond-1: if(agreementTemplate.subject === 'Default MSA & PORTING 
    Agreement Template' / 'Default MSA Agreement Template' /'Default NDA Agreement Template') 
    </li>
    <li>
     Cond-2: else if(agreementTemplate.emailGroup === 'custom_agreement_template')
    </li>
    <li>Cond-3: else it will throw error as 'Email group or subject not matched'. </li>
  </ul>
</li>
<li>
    If Cond-2 satisfies, it will check for the 
    agreementTemplateType to create the folder if does not exists.
    <ul>
      <li>agreementTemplateType(1 or 2) - folderName will be client.</li>
      <li>agreementTemplateType(3) - folderName will be type(client/vendor).</li>
      <li>require('mkdirp') - to create the directory.</li>
    </ul>
<li>
    getAgreementRelatedDetails - This method will fetch the user details 
    related to agreement by type(client/vendor/employee)
</li>
<li>
replaceAgreementPdfValues - This method is to replace the agreement information into pdf template.
</li>
<li>
    `getMergeFields` method is used here.
</li>
<li>`generatePdf`- 1) Get agreement header from 
  `tbl_email_templates` by emailGroup(agreement_header) if exists.
</li>
<li>
  <code><i>`generatePdf`</i></code>
  <ol>
    <li>
        Get agreement header from `tbl_email_templates` by emailGroup(agreement_header) if exists.
    </li>
    <li>getConfigDetailsForTenantCompanyKey - It will fetch the config details by tenantCompanyKey 
      and it will replace that values into template body.</li>
      <li>
          For generating custom pdf, we can use this package require('html-pdf') 
          and require('markup-js').
      </li>
      <li>
          getTenantName - Method is used to get the tenant name by companyKey
           from `tbl_tenant_companies`.
      </li>
      <li>
          Once the above methods are executed successfully,  uploadFilesTOCloud method will be executed.
      </li>
      <li>
          fs.unlink - to delete the pdf file from the local path.
      </li>
      <li>
          After uploading the pdf into GCP, pdfGeneratedStaus, 
          pdfPath and uploadedDate will be updated in `tbl_agreement`.
      </li>
  </ol>
</li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_clientPdf" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_clientPdf</a>
  </li>
</ul>
<br />



<p><code><i>recentlyAcceptedAgreements</i></code></p>
   
  <ul>
  <li>
      This API is used to list the recently accepted agreements by agreement_complete_status as 1.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_recentlyAcceptedAgreements" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_recentlyAcceptedAgreements</a>
  </li>
</ul>
<br />


<p><code><i>agreementsAwaitingClientSignature</i></code></p>  
  <ul>
  <li>
      This API is used to list the agreements which are not signed by 
      the client by agreement_complete_status as 0.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_agreementsAwaitingClientSignature" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_agreementsAwaitingClientSignature</a>
  </li>
</ul>
<br />


<p><code><i>vendorNewAgreement</i></code></p>   
  <ul>
  <li>
      This API is used to get the vendor details for the new agreement template 
      by vendorId(required), action(optional) and emailTemplatesId(optional).
  </li>
  <li>
      `getMergeFields` method is used here.
  </li>
  <li>
      The above method will be called when action is ‘customTemplate’.
  </li>
  <li>
      Id - required field when action = ‘customTemplate’.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_vendorNewAgreement" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_vendorNewAgreement</a>
  </li>
</ul>
<br />


<p><code><i>getPdfPath</i></code></p>
   
  <ul>
  <li>
      This API is used to get the pdf path of an agreement by agreementId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_getPdfPath" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_getPdfPath</a>
  </li>
</ul>
<br />

<p><code><i>newVendor</i></code></p>
   
  <ul>
  <li>
      This API will fetch the new agreement template for vendor from `tbl_email_templates` 
      by subject as `Default NDA Agreement Template`.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_newVendor" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_newVendor</a>
  </li>
</ul>
<br />

<p><code><i>View</i></code></p>
   
  <ul>
  <li>
      This API will fetch agreement by agreementId for front-end view purpose.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Agreement/Agreement_view" >
        {{currentUrl}}explorer/#!/Agreement/Agreement_view</a>
  </li>
</ul>
</div>
</mat-card>