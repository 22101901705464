import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { ConfigService } from '../../../service/config.service';
import { SettingsService } from '../../../service/settings.service';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-apps-setting-dialog',
    templateUrl: './apps-setting-dialog.component.html',
    styleUrls: ['./apps-setting-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatLabel, NgSelectModule, MatButton]
})
export class AppsSettingDialogComponent implements OnInit {

  public addAppSettingForm: FormGroup;
  imageURL: string | ArrayBuffer;
  isDelete: boolean = false;
  file: File;
  tenantName : string;
  options : NgOption[] = [
    {label : 'sandBox' , value : 1},
    {label : 'developement', value : 2},
    {label : 'production', value : 3}
  ] 

  constructor(inject: Injector,
    public dialogRef: MatDialogRef<AppsSettingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public fb: FormBuilder,
    private configService : ConfigService,
    private settingService : SettingsService) { }

  ngOnInit() {
    this.initForm();
    this.tenantName = this.configService.tenantName;
    if(this.dialogData) {
      this.fillform(this.dialogData)
    }
  }

  initForm() {
    this.addAppSettingForm = this.fb.group({
      title: ['', Validators.required],
      url: [''],
      public_key: [''],
      client_key: [''],
      secret_key: [''],
      developement_env : [null]
    })
  }

  addSetting() {

    if (this.addAppSettingForm.invalid){
      return
    }
    let dir = this.tenantName + '/' + 'apps' + '/Profile picture';
    const formData: FormData = new FormData();
    if (this.file !== undefined) {
      formData.append('directory', dir);
      formData.append('file', this.file, this.file.name);
      this.settingService.uploadImageFiles(formData).subscribe((res: any) => {
        this.addAppSettingForm.value.image_url = res.data.list[0].url;
        this.addAppSettingForm.value.developement_env = this.addAppSettingForm.value.developement_env === null ? 0 : this.addAppSettingForm.value.developement_env
        if(this.dialogData) {
          this.addAppSettingForm.value.id = this.dialogData.id
        }
        this.dialogRef.close(this.addAppSettingForm.value)
      })
    }
    else {
      if(this.dialogData) {
        this.addAppSettingForm.value.id = this.dialogData.id;
      }
      this.addAppSettingForm.value.developement_env = this.addAppSettingForm.value.developement_env === null ? 0 : this.addAppSettingForm.value.developement_env 
      this.dialogRef.close(this.addAppSettingForm.value)
    }
  }

  closeDialog() {
    this.addAppSettingForm.reset();
    this.dialogRef.close();
  }

  uploadImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.file = event.target.files[0];
      this.isDelete = true;
      const reader = new FileReader();
      reader.onload = e => this.imageURL = reader.result;
      reader.readAsDataURL(file);
    }
  }

  fillform(data){
    this.addAppSettingForm.patchValue({
      title: data.title ? data.title : '',
      url: data.url ? data.url : '',
      public_key: data.publicKey ? data.publicKey : '',
      client_key: data.clientKey ? data.clientKey : '',
      secret_key: data.secretKey ? data.secretKey : '',
      developement_env : data.developementEnv ? data.developementEnv : null
    })
  }

}
