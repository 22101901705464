import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-setting-up-environment',
    templateUrl: './setting-up-environment.component.html',
    styleUrls: ['./setting-up-environment.component.css'],
    standalone: true,
    imports: [MatIcon]
})
export class SettingUpEnvironmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
