import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppIntegrationsService } from '../../../service/app-integrations.service';
import { SettingData } from '../../../service/Interfaces/app-integration';
import { AppsSettingDialogComponent } from '../apps-setting-dialog/apps-setting-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-apps-layouts',
    templateUrl: './apps-layouts.component.html',
    styleUrls: ['./apps-layouts.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, MatButton]
})
export class AppsLayoutsComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);

  appsData : SettingData[] = [];
  constructor(public dialog : MatDialog,
             private _appIntegrationService : AppIntegrationsService) { }

  ngOnInit() {
    window.document.title = 'Apps';
    this.getApps();
  }

  openSettingForm(){
    const dialogref = this.dialog.open(AppsSettingDialogComponent,{
      width : '600px',
    }).afterClosed().subscribe((res:SettingData)=>{
      if(res){
      this._appIntegrationService.createApp(res).subscribe((res1:any)=>{
        if(res1.info.status === 'success'){
          this.toastr.success("App created Successfully");
          this.getApps();
        }
        else {
          this.toastr.error(res1.data.responseMsg)
        }
      })
    }
    })
  }


  getApps(){
    this._appIntegrationService.getApps().subscribe((res:any) => {
        this.appsData = res.data
    })
  }

  update(data){
    var dialogRef = this.dialog.open(AppsSettingDialogComponent,{
      width : '600px',
      data : data
    }).afterClosed().subscribe((res:any) => {
      if(res){
      this._appIntegrationService.createApp(res).subscribe((res1:any)=>{
        if(res1.info.status === 'success'){
          this.toastr.success("App Updated Successfully");
          this.getApps();
        }
        else {
          this.toastr.error(res1.data.responseMsg)
        }
      })
    }
    })
  }

}
