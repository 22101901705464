import { AlertCommonDialogData, EStatusCode } from './../../../../service/Utils/Interfaces.class';
import { GlobalService } from './../../../../service/global.service';
import { TenantCompaniesService } from './../../../../service/tenant-companies.service';
import { MatDialog, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { COMMON } from './../../../../service/constant';
import { ItemsService } from './../../../../service/items.service';
import { ConfigService } from './../../../../service/config.service';
import { Component, OnInit, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { CommonDialogModelComponent } from '../../../shared/common-dialog-model/common-dialog-model.component';
import { SettingsService } from '../../../../service/settings.service';
import { Subscription } from 'rxjs';
import { SubscriptionPayService } from '../../service/subscription-pay.service';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

export interface DialogData {
  type: string;
  name: string;
}

@Component({
    selector: 'app-remove-modules-dialog',
    templateUrl: './remove-modules-dialog.component.html',
    styleUrls: ['./remove-modules-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, NgFor, MatCheckbox, NgIf, MatDialogActions, MatButton, MatDialogClose, UpperCasePipe]
})
export class RemoveModulesDialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  moduleList: any;
  subscribedModuleList: any;
  request: any;
  currency: any;
  trialPeriodEndDate: any;
  today: any;
  defaultItemsList: any;
  itemFlag: boolean = false;
  removeModulesFlag: boolean = true;
  flag: boolean = false;
  costToBeCharged: number = 0;
  costOfModuleForMonth: number = 0;
  modulesCount: number = 0;
  daysToExpireSubscription: number = 0;
  addModulesArr: Array<any> = [];
  subscribedModulesArr: Array<any> = [];
  alredySubscribedModulesArr: Array<any> = [];
  newSelectedModulesArr: Array<any> = [];
  removedModules: string;
  addedModules: string;
  costToDisplay: any = 0;

  constructor(
    public dialogRef: MatDialogRef<RemoveModulesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private subscriptionPayService: SubscriptionPayService,
    private configService: ConfigService,
    private itemsService: ItemsService,
    private tenantCompaniesService: TenantCompaniesService,
    private settingService: SettingsService,
    private globalService: GlobalService,
    public dialogRef2: MatDialogRef<any>,
    public dialog: MatDialog
  ) {
    this.request = this.data;
   }

  ngOnInit() {
    this.configService.getAllConfigDetails();
    this.currency = this.configService.defaultCurrencySymbol;
    this.getDefaultItemsList();
    this.getSubscribedModulesList();
    this.getAlreadySubsbedList();
    const getCompDetls : Subscription = this.tenantCompaniesService.getCompanyDetails().subscribe((data: any) => {
      if (data.info.code=== EStatusCode.OK) {
        this.trialPeriodEndDate = new Date(data.data.trialPeriodEndDate);
        this.today = new Date();
        if (this.trialPeriodEndDate.getTime() < this.today.getTime()) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      }
    })
    this.subscriptions.push(getCompDetls);
  }

  getAlreadySubsbedList(){
    let nextBillingDate = moment(this.request.subscriptionEndDate).format('YYYY-MM-DD');
    this.subscriptionPayService.getAlreadySubscribedModules(nextBillingDate).subscribe((response: any) => {
      if (response.info.code === EStatusCode.OK) {
        response.data.menuResult.forEach(item => {
          this.alredySubscribedModulesArr.push({id: item.menuId, billingDate: moment(this.request.subscriptionEndDate).format('YYYY-MM-DD')})
        })
      }
    })
  }

  getSubscribedModulesList() {
    const getSubscbedModus : Subscription = this.subscriptionPayService.getSubscribedModules().subscribe((response: any) => {
      if (response.info.code=== EStatusCode.OK) {
        this.subscribedModuleList = response.data;
        response.data.forEach(module => {
          this.subscribedModulesArr.push(module.label)
        });
      }
    });
    this.subscriptions.push(getSubscbedModus);
  }

  getDefaultItemsList() {
    const getDefltItesList : Subscription = this.itemsService.getDefaultItemsList().subscribe((response: any) => {
      if (response.info.code=== EStatusCode.OK) {
        this.defaultItemsList = response.data.list;
      }
    });
    this.subscriptions.push(getDefltItesList);
  }

  onCheckboxChange(moduleInfo, checkboxState) {
    var menuId = moduleInfo.menuId;
    var name = moduleInfo.label;
    name = name.charAt(0).toUpperCase() + name.slice(1);
    var moduleSelected = {
      billingDate: moment(this.request.subscriptionEndDate).format('YYYY-MM-DD'), 
      id: menuId 
    };
    if (!checkboxState.checked) {
      this.newSelectedModulesArr.push(moduleSelected)
    } else {
      this.newSelectedModulesArr = this.newSelectedModulesArr.filter((item) => item.id !== menuId);
    }
    this.defaultItemsList.forEach(itemInfo => {
      if (itemInfo.itemName == name) {
        if (!checkboxState.checked) {
          this.modulesCount++;
          this.addModulesArr.push(name.toLowerCase());
          var index = this.subscribedModulesArr.indexOf(name.toLowerCase());
          if (index > -1) {            
            this.subscribedModulesArr.splice(index, 1);
          }

          if (itemInfo.pricingType == 1) {
            this.costOfModuleForMonth = this.costOfModuleForMonth + itemInfo.unitCost;
          } else if (itemInfo.pricingType == 0) {
            this.costOfModuleForMonth = this.costOfModuleForMonth + (itemInfo.unitCost * this.request.totalSeats);
          }
          
        } else {
          this.modulesCount--;
          this.addModulesArr.splice(this.addModulesArr.indexOf(name), 1);
          this.subscribedModulesArr.push(name)

          if (itemInfo.pricingType == 1) {
            this.costOfModuleForMonth = this.costOfModuleForMonth - itemInfo.unitCost;
          } else if (itemInfo.pricingType == 0) {
            this.costOfModuleForMonth = this.costOfModuleForMonth - (itemInfo.unitCost * this.request.totalSeats);
          }
        }
      }
    });
    if (this.modulesCount > 0) {
      this.removeModulesFlag = false;
    } else {
      this.removeModulesFlag = true;
    }
    // var perDayCostOfModules = this.costOfModuleForMonth / 30;
    var today = new Date();

    //Get 1 day in milliseconds
    var one_day = 1000 * 60 * 60 * 24;
    var endDate = new Date(this.request.subscriptionEndDate);

    // Convert both dates to milliseconds
    var date1_ms = today.getTime();
    var date2_ms = endDate.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;

    // Convert back to days 
    this.daysToExpireSubscription = Math.round(difference_ms / one_day);
    this.costToDisplay = Math.floor((this.costOfModuleForMonth) * 100) / 100;
    this.costToBeCharged = this.costOfModuleForMonth;
    
    this.addedModules = this.addModulesArr.join(',');
  }

  removeModules() {
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to unsubscribe a app. App unsubscription will be effective from your next billing cycle. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });
    var ids = new Set(this.newSelectedModulesArr.map(d => d.id));
    var merged = [...this.newSelectedModulesArr, ...this.alredySubscribedModulesArr.filter(d => !ids.has(d.id))];
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const creaPoliAndRoles : Subscription = this.globalService.createPolicyAndRoles(this.subscribedModulesArr.join(','),JSON.stringify(merged)).subscribe((RolesAndPolicyRes: any) => {
          if (RolesAndPolicyRes.result.info.code=== EStatusCode.OK) {
            var data = { isComplete: 1 };
            
            this.toastr.success( "Apps removed successfully" );
          } else {
            var data = { isComplete: 0 };
            
            this.toastr.error(RolesAndPolicyRes.result.data.responseMsg);
          }
          this.settingService.menuUpdateEventListener.next(true);
          this.dialogRef.close(data);
        });
        this.subscriptions.push(creaPoliAndRoles);
      } else if(!res) {
          
          this.toastr.error( "Module removal aborted" );
          this.dialogRef.close(); 
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

}
