import { ClientService } from './../../../service/client.service';
import { PaymentInfoService } from './../../../service/payment-info.service';
import { FormGroup, Validators, FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Inject, ElementRef, NgZone, inject } from "@angular/core";
import { TenantCompaniesService } from "../../../service/tenant-companies.service";

import moment from 'moment';
import { Router } from "@angular/router";
import { ConfigService } from "../../../service/config.service";
import { HttpClient } from "@angular/common/http";
import { TsysService } from "../../../service/tsys.service";
import { GlobalService } from "../../../service/global.service";
import { EStatusCode } from "../../../service/Utils/Interfaces.class";
import { LeadsService } from "../../../service/leads.service";
import { InvoiceServiceService } from "../../../service/invoice-service.service";
import { environment } from "../../../../environments/environment";
import { Subscription } from 'rxjs';
import { ToastMessages } from '../../../service/constant';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CommonDatePipe } from '../../../admin/shared/pipes/common-date.pipe';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgIf, NgFor, NgClass, UpperCasePipe, DecimalPipe } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

export interface DialogData {
  type: string;
  name: string;
}

@Component({
    selector: "app-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, MatDivider, CdkScrollable, MatDialogContent, MatRadioGroup, FormsModule, ReactiveFormsModule, NgIf, NgFor, MatRadioButton, MatCheckbox, NgClass, ExtendedModule, MatButton, UpperCasePipe, DecimalPipe, CommonDatePipe]
})
export class DialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  request: any;
  unitCost: number = 0;
  encryptedTSEP: string;
  userId: number;
  remainingDaysToEndMonth: number = 0;
  currentStatus: number = 2;
  zerocost: number = 0;
  cvvForm: FormGroup;
  paymentInfoId: number;
  isSavedCard: boolean = false;
  showErrorMsg: boolean = false;
  activateBtn: boolean = false;
  cardsOnFile: boolean = false;
  defaultCC: number = 0;
  isDefaultAvailable: boolean = false;
  btnText: string ='Save & Pay';
  saveDefault: boolean = true;
  showOptions : boolean;
  showdefault : boolean = true;
  showCheckbox: boolean = false;
  setValue : any;
  defaultTaxId : number;
  billingZipcode: number;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tenantCompaniesService: TenantCompaniesService,
    private elementRef: ElementRef,
    public tsysService: TsysService,
    public globalService: GlobalService,
    public invoiceService: InvoiceServiceService,
    public configService: ConfigService,
    private zone: NgZone,
    private leadsService: LeadsService,
    private fb: FormBuilder,
    private paymentInfoService: PaymentInfoService,
    private clientService: ClientService,
  ) {
    this.request = this.data;   
    if(this.data.paymentInfo.length == 0){
      this.showOptions = true;
    }
    window["angularComponentDialogReference"] = {
      zone: this.zone,
      componentFn: value => this.reopenDialog(value),
      component: this
    };

    window["angularComponentReference"] = {
      zone: this.zone,
      componentFn: value => this.updateTsysData(value),
      component: this
    };
  }

  ngOnInit() {
    this.encryptedTSEP = this.data.manifest;
    this.userId = this.data.userId;
    this.addCvvForm();
    this.getDefaultTaxId();
    if (this.request.paymentInfo.length > 0) {
      this.cardsOnFile = true;
    } else {
      this.cardsOnFile = false;
    }
    this.loadJs("../../../../assets/js/tsys-tsep.js")

    // check the first time card availablity
    this.isDefaultAvailable = !!((this.cardsOnFile && this.request.paymentInfo[0].isDefaultAvailable));
    this.defaultCC = this.isDefaultAvailable ? 0 : 1;

  }

  loadJs(url: string){
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  ngAfterViewInit() {
    this.loadScript(environment.TSYS_MANIFEST_URL + this.data.ubmDeviceId +"?" + this.encryptedTSEP);
  }
  loadScript(url) {
    let node = document.createElement("script");
    node.src = url;
    node.type = "text/javascript";
    this.elementRef.nativeElement.appendChild(node);
  }
  close() {
    var data = { isComplete: 0, reOpen: 0 };
    this.dialogRef.close(data);
  }
  reopenDialog(value) {
    var data = { isComplete: 0, reOpen: 1 ,errMsg:value.errorMsg};
    this.dialogRef.close(data);
  }

  updateTsysData(tsysData) {
    this.tsysService.updateTsysData(tsysData);
    this.currentStatus = 1;
  }

  addCvvForm() {
    this.cvvForm = this.fb.group({
      cardType: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required, Validators.maxLength(4), Validators.pattern('^[0-9]*$')]),
      paymentId: new FormControl('', [])
    });
  }

  checkCVC(value) {
    if (value.length >= 3) {
      var cvv = this.cvvForm.controls['cvv'].value;
      const validateACC : Subscription =  this.paymentInfoService.validateCC(cvv, this.paymentInfoId).subscribe((res: any) => {
        if (res.result.info.code === EStatusCode.OK) {
          if (res.result.data.isMatched == 1) {
            this.isSavedCard = true;
            this.showErrorMsg = false;
            this.activateBtn = true;
          } else{
            this.toastr.error("CVV verification failed ! please try again.")              
            this.activateBtn = false;
            this.currentStatus = 2;
            this.isSavedCard = false;
            this.showErrorMsg = true;
          }
        }
      })
      this.subscriptions.push(validateACC);
    }
  }
  setPaymentInfo(id,isDefaultCard) {
    this.showOptions = false;
    if(isDefaultCard == 0){
      this.showCheckbox = true;
    }
    else{
      this.showCheckbox = false;
    }
    this.activateBtn = false;
    this.cvvForm.patchValue({ cvv: null });
    this.paymentInfoId = id;
    this.isSavedCard = false;
    this.showErrorMsg = false;
  }

  selectCC() {
    this.paymentInfoService.payNowEventListener.next(true);
    const subPaymentInfo : Subscription = this.paymentInfoService.subscribePaymentInfo(this.paymentInfoId).subscribe((result: any) => {
      if (result.info.code === EStatusCode.OK) {
        var cardInfo = result.data[0];
        var isAddCC: 0
        var payMentData = {
          creditCardNumber: cardInfo.creditCardNumber,
          cardType: cardInfo.cardType,
          expirationDate: cardInfo.expirationDate,
          cvc: cardInfo.cvc.toString(),
          billingZip: cardInfo.billingZip
        };
        var ccData = {
          userId: Number(localStorage.getItem("userId")),
          authData: payMentData,
          isDefaultCard : this.defaultCC,
          saveDefault : this.saveDefault
        }
        if(this.defaultCC == 1){
          const makeCardADDefault : Subscription = this.globalService.makeCardDefault(this.paymentInfoId).subscribe((data:any)=>
          {
            if(data.info.code === EStatusCode.OK) {
              this.toastr.success("Default card has changed!");
            }
          })
          this.subscriptions.push(makeCardADDefault);
        }
        var amount = Number(this.request.total);        
        this.makesubscriptionPayments(amount, payMentData, ccData);
      }
    });
    this.subscriptions.push(subPaymentInfo);

  }

  getDefaultTaxId(){
    const getASConfigASById : Subscription = this.tsysService.getConfigById('default_tax').subscribe((data:any)=>{
      if(data.result.info.code === EStatusCode.OK){
        this.defaultTaxId = data.result.data[0].value;
      }
    })
    this.subscriptions.push(getASConfigASById);
  }

  makesubscriptionPayments(amount, payMentData, cardInfo){
    this.paymentInfoService.payNowEventListener.next(true);
    this.globalService
      .makeSubscribePayment(amount, payMentData)
      .subscribe((res: any) => {
        if (
          res.info.code == EStatusCode.OK ||
          res.info.code == EStatusCode.CREATED
        ) {
          var paymentInfo = res.data;
          /* Find lead details by company key */
          var date = new Date();
          var daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
          this.globalService
            .getLeadDetailsByCompanyKey()
            .subscribe((leadRes: any) => {
              if (
                leadRes.info.code == EStatusCode.OK ||
                leadRes.info.code == EStatusCode.CREATED
              ) {
                var leadInfo = leadRes.data;
                var clientId = leadInfo.convertedClientId;
                if (clientId) {
                  const getUbmClientDet : Subscription = this.clientService.getUbmClientDetail(clientId).subscribe((clientRes: any) => {
                    if (clientRes.info.code === EStatusCode.OK) {
                      var clientInfo = clientRes.data;
                      /* Create Invoice for UBM Item's */
                      var itemsList = this.request.defaultItemsList;

                      var discountAmount = 0;
                      if (this.request.plan == "monthly") {
                        var numberOfMonths = 1;
                        discountAmount = 0;
                      } else {
                        var numberOfMonths = 12;
                        discountAmount = 10;
                      }
                      var moduleArray = [];
                      this.request.subscribedModuleList.forEach(
                        subscribed_module => {
                          moduleArray.push(subscribed_module.label || subscribed_module);
                        }
                      );
                      var itemData: any = [];
                      var itemOrder = 1;
                      itemsList.forEach(element => {
                        if (
                          moduleArray.includes(
                            element.itemName.toLowerCase()
                          )
                        ) {
                          var newData = {
                            itemOrder: itemOrder,
                            itemId: element.savedItemsId,
                            itemName: element.savedItemsId,
                            itemDesc: element.itemDesc,
                            qty: 1,
                            unitCost: 0.0,
                            taxName: 0.0,
                            totalCost: 0.0,
                            itemTaxTotal: 0.0,
                            itemTaxRate: 0.0,
                            price: element.unitCost,
                            editItem: false,
                            taxValue: "",
                            isDeleted: 0,
                            check: true
                          };

                          if (element.itemName === "Seats Reserved") {
                            var actualUnitCost = Number(element.unitCost/daysInCurrentMonth)
                            var costForCurrentMonth = Number(actualUnitCost * this.request.remainingDaysToEndMonth);
                            var costForRemainingDays = costForCurrentMonth;
                            if (element.itemTaxRate) {
                              var itemTax = Number(((costForRemainingDays * element.itemTaxRate) / 100).toFixed(2));
                              newData.itemTaxRate = element.itemTaxRate;
                              newData.itemTaxTotal = itemTax;
                              newData.taxName = element.taxRatesId;
                            } else if (!element.itemTaxRate && element.itemTaxTotal) {
                              var itemTax = Number((element.itemTaxTotal).toFixed(2))
                              newData.itemTaxTotal = element.itemTaxTotal;
                              newData.taxName = element.taxRatesId;
                            } else {
                              var itemTax = 0;
                            }

                            if (numberOfMonths == 12) {
                              var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                              var costForSeatReservedRow = Number((((element.totalCost * numberOfMonths)) * this.request.totalSeats).toFixed(2))
                            } else {
                              var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                              var costForSeatReservedRow = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2));
                            }

                            newData.qty = this.request.totalSeats;
                            newData.unitCost = currentMonthlyCost;
                            newData.totalCost = costForSeatReservedRow;
                          } else if (
                            element.pricingType == 0 &&
                            (element.itemName != "Dashboard" &&
                              element.itemName != "Calendar" &&
                              element.itemName != "Client" &&
                              element.itemName != "Vendor" &&
                              element.itemName != "Tasks" &&
                              element.itemName != "HR" &&
                              element.itemName != "Subscription" &&
                              element.itemName != "Report" &&
                              element.itemName != "Utilities")
                          ) {
                            var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));

                            if (element.itemTaxRate) {
                              var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                              newData.itemTaxRate = element.itemTaxRate;
                              newData.itemTaxTotal = itemTax;
                              newData.taxName = element.taxRatesId;
                            } else if (!element.itemTaxRate && element.itemTaxTotal) {
                              var itemTax = Number(element.itemTaxTotal)
                              newData.itemTaxTotal = element.itemTaxTotal;
                              newData.taxName = element.taxRatesId;
                            } else {
                              var itemTax = 0;
                            }
                            if (numberOfMonths == 12) {
                              var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                              var total_cost = Number(((element.totalCost * numberOfMonths) * this.request.totalSeats).toFixed(2))
                            } else {
                              var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                              var total_cost = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2))
                            }
                            newData.qty = this.request.totalSeats;
                            newData.unitCost = currentMonthlyCost;
                            newData.totalCost = total_cost;
                          } else if (
                            element.pricingType == 1 &&
                            element.itemName != "Dashboard" &&
                            element.itemName != "Calendar" &&
                            element.itemName != "Client" &&
                            element.itemName != "Vendor" &&
                            element.itemName != "Tasks" &&
                            element.itemName != "HR" &&
                            element.itemName != "Subscription" &&
                            element.itemName != "Report" &&
                            element.itemName != "Utilities"
                          ) {
                            var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                            if (element.itemTaxRate) {
                              var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                              newData.itemTaxRate = element.itemTaxRate;
                              newData.itemTaxTotal = itemTax;
                              newData.taxName = element.taxRatesId;
                            } else if (!element.itemTaxRate && element.itemTaxTotal) {
                              var itemTax = Number(element.itemTaxTotal)
                              newData.itemTaxTotal = element.itemTaxTotal;
                              newData.taxName = element.taxRatesId;
                            } else {
                              var itemTax = 0;
                            }
                            if (numberOfMonths == 12) {
                              var currentMonthlyCost = Number(((element.unitCost).toFixed(2)));
                              var total_cost = Number(((element.totalCost * numberOfMonths)).toFixed(2))
                            } else {
                              var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                              var total_cost = Number((currentMonthlyCost).toFixed(2))
                            }
                            newData.qty = 1;
                            newData.unitCost = currentMonthlyCost;
                            newData.totalCost = total_cost;
                          } else if (
                            element.itemName == "Dashboard" ||
                            element.itemName == "Calendar" ||
                            element.itemName == "Client" ||
                            element.itemName == "Vendor" ||
                            element.itemName == "Tasks" ||
                            element.itemName == "HR" ||
                            element.itemName == "Subscription" ||
                            element.itemName == "Report" ||
                            element.itemName == "Utilities"
                          ) {
                            newData.qty = 1;
                            newData.unitCost = 0;
                            newData.totalCost = 0;
                          } else {
                            newData.qty = 0;
                            newData.unitCost = 0;
                            newData.totalCost = 0;
                          }
                          itemData.push(newData);
                          itemOrder = itemOrder + 1;
                        }
                      });

                      var invoiceData: any = {
                        clientId: clientInfo.clientId,
                        moduleId: 0,
                        relatedTo: "",
                        commissionId: 0,
                        commission: "",
                        memo: "",
                        invoiceDate: new Date().toString(),
                        taxId: Number(this.defaultTaxId),
                        discount: discountAmount,
                        notes: this.configService.defaultTerms,
                        items: itemData,
                        recuringFrequency: "none",
                        recurStartDate: "",
                        recurEndDate: "",
                        autoPayBy: 0,
                        autoPayId: 0,
                        userId: 0,
                        isSubscriptionInvoice: 1
                      };
                      this.invoiceService
                        .addInvoice(invoiceData)
                        .subscribe((invRes: any) => {
                          if (
                            invRes.info.code == EStatusCode.OK ||
                            invRes.info.code == EStatusCode.CREATED
                          ) {

                            /* Update Payment, Transaction, COA, income_expense */
                            var invoicesId = invRes.data.invoicesId;
                            var updateData: any = {
                              invoicesId: invoicesId,
                              amount: parseFloat(amount),
                              clientInfo: clientInfo,
                              paymentInfo: paymentInfo,
                              cardInfo: cardInfo
                            };

                            this.globalService
                              .updatePaymentTrans(updateData)
                              .subscribe((PTRes: any) => {

                                if (
                                  PTRes.info.code == EStatusCode.OK ||
                                  PTRes.info.code == EStatusCode.CREATED
                                ) {
                                  /* Update Subscription start_date end_date */
                                  var today = new Date();
                                  var dd = today.getDate();
                                  var mm = today.getMonth() + 1; //January is 0!
                                  var y = today.getFullYear();
                                  var subscriptionPlan = 0;
                                  var todayD = y + "-" + mm + "-" + dd;
                                  if (this.request.plan == "monthly") {
                                    var todaysDate = new Date();
                                    var month = mm + 1;
                                    var year = y;
                                    var daysInNextMonth = new Date(year, month, 0).getDate()
                                    var numberOfDaysToAdd = this.request.remainingDaysToEndMonth;
                                    todaysDate.setDate(
                                      todaysDate.getDate() +
                                      numberOfDaysToAdd
                                    );
                                    var dd = todaysDate.getDate();
                                    var mm = todaysDate.getMonth() + 1;
                                    var y = todaysDate.getFullYear();
                                    var endDate = y + "-" + mm + "-" + dd;
                                    subscriptionPlan = 0;
                                  } else if (
                                    this.request.plan == "yearly"
                                  ) {
                                    var todaysDate = new Date();
                                    var numberOfDaysToAdd = 365 + this.request.remainingDaysToEndMonth + 1;
                                    todaysDate.setDate(
                                      todaysDate.getDate() +
                                      numberOfDaysToAdd
                                    );
                                    var dd = todaysDate.getDate();
                                    var mm = todaysDate.getMonth() + 1;
                                    var y = todaysDate.getFullYear();
                                    var endDate = y + "-" + mm + "-" + dd;
                                    subscriptionPlan = 1;
                                  }
                                  var subscriptionData = {
                                    subscriptionStartDate: todayD,
                                    subscriptionEndDate: endDate,
                                    subscribedPlan: subscriptionPlan
                                  };
                                  this.tenantCompaniesService
                                    .subscription(subscriptionData)
                                    .subscribe((response: any) => {
                                      if (
                                        response.info.code ==
                                        EStatusCode.OK ||
                                        response.info.code ==
                                        EStatusCode.CREATED
                                      ) {
                                        this.globalService.createPolicyAndRoles(this.request.subscribedModuleList.join(',')).subscribe((RolesAndPolicyRes: any) => {
                                          var seatData = {
                                            seatCount: Number(this.request.seatsToadd)
                                          };
                                          this.tenantCompaniesService.addSeats(seatData).subscribe((res: any) => {
                                            this.configService.udpateInitialConfigDetails(response.data.data);
                                            this.configService.getAllConfigDetails();
    
                                            var data = {
                                              isComplete: 1,
                                              reOpen: 0,
                                              isSuccess: 1,
                                              otherError: 0,
                                              otherErrorReason: "",
                                              isPaymentFailFromTsys: 0
                                            };
                                            this.dialogRef.close(data);
                                            localStorage.setItem(
                                              "subscription",
                                              "1"
                                            );
                                            this.toastr.success(
                                              "Thanks for subscribing"
                                            );
                                            this.paymentInfoService.hideMessageventListener.next(true);
                                            window.location.href = "/admin/dashboard";

                                          });
                                        })

                                        // this.router.navigate(["/admin/dashboard"], {
                                        //   replaceUrl: true
                                        // });
                                      } else {
                                        var sdata = {
                                          isComplete: 1,
                                          reOpen: 0,
                                          isSuccess: 1,
                                          otherError: 1,
                                          otherErrorReason:
                                            response.data.responseMsg,
                                          isPaymentFailFromTsys: 0
                                        };
                                        this.dialogRef.close(sdata);
                                        this.toastr.error(
                                          "Subscription failed. Please try again"
                                        );
                                      }
                                    });
                                } else {
                                  var data = {
                                    isComplete: 1,
                                    reOpen: 0,
                                    isSuccess: 1,
                                    otherError: 1,
                                    otherErrorReason:
                                      PTRes.data.responseMsg,
                                    isPaymentFailFromTsys: 0
                                  };
                                  this.dialogRef.close(data);
                                }
                              });
                          } else {
                            var data = {
                              isComplete: 1,
                              reOpen: 0,
                              isSuccess: 1,
                              otherError: 1,
                              otherErrorReason: invRes.data.responseMsg,
                              isPaymentFailFromTsys: 0
                            };
                            this.dialogRef.close(data);
                          }
                        });
                    } else {
                      var data = {
                        isComplete: 1,
                        reOpen: 0,
                        isSuccess: 1,
                        otherError: 1,
                        otherErrorReason: clientRes.data.responseMsg,
                        isPaymentFailFromTsys: 0
                      };
                      this.dialogRef.close(data);
                    }
                  });
                  this.subscriptions.push(getUbmClientDet);
                } else {
                  let leadData: any = {};
                  /* Convert leads to client */
                  leadData.companyName = leadInfo.leadName;
                  leadData.locationName = leadInfo.country;
                  leadData.cemail = leadInfo.email;
                  leadData.phone = leadInfo.phone;
                  leadData.billingaddress = leadInfo.address;
                  leadData.billingCity = leadInfo.city;
                  leadData.billingState = leadInfo.state;
                  leadData.billingZipCode = leadInfo.zip ? leadInfo.zip : "";
                  leadData.country = leadInfo.country ? leadInfo.country : "";
                  leadData.shippingAddress = leadInfo.address ? leadInfo.address : "";
                  leadData.shippingCity = leadInfo.city ? leadInfo.city : "";
                  leadData.shippingState = leadInfo.state ? leadInfo.state : "";
                  leadData.shippingZipCode = leadInfo.zip ? leadInfo.zip : "";
                  leadData.sCountry = leadInfo.country ? leadInfo.country : "";
                  leadData.shortNote = leadInfo.notes ? leadInfo.notes : "";
                  leadData.fullname = leadInfo.fullName ? leadInfo.fullName : "";
                  leadData.title = leadInfo.title ? leadInfo.title : "";
                  leadData.email = leadInfo.email ? leadInfo.email : "";
                  leadData.mobile = leadInfo.mobile ? leadInfo.mobile : "";
                  leadData.website = leadInfo.website ? leadInfo.website : "";
                  leadData.facebookUrl = leadInfo.facebook ? leadInfo.facebook : "";
                  leadData.twitterUrl = leadInfo.twitter ? leadInfo.twitter : "";
                  leadData.clientStatus = 1;
                  leadData.permission = leadInfo.permissionType;
                  leadData.is_company_owner = leadInfo.isCompanyOwner;
                  leadData.company_key = leadInfo.companyKey;
                  leadData.customerSince = moment().format('YYYY-MM-DD');

                  if (leadInfo.permissionType == 3) {
                    var userIds = leadInfo.customUsers;
                    var userArr = JSON.parse(userIds);

                    var str = userArr
                      .map(ele => {
                        return ele.id;
                      })
                      .join(",");
                    leadData.assignedTo = str;
                  }

                  this.leadsService
                    .convertToUbmClient(leadInfo.leadsId, leadData)
                    .subscribe((clientRes: any) => {

                      if (
                        clientRes.info.code == EStatusCode.OK ||
                        clientRes.info.code == EStatusCode.CREATED
                      ) {
                        var clientInfo = clientRes.data;

                        /* Create Invoice for UBM Item's */
                        var itemsList = this.request.defaultItemsList;

                        var discountAmount = 0;
                        if (this.request.plan == "monthly") {
                          var numberOfMonths = 1;
                          discountAmount = 0;
                        } else {
                          var numberOfMonths = 12;
                          discountAmount = 10;
                        }
                        var moduleArray = [];
                        this.request.subscribedModuleList.forEach(
                          subscribed_module => {
                            moduleArray.push(subscribed_module.label || subscribed_module);
                          }
                        );
                        var itemData: any = [];
                        var itemOrder = 1;
                        itemsList.forEach(element => {
                          if (
                            moduleArray.includes(
                              element.itemName.toLowerCase()
                            )
                          ) {
                            var newData = {
                              itemOrder: itemOrder,
                              itemId: element.savedItemsId,
                              itemName: element.savedItemsId,
                              itemDesc: element.itemDesc,
                              qty: 1,
                              unitCost: 0.0,
                              taxName: 0.0,
                              totalCost: 0.0,
                              itemTaxTotal: 0.0,
                              itemTaxRate: 0.0,
                              price: element.unitCost,
                              editItem: false,
                              taxValue: "",
                              isDeleted: 0,
                              check: true
                            };


                            if (element.itemName === "Seats Reserved") {
                              var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                              if (element.itemTaxRate) {
                                var itemTax = Number(((costForRemainingDays * element.itemTaxRate) / 100).toFixed(2));
                                
                                newData.itemTaxRate = element.itemTaxRate;
                                newData.itemTaxTotal = itemTax;
                                newData.taxName = element.taxRatesId;

                              } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                var itemTax = Number(element.itemTaxTotal)
                                newData.itemTaxTotal = element.itemTaxTotal;
                                newData.taxName = element.taxRatesId;

                              } else {
                                var itemTax = 0;
                              }
                              if (numberOfMonths == 12) {
                                var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                                var costForSeatReservedRow = Number((((element.totalCost * numberOfMonths)) * this.request.totalSeats).toFixed(2))
                              } else {
                                var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                var costForSeatReservedRow = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2));
                              }
                                
                              newData.qty = this.request.totalSeats;
                              newData.unitCost = currentMonthlyCost;
                              newData.totalCost = costForSeatReservedRow;

                            } else 
                            if (
                              element.pricingType == 0 &&
                              (element.itemName != "Dashboard" &&
                                element.itemName != "Calendar" &&
                                element.itemName != "Client" &&
                                element.itemName != "Vendor" &&
                                element.itemName != "Tasks" &&
                                element.itemName != "HR" &&
                                element.itemName != "Subscription" &&
                                element.itemName != "Report" &&
                                element.itemName != "Utilities")
                            ) {

                              var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                              if (element.itemTaxRate) {
                                var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                newData.itemTaxRate = element.itemTaxRate;
                                newData.itemTaxTotal = itemTax;
                                newData.taxName = element.taxRatesId;
                              } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                var itemTax = Number(element.itemTaxTotal)
                                newData.itemTaxTotal = element.itemTaxTotal;
                                newData.taxName = element.taxRatesId;
                              } else {
                                var itemTax = 0;
                              }
                              if (numberOfMonths == 12) {
                                var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                                var total_cost = Number(((element.totalCost * numberOfMonths) * this.request.totalSeats).toFixed(2))
                              } else {
                                var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                var total_cost = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2))
                              }
                              newData.qty = this.request.totalSeats;
                              newData.unitCost = currentMonthlyCost;
                              newData.totalCost = total_cost;
                            } else if (
                              element.pricingType == 1 &&
                              element.itemName != "Dashboard" &&
                              element.itemName != "Calendar" &&
                              element.itemName != "Client" &&
                              element.itemName != "Vendor" &&
                              element.itemName != "Tasks" &&
                              element.itemName != "HR" &&
                              element.itemName != "Subscription" &&
                              element.itemName != "Report" &&
                              element.itemName != "Utilities"
                            ) {
                              var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                              if (element.itemTaxRate) {
                                var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                newData.itemTaxRate = element.itemTaxRate;
                                newData.itemTaxTotal = itemTax;
                                newData.taxName = element.taxRatesId;
                              } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                var itemTax = Number(element.itemTaxTotal)
                                newData.itemTaxTotal = element.itemTaxTotal;
                                newData.taxName = element.taxRatesId;
                              } else {
                                var itemTax = 0;
                              }
                              if (numberOfMonths == 12) {
                                var currentMonthlyCost = Number(((element.unitCost).toFixed(2)));
                                var total_cost = Number(((element.totalCost * numberOfMonths)).toFixed(2))
                              } else {
                                var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                var total_cost = Number((currentMonthlyCost).toFixed(2))
                              }
                              newData.qty = 1;
                              newData.unitCost = currentMonthlyCost;
                              newData.totalCost = total_cost;
                            } else if (
                              element.itemName == "Dashboard" ||
                              element.itemName == "Calendar" ||
                              element.itemName == "Client" ||
                              element.itemName == "Vendor" ||
                              element.itemName == "Tasks" ||
                              element.itemName == "HR" ||
                              element.itemName == "Report" ||
                              element.itemName == "Subscription" ||
                              element.itemName == "Utilities"
                            ) {
                              newData.qty = 1;
                              newData.unitCost = 0;
                              newData.totalCost = 0;
                              newData.itemTaxTotal = 0;
                              newData.taxName = element.taxRatesId;
                            } else {
                              newData.qty = 0;
                              newData.unitCost = 0;
                              newData.totalCost = 0;
                            }
                            itemData.push(newData);
                            itemOrder = itemOrder + 1;
                          }
                        });

                        var invoiceData: any = {
                          clientId: clientInfo.clientId,
                          moduleId: 0,
                          relatedTo: "",
                          commissionId: 0,
                          commission: "",
                          memo: "",
                          invoiceDate: new Date().toString(),
                          taxId: Number(this.defaultTaxId),
                          discount: discountAmount,
                          notes: this.configService.defaultTerms,
                          items: itemData,
                          recuringFrequency: "none",
                          recurStartDate: "",
                          recurEndDate: "",
                          autoPayBy: 0,
                          autoPayId: 0,
                          userId: 0,
                          isSubscriptionInvoice: 1
                        };
                        this.invoiceService
                          .addInvoice(invoiceData)
                          .subscribe((invRes: any) => {
                            if (
                              invRes.info.code == EStatusCode.OK ||
                              invRes.info.code == EStatusCode.CREATED
                            ) {

                              /* Update Payment, Transaction, COA, income_expense */
                              var invoicesId = invRes.data.invoicesId;
                              var updateData: any = {
                                invoicesId: invoicesId,
                                amount: parseFloat(amount),
                                clientInfo: clientInfo,
                                paymentInfo: paymentInfo,
                                cardInfo: cardInfo
                              };

                              this.globalService
                                .updatePaymentTrans(updateData)
                                .subscribe((PTRes: any) => {

                                  if (
                                    PTRes.info.code == EStatusCode.OK ||
                                    PTRes.info.code == EStatusCode.CREATED
                                  ) {
                                    /* Update Subscription start_date end_date */
                                    var today = new Date();
                                    var dd = today.getDate();
                                    var mm = today.getMonth() + 1; //January is 0!
                                    var y = today.getFullYear();
                                    var subscriptionPlan = 0;
                                    var todayD = y + "-" + mm + "-" + dd;
                                    if (this.request.plan == "monthly") {
                                      var todaysDate = new Date();
                                      var month = mm + 1;
                                      var year = y;
                                      var daysInNextMonth = new Date(year, month, 0).getDate()
                                      var numberOfDaysToAdd = this.request.remainingDaysToEndMonth;
                                      todaysDate.setDate(
                                        todaysDate.getDate() +
                                        numberOfDaysToAdd
                                      );
                                      var dd = todaysDate.getDate();
                                      var mm = todaysDate.getMonth() + 1;
                                      var y = todaysDate.getFullYear();
                                      var endDate = y + "-" + mm + "-" + dd;
                                      subscriptionPlan = 0;
                                    } else if (
                                      this.request.plan == "yearly"
                                    ) {
                                      var todaysDate = new Date();
                                      var numberOfDaysToAdd = 365 + this.request.remainingDaysToEndMonth + 1;
                                      todaysDate.setDate(
                                        todaysDate.getDate() +
                                        numberOfDaysToAdd
                                      );
                                      var dd = todaysDate.getDate();
                                      var mm = todaysDate.getMonth() + 1;
                                      var y = todaysDate.getFullYear();
                                      var endDate = y + "-" + mm + "-" + dd;
                                      subscriptionPlan = 1;
                                    }
                                    var subscriptionData = {
                                      subscriptionStartDate: todayD,
                                      subscriptionEndDate: endDate,
                                      subscribedPlan: subscriptionPlan
                                    };
                                    this.tenantCompaniesService
                                      .subscription(subscriptionData)
                                      .subscribe((response: any) => {
                                        if (
                                          response.info.code ==
                                          EStatusCode.OK ||
                                          response.info.code ==
                                          EStatusCode.CREATED
                                        ) {

                                          var data = {
                                            isComplete: 1,
                                            reOpen: 0,
                                            isSuccess: 1,
                                            otherError: 0,
                                            otherErrorReason: "",
                                            isPaymentFailFromTsys: 0
                                          };
                                          this.dialogRef.close(data);
                                          localStorage.setItem(
                                            "subscription",
                                            "1"
                                          );
                                          this.toastr.success(
                                            "Thanks for subscribing"
                                          );
                                          window.location.href = "/admin/dashboard";
                                          // this.router.navigate(["/admin/dashboard"], {
                                          //   replaceUrl: true
                                          // });
                                        } else {
                                          var sdata = {
                                            isComplete: 1,
                                            reOpen: 0,
                                            isSuccess: 1,
                                            otherError: 1,
                                            otherErrorReason:
                                              response.data.responseMsg,
                                            isPaymentFailFromTsys: 0
                                          };
                                          this.dialogRef.close(sdata);
                                          this.toastr.error(
                                            "Subscription failed. Please try again"
                                          );
                                        }
                                      });
                                  } else {
                                    var data = {
                                      isComplete: 1,
                                      reOpen: 0,
                                      isSuccess: 1,
                                      otherError: 1,
                                      otherErrorReason:
                                        PTRes.data.responseMsg,
                                      isPaymentFailFromTsys: 0
                                    };
                                    this.paymentInfoService.hideMessageventListener.next(true);
                                    this.dialogRef.close(data);
                                  }
                                });
                            } else {
                              var data = {
                                isComplete: 1,
                                reOpen: 0,
                                isSuccess: 1,
                                otherError: 1,
                                otherErrorReason: invRes.data.responseMsg,
                                isPaymentFailFromTsys: 0
                              };
                              this.paymentInfoService.hideMessageventListener.next(true);
                              this.dialogRef.close(data);
                            }
                          });
                      } else {
                        var data = {
                          isComplete: 1,
                          reOpen: 0,
                          isSuccess: 1,
                          otherError: 1,
                          otherErrorReason: clientRes.data.responseMsg,
                          isPaymentFailFromTsys: 0
                        };
                        this.paymentInfoService.hideMessageventListener.next(true);
                        this.dialogRef.close(data);
                      }
                    });
                }
              }
            });
        }
        else{
          this.toastr.error(res.data.responseMsg);
          this.paymentInfoService.hideMessageventListener.next(true);
        }
      });
  }

  addUserCC() {
    if (!this.billingZipcode || String(this.billingZipcode).length < 5) {
      this.toastr.error(ToastMessages.INVALID_ZIPCODE)
    } else {

    this.paymentInfoService.payNowEventListener.next(true);

    var ccData = {
      userId: Number(this.userId),
      authData: this.tsysService.getTsysData(),
      isDefaultCard: this.defaultCC,
      saveDefault: this.saveDefault
    };
    ccData.authData['zipCode'] = this.billingZipcode;

    var payMentData = {
      creditCardNumber: ccData.authData.card_number,
      cardType: ccData.authData.card_type,
      expirationDate: ccData.authData.expiration_date,
      cvc: ccData.authData.cvc,
      billingZip: ccData.authData.zipCode
    };

    var amount = this.request.total;

    this.globalService
      .makeSubscribePayment(amount, payMentData)
      .subscribe((res: any) => {
        if (
          res.info.code == EStatusCode.OK ||
          res.info.code == EStatusCode.CREATED
        ) {
          var paymentInfo = res.data;
          /* SAVE CARD DATA */
          this.globalService
            .userAuthentication(ccData)
            .subscribe((saveCardRes: any) => {

              if (
                saveCardRes.info.code == EStatusCode.OK ||
                saveCardRes.info.code == EStatusCode.CREATED
              ) {
                var cardInfo = saveCardRes.data.savedData;
                /* Find lead details by company key */
                var date = new Date();
                var daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
                this.globalService
                  .getLeadDetailsByCompanyKey()
                  .subscribe((leadRes: any) => {
                    if (
                      leadRes.info.code == EStatusCode.OK ||
                      leadRes.info.code == EStatusCode.CREATED
                    ) {
                      var leadInfo = leadRes.data;
                      var clientId = leadInfo.convertedClientId
                      if (clientId) {
                        this.makesubscriptionPayments(amount, payMentData, cardInfo);
                      } else {
                        let leadData: any = {};
                        /* Convert leads to client */
                        leadData.companyName = leadInfo.leadName;
                        leadData.locationName = leadInfo.country;
                        leadData.cemail = leadInfo.email;
                        leadData.phone = leadInfo.phone;
                        leadData.billingaddress = leadInfo.address;
                        leadData.billingCity = leadInfo.city;
                        leadData.billingState = leadInfo.state;
                        leadData.billingZipCode = leadInfo.zip ? leadInfo.zip : "";
                        leadData.country = leadInfo.country ? leadInfo.country : "";
                        leadData.shippingAddress = leadInfo.address ? leadInfo.address : "";
                        leadData.shippingCity = leadInfo.city ? leadInfo.city : "";
                        leadData.shippingState = leadInfo.state ? leadInfo.state : "";
                        leadData.shippingZipCode = leadInfo.zip ? leadInfo.zip : "";
                        leadData.sCountry = leadInfo.country ? leadInfo.country : "";
                        leadData.shortNote = leadInfo.notes ? leadInfo.notes : "";
                        leadData.fullname = leadInfo.fullName ? leadInfo.fullName : "";
                        leadData.title = leadInfo.title ? leadInfo.title : "";
                        leadData.email = leadInfo.email ? leadInfo.email : "";
                        leadData.mobile = leadInfo.mobile ? leadInfo.mobile : "";
                        leadData.website = leadInfo.website ? leadInfo.website : "";
                        leadData.facebookUrl = leadInfo.facebook ? leadInfo.facebook : "";
                        leadData.twitterUrl = leadInfo.twitter ? leadInfo.twitter : "";
                        leadData.clientStatus = 1;
                        leadData.permission = leadInfo.permissionType;
                        leadData.is_company_owner = leadInfo.isCompanyOwner;
                        leadData.company_key = leadInfo.companyKey;
                        leadData.customerSince = moment().format('YYYY-MM-DD');

                        if (leadInfo.permissionType == 3) {
                          var userIds = leadInfo.customUsers;
                          var userArr = JSON.parse(userIds);

                          var str = userArr
                            .map(ele => {
                              return ele.id;
                            })
                            .join(",");
                          leadData.assignedTo = str;
                        }

                        const convertToUbmClint : Subscription = this.leadsService
                          .convertToUbmClient(leadInfo.leadsId, leadData)
                          .subscribe((clientRes: any) => {

                            if (
                              clientRes.info.code == EStatusCode.OK ||
                              clientRes.info.code == EStatusCode.CREATED
                            ) {
                              var clientInfo = clientRes.data;

                              /* Create Invoice for UBM Item's */
                              var itemsList = this.request.defaultItemsList;

                              var discountAmount = 0;
                              if (this.request.plan == "monthly") {
                                var numberOfMonths = 1;
                                discountAmount = 0;
                              } else {
                                var numberOfMonths = 12;
                                discountAmount = 10;
                              }
                              var moduleArray = [];
                              this.request.subscribedModuleList.forEach(
                                subscribed_module => {
                                  moduleArray.push(subscribed_module.label || subscribed_module);
                                }
                              );
                              var itemData: any = [];
                              var itemOrder = 1;
                              itemsList.forEach(element => {
                                if (
                                  moduleArray.includes(
                                    element.itemName.toLowerCase()
                                  )
                                ) {
                                  var newData = {
                                    itemOrder: itemOrder,
                                    itemId: element.savedItemsId,
                                    itemName: element.savedItemsId,
                                    itemDesc: element.itemDesc,
                                    qty: 1,
                                    unitCost: 0.0,
                                    taxName: 0.0,
                                    totalCost: 0.0,
                                    itemTaxTotal: 0.0,
                                    itemTaxRate: 0.0,
                                    price: element.unitCost,
                                    editItem: false,
                                    taxValue: "",
                                    isDeleted: 0,
                                    check: true
                                  };

                                  if (element.itemName === "Seats Reserved") {
                                    var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                                    if (element.itemTaxRate) {
                                      var itemTax = Number(((costForRemainingDays * element.itemTaxRate) / 100).toFixed(2));
                                      newData.itemTaxRate = element.itemTaxRate;
                                      newData.itemTaxTotal = itemTax;
                                      newData.taxName = element.taxRatesId;
                                    } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                      var itemTax = Number(element.itemTaxTotal)

                                      newData.itemTaxTotal = element.itemTaxTotal;
                                      newData.taxName = element.taxRatesId;
                                    } else {
                                      var itemTax = 0;
                                    }
                                    if (numberOfMonths == 12) {
                                      var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                                      var costForSeatReservedRow = Number((((element.totalCost * numberOfMonths)) * this.request.totalSeats).toFixed(2))
                                    } else {
                                      var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                      var costForSeatReservedRow = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2));
                                    }
  
                                    newData.qty = this.request.totalSeats;
                                    newData.unitCost = currentMonthlyCost;
                                    newData.totalCost = costForSeatReservedRow;

                                  } else
                                  if (
                                    element.pricingType == 0 &&
                                    (element.itemName != "Dashboard" &&
                                      element.itemName != "Calendar" &&
                                      element.itemName != "Client" &&
                                      element.itemName != "Vendor" &&
                                      element.itemName != "Tasks" &&
                                      element.itemName != "HR" &&
                                      element.itemName != "Subscription" &&
                                      element.itemName != "Report" &&
                                      element.itemName != "Utilities")
                                  ) {

                                    var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                                    if (element.itemTaxRate) {
                                      var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                      newData.itemTaxRate = element.itemTaxRate;
                                      newData.itemTaxTotal = itemTax;
                                      newData.taxName = element.taxRatesId;
                                    } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                      var itemTax = Number(element.itemTaxTotal)
                                      newData.itemTaxTotal = element.itemTaxTotal;
                                      newData.taxName = element.taxRatesId;
                                    } else {
                                      var itemTax = 0;
                                    }
                                    if (numberOfMonths == 12) {
                                      var currentMonthlyCost = Number((element.unitCost).toFixed(2));
                                      var total_cost = Number(((element.totalCost * numberOfMonths) * this.request.totalSeats).toFixed(2))
                                    } else {
                                      var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                      var total_cost = Number((currentMonthlyCost * this.request.totalSeats).toFixed(2))
                                    }
                                    newData.qty = this.request.totalSeats;
                                    newData.unitCost = currentMonthlyCost;
                                    newData.totalCost = total_cost;
                                  } else if (
                                    element.pricingType == 1 &&
                                    element.itemName != "Dashboard" &&
                                    element.itemName != "Calendar" &&
                                    element.itemName != "Client" &&
                                    element.itemName != "Vendor" &&
                                    element.itemName != "Tasks" &&
                                    element.itemName != "HR" &&
                                    element.itemName != "Report" &&
                                    element.itemName != "Subscription" &&
                                    element.itemName != "Utilities"
                                  ) {
                                    var costForRemainingDays = Number(((element.unitCost / daysInCurrentMonth) * this.request.remainingDaysToEndMonth));
                                    if (element.itemTaxRate) {
                                      var itemTax = Number((costForRemainingDays * element.itemTaxRate) / 100);
                                      newData.itemTaxRate = element.itemTaxRate;
                                      newData.itemTaxTotal = itemTax;
                                      newData.taxName = element.taxRatesId;
                                    } else if (!element.itemTaxRate && element.itemTaxTotal) {
                                      var itemTax = Number(element.itemTaxTotal)
                                      newData.itemTaxTotal = element.itemTaxTotal;
                                      newData.taxName = element.taxRatesId;
                                    } else {
                                      var itemTax = 0;
                                    }
                                    if (numberOfMonths == 12) {
                                      var currentMonthlyCost = Number(((element.unitCost).toFixed(2)));
                                      var total_cost = Number(((element.totalCost * numberOfMonths)).toFixed(2))
                                    } else {
                                      var currentMonthlyCost = Number((costForRemainingDays).toFixed(2));
                                      var total_cost = Number((currentMonthlyCost).toFixed(2))
                                    }
                                    newData.qty = 1;
                                    newData.unitCost = currentMonthlyCost;
                                    newData.totalCost = total_cost;
                                  } else if (
                                    element.itemName == "Dashboard" ||
                                    element.itemName == "Calendar" ||
                                    element.itemName == "Client" ||
                                    element.itemName == "Vendor" ||
                                    element.itemName == "Tasks" ||
                                    element.itemName == "HR" ||
                                    element.itemName == "Report" ||
                                    element.itemName == "Subscription" ||
                                    element.itemName == "Utilities"
                                  ) {
                                    newData.qty = 1;
                                    newData.unitCost = 0;
                                    newData.totalCost = 0;
                                    newData.itemTaxTotal = 0;
                                    newData.taxName = element.taxRatesId;
                                  } else {
                                    newData.qty = 0;
                                    newData.unitCost = 0;
                                    newData.totalCost = 0;
                                  }
                                  itemData.push(newData);
                                  itemOrder = itemOrder + 1;
                                }
                              });

                              var invoiceData: any = {
                                clientId: clientInfo.clientId,
                                moduleId: 0,
                                relatedTo: "",
                                commissionId: 0,
                                commission: "",
                                memo: "",
                                invoiceDate: new Date().toString(),
                                taxId: Number(this.defaultTaxId),
                                discount: discountAmount,
                                notes: this.configService.defaultTerms,
                                items: itemData,
                                recuringFrequency: "none",
                                recurStartDate: "",
                                recurEndDate: "",
                                autoPayBy: 0,
                                autoPayId: 0,
                                userId: 0,
                                isSubscriptionInvoice: 1
                              };
                              const addSubInvoice : Subscription = this.invoiceService
                                .addInvoice(invoiceData)
                                .subscribe((invRes: any) => {
                                  if (
                                    invRes.info.code == EStatusCode.OK ||
                                    invRes.info.code == EStatusCode.CREATED
                                  ) {

                                    /* Update Payment, Transaction, COA, income_expense */
                                    var invoicesId = invRes.data.invoicesId;
                                    var updateData: any = {
                                      invoicesId: invoicesId,
                                      amount: parseFloat(amount),
                                      clientInfo: clientInfo,
                                      paymentInfo: paymentInfo
                                    };

                                    const upPaymentTrans : Subscription = this.globalService
                                      .updatePaymentTrans(updateData)
                                      .subscribe((PTRes: any) => {

                                        if (
                                          PTRes.info.code == EStatusCode.OK ||
                                          PTRes.info.code == EStatusCode.CREATED
                                        ) {
                                          /* Update Subscription start_date end_date */
                                          var today = new Date();
                                          var dd = today.getDate();
                                          var mm = today.getMonth() + 1; //January is 0!
                                          var y = today.getFullYear();
                                          var subscriptionPlan = 0;
                                          var todayD = y + "-" + mm + "-" + dd;
                                          if (this.request.plan == "monthly") {
                                            var todaysDate = new Date();
                                            var month = mm + 1;
                                            var year = y;
                                            var daysInNextMonth = new Date(year, month, 0).getDate()
                                            var numberOfDaysToAdd = this.request.remainingDaysToEndMonth;
                                            todaysDate.setDate(
                                              todaysDate.getDate() +
                                              numberOfDaysToAdd
                                            );
                                            var dd = todaysDate.getDate();
                                            var mm = todaysDate.getMonth() + 1;
                                            var y = todaysDate.getFullYear();
                                            var endDate = y + "-" + mm + "-" + dd;
                                            subscriptionPlan = 0;
                                          } else if (
                                            this.request.plan == "yearly"
                                          ) {
                                            var todaysDate = new Date();
                                            var numberOfDaysToAdd = 365 + this.request.remainingDaysToEndMonth + 1;
                                            todaysDate.setDate(
                                              todaysDate.getDate() +
                                              numberOfDaysToAdd
                                            );
                                            var dd = todaysDate.getDate();
                                            var mm = todaysDate.getMonth() + 1;
                                            var y = todaysDate.getFullYear();
                                            var endDate = y + "-" + mm + "-" + dd;
                                            subscriptionPlan = 1;
                                          }
                                          var subscriptionData = {
                                            subscriptionStartDate: todayD,
                                            subscriptionEndDate: endDate,
                                            subscribedPlan: subscriptionPlan
                                          };
                                          const subcription : Subscription = this.tenantCompaniesService
                                            .subscription(subscriptionData)
                                            .subscribe((response: any) => {
                                              if (
                                                response.info.code ==
                                                EStatusCode.OK ||
                                                response.info.code ==
                                                EStatusCode.CREATED
                                              ) {

                                                var data = {
                                                  isComplete: 1,
                                                  reOpen: 0,
                                                  isSuccess: 1,
                                                  otherError: 0,
                                                  otherErrorReason: "",
                                                  isPaymentFailFromTsys: 0
                                                };
                                                this.dialogRef.close(data);
                                                localStorage.setItem(
                                                  "subscription",
                                                  "1"
                                                );
                                                this.toastr.success(
                                                  "Thanks for subscribing"
                                                );
                                                window.location.href = "/admin/dashboard";
                                                // this.router.navigate(["/admin/dashboard"], {
                                                //   replaceUrl: true
                                                // });
                                              } else {
                                                var sdata = {
                                                  isComplete: 1,
                                                  reOpen: 0,
                                                  isSuccess: 1,
                                                  otherError: 1,
                                                  otherErrorReason:
                                                    response.data.responseMsg,
                                                  isPaymentFailFromTsys: 0
                                                };
                                                this.dialogRef.close(sdata);
                                                this.toastr.error(
                                                  "Subscription failed. Please try again"
                                                );
                                              }
                                            });
                                            this.subscriptions.push(subcription);
                                        } else {
                                          var data = {
                                            isComplete: 1,
                                            reOpen: 0,
                                            isSuccess: 1,
                                            otherError: 1,
                                            otherErrorReason:
                                              PTRes.data.responseMsg,
                                            isPaymentFailFromTsys: 0
                                          };
                                          this.paymentInfoService.hideMessageventListener.next(true);
                                          this.dialogRef.close(data);
                                        }
                                      });
                                      this.subscriptions.push(upPaymentTrans);
                                  } else {
                                    var data = {
                                      isComplete: 1,
                                      reOpen: 0,
                                      isSuccess: 1,
                                      otherError: 1,
                                      otherErrorReason: invRes.data.responseMsg,
                                      isPaymentFailFromTsys: 0
                                    };
                                    this.paymentInfoService.hideMessageventListener.next(true);
                                    this.dialogRef.close(data);
                                  }
                                });
                                this.subscriptions.push(addSubInvoice);
                            } else {
                              var data = {
                                isComplete: 1,
                                reOpen: 0,
                                isSuccess: 1,
                                otherError: 1,
                                otherErrorReason: clientRes.data.responseMsg,
                                isPaymentFailFromTsys: 0
                              };
                              this.paymentInfoService.hideMessageventListener.next(true);
                              this.dialogRef.close(data);
                            }
                          });
                          this.subscriptions.push(convertToUbmClint);
                      }
                    } else {
                      var data = {
                        isComplete: 1,
                        reOpen: 0,
                        isSuccess: 1,
                        otherError: 1,
                        otherErrorReason: leadRes.data.responseMsg,
                        isPaymentFailFromTsys: 0
                      };
                      this.paymentInfoService.hideMessageventListener.next(true);
                      this.dialogRef.close(data);
                    }
                  });
                } else {
                  var data = {
                    isComplete: 1,
                    reOpen: 0,
                    isSuccess: 1,
                    otherError: 1,
                    otherErrorReason: saveCardRes.data.responseMsg,
                    isPaymentFailFromTsys: 0
                  };
                  this.paymentInfoService.hideMessageventListener.next(true);
                  this.dialogRef.close(data);
                }
              });
        } else {
          var data = {
            isComplete: 1,
            reOpen: 0,
            isSuccess: 0,
            otherError: 0,
            otherErrorReason: "",
            isPaymentFailFromTsys: 1,
            failedResonseMsg:
              res.data.responseMsg
          };
          this.paymentInfoService.hideMessageventListener.next(true);
          this.dialogRef.close(data);
        }
      });
    }
  }

  saveCcCheck(event){
    if(event.checked){
      this.saveDefault = true;
      this.btnText = 'Save & Pay';
    }
    else{
      this.saveDefault = false;
      this.btnText = 'Pay';
    }
  }
  defaultCcCheck(event) {
    if (event.checked) {
      this.defaultCC = 1;
    } else {
      this.defaultCC = 0;
    }
  }
  changeOption(event){

    if(event == 'default'){
      this.showdefault = true;
      this.showOptions = false;
    }
    else{
      this.setValue = null;
      this.showOptions = true;
      this.showdefault = false;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  SearchData(zipcode){
    this.billingZipcode = zipcode;
  }

}
