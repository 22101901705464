<div *ngIf="!error" #docsign id="loader-docsign" class="clientView loader-docsign">

  <div class="view_header">
    <mat-toolbar class="themeColor" [ngClass]="themeData">
      <img src="{{getpdfdata?.companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
      <span *ngIf="themeData==='green-light'||themeData==='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: black">{{getpdfdata?.companyName}}</span>
      <span *ngIf="themeData!=='green-light'&& themeData!=='green-dark'" class="headerName themeColor"
        [ngClass]="themeData" style="color: white">{{getpdfdata?.companyName}}</span>
    </mat-toolbar>

  </div>
  <div id="doc-detail-content" class="clearfix">
        <!--Icons options for placing box inside pdf -->


      <!--Display pdf inside the html start here -->
    <div style="width: 100%;" id="printPage">
      <button *ngIf="getpdfdata?.signed===0 &&  getpdfdata.isOwner==1" mat-raised-button class="buttons-wrapper" color="primary" (click)="mergePdf()">Submit</button>
      <mat-card *ngIf="isSrcLoaded" id="pdfSend" class="pdf-wrapper" style="width: 90%;height: 80%;margin: 0 auto;">
        <pdf-viewer id='contentToConvert' [src]="fileURL"
          style="display: unset;"
          original-size="false" 
          fit-to-page="true"
          (after-load-complete)="callEditPdf($event)">
        </pdf-viewer>

         <!-- Multiple box for each option start here -->
        <form>
            <!-- Signature option-->
          <div *ngIf="isclientsign">
            <div *ngIf=" getpdfdata?.isOwner==1 && getpdfdata?.signed===0">
              <div *ngFor="let index of lenArr; let i of index" class="ui-widget-content draggable-sign"
                id="singnatureBox" (cdkDragEnded)="dragEnd($event)" style=" touch-action: none;
                           -webkit-user-drag: none;
                           -webkit-tap-highlight-color: transparent;
                            user-select: none;"
                [ngStyle]="{'transform': 'translate3d(' + boxposition[index].x+ 'px, '+boxposition[index].y+'px, 0px)'}">
                <!-- <input *ngIf="!isImage" [(ngModel)]="newinput[index].adminselected"
                  name="adminselected_{{newinput[index].id}}"
                  (click)="openModal(newinput[index].adminselected,i,'adminsign')" type="text"
                  class="client-docsign" /> -->
                <img *ngIf="isImage " [src]="newinput[index].adminsignatureImage?newinput[index].adminsignatureImage :adminsignatureImageCopy"
                  (click)="openModal(newinput[index].adminsignatureImage,i,'adminsign')" class="img">
              </div>
            </div>
          </div>

           <!--Description option-->
          <div *ngIf="isclientdesc ">
            <div *ngIf=" getpdfdata?.isOwner==1 && getpdfdata?.signed===0">
              <div *ngFor="let index of descArr; let i of index" class="ui-widget-content draggable1-desc" id="singnatureBox"
                (cdkDragEnded)="dragEnddesc($event)" style=" touch-action: none;
                     -webkit-user-drag: none;
                     -webkit-tap-highlight-color: transparent;
                      user-select: none;"
                [ngStyle]="{'transform': 'translate3d(' + deposition[index].x+ 'px, '+deposition[index].y+'px, 0px)'}">
                <textarea *ngIf="IsValue" [(ngModel)]="newdesc[index].desc" name="desc_{{newdesc[index].id}}"
                  type="text" class="desc-value" (keyup)="autogrow()" id="textarea">
                </textarea>
              </div>
            </div>
          </div>

             <!--Initial option-->
          <div *ngIf="isclientInitial">
            <div *ngIf=" getpdfdata?.isOwner==1 && getpdfdata?.signed===0">
              <div *ngFor="let index of iniArr; let i of index" class="ui-widget-content text-filed-cli" id="singnatureText"
                (cdkDragEnded)="dragEndinitial($event)" style=" touch-action: none;
                       -webkit-user-drag: none;
                       -webkit-tap-highlight-color: transparent;
                        user-select: none;"
                [ngStyle]="{'transform': 'translate3d(' + iniposition[index].x+ 'px, '+iniposition[index].y+'px, 0px)'}">
                <input *ngIf="isInitialValue" [ngStyle]="fontRuleIntial[index]" [(ngModel)]="initial[index].initial"
                  name="initial_{{initial[index].id}}" (click)="openModal(initial[index].initial, i,'initial')"
                  type="text" class="initial-value" />
              </div>
            </div>
          </div>

          <!--Date option-->
          <div *ngIf="isclientDate">
            <div *ngIf=" getpdfdata?.isOwner==1 && getpdfdata?.signed===0">
              <div *ngFor="let index of dateArr; let i of index" class="ui-widget-content dateDrage"
                (cdkDragEnded)="dragEnddate($event)" style=" touch-action: none;
               -webkit-user-drag: none;
               -webkit-tap-highlight-color: transparent;
               user-select: none;"
                [ngStyle]="{'transform': 'translate3d(' + datposition[index].x+ 'px, '+datposition[index].y+'px, 0px)'}">
                <input matInput [matDatepicker]="dp" [formControl]="date" class = "client-date">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </div>
            </div>
          </div>
        </form>

  
      </mat-card>

     
    

    </div>
    <!--Display pdf inside the html end here -->
    <ngx-ui-loader fgsType="ball-spin-clockwise" fgsColor="#1976d2" pbThickness="0" fgsSize="40"
      overlayColor="#00000024" [loaderId]="'loader-docsign'"></ngx-ui-loader>

  </div>
  <footer class="page-footer view_footer" style="padding-top:5px;">
    <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io "
        target="_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.
      <p class="alignright">{{version}}</p>
    </div>
  </footer>
</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px">Please check your entered Id.</p>
  </div>
</div>

<app-spinner></app-spinner>
