
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Tax Rates Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new tax rate details into `tbl_tax_rates`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_add"><u>{{path}}explorer/#!/TaxRates/TaxRates_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the tax rate from `tbl_tax_rates` by <strong>Tax Rate Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_delete"><u>{{path}}explorer/#!/TaxRates/TaxRates_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p>
     <ul>
         <li>
                This API will fetch all the tax rate list from `tbl_tax_rates` for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_list"><u>{{path}}explorer/#!/TaxRates/TaxRates_list</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                This API will fetch the details of particular tax rate from `tbl_tax_rates` by <strong>taxRateId</strong> if exists.  

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_listById"><u>{{path}}explorer/#!/TaxRates/TaxRates_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify details of existing tax rate into `tbl_tax_rates` <strong>taxRateId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_modify"><u>{{path}}explorer/#!/TaxRates/TaxRates_modify</u></a>  

         </li>
     </ul>
 <br />  
     <p><i><code>paginationList</code></i></p> 
     <ul>
         <li>
                This API will fetch the tax rate list from `tbl_tax_rates` with pagination. 

         </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/TaxRates/TaxRates_PaginationList"><u>{{path}}explorer/#!/TaxRates/TaxRates_PaginationList</u></a> 

        </li>
     </ul>
     </div>
</mat-card>
