
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
     </div>
   
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Current State opportunity Model</h2>
 <p><i><code>Add</code> </i></p>
<ul>
    <li>
            This API is used to create new opportunity status reason into `tbl_current_state_oppo` by <strong>name</strong> and <strong>parentId</strong>. 
    </li>
    <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_add"><u>{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_add</u></a> 

    </li>
</ul>
<br />
  <p><i><code>Delete</code> </i></p> 
 <ul>
     <li>
            It will delete the opportunity status reason from `tbl_current_state_oppo` by <strong>stateId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_delete"><u>{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_delete</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>List</code> </i></p> 
 <ul>
     <li>
            This API is used to fetch the list of opportunity status reason from `tbl_current_state_oppo`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_list"><u>{{path}}explorer/#!/OpportunityStateReason/OpportunityStateReason_list</u></a> 

     </li>
 </ul>
 <br />  
  <p><i><code>listById</code> </i></p> 
 <ul>
     <li>
            This API is used to get the details of particular opportunity status reason from `tbl_current_state_oppo` by <strong>stateId</strong>. 
     </li>
 <li>
        For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById</u></a> 
 </li>
</ul>
 <br />  
  <p><i><code>listByParentId</code> </i></p> 
 <ul>
     <li>
            This API is used to fetch the list of opportunity status reason from `tbl_current_state_oppo` by <strong>parentId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_listById</u></a> 
     </li>
 </ul>
 <br />  
  <p><i><code>Modify</code> </i></p> 
 <ul>
     <li>
            This API is used to modify the details of opportunity status reason into `tbl_current_state_oppo` by <strong>stateId</strong> if exists. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityStages/OpportunityStages_modify"><u>{{path}}explorer/#!/OpportunityStages/OpportunityStages_modify</u></a> 
     </li>
 </ul>
</div>
</mat-card>

