import { Component, OnInit, HostListener, Input,ChangeDetectorRef, Inject, ViewChild, EventEmitter, ElementRef, AfterViewInit, Injector, Output, inject } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule, ControlContainer, FormControl,Validators, FormBuilder } from '@angular/forms';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { EStatusCode, IResponse, AlertCommonDialogData, CustomFieldFormId } from '../../service/Utils/Interfaces.class';
import { TaskService } from '../../service/task.service';
import moment from 'moment';
import { ReleaseNoteService } from '../../service/release-note.service';
import { COMMON } from '../../service/constant';
import { DeleteEntryDialogComponent } from './delete-entry-dialog/delete-entry-dialog.component';
import { ConfigService } from '../../service/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { TicketsService } from '../../service/tickets.service';
import { Subscription } from 'rxjs';
import { TinyMceConfigComponent } from '../../admin/tinymce-config';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTabChangeEvent, MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent, MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { StripDots } from './dot.pipe';
import { SafePipe } from '../../admin/shared/pipes/safe.pipe';
import { MatButton } from '@angular/material/button';
import { TinymceConfigDirective } from '../../admin/shared/custom-directives/tinymce-config.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { OwlDateTimeComponent } from '../../../../../libs/third-party-deprecated/src/lib/date-time-picker/date-time/date-time-picker.component';
import { OwlDateTimeTriggerDirective } from '../../../../../libs/third-party-deprecated/src/lib/date-time-picker/date-time/date-time-picker-trigger.directive';
import { OwlDateTimeInputDirective } from '../../../../../libs/third-party-deprecated/src/lib/date-time-picker/date-time/date-time-picker-input.directive';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix, MatLabel } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss'],
    standalone: true,
    imports: [MatIcon, MatCard, MatTabGroup, MatTab, NgFor, MatTooltip, MatTabContent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatLabel, MatDatepicker, NgSelectModule, NgClass, ExtendedModule, OwlDateTimeInputDirective, OwlDateTimeTriggerDirective, OwlDateTimeComponent, MatCheckbox, TinymceConfigDirective, NgIf, MatButton, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, SafePipe, StripDots]
})
export class ReleaseNotesComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  scrollTop;
  scrollLeft;
  events: string[] = [];
  public submitted = false;
  updateId;
  size;
  today;
  responsibleUserId: NgOption = [];
  responsiblearr: any = [];
  responsibleUser;
  releaseNoteData: any;
  releaseNoteDate: any;
  releaseNotearr;
  finaljsonarray :any = [];
  tempStartTime:any ;
  tempEndTime:any;
  tempStartTime1:any ;
  tempEndTime1:any;
  finaljson: any = {};
  releaseNoteId = "";
  responsibleUsers;
  reposeuser=false;
  ubmFixGitNewup = "";
  newCommentFeature="";
  improveSprint="";
  bugFixes="";
  ubmNote = "";
  newUpdates = "";
  versionName;
  finalArr = [];
  tabLabel: string;
  parentdiv;
  start =0;
  limit = 15;
  versionValue = "";
  End;
  limitData: any;
  releaseDate = "";
  isvalidVersion = 0;
  i=0;
  j=0;
  counts=1;
  firstImgurl: any;
  secondImgurl:any;
  thierdImgurl:any;
  fourthImgurl:any;
  file: File;
  checked=false;
  mailNotify:any;
  deleteYear;
  deleteMonth;
  idForUpdate:any;
  isDesable:boolean=false;
  editNot:boolean=false;
  title='Add';
  createdDate:any;
  minDate = new Date();
  @Input() max: any;
  tomorrow = new Date();
  @ViewChild("picker2") picker2; 
  date = new FormControl(new Date());
  dataSource:any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['Version Name', 'Release Date', 'Time' , 'Created Date','Action'];
  public isChanged: boolean;
  createReleaseForm:FormGroup;
  notificationForm:FormGroup;
  @ViewChild("picker") picker;
  @ViewChild("picker1") picker1;
  @Input() dialogRef: any;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @Output() isUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('toolbar') toolbar:ElementRef<any>;
  @ViewChild('documentEditor') documentEditor:ElementRef<any>;
  tenant_name: any;
  @Input() config?:Object ={
  };
  @Input() url:string='';
  public componentEvents: string[] = [];
  tinyEditorConfig: Object = {};

  open() {
    this.picker.open();
  }
  open1() {
    this.picker1.open();
  }

  constructor(private http:HttpClient, private formBuilder: FormBuilder,public taskservice: TaskService,public configService: ConfigService, public releaseNoteService: ReleaseNoteService,
     public dialog: MatDialog,private ref:ChangeDetectorRef,private sanitizer: DomSanitizer, public ticketsService :TicketsService, public mceConfig: TinyMceConfigComponent) {
  }

  ngOnInit() {
    this.tomorrow.setDate(this.tomorrow.getDate());
    this.today = new Date();
    this.tabLabel = "Release Note";
    this.isChanged = false;
    this.getTinymceConfig();
    this.getResponsibleUserList();
    this.getreleaseNoteList();
    this.scrollCheck();
    this.getNotificationForm();
    this.tenant_name = this.configService.tenantName;
    this.createReleaseForm = this.formBuilder.group({
      releaseDate:  ['', Validators.required],
      responsibleUsers:  ['', Validators.required],
      versionName:  ['', Validators.required],
      ubmFixGitNewup: [''],
      newCommentFeature: [''],
      improveSprint: [''],
      bugFixes: [''],
      ubmNote:[''],
      api: [''],
      ui: [''],
      mobile: [''],
      startTime:[''],
      endTime: [''],
      Message: [''],
      title: [''],
      mailNotification:['']
    });
  }


  getNotificationForm(){
    this.notificationForm = this.formBuilder.group({
      releaseDate1:  ['', Validators.required],
      versionName1:  [''],
      startTime1:[''],
      endTime1: [''],
      Message1: [''],
      title1: [''],
      createdDate: ['']
    });
  }

  getTinymceConfig() {
    this.tinyEditorConfig = this.mceConfig.getTinyMCEConfig(this.tenant_name);
    this.tinyEditorConfig['height'] = 400;
  }

  ngAfterViewInit(): void {
    //calculate the pixel position
      this.parentdiv = document.getElementById("version");
      var rect = this.parentdiv.getBoundingClientRect();
      this.scrollLeft = rect.left;
      this.scrollTop = rect.top;
      this.size = screen.height;
   }

tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  if(tabChangeEvent.index == 0){
    this.isChanged = false;
    this.createReleaseForm.reset();
    this.createReleaseForm.controls.responsibleUsers.setErrors(null);
    this.createReleaseForm.controls.versionName.setErrors(null);
    this.createReleaseForm.controls.releaseDate.setErrors(null);
    this.createReleaseForm.controls.startTime.setErrors(null);
    this.createReleaseForm.controls.endTime.setErrors(null);
    this.createReleaseForm.controls.title.setErrors(null);
    this.createReleaseForm.controls.Message.setErrors(null);
  }
  if(tabChangeEvent.index == 1){
    this.checked=false;
    this.today = new Date();
    this.createReleaseForm.patchValue({
      responsibleUsers : this.configService.userId,
      // ubmFixGitNewup :"<p><strong>Fixes</strong></p><ul><li>Add Fixes</li></ul><p><strong>Git Issues </strong></p><ul><li>Add Git Issue</li></ul><p><strong>New Updates</strong></p><ul><li>Add New Update</li></ul>",
      ubmNote :"<p>Note..</p>",
      newCommentFeature: "<p><strong>New Comments Features</strong></p><ul><li>Add Features</li></ul>",
      improveSprint: "<p><strong>Improved Sprint Features</strong></p><ul><li>Add Features</li></ul>",
      bugFixes: "<p><strong>Bug Fixes</strong></p><ul><li>Add Fixes, Git Issues</li></ul>",
    })
  }
  if(tabChangeEvent.index == 2){
    this.getAllReleaseNotficationList();
    this.notificationForm.reset();
    this.idForUpdate=null;
    this.isDesable=false;
    this.editNot = false
    this.title='Add';
  }
  if(tabChangeEvent.index == 3){

   if(this.editNot === true){
     this.title='Update';
     this.today =this.createdDate;
   }else{
    this.title='Add';
    this.today = new Date();
    this.notificationForm.reset();
    this.idForUpdate=null;
    this.isDesable=false;
    this.editNot = false
   }
  }
}

  //End of on init
  flag = false;
  flag1 = false;
  count = 0;
  id = '';
  obj = '';

  @HostListener('window:mousewheel', ['$event'])
  @HostListener('window:keyup', ['$event'])

  //Scrolling functionality
  scorllData(e) {

   // calculate the percentage of scroll
   var pos = showPosition(this.parentdiv);
   if(pos!=null){
    var scrollPercent = Math.round(pos) + '%';
  }
    function showPosition(elm) {
      var parent = elm.parentNode,
        pos = (elm.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight) * 100;
        if(pos!=null){
          return pos;
        }
     };
  var scrollPercentage = '60%';
    if(scrollPercent == scrollPercentage){
      if(this.releaseNotearr.data.allData.length !== 0){
            this.start = this.limit;
            this.limit = this.limit + 15;
          }
      this.getreleaseNoteList();
    }


    //OnScroll Active class
   var result = document.elementFromPoint(this.scrollLeft, this.scrollTop);
   var count = document.querySelectorAll('.sectionnav').length;
     for (var i = 0; i < count; i++) {
      var totalCount = document.getElementsByClassName('sectionnav');
      if (totalCount!=null) {
        if(result.id!='' && totalCount[i].id!=''){
          if (result.id == totalCount[i].id) {
            var lielement = document.querySelectorAll('a');
            lielement.forEach(function (el) {
              el.classList.remove("activeScroll");
            });
            var results = result.id.replace(/\./g, ' ');
              // set the Active class  onScrolls    
            var elementScroll = document.querySelector(''+'.'+results+'');
            elementScroll.classList.add('activeScroll');
          }
        }

       }
     };

    // Default Bottom scroll Active
    var elements = document.getElementById('releaseDetailsWrapper');
    if(elements!=null){
      if (elements.offsetHeight + elements.scrollTop == elements.scrollHeight) {
        var lielement = document.querySelectorAll('a');
         lielement.forEach(function (el) {
           el.classList.remove("activeScroll");
        });
        var lastelement = document.querySelector(''+'.'+totalCount[totalCount.length -2].id+'');
        lastelement.classList.add('activeScroll');
       }
    }

    var clickId = document.getElementById("sectionnav"+this.id);
    if(clickId!=null){
        //remove padding from scrolling
      if(this.flag == true){
        this.flag = false;
      }else{
        document.getElementById("sectionnav"+this.id).classList.remove('addSpace');
      }
    }

  }

  //Default Active tab
  scrollCheck() {
    var sideDivs = document.getElementById('sideDivs');
    if(sideDivs !=null){
      var hasVerticalScrollbar = sideDivs.scrollHeight > sideDivs.clientHeight;
      if (hasVerticalScrollbar == true) {
        sideDivs.setAttribute("style", "border-right:none;");
      } else {
        sideDivs.setAttribute("style", "border-right:2px solid e4e4e4;");
      }
    }

  }

// tab click functionality
  tabClick(a, obj) {
    var valueId ;
    this.flag = true;
    this.obj = obj;

    valueId = (obj.day != null && obj.version!=null) ? (obj.month+obj.day+obj.version.replace(/\./g,'_')) : ((obj.month != null) ? obj.month : obj.year);
    this.id = valueId;
    // remove the class of activeclick and activescroll (remove the active status theme color)
    var lielements = document.querySelectorAll('a');
    lielements.forEach(function (el) {
      el.classList.remove("activeScroll");
     })

   var element1 = document.querySelectorAll(".secdiv");
    element1.forEach(function (el) {
      el.classList.remove("addSpace");
    });
   var element = document.getElementById("sectionnav" + valueId);
     element.classList.add("addSpace");
    // on click set the active staus (add theme color)
    a.target.classList.add('activeScroll');
  }

  goNext(stepper: MatStepper): void {
    this.submitted = true;
    stepper.next();
  }

  // Responsible User  list service
  getResponsibleUserList() {
    const responsibleUserListSub :Subscription =  this.taskservice.responsibleUserList().subscribe((res: any) => {
      if (res.info.code === 200) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = { 'value': element.userId, 'label': element.fullname };
          this.responsiblearr.push(myObj);
          if (element.isOwner == 1) {
            this.responsibleUser = element.userId;
          }
        });
        this.responsibleUserId = this.responsiblearr;
      }
    });
    this.subscriptions.push(responsibleUserListSub);
  }


  // Save data Web service
  saveTask() {    
          if(this.checked === true){
              this.mailNotify = 1;
            }else{
              this.mailNotify = 0;
            }
    this.tempStartTime=moment(this.createReleaseForm.value.startTime).format('HH:mm');
    this.tempEndTime=moment(this.createReleaseForm.value.endTime).format('HH:mm');
    this.submitted = true;
    this.releaseNoteData = {
      responsibleUserId: Number(this.createReleaseForm.value.responsibleUsers),
      versionName: String(this.createReleaseForm.value.versionName),
      // ubmFixGitNewup: String(this.createReleaseForm.value.ubmFixGitNewup),
      newCommentFeature: String(this.createReleaseForm.value.newCommentFeature),
      improveSprint: String(this.createReleaseForm.value.improveSprint),
      bugFixes: String(this.createReleaseForm.value.bugFixes),
      ubmNote: String(this.createReleaseForm.value.ubmNote),
      releaseDate: moment(this.createReleaseForm.value.releaseDate).format('YYYY-MM-DD'),
      api: String(this.createReleaseForm.value.api),
      ui: String(this.createReleaseForm.value.ui),
      mobile: String(this.createReleaseForm.value.mobile),
      startTime: this.tempStartTime,
      endTime:  this.tempEndTime,
      Message: String(this.createReleaseForm.value.Message),
      title: String(this.createReleaseForm.value.title),
      mainImageUrl:this.firstImgurl ? this.firstImgurl : 'null',
      secondImgUrl:this.secondImgurl ? this.secondImgurl : 'null',
      theirdImgUrl:this.thierdImgurl ? this.thierdImgurl : 'null',
      fourthImgUrl:this.fourthImgurl ? this.fourthImgurl : 'null',
      mailNotification:this.mailNotify
    };
    if (!this.createReleaseForm.value.releaseDate) {
      this.createReleaseForm.controls.releaseDate.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if(this.createReleaseForm.value.responsibleUsers){
      this.reposeuser = false;
      }else{
        this.reposeuser = true;
        this.toastr.error(COMMON.FORM_VALIDATION)
        return;
    }

    if (!this.createReleaseForm.value.versionName) {
      this.createReleaseForm.controls.versionName.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.startTime) {
      this.createReleaseForm.controls.startTime.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.endTime) {
      this.createReleaseForm.controls.endTime.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.title) {
      this.createReleaseForm.controls.title.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.Message) {
      this.createReleaseForm.controls.Message.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }

    const saveNoteSub :Subscription =  this.releaseNoteService.saveNote(this.releaseNoteData).subscribe((result: any) => {
      if (result.info.code == 200) {
        this.toastr.success(result.data.responseMsg);
        this.submitted = false;
        this.finaljsonarray = [];
        this.start = 0;
        this.limit = 15;
        this.releaseNotearr = undefined;
        this.getreleaseNoteList();
        this.tabGroup.selectedIndex = 0;
      } else {
        this.toastr.error(result.data.responseMsg);
      }
    },
      error => {
        this.toastr.error('Please Fill out any One field');
      });
      this.subscriptions.push(saveNoteSub);
  }

  updateReleaseNote(updateId) {
    if(this.checked === true){
      this.mailNotify = 1;
    }else{
      this.mailNotify = 0;
    }
    this.tempStartTime=moment(this.createReleaseForm.value.startTime).format('HH:mm');
    this.tempEndTime=moment(this.createReleaseForm.value.endTime).format('HH:mm');
    this.submitted = true;
    this.releaseNoteData = {
      responsibleUserId: Number(this.createReleaseForm.value.responsibleUsers),
      versionName: String(this.createReleaseForm.value.versionName),
      // ubmFixGitNewup: String(this.createReleaseForm.value.ubmFixGitNewup),
      newCommentFeature: String(this.createReleaseForm.value.newCommentFeature),
      improveSprint: String(this.createReleaseForm.value.improveSprint),
      bugFixes: String(this.createReleaseForm.value.bugFixes),
      ubmNote: String(this.createReleaseForm.value.ubmNote),
      releaseDate: moment(this.createReleaseForm.value.releaseDate).format('YYYY-MM-DD'),
      api: String(this.createReleaseForm.value.api),
      ui: String(this.createReleaseForm.value.ui),
      mobile: String(this.createReleaseForm.value.mobile),
      startTime: this.tempStartTime,
      endTime:  this.tempEndTime,
      Message: String(this.createReleaseForm.value.Message),
      title: String(this.createReleaseForm.value.title),
      mailNotification: this.mailNotify,
      mainImageUrl:this.firstImgurl ? this.firstImgurl : 'null',
      secondImgUrl:this.secondImgurl ? this.secondImgurl : 'null',
      theirdImgUrl:this.thierdImgurl ? this.thierdImgurl : 'null',
      fourthImgUrl:this.fourthImgurl ? this.fourthImgurl : 'null',
    };
    if (!this.createReleaseForm.value.versionName) {
      this.createReleaseForm.controls.versionName.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.startTime) {
      this.createReleaseForm.controls.startTime.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.endTime) {
      this.createReleaseForm.controls.endTime.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.title) {
      this.createReleaseForm.controls.title.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }
    if (!this.createReleaseForm.value.Message) {
      this.createReleaseForm.controls.Message.setErrors({ incorrect: true });
      this.toastr.error(COMMON.FORM_VALIDATION)
      return;
    }

    if(this.createReleaseForm.value.responsibleUsers){
      this.reposeuser = false;
      }else{
        this.reposeuser = true;
        this.toastr.error(COMMON.FORM_VALIDATION)
        return;
    }
    const modifyNoteSub :Subscription =  this.releaseNoteService.modifyReleaseNote(updateId, this.releaseNoteData).subscribe((result: any) => {
      if (result.info.code == 200) {
        this.createReleaseForm.reset();
        this.toastr.success(result.data.responseMsg);
        this.submitted = false;
        this.createReleaseForm.controls.responsibleUsers.setErrors(null);
        this.createReleaseForm.controls.versionName.setErrors(null);
        this.createReleaseForm.controls.releaseDate.setErrors(null);
        this.createReleaseForm.controls.startTime.setErrors(null);
        this.createReleaseForm.controls.endTime.setErrors(null);
        this.createReleaseForm.controls.title.setErrors(null);
        this.createReleaseForm.controls.Message.setErrors(null);
        this.finaljsonarray = [];
        this.start = 0;
        this.limit = 15;
        this.releaseNotearr = undefined;
        this.tabGroup.selectedIndex = 0;
        this.getreleaseNoteList();
      } else {
        this.toastr.error("Release Note Updation Failed");
      }
    },
      error => {
        this.toastr.error("Could not take action because of missing data, please check the form and try again");
      });
      this.subscriptions.push(modifyNoteSub);
  }


  cancelForm() {
    this.createReleaseForm.reset();
    this.tabGroup.selectedIndex = 0;
  }

  getreleaseNoteList() {
    this.isChanged = false;
    this.limitData = {
      limit: this.limit,
      start:this.start,
    };
   if(this.releaseNotearr == undefined || this.releaseNotearr.data.allData.length == 15){
    const getreleaseNoteListSub :Subscription = this.releaseNoteService.getreleaseNoteList(this.limitData).subscribe((res: any) => {
        if (res.info.code === EStatusCode.OK) {
          this.releaseNotearr = res;
         let checkJsonflag = false;
         let flag = false;
          res.data.sidebarList.forEach(ele => {
            let finaljson = {};
            let montharray = []
            finaljson['year'] = ele.year;
            ele.months.forEach(monthele => {
              let monthjson = {}
              monthjson['monthName'] = monthele.monthName;
             let dayarray = [];
              monthele.dates.forEach(dayele => {
                res.data.allData.forEach(rightsideele => {

                  if (rightsideele.versionStamp == dayele.day && rightsideele.year == ele.year) {
                    if(this.finaljsonarray.length !== 0){
                      this.finaljsonarray.forEach(eleyear=>{
                         if(eleyear.year == ele.year){
                           eleyear.montharr.forEach(elemonthyear=>{
                              if(elemonthyear.monthName == rightsideele.monthName){
                                rightsideele.counter=this.count;
                                this.counts++;
                                elemonthyear.daydata.push(rightsideele);
                                checkJsonflag = false;
                                flag = true;
                              }
                           })
                         }
                      })
                   }
                   if(flag == false){
                    dayarray.push(rightsideele);
                    checkJsonflag = true;
                   }else{
                     flag = false;
                   }
                  }
                })

              })
              monthjson['daydata'] = dayarray;
              this.j++;
               montharray.push(monthjson);
           })
            finaljson['montharr'] = montharray;
            if(checkJsonflag == true){
              this.finaljsonarray.push(finaljson);
              checkJsonflag = false;
            }else{
              checkJsonflag = false;
            }
          })
         let finalarray = this.finaljsonarray[0].year;
         setTimeout(function(){
            let years = document.querySelector(".parentYear"+finalarray);
              years.classList.add("activeScroll");
          }, 2000);
        } else {
          this.start = 0;
          this.limit = 0;
        }
      }, error => {
        this.toastr.error('Data is not available');
      });
      this.subscriptions.push(getreleaseNoteListSub);
    }
  }

  deleteNote(id,deleteYear,deleteMonth) {
    this.deleteYear = deleteYear;
    this.deleteMonth = deleteMonth;
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to delete a record. This cannot be undone. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    };
    const dialogRef = this.dialog.open(DeleteEntryDialogComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const gdeleteReleaseNoteSub :Subscription = this.releaseNoteService.deleteReleaseNote(id).subscribe((result: any) => {
          if (result.result.info.code == 200) {
            let maincounter = 0;
          this.finaljsonarray.forEach(function(element,i,object){
            maincounter=+1;

              if(element.year == deleteYear){
                if(element.montharr.length <=1 && element.montharr[0].daydata.length <=1){
                  object.splice(i,1);
                }else{
                  let yearcount = 0;
                  element.montharr.forEach(function(monthele,j){
                  yearcount=+1;

                  if(monthele.daydata.length <= 1 && monthele.monthName === deleteMonth){
                    element.montharr.splice(j,1);
                  }else{
                    let daycount = 0;
                    monthele.daydata.forEach(function(ele,k){
                      daycount=+1;
                      if(ele.id == id){
                       monthele.daydata.splice(k,1);
                      }
                    })
                  }
                })
              }
            }
          });
            var parentYear = document.querySelector('.sectionnav'+deleteYear);
            parentYear.classList.add('activeScroll');
           this.toastr.success(result.result.data.responseMsg);
          } else {
            this.toastr.error('Release Note Not Deleted');
          }

        }, (error) => {
        });
        this.subscriptions.push(gdeleteReleaseNoteSub);
      }

    });

  }



  changeUpdate(releaseNoteId: number) {
    // this.releaseNoteService.updateClientEventListener.next(releaseNoteId);
    this.tabGroup.selectedIndex = 1;
    // this.isUpdate.emit(true);
    this.isChanged = true;
    setTimeout(()=>{
      this.getReleaseNoteDetails(releaseNoteId);
    }, 500)
  }

  getReleaseNoteDetails(releaseNoteId: number) {
    this.updateId = releaseNoteId;
    var tempDate=new Date();

    const getReleaseNoteDetailsSub :Subscription = this.releaseNoteService.getReleaseNoteDetails(releaseNoteId).subscribe((res: any) => {
      if (res.info.code == 200) {
        if(res.data[0].startTime&&res.data[0].endTime){
          this.tempStartTime=res.data[0].startTime.split(":");
          this.tempEndTime=res.data[0].endTime.split(":");
          this.tempStartTime.push("00")
          this.tempEndTime.push("00")
         }
        else{
          this.tempStartTime="00:00:00";
          this.tempEndTime="00:00:00";
        }
        this.toastr.success('Release Note Details Fetched SuccessFully');
         this.createReleaseForm.patchValue({
          responsibleUsers : res.data[0].responsibleUserId,
          releaseDate: res.data[0].releaseDate,
          versionName: res.data[0].versionName,
          newCommentFeature: res.data[0].newCommentFeature,
          improveSprint: res.data[0].improveSprint,
          bugFixes: res.data[0].bugFixes,
          ubmNote: res.data[0].ubmNote,
          newUpdates: res.data[0].newAttribute,
          api: res.data[0].api === 'true',
          ui: res.data[0].ui === 'true',
          mobile: res.data[0].mobile === 'true',
          startTime:new Date(tempDate. getFullYear(),tempDate.getMonth(),tempDate.getDate(),this.tempStartTime[0],this.tempStartTime[1],this.tempStartTime[2]),
          endTime:new Date(tempDate. getFullYear(),tempDate.getMonth(),tempDate.getDate(),this.tempEndTime[0],this.tempEndTime[1],this.tempEndTime[2]),
          Message : res.data[0].message,
          title : res.data[0].title
        })
        this.firstImgurl = res.data[0].mainImageUrl,
        this.secondImgurl = res.data[0].secondImgUrl,
        this.thierdImgurl = res.data[0].theirdImgUrl,
        this.fourthImgurl = res.data[0].fourthImgUrl
      } else {
        this.toastr.error('Release Note details Not found !');
      }
    },
      error => {
        this.toastr.error('Could not take action because of missing data, please check the form and try again');
      });
      this.subscriptions.push(getReleaseNoteDetailsSub);
  }

  checkReleaseDate(value){
    this.releaseDate = moment(value).format('YYYY-MM-DD');
    const getvalidVersionSub :Subscription = this.releaseNoteService.checkReleasedate(this.releaseDate).subscribe((res: any) => {
      if (res.info.code == 200) {
         if (res.data.result.length > 0) {
          this.toastr.error('Please check release note date already exist.');
          this.createReleaseForm.controls.releaseDate.setErrors({ incorrect: true });
         return;
       }
       } else {
        this.toastr.error('Release note not found ');
      }
    },
      error => {
        this.toastr.error('Could not take action because of missing data, please check the form and try again');
      });
      this.subscriptions.push(getvalidVersionSub);
  }

  getvalidVersion(e){
    this.versionValue = e.target.value;
    if(!this.versionValue)
    {
      this.toastr.error('Version Name Empty');
      return;
    }
    this.releaseDate = moment(this.createReleaseForm.value.releaseDate).format('YYYY-MM-DD');
    const getvalidVersionSub :Subscription = this.releaseNoteService.getvalidVersion(this.releaseDate,this.versionValue).subscribe((res: any) => {
      if (res.info.code == 200) {
         this.isvalidVersion = 0;
       } else {
        this.isvalidVersion = 1;
        this.toastr.error('Version Name alredy present');
        if (this.isvalidVersion == 1) {
           this.createReleaseForm.controls.versionName.setErrors({ incorrect: true });
          return;
        }
      }
    },
      error => {
        this.toastr.error('Could not take action because of missing data, please check the form and try again');
      });
      this.subscriptions.push(getvalidVersionSub);
  }

  // Number key Check
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
       return false;

    return true;
    }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.checkReleaseDate(event.value);
  }
  onFileChanged(event) {
    this.file = event.target.files[0]
    const formData: FormData = new FormData();
    if (this.file !== undefined) {
      var dir ="Release Note main image"
      formData.append('directory', dir);
      formData.append('file', this.file, this.file.name);
      this.toastr.warning('Image is uploading please wait till process complete.');
      this.releaseNoteService.uploadImageFiles(formData).subscribe((res: any) => {
        if (res.info.code === 200) {
          this.firstImgurl = '"'+res.data.list[0].url+'"';
          this.toastr.success(res.data.responseMsg);
        }else{
          this.toastr.error(res.data.responseMsg);
        }
      })
   }
  }
  onSecondFileUpload(event) {
    this.file = event.target.files[0]
    const formData: FormData = new FormData();
    if (this.file !== undefined) {
      var dir ="Release Note main image"
      formData.append('directory', dir);
      formData.append('file', this.file, this.file.name);
      this.toastr.warning('Image is uploading please wait till process complete.');
      this.releaseNoteService.uploadImageFiles(formData).subscribe((res: any) => {
        if (res.info.code === 200) {
          this.secondImgurl = '"'+res.data.list[0].url+'"';
          this.toastr.success(res.data.responseMsg);
        }else{
          this.toastr.error(res.data.responseMsg);
        }
      })
   }
  }
  onThierdFileUpload(event) {
    this.file = event.target.files[0]
    const formData: FormData = new FormData();
    if (this.file !== undefined) {
      var dir ="Release Note main image"
      formData.append('directory', dir);
      formData.append('file', this.file, this.file.name);
      this.toastr.warning('Image is uploading please wait till process complete.');
      this.releaseNoteService.uploadImageFiles(formData).subscribe((res: any) => {
        if (res.info.code === 200) {
          this.thierdImgurl = '"'+res.data.list[0].url+'"';
          this.toastr.success(res.data.responseMsg);
        }else{
          this.toastr.error(res.data.responseMsg);
        }
      })
   }
  }
  onFourthFileUpload(event) {
    this.file = event.target.files[0]
    const formData: FormData = new FormData();
    if (this.file !== undefined) {
      var dir ="Release Note main image"
      formData.append('directory', dir);
      formData.append('file', this.file, this.file.name);
      this.toastr.warning('Image is uploading please wait till process complete.');
      this.releaseNoteService.uploadImageFiles(formData).subscribe((res: any) => {
        if (res.info.code === 200) {
          this.fourthImgurl = '"'+res.data.list[0].url+'"';
          this.toastr.success(res.data.responseMsg);
        }else{
          this.toastr.error(res.data.responseMsg);
        }
      })
   }
  }
  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    var tempReleaseDate = moment(event.value).format('YYYY-MM-DD');
  }
  saveNotification(){
    this.tempStartTime1=moment(this.notificationForm.value.startTime1).format('HH:mm');
    this.tempEndTime1=moment(this.notificationForm.value.endTime1).format('HH:mm');
    var releaseNotificationData = {
      versionName: String(this.notificationForm.value.versionName1),
      releaseDate: moment(this.notificationForm.value.releaseDate1).format('YYYY-MM-DD'),
      startTime: this.tempStartTime1,
      endTime:  this.tempEndTime1,
      Message: String(this.notificationForm.value.Message1),
      title: String(this.notificationForm.value.title1),
      createdDate: moment(this.date.value).format('YYYY-MM-DD')
    };
    this.releaseNoteService.saveNotification(releaseNotificationData).subscribe((res: any) => {
      if (res.info.code === 200) {
        this.toastr.success(res.data.responseMsg);
        this.submitted = false;
        this.finaljsonarray = [];
        this.start = 0;
        this.limit = 15;
        this.releaseNotearr = undefined;
        this.getreleaseNoteList();
        this.notificationForm.reset();
        this.tabGroup.selectedIndex = 2;
      } else {
        this.toastr.error(res.data.responseMsg);
      }
    })
  }
  getAllReleaseNotficationList(){
    this.releaseNoteService.getAllNotification().subscribe((res: any) =>{
          if (res.info.code === 200) {
          const users = res.data;
          this.dataSource = new MatTableDataSource(users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          } else {
            this.toastr.error(res.data.responseMsg);
          }
    })
  }

  updateNotification(id){
    this.editNot=true;
   this.tabGroup.selectedIndex = 3;
   this.isDesable=true;
   this.releaseNoteService.getNotificationById(id).subscribe((res: any) =>{
    this.idForUpdate=res.data[0].id;
    this.createdDate=moment(res.data[0].createdDate).format('YYYY-MM-DD')
      var tempDate=new Date();
      if(res.data[0].startTime&&res.data[0].endTime){
        this.tempStartTime=res.data[0].startTime.split(":");
        this.tempEndTime=res.data[0].endTime.split(":");
        this.tempStartTime.push('00')
        this.tempEndTime.push('00')
      }
      else{
        this.tempStartTime="00:00:00";
        this.tempEndTime="00:00:00";
      }
    this.notificationForm.patchValue({
      releaseDate1 : res.data[0].releaseDate,
      versionName1 : res.data[0].versionName, 
      Message1 : res.data[0].message, 
      title1 : res.data[0].title, 
      createdDate : this.createdDate ,
      startTime1:new Date(tempDate. getFullYear(),tempDate.getMonth(),tempDate.getDate(),this.tempStartTime[0],this.tempStartTime[1],this.tempStartTime[2]),
      endTime1:new Date(tempDate. getFullYear(),tempDate.getMonth(),tempDate.getDate(),this.tempEndTime[0],this.tempEndTime[1],this.tempEndTime[2]),
    })
   })
  }
  deleteNotification(id){
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to delete a record. This cannot be undone. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    };
    const dialogRef = this.dialog.open(DeleteEntryDialogComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    }); 

     dialogRef.afterClosed().subscribe((res: boolean) => {
              if(res === true){
                this.releaseNoteService.deleteNotificationById(id).subscribe((res: any) =>{
              if(res.info.code == 200){
                this.getAllReleaseNotficationList();
              this.toastr.success(res.data.responseMsg);
              }else{
              this.toastr.error(res.data.responseMsg); 
              }        
          })
      }
    })

  }
  updateNotificationById(){
    this.tempStartTime1=moment(this.notificationForm.value.startTime1).format('HH:mm');
    this.tempEndTime1=moment(this.notificationForm.value.endTime1).format('HH:mm');
    var releaseNotificationData = {
      id:this.idForUpdate,
      versionName: String(this.notificationForm.value.versionName1),
      releaseDate: moment(this.notificationForm.value.releaseDate1).format('YYYY-MM-DD'),
      startTime: this.tempStartTime1,
      endTime:  this.tempEndTime1,
      Message: String(this.notificationForm.value.Message1),
      title: String(this.notificationForm.value.title1),
      createdDate: moment(this.createdDate).format('YYYY-MM-DD')
    };
    this.releaseNoteService.updateNotification(releaseNotificationData).subscribe((res: any) => {
      if (res.info.code === 200) {
        this.toastr.success(res.data.responseMsg);
        this.getAllReleaseNotficationList();
        this.notificationForm.reset();
        this.tabGroup.selectedIndex = 2;
        this.idForUpdate=null;
        this.isDesable=false;
        this.editNot === false
      } else {
        this.toastr.error(res.data.responseMsg);
        this.tabGroup.selectedIndex = 2;
        this.idForUpdate=null;
        this.isDesable=false;
        this.editNot === false
      }
    })
  }
  cancleNofifyForm(){
    this.notificationForm.reset();
    this.tabGroup.selectedIndex = 2;
    this.idForUpdate=null;
    this.isDesable=false;
    this.editNot = false
  }
}
