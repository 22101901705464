import { AuthGuard } from '../../app.authGuard.service';

const supportRoutes = [
    {
        path: 'support/tickets',
        canActivate: [AuthGuard],
        data: { title: 'Support', status: false },
        loadChildren: () => import('./tickets/tickets.routes')
    },
    {
        path: 'support/project',
        canActivate: [AuthGuard],
        data: { title: 'Support', status: false },
        loadChildren: () => import('./projects/projects.routes')
    },
    {
        path: 'support/bugs',
        canActivate: [AuthGuard],
        data: { title: 'Support', status: false },
        loadChildren: () => import('./bugs/bugs.routes')
    },
    {
        path: 'support/rma',
        canActivate: [AuthGuard],
        data: { title: 'Support', status: false },
        loadChildren: () => import('./rma/rma.routes')
    }
];

export default supportRoutes;
