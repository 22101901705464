import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-technology-stack',
    templateUrl: './technology-stack.component.html',
    styleUrls: ['./technology-stack.component.css'],
    standalone: true,
    imports: [MatIcon]
})
export class TechnologyStackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
