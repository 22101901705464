import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../service/config.service';
import { Subscription } from 'rxjs';
import { TenantExistsService } from '../../service/tenant-exists.service';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AgreementService } from '../../service/agreement.service';
import { TicketsService } from '../../service/tickets.service';
import { EStatusCode } from '../../service/Utils/Interfaces.class';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor, CommonModule } from '@angular/common';

@Component({
    selector: 'app-review-submit',
    templateUrl: './review-submit.component.html',
    styleUrls: ['./review-submit.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, NgFor, MatIconButton, MatTooltip, MatIcon, MatFormField, MatInput, ReactiveFormsModule, CommonModule]
})
export class ReviewSubmitComponent implements OnInit, OnDestroy {

  hostname: string;
  ticketName: string;
  ticketSubject: string;
  ticketId: string;
  private readonly subscriptions: Subscription[] = [];
  ratingArr = [...Array(5)].map((x,i) => i);
  rating: number = 4;
  ratingtooltipArr = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];
  reviewMessage = new FormControl('', Validators.required);
  submitted = false;
  companyLogo: string;
  companyName: string;
  ipAddress: string;
  message: string = '';
  moduleType: string;
  isRated: number = 0;
  projectName: string;

  constructor(
    private configService: ConfigService,
    private tenantExistsService: TenantExistsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private agreementService: AgreementService,
    private ticketService: TicketsService) {
    this.configService.updateIsUserLogged.next(false);
    this.rating = this.activatedRoute.snapshot.queryParams['s'];
    this.ticketId = this.activatedRoute.snapshot.paramMap.get('id');
    this.moduleType = this.activatedRoute.snapshot.paramMap.get('module');
  }

  ngOnInit() {
    this.getIPAddress();
    this.hostname = window.location.hostname;
    // this.hostname = '<enter domain name>'; // For local testing eg., 'utiliko.xyz'
    this.checkDomain(this.hostname);
  }

  checkDomain(domain) {
    if (domain) {
      domain = domain.replace(/ /g, "").toLowerCase().trim();
      const isDomainExists : Subscription = this.tenantExistsService.domainExists(domain).subscribe((res: any) => {
        if (!res.data.isDomainExist) {
          this.router.navigate(["/page-not-found"], { replaceUrl: true });
        } else {
          this.companyName = res.data.companyName;
          this.companyLogo = res.data.companyLogo;
        }
      });
      this.subscriptions.push(isDomainExists);
    }
  }

  onClick(index: number) {
    this.rating = index;
  }

  submitReview(){
    this.submitted = true;
    if(!this.reviewMessage.invalid){
      this.message = this.reviewMessage.value.trim();
      this.isRated = 1;
      this.saveTicketReview('submitform');
    }
  }

  getIPAddress() {
    const ipAddressValue : Subscription = this.agreementService.getIPAddress().subscribe((res:any)=>{
      if(res && res.origin) {
        this.ipAddress = res.origin;
        this.saveTicketReview('onload');
      }
    });
    this.subscriptions.push(ipAddressValue);
  }

  saveTicketReview(type?){
    this.message = this.message ? this.message : null;
    const ticketReview : Subscription = this.ticketService.saveTicketReview(this.ticketId, this.rating, this.message, this.isRated, this.ipAddress, this.moduleType).subscribe((res:any)=>{
      if (res.info.code == EStatusCode.OK) {
        const ticketData = res.data.ticketData;
        if(type === 'onload') {
          if(this.moduleType === 'tickets'){
            this.ticketName = ticketData.ticketCode;
            this.ticketSubject = ticketData.subject;
          }
          
          if(this.moduleType === 'project'){
            this.projectName = ticketData.projectName;
          }
        }
        if(type === 'submitform') {
          this.reviewMessage.reset();
          this.ticketService.hideGoToHome.next(true);
          this.ticketService.thankYouMessage.next('Thank you for your valuable feedback. This will help us to improve our services.');
          this.router.navigate(['thank-you']);
        }
      }

      if (res.info.code == EStatusCode.FAILURE && type === 'onload') {
        this.ticketService.hideGoToHome.next(true);
        this.ticketService.thankYouMessage.next('You have already shared your feedback. For further assistance you can reach out to our support team.');
        this.router.navigate(['thank-you']);
      }
    }, (err) => {
      console.log(err);
    });
    this.subscriptions.push(ticketReview);
  }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription : Subscription) => {
      if(subscription) {
        subscription.unsubscribe();
      }
    })
  }

}
