
<div class="api-docs-back-btn">
    <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
    </section>
    </div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
    AccessRules Model
  </h2>
  <p><code><i>List</i></code></p>
  <ul>
  <li>
      This API will list all the access rules(view, edit) from `tbl_access_rules`.
  </li>
  <li>
      For reference: <a href="{{path}}explorer/#!/AccessRules/AccessRules_list">
      <p>{{path}}/explorer/#!/AccessRules/AccessRules_list</p>
    
    </a>
  </li>
</ul>
</div>
</mat-card>