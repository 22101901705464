import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-mailbox-model',
    templateUrl: './mailbox-model.component.html',
    styleUrls: ['./mailbox-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class MailboxModelComponent implements OnInit {
  htmlStr = '{ "neq":  null }';
  public path:any;
  constructor() { }

  ngOnInit() {
    this.path = environment.BASE_URL;
  }

}
