import { Component, inject, OnDestroy, OnInit, ElementRef, ViewChild, Signal, viewChild } from '@angular/core';
import { ConfigService } from '../../../service/config.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import CryptoJS from 'crypto-js';
import { COMMON } from '../../../service/constant';
import { CurrentUserService } from '../shared/services/current-user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SoftphoneStatus } from '../shared/enums/softphone-status';
import { ToastrService } from 'ngx-toastr';
import { MatFabButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-softphone',
    templateUrl: './softphone.component.html',
    styleUrls: ['./softphone.component.scss'],
    standalone: true,
  imports: [NgIf, MatFabButton, MatTooltipModule]
})
export class SoftphoneComponent implements OnInit, OnDestroy {
  public toastr: ToastrService = inject(ToastrService);
  public showSoftPhone = false;
  public iframeWidth: string;
  public iframeHeight: string;
  private softPhoneDomain: string;
  public softPhoneUrl: SafeUrl;
  private userId: number;
  private destroy$ = new Subject<void>();
  private eventListenerBound: any;
  private callerId: string;
  private softphoneFrame: HTMLIFrameElement | null;
  private messageId: string;

  public readonly iframeDialpadWrapper: Signal<ElementRef> = viewChild('iframeDialpadWrapper');

  constructor(
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
    private currentUserService: CurrentUserService
  ) {
    this.currentUserService.getCallerId
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (res) {
        this.callerId = res;
        if (!this.showSoftPhone) {
          this.showSoftPhone = true;
        }

        this.checkSoftphoneStatus();
        this.currentUserService.setCallerId(null);
      }
    });

    this.currentUserService.updateLoggedInUser
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => {
      if (res) {
        this.doLogout();
        this.currentUserService.setUpdateLoggedInUser(false);
      }
    });
  }

  ngOnInit() {
    this.setIframeWidthHeight();
    this.userId = this.configService.userId;
    this.softPhoneDomain = environment.PBX_SOFTPHONE_DOMAIN;
    const softPhoneURL = `${this.softPhoneDomain}/?platform=UTILIKO&platformuserid=${this.userId}`;
    this.softPhoneUrl = this.sanitizer.bypassSecurityTrustResourceUrl(softPhoneURL);
    this.softphoneFrame = document.getElementById('softphoneFrame') as HTMLIFrameElement | null;
    this.initializeEventListener();
  }

  public toggleSoftphone() {
    this.showSoftPhone = !this.showSoftPhone;
  }

  public closeSoftphone() {
    this.showSoftPhone = false;
    if (this.iframeDialpadWrapper()) {
      this.iframeDialpadWrapper().nativeElement.style.transform = 'none';
    }
  }

  private listenToSoftphoneEvents = (event): void => {
    if (event.origin !== this.softPhoneDomain) {
      return;
    }

    switch (event.data.action) {
      case 'onDomainLoaded':
        if (this.softphoneFrame && this.softphoneFrame.contentWindow) {
          this.doLogin();
        }
        break;
      case 'onUserLoggedIn':
        this.currentUserService.setIsUserLoggedIn(true);
        break;
      case 'onUserLoggedOut':
        this.setIframeSrc();
        this.currentUserService.setIsUserLoggedIn(false);
        break;
      case 'onIncomingCall':
        if (event.data && event.data.data.phoneNumber) {
          this.showSoftPhone = true;
        }
        break;
      case 'onCallAccepted':
        break;
      case 'onCallDeclined':
        break;
      case 'onSoftphoneSizeChanged':
        const data = event.data.data;
        this.setIframeWidthHeight(data.width, data.height);
        break;
    }
  }

  private decryptPassword(encryptedData: string): string {
    const key = CryptoJS.enc.Utf8.parse(COMMON.SECRETAES256.key);
    const iv = CryptoJS.enc.Utf8.parse(COMMON.SECRETAES256.iv);

    const encryptedDataWordArray = CryptoJS.enc.Hex.parse(encryptedData);

    const decrypted = CryptoJS.AES.decrypt(
        // @ts-ignore
      { ciphertext: encryptedDataWordArray },
      key,
      {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  private async checkSoftphoneStatus(): Promise<void> {
    this.messageId = this.generateUniqueId();
    const response = await this.sendMessageToIframe({
        action: 'doCheckSoftphoneMode',
        data: {messageId: this.messageId}
    });
    if (response && SoftphoneStatus[response.mode] === 'STAND_BY') {
      this.softphoneFrame.contentWindow.postMessage({
        action: 'doDial',
        data: { phoneNumber: this.callerId }
      }, '*');
    } else {
      this.toastr.error(`Please verify your webphone's status and ensure it is connected before attempting to make a call.`);
      this.currentUserService.setCallerId(null);
    }
  }

  private initializeEventListener(): void {
    if (this.eventListenerBound) {
      window.removeEventListener('message', this.eventListenerBound);
    }

    this.eventListenerBound = this.listenToSoftphoneEvents.bind(this);
    window.addEventListener('message', this.eventListenerBound);
  }

  async sendMessageToIframe(message): Promise<any> {
    const messageId = this.messageId;

    return new Promise((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        const responseData = event.data.data;

        if (responseData.messageId === messageId) {
          resolve(responseData);
          this.removeEventListener(messageHandler);
        }
      };

      window.addEventListener('message', messageHandler);

      this.softphoneFrame.contentWindow.postMessage(message, '*');
    });
  }

  private generateUniqueId(): string {
    return 'msg-' + Math.random().toString(36).substr(2, 9);
  }

  private removeEventListener(eventListener): void {
    this.messageId = null;
    window.removeEventListener('message', eventListener);
  }

  private setIframeWidthHeight(width = 332, height = 560): void {
    this.iframeWidth = `${width}`;
    this.iframeHeight = `${height}`;
  }

  private doLogin(): void {
    this.softphoneFrame.contentWindow.postMessage({
      action: 'doLogin',
      data: {
        username: this.configService.webphoneUsername,
        password: this.decryptPassword(this.configService.webphonePassword)
      }
    }, '*');
  }

  private doLogout(): void {
    this.softphoneFrame.contentWindow.postMessage({
      action: 'doLogout'
    }, '*');
  }

  private setIframeSrc(): void {
    const softPhoneURL = `${this.softPhoneDomain}/?platform=UTILIKO&platformuserid=${this.userId}`;
    this.softPhoneUrl = this.sanitizer.bypassSecurityTrustResourceUrl(softPhoneURL);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.removeEventListener(this.eventListenerBound);
  }
}
