<div id="opportunityCom">
  <!-- Content Header (Page header) -->
  <app-sub-header [icon]="'fa fa-tasks'" [title]="'Task'"></app-sub-header>
  <!-- Main content -->
  <section class="content">
    <div id="task-cont-dev">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup  (selectedTabChange)="onLinkClick($event)">
        <mat-tab label="All Tasks" *ngIf="permissionService.tasks.view"  >
          <ng-template matTabContent>
            <app-task-list [tabGroup]="tabGroup" (isUpdate)="isTabChange($event)"></app-task-list>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{ tabLabel }}" [@fadeInOutTranslate] *ngIf="permissionService.tasks.edit  && !isChanged">
          <ng-template matTabContent>
            <app-task-form [tabGroup]="tabGroup" (changedTabLabel)="changedTabLabel($event)" ></app-task-form>
          </ng-template>
        </mat-tab>

        <mat-tab label="{{ tabLabel }}" [@fadeInOutTranslate] *ngIf="permissionService.tasks.edit  && isChanged" id="mat-mdc-tab-0-1">
          <ng-template matTabContent>
            <app-task-form [tabGroup]="tabGroup" (changedTabLabel)="changedTabLabel($event)" (isNavigate)="isTabChange($event)"></app-task-form>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </section>
</div>
