import { ApplicationFormComponent } from './../../job-application/application-form/application-form.component';
import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '../../../../shared/AppComponentBase';
import { ActivatedRoute } from '@angular/router';
import { COMMON } from '../../../../../service/constant';

import { IResponse, EStatusCode } from '../../../../../service/Utils/Interfaces.class';
import CryptoJS from 'crypto-js';
import {JobcircularService} from "../../../../../service/jobcircular.service";
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../../shared/spinner.component';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-job-post-view',
    templateUrl: './job-post-view.component.html',
    styleUrls: ['./job-post-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatTooltip, MatButton, SpinnerComponent, CommonDatePipe, SafePipe, TranslateModule]
})
export class JobPostViewComponent extends AppComponentBase implements OnInit {
  public version: string;
  public jobCircularId: any;
  public companyLogo: string;
  public themeData: string;
  public companyName: string;
  public jobDetList: any;
  public showDetail: boolean;
  public error: boolean = false;
  salt: any;
  secret: any;
  saltData: any;
  secretData: any;
  constructor(inject: Injector, private route: ActivatedRoute , private jobcircularService: JobcircularService) {
    super(inject);
    this.version = COMMON.VERSION;
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    this.jobCircularId = this.route.snapshot.paramMap.get('id');
    this.salt = this.route.snapshot.paramMap.get('salt');
    this.secret = this.route.snapshot.paramMap.get('secret');
    var originalsalt = decodeURIComponent(this.salt);
    var originalsecret = decodeURIComponent(this.secret);
    this.saltData = JSON.parse(CryptoJS.AES.decrypt(originalsalt.toString(), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8));
    this.secretData = JSON.parse(CryptoJS.AES.decrypt(originalsecret.toString(), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8));
    // this.jobCircularId = this.route.snapshot.paramMap.get('id');
    this.getJobDetails();
    this.version = COMMON.VERSION;
    this.companyLogo = this.configService.companyLogo;
    this.companyName = this.configService.companyName;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
  }

  getJobDetails() { 
    this.jobcircularService.getJobViewDetails(this.jobCircularId, this.saltData, this.secretData).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        this.error = false;
        const str = JSON.stringify(res.data);
        this.jobDetList = JSON.parse(str);
        this.companyName = this.jobDetList.companyName;
        this.companyLogo = this.jobDetList.companyLogo;
      } else {
        this.showDetail = true;
        this.error = true;
        this.toastr.error('Job Not Found!');
      }
    })
  }

  applyToJob() {
    let setWidth : string;
    if (this.configService.tsysCcStatus === 'inactive') {
      setWidth = 'auto';
    } else {
      setWidth = COMMON.JOB_APPLICATION_WIDTH
    }
    const dialogRef = this.dialog.open(ApplicationFormComponent, {
        width: setWidth,
        autoFocus: false,
        disableClose: true,
        panelClass: [
          "animated",
          "slideInDown",
        ],
        data: {
          eGroup: 'jobApplication',
          msg: 'jobApplication',
          title: 'Job Application for ' + this.jobDetList.jobPosition,
          jobCircularId: this.jobCircularId,
          jobDetails: this.jobDetList,
        }
      });

    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        if (result.isSuccess === 1 && result.isModelClosed === 0) {
          this.toastr.success('Job applied successfully');
        } else if (result.isSuccess === 0 && result.isModelClosed === 1) {
          this.toastr.warning('Job Application cancelled!');
        } else if (result.isSuccess === 0 && result.isModelClosed === 0) {
          this.toastr.warning('Job Application failed! Please try again later.');
        }
      }
    });

    
  }

  goback() {
    this.routerDialog.navigateByUrl('career');
  }
  
  /* downloadPDF(jobCircularId){
    console.log("Download", jobCircularId);
  } */
}
