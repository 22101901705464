
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Subscribe Payment Info Model</h2>
 <p><i><code>listByUserId</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the list of subscribed payment information from `tbl_subscribe_payment_info` by using tenantCompanyKey of logged in user. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_listByUserID"><u>{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_listByUserID</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>paymethodDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of subscribed payment information from `tbl_subscribe_payment_info` by <strong>paymentInfoId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_paymentMethodDetails"><u>{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_paymentMethodDetails</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>reservedSeatItemDetails</code></i></p> 
     <ul>
         <li>
                This API will fetch the reserved seat item details from `tbl_saved_items` by providing `item_name` LIKE "%Seats Reserved%" AND `isDefaultItem` = 1. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_reservedSeatItemDetails"><u>{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_reservedSeatItemDetails</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>validateCC</code></i></p> 
     <ul>
         <li>
                This API is used to verify the credit card details by providing valid <strong>cvc</strong> and <strong>paymentInfoId</strong> from `tbl_subscribe_payment_info`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_validateCC"><u>{{path}}explorer/#!/subscribePaymentInfo/subscribePaymentInfo_validateCC</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
