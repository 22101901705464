import {Component, Inject, Injector, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog'
import {AlertCommonDialogData} from '../../../service/Utils/Interfaces.class';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-common-dialog-model',
    templateUrl: './delete-entry-dialog.component.html',
    styleUrls: ['./delete-entry-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ExtendedModule, MatDialogTitle, CdkScrollable, MatDialogContent, MatTooltip, NgFor, MatDialogActions, MatButton]
})
export class DeleteEntryDialogComponent implements OnInit {

  request: any;
  convert_to_client: boolean;
  automation: boolean;
  isCardDelete: boolean;
  showInvoice: boolean;
  /* Timer Count */
  activeTimerCount: number;
  activeTimerList: [];
  isLinked:boolean = false;

  constructor(inject: Injector, public dialogRef: MatDialogRef<DeleteEntryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public alertCommonDialogData: AlertCommonDialogData) {
   
    this.request = alertCommonDialogData;
  }

  ngOnInit() {
    if(this.request.actionType === 'convertToProject' || this.request.actionType === 'automation'){
      this.alertCommonDialogData = this.request.alertCommonDialogData;
      this.request.actionType === 'convertToProject' ? this.convert_to_client = true : this.convert_to_client = false;
      this.request.actionType === 'automation' ? this.automation = true : this.automation = false;
    }
    this.isCardDelete = this.request.isCardDelete;
    this.activeTimerCount = (this.request.activeTimersCount) ? this.request.activeTimersCount : 0;
    this.activeTimerList = (this.request.activeTimerList) ? this.request.activeTimerList : [];
    if(typeof this.alertCommonDialogData['isLinked'] !== 'undefined'){
      this.isLinked = this.alertCommonDialogData['isLinked'];
    }
  }

  cancelButton() {
    this.dialogRef.close(false);
  }


  submitButton() {
    this.dialogRef.close(true);
  }


}
