import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-status-model',
    templateUrl: './status-model.component.html',
    styleUrls: ['./status-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class StatusModelComponent implements OnInit {

 
  public path:any;
  constructor() { }

  ngOnInit() {
    this.path = environment.BASE_URL;
  }

}
