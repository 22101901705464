
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Config Model </h2>
<h4>Note:</h4>
<ul>
  <li>
      In config model all the values are updated/created into `tbl_config` by tenantCompanyKey.
  </li>
  <li>
      Unique fields: config_key
  </li>
  <li>
      Changes which are done in settings module will be reflected in the main module. 
      (Ex: changes done in invoice settings will be reflected in invoice module)
  </li>
</ul>
<br />

<p><i><code>actonSettings</code></i></p>
 
<ul>
<li>
    This API is on hold.
</li>
</ul>
<br />

<p><i><code>Company</code></i></p> 
<ul>
<li>
    This API is used to update the company details into `tbl_config` from the settings module.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_company">
        {{path}}explorer/#!/Config/Config_company</a>
</li>
</ul>
<br />

<p><i><code>createNewProposal</code></i></p>
<ul>
<li>
    This API will create a new proposal template into `tbl_email_templates`.
</li>
<li>
    Before creating the template it will update the config values for these config_keys ['proposal_prefix', 'proposal_first_reminder', 'proposal_second_reminder', 'proposal_third_reminder'] into `tbl_config` 
    if config_key exists for that tenantCompanyKey. Else it will insert the values into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_createNewProposal">
        {{path}}explorer/#!/Config/Config_createNewProposal</a>
</li>
</ul>
<br />

<p><i><code>cronjobSettings</code></i></p> 
<ul>
<li>
    This API will update the cronJob settings values into `tbl_config` if 
    config_key is present for that tenantCompanyKey else it will insert the values into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_cronjobSettings">
        {{path}}explorer/#!/Config/Config_cronjobSettings</a>
</li>
</ul>
<br />

<p><i><code>Days</code></i></p> 
<ul>
<li>
    This API will update the working days values from the setting page into `tbl_working_days` and it will update the 
    'configKey': 'office_time' with value either 'same_time' or 'different_time' into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_days">
        {{path}}explorer/#!/Config/Config_days</a>
</li>
</ul>
<br />

<p><i><code>emailNotification</code></i></p>
<ul>
<li>
    It will update the email notification setting values into `tbl_config` by moduletype.
</li>
<li>
    Based on notification values(0 - notification disabled, 1 - notification enabled) email will be sent to users.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_emailNotification">
        {{path}}explorer/#!/Config/Config_emailNotification</a>
</li>
</ul>
<br />

<p><i><code>getWorkingDays</code></i></p>
<ul>
<li>
    This API is used to fetch the workingDays from `tbl_working_days` by tenantCompanyKey.
</li>
<li>
    Based on notification values(0 - notification disabled, 1 - notification enabled) email will be sent to users.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_getWorkingDays ">
        {{path}}explorer/#!/Config/Config_getWorkingDays </a>
</li>
</ul>
<br />

<p><i><code>invoiceSettings</code></i></p> 
<ul>
<li>
    This API will update the invoice settings values into `tbl_config` for these config_keys 
    ["invoice_prefix", "invoices_due_after", "invoice_first_reminder", "invoice_second_reminder", "invoice_third_reminder", "default_terms", "show_invoice_tax", "send_email_when_recur", "company_template"](1) if exists. Else it will insert the values into `tbl_config` for the config_keys mentioned in (1).
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_invoiceSettings ">
        {{path}}explorer/#!/Config/Config_invoiceSettings </a>
</li>
</ul>
<br />

<p><i><code>leadSettings</code></i></p> 
<ul>
<li>
    This API will update the lead settings values into `tbl_config` for these config_keys 
    ["default_leads_source", "default_lead_status"](1) if exists. Else it will insert the values into `tbl_config` for the config_keys mentioned in (1).
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_leadSettings ">
        {{path}}explorer/#!/Config/Config_leadSettings </a>
</li>
</ul>
<br /> 

<p><i><code>Notification</code></i></p> 
<ul>
<li>
    This API will update the notification email setting values into `tbl_config`.
</li>
<li>
    Based on email settings(notification email) configuration, email notifications will be sent to users.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_notification">
        {{path}}explorer/#!/Config/Config_notification</a>
</li>
</ul>
<br />

<p><i><code>paymentSettings</code></i></p> 
<ul>
<li>
    This API is used to fetch the payment settings list from `tbl_online_payment` and status of each payment from `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_paymentSettings">
        {{path}}explorer/#!/Config/Config_paymentSettings</a>
</li>
</ul>
<br />

<p><i><code>purchaseOrderSettings</code></i></p>
<ul>
<li>
    This API will update the purchase order settings values into `tbl_config` for these config_keys 
    ['po_prefix', 'show_po_tax', 'po_terms'](1) if exists. Else it will insert the values into `tbl_config` for the config_keys mentioned in (1).
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_purchaseOrderSettings">
        {{path}}explorer/#!/Config/Config_purchaseOrderSettings</a>
</li>
</ul>
<br />

<p><i><code>sendNotificationEmail</code></i></p> 
<ul>
<li>
    This API is used to test the email settings in notification email. If configurations are correct 
    it will send an email. Otherwise, it will throw error.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_sendNotificationEmail">
        {{path}}explorer/#!/Config/Config_sendNotificationEmail</a>
</li>
</ul>
<br />

<p><i><code>System</code></i></p> 
<ul>
<li>
    This API is used to update the values of system settings into `tbl_config` if config_keys exists for the tenantCompanyKey. 
    Otherwise it will insert the values into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_system">
        {{path}}explorer/#!/Config/Config_system</a>
</li>
</ul>
<br />

<p><i><code>Templates</code></i></p> 
<ul>
<li>
    This API is used to update the email templates into `tbl_email_templates` by emailGroup if exists.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_templates">
        {{path}}explorer/#!/Config/Config_templates</a>
</li>
</ul>
<br />

<p><i><code>ticketSettings</code></i></p> 
<ul>
<li>
    This API is used to update the values of ticket settings into `tbl_config` if config_keys exists, 
    else it will insert the values into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_ticketSettings">
        {{path}}explorer/#!/Config/Config_ticketSettings</a>
</li>
</ul>
<br />

<p><i><code>updatePaymentSettings</code></i></p> 
<ul>
<li>
    This API will update the payment setting values into `tbl_config` for corresponding config_keys if 
    exists else it will insert the values with config_keys into `tbl_config`.
</li>
<li>
    Before updating/inserting the values into `tbl_config` it will 
    generate the transaction key for TSYS if `tsys_mid` is present in the request.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_updatePaymentSettings">
        {{path}}explorer/#!/Config/Config_updatePaymentSettings</a>
</li>
</ul>
<br />

<p><i><code>updateProposalTemplate</code></i></p> 
<ul>
<li>
    This API is used to update the proposal template values into 
    `tbl_email_templates` by emailTemplateId.
</li>
<li>
    Before updating the template it will update the config values for these config_keys ['proposal_prefix', 'proposal_first_reminder', 'proposal_second_reminder', 'proposal_third_reminder'] into `tbl_config` if config_key exists 
    for that tenantCompanyKey. Else it will insert the values into `tbl_config`.
  </li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_updateProposalTemplate">
        {{path}}explorer/#!/Config/Config_updateProposalTemplate</a>
</li>
</ul>
<br />

<p><i><code>updateThemeSettings</code></i></p> 
<ul>
<li>
    This API is used to update the theme settings into `tbl_config` for corresponding config_keys if 
    exists else it will insert the values with config_keys into `tbl_config`.
</li>
<li>
    For reference:  <a href="{{path}}explorer/#!/Config/Config_updateThemeSettings">
        {{path}}explorer/#!/Config/Config_updateThemeSettings</a>
</li>
</ul>
</div>
</mat-card>
