
<div class="api-docs-back-btn">
        <section class="content-header">
          <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
                <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                  <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
              </a>    
          </button>
        </section>
  </div>
   
<mat-card>
    <div class="api-docs">
        <h2 class="content-header-name">Goal Type</h2>
 <p><i><code>goalReport</code></i></p>
 <ul>
     <li>
            This API is used to get the goal report of achievement for a particular month in the year by following steps. 

     </li>
     <li>
            <strong>Query </strong>- it will fetch the goal tracking list from `tbl_goal_tracking` if condtion matches. Cond: <strong>'end_date BETWEEN start_date of selected month AND end_date of selected month'</strong>.(returns goalTypeInfo) 

     </li>
     <li>
            <strong>Query </strong>- will fetch the list of goal type from `tbl_goal_type` by tenantCompanyKey.(returns allTypeInfo) 

     </li>
     <li>
            Once the above details fetched successfully, the steps below will be followed through iteration(1) (vType): 
        <ul>
            <li>
                    If goalTypeInfo exists, <strong>Query </strong>will fetch the goal_type_name from  `tbl_goal_type` by goal_type_id from goalTypeInfo. 

            </li>
            <li>
                    The below process will be executed through iteration(2)(vItem) from allTypeInfo. 

            </li>
            <li>
                    If goal_type_id from vType and vItem matches getProgress method will be called. Else it will call the next iteration. 

            </li>
            <li>
                    Once iteration (2) completed next iteration will be called from iteration (1). 

            </li>
        </ul>
     </li>
     <li>
            getProgress - this method is used to get the progress of each goal by tbl_name from `tbl_goal_type`. 
            <ul>
                <li>
                        <strong>Query </strong>- will fetch the details of goal by goal_type_id from `tbl_goal_type`. 

                </li>
                <li>
                        If goalInfo.<strong>achievement</strong> &lt;= result from each condition below: progress=100 else progress = (result / goalInfo.<strong>achievement</strong>) * 100. 

                </li>
                <li>
                        If tbl_name === <strong>'tbl_transactions'</strong>  
                        <ul>
                            <li>
                                    <strong>Query</strong> - It will fetch the sum(amount) from `tbl_transactions` if condition satisfied. Cond: <strong>date &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> <strong>AND date &lt;=</strong> goalInfo.<strong>end_date</strong>. 

                            </li>
                            <li>
                                    Response amount will be returned with default money_format, currency_symbol and currency_position from `tbl_config`. 

                            </li>
                        </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_invoices' || </strong>tbl_name === <strong>'tbl_estimates'</strong> 
                        <ul>
                            <li>
                                    <strong>Query</strong> - will fetch the details from tbl_name if <strong>date_saved &gt;=</strong> goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND date_saved &lt;= </strong>goalInfo.<strong>end_date </strong>+ <strong>"23:59:59".</strong> 
                            </li>
                        </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_task'</strong>  
                        <ul>
                            <li>
                                    <strong>Query</strong> - will fetch the details from `tbl_task` if <strong>task_created_date &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND task_created_date &lt;= </strong>goalInfo.<strong>end_date</strong> + <strong>" 23:59:59" </strong>+ <strong>'AND task_status =completed</strong> 

                            </li>
                        </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_bug'</strong>  
                        <ul>
                            <li>
                                    <strong>Query</strong> - will fetch the details from `tbl_bug` if <strong>update_time &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND update_time &lt;= </strong>goalInfo.<strong>end_date</strong> + <strong>" 23:59:59" </strong>+ <strong>'AND bug_status = resolved.</strong> 

                            </li>
                        </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_client'</strong>  
                    <ul>
                        <li>
                                <strong>Query</strong> - will fetch the details from `tbl_client` using below conditions. 
                        </li>                 
                        <li>
                        If goalType.<strong>type_name </strong>== <strong>'convert_leads_to_client'</strong> 
                        Cond: <strong>date_added &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND date_added &lt;= </strong>goalInfo.<strong>end_date</strong> + <strong>" 23:59:59" </strong>+ <strong>'AND leads_id!=0.</strong> 
                        </li>
                        <li>
                                Else Cond: <strong>date_added &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND date_added &lt;= </strong>goalInfo.<strong>end_date</strong> + <strong>" 23:59:59" </strong>+ <strong>'AND leads_id=0.</strong> 
                        </li>
                    </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_payments'</strong> 
                        <ul>
                            <li>
                                    <strong>Query</strong> - it will fetch the currency and sum(amount) from `tbl_payments` if <strong>payment_date &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> <strong>AND payment_date &lt;=</strong> goalInfo.<strong>end_date</strong>. 
                            </li>
                        </ul>
                </li>
                <li>
                        If tbl_name === <strong>'tbl_project'</strong>  
                    <ul>
                        <li>
                                <strong>Query</strong> - it will fetch the details from `tbl_project` if <strong>created_time &gt;= </strong>goalInfo<strong>.</strong><strong>start_date</strong> + <strong>"00:00:00" </strong>+ <strong>'AND created_time &lt;= </strong>goalInfo.<strong>end_date</strong> + <strong>" 23:59:59" </strong>+ <strong>'AND project_status =completed</strong> 
                        </li>
                    </ul>
                </li>
            </ul>
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/GoalType/GoalType_goalReport"><u>{{path}}explorer/#!/GoalType/GoalType_goalReport</u></a> 
     </li>
 </ul> 
 </div>  
</mat-card>
