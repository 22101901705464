<div class="proposal-signature-dialog-wrapper tw-p-2">
  <h1 mat-dialog-title class="modal-title m-b-0">{{data.title}}</h1>
  <mat-divider></mat-divider>
  <div class="modal-body">
    <div mat-dialog-content>
      <form [formGroup]="proposalForm" #form="ngForm" (ngSubmit)="saveSignature()">
        <mat-form-field class="example-full-width tw-pt-2" appearance="outline">
          <input matInput type="text" placeholder="First name" formControlName="firstName" required name="firstName">
          <mat-label>First Name</mat-label>
        </mat-form-field>
        <mat-form-field class="example-full-width tw-mt-4" appearance="outline">
          <input matInput type="text" placeholder="Last name" formControlName="lastName" required name="lastName">
          <mat-label>Last Name</mat-label>
        </mat-form-field>
      </form>
      <div class="signature-pad-wrapper tw-mt-4">
        <div class="sign-image">
          <img src="assets/images/sign-arrow.webp" alt="signature" class="img-responsive"/>
        </div>
        <signature-pad [options]="signaturePadOptions">
        </signature-pad>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="clear()" class="cancelbtn">Clear</button>
    <span class="description" data-status-text-color="primary">Sign above</span>
    <button  color="primary" mat-raised-button (click)="form.ngSubmit.emit()" class="save-btn">Capture & Submit</button>
    <button mat-raised-button (click)="closeModel()" class="cancelbtn">Cancel</button>
  </div>
</div>