
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Refunds Model</h2>
 <p><i><code>addClientRefundDetails</code></i></p>
 <ul>
     <li>
            This API is used to fetch the list of paid invoices with the details of client by using action(<strong>new or details</strong>) and clientId while creating refund for a client. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_addClientRefundDetails"><u>{{path}}explorer/#!/Refunds/Refunds_addClientRefundDetails</u></a> 

     </li>
 </ul> 
 <br />  
     <p><i><code>deleteRefundbyVendorId</code></i></p> 
     <ul>
         <li>
                This API is used to delete the refund record from `tbl_refunds` by using vendorId and poId if exists. 

         </li>
         <li>
                Once the record is deleted successfully, refunded value and creditAmount will be updated into `tbl_purchase_order` and `tbl_credit_amount`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_deleteRefund"><u>{{path}}explorer/#!/Refunds/Refunds_deleteRefund</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getAmountByPO</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the amount of purchase order for the particular vendor while creating refund. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_getAmountByPO"><u>{{path}}explorer/#!/Refunds/Refunds_getAmountByPO</u></a> 

         </li>
     </ul>
     <br />
     <p><i><code>getRefundById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of refund from `tbl_refunds` by <strong>Refund Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_getRefunById"><u>{{path}}explorer/#!/Refunds/Refunds_getRefunById</u></a>  

         </li>
     </ul>
 <br />  
     <p><i><code>listByVendor</code></i></p> 
     <ul>
         <li>
                This API will fetch the list of refunds for a vendor by providing <strong>vendor id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_listByVendor"><u>{{path}}explorer/#!/Refunds/Refunds_listByVendor</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listByClient</code></i></p> 
     <ul>
         <li>
                This API will fetch the list of refund records for a client by <strong>client id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_listByClient"><u>{{path}}explorer/#!/Refunds/Refunds_listByClient</u></a> 

         </li>
     </ul>
     <br /> 
     <p><i><code>purchaseOrderList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of purchase order which are not refunded by <strong>vendor id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_purchaseOrderList"><u>{{path}}explorer/#!/Refunds/Refunds_purchaseOrderList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>deleteRefundbyClientId</code></i></p> 
     <ul>
         <li>
                This API is used to delete the refund record from `tbl_refunds` by <strong>Client Id</strong> and <strong>Refund Id </strong>if refundType is credit.  

         </li>
         <li>
                Once the record is deleted successfully, refunded value and creditAmount will be updated into `tbl_invoices` and `tbl_credit_amount`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Refunds/Refunds_deleteClientRefund"><u>{{path}}explorer/#!/Refunds/Refunds_deleteClientRefund</u></a> 

         </li>
     </ul>
 </div>
</mat-card>
