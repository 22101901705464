
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">opportunity Source Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create new opportunity source into `tbl_opportunity_source` by providing <strong>opportunitySourceName</strong>. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_add"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_add</u></a> 

      </li>
  </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API is used to delete the opportunity source from `tbl_opportunity_source` by <strong>OpportunitySourceId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_delete"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_delete</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>getDashboardOpposource</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the list of opportunity source from `tbl_opportunity_source` for dashboard widgets. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_getDashboardOppoSource"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_getDashboardOppoSource</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p> 
   <ul>
       <li>
            It will fetch the list of opportunity sources from `tbl_opportunity_source`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_list"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_list</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listById</code></i></p> 
   <ul>
       <li>
            It will fetch the details of opportunity source from `tbl_opportunity_source` by <strong>OpportunitySourceId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_listById"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_listById</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the opportunity source name into `tbl_opportunity_source` by <strong>OpportunitySourceId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunitySource/OpportunitySource_modify"><u>{{path}}explorer/#!/OpportunitySource/OpportunitySource_modify</u></a> 

       </li>
   </ul>
   </div>
</mat-card>
