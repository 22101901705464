
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
    
<h2 class="content-header-name">Status Model</h2>
 <p><i><code>TicketStatuses</code></i></p> 
 <ul>
        <li>
                This API is used to get all the ticket status from `tbl_status`. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/TicketStatus/TicketStatus_find"><u>{{path}}explorer/#!/TicketStatus/TicketStatus_find</u></a> 

        </li>
    </ul>
    </div>
</mat-card>
