
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Saved Items Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new item into `tbl_saved_items`. 

     </li>
     <li>
            Before creating an item, tax rate total will be calculated for that item using <i><code>calculateTaxRateAndTotal</code></i> and <i><code>calculateRateAndTax</code></i>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_add"><u>{{path}}explorer/#!/savedItems/savedItems_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>defaultItemList</code></i></p>
     <ul>
         <li>
                This API is used to fetch the list of default items from `tbl_saved_items` if <strong>tbl_saved_items.isDefaultItem = 1</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_defaultItemList"><u>{{path}}explorer/#!/savedItems/savedItems_defaultItemList</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the items from `tbl_saved_items` by providing saved_items_id(<strong>Enter item id's that need to be deleted (use comma separated values for multiple users)</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_delete"><u>{{path}}explorer/#!/savedItems/savedItems_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Details</code></i></p> 
     <ul>
     <li>
            This API is used to fetch the details of particular item from `tbl_saved_items` by using <strong>savedItemsId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_details"><u>{{path}}explorer/#!/savedItems/savedItems_details</u></a> 

     </li>
    </ul>
     <br />  
     <p><i><code>dropdownList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the items list from `tbl_saved_items` for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_dropdownList"><u>{{path}}explorer/#!/savedItems/savedItems_dropdownList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of items from `tbl_saved_items` with pagination. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_list"><u>{{path}}explorer/#!/savedItems/savedItems_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify the details of items by <strong>savedItems id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/savedItems/savedItems_modify"><u>{{path}}explorer/#!/savedItems/savedItems_modify</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
