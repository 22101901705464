import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-ui-docs',
    templateUrl: './ui-docs.component.html',
    styleUrls: ['./ui-docs.component.scss'],
    standalone: true
})
export class UiDocsComponent implements OnInit {
  name = 'Angular 6';
  safeSrc: SafeResourceUrl;
  thumbnail='';

  constructor(private sanitizer: DomSanitizer) { 
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://techops.techspawnmobiles.com/documentation/");
  }

  ngOnInit() {
  }

}
