import {Component, OnInit} from '@angular/core';
import {ConfigService} from "../../service/config.service";
import { SpinnerComponent } from '../../admin/shared/spinner.component';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    standalone: true,
    imports: [SpinnerComponent]
})
export class PageNotFoundComponent implements OnInit {

  constructor(private configService : ConfigService) {
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {

  }

}
