@if (timerArray.length) {
    <div [ngClass]="timerDragged === true ? 'timerDragged' : ''">
        <div
            cdkDrag
            cdkDragBoundary="body"
            class="task_timers"
            [ngClass]="panelOpenState() ? '' : 'timer_collapsed'"
            (cdkDragStarted)="dragStart()"
        >
            <div class="floating-timer">
                <span style="text-align:center">
                    <i class="fas fa-stopwatch hide_show_btn" [matBadge]="timerCount" matBadgePosition="before"></i>
                </span>

                @if (timerArray.length) {
                    <div
                        [ngClass]="timerArray?.length > 4 ? ['multiple_timer', 'morethanfour'] : (timerArray?.length == 1 ? 'single_timer' : 'multiple_timer')">
                        <i
                            class="fas fa-grip-vertical drag-icon"
                            cdkDragHandle
                            matTooltip="Drag timer"
                            matTooltipPosition="above"
                        ></i>

                        <mat-accordion>
                            <mat-expansion-panel [expanded]="panelOpenState()" (opened)="togglePanel(true)" (closed)="togglePanel(false)">
                                <mat-expansion-panel-header>
                                    @if (timerArray?.length === 1) {
                                        <mat-panel-title>
                                            <i class="fas fa-stopwatch collapse_single" matTooltip="Collapse Timer"></i>
                                        </mat-panel-title>
                                    }

                                    @if (timerArray?.length > 1) {
                                        <mat-panel-title>
                                            <span class="active-timers-title" matTooltip="Drag timer">
                                                {{ timerArray.length }} Active timers
                                            </span>
                                        </mat-panel-title>
                                    }
                                </mat-expansion-panel-header>

                                @for (element of timerArray; track i; let i = $index) {
                                    <div>
                                        @if (element.moduleName != 'Client') {
                                            <p class="project_name">
                                                <a class="static_nm">{{ element.moduleName }}</a>

                                                <span class="colon"> : </span>

                                                @if (element.moduleName === 'Tickets') {
                                                    <a (click)="navigateToModule(element)">{{ element.name | uppercase }}</a>
                                                } @else {
                                                    <a (click)="navigateToModule(element)">{{ element.name }}</a>
                                                }
                                            </p>
                                        }

                                        <p class="task_name timer-content-block">
                                            <a class="static_nm">{{ element.relatedTo }}</a>

                                            <span class="colon"> : </span>


                                            @switch (element.relatedTo) {
                                                @case ('task') {
                                                    <a
                                                        class="task-name-color"
                                                        (click)="navigateToTask($event, element)"
                                                        href="admin/tasks/details/{{element.clockTimerTaskId}}"
                                                    >
                                                        {{ element.taskName }}
                                                    </a>
                                                }
                                                @case ('project') {
                                                    <a
                                                        class="task-name-color"
                                                        (click)="navigateToTask($event, element)"
                                                        href="support/project/details/{{element.clockTimerProjectId}}"
                                                    >
                                                        {{ element.projectName }}
                                                    </a>
                                                }
                                                @case ('ticket') {
                                                    <a
                                                        class="task-name-color"
                                                        (click)="navigateToTask($event, element)"
                                                        href="support/tickets/details/{{element.clockTimerTicketsId}}"
                                                    >
                                                        {{ element.ticketCode }}
                                                    </a>
                                                }
                                            }
                                        </p>

                                        <p class="timer_class timer-content-block">
                                            {{ element.totalSeconds | commonDate : 'timer' }}
                                        </p>

                                        <div class="stop_timer_btn stop_timer_btn-single">
                                            @switch (element.relatedTo) {
                                                @case ('task') {
                                                    <button
                                                        [disabled]="!permissionService.tasks.edit || element.isStop"
                                                        (click)="stopTimer(element); element.isStop = true;"
                                                        class="btn btn-mini redButton multiple_timer_stopbtn hand-cursor"
                                                    >
                                                        Stop
                                                    </button>
                                                }
                                                @case ('project') {
                                                    <button
                                                        [disabled]="!permissionService.projects.edit || element.isStop"
                                                        (click)="stopTimer(element); element.isStop = true;"
                                                        class="btn btn-mini redButton multiple_timer_stopbtn hand-cursor"
                                                    >
                                                        Stop
                                                    </button>
                                                }
                                                @case ('ticket') {
                                                    <button
                                                        [disabled]="!permissionService.tickets.edit || element.isStop"
                                                        (click)="stopTimer(element); element.isStop = true;"
                                                        class="btn btn-mini redButton multiple_timer_stopbtn hand-cursor"
                                                    >
                                                        Stop
                                                    </button>
                                                }
                                            }
                                        </div>

                                        @if (i < timerArray?.length - 1) {
                                            <hr/>
                                        }
                                    </div>
                                }
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                }
            </div>
        </div>
    </div>
}
