<!-- 
<section class="content-header">
  <div class="row">
    <div class="col-md-6">
      <h1 class="content-header-name"><mat-icon>notes</mat-icon>UI Docs</h1>
    </div>
  </div>          
</section>

<mat-card>
<ul>
  <li>
<a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/modules" > Modules </a><br>
</li>
<li>
  <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/components" > Components </a><br>
  </li>
  <li>
    <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/directives" > Directives </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/classes" > Classes </a><br>
      </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/injectables" > Injectables </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/interceptors" > Interceptors </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/guards" > Guards </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/interfaces" > Interfaces </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/miscellaneous" > Miscellaneous </a><br>
    </li>
    <li>
      <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/routes" > Routes </a><br>
    </li>
    <li>
     <a class="sidenavelement" routerLinkActive="active-link" routerLink="/docs/ui-docs/documentation-coverage" > documentation-coverage </a><br>
  </li>
</ul>
</mat-card> -->

<iframe [src]="safeSrc" width="1080px" height="720px" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>

