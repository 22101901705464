
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Policy Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create new policy into `tbl_policy` by <strong>policyName</strong> and <strong>accessRuleId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Policy/Policy_add"><u>{{path}}explorer/#!/Policy/Policy_add</u></a> 

     </li>
 </ul>
 <br />  
 <p><i><code>checkPolicyNameExists</code></i></p>
 <ul>
     <li>
            This API will check whether policy name is exists or not in `tbl_policy`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Policy/Policy_checkPolicyNameExists"><u>{{path}}explorer/#!/Policy/Policy_checkPolicyNameExists</u></a> 

     </li>
 </ul> 
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API will delete the policy from `tbl_policy` by <strong>policyId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Policy/Policy_delete"><u>{{path}}explorer/#!/Policy/Policy_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of policies exists for the logged in user from `tbl_policy`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Policy/Policy_list"><u>{{path}}explorer/#!/Policy/Policy_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                It will fetch the details particular policy from `tbl_policy` by <strong>policyId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Policy/Policy_listById"><u>{{path}}explorer/#!/Policy/Policy_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify the details of policy into `tbl_policy` by <strong>policyId</strong> If exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Policy/Policy_modify"><u>{{path}}explorer/#!/Policy/Policy_modify</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>treeDataById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the access rules(<strong>view, edit</strong>) of each module in tree structure for the logged in user based on the resources from `tbl_access_rules` and `tbl_resources` by providing <strong>policyId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Policy/Policy_treeDataById"><u>{{path}}explorer/#!/Policy/Policy_treeDataById</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
