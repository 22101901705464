import { Component, OnInit } from '@angular/core';
import { BillingComponent } from './billing/billing.component';

@Component({
    selector: 'app-subscription-pay',
    templateUrl: './subscription-pay.component.html',
    styleUrls: ['./subscription-pay.component.css'],
    standalone: true,
    imports: [BillingComponent]
})
export class SubscriptionPayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
