
<div class="api-docs-back-btn">
    <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
    </section>
    </div>
      
  <mat-card>
      <div class="api-docs">
    <h2 class="content-header-name">Configuration of Files</h2>

        <ul>
            
          <li>  component-config.json                          
          </li> 
           This file configures and loads the components of loopback application.  
          <li>  Config.json                
          </li>
            Define application server side settings and global constants values etc., 
          <li>  datasource.json                          
          </li>
            It is used to configure the data source(database) of an application 
          <li>     
               middleware.json   
          </li>              
            Middleware functions are functions that have access to the request object (req), the response object (res), and the next middleware function in the application's request-response cycle.
              The API’s present inside the middleware should have valid accessToken to get the response.  
            <li>  middleware.development.json               
            </li>
             It configures the middleware in a development and debugging environment.   
            <li>  model-config.json    
            </li>
             This file configures LoopBack models, for example it binds models to data sources and specifies whether a model is exposed over REST. 
            <li>  server.js                 
            </li>
              This is the main application script in the standard application where it configures models, datasources, middleware etc., This file will be the starting point of the application. 
        </ul>
         For more information: <a href="https://loopback.io/doc/en/lb3/server-directory.html">https://loopback.io/doc/en/lb3/server-directory.html </a>  
          
      </div>
      </mat-card>
      