<!-- Footer -->
<div class="clear"></div>
<footer class="page-footer">

  <!-- Copyright -->
  <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.
    <p class="alignright">{{version}}</p>
  </div>
  <!--<div class="footer-copyright text-right">Version 4.0</div>-->
  <!-- Copyright -->
</footer>
<!-- Footer -->
