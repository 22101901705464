import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AppComponentBase} from '../../shared/AppComponentBase';
import {MatDialogRef} from '@angular/material/dialog';
import {AdminClientAgreementDetailComponent} from '../../admin-client/admin-client-detail/admin-client-agreement/admin-client-agreement-detail/admin-client-agreement-detail.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Common} from '../../../service/constant';
import {AgreementService} from "../../../service/agreement.service";

@Component({
    selector: 'app-agreement-dialog-entry',
    template: ``,
    standalone: true
})
export class AgreementDialogEntryComponent extends AppComponentBase implements OnDestroy {

  currentDialog: MatDialogRef<AdminClientAgreementDetailComponent> = null;
  destroy = new Subject<void>();

  constructor(inject: Injector,private agreementService: AgreementService) {
    super(inject);
    this.activatedRoute.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      if (this.currentDialog) {
        this.currentDialog.close();
      }
      this.openDialog(params);
    });
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  openDialog(params): void {
    var argType = this.agreementService.getAgreementType();
    const dialogConfig = Common.matDialogConfig;
    dialogConfig.panelClass = [this.configService.userTheme, 'animated',
      'slideInRight',
      'full-screen-modal'];
    dialogConfig.data = {agreementId: params.id, type: argType};
    this.currentDialog = this.dialog.open(AdminClientAgreementDetailComponent, dialogConfig);
    this.currentDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.msg === 'dashboard') {
          this.routerDialog.navigate(['admin/dashboard']);
        }
      }
    });
  }
}

