import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { EStatusCode } from '../../../service/Utils/Interfaces.class';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { PaymentsInfo } from '../../../service/models/PaymentsInfo';
import { NgOption } from '@ng-select/ng-select';
import { Payments } from '../../../service/models/Payments';
import { MenuService } from '../../../service/menu.service';
import { ConfigService } from '../../../service/config.service';
import { Router } from '@angular/router';
import { ModuleListService } from '../../../service/module-list.service';
import { EmployeeService } from '../../../service/employee.service';
import { TenantCompaniesService } from '../../../service/tenant-companies.service';
import { ItemsService } from '../../../service/items.service';
import { PaymentInfoService } from '../../../service/payment-info.service';
import { GlobalService } from '../../../service/global.service';
import { LoginService } from '../../../service/login.service';
import { COMMON } from '../../../service/constant';
import { CommonDialogModelComponent } from '../../shared/common-dialog-model/common-dialog-model.component';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { RemoveModulesDialogComponent } from './remove-modules-dialog/remove-modules-dialog.component';
import { AddCompanyDialogComponent } from './add-company-dialog/add-company-dialog.component';
import { AddRemoveUserDialogComponent } from './add-remove-user-dialog/add-remove-user-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { TsysCcDialogComponent } from './tsys-cc-dialog/tsys-cc-dialog.component';
import { ISubscriptionPayResponseCCList, ISubscribedCCList, ISubscriptionPayResponseAddedModuleList, ISubscriptionPayResponseSubModuleList, ISubscribedModuleList, ISubscribedDefaultModule } from '../interface/subscription-pay.interface';
import { SubscriptionPayService } from '../service/subscription-pay.service';
import { SharedService } from '../../../service/shared.service';
import { SubscriptionDialogComponent } from './subscription-dialog/subscription-dialog.component';
import { MatSort } from '@angular/material/sort';
import { CommonDatePipe } from '../../shared/pipes/common-date.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf, NgFor, NgClass, UpperCasePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { SubHeaderComponent } from '../../layouts/full/sub-header/sub-header.component';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
    standalone: true,
    imports: [SubHeaderComponent, MatCard, MatButton, NgIf, MatTooltip, NgFor, MatExpansionPanelTitle, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass, ExtendedModule, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, UpperCasePipe, CommonDatePipe]
})
export class BillingComponent extends AppComponentBase implements OnInit {

  @Input() clientId: number;
  @Output() clientDetailCount = new EventEmitter<void>();
  @ViewChild('sortCol1') sortCol1: MatSort;
  @Output() paymentsInfoCount = new EventEmitter<number>();

  private readonly subscriptions: Subscription[] = [];

  public isTrialOver: boolean = false;
  public isSubscriptionActive: boolean = true;
  public isCancelled: string;
  public expiryCount: number = 0;

  today: any;
  moduleList: string;
  list: any;

  subscribedModuleList: ISubscribedModuleList[] = [];
  alreadySubscribedModuleList: Array<any> = [];

  totalSeats: number = 0;
  removedSeats: number = 0;
  subscribedPlan: number;
  remainingSeats: number;
  activeUsers: number;
  deactiveUsers: number;

  subscriptionStartDate: number;
  subscriptionEndDate: string;
  trialPeriodEndDate: number;

  modulePrice = 0;
  defaultModulePrice = 2;
  totalPrice = 0;
  dataArr = [];
  subTotal = 0;
  pucost = 0;
  displaypucost: any = 0;
  mcost = 0;
  displaymcost: any = 0;
  total_cost: number = 0;
  displaytotal_cost: any = 0;
  sTotal: any = 0;
  tax: number = 0;
  displaytax: any = 0;
  currency: string = '';
  companyName: string;
  defaultCardDetails: string = "-";
  countryName: string;

  displaydiscount: any = 0;
  discount: number = 0;
  total: number = 0;
  displaytotal: any = 0;

  reservedSeatInfo: any;
  isMonthly: boolean = true;
  isYearlyPlan: boolean = false;
  isMonthlyPlan: boolean = false;
  disableRemoveSeats: boolean = false;

  public paymentsInfoList: PaymentsInfo[];
  public dataSource1 = new MatTableDataSource<PaymentsInfo>();
  public dataSource2 = new MatTableDataSource<PaymentsInfo>();
  public paymentsDetailList: NgOption[] = [];

  private limitStart: number;
  public pageSizeArray = [5, 10, 15];
  private pageSize = this.pageSizeArray[0];
  public limit = this.pageSizeArray[0];
  public searchValue: string = '';
  public paymentsArr: Payments[] = [];
  creditCardList = [];
  public totalRecord1: number;
  public displayedColumns1: string[];
  event: any;
  prevPageIndex = 0;
  tenantKey: string;
  hostname: string;

  private paymentInfo: ISubscribedCCList[] = [];

  public manifest: string = '';
  private tsysFlag: boolean = true;
  private deviceId: number;

  defaultItemsList: ISubscribedDefaultModule[] = []

  constructor(injector: Injector, private menuService: MenuService,
    public configService: ConfigService, public router: Router, public dialog: MatDialog,
    private moduleListService: ModuleListService, private employeeService: EmployeeService,
    private tenantCompaniesService: TenantCompaniesService, private itemsService: ItemsService,
    private paymentInfoService: PaymentInfoService, private globalService: GlobalService,
    private subscriptionPayService: SubscriptionPayService,
    private sharedService: SharedService,
    private loginService: LoginService) {

    super(injector);
    this.setWindowTitle('Subscription')

    if (this.permissionService.clientPayments.edit) {
      this.displayedColumns1 = ['credit_card', 'card_type', 'expiry_date', 'cvv', 'billing_zip', 'Action'];
    } else {
      this.displayedColumns1 = ['credit_card', 'card_type', 'expiry_date', 'cvv', 'billing_zip'];
    }

    try {
      this.tenantKey = CryptoJS.AES.decrypt(localStorage.getItem('tk'), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8);
    }
    catch (error) {
      console.log(error)
    }

  }


  ngOnInit() {
    this.getSubscribedModules();
    this.getReservedSeats();
    this.getReservedSeatDetails();

    this.configService.getAllConfigDetails();
    this.companyName = this.configService.companyName ? this.configService.companyName : "-";
    this.countryName = this.configService.companyCountry ? this.configService.companyCountry : "-";
    this.currency = this.configService.defaultCurrencySymbol;

    this.getCCList()
    this.getPaymentInfoById(this.clientId, 0, 0);

    if (this.isCancelled == '0') {
      this.isSubscriptionActive = true;
    } else {
      this.isSubscriptionActive = false;
    }
  }

  getReservedSeatDetails() {
    const reseSeatDet: Subscription = this.paymentInfoService.reservedSeatItemDetails().subscribe((itemDetails: any) => {

      if (itemDetails.info.code === EStatusCode.OK) {
        this.reservedSeatInfo = itemDetails.data[0];
      }
    });
    this.subscriptions.push(reseSeatDet);
  }

  getAlreadySubsbedList() {

    if (this.subscriptionEndDate) {

      const nextBillingDate = moment(this.subscriptionEndDate).format('YYYY-MM-DD');

      const getDefutItmsList: Subscription =
        this.subscriptionPayService.getAlreadySubscribedModules(nextBillingDate).subscribe(
          (response: ISubscriptionPayResponseAddedModuleList) => {
            this.alreadySubscribedModuleList = response.data.menuResult;
            this.getList();
          })
      this.subscriptions.push(getDefutItmsList);
    }
  }

  getSubscribedModules() {
    const getSubscribedModuleSubscription: Subscription =
      this.subscriptionPayService.getSubscribedModules().subscribe(
        (response: ISubscriptionPayResponseSubModuleList) => {
          if (response.info.code === EStatusCode.OK) {
            this.subscribedModuleList = response.data;
            this.getDefaultItemsList();
          }
        })
    this.subscriptions.push(getSubscribedModuleSubscription)
  }

  getDefaultItemsList() {
    this.dataArr = [];
    this.subTotal = 0;
    const getDefltIteList: Subscription =
      this.itemsService.getDefaultItemsList().subscribe((response: any) => {
        if (response.info.code === EStatusCode.OK) {
          if (this.subscribedPlan == 0) {
            this.isMonthlyPlan = true;
            var numberOfMonths = 1;
          } else {
            this.isYearlyPlan = true;
            var numberOfMonths = 12;
          }

          var activeUser = {
            name: 'Active Users',
            class: 'active_users',
            numberOfUsers: this.totalSeats,
            cost_per_u_p_m: Number(this.reservedSeatInfo.unitCost).toFixed(2),
            cost_per_month: Number(0.00).toFixed(2),
            total_row_cost: Number(this.totalSeats * this.reservedSeatInfo.unitCost * numberOfMonths).toFixed(2)
          }
          this.subTotal = (this.totalSeats * this.reservedSeatInfo.unitCost * numberOfMonths)
          this.dataArr.push(activeUser);
          var moduleArray = [];

          this.subscribedModuleList.forEach(subscribed_module => {
            moduleArray.push(subscribed_module.label);
          });

          this.defaultItemsList = response.data.list;

          response.data.list.forEach(element => {
            this.pucost = 0;
            this.mcost = 0;
            this.discount = 0;
            if (moduleArray.includes(element.itemName.toLowerCase())) {
              if (element.pricingType == 0 && (element.itemName != 'Dashboard' && element.itemName != 'Calendar' && element.itemName != 'Client' && element.itemName != 'Vendor' && element.itemName != 'Tasks' && element.itemName != 'HR' && element.itemName != 'Report' && element.itemName != 'Utilities' && element.itemName != 'Subscription')) {
                this.pucost = element.unitCost;
                this.displaypucost = Number(this.pucost).toFixed(2);
                this.total_cost = this.pucost * this.totalSeats * numberOfMonths;
                this.displaytotal_cost = Number(this.total_cost).toFixed(2);
                this.subTotal += this.total_cost;
              } else if (element.pricingType == 1 && element.itemName != 'Dashboard' && element.itemName != 'Calendar' && element.itemName != 'Client' && element.itemName != 'Vendor' && element.itemName != 'Tasks' && element.itemName != 'HR' && element.itemName != 'Report' && element.itemName != 'Utilities' && element.itemName != 'Subscription') {
                this.mcost = element.unitCost
                this.total_cost = this.mcost * numberOfMonths;
                this.displaymcost = Number(element.unitCost).toFixed(2);
                this.displaytotal_cost = Number(this.total_cost).toFixed(2);
                this.subTotal += this.total_cost;
              } else {
                this.displaymcost = Number(0.00).toFixed(2);
                this.displaypucost = Number(0.00).toFixed(2);
                this.displaytotal_cost = Number(0.00).toFixed(2);
              }
            }
          });
          this.sTotal = Number(Math.round((this.subTotal) * 100) / 100).toFixed(2);
          this.displaytax = Number(Math.round((this.subTotal * (8.25 / 100)) * 100) / 100).toFixed(2);
          this.tax = Math.round((this.subTotal * (8.25 / 100)) * 100) / 100;

          if (this.isYearlyPlan) {
            this.displaydiscount = Number(Math.round((this.subTotal * (10 / 100)) * 100) / 100).toFixed(2);
            this.discount = Math.round((this.subTotal * (10 / 100)) * 100) / 100;
          } else {
            this.displaydiscount = Number(0.00).toFixed(2);

          }
          this.total = ((this.subTotal) + this.tax) - this.discount;
          this.displaytotal = Number(((this.subTotal) + this.tax) - this.discount).toFixed(2);
        }
      });
    this.subscriptions.push(getDefltIteList);
  }

  getReservedSeats() {
    const getReserdSeats: Subscription = this.tenantCompaniesService.getReservedSeats().subscribe((response: any) => {
      if (response.info.code === EStatusCode.OK) {

        this.totalSeats = response.data[0].noOfUsers;
        this.removedSeats = response.data[0].removedSeats;

        this.subscribedPlan = response.data[0].subscribedplan;
        this.subscriptionEndDate = response.data[0].subscriptionEndDate;
        this.expiryCount = moment(this.subscriptionEndDate).diff(moment(), 'days') + 1;
        this.subscriptionStartDate = new Date(response.data[0].subscriptionStartDate).getTime();
        this.trialPeriodEndDate = new Date(response.data[0].trialPeriodEndDate).getTime();

        this.isCancelled = response.data[0].isCancelled;
        let currentDate = new Date().getTime();
        if (currentDate <= this.trialPeriodEndDate) {
          this.isTrialOver = true;
          localStorage.setItem('isTrialOver', '0');
        } else {
          this.isTrialOver = false;
          localStorage.setItem('isTrialOver', '1');
        }

        if (response.data[0].isCancelled == 0) {
          this.isSubscriptionActive = true;
        } else {
          this.isSubscriptionActive = false;
          this.expiryCount = 0;
        }

        this.getAlreadySubsbedList();
        this.getUserCount();
      }
    });
    this.subscriptions.push(getReserdSeats);
  }
  getList() {
    var alderyAddedModuleArray = [];
    if (this.alreadySubscribedModuleList != undefined || this.alreadySubscribedModuleList.length != 0) {
      this.alreadySubscribedModuleList.forEach(already_subscribed_module => {
        alderyAddedModuleArray.push(already_subscribed_module.label);
      });
    }
    const getAModulList: Subscription = this.moduleListService.getModules().subscribe((data: any) => {
      data.data.forEach(element => {
        if (this.trialPeriodEndDate < new Date().getTime()) {
          if (this.alreadySubscribedModuleList != undefined || this.alreadySubscribedModuleList.length != 0) {
            alderyAddedModuleArray.forEach(item => {
              element.label = element.label.toLowerCase();
              if (element.label === item.toLowerCase()) {
                element.isPaid = true;
              }
            })
          }
        }
        element.label = element.label.toLowerCase();
        if (element.label == 'dashboard' || element.label == 'calendar' || element.label == 'client'
          || element.label == 'vendor' || element.label == 'tasks' || element.label == 'hr' ||
          element.label == 'report' || element.label == 'utilities' || element.label == 'subscription') {
          element.ischecked = 1;
          element.isDisabledCheck = true;
        } else {
          element.ischecked = 0;
          element.isDisabledCheck = false;
        }
      });
      this.list = data.data;
    });
    this.subscriptions.push(getAModulList);
  }
  getUserCount() {
    const actiDeactUserCount: Subscription = this.employeeService.activeDeactiveUserCount().subscribe((response: any) => {
      if (response.info.code === EStatusCode.OK) {
        this.activeUsers = response.data.activeUsers;
        this.deactiveUsers = response.data.deActiveUsers;
        this.remainingSeats = Number(this.totalSeats) - Number(this.activeUsers);
        if (this.remainingSeats <= 0) {
          this.disableRemoveSeats = true;
        }
        else {
          this.disableRemoveSeats = false;
        }
      }
    });
    this.subscriptions.push(actiDeactUserCount);
  }
  gotoUsers() {
    this.router.navigate(['/hr/employee'], { replaceUrl: true });
  }
  gotoInvoices() {
    this.router.navigate(['/accounting/invoice'], { replaceUrl: true });
  }
  openDialog(): void {
    this.getAlreadySubsbedList()
    for (let index = 0; index < this.list.length; index++) {
      var element = this.list[index];
      for (let index1 = 0; index1 < this.subscribedModuleList.length; index1++) {
        var element1 = this.subscribedModuleList[index1];
        if (element.label.toLowerCase() == element1.label.toLowerCase()) {
          element.isSubscribed = 1;
          element.isDisabledCheck = true;
          break
        } else {
          element.isSubscribed = 0;
        }
      }
    }
    var subscribedMenuID = this.subscribedModuleList.map(function (obj) {
      return obj.menuId;
    });

    var isPaidFlag = this.alreadySubscribedModuleList.every(function (val) {
      return subscribedMenuID.indexOf(val.menuId) >= 0;
    });

    let isPaidHave = false;
    if (this.trialPeriodEndDate < new Date().getTime() &&
      (this.alreadySubscribedModuleList != undefined ||
        this.alreadySubscribedModuleList.length != 0)) {
      if (!isPaidFlag) {
        isPaidHave = true
      }
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      height: 'auto',
      disableClose: true,
      panelClass: [
        this.configService.userTheme
      ],
      data: {
        moduleList: this.list, totalSeats: this.totalSeats, plan: this.subscribedPlan,
        totalCost: this.total, subscriptionEndDate: this.subscriptionEndDate, isPaidHave: isPaidHave
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAlreadySubsbedList();
      this.getSubscribedModules();
      this.getDefaultItemsList();
      this.configService.getAllConfigDetails();
    });
  }
  openRemoveDialog(): void {
    var moduleArr = [];
    for (let index = 0; index < this.list.length; index++) {
      var element = this.list[index];
      for (let index1 = 0; index1 < this.subscribedModuleList.length; index1++) {
        var element1 = this.subscribedModuleList[index1];
        element1.label = element1.label.toLowerCase();
        if (element.label.toLowerCase() == element1.label.toLowerCase()) {
          if ((element1.label == 'dashboard' || element1.label == 'calendar' || element1.label == 'client'
            || element1.label == 'vendor' || element1.label == 'tasks' || element1.label == 'hr' ||
            element1.label == 'report' || element1.label == 'utilities' || element1.label == 'subscription')) {
            element.isDisabledCheck = true;
          } else {
            element.isDisabledCheck = false;
          }
          element.isSubscribed = 1;
          moduleArr.push(element);
          break
        } else {
          element.isSubscribed = 0;
        }
      }
    }
    const dialogRef = this.dialog.open(RemoveModulesDialogComponent, {
      width: '50%',
      height: 'auto',
      disableClose: true,
      panelClass: [
        this.configService.userTheme
      ],
      data: { moduleList: moduleArr, totalSeats: this.totalSeats, plan: this.subscribedPlan, totalCost: this.total, subscriptionEndDate: this.subscriptionEndDate }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReservedSeats();
      this.getUserCount();
      this.getSubscribedModules();
      this.getDefaultItemsList();
    });
  }

  openCompanyDialog(action): void {
    if (action === "newCompany") {
      const dialogRef = this.dialog.open(AddCompanyDialogComponent, {
        width: '50%',
        height: 'auto',
        disableClose: true,
        panelClass: [this.configService.userTheme],
        data: { moduleList: this.list }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getModuleList();
        if (result) {
          this.changeCompany(result.data.companyId);
        }
      });
    } else {
      const dialogRef = this.dialog.open(SubscriptionDialogComponent, {
        width: '80%',
        height: '80%',
        disableClose: true,
        panelClass: [this.configService.userTheme],
        data: { moduleList: this.list }
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getModuleList();
      });
    }
  }

  usersSeatDialog(res): void {
    var usedSeats = this.totalSeats - this.remainingSeats;
    const dialogRef = this.dialog.open(AddRemoveUserDialogComponent, {
      width: '40%',
      height: 'auto',
      disableClose: true,
      panelClass: [
        "animated",
        "slideInDown",
        this.configService.userTheme
      ],
      data: {
        actionType: res, totalSeats: this.totalSeats,
        unusedSeats: usedSeats, plan: this.subscribedPlan,
        totalCost: this.total, subscriptionEndDate: this.subscriptionEndDate,
        removedSeats: this.removedSeats
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReservedSeats();
      this.getUserCount();
      this.getDefaultItemsList();
    });

  }

  getPaymentInfoById(clientId, count?, previousPageIndex?) {
    if (count === 0) {
      if (count < previousPageIndex || count === previousPageIndex) {
        this.limitStart = 0;
      }
      else {
        this.limitStart += this.pageSize;
      }
    }
    else if (count < previousPageIndex && this.limitStart >= this.pageSize) {
      this.limitStart -= this.pageSize;
    }
    else if (count > previousPageIndex && (this.limitStart <= this.pageSize || this.limitStart >= this.pageSize)) {

      this.limitStart += this.pageSize;
    }
    const filter = { "start": this.limitStart, "limit": this.limit, "search": this.searchValue };
    const getPaymentInfoBList: Subscription = this.paymentInfoService.CCListByTenant(filter).subscribe((res: any) => {
      this.paymentsInfoList = [];
      this.paymentsArr = [];
      if (res.info.code === EStatusCode.OK) {
        if (res.data.list) {
          this.dataSource1.data = res.data.list;
          this.creditCardList = res.data.list;
          this.totalRecord1 = res.data.totalCount;
          this.dataSource1.sort = this.sortCol1;
          this.paymentsInfoCount.emit(res.data.totalCount);
        } else {
          this.dataSource1.data = res.data.list;
          this.totalRecord1 = 0;
          this.dataSource1.sort = this.sortCol1;
          this.paymentsInfoCount.emit(0);
        }
      } else {
        this.dataSource1.data = res.data.list;
        this.totalRecord1 = 0;
        this.dataSource1.sort = this.sortCol1;
        this.paymentsInfoCount.emit(0);
      }
    });
    this.subscriptions.push(getPaymentInfoBList);
  }

  deleteCC(id, isDefaultCard) {
    let message = "";
    if (isDefaultCard >= 1) {
      this.toastr.error('This Card cannot be deleted as its the default subscribed Card for payment.');
    } else {
      message = "You are about to delete a record. This cannot be undone. Are you sure?";
      const alertCommonDialogData = {
        title: 'Confirm Delete',
        message: message,
        submitButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      };
      const dialogRef = this.dialog.open(CommonDialogModelComponent, {
        width: COMMON.DELETE_ALERT_WIDTH,
        height: this.totalRecord1 ? '' : COMMON.DELETE_ALERT_HEIGHT,
        backdropClass: 'paymentDeletePopupbackdrop',
        data: alertCommonDialogData
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {

          const DelEtEList: Subscription = this.paymentInfoService.deleteTenantCC(id, 0).subscribe((response: any) => {
            if (response.info.code === EStatusCode.OK) {
              this.toastr.success('Credit Card deleted successfully!');
              this.getPaymentInfoById(this.clientId, 0, 0);
              this.clientDetailCount.next();

            } else {
              this.toastr.error('Credit Card not deleted');
            }
          }, (error) => {
            console.log(error);
          });
          this.subscriptions.push(DelEtEList);

        }
      });
    }
  }

  makeCardDefault(paymentInfoId, isDefaultCard) {

    if (isDefaultCard === '1') {
      this.toastr.info('This card is already marked as default');
    } else {

      const makeCardDefauList: Subscription = this.globalService.makeCardDefault(paymentInfoId).subscribe((data: any) => {
        if (data.info.code == EStatusCode.OK) {
          this.toastr.success(data.data.responseMsg);
          this.getPaymentInfoById(this.clientId);
        } else {
          this.toastr.error(data.data.responseMsg);
        }
      })
      this.subscriptions.push(makeCardDefauList);
    }
  }

  setToolTip(i) {
    if (i == '1') {
      return 'This is default card!';
    }
    else {
      return 'Make this card as default';
    }
  }

  onChange(event) {

    this.event = event.pageIndex;
    if (this.limit !== event.pageSize) {
      this.limitStart -= event.pageSize;
      this.limit = event.pageSize;
      this.pageSize = event.pageSize;
    }
    this.prevPageIndex = event.previousPageIndex;
    this.getPaymentInfoById(null, event.pageIndex, event.previousPageIndex);
  }

  addCC() {
    var ubmMid: number;
    var ubmTransactionKey: string;
    const UBMTsysData: Subscription = this.configService.tsysUBMData().subscribe((res: any) => {

      if (res.info.code === EStatusCode.OK) {
        res.data.forEach((resObject: any) => {
          if (resObject.configKey === "tsys_mid") {
            ubmMid = resObject.value;
          } else if (resObject.configKey === "tsys_device_id") {
            this.deviceId = resObject.value;
          } else if (resObject.configKey === "tsys_transaction_key") {
            ubmTransactionKey = resObject.value;
          }
        });

        this.tsysFlag = true;
        this.manifest = this.sharedService.encryptManifest(ubmMid, this.deviceId, ubmTransactionKey);

        const dialogRef = this.dialog.open(TsysCcDialogComponent, {
          width: '600px',
          height: 'auto',
          disableClose: true,
          panelClass: [
            this.configService.userTheme
          ],
          data: {
            manifest: this.manifest,
            ubmDeviceId: this.deviceId,
            paymentInfo: this.paymentInfo
          }
        });
        dialogRef.afterClosed().subscribe(result => {


          if (result.isComplete == 0 && result.reOpen == 1) {
            this.addCC();
            if (result.response != "") {
              this.toastr.error(result.errMsg);
            }
          } else if (result.isComplete == 0 && result.reOpen == 0) {
            this.toastr.error('Add credit card aborted');
          } else if (result.isComplete == 1 && result.reOpen == 0) {
            if (result.isSuccess == 1) {
              this.toastr.success('Credit card added successfully');
              this.getPaymentInfoById(this.clientId, 0, 0);
              this.clientDetailCount.next();
            } else {
              this.toastr.error('Failed to add credit card. Please try again.');
            }
          } else if (result.isComplete == 2) {
            this.toastr.error('Credit card already exists!');
          } else if (result.isComplete == 3) {
            this.toastr.error('Something went wrong. Please try again later.');
          }
        });

      } else {
        this.toastr.error('Tsys Details no found. Contact Admin.');
      }
    });
    this.subscriptions.push(UBMTsysData);

  }

  changeCompany(companyId) {
    this.hostname = window.location.hostname;
    this.loginService.companyDetails(companyId, this.tenantKey.replace(/"/g, "").trim()).subscribe((res: any) => {

      if (res.info.code === EStatusCode.OK) {
        this.employeeService.detailsById(localStorage.getItem("userId")).subscribe((result: any) => {
          if (result.info.code === EStatusCode.OK) {
            // uncomment this for local testing
            // if (this.hostname == 'localhost') {
            //   var obj = {
            //     "email": result.data[0].email,
            //     "domain": this.hostname,
            //     "password": localStorage.getItem("passkey"),
            //     "ck": CryptoJS.AES.encrypt(JSON.stringify(res.data.companyDetails.companyKey), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString()
            //   };
            //   var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
            //   var switchKey = encodeURIComponent(ciphertext);
            //   window.open("http://localhost:4000/login/?switchCompany=" + switchKey, "_blank");
            // } else {
              var obj1 = {
                "email": result.data[0].email,
                "domain": res.data.companyDetails.domain,
                "password": localStorage.getItem("passkey"),
                "ck": CryptoJS.AES.encrypt(JSON.stringify(res.data.companyDetails.companyKey), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString()
              };
              var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj1), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString();
              var switchKey = encodeURIComponent(ciphertext);
              window.open("https://" + res.data.companyDetails.domain + "/login/?switchCompany=" + switchKey, "_blank");
            // }
          }
        });
      } else {
        this.toastr.error("Something went wrong.");
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  cancelSubscriptionDialog(status) {
    if (status) {
      var message = "Do you really want to cancel subscription?";
      var title = "Cancel Subscription";
    } else {
      var message = "On confirm your subscription will be reactivated.";
      var title = "Activate Subscription";
    }
    const alertCommonDialogData = {
      icon: 'success',
      title: title,
      message: message,
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      showCloseButton: true,
    };

    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: this.totalRecord1 ? '' : COMMON.DELETE_ALERT_HEIGHT,
      backdropClass: 'paymentDeletePopupbackdrop',
      data: alertCommonDialogData
    });


    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {

        const cancelSubs: Subscription = this.tenantCompaniesService.cancelReactivateSubscription({ status }).subscribe((response: any) => {
          if (response.info.code === EStatusCode.OK) {
            if (status == 0) {
              this.toastr.success("Subscription activated successfully.");
              this.isSubscriptionActive = true;
              this.expiryCount = moment(this.subscriptionEndDate).diff(moment(), 'days') + 1;
            } else {
              this.toastr.success("Subscription cancelled successfully.");
              this.isSubscriptionActive = false;
              this.expiryCount = 0;
            }
            this.getReservedSeats();
          } else {
            status == 0 ? this.toastr.error("Failed to activate subscription.") :
              this.toastr.error("Failed to cancel subscription.");
          }
        });
        this.subscriptions.push(cancelSubs);

      }
    });
  }

  getCCList() {
    const getCCSubscription: Subscription =
      this.subscriptionPayService.paymentInfoListByUser().subscribe(
        (response: ISubscriptionPayResponseCCList) => {

          if (response.info.code === EStatusCode.OK && response.data.length) {

            this.paymentInfo = response.data
            let defaultCard = response.data.find((e) => { return e.isDefaultCard === 1 })

            if (defaultCard) {
              this.defaultCardDetails = `${defaultCard.cardType} **** 
              ${defaultCard.creditCardNumber.substr(defaultCard.creditCardNumber.length - 4)} 
              (${defaultCard.expirationDate})`;
            } else {
              this.defaultCardDetails = '-'
            }

          } else {
            this.defaultCardDetails = '-'
            this.paymentInfo = [];
          }
        })
    this.subscriptions.push(getCCSubscription)
  }
}
