
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Project Pipeline Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
        <li>
                This API will create new project pipeline into `tbl_project_state_pipeline` by providing <strong>projectPipelineName.</strong> 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_add"><u>{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_add</u></a> 

        </li>
    </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API will delete the project pipeline from `tbl_project_state_pipeline` by <strong>projectPipelineId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_delete"><u>{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_delete</u></a> 

       </li>
   </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of project pipelines from `tbl_project_state_pipeline`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_list"><u>{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                It is used to get the details of particular project pipeline from `tbl_project_state_pipeline` by <strong>projectPipelineId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_listById"><u>{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify the project pipeline name into `tbl_project_state_pipeline` by <strong>projectPipelineId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_modify"><u>{{path}}explorer/#!/ProjectStatePipline/ProjectStatePipline_modify</u></a>   

         </li>
     </ul>
    </div>
</mat-card>