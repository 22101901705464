
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Resources Model</h2>
 <p><i><code>treeData</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the list of Subscribed Apps resources from `tbl_resources` in tree structure. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Resources/Resources_treeData"><u>{{path}}explorer/#!/Resources/Resources_treeData</u></a> 

     </li>
 </ul>
 </div>
</mat-card>
