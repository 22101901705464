<div>
  <div mat-dialog-title class="modal-title">{{data.title}}
    <div class="pull-right">
      <mat-icon class="close-btn" style="cursor: pointer;" (click)="closeModel()">close</mat-icon>
    </div>
  </div>
  <mat-divider style="margin-bottom: 10px;"></mat-divider>
  <div class="payment-radio__container row tw-px-2.5">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="data.eGroup" (change)="radioChange($event)">

    <!--<mat-radio-button value="achCard" [disabled] = "tsysAch"-->
    <mat-radio-button value="achCard"
                      #tooltip="matTooltip" matTooltipPosition="above"
                      matTooltip="{{'ACH'}}">ACH </mat-radio-button>
                      <!--matTooltip="{{tsysAch ? 'Please setup ACH details for proceeding ACH payment.!' : 'ACH'}}">ACH </mat-radio-button>-->

    <!--<mat-radio-button value="creditCard" [disabled] = "tsysCC"-->
      <mat-radio-button value="creditCard"
        #tooltip="matTooltip" matTooltipPosition="above"
        matTooltip="{{'CreditCard'}}">CreditCard
        <!--matTooltip="{{tsysCC ? 'Please setup Credit Cards details for proceeding CC payment.!' : 'CreditCard'}}">CreditCard-->
    </mat-radio-button>

  </mat-radio-group>
</div>
  <div mat-dialog-content class="tw-p-0" *ngIf="data.eGroup === 'creditCard'">
      <div class="col-md-12">
        <div style="margin-top: 15px; background-color: white;">
            <p>{{'invoices.credit-card.addNewCard' | translate}}</p>
        </div>
      </div>
 
      <div class="col-md-12 detail-view showfileds">
            <html class="html-tsys">	
  	
              <head>	
              </head>	
  	
              <body style="background:#ffffff;">	
                <form method="POST" id="payment-form" class="clearfix">	
                  <div class="col-md-12 pl-0">	
                    <div class="label-field">Credit Card Number</div>	
                    <div id="tsep-cardNumDiv" class="tsys-div"></div>	
                  </div>	
                  <div class="col-md-4 pl-0">	
                    <div class="label-field">Zip Code</div>	
                    <div id="tsep-zipcode" class="form-group mb tsys-div">	
                      <input #textInput type="text" class="form-control" id="zipcode" name="zipcode" placeholder="00000"	
                        (input)="SearchData(textInput.value)">	
                    </div>	
                  </div>	
                  <div class="col-md-4 pl-0">	
                    <div class="label-field">Exp Date</div>	
                    <div id="tsep-datepickerDiv" class="tsys-div"></div>	
                  </div>	
                  <div class="col-md-3 pl-0">	
                    <div class="label-field">CVV</div>	
                    <div id="tsep-cvv2Div" class="tsys-div"></div>	
                  </div>	
                  <div id="div_console"></div>	
                </form>	
              </body>	
            </html>
          <div class="pay-now">

          </div>
        
      </div>
     <span *ngIf="showErrorMsg" class="errorMsg">CVC verification failed</span>
    <!-- <div class="row"> -->
      <!--<div class="col-md-6 paymentSelectorBtns">
        <button mat-raised-button type="submit" class="submitbtn margin-left" [disabled]="!activateBtn" (click)="selectCC()">{{'invoices.credit-card.select' | translate}}
        </button>
      </div>-->
 
        <div class="col-xs-12 mat-pay-Class">
          <div class="ach-pay-btn">
          <mat-checkbox class="example-margin top-mar-che-box" [(ngModel)]="checked"(click)="OnChange($event)">&nbsp;<span class="mandatory-field">*</span><span class="overflow-auto">Customer acknowledges receipts of goods and/or services in the amount of the total<wbr> shown hereon and argees to perform the obligations set forth by the customers's <wbr>  agreement with the issuer.</span></mat-checkbox>
          </div>
        </div>

        <button class="pull-right pay-btn-add-top" mat-raised-button color="primary" (click)="addCCAndActivate()"[disabled]="IsDisabled"
                #tooltip="matTooltip" matTooltipPosition="above"
                matTooltip="{{'Pay'}}"
                class="btn-submit form-button-40 pull-right"
                type="submit">Pay</button>
      
    </div>


  <div mat-dialog-content class="tw-p-0" *ngIf="data.eGroup === 'achCard'">
    <div class="row">
      <div class="col-md-12">
            <p>{{'invoices.ach.addNewAch' | translate}}</p>
      </div>
    </div>
      <div class="col-md-12 invoiceDialog">
        <div class="row">
            <form novalidate [formGroup]="createACHForm" class="md-float-material form-material lead-form tw-p-0" action="javascript:">
              <div class="card-block tw-mt-4">
                <div class="form-group form-default form-static-label">
                  <ng-select [items]="accountTypeList" appendTo='body' bindLabel="label" bindValue="value" formControlName="accountType" placeholder="Select Account Type *" (change)="updateModule($event)" [ngClass]="typeSelected===false ? 'errorClass' :'noError'">
                </ng-select>
              </div>
            </div>
            <div class="card-block tw-mt-4" *ngIf="accountNameFlag">
              <mat-form-field appearance="outline">
                <mat-label>Business Name</mat-label>
                <input matInput placeholder="Account Holder Name" name="businessName" formControlName="businessName" required>
              </mat-form-field>
            </div>
            <div class="card-block tw-mt-4" *ngIf="fnlnFlag">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="Account Holder Name" name="fName" formControlName="fName" required>
              </mat-form-field>
              <mat-form-field class="tw-mt-4" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Account Holder Name" name="lName" formControlName="lName" required>
              </mat-form-field>
            </div>

              <!--account type-->
              <div class="card-block accType tw-mt-4">
                <mat-radio-group formControlName="acctType">
                  <mat-radio-button value="checking" >{{'invoices.ach.checking' | translate}}</mat-radio-button>
                  <mat-radio-button value="savings" >{{'invoices.ach.savings' | translate}}</mat-radio-button>
                </mat-radio-group>
                <!-- <div class="messages text-danger" *ngIf="(submitted) && (f.acctType.errors?.required)">{{ 'invoices.ach.pleaseSelect' | translate }}</div> -->
              </div>

              <!--routing number-->
              <div class="card-block tw-mt-4">
                <mat-form-field appearance="outline">
                  <mat-label >{{'invoices.ach.rtnr' | translate}}</mat-label>
                  <input matInput placeholder="{{'invoices.ach.rtnr' | translate}}" name="rtnr" formControlName="rtnr" required>
                </mat-form-field>
              </div>

              <!--account number-->
              <div class="card-block tw-mt-4">
                <mat-form-field appearance="outline">
                  <mat-label >{{'invoices.ach.accountNumber' | translate}}</mat-label>
                  <input matInput placeholder="{{'invoices.ach.accountNumber' | translate}}" name="accntNum" formControlName="accntNum" required>
                </mat-form-field>
              </div>

              <!--bank name-->
              <div class="card-block tw-mt-4">
                <mat-form-field appearance="outline">
                  <mat-label >{{'invoices.ach.bankName' | translate}}</mat-label>
                  <input matInput placeholder="{{'invoices.ach.bankName' | translate}}" name="bankName" formControlName="bankName" required>
                </mat-form-field>
              </div>
            </form>
        </div>
      </div>
     <span *ngIf="showErrorMsg" class="errorMsg">{{'invoices.ach.error' | translate}}</span>
    <div class="row">
      <div class="col-md-12 pull-right">
        <button mat-raised-button color="primary" (click)="addACHAndActivate()"
                class="btn-submit form-button-40 pull-right ach-pay-btn"
                #tooltip="matTooltip" matTooltipPosition="above"
                matTooltip="{{'Pay'}}"
                type="submit">Pay</button>
      </div>
    </div>
  </div>

</div>
