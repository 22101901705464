<div class="hold-transition login-page forget-password clearfix row align-items-center d-flex flex-wrap">

  <div class="login_page_content m-0 col-md-6 col-sm-12 col-xs-12">
    <h1> Reset Your Password </h1>
    <h4>
      Don't worry Resetting your password is easy  </h4>
    <h4>
      just tell us the email address you registered with us
    </h4>
  </div>

  <div class="login-box forget_pass col-md-6 col-sm-12 col-xs-12 vh-100 d-flex align-items-center justify-content-center position-relative">
    <!-- <div class="login-logo">
      <a routerLink="/"><b>UTILIKO</b></a>
    </div> -->
    <!-- /.login-logo -->
    <div class="login-box-body mx-3">
      <form novalidate [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="md-float-material form-material" action="javascript:">
        <div class="auth-box card border-0">
          <div class="card-block">
            <div class="row m-b-20">
              <div class="col-md-12">
                <h1 class="text-center pv">Reset Password</h1>
              </div>
            </div>
            <div class="form-group form-primary">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" name="email" type="email" (blur)="checkEmail($event.target.value)" formControlName="email" required>
                <mat-icon matPrefix><i class="fa fa-envelope" aria-hidden="true"></i></mat-icon>
                <mat-error class="messages text-danger" *ngIf="show">Email is not registered in system</mat-error>
              </mat-form-field>
            </div>

            <div class="row m-t-30">
              <div class="col-md-12 position-relative">
                <button type="submit" [disabled]="isDisabled" class="btn btn-block text-center m-b-20 proceedBtn">Send Reset Password Link</button>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div>
            <hr />
            <div class="m-t-25 text-left">
              <div class="bottom_btn col-12">
                <div class="pull-left cursor-pointer">
                  <a [routerLink]="['/login']" class="fw-bold">Remembered password?</a>
                </div>
                <div class="pull-right cursor-pointer">
                  <a href="https://www.utiliko.io/free-trial/" class="fw-bold" target="_blank"><b>Try it free</b></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
    <!-- /.login-box-body -->

    <div class="footer-box-body w-100 m-0 position-absolute bottom-0">
        <div class="footer-copyright text-center">Copyright © <a style="color:#5d9cec" href="https://www.utiliko.io " target="_blank">Utiliko - Business Management Platform.</a> All rights reserved.
          <p class="alignright d-inline">{{version}}</p>
        </div>
      </div>
  </div>
</div>
<app-spinner></app-spinner>
