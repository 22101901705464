<div class="knowledgebase-overview bg-white">
    <mat-toolbar>
        <div class="comp-logo">
          <img
            src="{{ companyLogo }}"
            onError="this.src='/assets/img/default_company_logo.png'"
          />
        </div>
    </mat-toolbar>
  <div class="kb-overview_wrapper">
    <div class="title_wrapper">
      <div class="title_bg">
        <h1 class="kb_title">Knowledge Base</h1>
        <h3 class="kb_sub_title">Search Knowledge Base by Keyword</h3>
      </div>
      <div class="searchbar">
        <div class="elementor-widget-container">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input #supportsearch header-search [matAutocomplete]="articleAutocomplete" [(ngModel)]="searchText" (input)="search($event.target.value)" type="text" placeholder="Search by Keyword" class="mat-elevation-z3 search-input">
          <mat-autocomplete #articleAutocomplete="matAutocomplete" class="kb_autocomplete">
            <mat-option disabled *ngIf="articleList.length === 0">
              No Articles found
            </mat-option>
            <mat-optgroup *ngFor="let item of articleList; trackBy:trackArticleList;let j = index" [label]="item.label">
              <mat-option *ngFor="let subItem of item.articles; trackBy:trackArticlesById;" >
                <a class="no_link" (click)="changePage(item.label,subItem.artName,subItem?.artId, subItem.artCategoryId)" [routerLink]="subItem.routerLink" [queryParams]="subItem.queryParams">{{subItem.artName}}</a>
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </div>
        <div class="most_view_tags" *ngIf="mostViewedArticles.length > 0">
          <div class="title">Most Viewed:</div>
          <ul class="mv_tags_li_wrap">
            <li class="mv_tags_li" [title]="article.artName" *ngFor="let article of mostViewedArticles; trackBy:trackMostViewedArticles">
              <a (click)="changePage(article.name, article.artName, article.artId, article.artCategoryId)"  [routerLink]="article.routerLink" [queryParams]="article.queryParams">{{article.artName}}</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="kb_cat_wrapper">
      <div class="kb_cat_row row" *ngIf="!noArticleFlag">
        <div class="kb_cat_col col-md-4" *ngFor="let item of items; trackBy:trackItems; let j = index">
          <div class="kb_cat_inner">
            <div class="cat_title">
              <span class="icon_outer_box"><i class="fa fa-file-text" aria-hidden="true"></i></span> {{ item.label }}
            </div>
            <div class="cat_section_list">
              <ng-container *ngIf="item.articles.length > 0">
                <ng-container *ngFor="let subItem of item.articles | slice:0:item.limit; let i = index; trackBy:trackArticles">
                  <div class="cat_section_li">
                    <a
                        name="articleName"
                        style="cursor: pointer"
                        class="articleName"
                        [routerLink]="subItem.routerLink"
                        [queryParams]="subItem.queryParams"
                        (click)="changePage(item.label,subItem.artName,subItem?.artId, item.id)"
                      >
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        <div class="description">{{ subItem.artName }} <span *ngIf="subItem.artIsSticky" class="sticky-article">*</span></div>
                      </a>
                  </div>
                </ng-container>
              </ng-container>
              <div *ngIf="item.articles.length == 0">
                <div class="no-articles-class">No Articles found!</div>
              </div>
            </div>
            <button *ngIf="item.articles.length > 4" class="btn_all" (click)="seeAll(j)">See {{ item.limit === item.articles.length ? 'Less' : 'More'  }} <i class="fa fa-chevron-right"></i></button>
          </div>
        </div>
        <div class="col-xs-12 load_more_wrap" *ngIf="!noArticleFlag">
          <button *ngIf="catCount > items.length && !isLoading" class="btn btn_load_more" (click)="loadMoreArticles()">Load More</button>
          <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
      <div *ngIf="noArticleFlag" class="no-articles">
        <h2>
          No articles found!
        </h2>
      </div>
    </div>
  
  </div>
</div>

<footer class="page-footer">
  <div class="footer_inner">
    <!-- Copyright -->
    <div class="footer-copyright">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a> All rights reserved.
    </div>
    <!-- Copyright -->
    <div class="_version">{{version}}</div>
  </div>
</footer>
