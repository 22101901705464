import {Component, ElementRef, EventEmitter, inject, Inject, Injector, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TsysService} from '../../../../service/tsys.service';
import {ThemeColorInterface} from '../../../../service/Utils/Interfaces.class';
import {PaymentsInfo} from '../../../../service/models/PaymentsInfo';
import {environment} from '../../../../../environments/environment';
import {ConfigService} from '../../../../service/config.service';
import { ToastMessages } from '../../../../service/constant';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-pay-invoice-popup',
    templateUrl: './pay-invoice-popup.component.html',
    styleUrls: ['./pay-invoice-popup.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, MatDivider, MatRadioGroup, FormsModule, MatRadioButton, MatTooltip, NgIf, CdkScrollable, MatDialogContent, MatCheckbox, MatButton, ReactiveFormsModule, NgSelectModule, NgClass, ExtendedModule, MatFormField, MatLabel, MatInput, TranslateModule]
})
export class PayInvoicePopupComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  @Output() paymentsInfoCount = new EventEmitter();
  @ViewChild('sortCol1') sortCol1: MatSort;

  // public cvvForm: FormGroup;
  // public paymentForm: FormGroup;
  public createACHForm: FormGroup;
  // public createCreditcardForm: FormGroup;


  // public activateBtn: boolean = false;
  public showErrorMsg: boolean = false;
  public encryptedTSEP: string;
  public currentStatus: number = 2;

  public paymentInfoId: number;
  public clientId: number;
  public billingZipcode: number;
  IsDisabled = true;
  checked = false;
  public submitted: boolean = false;

  public dataSource = new MatTableDataSource<PaymentsInfo>();

  public themeClass = this.configService.userTheme;
  public themeColor: ThemeColorInterface[];
  typeSelected: boolean = true;
  accountNameFlag: boolean = false;
  fnlnFlag: boolean = false;
  accountTypeList = [
    {
      value: 1,
      label: "Personal Account (PPD)"
    },
    {
      value: 2,
      label: "Business Account (CCD)"
    }
  ];
  // validate cc/ach
  public tsysAch = false;
  public tsysCC = false;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<PayInvoicePopupComponent>,
              private elementRef: ElementRef, @Inject(MAT_DIALOG_DATA) public data: any,
              public tsysService: TsysService, private zone: NgZone, private configService: ConfigService,
              private fb: FormBuilder,
  ) {

    window['angularComponentDialogReference'] = {
      zone: this.zone,
      componentFn: (value) => this.reopenDialog(value),
      component: this,
    };

    window['angularComponentReference'] = {
      zone: this.zone,
      componentFn: (value) => this.updateTsysData(value),
      component: this,
    };
  }

  ngAfterViewInit() {
    this.loadScript(environment.TSYS_MANIFEST_URL + this.data.tsysDeviceId + '?' + this.encryptedTSEP);
  }

  loadScript(url) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    this.elementRef.nativeElement.appendChild(node);
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 'creditCard') {
      this.loadScript(environment.TSYS_MANIFEST_URL + this.data.tsysDeviceId + '?' + this.encryptedTSEP);
    }
  }

  ngOnInit() {
    this.encryptedTSEP = this.data.manifest;
    this.addAchForm();
    this.createACHForm.patchValue({
      acctType:'checking',
      accountType: null
    })
    this.loadJs("../../../../../assets/js/tsys-tsep.js")
  }

  loadJs(url: string){
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  OnChange($event){
         this.IsDisabled =false;
         this.IsDisabled =this.checked;  
           
   }  
  updateModule(event) {
    if (event) {
      if (event.value == 1) {
        this.fnlnFlag = true;
        this.accountNameFlag = false;
      } else if(event.value == 2) {
        this.fnlnFlag = false;
        this.accountNameFlag = true;
      }
    } else {
      this.fnlnFlag = false;
      this.accountNameFlag = false;
    }
  }

  reopenDialog(value) {
    var data = {isComplete: 0, reOpen: 1, errMsg: value ? value['errorMsg'] : 'Failed to add card'};
    this.dialogRef.close(data);
  }

  closeModel() {
    // this.data.msg = 'un done';
    var data1 = {isComplete: 0, reOpen: 0};
    this.dialogRef.close(data1);
  }

  addCCAndActivate() {
    var ccData = {
      authData: this.tsysService.getTsysData(),
      clientId: this.data.clientId
    };

    if (!this.billingZipcode || String(this.billingZipcode).length < 5) {
      this.toastr.error(ToastMessages.INVALID_ZIPCODE)
    } else {
    
    if (ccData.authData) {
      ccData.authData['zipCode'] = this.billingZipcode;
      var cardInfo = {
        creditCardNumber: ccData.authData.card_number,
        expirationDate: ccData.authData.expiration_date,
        cvc: ccData.authData.cvc,
        billingAddress: undefined,
        cardType: ccData.authData.card_type,
        billingZip: ccData.authData['zipCode'],
      };
      var data = {eGroup: 'creditCard', paymentInfo: cardInfo};
      this.dialogRef.close(data);
    } else {
      this.toastr.error("Could not take action because of missing data, please check the form and try again");
      return;
    }
    
    }
  }

  updateTsysData(tsysData) {
    this.tsysService.updateTsysData(tsysData);
  }

  addAchForm() {
    this.createACHForm = this.fb.group({
      businessName: [''],
      fName: [''],
      lName: [''],
      accntNum: ['', Validators.required],
      acctType: ['', Validators.required],
      rtnr: ['', Validators.required],
      bankName: ['', Validators.required],
      accountType : ['', Validators.required],
    });
  }

  selectACH() {
    var data = {existingACH: 1, paymentId: this.paymentInfoId};
    this.dialogRef.close(data);
  }

  addACHAndActivate() {
    this.submitted = true;
    if (this.createACHForm.invalid) {
      return;
    } else {
      this.submitted = true;
      if (this.fnlnFlag) {
        var fname = this.createACHForm.value.fName
        var lname = this.createACHForm.value.lName
        var businessName = this.createACHForm.value.businessName
        var accountType = 'PPD'
      } else {
        var fname = this.createACHForm.value.fName
        var lname = this.createACHForm.value.lName
        var businessName = this.createACHForm.value.businessName
        var accountType = 'CCD'
      }
      var achData = {
        fname: fname?fname : "",
        lname: lname?lname : "",
        businessName: businessName?businessName:"",
        accountNumber: String(this.createACHForm.controls['accntNum'].value),
        routingNumber: this.createACHForm.controls['rtnr'].value,
        bankName: this.createACHForm.controls['bankName'].value,
        acctType: this.createACHForm.value.acctType,
        clientId: this.data.clientId,
        accountHolderType: accountType
      };
      var data = {eGroup: 'achCard', paymentInfo: achData};
      this.dialogRef.close(data);

    }
  }

  validateCCAch(eGroup) {
    // check payment setting
    if (this.configService.tsysCcStatus === 'inactive')  {
      eGroup === 'creditCard' ? this.toastr.error('Please setup TSYS details for proceeding CC payment.!') : '';
      this.tsysCC = true;
    }
    if (this.configService.tsysCcStatus === 'inactive') {
      eGroup === 'achCard' ? this.toastr.error('Please setup TSYS details for proceeding ACH payment.!') : '';
      this.tsysAch = true;
    }
  }

  SearchData(zipcode){
    this.billingZipcode = zipcode;
  }
}
