import { SetupService } from '../../../../service/setup.service';
import { ConfigService } from '../../../../service/config.service';
import { LoginService } from '../../../../service/login.service';
import { TenantExistsService } from '../../../../service/tenant-exists.service';
import { TenantCompaniesService } from '../../../../service/tenant-companies.service';
import { MenuService } from '../../../../service/menu.service';
import { Component, OnInit, Inject, ViewChild, inject } from '@angular/core';
import { MatStepper, MatStep, MatStepLabel, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from '../../../../../environments/environment';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from 'ngx-google-places-autocomplete';
import { AddressComponent } from 'ngx-google-places-autocomplete';
import CryptoJS from 'crypto-js';
import { NgOption, NgSelectModule } from "@ng-select/ng-select";
import { Subscription } from 'rxjs';
import { COMMON } from '../../../../service/constant';
import { ToastrService } from 'ngx-toastr';
import { MatCheckbox } from '@angular/material/checkbox';
import { GooglePlaceDirective as GooglePlaceDirective_1 } from '../../../../../../../libs/third-party-deprecated/src/lib/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgFor, TitleCasePipe } from '@angular/common';

export interface DialogData {
  type: string;
  name: string;
}

@Component({
    selector: 'app-add-company-dialog',
    templateUrl: './add-company-dialog.component.html',
    styleUrls: ['./add-company-dialog.component.scss'],
    standalone: true,
    imports: [MatStepper, MatStep, MatStepLabel, FormsModule, ReactiveFormsModule, NgSelectModule, NgClass, ExtendedModule, MatFormField, MatLabel, MatInput, MatButton, MatStepperNext, NgIf, GooglePlaceDirective_1, MatStepperPrevious, NgFor, MatCheckbox, TitleCasePipe]
})
export class AddCompanyDialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  moduleList: any;
  subscribedModuleList: any;
  request: any;
  firstAddCompanyForm: FormGroup;
  zeroAddCompanyForm: FormGroup;
  secondAddCompanyForm: FormGroup;
  submitted = false;
  checkedListModule: any = [1, 2, 5, 6, 7, 39, 65, 76];
  public configData: any;
  show: boolean = false;
  isDisabled: boolean = false;
  domainChecked: boolean = false;
  showDomainAvailability: boolean;
  yourDomain: string;
  password: string;
  submit = false;
  checkCompany = false;
  country;
  city = '';
  state = '';
  zip = '';
  addressLine1 = '';
  address = '';
  public showError: boolean;
  public showError1: boolean;
  public errorMsg:  string;
  public errorMsg1: string;

  constructor(public dialogRef: MatDialogRef<AddCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private menuService: MenuService,
    private tenantCompaniesService: TenantCompaniesService,
    private tenantExistsService: TenantExistsService,
    private loginService: LoginService,
    private fb: FormBuilder,
    private configService: ConfigService,
    private setupService: SetupService
    ) {
      this.request = this.data;
    }

  ngOnInit() {
    
    this.password = CryptoJS.AES.decrypt(localStorage.getItem("passkey"), '8Z4DXM5QG5KFHHPGIRT11NBTZ18R80O5').toString(CryptoJS.enc.Utf8);
    
    this.firstAddCompanyForm = this.fb.group({
      email: ["", [Validators.required, Validators.email, Validators.pattern(COMMON.EMAIL_PATTERN)]],
      companyName: ["", Validators.required],
      domain: ["", Validators.required],
      companyContact: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      address: ['', Validators.required],
      cityStateZip: ['', Validators.required],
      country: ['', Validators.required],
      website: [''],
    });
    this.zeroAddCompanyForm = this.fb.group({
      industry: ['', Validators.required],
      companyType: ['', Validators.required],
      fein: ['']

    });
    this.secondAddCompanyForm = this.fb.group({
      selectModules: []
    });
    if(!this.zeroAddCompanyForm.value.industry){
      this.zeroAddCompanyForm.controls['industry'].reset();
    }
    if(!this.zeroAddCompanyForm.value.companyType){
      this.zeroAddCompanyForm.controls['companyType'].reset();
    }
  }

  get f() {
    return this.firstAddCompanyForm.controls;
  }

  companyTypeList: NgOption = [
    {
      'value': 'S Corp', 'label': 'S Corp'
    },
    {
      'value': 'C Corp', 'label': 'C Corp'
    },
    {
      'value': 'LLC', 'label': 'LLC'
    }
  ];
  industryList: NgOption = [
    {
      'value': 'Legal Services', 'label': 'Legal Services'
    },
    {
      'value': 'Professional Services', 'label': 'Professional Services'
    },
    {
      'value': 'Medical, Dental, Health Services', 'label': 'Medical, Dental, Health Services'
    },
    {
      'value': 'Construction - Electrician, Plumbers, HVAC, Security', 'label': 'Construction - Electrician, Plumbers, HVAC, Security'
    },
    {
      'value': 'Information Technology (I.T.)', 'label': 'Information Technology (I.T.)'
    },
    {
      'value': 'Product Based Company', 'label': 'Product Based Company'
    },
    {
      'value': 'Service Based Company', 'label': 'Service Based Company'
    },
    {
      'value': 'Product & Service Based Company', 'label': 'Product & Service Based Company'
    }
  ];

  industryData = JSON.parse(JSON.stringify(this.industryList));
  companyData = JSON.parse(JSON.stringify(this.companyTypeList));

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  public handleAddressChange(address: Address) {
    /* if (address.photos && address.photos.length > 0) {
      console.dir(address.photos[0].getUrl({ maxHeight: 500, maxWidth: 500 }));
    } */
    let x = this.getComponentByType(address, "street_number");
    let lng = address.geometry.location.lng();
    let lat = address.geometry.location.lat();
    this.city = '';
    this.state = '';
    this.zip = '';
    this.address = address.name;
    this.addressLine1 = ''

    address.address_components.forEach(element => {
      if (element.types[0] == 'country') {
        this.country = element.long_name;
      } else if (element.types[0] == 'locality') {
        this.city = element.long_name;
      } else if (element.types[0] == 'administrative_area_level_1') {
        this.state = element.long_name;
      } else if (element.types[0] == 'postal_code') {
        this.zip = element.long_name;
      } else {
        if (this.address == '') {
          this.address = element.long_name;
        } else {
          this.address = this.address;// + ", " + element.long_name;
        }
      }
    });
    if (this.city) {
      this.addressLine1 = this.city;
      if (this.state) {
        this.addressLine1 = this.addressLine1 + ", " + this.state;
        if (this.zip) {
          this.addressLine1 = this.addressLine1 + ", " + this.zip;
        }
      }
    } else if (this.state) {
      this.addressLine1 = this.state;
      if (this.zip) {
        this.addressLine1 = this.addressLine1 + ", " + this.zip;
      }
    } else if (this.zip) {
      this.addressLine1 = this.zip;
    }
    this.firstAddCompanyForm.patchValue({
      country: this.country,
      cityStateZip: this.addressLine1,
      address: this.address
    });
    let addressdata;
    addressdata = this.firstAddCompanyForm.value.cityStateZip.split(',');

    if (addressdata[0]) {
      this.showError = false;
      this.city = addressdata[0];
    }
    else {
      this.showError = true;
      this.errorMsg = 'Please add the city';
    }
    if (addressdata[1]) {
      this.showError1 = false;
      this.state = addressdata[1];
    }
    else {
      this.showError1 = true;
      this.errorMsg1 = 'Please add the state';
    }
    if (addressdata[2]) {
      this.showError = false;
      this.zip = addressdata[2];
    }
    else {
      this.showError = true;
      this.errorMsg = 'Please add the zip code';
    }
  }

  checkbillingcityStateZip() {
    if (this.firstAddCompanyForm.value.cityStateZip) {
      let addressdata;
      addressdata = this.firstAddCompanyForm.value.cityStateZip.split(',');
      if (addressdata[0]) {
        this.showError = false;
        this.city = addressdata[0];
      } else {
        this.showError = true;
        this.errorMsg = 'Please add the city';
      }
      if (addressdata[1]) {
        this.showError1 = false;
        this.state = addressdata[1];
      } else {
        this.showError1 = true;
        this.errorMsg1 = 'Please add the state';
      }
      if (addressdata[2]) {
        this.showError = false;
        this.zip = addressdata[2];
      } else {
        this.showError = true;
        this.errorMsg = 'Please add the zip code';
      }
    } else {
      this.showError = false;
      this.showError1 = false;
    }
  }

  public getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(data?) {
    if(data){
      this.dialogRef.close({ data: data });
    }else{
      this.dialogRef.close();
    }

  }

  update() {
    /* if (this.request.type === 'category') {
      this.updateCategory();
    } else {
      this.updateDept();
    } */
  }

  checkDomain(domain) {
    if (domain) {
      this.domainChecked = true;
      domain = domain.replace(/ /g, "").toLowerCase().trim();
      const domainASExists : Subscription = this.tenantExistsService.domainExists(domain + "." + environment.APP_DOMAIN).subscribe((res: any) => {
        if (res.data.isDomainExist) {
          this.isDisabled = !this.show;
          this.showDomainAvailability = false;
          this.yourDomain = "https://" + domain + "."+environment.APP_DOMAIN;
        } else {
          this.showDomainAvailability = true;
          this.isDisabled = false;
          this.yourDomain = "https://" + domain + "." + environment.APP_DOMAIN;
        }
      });
      this.subscriptions.push(domainASExists);
    } else {
      this.domainChecked = false;
    }
  }

  updateCategory() {
    if (!this.request.categoryName || this.request.quota <= 0) {
      this.toastr.error('Fill the required fields');
      return;
    }
  }

  // Add new modules to subscribe module array
  onCheckboxChange(option, event) {
    if (event.checked) {
      this.checkedListModule.push(option.menuId);
    }
    else {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.checkedListModule[i] == option.menuId) {
          this.checkedListModule.splice(i, 1);
        }
      }
    }
  }

  checkEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
      const tenantASExists : Subscription = this.tenantExistsService.tenantExists(email).subscribe((res: any) => {
        if (res.data.isTenantExist) {
          this.show = true;
          this.isDisabled = true;
        } else {
          this.show = false;
          this.isDisabled = false;
        }
      });
      this.subscriptions.push(tenantASExists);
    }
  }

  form0() {
    if (!this.zeroAddCompanyForm.value.industry) {
      this.submit = true;
    } else {
      this.submit = false;
    }
    if (!this.zeroAddCompanyForm.value.companyType) {
      this.checkCompany = true;
    } else {
      this.checkCompany = false;
    }
  }
  updateIndustry() {
    this.submit = false;
  }
  updateCompany() {
    this.checkCompany = false;
  }

  form1(stepper: MatStepper): void {
    if (this.showError || this.showError1) {
      return;
    } else {
      stepper.next();
    }
  }
  form2() {
    if (this.zeroAddCompanyForm.invalid || this.firstAddCompanyForm.invalid || this.secondAddCompanyForm.invalid ) {
      return;
    }else{
      if (this.zip == '') {
        this.zip = '0';
      }
      this.configData = {
        name: this.firstAddCompanyForm.value.companyName,
        email: this.firstAddCompanyForm.value.email,
        domain: this.firstAddCompanyForm.value.domain.replace(/ /g, "").toLowerCase().trim()+"."+environment.APP_DOMAIN,
        contact: this.firstAddCompanyForm.value.companyContact,
        modules: this.checkedListModule.toString(),
        password: this.password,
        industry: this.zeroAddCompanyForm.value.industry,
        companyType: this.zeroAddCompanyForm.value.companyType,
        taxId: this.zeroAddCompanyForm.value.fein,
        leaglName: this.firstAddCompanyForm.value.companyName,
        address: this.firstAddCompanyForm.value.address,
        city: this.city,
        country: this.firstAddCompanyForm.value.country,
        state: this.state,
        zip: this.zip,
        website: this.firstAddCompanyForm.value.website
      };
      const addCompany : Subscription = this.tenantCompaniesService.addCompany(this.configData).subscribe(
        res => {
          if (res.info.code == 200) {
            this.toastr.success("Company added successfully");
            this.close(res.data);
          } else {
            this.toastr.error(res.data.responseMsg);
          }
        },
        error => {
          this.toastr.error("Add company Failed");
        }
      );
      this.subscriptions.push(addCompany);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
}
