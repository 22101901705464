<div class="testcase-comment-section">
  <div class="headerDiv">Comments </div>
  <mat-divider class="primary-color"></mat-divider>
  <div class="testcase_comment_form" *ngIf="commentPermission">
    <form novalidate [formGroup]="testCaseCommentForm" class="" action="javascript:">
      <div class="form-group">
        <textarea appTinymceConfig [tinymceSettings]="config" name="comment" formControlName="comment" id="comment"></textarea>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-4">
            <div class="_log_hours">
              <label>Log Hours: </label>
              <ng-select [items]="hoursMinutesArray" class="custom-select"
                          bindLabel="label" bindValue="value" formControlName="logHours"
                          placeholder="HH:MM" name="logHours">
              </ng-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="billable-chk">
              <mat-checkbox (change)="changeBillStatus()" [checked]="billable"><span class="field_label">Billable</span></mat-checkbox>
            </div>
          </div>
          <div class="col-md-4">
            <div class="choose-template-div">
              <button mat-raised-button [matMenuTriggerFor]="Template" class="choose-template">
                <i class="fas fa-cogs"></i>
                Template 
                <i class="fas fa-chevron-down"></i>
              </button>
        
              <mat-menu #Template="matMenu" [overlapTrigger]="false" class="menu-width"> 
                <ng-container *ngFor="let category of TemplateCategoryArr">
                  <button mat-menu-item  [matMenuTriggerFor]="sub_menu" style="padding-right: 25px;">{{category.categoryName}}</button>
                  <mat-menu #sub_menu="matMenu">
                    <ng-container *ngIf="category.templateArr && category.templateArr.length > 0; else no_template">
                      <button *ngFor="let template of category.templateArr" mat-menu-item (click)="patchTemplate(template)">{{ template.templateName }}</button>
                    </ng-container>
                    <ng-template #no_template>
                      <button disabled mat-menu-item>None</button>
                    </ng-template>
                  </mat-menu>
                </ng-container>  
              </mat-menu>

            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="btn-div text-right">
          <button name="submitBtn" class="btn btn-post" #submitButton (click)="saveComment()"> Post Comment <i class="far fa-paper-plane"></i></button>
        </div>
      </div>
    </form>
  </div>
  <hr />
  <div class="_comments_section">
    <div class="search" *ngIf="commentList.length!==0">
      <input (keyup)="search($event.target.value)" class="search-input custom-search" placeholder="Search">
    </div>
    <div class="comments-style">
      <div class="comments-container">
        <ul id="comments-list" class="comments-list" [class._show_timeline]="commentList.length > 0">
          <li *ngFor="let data of commentList;let index = i">
            <div class="comment-main-level">
              <div class="comment-avatar"><img src={{data?.avatar}} onError="this.src='assets/img/default_avatar.jpg'"
                                               alt="{{data?.fullname}}"></div>
              <div class="comment-box">
                <div class="comment-head">
                  <h6 class="comment-name" [ngClass] = "data?.isDeleted === 1? 'delCont' : 'actCont'">{{data?.fullname}}
                  </h6>
                  <span class="">
                    <span>{{data?.commentDatetime | commonDate : 'date_time'}}</span>
                    <!-- <i name="deleteIcon" *ngIf="isClientActive !== 1 && commentPermission && activated !== 0" (click)="deleteComment(data?.taskCommentId)" class="fai fa-trash-o text-danger"></i> -->
                    <i name="deleteIcon" (click)="deleteComment(data?.taskCommentId)" class="fai fa-trash-o text-danger"></i>
                    <!-- <i name="deleteIcon" *ngIf="isClientActive === 1 && !commentPermission" class="fai fa-trash-o text-danger"></i> -->
                  </span>
                </div>
                <div class="comment-content">
                  <span [innerHTML]="data.comment | safe: 'html'" class="tinyClass"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <mat-paginator *ngIf="totalRecords !== 0" #paginator [pageSizeOptions]="pageSizeArray" (page)="onChange($event)" [length]="totalRecords"
                     [pageSize]="limit" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
