
<div class="api-docs-back-btn">
   <section class="content-header">
     <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
           <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
             <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
         </a>    
     </button>
   </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
  Opportunities-Model
</h2>

  <p><i><code>Add</code></i></p> 
  <ul>
    <li>
        This API is used to create a new opportunity into `tbl_opportunities`. 

    </li>
    <li>
        Before creating opportunity, the steps below will be verified: 
        <ul>
          <li>
              it will check client/lead is exists or not. 

          </li>
          <li>
              checkGroups method will be called to check the permissions(returns groupNo). 

          </li>
          
        </ul>
    </li>
    <li>
        Once the opportunity is created successfully, steps below will be executed. 
        <ul>
          <li>
              send_oppo_notify(refer sendMail.js)- Email will be sent to users by fetching userIds from `tbl_groups` by groupNo. 

          </li>
          <li>
              New timebar will be created into `tbl_opportunities_no_days` by oppoId. 

          </li>
          <li>
              Based on the nextAction(call, meeting, deadline) values will be saved into respective tables with required data.(refer nextAction.js for adding actions into database). 

          </li>
        </ul>
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_add"><u>{{path}}explorer/#!/Opportunities/Opportunities_add</u></a> 

    </li>
  </ul>
 <br />  
   <p><i><code>clientContacts</code></i></p> 
   <ul>
     <li>
        This API will fetch the client contact details using clientId from `tbl_account_details`. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_clientContacts"><u>{{path}}explorer/#!/Opportunities/Opportunities_clientContacts</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>convertToProject</code></i></p> 
   <ul>
     <li>
        This API is used to convert the opportunity into project by <strong>opportunitiesId</strong> . 

     </li>
     <li>
        Before converting to project, it execute the below steps: 
        <ul>
          <li>
              If clientId is present for the opportunity, client details will be fetched else lead details will be fetched. 

          </li>
          <li>
              checkGroups method is used to fetch the groupNo. Based on the groupNo projectManager and Owner values will be fetched. 

          </li>
          <li>
              Project details will be saved into `tbl_project`. 

          </li>
        </ul>
     </li>
     <li>
        Once the project is created successfully, email will be sent using sendProjectNotifyComplete method(refer sendMail.js) 
        <ul>
          <li>
              Timebar details for the created project will be saved in `tbl_opportunities_no_days`. 

          </li>
          <li>
              <strong>convertProject'</strong>: 1 and <strong>'projectId'</strong>: created <strong>projectId</strong> values will be updated into `tbl_opportunities` by opportunityId. 

          </li>
        </ul>
     </li>
<li>              For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_convertToProject"><u>{{path}}explorer/#!/Opportunities/Opportunities_convertToProject</u></a> 


</li>
   </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
     <li>
        This API is used to delete the opportunity from `tbl_opportunities` by opportunityId. 

     </li>
     <li>
        Before deleting the opportunity, it will delete the data related to that opportunity including calls, meetings, comments, tasks, deadlines, timebar, attachments and pinaction if exists. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_delete"><u>{{path}}explorer/#!/Opportunities/Opportunities_delete</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>detailCount</code></i></p> 
   <ul>
     <li>
        This API is used to fetch number of records of each submodule of the opportunity by opportunityId. 

     </li>
     <li>
        `opportunity_detail_count` is a stored procedure to fetch the detail count. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_detailCount"><u>{{path}}explorer/#!/Opportunities/Opportunities_detailCount</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>Details</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the details of particular opportunity using the below methods. 

     </li>
     <li>
        opportunityDetails - it will fetch basic details of opportunity including client/lead details, status of opportunity etc., 

     </li>
     <li>
        avgWonTime method will fetch the average won time through iteration if data exists. 

     </li>
     <li>
        inactiveDays - it is used to fetch activity info of the opportunity. 

     </li>
     <li>
        participantsInfo - this method is used to fetch the details of participants of the opportunity. 

     </li>
     <li>
        pinDetails - it will check whether opportunity is pinned or not. 

     </li>
     <li>
        primaryContact method will fetch the primary contact details of client/lead for that opportunity. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_details"><u>{{path}}explorer/#!/Opportunities/Opportunities_details</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>dropdownList</code></i></p> 
   <ul>
     <li>
        It is used to fetch the list of opportunity Id and name from `tbl_opportunities` for dropdown purpose. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_dropdownList"><u>{{path}}explorer/#!/Opportunities/Opportunities_dropdownList</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>kanbanPopUp</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the required details of opportunity for kanban popup in UI. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_kanbanPopUp"><u>{{path}}explorer/#!/Opportunities/Opportunities_kanbanPopUp</u></a>&nbsp; 

     </li>
   </ul>
 <br />  
   <p><i><code>leadContacts</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the list of contact details of lead from `tbl_contacts` by leadsId if exists. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_leadContacts"><u>{{path}}explorer/#!/Opportunities/Opportunities_leadContacts</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p>
   <ul>
     <li>
        This API is used to fetch the opportunities list from `tbl_opportunities` based on permissions. 

     </li>
     <li>
        isUserDepartmentHead method is used here. 

     </li>
     <li>
        inActiveDays method will be called to fetch inactive days list of the opportunity. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_list"><u>{{path}}explorer/#!/Opportunities/Opportunities_list</u></a> 

     </li>
   </ul> 
 <br />  
   <p><i><code>listByClientLead</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the list of opportunities Id and name from `tbl_opportunities` by moduleId(<strong>client/lead id</strong>) and moduleType(<strong>Client or Leads</strong>). 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_listByClientLead"><u>{{path}}explorer/#!/Opportunities/Opportunities_listByClientLead</u></a> 

     </li>
   </ul>
   <p><i><code>ListById</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the details of particular opportunity from `tbl_opportunities` by opportunityId. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_listById"><u>{{path}}explorer/#!/Opportunities/Opportunities_listById</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
     <li>
        This API is used to modify the details of opportunity into `tbl_opportunities` by opportunityId if exists. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_modify"><u>{{path}}explorer/#!/Opportunities/Opportunities_modify</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>pipelineOpportunities</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the list of opportunity details based on the permissions using opportunityPipelineId. 

     </li>
     <li>
        It will fetch the stages under the entered pipelineId and the opportunities present in each stage. 

     </li>
     <li>
        isUserDepartmentHead method is used here. 
     </li>
     For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_pipelineOpportunities"><u>{{path}}explorer/#!/Opportunities/Opportunities_pipelineOpportunities</u></a> 

   </ul>
 <br />  
   <p><i><code>statisticsUserList</code></i></p> 
   <ul>
     <li>
        This API is used to fetch the users list for statistics based on the permissions. 

     </li>
     <li>
        If userIsOwner: 
        <ul>
          <li>
              getDepartmentUsers method is used(refer myModel.js). 

          </li>
        </ul>
     </li>
     <li>
        If userIsNotOwner: 
        <ul>
          <li>
              isUserDepartmentHead method is used(refer myModel.js).
          </li>
          <li>
              If department exists, users list will be fetched based on the parent value(head department). 

          </li>
        </ul>
     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_statisticsUserList"><u>{{path}}explorer/#!/Opportunities/Opportunities_statisticsUserList</u></a> 
     </li>
   </ul>
 <br /> 
   <p><i><code>Status</code></i></p> 
   <ul>
     <li>
        This API is used to change the status of opportunity by opportunityId. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_status"><u>{{path}}explorer/#!/Opportunities/Opportunities_status</u></a> 

     </li>
   </ul>
 <br />  
   <p><i><code>updateFinalStatus</code></i></p> 
   <ul>
      <li>
          This API is used to update final status of opportunity by opportunityId and statusId. 

      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_updateFinalStatus"><u>{{path}}explorer/#!/Opportunities/Opportunities_updateFinalStatus</u></a> 

      </li>
   </ul>
 <br />  
   <p><i><code>updateStage</code></i></p> 
   <ul>
     <li>
        This API is used to update the opportunity stage values by opportunityId and stageId. 

     </li>
     <li>
        Once stage is updated into `tbl_opportunities`, timebar details will be fetched for oppoId and stageId. 

     </li>
     <li>
        If timbar exists, values will be updated into `tbl_opportunities_no_days` else new timebar will be created. 

     </li>
     <li>
        For reference: <a href="{{path}}explorer/#!/Opportunities/Opportunities_updateStage"><u>{{path}}explorer/#!/Opportunities/Opportunities_updateStage</u></a> 

     </li>
   </ul>
</div>
</mat-card>


