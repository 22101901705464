<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<!--<router-outlet><app-spinner></app-spinner></router-outlet>-->

@if (!isUserLoggedIn) {
    <div id="themeDiv1" [ngClass]="themeClass">
        <div class="wrapper">
            <div class="main-container">
                <div class="page-content">
                    <router-outlet />
                </div>
            </div>
        </div>
    </div>
} @else {
    <app-admin />
}
