import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from "rxjs";
import { TaskService } from '../../../../service/task.service';
import { EStatusCode } from '../../../../service/Utils/Interfaces.class';
import { ParticipantsDialogComponent } from '../participants-dialog/participants-dialog.component';
import {AppComponentBase} from '../../../../admin/shared/AppComponentBase';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';
import { TestcaseActivitiesComponent } from './testcase-activities/testcase-activities.component';
import { TestcaseAttachmentsComponent } from './testcase-attachments/testcase-attachments.component';
import { TestcaseCommentsComponent } from './testcase-comments/testcase-comments.component';
import { TestcaseDetailComponent } from './testcase-detail/testcase-detail.component';
import { MatTabGroup, MatTab, MatTabContent, MatTabLabel } from '@angular/material/tabs';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-test-case-view',
    templateUrl: './test-case-view.component.html',
    styleUrls: ['./test-case-view.component.css'],
    standalone: true,
    imports: [MatIcon, MatTabGroup, MatTab, MatTabContent, TestcaseDetailComponent, MatTabLabel, TestcaseCommentsComponent, TestcaseAttachmentsComponent, TestcaseActivitiesComponent, NgFor, NgIf, MatTooltip]
})
export class TestCaseViewComponent extends AppComponentBase implements OnInit {

  private readonly subscriptions: Subscription[] = [];
  testCaseId;
  responsibleUserId = [];
  responsibleUsersArr = [];
  participantsUsersArr = [];
  testCaseData = [];
  totalCount:number;
  usersArr = [];

  constructor(inject: Injector,
    public taskService: TaskService, public dialogRef: MatDialogRef<TestCaseViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(inject)
  }

  ngOnInit() {

    if (this.data.hasOwnProperty('taskId')) {
      this.testCaseId = parseInt(this.data.taskId);
    } else {
      this.testCaseId = parseInt(this.data);
    }
    this.getActivityCount();
    this.taskService.testCaseId = this.testCaseId;


    this.getTestCaseById();
  }
  getTestCaseById(){
    const testCaseDDLi : Subscription = this.taskService.getTestCasesById(this.testCaseId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        let _tempData = res.data.listResult[0];
        this.usersArr = res.data.users;
        this.responsibleUsersArr = this.usersArr.filter((el) => el.userId === _tempData.responsibleUserId);

        if(_tempData.permission == '2') {
          _tempData['participantsUsersArr'] = [];
        }

        if(_tempData.permission === '3') {
          this.participantsUsersArr = _tempData.customUsers.split(',').map((user) => {
            let userExist = this.usersArr.find((el) => el.userId == user);
            return userExist ? userExist : null;
          }).filter((el) => el !== null);
          _tempData['participantsUsersArr'] = this.participantsUsersArr;
        }

        if(_tempData.permission == '1') {
          _tempData['participantsUsersArr'] = this.responsibleUsersArr;
        }

        _tempData['responsibleUsersArr'] = this.responsibleUsersArr;

        this.testCaseData = _tempData;
      }
    });

    this.subscriptions.push(testCaseDDLi);
  }

  goBack(){
    this.dialog.closeAll();
    this.router.navigateByUrl('/admin/docs/test-and-qa/test-cases');
  }

  openParticipantsDialog(data) {
    if (data.ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      const matDialogConfig: MatDialogConfig = {
        position: {
          top: '50px',
        },
        width: '800px',
        panelClass: ['animated', 'none', this.configService.userTheme],
        backdropClass: 'participantspopup'
      };
      const dialogConfig = matDialogConfig;
      dialogConfig.data = { details: data, type: 'test-case' };
      const dialogRef = this.dialog.open(ParticipantsDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data == true) {
          this.ngOnInit();
        }
      });
    }
  }

  getActivityCount(){
    const testCaseAct : Subscription = this.taskService.getTestCaseActivity(this.testCaseId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.totalCount = res.data.totalActivityCount
      }
    });

    this.subscriptions.push(testCaseAct);
  }

  trackByuserFn(index:number, item){
    return item;
  }
}
