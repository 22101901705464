import { RegisterTenantService } from './../../service/register-tenant.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import {ConfigService} from "../../service/config.service";
import {SurveyService} from "../../service/survey.serviceTemplate";
import { TicketsService } from '../../service/tickets.service';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class ThankYouComponent implements OnInit, OnDestroy {
  progressbarValue = 0;
  curSec: number = 0;
  domain: string;
  showsubdomainCreated: boolean = false;
  showEmailSent: boolean = false;
  showSetupComplete: boolean = false;
  hideProgress: boolean = false;
  showGif: boolean = false;
  isLive: boolean = false;
  message: string;
  hideGoToHomeButton = false;
  private readonly subscriptions: Subscription[] = [];
  constructor( private surveyService: SurveyService,
    private tenantRegister: RegisterTenantService,
    private configService : ConfigService,
    private ticketService: TicketsService
  ) {
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    const messageSubscription = this.ticketService.thankYouMessage.subscribe((res) => {
      this.message = res ? res : 'Please check your email for activating your account and Login to complete your account setup.'
    });
    const homeButtonSubscription = this.ticketService.hideGoToHome.subscribe((res) => {
      this.hideGoToHomeButton = res;
    });
    this.subscriptions.push(messageSubscription, homeButtonSubscription);
    this.domain = this.tenantRegister.getShareValue();
    if (window.location.hostname === 'localhost') {
      this.isLive = true;
    } else{
      this.isLive = false;
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription : Subscription) => {
      if(subscription) {
        subscription.unsubscribe();
      }
    })
  }
}
