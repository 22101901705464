<div class="hold-transition login-page login_main clearfix row align-items-center d-flex flex-wrap">

  <div class="login_page_content m-0 col-md-6 col-sm-12 col-xs-12">
    <h1> Reset Your Password </h1>
    <h4>
      Don't worry Resetting your password is easy  </h4>
    <h4>
      just tell us the email address you registered with us
    </h4>
  </div>

  <div class="login-box col-md-6 col-sm-12 col-xs-12 vh-100 d-flex align-items-center">
    <!-- <div class="login-logo">
      <a routerLink="/"><b>UTILIKO</b></a>
    </div> -->
    <!-- /.login-logo -->
    <div class="login-box-body mx-3">
      <form novalidate [formGroup]="resetPasswordForm"  class="md-float-material form-material">
        <div class="auth-box card">
          <div class="card-block">
            <div class="row m-b-20">
              <div class="col-md-12">
                <h1 class="text-center pv">Reset Password</h1>
              </div>
            </div>
            <div class="form-group form-primary textFields">
              <mat-form-field appearance="outline">
                <mat-label>New Password</mat-label>
                <input matInput placeholder="New Password" name="newPassword" [type]="changeType?'password' : 'text'"  formControlName="newPassword" required>
                <mat-icon matPrefix><i class="fa fa-password" aria-hidden="true"></i></mat-icon>

              </mat-form-field>
              <!-- <div class="messages text-danger" *ngIf="show">Email is not registered in system</div> -->
            </div>

            <div class="form-group form-primary textFields">
              <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input matInput placeholder="Confirm Password" name="confirmPassword" [type]="changeType? 'password' : 'text' "  formControlName="confirmPassword" required>
                <mat-icon matPrefix><i class="fa fa-password" aria-hidden="true"></i></mat-icon>
              </mat-form-field>
              <div class="messages text-danger textFields" *ngIf="show">New and Confirm Password must be same</div>
            </div>

              <div class="d-flex">
                <mat-checkbox [checked]="!disableInput" (change)="checkSelected()">
                    <span >Show Password</span>
                  </mat-checkbox>
              </div>


            <div class="row m-t-30">
              <div class="col-md-12">
                <button type="submit" class="btn btn-block text-center m-b-20 proceedBtn" (click)="onSubmit()">Update Password</button>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div>
            <!-- <hr /> -->
            <div class="m-t-25 text-left">
              <div class="bottom_btn col-12">
                <div class="pull-left cursor-pointer">
                  <a [routerLink]="['/login']" class=""><b>Remembered Password?</b></a>
                </div>
                <div class="pull-right cursor-pointer">
                  <a href="https://www.utiliko.io/free-trial/" target="_blank"><b>Try it free</b></a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>

    <div class="footer-box-body w-100 mt-3 position-absolute bottom-0">
      <div class="footer-copyright text-center">Copyright © <a style="color:#5d9cec" href="https://www.utiliko.io " target="_blank">Utiliko - Business Management Platform.</a> All rights reserved.
        <p class="alignright">{{version}}</p>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
