<div matAutocompleteOrigin #origin="matAutocompleteOrigin" fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar headerComponent">
  <form method="get" class="search_form">
    <select name="location" class="header-select" #supportsearchmodel (change)="search($event)">
      <option value="all">All</option>
      <option value="client">Clients</option>
      <option value="vendors">Vendors</option>
      <option value="task">Task</option>
      <option value="sale">Sales</option>
      <option value="support">Support</option>
      <option value="hr">HR</option>
      <option value="account">Accounting</option>
    </select>
    <input #supportsearch header-search [matAutocomplete]="auto1" [matAutocompleteConnectedTo]="origin" (keyup)="search($event)" type="text"
    placeholder="Search" class="mat-elevation-z3 header-search">
  </form>
  <mat-autocomplete class="search-autocomplete" classlist="search-autocomplete" #auto1="matAutocomplete">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" *ngIf="!showMsg">
      <mat-tab label="Tickets" *ngIf="showDataTck">
        <ng-container *ngIf="searchModel === 'support' || searchModel === 'all' && panelStatusTicket ">
            <mat-option class="search_result" *ngFor="let searchData of halfTicketData; trackBy : trackByTickets;">
              <a [routerLink]="['support/tickets/details/', searchData.ticketsId]" (click)="clearResult()">{{searchData.name }} : {{searchData.subject }} : {{searchData.ticketCode }}</a>
            </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Projects" *ngIf="showDataPro">
        <ng-container *ngIf="searchModel === 'support' || searchModel === 'all' && panelStatusProject">
          <mat-option class="search_result" *ngFor="let searchData of halfProjectData; trackBy : trackByProjects;">
            <a [routerLink]="['support/project/details/', searchData.projectId]" (click)="clearResult()">{{searchData.projectName }} : {{searchData.projectStatus| removeUnderscore }}</a>
          </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Bugs" *ngIf="showDataBug">
        <ng-container *ngIf="searchModel === 'support' || searchModel === 'all' && panelStatusBugs">
          <mat-option class="search_result" *ngFor="let searchData of bugsData; trackBy : trackByBugs;">
            <a [routerLink]="['support/bugs/details/', searchData.bugId]" (click)="clearResult()">{{searchData.bugTitle }} : {{searchData.bugStatus| removeUnderscore }}</a>
          </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Lead" *ngIf="showDataLead">
        <ng-container *ngIf="searchModel === 'sale' || searchModel === 'all' && panelStatusLeads">
          <mat-option class="search_result" *ngFor="let searchData of leadData; trackBy : trackByLeads;">
            <a [routerLink]="['sales/leads/details/', searchData.leadsId]" (click)="clearResult()">{{searchData.leadName }} : {{searchData.contactName }}</a>
          </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Estimate" *ngIf="showDataEstimate">
        <ng-container *ngIf="searchModel === 'sale' || searchModel === 'all' && panelStatusEstimate">
          <mat-option class="search_result" *ngFor="let searchData of estimateData; trackBy : trackByEstimates;">
            <a [routerLink]="['sales/estimates/details/', searchData.estimatesId]" (click)="clearResult()">{{searchData.firstName }} : {{searchData.lastName }} : {{searchData.referenceNo}}</a>
          </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Opportunity" *ngIf="showDataOppoData">
        <ng-container *ngIf="searchModel === 'sale' || searchModel === 'all' && panelStatusOppo">
          <mat-option class="search_result" *ngFor="let searchData of oppoData; trackBy : trackByOpportunities;">
            <a [routerLink]="['sales/opportunities/details/', searchData.opportunitiesId]" (click)="clearResult()">{{searchData.opportunityName }} : {{searchData.stages }}</a>
          </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Task" *ngIf="showDataTas">
        <ng-container *ngIf="searchModel === 'task' || searchModel === 'all' && panelStatusTask">
            <mat-option class="search_result" *ngFor="let searchData of taskData; trackBy : trackByTasks;">
                <a [routerLink]="['admin/tasks/details/', searchData.taskId]" (click)="clearResult()">{{searchData.taskName }} : {{searchData.taskStatus| removeUnderscore }}</a>
            </mat-option>
        </ng-container>
      </mat-tab>
      <mat-tab label="Clients" *ngIf="showDataCli">
          <ng-container *ngIf="searchModel === 'client' || searchModel === 'all' && panelStatusClient">
              <mat-option class="search_result" *ngFor="let searchData of clientData; trackBy : trackByClients;">
                <a [routerLink]="['admin/client/details/', searchData.clientId]" (click)="clearResult()">{{searchData.name }} : {{searchData.email}}</a>
              </mat-option>
          </ng-container>
      </mat-tab>
      <mat-tab label="Vendors" *ngIf="showDataVen">
          <ng-container *ngIf="searchModel === 'vendors' || searchModel === 'all' && panelStatusVendors">
              <mat-option class="search_result" *ngFor="let searchData of vendorData; trackBy : trackByVendors;">
                <a [routerLink]="['admin/vendor/details/', searchData.vendorId]" (click)="clearResult()">{{searchData.name }} : {{searchData.email}}</a>
              </mat-option>
          </ng-container>
      </mat-tab>
      <mat-tab label="Invoice" *ngIf="showDataInv">
          <ng-container *ngIf="searchModel === 'account' || searchModel === 'all' && panelStatusPurchaseOrder">
            <mat-option class="search_result" *ngFor="let searchData of invoiceData; trackBy : trackByInvoices;">
              <a [routerLink]="['accounting/invoice/details/', searchData.invoicesId]" (click)="clearResult()">{{searchData.referenceNo }} : {{searchData.status | removeUnderscore}}</a>
            </mat-option>
          </ng-container>
      </mat-tab>
      <mat-tab label="Purchase Orders" *ngIf="showDataPo">
          <ng-container *ngIf="searchModel === 'account' || searchModel === 'all' && panelStatusInvoice">
            <mat-option class="search_result" *ngFor="let searchData of purchaseOrderData; trackBy : trackByPurchaseOrders;">
              <a [routerLink]="['accounting/purchaseOrder/details/', searchData.purchaseOrderId]" (click)="clearResult()">{{searchData.memo | lowercase}} : {{searchData.status| removeUnderscore }}</a>
            </mat-option>
          </ng-container>
      </mat-tab>
      <mat-tab label="Payment" *ngIf="showDataPay">
          <div *ngIf="searchModel === 'account' || searchModel === 'all' && panelStatusPayment" (scroll)="onScroll()">
            <mat-option class="search_result" *ngFor="let searchData of paymentData; trackBy : trackByPayments;">
              <a [routerLink]="['accounting/paymentsReceived/details/', searchData.paymentsId]" (click)="clearResult()">{{searchData.payerEmail}} : {{searchData.status }}</a>
            </mat-option>
          </div>
      </mat-tab>
    </mat-tab-group>
    <div class="details no-data" *ngIf="showMsg">
      <mat-option disabled class="search_result">
        No Search Result Found!
      </mat-option>
    </div>
  </mat-autocomplete>
</div>