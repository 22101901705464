

<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Timbar Model</h2>
 <p><i><code> getTimebarDetails</code></i></p>  
 <ul>
     <li>
            This API is used to fetch the timebar details from `tbl_opportunities_no_days` by moduleName(<strong>ticket, project or opportunity</strong>) and moduleId(<strong>ticketsId, projectId or opportunitiesId</strong>).  

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Timebar/Timebar_getTimebarDetails"><u>{{path}}explorer/#!/Timebar/Timebar_getTimebarDetails</u></a>  

     </li>
 </ul>
 </div>
</mat-card>
