<div class="settingsAdminForm">
  <div class="card">
    <div class="row" *ngIf="!availabilityFlag">
      <div class="col-md-12 leadFormNotAvailable" >
        This form is no longer available!
      </div>
    </div>
    <div class="row" *ngIf="availabilityFlag">
        <div class="col-sm-3"></div>
        <div class="col-sm-6 border-form">
          <div id="wuform" class="info">
              <h3 id="fname" class="notranslate 0">{{formTitle}}</h3>
              <h5 style="text-align: center">{{formDescription}}</h5>
          </div>
          <form [formGroup]="leadForm" (ngSubmit)="saveForm()" class="form form-register">
            <div id="main" class="noI ">
              <ul id="formFields" class="field-list list-group">
                    <li *ngFor="let item of formControlArray" id="foli11" data-wufoo-field="" data-field-type="text" [ngClass]="selectedItem == item.fieldName ? 'editClass' :'noEdit'" style="cursor: pointer; z-index: 500;" class="list-group-item">
                  
                      <div *ngIf="item.fieldName !== 'messageField' && item.fieldName !== 'addressField'">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <input matInput id="Field11" [name]="item.name" [formControlName]="item.fieldName" [placeholder]="item.placeholder" tabindex="0" [required]="item.required">
                          <mat-label> {{item.label}}</mat-label>
                        </mat-form-field>
                      </div>
                      <div *ngIf="item.fieldName == 'messageField' && item.fieldName !== 'addressField'">
                        <mat-form-field class="example-full-width" appearance="outline">
                          <textarea matInput id="Field11" rows="5" [formControlName]="item.fieldName" [name]="item.name" [placeholder]="item.placeholder" [required]="item.required"></textarea>
                            <mat-label> {{item.label}} </mat-label>
                        </mat-form-field>
                      </div>
                      <div *ngIf="item.fieldName !== 'messageField' && item.fieldName == 'addressField'">
                        <div class="row">
                          <div class="col-md-12">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <input matInput id="Field11" [name]="item.name" [formControlName]="item.fieldName" [placeholder]="item.placeholder" ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" tabindex="0" required>
                              <mat-label> {{item.label}}</mat-label>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <input matInput id="Field11" formControlName="cityStateZip" placeholder="City, State, Zip" tabindex="0" required>
                              <mat-label> City, State, Zip</mat-label>
                            </mat-form-field>
                            <div *ngIf="showError" class="alert-msg">{{errorMsg}}</div>
                            <div *ngIf="showError1" class="alert-msg">{{errorMsg1}}</div>
                          </div>
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width" appearance="outline">
                              <input matInput id="Field11" formControlName="country" placeholder="Country" tabindex="0" required>
                              <mat-label> Country</mat-label>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    
                    </li>
                </ul>
              
            </div>
            <div>
              <button name="createForm" type="submit" mat-raised-button class="btn-submit form-button-40 form_btn createbtn" color="btn-createemployee" >Send</button>
              <button name="cancelLead" mat-raised-button (click)="cancelForm()" class="btn-cancel form_btn" >Reset</button>
            </div>
          </form>
        </div>
        <div class="col-sm-3"></div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
