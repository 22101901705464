
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Leads Model</h2>
  <p><i><code>Add</code></i></p>
  <ul>
      <li>
            This API is used to create a new lead into `tbl_leads`. 

      </li>
      <li>
            Unique fields: lead name, lead email - to check checkUpdate method will be called. 

      </li>
      <li>
            checkGroups method is used to check permission before creating lead. 

      </li>
      <li>
            Once lead created successfully, contact of that lead will be created into `tbl_contacts`. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_add"><u>
{{path}}explorer/#!/Leads/Leads_add</u></a> 

      </li>
  </ul> 
 <br />  
   <p><i><code>convertToClient</code></i></p> 
   <ul>
       <li>
            This API is used to convert the lead to client by below steps. 

       </li>
       <li>
            checkUpdate and checkGroups method are used to check the unique fields and permissions. 

       </li>
       <li>
            Once the client is created successfully, new user will be added with the same emailId into `tbl_users` and contact details of that client will be saved in `tbl_account_details`. 

       </li>
       <li>
            If above queries executed successfully, <strong>convertedClientId</strong>: result.<strong>clientId</strong> will updated into `tbl_leads`. 

       </li>
       <li>
            Once lead is updated, <strong>`client_id`=</strong>result.clientId<strong>,`leads_id`=null </strong>will be updated into `tbl_opportunities` for that leadId. 

       </li>
       <li>
            This API will be called when converting the lead which is created from <i><code>leads</code></i>module. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_convertToClient"><u>
{{path}}explorer/#!/Leads/Leads_convertToClient</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>convertToUbmClient</code></i></p> 
   <ul>
       <li>
            This API is similar to <i><code>convertToClient</code></i> API.
       </li>
       <li>
            This API will be called when converting the lead which is created using <i><code>tenant</code></i>registration. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_convertToUbmClient"><u>
{{path}}explorer/#!/Leads/Leads_convertToUbmClient</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API is used to delete the leads from `tbl_leads` by leadId. 

       </li>
       <li>
            Before deleting the lead, it will delete calls, meetings, comment, attachments,  and contacts of that lead using deleteMultiple(refer myModel.js) method.  

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_delete"><u>{{path}}explorer/#!/Leads/Leads_delete</u></a> 

       </li>
   </ul>
   <br />
   <p><i><code>detailCount</code></i></p> 
   <ul>
       <li>
            This API is used to count of records each submodule of leads module. 

       </li>
       <li>
            To execute this API stored procedure has been written called `lead_detail_count`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_detailCount"><u>{{path}}explorer/#!/Leads/Leads_detailCount</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Details</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the lead details by using leadId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_details"><u>{{path}}explorer/#!/Leads/Leads_details</u></a> 
       </li>
   </ul>
 <br />  
   <p><i><code>dropdownList</code></i></p> 
   <ul>
       <li>
            This API will fetch all the leads list for dropdown purpose. 
       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_dropdownList"><u>{{path}}explorer/#!/Leads/Leads_dropdownList</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>leadNameExists</code></i></p> 
   <ul>
       <li>
            This API will check whether leadName is exists or not. 
       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_leadNameExists"><u>{{path}}explorer/#!/Leads/Leads_leadNameExists</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>leadNameList</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the leads name list from `tbl_leads`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_leadNamelist"><u>{{path}}explorer/#!/Leads/Leads_leadNamelist</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the leads list from `tbl_leads`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_list"><u>{{path}}explorer/#!/Leads/Leads_list</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the details of lead including permissions, contact details etc., by leadId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_modify"><u>{{path}}explorer/#!/Leads/Leads_modify</u></a> 

       </li>
   </ul>
   <br />
   <p><i><code>Status</code></i></p>
   <ul>
       <li>
            This API is used to change the status of lead by leadId and leadStatusId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Leads/Leads_status"><u>{{path}}explorer/#!/Leads/Leads_status</u></a> 

       </li>
   </ul> 
   </div>
</mat-card>

