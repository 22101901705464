import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-commission-calculation-model',
    templateUrl: './commission-calculation-model.component.html',
    styleUrls: ['./commission-calculation-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class CommissionCalculationModelComponent implements OnInit {

  constructor() { }
public path:any;
  ngOnInit() {
    this.path = environment.BASE_URL;
  }

}
