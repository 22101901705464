import { Component, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ProposalsService } from '../../admin/sales/proposals/services/proposals.service';
import { ConfigService } from '../../service/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantExistsService } from '../../service/tenant-exists.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonDatePipe } from '../../admin/shared/pipes/common-date.pipe';
import { COMMON } from '../../service/constant';
import { AlertCommonDialogData, EStatusCode } from '../../service/Utils/Interfaces.class';
import { take } from 'rxjs/operators';
import { BaseResponse, CommonResponse, DomainExistsResponse } from '../../admin/sales/proposals/interfaces/types';
import { CommonDialogModelComponent } from '../../admin/shared/common-dialog-model/common-dialog-model.component';
import { ProposalsSignatureDialogComponent } from './proposals-signature-dialog/proposals-signature-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-proposals-signature',
    templateUrl: './proposals-signature.component.html',
    styleUrls: ['./proposals-signature.component.scss'],
    standalone: true,
    imports: [MatToolbar, NgClass, ExtendedModule, MatLabel, NgIf, MatButton, MatTooltip, CommonDatePipe]
})
export class ProposalsSignatureComponent implements OnInit, OnDestroy {
  public toastr: ToastrService = inject(ToastrService);
  ecryptedId: string;
  proposalData: any;
  hostname: string;
  isProposalExpired = false;
  // footer variables
  public version: string;
  public currYear: number;
  // header variables
  public companyDetails: DomainExistsResponse;
  public themeData: string;

  constructor(
    private configService: ConfigService,
    private proposalService: ProposalsService,
    private route: ActivatedRoute,
    private router: Router,
    private tenantExistsService: TenantExistsService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private commonDatePipe: CommonDatePipe,
    private renderer: Renderer2
  ) {
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'proposal-signature-body');
    this.hostname = window.location.hostname;
    if (this.hostname !== 'localhost') {
      this.checkDomain(this.hostname);
    }
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.ecryptedId = this.route.snapshot.paramMap.get('id');
    this.getProposalDetails();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'proposal-signature-body');
  }

  async checkDomain(domain) {
    if (domain) {
      domain = domain.replace(/ /g, '').toLowerCase().trim();
      const isDomainExists: any = await this.tenantExistsService.domainExists(domain).pipe(take(1))
      .toPromise();
      if (!isDomainExists.data.isDomainExist) {
        this.router.navigate(['/page-not-found'], { replaceUrl: true });
      } else {
        this.companyDetails = isDomainExists.data;
      }
    }
  }

  async getProposalDetails() {
    const proposalResponse: any = await this.proposalService.viewProposal(this.ecryptedId).pipe(take(1))
    .toPromise();
    if (proposalResponse.info.code === EStatusCode.OK) {
      const proposalDataTemp = proposalResponse.data;
      proposalDataTemp.template = this.sanitizeHtml(proposalDataTemp.template);
      this.proposalData = proposalDataTemp;
    } else {
      this.isProposalExpired = proposalResponse.data.isExpired === 1;
      this.proposalData = null;
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async print() {
    const fileName = (this.proposalData.clientLeadName.toLowerCase()).split(' ').join('-');
    const response = await this.proposalService.getCssStyles('assets/styles/proposal-template-print.css').toPromise();
    const style = response || '';

    const printContents = document.getElementById('printProposal').innerHTML;
    const popupWinindow = window.open('', '_blank', 'width=1000,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    const htmlContent = printContents.replace(/\x3C!--\s?pagebreak\s?-->/g, '<div class="custom-page-break"><!-- pagebreak --></div>');
    let companyName, companyWebsite, companyPhone;
    if (this.companyDetails) {
      companyName = this.companyDetails.companyName;
      companyWebsite = this.companyDetails.website.replace(/^https?:\/\//, '');
      companyPhone = this.commonDatePipe.transform(this.companyDetails.companyPhone, 'phone_format');
    }
    popupWinindow.document.write(`<html>
      <head>
        <title>${fileName}-proposal</title>
        <script>function myFunction() {window.close()}</script>
        <style>
        ${style}
        </style>
      </head>
      <body onafterprint="myFunction()" onload="window.print()">
      ${htmlContent}
      ${this.companyDetails ? `
      <footer>
        <div class="footer-content">
        <p>${companyName} | ${companyWebsite} | ${companyPhone}</p>
        </div>
      </footer>` : ''}
      </body>
    </html>`);
    popupWinindow.document.close();
  }

  async declineProposal() {
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Decline',
      message: 'Are you sure you want to decline the proposal?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      data: alertCommonDialogData
    });
    dialogRef.afterClosed().subscribe(async (res: boolean) => {
      if (res) {
        const statusValue = 'declined';
        const proposalId = this.proposalData.proposalsId;
        const statusResponse: BaseResponse<CommonResponse> = await this.proposalService.updateStatusClientView(proposalId, statusValue).pipe(take(1))
        .toPromise();
        if (statusResponse.info.code === EStatusCode.OK) {
          this.toastr.success('The proposal has been marked as declined.');
          this.getProposalDetails();
        }
      }
    });
  }

  async acceptProposal() {
    const dialogRef = this.dialog.open(ProposalsSignatureDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: [
        'animated',
        'slideInDown',
        'proposal-signature-dialog',
        this.configService.userTheme
      ],
      data: {title: 'Proposal', firstName: '', lastName: ''}
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const acceptData = {
          proposalsId: this.proposalData.proposalsId,
          signature: res.signature,
          firstName: res.firstName,
          lastName: res.lastName
        };
        const acceptResponse: BaseResponse<CommonResponse> = await this.proposalService.submitSignedProposal(acceptData).pipe(take(1))
        .toPromise();
        if (acceptResponse.info.code === EStatusCode.OK) {
          this.toastr.success('Proposal Accepted!');
          this.getProposalDetails();
        } else {
          this.toastr.error('Failed to accept the proposal.');
        }
      }
    });
  }
}
