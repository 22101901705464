<div class="tc_detail_wrap">
  <div class="row _row container-header">
    <div class="col-md-9">
    <h4 class="">{{testCaseData?.testCaseName}}</h4>
    </div>
         <div class=" col-md-3 text-right buttonsCenter">
            <span class="account-no">{{testCaseData?.testCaseCode}}</span>
        
            <span class="btn butnClass edit-button edtButton" matTooltip="Edit" (click)="editTestCase(testCaseData.testCaseId)">
              <div class="ripple">
                <i class="far fa-edit edit-icon"> </i>
              </div>
            </span>
           
            <span class="btn butnClass edit-button"  matTooltip="Delete" (click)="deleteTestCase(testCaseData.testCaseId)">
              <div class="ripple">
                <i class="fai fa-trash-o text-danger fa-lg"></i>
              </div>
            </span>
          </div>
    <!-- </div> -->
  </div>
  <!-- <hr class="_hr_divider"/> -->
  <div class="tc_content">
    <div class="_detail_wrap">
      <div class="row _row">
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"><i class="fas fa-project-diagram"></i>Project Type Name :</span>
            <p class="_text">{{projectName}}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"><i class="fas fa-tasks"></i> Type :</span>
            <p class="_text">{{testCaseData?.testCaseType | titlecase}}</p>
          </div>
        </div>
      </div>
      <hr class="_hr_divider"/>
      <div class="row _row">
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"><i class="fa fa-ticket-alt"></i> Module Name :</span>
            <p class="_text">{{testCaseData?.mainModuleName | titlecase}}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"><i class="fa fa-ticket-alt"></i> Sub Module Name :</span>
            <p class="_text">{{testCaseData?.subModulesName | titlecase}}</p>
          </div>
        </div>
      </div>
      <hr class="_hr_divider"/>
      <div class="row _row">
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"> <i class="fas fa-exclamation-circle"></i> Priority :</span>
            <p class="_text">
              <span *ngIf="testCaseData?.testCasePriority === 'low'" style="color: #00b050">{{testCaseData?.testCasePriority | titlecase}}</span>
              <span *ngIf="testCaseData?.testCasePriority === 'medium'" style="color: #ff9900">{{testCaseData?.testCasePriority | titlecase}}</span>
              <span *ngIf="testCaseData?.testCasePriority === 'high'" style="color: #ff0000">{{testCaseData?.testCasePriority | titlecase}}</span>
              <span *ngIf="testCaseData?.testCasePriority === 'critical'" style="color: #9400d3">{{testCaseData?.testCasePriority | titlecase}}</span>
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="_col_inner">
            <span class="_label"> <i class="far fa-check-square"></i>  Status :</span>
            <p class="_text">
              <ng-container *ngIf="testCaseData?.testCaseStatus === 'in_progress'">
                <a class="statusbtn" [matMenuTriggerFor]="menu">
                  <div disabled class="tag-btn progressButton">In Progress <span class="caret"></span></div>
                </a>
              </ng-container>
              <ng-container *ngIf="testCaseData?.testCaseStatus === 'completed'">
                <a class="statusbtn" [matMenuTriggerFor]="menu">
                  <div disabled class="tag-btn successButton">Completed <span class="caret"></span></div>
                </a>
              </ng-container>
              <ng-container *ngIf="testCaseData?.testCaseStatus === 'waiting_for_someone'">
                <a class="statusbtn" [matMenuTriggerFor]="menu">
                  <div disabled class="tag-btn btn_warning_to">Waiting for Someone <span class="caret"></span></div>
                </a>
              </ng-container>
              <ng-container *ngIf="testCaseData?.testCaseStatus === 'approved'">
                <a class="statusbtn" [matMenuTriggerFor]="menu">
                  <div disabled class="tag-btn voiletButton">Approved <span class="caret"></span></div>
                </a>
              </ng-container>
            </p>
          </div>
          <mat-menu #menu="matMenu">
            <span>
              <button mat-menu-item *ngFor="let ts of taskStatusArr" (click)="statusUpdate(ts.value)">{{ts.label}}</button>
            </span>
          </mat-menu>
        </div>
      </div>
      <hr class="_hr_divider"/>
      <div class="row _row">
          <div class="col-md-12">
            <div class="description_col_inner">
              <span class="_label"><i class="fa fa-bars"></i>&nbsp;&nbsp;Step to reproduce :</span>
              <div class="textBoxAllign">{{testCaseData?.stepToReproduce}}</div>
            </div>
          </div>
          
      </div>
      <hr class="_hr_divider"/>
      <div class="row _row">
        <div class="col-md-12">
          <div class="description_col_inner">
            <span class="_label"><i class="fa fa-drivers-license"></i> Expected Result :</span>
            <div class="textBoxAllign">{{testCaseData?.expectedResult}}</div>
          </div>
        </div>
      </div>
      <hr class="_hr_divider"/>
      <div class="row _row">
        <div class="col-md-12">
          <div class="description_col_inner">
            <span class="_label"><i class="far fa-edit"></i>&nbsp;Description :</span>
            <div class="textBoxAllign"  [innerHTML]="testCaseData?.testCaseDescription"></div>
          </div>
        </div>
      </div>
      <hr class="_hr_divider"/>
    </div>
  </div>
</div>
