
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Visibility Model</h2>
 <p><i><code> addModify</code></i></p> 
 <ul>
     <li>
            This API is used to update the visibility columns of list page into `tbl_visibility` if <strong>moduleName</strong> and <strong>columnName</strong> exists. Otherwise new visibility will be created. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Visibility/Visibility_addModify"><u>{{path}}explorer/#!/Visibility/Visibility_addModify</u></a> 

     </li>
 </ul>     
 <br />  
    <p><i><code> listById</code></i></p> 
    <ul>
        <li>
                This API will fetch the visibilities column from `tbl_visibility` by <strong>moduleName</strong>. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Visibility/Visibility_listById"><u>{{path}}explorer/#!/Visibility/Visibility_listById</u></a> 

        </li>
    </ul>
    </div>
</mat-card>
