
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Calls Model </h2>

<p> <i><code>List</code></i></p>
<ul>
<li>
    This API is used to get the list of calls by moduleId and moduleType.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_list" >
    {{path}}explorer/#!/Calls/Calls_list</a>
</li>
</ul>
<br />
<p><i><code>Details</code></i></p>
<ul>
<li>
    This API is used to get details of call from `tbl_calls` by using callId.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_details" >
    {{path}}explorer/#!/Calls/Calls_details</a>
</li>
</ul>
<br />
<p><i><code>Add</code></i></p>
<ul>
<li>
    This API is used to create a new call in `tbl_calls`.
</li>
<li>
    addCall method is used here.(refer nextAction helper)
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_add" >
    {{path}}explorer/#!/Calls/Calls_add</a>
</li>
</ul>
<br />
<p><i><code>Modify</code></i></p>
<ul>
<li>
    This API is used to update the call details for particular callId if exists.
</li>
<li>
    addCall method is used here.(refer nextAction helper)
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_modify" >
    {{path}}explorer/#!/Calls/Calls_modify</a>
</li>
</ul>
<br />
<p><i><code>Delete</code></i></p>
<ul>
<li>
    This API is used to delete the call by using callId from `tbl_calls`.
</li>
<li>
    addCall method is used here.(refer nextAction helper)
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_delete" >
    {{path}}explorer/#!/Calls/Calls_delete</a>
</li>
</ul>
<br />

<p><i><code>getOverdueCallsList</code></i></p>
<ul>
<li>
    This API is used to get the overdue calls list for dashboard module if the below condition satisfies.
</li>
<li>
    tbl_calls.mark_as_done != 1 and (tbl_calls.date, \'%Y-%m-%d\') CURDATE()
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Calls/Calls_getOverdueCallsList" >
    {{path}}explorer/#!/Calls/Calls_getOverdueCallsList</a>
</li>
</ul>
</div>
</mat-card>














