
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Payment Info Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            Not in usage. 
      </li>
  </ul>
   <br />
   <p><i><code>Delete</code></i></p>
   <ul>
       <li>
            This API is used to delete the payment information from `tbl_payment_info` by <strong>paymentInfoId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/paymentInfo/paymentInfo_delete"><u>{{path}}explorer/#!/paymentInfo/paymentInfo_delete</u></a> 

       </li>
   </ul> 
   <br />
   <p><i><code>Details</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the particular payment details from `tbl_payment_info` by <strong>paymentInfoId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/paymentInfo/paymentInfo_details"><u>{{path}}explorer/#!/paymentInfo/paymentInfo_details</u></a> 

       </li>
   </ul>
   <br />
   <p><i><code>listACHByClientId</code></i></p> 
   <ul>
       <li>
            This API is used to get the ACH account list from `tbl_payment_info` by clientId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/paymentInfo/paymentInfo_listACHByClientID"><u>{{path}}explorer/#!/paymentInfo/paymentInfo_listACHByClientID</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listCCByClientId</code></i></p>
   <ul>
       <li>
            This API will fetch the list of credit card from `tbl_payment_info` by clientId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/paymentInfo/paymentInfo_listCCByClientID"><u>{{path}}explorer/#!/paymentInfo/paymentInfo_listCCByClientID</u></a> 

       </li>
   </ul> 
 <br />  
   <p><i><code>listByClientId</code></i></p>
   <ul>
       <li>
            This API will fetch all the list of payment account from `tbl_payment_info` by clientId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/paymentInfo/paymentInfo_listByClientID"><u>{{path}}explorer/#!/paymentInfo/paymentInfo_listByClientID</u></a> 

       </li>
   </ul> 
   </div>
</mat-card>

