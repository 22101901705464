import { Component, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripDots',
    standalone: true
})
export class StripDots implements PipeTransform {
  transform(str: string): any {
    return str.replace(/\./g, '_')
  }
}