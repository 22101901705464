import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { trigger } from '@angular/animations';
import { TaskService } from '../../../service/task.service';
import { TestCasesFormComponent } from './test-cases-form/test-cases-form.component';
import { TestCasesListComponent } from './test-cases-list/test-cases-list.component';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';


@Component({
    selector: 'app-test-cases',
    templateUrl: './test-cases.component.html',
    styleUrls: ['./test-cases.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('fadeInOutTranslate', [])],
    standalone: true,
    imports: [MatTabGroup, MatTab, MatTabContent, TestCasesListComponent, TestCasesFormComponent]
})

export class TestCasesComponent implements OnInit {
  title:string ='New';
  data:any;
  public isEditMode: boolean = false;
  constructor(public taskService:TaskService) { }

  ngOnInit() {
     this.isEditMode = this.taskService.testcaseEditData.isEditMode;
     if(this.isEditMode == true){
       this.title='Update' 
     }else{
       this.title='New'
     }

  }

  onLinkClick(event){
    // console.log('event-->',event);
    if(event.index == 1){
      this.isEditMode = this.taskService.testcaseEditData.isEditMode;
      if(this.isEditMode == true){
        this.title='Update'
      }else{
        this.title='New'
        var data = {
          isEditMode : false,
          editId : null
        }
        this.taskService.testcaseEditData = data;
      }
    }
    if(event.index == 0){
      this.title='New'
        var data = {
    isEditMode : false,
    editId : null
  }
  this.taskService.testcaseEditData = data;
    }
  }

}
