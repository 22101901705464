
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Task Uploaded Files Model</h2>
 <p><i><code>Delete</code></i></p> 
 <ul>
     <li>
            This API is used to delete the single file from the `tbl_task_uploaded_files` by <strong>uploaded file id</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/TaskUploadedFiles/TaskUploadedFiles_delete"><u>{{path}}explorer/#!/TaskUploadedFiles/TaskUploadedFiles_delete</u></a>&nbsp; 

     </li>
 </ul>
 </div>
</mat-card>
