
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Credit Amount Model</h2>
<p><i><code>getCreditAmountById</code></i></p>
<ul>
  <li>
      This API is used to get the credit amount from `tbl_credit_amount` by using moduleId and moduleType.
  </li>
  <li>
      For reference: <a href="{{path}}explorer/#!/CreditAmount/CreditAmount_getCreditAmountById">
      <u>{{path}}explorer/#!/CreditAmount/CreditAmount_getCreditAmountById</u></a>
  </li>
</ul>
</div>  
</mat-card>