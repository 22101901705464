
<div class="api-docs-back-btn">
   <section class="content-header">
     <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
           <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
             <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
         </a>    
     </button>
   </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Estimates Model</h2>
 <p><i><code>acceptedSignatureEstimate</code></i></p> 
 <ul>
   <li>
      This API is used to accept the estimate with signature of the accepted user from the view estimate page. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_acceptedSignatureEstimate"><u>{{path}}explorer/#!/Estimates/Estimates_acceptedSignatureEstimate</u></a> 
   </li>
 </ul>
 <br />  
  <p><i><code>Add</code></i></p>
  <ul>
    <li>
        This API is used to create a new estimate into `tbl_estimates`. 
    </li>
    <li>
        The following will be followed while creating estimate: 
    </li>
    <li>
        estimatesReferenceNoExists - While creating estimate it will generate the estimate reference number if&nbsp; not exists. 
    </li>
    <li>
        checkGroups - method is used here. 
    </li>
    <li>
        Once estimate created successfully, below steps will be followed: 
        <ul>
            <li>
                If items are not selected for the estimate:
                <ul>
                    <li>
                        getEstimateDetails method will be called to get the estimate details. 
                    </li>
                  </ul> 
            </li>
           <li>
                  If items present:
                  <ul>
                      <li>
                          items will be saved in `tbl_items`.
                      </li>
                      <li>
                          calculateTo - method is used to calculate the total estimate values.
                      </li>
                      <li>
                          getEstimateDetails method will be called to get the estimate details. 
                      </li>
                    </ul>  
              </li> 
          </ul> 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_add"><u>{{path}}explorer/#!/Estimates/Estimates_add</u></a> 
    </li>
  </ul>
  <br />  
 <p><i><code>Convert</code></i></p> 
 <ul>
   <li>
      This API is used to convert the estimate into invoice. 
   </li>
   <li>
      Before converting, the following steps will be followed: 
   <ul>
     <li>
        getInvoceNo method will return the last created invoice. 
     </li>
     <li>
        invoiceReferenceNoExists method is used here. 
     </li>
   </ul>
    </li>
    <li>
        Once invoice created successfully, below steps will be followed: 
    <ul>
      <li>
          If items are not selected for the estimate: 
<ul>
  <li>
      These will be updated in `tbl_estimates` (<strong>'invoiced'</strong>: <strong>'Yes'</strong>, <strong>convertedInvoiceId</strong>: invoice.<strong>invoiceId</strong>). 
  </li>
</ul>
      </li>
      <li>
          If items present for that estimate:
      </li>
      <ul>
        <li>
            Items will be saved in `tbl_items` with invoiceId 

        </li>
        <li>
            Once items saved, these will be updated in `tbl_estimates` (<strong>'invoiced'</strong>: <strong>'Yes'</strong>, <strong>convertedInvoiceId</strong>: invoice.<strong>invoiceId</strong>). 

        </li>
      </ul>
    </ul>
      </li>
      <li>
          For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_convert"><u>{{path}}explorer/#!/Estimates/Estimates_convert</u></a> 
      </li>
 </ul>
 <br />  
 <p><i><code>Delete</code></i></p> 
 <ul>
   <li>
      This API is used to delete the estimate from the `tbl_estimates` and the items related to that estimate from `tbl_items` by using estimateId. 

   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_delete"><u>{{path}}explorer/#!/Estimates/Estimates_delete</u></a> 
   </li>
 </ul>
 <br />  
 <p><i><code>Details</code></i></p> 
 <ul>
   <li>
      This API will fetch all the estimate details from `tbl_estimates` by estimateId. 

   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_details"><u>{{path}}explorer/#!/Estimates/Estimates_details</u></a> 

   </li>
 </ul>
 <br /> 
 <p><i><code>Email</code></i></p> 
 <ul>
   <li>
      This API is used to send the estimate through an email. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_email"><u>{{path}}explorer/#!/Estimates/Estimates_email</u></a> 
   </li>
 </ul>
 <br />  
 <p><i><code>estimateDetailPdf</code></i></p>
 <ul>
   <li>
      This API is used to generate the detail page in pdf format using <em><strong>require</strong></em>(<strong>'html-pdf'</strong>). 
 Eg: <a href="https://storage.googleapis.com/milagro-test/vivant%2FEstimate%2FE%3A%2FUtiliko%2Futiliko_API%2F-%2Fclient%2Fmanjula%20test%2Festimate%2F%2F1560418462071-manjula%20test_estimate_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4670818466&amp;Signature=eYlfs6HAiDeq1qruKjndFUgMmU5e6ahj6jZLDWMAHXQUGZfAhHKbQYIHNJPiPtgSvuOawQYRhM43THHEBnLPlWBKnRjDAEqMbm4lfD075%2BHviqyHf8nQuI%2BaK97MPUG7VGst8NvRKz9vBFfijCoB1TlBDjthT8KFI4zwLjcLmfYJW9IKwQlD%2B5uIDjNaoEecIRmpo963TR1o91rYIxdV4lV%2Bkqt1rS8qpsNR%2B6PwTnO3NIkMYOl3o7xyGDpnm5Nwjr68CGW%2F4sN37KZB4946wyVUdYF7TJivS4cyCUnTUOtiehpDo2LMcT1zvGkBQqP%2Bo1mKOO2hIqyxZ11N2nfaeA%3D%3D">
  <u>https://storage.googleapis.com/milagro-test/vivant%2FEstimate%2FE%3A%2FUtiliko%2Futiliko_API%2F-%2Fclient%2Fmanjula%20test%2Festimate%2F%2F1560418462071-manjula%20test_estimate_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4670818466&amp;Signature=eYlfs6HAiDe-q1qruKjndFUgMmU5e6ahj6jZLDWMAHXQUGZfAhHKbQYIHNJPiPtgSvuOawQYRhM43THHEBnLPlWBKnRjDAEqMbm4lfD075%2BHviqyHf8n-QuI%2BaK97MPUG7VGst-8NvRKz9vBFfijCoB1TlBDjthT8KFI4zwLjcLmfYJW9IKwQlD%2B5uIDjNaoEecIRmpo963TR1-o91rYIxdV4lV%2Bkqt1rS8qpsNR%2B6PwTnO3NIkMYOl3o7xyGDpnm5Nwjr68CGW%2F4sN37KZB4946wyVUdYF7TJivS4cy-CUnTUOtiehpD-o2LMcT1zvGkBQqP%2Bo1mKOO2hIqyxZ11N2nfaeA%3D%3D</u></a>&nbsp; 

   </li>
   <li>
      Template for the estimate details page will fetched from the `tbl_email_templates` using emailGroup: &lsquo;<strong>estimate_detail_pdf</strong>&rsquo;. 

   </li>
   <li>
      uploadFilesTOCloud method is used here. 
   </li>
   <li>
      Fs.unlink method will delete file from local path. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_estimateDetailPdf"><u>{{path}}explorer/#!/Estimates/Estimates_estimateDetailPdf</u></a> 
   </li>
 </ul> 
 <br />  
 <p><i><code>List</code></i></p> 
 <ul>
   <li>
      This API is used to fetch the estimate list from `tbl_estimates`. 
   </li>
   <li>
      If filterType is &lsquo;overdue&rsquo;, it will fetch the list which satisfies the following condition: 
      CURRENT_DATE() &gt; (CAST(`estimates`.`due_date` AS DATE)) AND tbl_estimates.status != 'Accepted' AND tbl_estimates.status != 'Declined'. 
      
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_list"><u>{{path}}explorer/#!/Estimates/Estimates_list</u></a> 
   </li>
 </ul>
 <br />  
 <p><i><code>Modify</code></i></p> 
 <ul>
   <li>
      This API is used to update the details of estimate and the items present in that estimate into `tbl_estimates` and `tbl_items` by estimateId, moduleId and referenceNo. 
   </li>
   <li>
      auditTrialList method is used here. 
   </li>
   <li>
      updateItemActivityDetails method is used here. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_modify"><u>{{path}}explorer/#!/Estimates/Estimates_modify</u></a> 
   </li>
 </ul>
 <br />  
 <p><i><code>recentlyAcceptedEstimates</code></i></p> 
 <ul>
   <li>
      Cond: <strong>`tbl_estimates`.`status` =&nbsp; 'Accepted'</strong> 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_recentlyAcceptedEstimates"><u>{{path}}explorer/#!/Estimates/Estimates_recentlyAcceptedEstimates</u></a> 
   </li>
 </ul>
 <br />  
 <p><i><code>Status</code></i></p> 
 <ul>
   <li>
      This API is used to change the status of estimate into `tbl_estimates`. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_status"><u>{{path}}explorer/#!/Estimates/Estimates_status</u></a> 

   </li>
 </ul>
 <br /> 
 <p><i><code>View</code></i></p> 
 <ul>
   <li>
      This API is used to get the estimate details from `tbl_estimates` by estimateId(encrypted) for view page. 
   </li>
   <li>
      For reference: <a href="{{path}}explorer/#!/Estimates/Estimates_view"><u>{{path}}explorer/#!/Estimates/Estimates_view</u></a> 
   </li>
 </ul>
</div>
</mat-card>
