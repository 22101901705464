<div class="api-docs-back-btn">
    <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary">
            <a routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>
        </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
        <h2 class="content-header-name">Helper</h2>
        <p>It will be used to define the method which can be reused across the system.</p>
        <br />

        <p><b><u>commonhelper.js</u> </b></p>

        <p><i><code>checkGroups</code></i></p>
        <ul>
            <li>
                It will check the group permission for the logged in user(returns the usersList(1)).
                <ul>
                    <li>
                        getGroupNo - this method return a groupNo if exists for the (1). Otherwise it will insert the
                        random group number by generateGroupNumber method for the userIds fetched from (1).

                    </li>
                    <li>
                        Once groupNo fetched from checkGroups method, it will create the estimate into
                        `tbl_estimates`.(2)

                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p><i><code>commonMsg</code></i></p>
        <ul>
            <li>
                This method is used to send the final callback response in particular format(info, data, error) based on
                the status.

            </li>
        </ul>
        <br />
        <p><i><code>convertIdToJson</code></i></p>
        <ul>
            <li>
                This method is used to convert the provided id into json format.

            </li>
            <li>
                For example: (20) -&gt; {{htmlStr1}}

            </li>
        </ul>
        <br />
        <p><i><code>Activity</code></i></p>
        <ul>
            <li>
                This method is used to create the activity into `tbl_activities`. It will be called when user is doing
                any I/o operations such as add, update, delete.

            </li>
        </ul>
        <br />
        <p><i><code>checkUpdate</code></i></p>
        <ul>
            <li>
                This method is used to check whether the values exists or not for the given condition in table specified
                in the request.

            </li>
            <li>
                Query: <strong>var </strong>sql = <strong>'select * from '</strong>+ table +<strong>' where
                    '</strong>+where;

            </li>
        </ul>
        <br />
        <p><i><code>removeDuplicates</code></i></p>
        <ul>
            <li>
                This method will remove the duplicate ids from the given array.

            </li>
        </ul>
        <br />
        <p><i><code>addSlashes</code></i></p>
        <ul>
            <li>
                This method is used to accept the special characters in the given string.

            </li>
        </ul>
        <br />
        <p><i><code>setAction</code></i></p>
        <ul>
            <li>
                This method is used to update the values into given table with the conditions satisfied from the request

            </li>
            <li>
                Query - <strong>var </strong>sql = <strong>'UPDATE ' </strong>+ table + <strong>' SET ' </strong>+ value
                +<strong>' where '</strong>+ where;

            </li>
        </ul>
        <br />
        <p><i><code>hashPassword</code></i></p>
        <ul>
            <li>
                It is used to encrypt the password using hash(<strong>sha512</strong>) method.

            </li>
        </ul>
        <br />
        <p><i><code>configItem</code></i></p>
        <ul>
            <li>
                This method is used to fetch the config details from `tbl_config` by tenantCompanyKey.

            </li>
            <li>
                The config values will be saved in the respective config_key as index using iteration.

            </li>
        </ul>
        <br />
        <p><i><code>getUserName</code></i></p>
        <ul>
            <li>
                It will fetch the fullName, userId and profile image of all the users by tenantCompanyKey.

            </li>
            <li>
                Details will be stored in userId index in the result array.

            </li>
        </ul>
        <br />
        <p><i><code>clientCurrencySymbol</code></i></p>
        <ul>
            <li>
                It is used to fetch the currency of the client from `tbl_client` and `tbl_currencies` by clientId.

            </li>
        </ul>
        <br />
        <p><i><code>customFromLabel</code></i></p>
        <ul>
            <li>
                This method is used to fetch the custom field details of particular client by clientId using below
                steps.

            </li>
            <li>
                Get the custom field details from `tbl_custom_field` by form_id.

            </li>
            <li>
                Get the table name `tbl_form` by form_id(client - 11).

            </li>
            <li>
                It will fetch the details of each custom field through iteration.

            </li>
        </ul>
        <br />
        <p><i><code>getDynamicMenu</code></i></p>
        <ul>
            <li>
                This API is used to fetch menu list from `tbl_menu` in tree structure based on the permission.

            </li>
            <li>
                UserSubscribedModules method will return the userId, isOwner and Subscribed Apps list of that userId.

            </li>
            <li>
                getMenus method will return the list of menus based on the below conditions:

            </li>
            <li>
                isOwner == 1: It will fetch all the menus present in Subscribed Apps list.

            </li>
            <li>
                isOwner != 1: It will fetch the menus based on the permissions including policy, roles, resources and
                accessrules.

            </li>
            <li>
                getTree method is used to create the tree structure for the menu list.

            </li>
        </ul>
        <br />

        <p><b><u>defaultSettings.js </u> </b> : while doing signUp these methods will be used.</p>
        <p><i><code>createDefaultEmailTemplates</code></i></p>
        <ul>
            <li>
                This method will insert the default email templates for the emailGroup <strong>activate_account</strong>
                , <strong>change_email</strong> and <strong>registration</strong> into `tbl_email_templates`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultPaymentMethods</code></i></p>
        <ul>
            <li>
                This method will insert the default payment methods with tenantCompanyKey into `tbl_payment_methods`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultCOA</code></i></p>
        <ul>
            <li>
                It will insert the default chart of accounts values into `tbl_chart_of_accounts`, new bank account
                values into `tbl_accounts` and it will insert this account as default_account in `tbl_config`.

            </li>
            <li>
                Once account has been created successfully, it will update that accountId for &lsquo;Bank Account&rsquo;
                COA into `tbl_chart_of_accounts`.

            </li>
            <li>
                New tax rate values will be inserted into `tbl_tax_rates` and this tax rate will be saved as default_tax
                in `tbl_config`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultConfig</code></i></p>
        <ul>
            <li>
                It will insert the default values for particular config_keys into `tbl_config`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultLeadsCategory</code></i></p>
        <ul>
            <li>
                It will insert the default leads category into `tbl_lead_status`.

            </li>
            <li>
                After inserting, it will add the leadStatusId of &lsquo;New&rsquo; lead category as default_lead_status
                into `tbl_config`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultLeaveCategory</code></i></p>
        <ul>
            <li>
                It will insert the default leave category values into `tbl_leave_category`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultLeadSource</code></i></p>
        <ul>
            <li>
                It will add the default lead sources into `tbl_lead_source`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultOpportunitySource</code></i></p>
        <ul>
            <li>
                Like leads, it will add the default opportunity sources into `tbl_opportunity_source`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultTicketCategory</code></i></p>
        <ul>
            <li>
                It will create the default ticket categories into `tbl_ticket_category`

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultProjectCategory</code></i></p>
        <ul>
            <li>
                As like tickets, it will add the default project category values into `tbl_project_category`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultOpportunitiesPipelineAndStages</code></i></p>
        <ul>
            <li>
                This method will insert the default opportunity pipeline into `tbl_opportunity_pipelines` and default
                stages for that pipeline into `tbl_opportunities_state_reason`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultProjectPipelineAndStages</code></i></p>
        <ul>
            <li>
                This method will insert the default project pipeline into `tbl_project_state_pipeline` and default
                stages for that pipeline into `tbl_project_state_reason`.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultAgreementTemplate</code></i></p>
        <ul>
            <li>
                This method is used to insert the new agreement template for MSA and NDA agreements into
                `tbl_email_templates` by emailGroup new_agreement_template.

            </li>
        </ul>
        <br />
        <p><i><code>createDefaultDepartment</code></i></p>
        <ul>
            <li>
                array = [<strong>'Executive Admin'</strong>,<strong>'Sales'</strong>, <strong>'Marketing'</strong>,
                <strong>'Support'</strong>, <strong>'HR'</strong>, <strong>'Accounting'</strong>];

            </li>
            <li>
                This method is used to create the default department for new user by following steps through iteration.

            </li>
            <li>
                Query - to fetch the default policies from `tbl_default_policy`.

            </li>
            <li>
                If the array values [<strong>'Executive Admin'</strong>,<strong>'Sales'</strong>,
                <strong>'Marketing'</strong>, <strong>'Support'</strong>, <strong>'HR'</strong>,
                <strong>'Accounting'</strong>] matched with the response from (1), it will insert the policy into
                `tbl_policy` with <strong>access_rule_id</strong> and roles into `tbl_roles` with
                <strong>policy_id</strong>.

            </li>
            <li>
                If (2) resultant in success, it will insert the department values into `tbl_department` with
                <strong>role_id</strong> from (2).

            </li>
            <li>
                If departmentName is <strong>'Support'</strong>, it will be added as default_department in `tbl_config`.

            </li>
            <li>
                Once department added successfully, it will add the department(eg: <strong>'Executive Admin'</strong>)
                as config_key(<strong>'executive_admin'</strong>) with departmentId into `tbl_config`.

            </li>
            <li>
                If (4) is done, it will check whether the name is <strong>'Sales'</strong>. If yes, that departmentId
                will be updated for the leadId into `tbl_leads`.

            </li>
        </ul>
        <br />
        <p><b><u>emailDynamicTemplates.js </u> </b>: it will be called when sending an email to users.</p>
        <p><i><code>getEmailDynamicTemplate</code></i></p>
        <ul>
            <li>
                It is used to fetch the default email template design for the body of the template.

            </li>
        </ul>
        <br />
        <br />
        <p><b><u>FileManager.js </u> </b>: it will be called while uploading/deleting the files.</p>
        <p><i><code>uploadFilesTOCloud</code></i></p>
        <ul>
            <li>
                This method is used to upload the files and images in GCP(google cloud platform) by calling
                uploadFilesToGoogleStorage.

            </li>
            <li>
                uploadFilesToGoogleStorage: it will upload the list of files by providing the bucketname file name with
                milliseconds(optional) and file directory.
                Eg: <strong>"GOOGLECLOUDSTORAGE" </strong>: {{htmlStr}}
            </li>
            <li>
                Refer: <a
                    href="https://cloud.google.com/gcp/getting-started/"><u>https://cloud.google.com/gcp/getting-started/</u></a>
            </li>
        </ul>
        <br />
        <p><i><code>deleteFileInCloud</code></i></p>
        <ul>
            <li>
                This method is used to delete the files from GCP by calling deleteFileInGoogleCloudStorage with
                bucketName and fileName.

            </li>
        </ul>
        <br />
        <p><b><u>invoiceHelper.js</u> </b></p>
        <p><i><code>getInvoiceCost</code></i></p>
        <ul>
            <li>
                This method is used to fetch the <strong>SUM(total_cost)</strong> of an invoice/purchase order/estimate
                by given value and id.

            </li>
        </ul>
        <br />
        <p><i><code>getInvoiceTaxAmount</code></i></p>
        <ul>
            <li>
                This method is used to get the total tax amount of an invoice/purchase order/estimate by fetching the
                tax rate from `tbl_tax_rates` and <strong>SUM(item_tax_total)</strong> from
                `tbl_items`/`tbl_po_items`(by value).

            </li>
            <li>
                Calculation: tax = (parseFloat(itemTax) + parseFloat(taxAmount)).toFixed(2);

            </li>
        </ul>
        <br />
        <p><i><code>getInvoiceDiscount</code></i></p>
        <ul>
            <li>
                This method is used to calculate the discount amount using the below formula: discountAmount =
                ((discount/100) * cost).toFixed(2)

            </li>
        </ul>
        <br />
        <p><i><code>getInvoicePaidAmount</code></i></p>
        <ul>
            <li>
                It is used to fetch the <strong>SUM(amount)</strong>(remaining amount to be paid) from `tbl_payments` by
                invoice/PO id if the condition satisfies: <strong>(refund_applied = 0 OR refund_applied = 2) AND
                    payment_consideration_flag ="Y"</strong>.

            </li>
        </ul>
        <br />
        <p><i><code>getInvoiceDueAmount</code></i></p>
        <ul>
            <li>
                This method is used to get the due amount of an invoice/PO by fetching the refund amount from
                `tbl_refunds` by invoice/PO id and client/vendor id.

            </li>
            <li>
                Formula: dueAmount = (((cost - discount) + tax) - paymentMade + parseFloat(refundAmount)).toFixed(2).

            </li>
        </ul>
        <br />

        <p><b><u>myModel.js</u> </b></p>
        <p><i><code>getGroupNo</code></i></p>
        <ul>
            <li>
                This method is used to fetch the group_no for the given users list from `tbl_groups`. It will be called
                from the <em>checkGroups</em> method if module includes the participants.

            </li>
        </ul>
        <br />
        <p><i><code>getTsysUrl</code></i></p>
        <ul>
            <li>
                This method will fetch the below config_key and values from `tbl_config` to get tsys payment gateway
                url.

            </li>
            <li>
                Keys: <strong>tsys_cc_api_url OR tsys_ach_gateway_id OR tsys_ach_reg_key OR tsys_mid OR tsys_ach_api_url
                    OR tsys_device_id OR tsys_transaction_key</strong>

            </li>
        </ul>
        <br />
        <p><i><code>generateGroupNumber</code></i></p>
        <ul>
            <li>
                This method is used to generate the random group number if the groupNo doesn&rsquo;t exists for the
                given participants.

            </li>
        </ul>
        <br />
        <p><i><code>isUserDepartmentHead</code></i></p>
        <ul>
            <li>
                This method is used to check whether the logged in user is <strong>owner</strong> or
                <strong>admin</strong> or working under <strong>admin departments</strong>.

            </li>
            <li>
                If yes, they can able to see all the list of values.

            </li>
            <li>
                Else, they can able to see particular list of values based on their permissions from tbl_groups.

            </li>
        </ul>
        <br />
        <p><i><code>isUserOwner</code></i></p>
        <ul>
            <li>
                This method will check whether logged in user is owner or not.

            </li>
            <li>
                Returns 1 if the user is the owner else returns 0.

            </li>
        </ul>
        <br />
        <p><i><code>deleteMultiple</code></i></p>
        <ul>
            <li>
                This method is used to delete the data from database by providing the table, params and whereClause.

            </li>
            <li>
                If table == <strong>'tbl_pinaction'</strong>:
                <ul>
                    <li>
                        Query - <strong>UPDATE " </strong>+ table + <strong>" SET `isDeleted` = 1, deleteDateTime = '"
                        </strong>+ dt.format(<strong>'Y-m-d H:M:S'</strong>) + <strong>"' where module_id =? and
                            module_name=? and tenant_company_key=?</strong>(Eg: params =
                        [<strong>OpportunityId</strong>, <strong>'opportunities'</strong>, companyKey])

                    </li>
                </ul>
            </li>
            <li>
                Else:
                <ul>
                    <li>
                        <strong>UPDATE " </strong>+ table + <strong>" SET `isDeleted` = 1, deleteDateTime = '"
                        </strong>+ dt.format(<strong>'Y-m-d H:M:S'</strong>) + <strong>"' where " </strong>+ whereClause
                        + <strong>" and tenant_company_key=? </strong>(Eg: params = [tfrId, companyKey])

                    </li>
                </ul>
            </li>
        </ul>

        <br />  
        <p><i><code>getActiveUsers</code></i></p>
        <ul>
            <li>
            This method is used to fetch the list of active users details from `tbl_users` and `tbl_account_details`.
        </li>
        </ul>
        <br />
        <p><i><code>assignUserFunction</code></i></p>
        <ul>
            <li>
                It will fetch the assigned users(participants) of the bug/task/project/tickets/opportunity etc., with
                participation type(myself/ everyone/ custom).

            </li>
            <li>
                For getting everyone and custom participants userCountForModule method is used.

            </li>
        </ul>
        <br />
        <p><i><code>userCountForModule</code></i></p>
        <ul>
            <li>
                It is used to fetch the count of users from `tbl_users` as per module by providing userType,
                departmentId and it should not be present in the given department users list.

            </li>
        </ul>
        <br />
        <p><i><code>getDepartmentUsers</code></i></p>
        <ul>
            <li>
                This method is used to get list of users list with department details based on the department id.

            </li>
        </ul>
        <br />
        <p><i><code>checkIsPinned</code></i></p>
        <ul>
            <li>
                This method is used to check whether the project/ticket is pinned or not in `tbl_pinaction` by providing
                userId, moduleName, moduleId.

            </li>
        </ul>
        <br />
        <p><i><code>companySetup</code></i></p>
        <ul>
            <li>
                To setup the initial values for new company the steps below will be followed:
                <ul>
                    <li>
                        It will fetch the fullName, companyName etc., of the tenant from `tbl_tenant_companies` and
                        `tbl_tenants`

                    </li>
                    <li>
                        getAllModules method is used to get all the Subscribed Apps from `tbl_menu`.

                    </li>
                    <li>
                        Once modules are fetched from (2), it will insert or update the initial values for each module
                        into database through iteration by following steps.
                        <ul>
                            <li>
                                <strong>Query here</strong> - it will fetch the resources for each module from
                                `tbl_resources` and `tbl_access_rules`.

                            </li>
                            <li>
                                <strong>Query here</strong> - it will create a new policy for each module by
                                tenant_company_key and access_rule_id fetched from (A).

                            </li>
                            <li>
                                <strong>Query </strong>- it will update the access_rule_id and module_name into
                                `tbl_default_policy`.

                            </li>
                            <li>
                                If (B) is a success, it will insert the roles for that particular policy(from B) into
                                `tbl_roles`.

                            </li>
                            <li>
                                If (D) resultant in success, it will update the roles into `tbl_users` by userId.

                            </li>
                            <li>
                                <strong>Query </strong>- to fetch all the users from `tbl_users`.

                            </li>
                            <li>
                                <strong>Query </strong>- it will check whether groupNo is exists or not for the users
                                from (F). if no, it will generate random groupNo and it will be inserted into
                                `tbl_groups` if groupNo not exists.

                            </li>
                            <li>
                                Once group created successfully, it will insert the below values into database.

                            </li>
                            <li>
                                <strong>Query here</strong> to insert the user details and user contact details into
                                `tbl_leads`, `tbl_contacts` and `tbl_account_details`.

                            </li>
                            <li>
                                Default working days values will be inserted in `tbl_working_days`.

                            </li>
                            <li>
                                Once working days set successfully, the following methods will be called. (for method
                                definition refer defaultSettings.js).
                                <p>createDefaultEmailTemplates</p>
                                <p>createDefaultPaymentMethods</p>
                                <p>createDefaultCOA</p>
                                <p>createDefaultConfig</p>
                                <p>createDefaultLeadsCategory</p>
                                <p>createDefaultLeaveCategory</p>
                                <p>createDefaultLeadSource</p>
                                <p>createDefaultOpportunitySource</p>
                                <p>createDefaultTicketCategory</p>
                                <p>createDefaultProjectCategory</p>
                                <p>createDefaultOpportunitiesPipelineAndStages</p>
                                <p>createDefaultProjectPipelineAndStages</p>
                                <p>createDefaultAgreementTemplate</p>
                                <p>createDefaultDepartment</p>
                            </li>
                            <li>
                                <strong>Query </strong>- it will update the primary_contact and leads_id into
                                `tbl_leads`.

                            </li>
                            <li>
                                <strong>Query </strong>to insert the leads activities into `tbl_activities`.

                            </li>
                            <li>
                                <strong>Query </strong>- default company details will be inserted into `tbl_config`
                                through iteration.

                            </li>
                            <li>
                                Once default config values are inserted into database, finally, it will update the
                                subscribed_modules and isSetupDone as 1 in `tbl_tenant_companies` to complete the
                                application setup.

                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p><i><code>getAllModules</code></i></p>
        <ul>
            <li>
                This method is used to fetch all the Subscribed Apps from `tbl_menu` if status = 1.

            </li>
        </ul>
        <br />
        <p><i><code>auditTrialList</code></i></p>
        <ul>
            <li>
                This method is used to fetch the old details(before updating) of the given module by using moduleId,
                moduleName(<strong>invoices</strong>, <strong>estimates</strong>, <strong>purchase_order</strong>,
                <strong>tickets</strong>, <strong>Opportunities</strong>, <strong>bugs</strong>,
                <strong>project</strong>, <strong>leads</strong> and <strong>tasks</strong>) and oldValue(not required).

            </li>
            <li>
                Once the changes are done, it will be called(after updating) and new activity will be created into
                `tbl_activities` for the modified values by moduleId, moduleName and oldValue(required).

            </li>
        </ul>
        <br />
        <p><i><code>updateItemActivityDetails </code></i></p>
        <ul>
            <li>
                This method is same as like <em>auditTrialList </em>method. Here the values which are fetching and
                creating into `tbl_activities` is related to items of an invoice/ PO/estimate.

            </li>
        </ul>
        <br />
        <p><i><code>getPaymentRelatedPreviousData</code></i></p>
        <ul>
            <li>
                This method is used to fetch the payment related details(before updation) by using
                type(<strong>chartOfAccounts</strong>, <strong>payments</strong>, <strong>transactions</strong>,
                <strong>incomeExpense</strong>, <strong>salesTaxInvoice</strong>, <strong>creditAmount</strong>,
                <strong>refund</strong>), moduleId and fields.

            </li>
        </ul>
        <br />
        <p><b><u>nextAction.js</u> </b></p>

        <p><i><code>addCall</code></i></p>
        <ul>
            <li>
                This method used to create / update the call with respect to moduleType.

            </li>
            <li>
                If moduleType = <strong>leads</strong> : it will check whether callId is present or not. If yes, it will
                update the call details for that leadId else it will create.

            </li>
            <li>
                If moduleType = <strong>opportunities</strong> : it will check whether callId is present or not. If yes,
                it will update the call details for that opportunitiesId else it will create.

            </li>
        </ul>
        <br />
        <p><i><code>addMeeting</code></i></p>
        <ul>
            <li>
                This method will be called when creating the new meeting into `tbl_meetings` from leads/ opportunities
                module.

            </li>
        </ul>
        <br />
        <p><i><code>addDeadline</code></i></p>
        <ul>
            <li>
                This method will be called while creating the new deadline into `tbl_deadline` from opportunities
                module.

            </li>
        </ul>
        <br />
        <br />
        <p><b><u>sendMail.js</u> </b></p>
        <p><i><code>sendMail</code></i></p>
        <ul>
            <li>
                This method is used to check the type of email that should be sent to the users.

            </li>
            <li>
                Type of email type:
                <ul>
                    <li>
                        <strong>Activate</strong>: send_activation_email method will be called.

                    </li>
                    <li>
                        <strong>Forgot_password</strong>: call send_reset_password_email method.
                    </li>
                    <li>
                        <strong>Reset_password</strong>: return the provided type.

                    </li>
                    <li>
                        <strong>Welcome_email</strong>: call send_welcome_email method.

                    </li>
                    <li>
                        <strong>Project_automation_email</strong>: call sendEmailProjectAutomation method

                    </li>
                    <li>
                        <strong>Project_comment_automation_email</strong>: call sendCommentEmailProjectAutomation
                        method.

                    </li>
                    <li>
                        <strong>Project_task_automation_email</strong>: sendTaskAssignmentEmailProjectAutomation method
                        used.

                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p><i><code>Send_reset_password_email, Send_activation_email</code></i></p>
        <ul>
            <li>
                It will generate the template body(from request) of the email and style for that template will be
                applied by calling getEmailDynamicTemplate method.

            </li>
            <li>
                shootAcivationEmail will be called to send the email.

            </li>
        </ul>
        <br />
        <p><i><code>Send_welcome_email</code></i></p>
        <ul>
            <li>
                It will generate the template body(from request) of the email and style for that template will be
                applied by calling getEmailDynamicTemplate method.

            </li>
            <li>
                shootEmail method will be called to send the email.
            </li>
        </ul>
        <br />
        <p><i><code>Send_oppo_notify</code></i></p>
        <ul>
            <li>
                This method is used to send an email while creating the opportunities.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig24.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>shootEmail, shootActivationEmail</code></i></p>
        <ul>
            <li>
                This method is used to send an email with the provided details such as recipient, subject, template body
                etc.,

            </li>
            <li>
                Before sending the email, config details (<strong>smtp_host</strong>, <strong>smtp_pass</strong>,
                <strong>smtp_port</strong>, <strong>smtp_user</strong>, <strong>company_email</strong>) will be fetched
                from `tbl_config`.

            </li>
            <li>
                Email will be sent using transporter by providing
                <em><strong>require</strong></em>(<strong>'nodemailer'</strong>) package.

            </li>
        </ul>
        <br />
        <p><i><code>sendNotifyTicketReplyForClosedStatus</code></i></p>
        <ul>
            <li>
                This method is used to send the email if the ticket is resolved.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig25.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>sendTicketsInfoByEmail</code></i></p>
        <ul>
            <li>
                This method will be called to send the ticket information through email while creating the new ticket.

            </li>
            <li>
                Refer below flowchart for more info.
            </li>
        </ul>

        <figure class="images">
            <img src='../../../../assets/docs/fig26.png' alt='missing' />
        </figure>

        <br />
        <p><i><code>sendEmailTicketAutomation</code></i></p>
        <ul>
            <li>
                This method is used to send an email while applying automation in tickets.

            </li>
            <li>
                Email template will be fetched from `tbl_email_templates` by
                emailGroup(<strong>ticket_details_changed_by_automation</strong>).

            </li>
        </ul>
        <br />
        <p><i><code>sendEmailProjectAutomation, sendCommentEmailProjectAutomation, sendTaskAssignmentEmailProjectAutomation</code></i>
        </p>
        <ul>
            <li>
                It will be called while applying automation in project.

            </li>
            <li>
                Email template will be fetched from `tbl_email_templates` by
                emailGroup(<strong>project_details_changed_by_automation</strong>/
                <strong>project_comment_automation_email</strong>/ <strong>project_task_automation_email</strong>).

            </li>
        </ul>
        <br />
        <p><i><code>sendProjectNotifyAssignUser</code></i></p>
        <ul>
            <li>
                This method will be called to send the email to the user(created user and participants) while creating
                the project.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig27.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>sendProjectNotifyComplete</code></i></p>
        <ul>
            <li>
                This method is used to send the email to users once the project is completed.

            </li>
            <li>
                Refer the below flowchart for more info.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig28.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>sendProjectNotifyClient</code></i></p>
        <ul>
            <li>
                This method is used to send the email to notify the client while creating, updating and converting the
                project from opportunities.

            </li>
            <li>
                Refer the below flowchart for more info.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig29.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>taskNotification</code></i></p>
        <ul>
            <li>
                This method is used to send the email to the user while creating or modify the task

            </li>
            <li>
                Refer the below flowchart for more info.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig30.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>sendMailboxEmail</code></i></p>
        <ul>
            <li>
                This method will be called to send the email from mailbox.

            </li>
        </ul>
        <br />

        <p><i><code>Cron</code></i></p>
        <ul>
            <li>
                Cron is used to automatically send the email for an invoice, purchase order, estimate and to calculate
                the number of days of project, ticket, opportunities from the created date in the specified time.

            </li>
        </ul>
        <br />
        <p><b><u>AchStatusCron.js</u> </b></p>
        <p><i><code>Ach_paid_invoices</code></i></p>
        <ul>
            <li>
                This method is used to update the ach status when the cron runs.

            </li>
            <li>
                updateTransactionDataByAccountId, getCOAInfoForClientInvoices, updateCOAInfo, updateCommission,
                getCalculateCommDuration, updateInvoiceCommission - refer payments model for these methods.

            </li>
            <li>
                calculateTo - refer invoice model for this method flow.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig31.png' alt='missing' />
        </figure>
        <br />
        <figure class="images">
            <img src='../../../../assets/docs/fig31.png' alt='missing' />
        </figure>
        <br />

        <p><b><u>CommonCron.js</u> </b></p>
        <p><i><code>setAttendance</code></i></p>
        <ul>
            <li>
                This method is used to create the attendance for the employee, when they are not on holiday and clock in
                also not done(except public holiday).

            </li>
            <li>
                Steps below for this method:
                <ul>
                    <li>
                        Get the list of user details from `tbl_users` by <strong>activated</strong>: 1 and
                        <strong>userType</strong>:{{htmlStr2}}

                    </li>
                    <li>
                        Check whether attendance exists for the userId in `tbl_attendance`(1).

                    </li>
                    <li>
                        Get the holidays from `tbl_working_days` and check with the current day(2).

                    </li>
                    <li>
                        Get the public holidays from `tbl_holiday`.

                    </li>
                    <li>
                        If the day is not a public holiday it will create the attendance and the iteration continued for
                        the next employee.

                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p><i><code>calculateAllOpportunityNoOfDays</code></i></p>
        <ul>
            <li>
                This method is used to calculate the total number of days of all the opportunities, projects and tickets
                which are not completed through iteration when the cron runs.

            </li>
            <li>
                Refer the below steps for method flow.
                <ul>
                    <li>
                        Get the time bar list from `tbl_opportunities_no_days` if pushTime &lt; today&rsquo;s date and
                        current: 1 using allOpportunitiesNoDays method(1).

                    </li>
                    <li>
                        It will update the days(totalNoOfDays) and pushTime(today&rsquo;s date time) into
                        `tbl_opportunities_no_days` if the below condition satisfied.

                    </li>
                    <li>
                        For project: projectStatus != 'completed' &amp;&amp; allOppsNoDays.pushTime &lt; today&rsquo;s
                        date time.

                    </li>
                    <li>
                        For opportunity: oppoFinalStatus != 1(Won) &amp;&amp; oppoFinalStatus != 2(lost) &amp;&amp;
                        pushTime &lt; today&rsquo;s date time.

                    </li>
                    <li>
                        For ticket: statusId != 6(resolved) &amp;&amp; allOppsNoDays.pushTime &lt; today&rsquo;s date
                        time

                    </li>
                    <li>
                        Iteration to be continued for the next data from (1).

                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p><i><code>resetPullPushTimeForNoOfDays</code></i></p>
        <ul>
            <li>
                This method will update the pullTime: today&rsquo;s datetime, pushTime: today&rsquo;s datetime of the
                timebar into `tbl_opportunities_no_days` for the completed projects, won/lost opportunities and resolved
                tickets through iteration when the cron runs.

            </li>
        </ul>
        <br />
        <p><i><code>autoCloseTickets</code></i></p>
        <ul>
            <li>
                It is used to close the ticket automatically when the cron runs.

            </li>
            <li>
                Fetch all the tickets from `tbl_tickets` if statusId != 6(resolved).

            </li>
            <li>
                The below steps will be executed for each ticket through iteration.

            </li>
            <li>
                getConfigDetailsForTenantCompanyKey1 - get all the config details by tenantCompanyKey.

            </li>
            <li>
                If Date.parse(ticket.<strong>lastReply || </strong>ticket.<strong>updatedDate</strong>) &lt;=
                moment().subtract(configDetails.<strong>auto_close_ticket</strong>, <strong>'hour'</strong>).unix():
                ticket will be closed by updating the statusId(6) into `tbl_tickets`.

            </li>
            <li>
                Once the ticket is resolved email will be sent to the companyEmail by fetching the email template from
                `tbl_email_templates` if emailGroup: 'ticket_closed_email'.

            </li>
        </ul>
        <br />
        <p><i><code>invoicesCron</code></i></p>
        <ul>
            <li>
                This method is used to send emails to users regarding overdue invoices when the cron runs. Refer the
                below flowchart for more info.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig33.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>estimateCron</code></i></p>
        <ul>
            <li>
                This method is used to send emails to users regarding overdue estimates through iteration when the cron
                runs. Refer the below steps for more info.

            </li>
            <li>
                Get the overdue estimate list from `tbl_estimates` if `tbl_estimates`.due_date = today&rsquo;s date.

            </li>
            <li>
                Get the email template from `tbl_email_templates` by emailGroup: 'estimate_email'.

            </li>
            <li>
                Call getConfigDetailsForTenantCompanyKey1 method.

            </li>
            <li>
                Get the companyName, currency, estimate totalCost, taxAmount and discount etc., and replace those values
                into email template body.

            </li>
            <li>
                Generate the estimate detail url by encrypting the id using viewModuleLink.

            </li>
            <li>
                If configDetails[<strong>'estimate_email'</strong>] === <strong>'1'</strong>, email will be validated
                using validateEmail and shootEmail will be called to send email.

            </li>
            <li>
                Finally, {{htmlStr3}} will be updated into `tbl_estimates` by estimate_id.

            </li>
        </ul>
        <br />
        <p><i><code>projectsCron</code></i></p>
        <ul>
            <li>
                This method is used to send the email to users, if the project is overdue when the cron runs.

            </li>
            <li>
                Steps: It will fetch the list of overdue project from `tbl_project` if <strong>tbl_project.end_date =
                    today&rsquo;s date</strong>.

            </li>
            <li>
                The config values and the project details will be replaced email template body and shootEmail will be
                called through iteration.

            </li>
        </ul>
        <br />

        <p><b><u>CronScheduler.js</u> </b></p>
        <ul>
            <li>
                This file is used to schedule the time to run each cron method automatically. All the cron methods will
                start from the scheduler at the specified time.

            </li>
            <li>
                Eg: to call the particular method at the specified time when the cron runs,
                <ul>
                    <li>
                        <strong>var </strong>recurInvCr = <strong>recurInvCron</strong>.recurring_invoice;

                    </li>
                    <li>
                        <strong>var </strong>recurInvCronJob = <strong>new </strong>cron( {{htmlStr4}});

                    </li>
                    <li>
                        logger.info(moment(Date.parse(moment().format(<strong>'YYYY-MM-DD'</strong>))).format() +
                        <strong>'CRON - 4: Recur Inv cron Initialized...'</strong>);

                    </li>
                    <li>
                        recurInvCronJob.start();

                    </li>
                </ul>
            </li>
        </ul>
        <br />

        <p><b><u>RecurringInvoiceCron.js</u> </b></p>
        <p><i><code>Recurring_invoice</code></i></p>
        <ul>
            <li>
                This method is used to create the recurring invoices into `tbl_invoices` when the cron runs.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig34.png' alt='missing' />
        </figure>
        <br />
        <p><i><code>Copy_invoice_items</code></i></p>
        <ul>
            <li>
                This method is used to create the items of an invoice when the cron runs using below steps.

            </li>
            <li>
                Get the items list from `tbl_items` by invoiceId.

            </li>
            <li>
                If item exists, it will fetch the each item info from `tbl_saved_items` and totalSalesPrice of an item
                will be updated into `tbl_chart_of_accounts`.

            </li>
            <li>
                Once the COA has been updated successfully, it will create the new item into `tbl_items` with invoiceId.

            </li>
            <li>
                The above process will be executed for all items through iteration.

            </li>
        </ul>
        <br />
        <p><i><code>Set_next_recur_date</code></i></p>
        <ul>
            <li>
                This method is used to update the next recurring date of an invoice into `tbl_invoices` when the cron
                runs.

            </li>
        </ul>
        <p><i><code>Recurring_autopay</code></i></p>
        <ul>
            <li>
                This method is used to create the payment for autoPay invoice when the cron runs using the below steps.

            </li>
            <li>
                Refer <em>addPayment</em> API in <u>payments Model</u>. For the method flow.

            </li>
        </ul>
        <br />
        <p><b><u>SubscriptionCron.js</u> </b></p>
        <p><i><code>Subscription_auto_payment</code></i></p>
        <ul>
            <li>
                This method is used to create the new subscription invoice and create the payment for that invoice when
                the cron runs.

            </li>
            <li>
                Refer below flow chart for more details.

            </li>
        </ul>
        <figure class="images">
            <img src='../../../../assets/docs/fig35.png' alt='missing' />
        </figure>
        <br />
    </div>
</mat-card>