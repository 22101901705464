import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../../../service/constant';
import { Observable } from 'rxjs';
import { AccessTokenResponse, BaseResponse } from '../interfaces/pbx-consent-types';

@Injectable({
  providedIn: 'root'
})
export class PbxConsentService {

  constructor(private http: HttpClient) {
  }

  getAccessToken(): Observable<BaseResponse<AccessTokenResponse>> {
    return this.http.post<BaseResponse<AccessTokenResponse>>(API.GET_PBX_ACCESS_TOKEN, {});
  }
}
