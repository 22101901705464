
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Sales Tax Invoice Model</h2>
 <p><i><code>listBySalesTax</code></i></p>
 <ul>
     <li>
            This API is used to fetch the list of sales tax invoices from `tbl_sales_tax_invoice`. 

     </li>
     <li>
            For reference: <a href="http://localhost:3000/explorer/#!/SalesTaxInvoice/SalesTaxInvoice_listBySalesTax"><u>http://localhost:3000/explorer/#!/SalesTaxInvoice/SalesTaxInvoice_listBySalesTax</u></a> 

     </li>
 </ul> 
 </div> 
</mat-card>