
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
    </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Contact Model</h2>

    <p><i><code>List</code> </i> </p> 
  <ul>
      <li>
            This API will fetch the list of contact details from `tbl_account_details` by using moduleId(ex.clientId/vendorId) and moduleType(ex.client/vendor).
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_list"><u>{{path}}explorer/#!/Contact/Contact_list</u></a>  
      </li>
  </ul> 
  <br />   
    <p><i><code>Status</code> </i> </p>  
  <ul>
      <li>
            This API is used to change the status(make as primary contact) of contact by using contactId, moduleId and moduleType.  
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_status"><u>{{path}}explorer/#!/Contact/Contact_status</u></a>  
      </li>
    </ul>
  <br />   
    <p><i><code>Add</code> </i> </p>  
  <ul>
      <li>
            This API is used to create a new contact in `tbl_users` and `tbl_account_details`.  
      </li>
      <li>
            If password is not entered for the contact, it will generate random password using  <i><code><strong>require</strong></code></i>(<strong>'crypto'</strong>).  
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_add"><u>{{path}}explorer/#!/Contact/Contact_add</u></a>  
      </li>
  </ul>
  <br />   
    <p><i><code>Details</code> </i> </p>  
  <ul>
      <li>
            This API is used to get the contact details from `tbl_account_details` by using accountId.  
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_details"><u>{{path}}explorer/#!/Contact/Contact_details</u></a>;  
      </li>
  </ul>
  <br />   
    <p><i><code>Modify</code> </i> </p> 
  <ul>
      <li>
            This API is used to update the contact details into `tbl_users` and `tbl_account_details` by using accountDetailsId.  
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_modify"><u>{{path}}explorer/#!/Contact/Contact_modify</u></a>  
      </li>
  </ul> 
  <br />   
    <p><i><code>Delete</code> </i> </p> 
  <ul>
      <li>
            This API will delete the contact from `tbl_account_details` and `tbl_users` by using accountDetailsId.  
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Contact/Contact_delete"><u>{{path}}explorer/#!/Contact/Contact_delete</u></a>  
      </li>
  </ul> 
</div>
</mat-card>
