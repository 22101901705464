import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskService, TestCaseResult } from '../../../../service/task.service';
import { TestCaseViewComponent } from '../test-case-view/test-case-view.component';
import { Common } from '../../../../service/constant';
import { ConfigService } from '../../../../service/config.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-testcase-dialog-entry-component',
    template: ``,
    standalone: true,
})
export class TestcaseDialogEntryComponentComponent implements OnDestroy {

  currentDialog: MatDialogRef<TestCaseViewComponent> = null;
  destroy = new Subject<void>();

  constructor(public taskService:TaskService, public activatedRoute: ActivatedRoute, public configService: ConfigService, private dialog: MatDialog) { 
    this.activatedRoute.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      if (this.currentDialog) {
        this.currentDialog.close();
      }
      this.openDialog(params);
    });
  }

  openDialog(params) : void {
    var detailType = this.taskService.getTaskDetailType();
    const dialogConfig = Common.matDialogConfig;
    dialogConfig.panelClass = [this.configService.userTheme, "animated",
      "slideInRight",
      "full-screen-modal"];
    dialogConfig.data = { taskId: parseInt(params.id), type: detailType };
    this.currentDialog = this.taskService.testCaseDetail.testCaseDialog = this.dialog.open(TestCaseViewComponent, dialogConfig);
    this.currentDialog.afterClosed().subscribe((result: TestCaseResult) => {

      
    });
  }

  ngOnDestroy(){
    this.destroy.next();
  }

}
