

<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>
  
<mat-card>
  <div class="api-docs">
  <h2 class="content-header-name">
    Activities Model
  </h2>
  <p><code><i>List, auditActivityList</i></code></p> 
  <ul>
  <li>
      This API is used to fetch list of activities of a user by moduleId(ie.invoiceId or POId or estimatesId 
      or leadsId or bugId or ticketsId or OpportunitiesId) and moduleType(ie.invoice or 
      PO or estimates or leads or bug or tickets or Opportunities) from `tbl_activities`.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Activities/Activities_list" >
        {{currentUrl}}explorer/#!/Activities/Activities_list</a>
  </li>
</ul>
</div>
</mat-card>