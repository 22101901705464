
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Task Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new task into `tbl_task` by providing required fields. 

     </li>
     <li>
            Before creating the task checkGroups method will be called to get the group permissions(participants). 

     </li>
     <li>
            Once the task has been created successfully, notification will be sent to users(participants) by using <strong>TaskNotification</strong> method(refer sendMail.js). 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Task/Task_add"><u>{{path}}explorer/#!/Task/Task_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>bugList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of bugs from `tbl_bug` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_bugList"><u>{{path}}explorer/#!/Task/Task_bugList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>clientList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of client from `tbl_client` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_clientList"><u>{{path}}explorer/#!/Task/Task_clientList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the task from `tbl_task` by taskId if exists. 

         </li>
         <li>
                Before deleting the task, it will delete the records related to that taskId such as attachments from `tbl_task_attachment` and `tbl_task_uploaded_files`, comments from `tbl_task_comment` and pinaction from `tbl_pinaction` etc., 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_delete"><u>{{path}}explorer/#!/Task/Task_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>detailCount</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the count of records of sub modules present for each task using `task_detail_count` procedure. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_detailCount"><u>{{path}}explorer/#!/Task/Task_detailCount</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getDashboardDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of task details from `tbl_task` for dashboard. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_getDashboardDetails"><u>{{path}}explorer/#!/Task/Task_getDashboardDetails</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getTimelineEvents</code></i></p>
     <ul>
         <li>
                This API is used to fetch the timeline of the task by providing moduleId(<strong>opportunities id or leads id or project id</strong>) and moduleType(<strong>opportunities or leads or project</strong>). 

         </li>
         <li>
                If type = <strong>project</strong>: 
                <ul>
                    <li>
                            It will fetch the details of comments, attachments, taskList, pastMilestones, plannedMilestones using getMilestoneProgress method. 

                    </li>
                </ul>
         </li>
         <li>
                If type != <strong>project</strong>: 
                <ul>
                    <li>
                            It will fetch the details of comments, attachments, taskList, plannedCalls, pastCalls, pastMeetings, plannedMeetings, plannedDeadlines, pastDeadlines, plannedEmails, pastEmails .  

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_getTimelineEvents"><u>{{path}}explorer/#!/Task/Task_getTimelineEvents</u></a> 
         </li>
     </ul> 
 <br />  
     <p><i><code>getTimerClockStatus</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the status of timerClock of particular task by <strong>taskId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_getTimerClockStatus"><u>{{path}}explorer/#!/Task/Task_getTimerClockStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>goalList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of goals from `tbl_goal_tracking` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_goalList"><u>{{path}}explorer/#!/Task/Task_goalList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>inProgressTaskDashboard</code></i></p> 
     <ul>
         <li>
                This API will fetch count of task from `tbl_task` if <strong>task_status = in_progress</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_inProgressTaskDashboard"><u>{{path}}explorer/#!/Task/Task_inProgressTaskDashboard</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>leadsList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of leads from `tbl_leads` for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_leadsList"><u>{{path}}explorer/#!/Task/Task_leadsList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p>
     <ul>
         <li>
                This API is used to fetch the list of task details including opportunity, project, goalTracking, bugs, client etc., by providing <strong>Task List type ie. 1) alltask - (for displaying all current Task List) 2) completed -(List with completed Task List Status) , 3) todo -(List with Status as "Not Started" Task List ) 4) client - Client Task List 6) goal - Goal Task List 6) project - Project Task List 7) leads - Leads Task List 8) opportunities -Opportunities Task List 9) bugs - Bug Task List</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_list"><u>{{path}}explorer/#!/Task/Task_list</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of particular task by <strong>task id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_listById"><u>{{path}}explorer/#!/Task/Task_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>milestoneListbyProjectId</code></i></p> 
     <ul>
         <li>
                This API will fetch the milestone list from `tbl_milestones` by <strong>project id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_milestoneListbyProjectId"><u>{{path}}explorer/#!/Task/Task_milestoneListbyProjectId</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p>
     <ul>
         <li>
                This API is used to modify the details of particular task by <strong>taskId</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_modify"><u>{{path}}explorer/#!/Task/Task_modify</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>opportunityList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of opportunities from `tbl_opportunities` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_opportunityList"><u>{{path}}explorer/#!/Task/Task_opportunityList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>projectList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of projects from `tbl_project` for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_projectList"><u>{{path}}explorer/#!/Task/Task_projectList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>responsibleUserList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of users from `tbl_users` and `tbl_account_details` if it satisfies conditions present in the query. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_responsibleUserList"><u>{{path}}explorer/#!/Task/Task_responsibleUserList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>statusUpdate</code></i></p> 
     <ul>
         <li>
                This API is used to change the status of particular task by <strong>taskId</strong> and <strong>taskStatus(</strong><strong>not_started, in_progress, completed</strong><strong>)</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_statusUpdate"><u>{{path}}explorer/#!/Task/Task_statusUpdate</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>taskClientName</code></i></p> 
     <ul>
         <li>
                This API will fetch the clientName of the task by <strong>taskId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_taskClientName"><u>{{path}}explorer/#!/Task/Task_taskClientName</u></a> 

         </li>
     </ul>
     <p><i><code>taskdepartmentDD</code></i></p>
     <ul>
         <li>
                It will fetch all the department list from `tbl_department`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_taskdepartmentDD"><u>{{path}}explorer/#!/Task/Task_taskdepartmentDD</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>tasksTimer</code></i></p> 
     <ul>
         <li>
                This API is used to create/update the timer values into `tbl_tasks_timer`. 

         </li>
         <li>
                If status == <strong>'on'</strong>: 
                <ul>
                    <li>
                            Timer details will be created into `tbl_timer_clockin_clockout` 

                    </li>
                </ul>
         </li>
         <li>
                If status == <strong>'off'</strong>: 
                <ul>
                    <li>
                            It will check whether timer is started or not in `tbl_timer_clockin_clockout` by timerClockinClockoutId. 

                    </li>
                    <li>
                            If time started, new timer details will be updated for timerClockinClockoutId and new timer will be created in `tbl_tasks_timer` 

                    </li>
                    <li>
                            If type == <strong>'ticket'</strong>, checkTicketClockTimerStatus method will be called to get the clock status. 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_tasksTimer"><u>{{path}}explorer/#!/Task/Task_tasksTimer</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>ticketDDList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the list of tickets from `tbl_tickets` for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_ticketDDList"><u>{{path}}explorer/#!/Task/Task_ticketDDList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>totalTimeById</code></i></p> 
     <ul>
         <li>
                This API will fetch the total timer details in hours, minutes,seconds format by providing <strong>taskId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_totalTimeById"><u>{{path}}explorer/#!/Task/Task_totalTimeById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>updateTimelineEvents</code></i></p> 
     <ul>
         <li>
                This API is used to update the timeline events(calls, meetings, deadlines, email) of opportunities, leads and project into respective tables by providing <strong>Id(</strong><strong>opportunities id or leads id or project id</strong><strong>), markAsDone(</strong><strong>ie. 0 or 1</strong><strong>) and type(</strong><strong>ie. meetings or calls or deadlines or mails</strong><strong>)</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Task/Task_updateTimelineEvents"><u>{{path}}explorer/#!/Task/Task_updateTimelineEvents</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
