import { Component, inject, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { SignaturePad as SignaturePad_1 } from '../../../../../../libs/third-party-deprecated/src/lib/signaturepad/angular2-signaturepad.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'app-proposals-signature-dialog',
    templateUrl: './proposals-signature-dialog.component.html',
    styleUrls: ['./proposals-signature-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDivider, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatLabel, SignaturePad_1, MatDialogActions, MatButton]
})
export class ProposalsSignatureDialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  @Input() DialogData;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  proposalForm: FormGroup;
  public signaturePadOptions = {
    'minWidth': 1,
    'canvasWidth': 500,
    'canvasHeight': 200
  };

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProposalsSignatureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.proposalForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required]
    });
  }

  closeModel() {
    this.data.msg = '';
    this.dialogRef.close(null);
  }

  clear() {
    this.signaturePad.clear();
  }

  saveSignature() {
    Object.values(this.proposalForm.controls).forEach(control => {
      control.markAsTouched();
    });

    if (this.proposalForm.invalid) {
      return;
    }

    if (this.signaturePad.isEmpty()) {
      this.toastr.info('Please provide signature.');
      return;
    }

    this.getSignedAreaImage();
  }

  getSignedAreaImage() {
    const canvas = this.signaturePad['signaturePad'].canvas;
    const height = this.signaturePad.options['canvasHeight'];
    const width = this.signaturePad.options['canvasWidth'];
    const pixelData = this.signaturePad['signaturePad']._ctx.getImageData(0, 0, width, height).data;

    let maxX = -1;
    let maxY = -1;
    let minX = width;
    let minY = height;
    // to get the signed area of the signature from the canvas
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const index = (y * width + x) * 4;
          const r = pixelData[index];
          const g = pixelData[index + 1];
          const b = pixelData[index + 2];
          const a = pixelData[index + 3];
          if (r + g + b + a > 0) {
              minX = Math.min(minX, x);
              minY = Math.min(minY, y);
              maxX = Math.max(maxX, x);
              maxY = Math.max(maxY, y);
          }
        }
    }

    const signatureWidth = maxX - minX;
    const signatureHeight = maxY - minY;
    // to create a new temp image from the cropped signature area
    const signatureImage = new Image();
    signatureImage.src = canvas.toDataURL('image/png', 1);
    // to get the data url of the cropped signature
    const loadImage = new Promise<string>((resolve, reject) => {
      signatureImage.onload = () => {
        const imageUrl = this.cropImage(signatureImage, minX, minY, signatureWidth, signatureHeight);
        resolve(imageUrl);
      };
      signatureImage.onerror = reject;
    });

    loadImage.then(imageUrl => {
      this.data['firstName'] = this.proposalForm.value.firstName;
      this.data['lastName'] = this.proposalForm.value.lastName;
      if (imageUrl) {
        this.data['signature'] = imageUrl;
        this.dialogRef.close(this.data);
      } else {
        this.toastr.info('Please provide signature.');
      }
    }).catch(error => {
      this.signaturePad.clear();
      this.toastr.info('Error loading image. Please sign Again');
    });
  }

  // to cropped signature image
  cropImage(image, x, y, width, height) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
    return canvas.toDataURL('image/png');
  }
}
