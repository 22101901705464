
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Roles Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new role into `tbl_roles` by providing <strong>roleName</strong> and created <strong>policyId</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Roles/Roles_add"><u>{{path}}explorer/#!/Roles/Roles_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>checkRoleNameExists</code></i></p> 
     <ul>
         <li>
                This API is used to check whether roles name exists or not. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_checkRoleNameExists"><u>{{path}}explorer/#!/Roles/Roles_checkRoleNameExists</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>DDlist</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the list of roles from `tbl_roles` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_DDlist"><u>{{path}}explorer/#!/Roles/Roles_DDlist</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the role from `tbl_roles` by roleId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_delete"><u>{{path}}explorer/#!/Roles/Roles_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of roles based on the policies, resources and accessrules of the logged in user. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_list"><u>{{path}}explorer/#!/Roles/Roles_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                This API will fetch the details of particular role from `tbl_roles` by <strong>Role Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_listById"><u>{{path}}explorer/#!/Roles/Roles_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify/update the details of particular role by <strong>Role Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Roles/Roles_modify"><u>{{path}}explorer/#!/Roles/Roles_modify</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
