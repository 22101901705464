import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-testcase-view-attachment',
    templateUrl: './testcase-view-attachment.component.html',
    styleUrls: ['./testcase-view-attachment.component.css'],
    standalone: true,
    imports: [NgIf, NgxDocViewerModule, MatTooltip]
})
export class TestcaseViewAttachmentComponent implements OnInit {

  fileURL: string;

  constructor(public dialogRef: MatDialogRef<TestcaseViewAttachmentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fileURL = data.url.replace(/"/g, "'");
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
