
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Client Model </h2>

<p><i><code>Add</code></i></p>
<ul>
<li>
    This API will add a new client into the client table in the database.
</li>
<li>
    While creating a new client, 
    contact of that particular client will also saved in account_details table.
</li>
<li>
    Unique fields - emailId,companyKey.
</li>
<ul>
  <li>
      Permissions -  
      when permission type is myself,that 
      particular user can able to see that client when logged in into the system.
  </li>
  <li>
      when permission type is everyone, then everyone can able to see that client.
  </li>
  <li>
      when permission type is custom, only selected user can able to see that 
      client when logged in into the system.
  </li>
  </ul>
  <li>
      Each client will have a unique account number which will be generated while creating a new client
  </li>
  <li>
      Once the client is created successfully, then that activity will be added in activities table.
  </li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_add" >
    {{path}}explorer/#!/Client/Client_add</a>
</li>
</ul>
<br />

<p><i><code>clientPayments</code></i></p> 
<ul>
<li>
    This API is used to fetch the list of income account if the below condition satisfies.
</li>
<li>
    This function will get the list of invoices that the client will have, 
    then it will provide payment information of that client.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_clientPayments" >
    {{path}}explorer/#!/Client/Client_clientPayments</a>
</li>
</ul>
<br />

<p><i><code>contactList</code></i></p> 
<ul>
<li>
    This API will fetch all the contact list of the particular client from the account_details table with respect to client id with filter option.
    Filter: <code><p>{{htmlStr}}</p></code>  as optional  
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_contactList" >
    {{path}}explorer/#!/Client/Client_contactList</a>
</li>
</ul>
<br />

<p><i><code>Delete</code></i></p>
<ul>
<li>
    This API will delete the particular client from the client table with respect to client id if exists.
</li>
<li>
    When deleting the client, following columns will be updated in client table for that particular client
    <ul>
      <li>
          isDeleted - 1
      </li>
      <li>
          deleteDateTime - time of deletion
      </li>
    </ul>
</li>
<li>Once the client is deleted successfully, that activity will be added in activities table
     For reference: <a href="{{path}}explorer/#!/Client/Client_delete" >
        {{path}}explorer/#!/Client/Client_delete</a>
</li>
</ul>
<br /> 

<p><i><code>deleteModuleCount</code></i></p>
<ul>
<li>
    This API will fetch the count of all the apps in which the particular client is related to it. Before deleting any client it will show the count of all the apps.
</li>
<li>
    Query call Client_related_module_count(?, ?, ?)
</li>
<li>
  Where Client_related_module_count is the stored procedure which will get the each app count with respect to the clientId, companyKey and isDeleted value.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_deleteModuleCount" >
    {{path}}explorer/#!/Client/Client_deleteModuleCount</a>
</li>
</ul>
<br />

<p><i><code>detailCount</code></i></p> 
<ul>
<li>
    This API will fetch the count of records of each submodule in the client module with respect to clientId if exists.
</li>
<li>
    Query call client_detail_count(?, ?, ?)
</li>
<li>
    Where client_detail_count is the stored procedure which will get the each sub module count.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_detailCount" >
    {{path}}explorer/#!/Client/Client_detailCount</a>
</li>
</ul>
<br />

<p><i><code>Details</code></i></p> 
<ul>
<li>
    This API is used to get all the details of particular client with respect to client id.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_details" >
    {{path}}explorer/#!/Client/Client_details</a>
</li>
</ul>
<br />

<p><i><code>dropdownList</code></i></p>
 
<ul>
<li>
    This API will fetch all the active clients from client table with respect to tenant company key.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_dropdownList" >
    {{path}}explorer/#!/Client/Client_dropdownList</a>
</li>
</ul>
<br />

<p><i><code>filterByLocation</code></i></p> 
<ul>
<li>
    This API will fetch the multiLocation site list of that particular client upto third level child.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_filterByLocation" >
    {{path}}explorer/#!/Client/Client_filterByLocation</a>
</li>
</ul>
<br />

<p><i><code>getAllPayments</code></i></p>

<ul>
<li>
    This api is used to get the payment list of that particular client with respect to the client id and filter as an optional parameter.(filter: <code><p>{{htmlStr}})</p></code>
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_getAllPayments" >
    {{path}}explorer/#!/Client/Client_getAllPayments</a>
</li>
</ul>
<br />

<p><i><code>List</code></i></p>
<ul>
<li>
    This API will list all the clients if user is a super admin else it will list the clients based on the permissions.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_list" >
    {{path}}explorer/#!/Client/Client_list</a>
</li>
</ul>
<br />

<p><i><code>listOnMap</code></i></p> 
<ul>
<li>
    This API will list all the clients with respect to company key to show it in map.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_listOnMap" >
    {{path}}explorer/#!/Client/Client_listOnMap</a>
</li>
</ul>
<br />

<p><i><code>Modify</code></i></p> 
<ul>
<li>
    This API is used to update the details of particular client in database.
</li>
<li>
    Once the client details are updated successfully, that activity will be added in account_details table.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_modify" >
    {{path}}explorer/#!/Client/Client_modify</a>
</li>
</ul>
<br />

<p><i><code>multiLocationDropdown</code></i></p>
<ul>
<li>
    This API will fetch the multiLocation site list at sub-parent client level if clientId exists. Otherwise, it will list all the muliLocation site list for super-parent client.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_multiLocationDropdown" >
    {{path}}explorer/#!/Client/Client_multiLocationDropdown</a>
</li>
</ul>
<br />

<p><i><code>ubmClientDetails</code></i></p>
 
<ul>
<li>
    This API will fetch the UBM client details with respect to clientId
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_ubmClientdetails" >
    {{path}}explorer/#!/Client/Client_ubmClientdetails</a>
</li>
</ul>
<br />

<p><i><code>updateBillingAddress</code></i></p> 
<ul>
<li>
    his API is used for old data to new data migration for billing address field in client table.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_updateBillingAddress" >
    {{path}}explorer/#!/Client/Client_updateBillingAddress</a>
</li>
</ul>
<br />

<p><i><code>updateShippingAddress</code></i></p>
<ul>
<li>
    This API is used for old data to new data migration for shipping address field in client table.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/Client/Client_updateShippingAddress" >
    {{path}}explorer/#!/Client/Client_updateShippingAddress</a>
</li>
</ul>
</div>
</mat-card>
