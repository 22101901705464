<div class="locationList test_case_list_section">
  <div class="table-fields">
      <button class="custom-item-btn" mat-button (click)="downloadPDF(); " matTooltip="Download as PDF">
          <i class="fa fa-file-pdf-o"></i></button>
      <button class="custom-item-btn" mat-button (click)="downloadExcel() ; " matTooltip="Download as Excel">
          <i class="fa fa-file-excel-o"></i></button>
          <button name="filterBtn" class="custom-item-btn top-btn" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Filter"
          [ngClass]="filterApply ? 'filter-green' :''" (click)="taskfilter.open()" mat-button>
    <i class="fa fa-filter"></i>
  </button>
  <mat-form-field class="filters hidden-xs select-form visibility hidden-xs visibilityDisable fieldHeight" appearance="outline"
    style="max-width:100%;" >
    <mat-select placeholder="Apply filters" class="filter-select"  [(ngModel)]="modelGroup" multiple #taskfilter
      #itemSelect="ngModel">
      <mat-option class="filter-option modal-300 m-t-5" disabled="disabled">
        <div class="clearfix filter_btns w-100">
          <button name="applyBtn" mat-raised-button class="mat-primary fill text-sm apply-filter-btn"  (click)="[applyTaskFilter(itemSelect),taskfilter.close()]">Apply
          </button>
          <button name="clearBtn" mat-raised-button class="mat-warn fill text-sm reset-filter-btn" (click)="[resetFilter(itemSelect),taskfilter.close()]">
            Clear
          </button>
          <i class="fa fa-close pointer font-size-style" (keyup.escape)="[taskfilter.close(),resetFilter(itemSelect)]" (click)="[taskfilter.close()]"></i>
        </div>
      </mat-option>
      
    <div class="mainBoxSizeOne">       
     <div class="boxSize form-group form-default field_description">               
         <ng-select  [items]="moduleArr"  appearance="outline" [multiple]="true"   class="boxSides custom-select" SelectOnClose = false 
                    [(ngModel)]="userArray" (change)="getModuleDetails($event)"placeholder="Module Name" bindLabel="label">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox"  class="ngSelectTemplateSize" [ngModel]="item$.selected"/> {{item.label}}
                      </ng-template>
        </ng-select>           
     </div> 
     <div class="boxSize form-group form-default field_description"> 
      <ng-select  [items]="submodulearr"  appearance="outline" [multiple]="true" class="boxSides custom-select "  appearance="outline" 
                        [(ngModel)]="subModName" (change)="getSubModDetails($event)"placeholder="Sub module name">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                          <input id="item-{{index}}" type="checkbox" class="ngSelectTemplateSize" [ngModel]="item$.selected"/> {{item.label}}   
                   </ng-template>                       
      </ng-select>           
    </div>
     <div class="boxSize form-group form-default field_description"> 
      <ng-select  [items]="taskStatusArr" [multiple]="true" appearance="outline"  class="boxSides custom-select "  appearance="outline" 
                        [(ngModel)]="statusArray" (change)="getStatus($event)" placeholder="Status">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" class="ngSelectTemplateSize" [ngModel]="item$.selected"/> {{item.label}}   
                   </ng-template>                       
      </ng-select>           
    </div>
    <div class="boxSize form-group form-default field_description"> 
      <ng-select  [items]="testcaseTypeData" [multiple]="true" appearance="outline"  class="boxSides custom-select "  appearance="outline" 
                        [(ngModel)]="typeArray" (change)="getType($event)" placeholder="Type">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" class="ngSelectTemplateSize" [ngModel]="item$.selected"/> {{item.label}}   
                   </ng-template>                       
      </ng-select>           
    </div>
    <div class="boxSize form-group form-default field_description"> 
      <ng-select  [items]="project" [multiple]="true" appearance="outline"  class="boxSides custom-select "  appearance="outline" 
                        [(ngModel)]="projectArray" (change)="getProject($event)" placeholder="Project">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{index}}" type="checkbox" class="ngSelectTemplateSize" [ngModel]="item$.selected"/> {{item.label}}   
                   </ng-template>                       
      </ng-select>           
    </div>
    </div>    
    </mat-select>
  </mat-form-field>
      <div class="search">
          <input matInput (keyup)="search($event.target.value)" class="search-input" placeholder="Search">
        <mat-form-field class="visibility hidden-xs visibilityDisable" appearance="outline">
          <mat-select placeholder="Visibility" multiple
          [(ngModel)]="selected" #visibilityBox>
            <mat-option *ngFor="let column of visibilityColumns" class="modal-200" [value]="column">{{column}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  </div>
  <!-- <span *ngFor="dataSource.listResult"> -->
<table mat-table [dataSource]="dataSource" matSort class="testcase_list_table">
 
  <ng-container matColumnDef="testCase_Code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
    <td mat-cell *matCellDef="let data">
    <a  name="taskNameLink" class="pointer" (click)="viewDetailPage(data.testCaseId,viewType, $event)">{{data.testCaseCode}}</a>
    </td>
</ng-container>
  <ng-container matColumnDef="testCase_Title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let data">
      <span class="dispalyDotClass" >{{data.testCaseName | titlecase}}</span>
      </td>
  </ng-container>
<ng-container matColumnDef="Priority">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
  <td mat-cell *matCellDef="let data">

    <span *ngIf="data.testCasePriority === 'low'" style="color: #00b050">{{data.testCasePriority | titlecase}}</span>
    <span *ngIf="data.testCasePriority === 'medium'" style="color: #ff9900">{{data.testCasePriority | titlecase}}</span>
    <span *ngIf="data.testCasePriority === 'high'" style="color: #ff0000">{{data.testCasePriority | titlecase}}</span>
    <span *ngIf="data.testCasePriority === 'critical'" style="color: #9400d3">{{data.testCasePriority | titlecase}}</span>

  </td>
</ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>status</th>
    <td mat-cell *matCellDef="let data">

      <mat-menu #menu="matMenu">
        <span>
          <button mat-menu-item *ngFor="let ts of taskStatusArr" (click)="statusUpdate(data.testCaseId, ts.value)">{{ts.label}}</button>
        </span>
      </mat-menu>

      <div *ngIf="data.testCaseStatus === 'in_progress'">
        <a class="statusbtn" [matMenuTriggerFor]="menu" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="IN PROGRESS">
          <div disabled class="tag-btn progressButton"> <span class="status_string">IN PROGRESS</span>
          <span class="caret"></span></div> 
        </a>
      </div>
      <div *ngIf="data.testCaseStatus === 'completed'">
        <a class="statusbtn" [matMenuTriggerFor]="menu" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="COMPLETED">
          <div disabled class="tag-btn successButton"> <span class="status_string">COMPLETED</span>
          <span class="caret" ></span></div> 
        </a>
      </div>

      <div *ngIf="data.testCaseStatus === 'waiting_for_someone'">
        <a class="statusbtn" [matMenuTriggerFor]="menu" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="WAITING FOR SOMEONE">
          <div disabled class="tag-btn btn_warning_to"> <span class="status_string">WAITING FOR SOMEONE</span>
          <span class="caret"></span></div> 
        </a>
      </div>

      <div *ngIf="data.testCaseStatus === 'approved'">
        <a class="statusbtn" [matMenuTriggerFor]="menu" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="APPROVED">
          <div disabled class="tag-btn successButton"> <span class="status_string">APPROVED</span>
          <span class="caret" ></span></div>
        </a>
      </div>

    </td>
</ng-container>
<ng-container matColumnDef="Module Name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Module Name</th>
  <td mat-cell *matCellDef="let data">
      
      {{data.mainModuleName | titlecase}} 
    
  </td>
</ng-container>
<ng-container matColumnDef="Project">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Type</th>
  <td mat-cell *matCellDef="let data">
    
    <div *ngIf="data.projectType == 1">
      <span>Cloud app</span>
     </div>
     <div *ngIf="data.projectType == 2">
      <span>Android app</span>
     </div>
     <div *ngIf="data.projectType == 3">
      <span>iOS app</span>
     </div>
     <div *ngIf="data.projectType == 4">
      <span>Other</span>
     </div>
  </td>
</ng-container>
<ng-container matColumnDef="Responsible User">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Responsible User</th>
  <td mat-cell *matCellDef="let data">
    
          {{ data.responsibleUserName | titlecase }}
    
  </td>
</ng-container>
<ng-container matColumnDef="Participant">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Participant</th>
  <td mat-cell *matCellDef="let data">
    <span class="everyone_participants" *ngIf="data.permissionType === 2">
      Everyone<i class="fas fa-exclamation" matTooltip="Permission to all department Users"></i>
    </span>

    <ng-container *ngIf="data.permissionType === 1 || data.permissionType === 3">
        <!-- <div class="row userImg align-user" *ngFor="let user of data.participants | slice:0:5 ;let i = index">
          <span *ngIf="!(i > 4)">
            <img  src="{{user.avatar}}" alt="{{user.fullname}}" title="{{user.fullname}}"
              class="profile-pic mat-icon-button list-avatar-img" onError="this.src='/assets/img/default_avatar.jpg'">
            <span style="margin: 0 0 8px -10px;" class="circle circle-success circle-lg"></span>
          </span>
        </div> -->
        <div class="row userImg align-user" *ngFor="let userDetails of data.users  trackBy: trackByVisibleFn">
          <span *ngIf="userDetails.activated == 1 && !(i > 4)">
            <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
              class="profile-pic mat-icon-button list-avatar-img" onError="this.src='/assets/img/default_avatar.jpg'">
            <span style="margin: 0 0 8px -10px;" class="circle circle-success circle-lg"></span>
          </span>
          <span *ngIf="userDetails.activated == 0 && !(i > 4)">
            <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
              class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'">
            <span style="margin: 0 0 8px -10px;" class="circle circle-warning circle-lg"></span>
          </span>
        </div>
    </ng-container>

    <span class="p-l-10 text-info" *ngIf="data.participants?.length > 5"><i class="fas fa-ellipsis-h"></i></span>
    <a class="btn btn-action p-0 plus_icon" (click)="openParticipantsDialog(data)">
      <div class="ripple">
        <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" color="primary">add</mat-icon>
      </div>
    </a>
  </td>
</ng-container>
<ng-container matColumnDef="Action">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
  <td mat-cell *matCellDef="let data">
    
    <div class="actionIcon">
      <a class="btn btn-mini btn-action" matTooltip="View"  (click)="viewDetailPage(data.testCaseId,viewType,$event)">
        <div class="ripple-list">
          <i class="fas fa-list"></i>
        </div>
      </a>
      <a class="btn btn-sm btn-action" matTooltip="Edit">
        <div class="ripple">
          <i class="far fa-edit edit-icon" (click)="editTestCase(data.testCaseId)"></i>
        </div>
      </a>
      <a class="btn btn-sm btn-action" matTooltip="Delete">
        <div class="ripple">
          <i class="fai fa-trash-o text-danger fa-lg " aria-hidden="true" (click)="deleteTestCase(data.testCaseId)"></i>
        </div>
      </a>
      </div>
    
  </td>
</ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <!-- </span> -->
</table>
<mat-paginator #paginator [pageSizeOptions]="pageSizeArray" showFirstLastButtons (page)="onChange($event)"
[length]="tastCaseLength" [pageSize]="limit"></mat-paginator>
<div class="locaList-no-results" *ngIf="dataSource.data.length === 0" style="text-align: center">
  No Data Found!
</div>
</div>
