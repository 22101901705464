import { Component, inject, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskService } from '../../../../../service/task.service';
import { AlertCommonDialogData, EStatusCode } from '../../../../../service/Utils/Interfaces.class';
import { Router } from '@angular/router';
import { CommonDialogModelComponent } from '../../../../../admin/shared/common-dialog-model/common-dialog-model.component';
import { COMMON } from '../../../../../service/constant';
import { MailboxService } from '../../../../../admin/mailbox/mailbox-service/mailbox.service';
import { NgOption } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-testcase-detail',
    templateUrl: './testcase-detail.component.html',
    styleUrls: ['./testcase-detail.component.scss'],
    standalone: true,
    imports: [MatTooltip, NgIf, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, TitleCasePipe]
})
export class TestcaseDetailComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  @Input() testCaseId;
  testCaseData: any;
  testcasePriorityData = [];
  testcaseStatus = [];
  projectarr: Array<object> = [];
  project: NgOption = [];
  projectID:any;
  projectName:any;
  taskStatusArr = [];
  testCaseName :any;
  mainModuleName:any;
  subModulesName:any;
  testCasePriority:any;
  testCaseStatus:any;
  testCaseDescription:any;
  private readonly subscriptions: Subscription[] = [];
  private subscription: Subscription;

  constructor(public taskService: TaskService,public router: Router, public dialog: MatDialog,public mailboxService:MailboxService) { }
  ngOnInit() {
    this.testcasePriorityData = [
      { value: "low", label: "Low" },
      { value: "medium", label: "Medium" },
      { value: "high", label: "High" },
      { value: "critical", label: "Critical" },
    ];

    this.taskStatusArr = [
      {
        "value": "in_progress",
        "label": "In Progress",
      },
      {
        "value": "completed",
        "label": "Completed"
      },
      {
        "value": "waiting_for_someone",
        "label": "Waiting for Someone"
      },
      {
        "value": "approved",
        "label": "Approved"
      }
    ];
    this.project = [
      {
        value: "1",
        label: "Cloud app",
      },
      {
        value: "2",
        label: "Android app",
      },
      {
        value: "3",
        label: "iOS app",
      },
      {
        value: "4",
        label: "Other",
      },
    ];
    this.getTestCaseById();
  }

  getTestCaseById(){
    const testCaseDDLi : Subscription = this.taskService.getTestCasesById(this.testCaseId).subscribe((res: any) => {
      console.log(res);
      if (res.info.code === EStatusCode.OK) {
        this.testCaseData = res.data.listResult[0];
        this.projectID = this.testCaseData.projectType
        this.getProjName();
      }
    });

    this.subscriptions.push(testCaseDDLi);
  }

  statusUpdate(value){
    console.log(value);
    const updateStatustestCase : Subscription = this.taskService.updateTestCasesStatus(this.testCaseId , value).subscribe((res: any) => {
      if (res.info.code === 200) {
        this.toastr.success(res.data.responseMsg);
        this.ngOnInit();
      }else{
        this.toastr.error('Test case status not updated due to some error, please contact admin');
      }
    })
  this.subscriptions.push(updateStatustestCase);

  }
  editTestCase(id){
    var data = {
      isEditMode : true,
      editId : id
    }
    this.taskService.testcaseEditData= data;
    this.dialog.closeAll();
    this.router.navigate(['/admin/docs/test-and-qa/test-cases']);
  }
  deleteTestCase(id){
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to delete a record. This cannot be undone. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
    const DeleteList : Subscription = this.taskService.deleteTestCasesById(id).subscribe((result: any) => {
      if (result.result.info.code === 200) {
        this.toastr.success(result.result.data.responseMsg);
        this.dialog.closeAll();
        this.router.navigate(['/admin/docs/test-and-qa/test-cases']);
      }    
    });
    this.subscriptions.push(DeleteList);
  }
})
  }
  getProjName(){
   var temp =  this.project.find(({ value }) => value == this.projectID);
   this.projectName = temp.label
  }
}
