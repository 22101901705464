export interface LanguageInterface {
  id: string;
  value: string;
}

export class Language {
  static languages: LanguageInterface[] = [
    {id: 'en', value: 'English'},
    {id: 'de', value: 'Hindi'},
  ];
}
