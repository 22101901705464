
<div class="api-docs-back-btn">
        <section class="content-header">
          <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
                <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                  <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
              </a>    
          </button>
        </section>
  </div>
   
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Leave Category Model</h2>
<h4>Unique field - leave category</h4>
    <p><i><code>Add</code></i></p> 
    <ul>
        <li>
                This API is used to create a new leave category into `tbl_leave_category`. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Leavecategory/Leavecategory_add"><u>{{path}}explorer/#!/Leavecategory/Leavecategory_add</u></a> 

        </li>
    </ul> 
   <br />
    <p><i><code>Delete</code></i></p>
        <ul>
            <li>
                    This API will delete the leave category from `tbl_leave_category` if category does not exists in attendance. 

            </li>
            <li>
                    For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_delete"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_delete</u></a> 

            </li>
        </ul>  
        <br />
    <p><i><code>List</code></i></p>  
    <ul>
        <li>
                This API will fetch all the leave category list from `tbl_leave_category`. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Leavecategory/Leavecategory_list"><u>{{path}}explorer/#!/Leavecategory/Leavecategory_list</u></a> 

        </li>
    </ul>
 <br />  
    <p><i><code>listById</code></i></p>
        <ul>
            <li>
                    It will fetch the details of particular leave category from `tbl_leave_category` by leaveCategoryId. 

            </li>
            <li>
                    For reference: <a href="{{path}}explorer/#!/Leavecategory/Leavecategory_listById"><u>{{path}}explorer/#!/Leavecategory/Leavecategory_listById</u></a> 

            </li>
        </ul> 
 <br />  
    <p><i><code>Modify</code></i></p>  
        <ul>
            <li>
                    This API is used to modify the leave category name and leave quota into `tbl_leave_category` by leaveCategoryId. 

            </li>
            <li>
                    For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_modify"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_modify</u></a> 

            </li>
        </ul>
    </div>
</mat-card>
