import { Component, Injector, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { AppComponentBase } from '../../shared/AppComponentBase';
import { Common } from '../../../service/constant';
import { TaskResult, TaskService } from '../../../service/task.service';

@Component({
    selector: 'app-task-dialog-entry-component',
    template: ``,
    standalone: true
})
export class TaskDialogEntryComponentComponent extends AppComponentBase implements OnDestroy {

  currentDialog: MatDialogRef<TaskDetailComponent> = null;
  destroy = new Subject<void>();

  constructor(inject: Injector, public taskService:TaskService) {
    super(inject);
    this.activatedRoute.params.pipe(takeUntil(this.destroy)).subscribe(params => {
      if (this.currentDialog) {
        this.currentDialog.close();
      }
      this.openDialog(params);
    });
  }

  ngOnDestroy() {
    this.destroy.next();
  }

  openDialog(params): void {
    var detailType = this.taskService.getTaskDetailType();
    const dialogConfig = Common.matDialogConfig;
    dialogConfig.panelClass = [this.configService.userTheme, "animated",
      "slideInRight",
      "full-screen-modal"];
    dialogConfig.data = { taskId: parseInt(params.id), type: detailType };
    this.currentDialog = this.taskService.taskDetail.taskDialog = this.dialog.open(TaskDetailComponent, dialogConfig);
    this.currentDialog.afterClosed().subscribe((result: TaskResult) => {

      if (result) {
        if (result.msg == 'edit') {
          this.taskService.updateTasksEventListener.next(result.taskId);
          this.taskService.taskDetail.taskId = result.taskId;
          this.taskService.taskDetail.msg = result.msg;
          this.routerDialog.navigate(['admin/tasks']);
        } else if (result.msg == 'delete') {
          this.taskService.taskDetail.taskId = result.taskId;
          this.taskService.taskDetail.msg = result.msg;
          this.routerDialog.navigate(['admin/tasks']);
        } else if (result.msg == 'list') {
          this.taskService.taskDetail.taskId = result.taskId;
          this.taskService.taskDetail.msg = result.msg;
          this.routerDialog.navigate(['admin/tasks']);
        } else if (result.msg === 'dashboard') {
          this.taskService.taskDetail.taskId = result.taskId;
          this.taskService.taskDetail.msg = result.msg;
          this.routerDialog.navigate(['admin/dashboard']);
        } else if (result.msg === 'calendar') {
          this.routerDialog.navigateByUrl('/admin/calendar');
        }
      }
    });
  }
}
