import { Routes, } from '@angular/router';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { UiDocsComponent } from './ui-docs/ui-docs.component';
import { ApiDocsComponent } from './api-docs/api-docs.component';
import { PlatformDocsInfoComponent } from './platform-docs-info/platform-docs-info.component';
import { SettingUpEnvironmentComponent } from './setting-up-environment/setting-up-environment.component';
import { TechnologyStackComponent } from './technology-stack/technology-stack.component';
import { VersionComponent } from './release-notes/version/version.component';
import { DocsComponent } from './docs.component';
import { IntroductionComponent } from './api-docs/introduction/introduction.component';
import { HelperModelComponent } from './api-docs/helper-model/helper-model.component';
import { WriteCheckModelComponent } from './api-docs/write-check-model/write-check-model.component';
import { ConfigFilesComponent } from './api-docs/config-files/config-files.component';
import { ModelsComponent } from './api-docs/models/models.component';
import { AccessRulesModelComponent } from './api-docs/access-rules-model/access-rules-model.component';
import { AccountsModelComponent } from './api-docs/accounts-model/accounts-model.component';
import { AccountDetailsModelComponent } from './api-docs/account-details-model/account-details-model.component';
import { ActivitiesModelComponent } from './api-docs/activities-model/activities-model.component';
import { AgreementsModelComponent } from './api-docs/agreements-model/agreements-model.component';
import { AttachmentsModelComponent } from './api-docs/attachments-model/attachments-model.component';
import { AttendanceModelComponent } from './api-docs/attendance-model/attendance-model.component';
import { BillsModelComponent } from './api-docs/bills-model/bills-model.component';
import { BugsModelComponent } from './api-docs/bugs-model/bugs-model.component';
import { VisibilityModelComponent } from './api-docs/visibility-model/visibility-model.component';
import { VendorModelComponent } from './api-docs/vendor-model/vendor-model.component';
import { UsersModelComponent } from './api-docs/users-model/users-model.component';
import { TypesofcoaModelComponent } from './api-docs/typesofcoa-model/typesofcoa-model.component';
import { TransactionModelComponent } from './api-docs/transaction-model/transaction-model.component';
import { TimesheetModelComponent } from './api-docs/timesheet-model/timesheet-model.component';
import { TimebarModelComponent } from './api-docs/timebar-model/timebar-model.component';
import { StatusModelComponent } from './api-docs/status-model/status-model.component';
import { TicketsRepliesModelComponent } from './api-docs/tickets-replies-model/tickets-replies-model.component';
import { TicketsModelComponent } from './api-docs/tickets-model/tickets-model.component';
import { TicketCategoryModelComponent } from './api-docs/ticket-category-model/ticket-category-model.component';
import { TenantsModelComponent } from './api-docs/tenants-model/tenants-model.component';
import { TenantCompaniesModelComponent } from './api-docs/tenant-companies-model/tenant-companies-model.component';
import { TaxRatesModelComponent } from './api-docs/tax-rates-model/tax-rates-model.component';
import {
    TaskUploadedFilesModelComponent
} from './api-docs/task-uploaded-files-model/task-uploaded-files-model.component';
import {
    SubscribePaymentInfoModelComponent
} from './api-docs/subscribe-payment-info-model/subscribe-payment-info-model.component';
import { SavedItemsModelComponent } from './api-docs/savedItems-model/savedItems-model.component';
import { SalesTaxInvoiceModelComponent } from './api-docs/sales-tax-invoice-model/sales-tax-invoice-model.component';
import { RolesModelComponent } from './api-docs/roles-model/roles-model.component';
import { ResourcesModelComponent } from './api-docs/resources-model/resources-model.component';
import { RefundsModelComponent } from './api-docs/refunds-model/refunds-model.component';
import { PurchaseOrderModelComponent } from './api-docs/purchase-order-model/purchase-order-model.component';
import { ProposalsModelComponent } from './api-docs/proposals-model/proposals-model.component';
import { ProjectreasonModelComponent } from './api-docs/projectreason-model/projectreason-model.component';
import { ProjectpipelineModelComponent } from './api-docs/projectpipeline-model/projectpipeline-model.component';
import { ProjectcategoryModelComponent } from './api-docs/projectcategory-model/projectcategory-model.component';
import { ProjectModelComponent } from './api-docs/project-model/project-model.component';
import { PrioritiesModelComponent } from './api-docs/priorities-model/priorities-model.component';
import { PolicyModelComponent } from './api-docs/policy-model/policy-model.component';
import { PinactionModelComponent } from './api-docs/pinaction-model/pinaction-model.component';
import { PaymentsModelComponent } from './api-docs/payments-model/payments-model.component';
import { PaymentMethodModelComponent } from './api-docs/payment-method-model/payment-method-model.component';
import { PaymentInfoModelComponent } from './api-docs/payment-info-model/payment-info-model.component';
import {
    OppostatereasonparentModelComponent
} from './api-docs/oppostatereasonparent-model/oppostatereasonparent-model.component';
import { CurrentstateoppoModelComponent } from './api-docs/currentstateoppo-model/currentstateoppo-model.component';
import {
    OpportunitiesstatereasonModelComponent
} from './api-docs/opportunitiesstatereason-model/opportunitiesstatereason-model.component';
import { OpportunitysourceModelComponent } from './api-docs/opportunitysource-model/opportunitysource-model.component';
import { OpportunitypipelinesComponent } from './api-docs/opportunitypipelines/opportunitypipelines.component';
import { OpportunitiesModelComponent } from './api-docs/opportunities-model/opportunities-model.component';
import { NotesModelComponent } from './api-docs/notes-model/notes-model.component';
import { MilestonesModelComponent } from './api-docs/milestones-model/milestones-model.component';
import { MenuModelComponent } from './api-docs/menu-model/menu-model.component';
import { MeetingsModelComponent } from './api-docs/meetings-model/meetings-model.component';
import { MailboxModelComponent } from './api-docs/mailbox-model/mailbox-model.component';
import { LocaleModelComponent } from './api-docs/locale-model/locale-model.component';
import { LeaveCategoryModelComponent } from './api-docs/leave-category-model/leave-category-model.component';
import { LeadStatusModelComponent } from './api-docs/lead-status-model/lead-status-model.component';
import { LeadSourceModelComponent } from './api-docs/lead-source-model/lead-source-model.component';
import { LeadsModelComponent } from './api-docs/leads-model/leads-model.component';
import { LeadContactsModelComponent } from './api-docs/lead-contacts-model/lead-contacts-model.component';
import { LanguagesModelComponent } from './api-docs/languages-model/languages-model.component';
import { InvoiceModelComponent } from './api-docs/invoice-model/invoice-model.component';
import { GoaltypeModelComponent } from './api-docs/goaltype-model/goaltype-model.component';
import { GlobalModelComponent } from './api-docs/global-model/global-model.component';
import { EstimatesModelComponent } from './api-docs/estimates-model/estimates-model.component';
import { EmployeesModelComponent } from './api-docs/employees-model/employees-model.component';
import { EmailTemplatesModelComponent } from './api-docs/email-templates-model/email-templates-model.component';
import { DepartmentsModelComponent } from './api-docs/departments-model/departments-model.component';
import { DeadlineModelComponent } from './api-docs/deadline-model/deadline-model.component';
import { CurrencyModelComponent } from './api-docs/currency-model/currency-model.component';
import { CreditAmountModelComponent } from './api-docs/credit-amount-model/credit-amount-model.component';
import { ContactModelComponent } from './api-docs/contact-model/contact-model.component';
import { ConfigModelComponent } from './api-docs/config-model/config-model.component';
import {
    CommissionCalculationModelComponent
} from './api-docs/commission-calculation-model/commission-calculation-model.component';
import { CommentsModelComponent } from './api-docs/comments-model/comments-model.component';
import { ClientModelComponent } from './api-docs/client-model/client-model.component';
import { ChartOfAccountsModelComponent } from './api-docs/chart-of-accounts-model/chart-of-accounts-model.component';
import { CallsModelComponent } from './api-docs/calls-model/calls-model.component';
import { TaskModelComponent } from './api-docs/task-model/task-model.component';
import { TestCasesComponent } from './test-and-qa/test-cases/test-cases.component';
import {
    TestcaseDialogEntryComponentComponent
} from './test-and-qa/test-cases/testcase-dialog-entry-component/testcase-dialog-entry-component.component';

const docsRoutes: Routes = [
    {
        path: 'admin/docs',
        data: {
            title: 'Docs',
        },
        component: DocsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'platform-docs'
            },
            {
                path: 'api-docs',
                component: ApiDocsComponent,
            },
            {
                path: 'api-docs/intro',
                component: IntroductionComponent,
            },
            {
                path: 'api-docs/config-files',
                component: ConfigFilesComponent,
            },
            {
                path: 'api-docs/models',
                component: ModelsComponent,
            },
            {
                path: 'api-docs/access-rules-models',
                component: AccessRulesModelComponent,
            },
            {
                path: 'api-docs/account-details-model',
                component: AccountDetailsModelComponent,
            },
            {
                path: 'api-docs/accounts-model',
                component: AccountsModelComponent
            },
            {
                path: 'api-docs/activities-model',
                component: ActivitiesModelComponent
            },
            {
                path: 'api-docs/agreements-model',
                component: AgreementsModelComponent
            },
            {
                path: 'api-docs/attachments-model',
                component: AttachmentsModelComponent
            },
            {
                path: 'api-docs/attendance-model',
                component: AttendanceModelComponent
            },
            {
                path: 'api-docs/bills-model',
                component: BillsModelComponent
            },
            {
                path: 'api-docs/bugs-model',
                component: BugsModelComponent
            },
            {
                path: 'api-docs/calls-model',
                component: CallsModelComponent
            },
            {
                path: 'api-docs/chart-of-accounts-model',
                component: ChartOfAccountsModelComponent
            },
            {
                path: 'api-docs/client-model',
                component: ClientModelComponent
            },
            {
                path: 'api-docs/comments-model',
                component: CommentsModelComponent
            },
            {
                path: 'api-docs/commission-calculation-model',
                component: CommissionCalculationModelComponent
            },
            {
                path: 'api-docs/config-model',
                component: ConfigModelComponent
            },
            {
                path: 'api-docs/contact-model',
                component: ContactModelComponent
            },
            {
                path: 'api-docs/credit-amount-model',
                component: CreditAmountModelComponent
            },
            {
                path: 'api-docs/currency-model',
                component: CurrencyModelComponent
            },
            {
                path: 'api-docs/currencystate-model',
                component: CurrentstateoppoModelComponent
            },
            {
                path: 'api-docs/deadline-model',
                component: DeadlineModelComponent
            },
            {
                path: 'api-docs/departments-model',
                component: DepartmentsModelComponent
            },
            {
                path: 'api-docs/email-templates-model',
                component: EmailTemplatesModelComponent
            },
            {
                path: 'api-docs/employees-model',
                component: EmployeesModelComponent
            },
            {
                path: 'api-docs/estimates-model',
                component: EstimatesModelComponent
            },
            {
                path: 'api-docs/global-model',
                component: GlobalModelComponent
            },
            {
                path: 'api-docs/goaltype-model',
                component: GoaltypeModelComponent
            },
            {
                path: 'api-docs/invoice-model',
                component: InvoiceModelComponent
            },
            {
                path: 'api-docs/languages-model',
                component: LanguagesModelComponent
            },
            {
                path: 'api-docs/lead-contacts-model',
                component: LeadContactsModelComponent
            },
            {
                path: 'api-docs/leads-model',
                component: LeadsModelComponent
            },
            {
                path: 'api-docs/lead-source-model',
                component: LeadSourceModelComponent
            },
            {
                path: 'api-docs/lead-status-model',
                component: LeadStatusModelComponent
            },
            {
                path: 'api-docs/leave-category-model',
                component: LeaveCategoryModelComponent
            },
            {
                path: 'api-docs/locale-model',
                component: LocaleModelComponent
            },
            {
                path: 'api-docs/mailbox-model',
                component: MailboxModelComponent
            },
            {
                path: 'api-docs/meetings-model',
                component: MeetingsModelComponent
            },
            {
                path: 'api-docs/menu-model',
                component: MenuModelComponent
            },
            {
                path: 'api-docs/milestones-model',
                component: MilestonesModelComponent
            },
            {
                path: 'api-docs/notes-model',
                component: NotesModelComponent
            },
            {
                path: 'api-docs/opportunities-model',
                component: OpportunitiesModelComponent
            },
            {
                path: 'api-docs/opportunitypipelines-model',
                component: OpportunitypipelinesComponent
            },
            {
                path: 'api-docs/opportunitysource-model',
                component: OpportunitysourceModelComponent
            },
            {
                path: 'api-docs/opportunitiesstatereason-model',
                component: OpportunitiesstatereasonModelComponent
            },
            {
                path: 'api-docs/currentstateoppo-model',
                component: CurrentstateoppoModelComponent
            },
            {
                path: 'api-docs/oppostatereasonparent-model',
                component: OppostatereasonparentModelComponent
            },
            {
                path: 'api-docs/payment-info-model',
                component: PaymentInfoModelComponent
            },
            {
                path: 'api-docs/payment-method-model',
                component: PaymentMethodModelComponent
            },
            {
                path: 'api-docs/payments-model',
                component: PaymentsModelComponent
            },
            {
                path: 'api-docs/pinaction-model',
                component: PinactionModelComponent
            },
            {
                path: 'api-docs/policy-model',
                component: PolicyModelComponent
            },
            {
                path: 'api-docs/priorities-model',
                component: PrioritiesModelComponent
            },
            {
                path: 'api-docs/project-model',
                component: ProjectModelComponent
            },
            {
                path: 'api-docs/project-category-model',
                component: ProjectcategoryModelComponent
            },
            {
                path: 'api-docs/project-pipeline-model',
                component: ProjectpipelineModelComponent
            },
            {
                path: 'api-docs/project-reason-model',
                component: ProjectreasonModelComponent
            },
            {
                path: 'api-docs/proposals-model',
                component: ProposalsModelComponent
            },
            {
                path: 'api-docs/purchase-order-model',
                component: PurchaseOrderModelComponent
            },
            {
                path: 'api-docs/refunds-model',
                component: RefundsModelComponent
            },
            {
                path: 'api-docs/resources-model',
                component: ResourcesModelComponent
            },
            {
                path: 'api-docs/roles-model',
                component: RolesModelComponent
            },
            {
                path: 'api-docs/sales-tax-invoice-model',
                component: SalesTaxInvoiceModelComponent
            },
            {
                path: 'api-docs/saved-items-model',
                component: SavedItemsModelComponent
            },
            {
                path: 'api-docs/subscribe-payment-info-model',
                component: SubscribePaymentInfoModelComponent
            },
            {
                path: 'api-docs/task-model',
                component: TaskModelComponent
            },
            {
                path: 'api-docs/task-uploaded-files-model',
                component: TaskUploadedFilesModelComponent
            },
            {
                path: 'api-docs/tax-rates-model',
                component: TaxRatesModelComponent
            },
            {
                path: 'api-docs/tenant-companies-model',
                component: TenantCompaniesModelComponent
            },
            {
                path: 'api-docs/tenants-model',
                component: TenantsModelComponent
            },
            {
                path: 'api-docs/tickets-category-model',
                component: TicketCategoryModelComponent
            },
            {
                path: 'api-docs/tickets-model',
                component: TicketsModelComponent
            },
            {
                path: 'api-docs/tickets-replies-model',
                component: TicketsRepliesModelComponent
            },
            {
                path: 'api-docs/status-model',
                component: StatusModelComponent
            },
            {
                path: 'api-docs/timbar-model',
                component: TimebarModelComponent
            },
            {
                path: 'api-docs/timesheet-model',
                component: TimesheetModelComponent
            },
            {
                path: 'api-docs/transaction-model',
                component: TransactionModelComponent
            },
            {
                path: 'api-docs/types-of-coa-model',
                component: TypesofcoaModelComponent
            },
            {
                path: 'api-docs/users-model',
                component: UsersModelComponent
            },
            {
                path: 'api-docs/vendor-model',
                component: VendorModelComponent
            },
            {
                path: 'api-docs/visibility-model',
                component: VisibilityModelComponent
            },
            {
                path: 'api-docs/write-check-model',
                component: WriteCheckModelComponent
            },
            {
                path: 'api-docs/helper-model',
                component: HelperModelComponent
            },
            {
                path: 'platform-docs',
                component: PlatformDocsInfoComponent,
            },
            {
                path: 'release-notes',
                component: ReleaseNotesComponent,
            },
            {
                path: 'release-notes/version/:id',
                component: VersionComponent
            },
            {
                path: 'setting-up-environment',
                component: SettingUpEnvironmentComponent,
                pathMatch: 'full',
            },
            {
                path: 'technology-stack',
                component: TechnologyStackComponent,
                pathMatch: 'full',
            },
            {
                path: 'test-and-qa/test-cases/details/:id',
                component: TestcaseDialogEntryComponentComponent
            },
            {
                path: 'test-and-qa/test-cases',
                component: TestCasesComponent
            },
            {
                path: 'ui-docs',
                component: UiDocsComponent,
            }
        ]
    }
];

export default docsRoutes;
