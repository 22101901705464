<div class="add_remove_module">
<h2 mat-dialog-title>Check apps that you want to subscribe</h2>

<mat-dialog-content>
  <div class="row">
<div class="col-sm-4" *ngFor="let mainMenu of request.moduleList">
  <div class="checkbox-fade fade-in-primary">
<mat-checkbox class="text-black-checkbox" name="selectModules" value="{{mainMenu.menuId}}" type="checkbox" [checked]="mainMenu.isSubscribed"
  [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)" [ngClass]="mainMenu.isPaid ? 'Green' : 'default'">
      {{mainMenu.label | uppercase}}
    </mat-checkbox>
  </div>
</div>
</div>

<div class="col-sm-12" style="color: green;" *ngIf="paidFlag">* Apps highlighted in green are already paid.</div>

<div class="col-sm-12" *ngIf="flag">You've to pay {{currency}}{{costToDisplay}} to add selected apps.</div>
</mat-dialog-content>

<mat-dialog-actions class="btn-row-group d-flex justify-content-between">
  <button mat-raised-button mat-dialog-close class="btn-cancel">Cancel</button>
  <button name="addModulesBtn" mat-raised-button [disabled]="addModulesFlag" (click)="addModules()" 
  class="btn-submit form-button-40 tw-mr-2"> Add Apps </button>
</mat-dialog-actions>
</div>