<section class="content-header">
    <div class="row">
      <div class="col-md-6">
        <div class="header">
        <h1 class="headerName"><mat-icon>notes</mat-icon>Platform Docs </h1>
      </div>
      </div>
    </div>
</section>

<mat-card>
<div class="platform-docs">

   <h2 class="content-header-name">Angular </h2>

<ul>
  <li>
     <h4>
    Introduction
  </h4>
  </li>
  <p>Utiliko project is developed on Angular 6 version. Angular 6 is a
    JavaScript framework for building web applications and apps in JavaScript, html, and
    TypeScript, which is a superset of JavaScript. Angular provides built-in features for animation,
    http service, and materials which in turn has features such as auto-complete, navigation, toolbar, menus, etc</p>
<br/>

<ul>
  <li> Folder Structure </li>
</ul>

<span id="folder" >
  |-- app
       <br>|-- [+] admin
       <br>|-- [+] client
       <br>|-- [+] core
       <br>|-- [+] docs
       <br>|-- [+] service
       <br>|-- [+] themes
       <br>|-- app-request-handler.service.ts
       <br>|-- app.authGuard.service.ts
       <br>|-- app.component.css
       <br>|-- app.component.html
       <br>|-- app.component.ts
       <br>|-- app.module.ts
       <br>|-- app.routing.ts
       <br>|-- feather-pipe.ts
       <br>|-- material-module.ts
       <br>|-- [+] assets
       <br>|-- [+] environments
       <br>|-- browserslist
       <br>|-- favicon.ico
       <br>|-- index.html
       <br>|-- karma.conf.js
       <br>|-- main.ts
       <br>|-- polyfills.ts
       <br>|-- styles.css
       <br>|-- test.ts
       <br>|-- tsconfig.app.json
       <br>|-- tsconfig.spec.json
       <br>|-- tslint.json
       <br>|-- typings.d.ts
<br>|-- .editorconfig
<br>|-- .gitignore
<br>|-- .gitlab-ci.yml
<br>|-- a.out
<br>|-- angular.json
<br>|-- changelog.md
<br>|-- karma.conf.js
<br>|-- LICENSE
<br>|-- package-lock.json
<br>|-- protractor.conf.js
<br>|-- README.md
<br>|-- tsconfig.json
<br>|-- tsling.json
</span>

</ul>

<ul>
  <li>
     <h4>
    Components
  </h4>
  </li>
  <p>
      Major part of the project development with Angular 6 is done in the components.
      Components are basically classes that interact with the .html file of the component,
      which gets displayed on the browser.
      Each component defines a class that contains application data and logic,
      and is associated with an HTML template that defines a view to be displayed in a target environment.
  </p>
  <li>
    <h4>
    Modules
  </h4>
  </li>
  <p>
      Modules in Angular refers to a place where you can group the components,
      directives, pipes, and services, which are related to the Utiliko project.
      The code is organized into distinct functional modules helps in managing development of
      complex processess, and in designing for reusability.
      In addition, this technique lets you take advantage of lazy-loading—that is,
      loading modules on demand—to minimize the amount of code that needs to be loaded at startup.
  </p>
  <li>
     <h4>
    Routing
  </h4>
  </li>
  <p>
    Routing basically means navigating between pages.
    The router maps "URL-like" paths to views instead of pages.
    i.e. When a user performs an action, such as clicking a link, that would load a new page in the browser,
    the router intercepts the browser's behavior, and shows or hides view hierarchies of the page.
  </p>

  <li>
       <h4>
        Overview and Command Reference
      </h4>
  </li>
  <p>
      The Angular CLI is a command-line interface tool that you use to initialize, develop, scaffold, and maintain
      Angular applications. You can use the tool directly in a command shell, or indirectly through an
      interactive UI such as Angular Console.
  </p>

  <li>
     <h4>
      Security
    </h4>
  </li>
  <p>
      Routes enable the user to navigate through the application.
      Sometimes the user needs to do something before being allowed access to a certain part of the application—for example,
      log in. Route guards can be used to control access to certain routes. <br/>
        There are two types of route guard:
        <br/>
            CanActivate: Can the user navigate to a route? In this class, you can inject the router.
            This is useful to navigate the user a way to another resource if the user isn’t allowed to navigate to a route.
            CanDeactivate: Can the user move away from a route? Useful for prompting to save changes.
        <br/>
    </p>

  <li>
    <h4>
     Cross-site script inclusion (XSSI)
    </h4>
  </li>
  <p>
      Cross-site script inclusion, also known as JSON vulnerability, can allow an attacker's website to
      read data from a JSON API. The attack works on older browsers by overriding native JavaScript
      object constructors, and then including an API URL using a <code>&lt;script&gt;</code> tag.<br/>
      This attack is only successful if the returned JSON is executable as JavaScript.
      Servers can prevent an attack by prefixing all JSON responses to make them non-executable, by convention,
      using the well-known string.<br/>
      Angular's HttpClient library recognizes this convention and automatically strips the string
      from all responses before further parsing.
  </p>


  <li>
     <h4>
      Testing with Protractor
    </h4>
  </li>
  <p>
      Protractor is a node.js program or we can say it is an end-to-end test framework for Angular and
      AngularJS applications. Protractor runs tests against your application running in a real browser,
      interacting with it as a user would.Protractor is built on top of WebDriverJS, which uses native events and
      browser-specific drivers to interact with your application as a user. Protractor is also compatible for
      Non-Angular apps also. It’s work is mostly similar to Selenium.
  </p>

    <h5>Important points about Protractor :-</h5>

      <ul>
        <li>
          Protractor is mainly designed for Angular and Angular JS Applications. But it also supports non angular apps.
        </li>
        <li>
          Protractor basically works on jasmine testing framework. Like &lsquo; Karma &rsquo;(angular&rsquo;s default testing tool) it is designed to run tests against apps on jasmine framework.
        </li>
        <li>
          Both full and Limited Scope testing is possible through Protractor.
        </li>
        <li>
          Protractor always executes test cases in sequential manner. So, when running all test cases in single project we should be aware of the flow of project.
        </li>
        <li>
          Protractor supports Angular-specific locator strategies, which allows you to test Angular-specific elements without any setup effort on your part.
        </li>
        <li>
          Protractor is automated testing tool, that&rsquo;s why it executes the test cases one by one automatically without any disturbance. Even if some test cases fails, it doesn&rsquo;t stop execution of other test cases.
        </li>
      </ul>
  </ul>
   <h2 class="content-header-name"> LoopBack   </h2>
  <ul>
    <p>
        It is an open source Node.js API framework from StrongLoop.
        It is built on top of Express optimized for mobile, web, and other devices.
    </p>
    <p>LoopBack is a highly-extensible, open-source Node.js framework that enables you to:</p>
    <li>
        Create dynamic end-to-end REST APIs with little or no coding.
    </li>
    <li>
        Access data from major relational databases, MongoDB, SOAP, and REST APIs.
    </li>
    <li>
        Incorporate model relationships and access controls for complex APIs.
    </li>
    <li>
        Use separable components for file storage, third-party login, and OAuth 2.0.
    </li>
    <li>Very quick RESTful API development.
      </li>
      <li>Convention over configuration.
      </li>
      <li>Built-in models ready to use.
      </li>
      <li> RPC support.</li>
      <li> Fully configurable when needed. </li>
      <li>
          Extensive documentation.
      </li>
      <li>
          Full-time team working on the project.
      </li>
      <li>
          Online support support.
      </li>
  </ul>

<ul>
  <li><h4>Models</h4></li>
  <p> LoopBack models are JavaScript objects with both Node and REST APIs.<br/>
      A key powerful feature of LoopBack is that when you define a model it
      automatically comes with a predefined REST API with a full set of create, read, update,
      and delete operations
  </p>
  <li><h4> Routing  </h4></li>
  <p>
      Routing refers to determining how an application responds to a client request to a
      particular endpoint, which is a URI (or path) and a specific HTTP request method (GET, POST, and so on).
  </p>
  <li>
    <h4>
    Middleware
  </h4>
  </li>
  <p>
      Middleware functions are functions that have access to the request object (req), the response object (res),
      and the next middleware function in the application’s request-response cycle. The next middleware function is
      commonly denoted by a variable named next.</p>
      <ul>
          <li>
              Execute any code.
          </li>
          <li>
              Make changes to the request and the response objects.
          </li>
          <li>
              End the request-response cycle.
          </li>
          <li>
              Call the next middleware function in the stack.
          </li>
        </ul>
  <li>
    <h4>
    Remote Method
  </h4>
  </li>
  <p>
      A remote method is a method of a model, exposed over a custom REST endpoint.
      Use a remote method to perform operations not provided by LoopBack’s standard model REST API.
  </p>
</ul>

 <h2 class="content-header-name">
  Angular Material

 </h2>

<ul>
  <li>
      Angular Material is a collection of Material Design components for Angular.
  </li>
</ul>

      <div class="image-class">
      <img src="../../../assets/docs/platform_info_mat_dashboard.png" class="images"> <br/>
    </div>

<br/>
<ul>
  <li>
      Angular Material provides two sets of components designed to add collapsible side content (often navigation,
      though it can be any content) alongside some primary content. These are the sidenav and drawer components.
  </li>
</ul>


<br/>

<div class="image-class">
  <img src="../../../assets/docs/platform_info_mat_task.png" class="images">
</div>

<br/>
<br/>
<ul>
  <li>
      The <code>mat-table</code> provides a Material Design styled data-table that can be used to display rows of data.
  </li>
</ul>

 <h2 class="content-header-name"> Node  </h2>

<ul>
  <p>
    Node.js is Asynchronous event driven JavaScript runtime environment(Server-side platform).
  </p>
</ul>

<ul>
  <li>
    <h4>
    Asynchronous and Event Driven
  </h4>
  </li>
  <p>
    All APIs of Node.js library are asynchronous, that is, non-blocking. It essentially means a
    Node.js based server never waits for an API to return data. The server moves to the next API after calling it and
    a notification mechanism of Events of Node.js helps the server to get a response from the previous API call.
  </p>
</ul>


<ul>
  <li>
    <h4>
    Single Threaded but Highly Scalable
  </h4>
  </li>
  <p>
    Node.js uses a single threaded model with event looping. Event mechanism helps the server to respond in a
    non-blocking way and makes the server highly scalable as opposed to traditional servers which create limited threads
    to handle requests. Node.js uses a single threaded program and the same program can provide service to a much larger
    number of requests than traditional servers like Apache HTTP Server.
  </p>
</ul>

 <h2 class="content-header-name"> MySQL  </h2>

<ul>
  <li>
    MySQL is a fast, easy to use relational database. It is currently the most popular open-source database.
    It is very commonly used in conjunction with PHP scripts to create powerful and dynamic server-side applications.
  </li>
  <p>
    MySQL is based on a client-server model.
    The core of MySQL is MySQL server, which handles all of the database instructions (or commands).
  </p>
  <ul class="points">
    <li><strong>Relational Database Management System (RDBMS):</strong> MySQL is a relational database management system.</li>
    <li><strong>Easy to use:</strong> MySQL is easy to use. You have to get only the basic knowledge of SQL. You can build and interact with MySQL with only a few simple SQL statements.</li>
    <li><strong>It is secure:</strong> MySQL consist of a solid data security layer that protects sensitive data from intruders. Passwords are encrypted in MySQL.</li>
    <li><strong>Client/ Server Architecture:</strong> MySQL follows a client /server architecture. There is a database server (MySQL) and arbitrarily many clients (application programs), which communicate with the server; that is, they query data, save changes, etc.</li>
    <li><strong>Free to download:</strong> MySQL is free to use and you can download it from MySQL official website.</li>
    <li><strong>It is scalable:</strong> MySQL can handle almost any amount of data, up to as much as 50 million rows or more. The default file size limit is about 4 GB. However, you can increase this number to a theoretical limit of 8 TB of data.</li>
    <li><strong>Compatibale on many operating systems:</strong> MySQL is compatible to run on many operating systems, like Novell NetWare, Windows* Linux*, many varieties of UNIX* (such as Sun* Solaris*, AIX, and DEC* UNIX), OS/2, FreeBSD*, and others. MySQL also provides a facility that the clients can run on the same computer as the server or on another computer (communication via a local network or the Internet).</li>
    <li><strong>Allows roll-back:</strong> MySQL allows transactions to be rolled back, commit and crash recovery.</li>
    <li><strong>High Performance:</strong> MySQL is faster, more reliable and cheaper because of its unique storage engine architecture.</li>
    <li><strong>High Flexibility:</strong> MySQL supports a large number of embedded applications which makes MySQL very flexible.</li>
    <li><strong>High Productivity:</strong> MySQL uses Triggers, Stored procedures and views which allows the developer to give a higher productivity. </li>
    </ul>
</ul>


</div>

</mat-card>



