
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
<div class="api-docs">
<h2 class="content-header-name">Tickets Model</h2>
 <h4>Commonly used methods in tickets</h4> 
 <ul>
     <li>
            getTicketsInfoById :
            <ul>
                <li>
                        returns ticketInfo from `tbl_tickets`. 

                </li>
            </ul>
     </li>
     <li>
            searchTicketsByAutomationFilter :(returns automationData if exists) 
            <ul>
                <li>
                        It will fetch the automation list from `tbl_automation` by tenantCompanyKey. 

                </li>
                <li>
                        It will check whether the ticket is exists in automation list or not by using matchTicketsInautomation method. 

                </li>
                <li>
                        Before modifying ticket details in any API, this method will be called. 

                </li>
                <li>
                        If automationData exists, applyAutomationActionInTickets method will be called else other iterations will be executed. 

                </li>
            </ul>
     </li>
     <li>
            applyAutomationActionInTickets : on Hold
     </li>
 </ul>
 <br />    
    
     <p><i><code> Add</code></i></p> 
     <ul>
         <li>
                This API is used to create a new ticket into `tbl_tickets`. 
         </li>
         <li>
                Before creating ticket, the below steps will be followed: 
                <ul>
                        <li>
                                checKGroups method will be called to get the permissions. 

                        </li>
                        <li>
                                searchTicketsByAutomationFilter method to check automation is exists or not for that ticket(returns automationData). 

                        </li>
                        <li>
                                ticketsMailConfigSetting method will fetch the email config settings for the ticket. 

                        </li>
                        <li>
                                If automationData found, applyAutomationActionInTickets method will be called. Else new ticket will be created into . 

                        </li>
                        <li>
                                Finally, <strong>sendMailToClientAndStaff</strong> method will be called to send the ticket to participants through email. 

                        </li>
                    </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_add"><u>{{path}}explorer/#!/Tickets/Tickets_add</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> addTicketReply</code></i></p> 
     <ul>
         <li>
                This API is used to create new ticket reply(comment) into `tbl_tickets_replies`. 

         </li>
         <li>
                Once comment is saved in database, email will be sent to the respective users by using ticketsMailConfigSetting method. 

         </li>
         <li>
                If dontSendMailToClient = 1: <strong>sendMailToClientAndStaff</strong> and sendTicketsInfoByEmail method will be called. 

         </li>
         <li>
                If status = &lsquo;resolved(<strong>6</strong>)&rsquo;: sendNotifyTicketReplyForClosedStatus method is used. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_addTicketReply"><u>{{path}}explorer/#!/Tickets/Tickets_addTicketReply</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> changeBillstatus</code></i></p> 
     <ul>
         <li>
                This API is used to change bill status into `tbl_tickets` by <strong>ticket id</strong> and <strong>bill status (1 or 2 [1:Billable , 2:Already billed])</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_changeBillStatus"><u>{{path}}explorer/#!/Tickets/Tickets_changeBillStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> changeTicketCategory</code></i></p> 
     <ul>
         <li>
                This API is used to change the ticket category of the particular ticket by <strong>ticket id</strong> and <strong>ticket category id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_changeTicketCategoryById"><u>{{path}}explorer/#!/Tickets/Tickets_changeTicketCategoryById</u></a> 

         </li>
     </ul>
      <br />
     <p><i><code> changeTicketPriorities</code></i></p> 
     <ul>
         <li>
                This API is used to change the priority of the ticket into `tbl_tickets` by <strong>ticketId</strong> and <strong>ticket priorities id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_changeTicketPrioritiesById"><u>{{path}}explorer/#!/Tickets/Tickets_changeTicketPrioritiesById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> changeTicketStatus</code></i></p> 
     <ul>
         <li>
                This API is used to change the status of the ticket by <strong>ticket id</strong> and <strong>ticketStatusId</strong>. 

         </li>
         <li>
                If <strong>ticketStatusId</strong>=9(<strong>unassigned</strong>): checkGroups method will be called to get the permission of the ticket. 

         </li>
         <li>
                If <strong>ticketStatusId</strong>=6(<strong>resolved</strong>): getTimerClockStatus method will be called to check the timer status(on/off). If status is on, timer will be stopped. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_changeTicketStatusById"><u>{{path}}explorer/#!/Tickets/Tickets_changeTicketStatusById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> clientLocationsList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the sub client list of the parent client by <strong>client Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_clientLocationsList"><u>{{path}}explorer/#!/Tickets/Tickets_clientLocationsList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the particular ticket from `tbl_tickets` by <strong>ticket id</strong>. 

         </li>
         <li>
                Before deleting the ticket, it will delete the data related to the ticket such as pinaction, timesheet, timer, ticketReplies etc., 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_delete"><u>{{path}}explorer/#!/Tickets/Tickets_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> detailCount</code></i></p> 
     <ul>
         <li>
                This API will fetch the count of records of sub module of the ticket by using `ticket_detail_count` procedure. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_detailCount"><u>{{path}}explorer/#!/Tickets/Tickets_detailCount</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> dropdownListByClientId</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the tickets list by clientId from `tbl_tickets`. 

         </li>
         <li>
                This API is used to fetch the tickets list by clientId from `tbl_tickets`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_dropdownListByClientID"><u>{{path}}explorer/#!/Tickets/Tickets_dropdownListByClientID</u></a> 

         </li>
     </ul>
      <br />
     <p><i><code> generateTicketCode</code></i></p> 
     <ul>
         <li>
                This API is used to generate the ticketCode if doesn&rsquo;t exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_generateTicketCode"><u>{{path}}explorer/#!/Tickets/Tickets_generateTicketCode</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> getTickCountForGraph</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the summary report of the tickets upto one month with intervals. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_getTickCountForGraph"><u>{{path}}explorer/#!/Tickets/Tickets_getTickCountForGraph</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> getTicketsListByStatus</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of tickets by status for kanban view. 

         </li>
     </ul>
 <br />  
     <p><i><code> getTimerClockStatus</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the timer status of ticket when timer is in on by <strong>ticket id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_getTimerClockStatus"><u>{{path}}explorer/#!/Tickets/Tickets_getTimerClockStatus</u></a> 

         </li>
     </ul>
     <br />
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of tickets from `tbl_tickets` based on the permissions. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_list"><u>{{path}}explorer/#!/Tickets/Tickets_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> listById</code></i></p> 
     <ul>
         <li>
                This API is used to get the details of particular ticket including participants, timer, pinaction etc., by <strong>ticket id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_listById"><u>{{path}}explorer/#!/Tickets/Tickets_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> lockTicketStatus</code></i></p> 
     <ul>
         <li>
                This API is used to change the lock status of the ticket by <strong>ticketId</strong> and <strong>lock status as 1 or 0 </strong>(<strong>[1:LOCK , 0:UNLOCK]</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_lockTicketStatusById"><u>{{path}}explorer/#!/Tickets/Tickets_lockTicketStatusById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Modify</code></i></p>
     <ul>
         <li>
                This API is used to modify the details of ticket by <strong>ticketId</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_modify"><u>{{path}}explorer/#!/Tickets/Tickets_modify</u></a> 

         </li>
     </ul>
     <br /> 
     <p><i><code> openTicketsCountDashboard</code></i></p> 
     <ul>
        <li>
                This API is used to get the count of open tickets(<strong>`tbl_status`.`status` != </strong><strong>'</strong><strong>follow_up</strong><strong>'</strong> <strong>AND `tbl_status`.`status` != </strong><strong>'</strong><strong>resolved</strong><strong>'</strong>) from `tbl_tickets` for dashboard. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_openTicketsCountDashboard"><u>{{path}}explorer/#!/Tickets/Tickets_openTicketsCountDashboard</u></a> 

        </li>
     </ul>
 <br />  
     <p><i><code> ticketsInfoInCountDays</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the ticket information in count(inactiveDays, openDays, repliesCount, lastReply) with participants. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_ticketsInfoInCountDays"><u>{{path}}explorer/#!/Tickets/Tickets_ticketsInfoInCountDays</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> ticketsTimeWorked</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the total time worked for the ticket in totalTimeSpentSum and totalTimeSpentInHrsMinSec format. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_ticketsTimeWorked"><u>{{path}}explorer/#!/Tickets/Tickets_ticketsTimeWorked</u></a> 

         </li>
     </ul>
 <br /> 
     <p><i><code> totalTimeByTicketsId</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the total time of the ticket in hours, minutes, seconds format by using ticketId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tickets/Tickets_totalTimeByTicketsId"><u>{{path}}explorer/#!/Tickets/Tickets_totalTimeByTicketsId</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
