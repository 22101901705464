
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Opportunity State Reaseon Parent Model</h2>
 <p><i><code>List</code></i></p>
 <ul>
     <li>
            This API will fetch the opportunity parent status reason list from `tbl_oppo_state_reason_parent`. 

     </li>
     <li>   
            For reference: <a href="{{path}}explorer/#!/OpportunityStateReasonParent/OpportunityStateReasonParent_list"><u>{{path}}explorer/#!/OpportunityStateReasonParent/OpportunityStateReasonParent_list</u></a> 

     </li>
 </ul> 
 </div>
</mat-card>
