import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-bugs-model',
    templateUrl: './bugs-model.component.html',
    styleUrls: ['./bugs-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class BugsModelComponent implements OnInit {

  constructor() { }
  public currentUrl:any;
  ngOnInit() {
    this.currentUrl = environment.BASE_URL;
  }

}
