
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Tenants Model</h2>
 <p><i><code> Activate</code></i></p> 
 <ul>
     <li>
            This API is used to activate the user by <strong>user id</strong> and <strong>activation key</strong> of that user. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Tenants/Tenants_activate"><u>{{path}}explorer/#!/Tenants/Tenants_activate</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the tenants list from `tbl_tenants` and `tbl_users`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tenants/Tenants_list"><u>{{path}}explorer/#!/Tenants/Tenants_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Signup</code></i></p> 
     <ul>
         <li>
                This API is used to create the new user/tenant by doing signup. 

         </li>
         <li>
                Unique fields - tenant email address. 

         </li>
         <li>
                While doing signup tenant and company details will be saved in the following tables: `tbl_tenants`, `tbl_tenant_companies`, `tbl_config`, `tbl_activities` and `tbl_users`. 

         </li>
         <li>
                After creation of tenant, an activation email will be sent to that tenant by using sendMail method. 

         </li>
         <li>
                Activation email template is fetched from `tbl_email_templates` by <strong>`email_group`=activate_account</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tenants/Tenants_signup"><u>{{path}}explorer/#!/Tenants/Tenants_signup</u></a> 
         </li>
     </ul>
     <p><i><code> tenantExists</code></i></p> 
     <ul>
         <li>
                This API will check whether tenant is exists or not by using emailId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Tenants/Tenants_tenantExists"><u>{{path}}explorer/#!/Tenants/Tenants_tenantExists</u></a> 

         </li>
     </ul>
     </div> 
</mat-card>
