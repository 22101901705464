
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Project Category Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to add new project category into `tbl_project_category` using <strong>Project Category Name</strong> and <strong>priority id</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_add"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API will delete the project category from `tbl_project_category` by <strong>ProjectCategoryId</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_delete"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API will list all the project category from `tbl_project_category`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_list"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of particular project category using <strong>ProjectCategoryId</strong> . 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_listById"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                It is used to update the details of project category into `tbl_project_category` by <strong>ProjectCategoryId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_modify"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_modify</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>paginationList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the project category list with pagination. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_PaginationList"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_PaginationList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>updateActions</code></i></p> 
     <ul>
         <li>
                This API is used to update the action(<strong>category or auto assign</strong>) values into `tbl_project_category`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/ProjectCategory/ProjectCategory_updateActions"><u>{{path}}explorer/#!/ProjectCategory/ProjectCategory_updateActions</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
