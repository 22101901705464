

<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Proposal Model</h2>
 <p><i><code>proposalsAwaitingApproval</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the list of proposals from `tbl_proposals` if <strong>`tbl_proposals`.`status` =  'pending'</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Proposals/Proposals_proposalsAwaitingApproval"><u>{{path}}explorer/#!/Proposals/Proposals_proposalsAwaitingApproval</u></a> 

     </li>
 </ul>
 <br />  
 <p><i><code>recentlyAcceptedProposals</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the list of proposals from if `tbl_proposals` if <strong>`tbl_proposals`.`status` =  'accepted'</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Proposals/Proposals_recentlyAcceptedProposals"><u>{{path}}explorer/#!/Proposals/Proposals_recentlyAcceptedProposals</u></a> 

     </li>
 </ul>
 </div>
</mat-card>
