import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { MatCard } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.css'],
    standalone: true,
    imports: [MatIcon, NgIf, MatCard]
})
export class VersionComponent implements OnInit {

  versionId: any= '';
  constructor(private activatedRoute: ActivatedRoute) {
    // subscribe to router event
    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   console.log(typeof params);
    //   console.log(params);
    //   this.versionId = params.id;
    // });

  }

  ngOnInit() {
    this.activatedRoute.paramMap
    .subscribe( params => {
    this.versionId = params.get('id');
    console.log('id' + this.versionId);
    console.log(params.get('id'));
    });


    // const routeParams = this.activatedRoute.snapshot.paramMap.get('id');
    // // this.versionId = routeParams.id;
    // console.log('id', this.activatedRoute);
  }
}
