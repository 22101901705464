

<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
 <mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Payments Model</h2>
<h4>Commonly used methods:</h4>
<ul>
    <li>
            updateTransactionDataByAccountId :
            <ul>
                <li>
                        This method is used to update the balance amount into `tbl_accounts` and `tbl_chart_of_accounts` by accountId and coaId. 

                </li>
                <li>
                        Once update is done, new transaction will be created into `tbl_transactions`. 

                </li>
            </ul>
    </li>
    <li>
            sendPaymentEmail : It is used to send the payment through email by fetching email template(<strong>email_group ="payment_email"</strong>), client details(by clientId) and company_name from `tbl_config`. 

    </li>
    <li>
            getCOAInfoForClientInvoices :  
            <ul>
                <li>
                        Calculate the invoice totalAmount using calculateTo method. 

                </li>
                <li>
                        Tax rate of each item of an invoice will be fetched from `tbl_items`, `tbl_saved_items` and `tbl_tax_rates` through iteration. 

                </li>
            </ul>
    </li>
    <li>
            updateCommission : To create new sales commission details with userId and commissionId(from `tbl_commission_calculation`) into `tbl_sales_rep_commission`. 

    </li>
    <li>
            updateInvoiceCommission : To update the commissionId and commission duration(if exists) into `tbl_invoices` by invoiceId. 

    </li>
</ul>
 <br />  
     <p><i><code>addPayment</code></i></p> 
     <ul>
         <li>
                This API is used to create the payment for more than one invoice. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_addPayment"><u>{{path}}explorer/#!/Payments/Payments_addPayment</u></a> 

                <mat-card>
                        <figure class="images">
                                <img src='../../../../assets/docs/fig18.png' alt='missing' />
                                <figcaption>Fig 1: addPayment API flow</figcaption>
                        </figure>
                </mat-card>
                <br />
                <mat-card>
                        <figure>
                                <img src='../../../../assets/docs/fig19.png' alt='missing' />
                                <figcaption>Fig 2: getTransactionAmount API flow</figcaption>
                        </figure>
                </mat-card>
                <br />
                <mat-card>
                        <figure>
                                <img src='../../../../assets/docs/fig20.png' alt='missing' />
                                <figcaption>Fig 3: commission duration values from addPayment API</figcaption>
                        </figure>
                </mat-card>
                <br />
         </li>
     </ul>
 <br />  
  
     <p><i><code>allPayments</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the received payments for the invoices from `tbl_payments`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_allPayments"><u>{{path}}explorer/#!/Payments/Payments_allPayments</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>creditCardExpiringSoon</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the credit card  list which satisfies the following condition: <strong>tbl_invoices.status = "Not Paid" AND tbl_payment_info.payment_type = "credit_card" AND tbl_invoices.autopay = 1</strong> 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_creditCardExpiringSoon"><u>{{path}}explorer/#!/Payments/Payments_creditCardExpiringSoon</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Email</code></i></p>
     <ul>
         <li>
                This API is used to send an email of the payment using shootEmail method. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_email"><u>{{path}}explorer/#!/Payments/Payments_email</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>failedPayments</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of failed payments from `tbl_payments` if the below condition satisfies: <strong>tbl_payments.status = 'DECLINED' AND (tbl_payments.payment_method = 7 OR tbl_invoices.autopay = 1) AND tbl_payments.invoices_id IS NOT NULL</strong> 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_failedPayments"><u>{{path}}explorer/#!/Payments/Payments_failedPayments</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getAchPayment</code></i></p> 
     <ul>
         <li>
                This API is used to update the payment related data when doing payment using ACH. 

         </li>
         <li>
                Refer the below flowchart for more information about ach payment. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_getAchPayment"><u>{{path}}explorer/#!/Payments/Payments_getAchPayment</u></a> 
            <br />
            <mat-card>
                    <figure>
                            <img src='../../../../assets/docs/fig21.png' alt='missing' />
                            <figcaption>Fig 1: getAchPayment API flow</figcaption>
                    </figure>
            </mat-card>
         </li>
     </ul>
 <br />  
     <p><i><code>getEditPaymentDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the payment details for editing by using <strong>paymentsId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_getEditPaymentDetails"><u>{{path}}explorer/#!/Payments/Payments_getEditPaymentDetails</u></a> 

         </li>
     </ul>
     <p><i><code>getPaymentDetailList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the received payment details list with invoice for detail page. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_PaymentDetail"><u>{{path}}explorer/#!/Payments/Payments_PaymentDetail</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getSettlementSummeryFromPaymentGatewayLast6Month</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the summary report from payment gateway for the last six month of payments if the below condition satisfied: 

         </li>
         <li>
                <strong>tbl_payments.status = 'APPROVED' AND (tbl_payments.refund_status != 'APPROVED' OR tbl_payments.refund_status IS NULL) AND (tbl_payments.invoices_id IS NOT NULL) AND (tbl_payments.payment_method = 7).</strong> 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_SettlementSummeryFromPaymentGatewayLast6Month"><u>{{path}}explorer/#!/Payments/Payments_SettlementSummeryFromPaymentGatewayLast6Month</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API will fetch the list of received payments from `tbl_payments` with filter option. 

         </li>
         <li>
                totalPurchaseTotalCount method will fetch the totalCount of payments present in the `tbl_payments`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_list"><u>{{path}}explorer/#!/Payments/Payments_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>paymentsReport</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the payment amount approved for each month in a year. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_paymentsReport"><u>{{path}}explorer/#!/Payments/Payments_paymentsReport</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>ReceivePaymentsDetailPdf</code></i></p> 
     <ul>
         <li>
                This API is used to generate the detail page of a received payments in pdf format using <i><code><strong>require</strong></code></i>(<strong>'html-pdf'</strong>). 
                Eg: https://storage.googleapis.com/milagro-test/vivant%2FReceivePayments%2FE%3A%2FUtiliko%2Futiliko_API%2F-%2Faccounting%2FManjula%20test%2FreceivePayments%2F%2F1561013846665-undefined_receivePayments_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4671413851&amp;Signature=L%2BsPvkZQdEydydZ%2BvUFiRBxvX3bN5%2Bep61TEUAbGAMbO2q1noZvOj%2BHJ8Q5i%2Bvd9JL7BJXp50uFYYHGsgpdKRc8BlA1S9YBentR%2BtDQUWO3kzn3ABgb2tDamvfDVJ%2Fa9xkQTV7LNnGsxj%2Bx7TJ7x8o4rEAKDFkbeAieM3hGqVP29ticX8i%2BJ4vFAIlgRkEVyv4Uoi6PqUC6%2FY%2Bk9JsphsEbF7fSzJiI%2BJ5jMpepyXCgDT2NWUJZrzr%2FPPiIQWP96IiqRI3NIZxyn8cloLVo4csT2JNySs7oD7VOuAZUUq%2F0JvueWP86PjCgwSlfX%2FSf4WZprMw0CEKzKCHIPuFbQKg%3D%3D 

         </li>
         <li>
                Template for the payment detail page will be fetched from the `tbl_email_templates` using emailGroup: &lsquo;<strong>receive_payments_pdf</strong>&rsquo;. 

         </li>
         <li>
                uploadFilesTOCloud method is used here. 

         </li>
         <li>
                Fs.unlink method will delete the file from the local path once it has been uploaded into cloud storage. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_ReceivePaymentsDetailPdf"><u>{{path}}explorer/#!/Payments/Payments_ReceivePaymentsDetailPdf</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>updateEditPaymentDetails</code></i></p> 
     <ul>
         <li>
                This API is used to modify the details of received payment by the steps below. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Payments/Payments_updateEditPaymentDetails"><u>{{path}}explorer/#!/Payments/Payments_updateEditPaymentDetails</u></a> 
                <br />
                <mat-card>
                        <figure>
                                <img src='../../../../assets/docs/fig22.png' alt='missing' />
                                <figcaption>Fig : updateEditPaymentDetails API flow</figcaption>
                        </figure>
                </mat-card>
                <br />  
                <mat-card>
                        <figure>
                            <img src='../../../../assets/docs/fig23.png' alt='missing' />
                            <figcaption>Fig : update account details by newAmount and oldAmount</figcaption>
                        </figure>
                </mat-card>
         </li>
     </ul> 
     </div>
</mat-card>

