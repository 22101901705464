  
<div class="api-docs-back-btn">
    <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
    </section>
</div>

  <mat-card>
    <div class="api-docs">
    <h2 class="content-header-name">Models</h2>
    <p>Model is the one where we can create 
      the API’s in js file and configuration of each table in the model json file.</p>
    
        <ul>
          <li>
              Command to create model: lb model 
          </li> 
          <li>
              Command to install the npm packages: npm install / npm i
          </li>
          <li>
              Command to start the server: node server/server.js
          </li>
        </ul>
    </div>
  </mat-card>
      
