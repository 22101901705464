<div class="hold-transition register-page clearfix">
    <div class="register-box col-md-6 col-sm-12 col-xs-12 d-flex align-items-center pull-right vh-100">

        <div class="register-box-body">
          <form novalidate [formGroup]="registerForm" (ngSubmit)="onSubmit()" action="javascript:" class="md-float-material form-material">
              <div class="auth-box card border-0">
                <div class="card-block">
                  <div class="row m-b-20">
                    <div class="col-md-12">
                      <h3 class="text-center txt-primary tw-mb-4">Get your own free Utiliko account in minutes</h3>
                    </div>
                    <div class="col-md-12">
                      <h6 class="text-center txt-primary">Increase your efficiency, improve your communication, stay organized, and achieve more.</h6>
                    </div>
                  </div>
                  <div class="form-group form-primary tw-mb-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Full Name</mat-label>
                      <input matInput placeholder="Full Name" name="fullname" formControlName="fullname" required>
                    </mat-form-field>
                  </div>
                  <div class="form-group form-primary tw-mb-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" type="email" name="email" formControlName="email" (blur)="checkEmail($event.target.value)"  required>
                      <mat-error class="messages text-danger" *ngIf="show">Email already exists!</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group form-primary tw-mb-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Company Name</mat-label>
                      <input matInput placeholder="Company Name" type="text" name="companyName" formControlName="companyName" required>
                    </mat-form-field>
                  </div>
                  <div class="form-group form-primary tw-mb-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Company Domain</mat-label>
                      <input matInput placeholder="Company Domain" type="text" name="domain" formControlName="domain" (blur)="checkDomain($event.target.value)"  required>
                      <mat-error *ngIf="domainChecked && !showMsg && !showLengthMsg" >
                        <div class="messages text-danger" *ngIf="!showDomainAvailability">{{yourDomain}} is already registered!</div>
                        <div class="messages text-green" *ngIf="showDomainAvailability">{{yourDomain}} is available to use.</div>
                      </mat-error>
                      <mat-error *ngIf="showMsg">
                        <span class="messages text-danger">Invalid domain , please do not use special characters!</span>
                      </mat-error>
                      <mat-error *ngIf="showLengthMsg">
                        <span class="messages text-danger">Invalid domain , domain length must be greater than 1 and less than 63 !</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group form-primary tw-mb-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Company Phone Number</mat-label>
                      <input matInput placeholder="(123) 456-7890" type="text" id="contact" name="companyContact" formControlName="companyContact"  required>
                    </mat-form-field>
                  </div>
                  <div class="row p-2">
                    <div class="col-sm-6 password_left tw-mb-4">
                      <div class="form-group form-primary">
                        <mat-form-field appearance="outline">
                          <mat-label>Password</mat-label>
                          <input matInput placeholder="Password" type="password" name="password" formControlName="password"  (keypress)="restrictSpace($event)" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-sm-6 password_right tw-mb-4">
                      <div class="form-group form-primary">
                        <mat-form-field appearance="outline">
                          <mat-label>Confirm Password</mat-label>
                          <input matInput placeholder="Confirm Password" type="password" name="confirmPassword" formControlName="confirmPassword"  (keypress)="restrictSpace($event)" required>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-sm-6" *ngFor="let mainMenu of moduleList">
                      <div class="checkbox-fade fade-in-primary">
                        <mat-checkbox name="selectModules" value="{{mainMenu.menuId}}" formControlName="selectModules" type="checkbox" [indeterminate]="mainMenu.ischecked" [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)" > {{mainMenu.label | titlecase}}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div> -->
                  <div class="m-t-5 position-relative">
                        <button type="submit" name="signUp" class="btn btn-block text-center proceedBtn"
                        [disabled]="isDisabled">Sign up now</button>
                        <i class="fa fa-arrow-right signup-arrow" aria-hidden="true"></i>
                  </div>
                  <hr class="m-b-10 m-t-10">
                  <div class="col-md-12">
                    <p class="text-inverse text-center m-b-10"><a [routerLink]="['/login']" class="link-text"><b>Back to login</b></a></p>
                    
                  </div>
                </div>
              </div>
            </form>
        </div>
        <!-- /.form-box -->
      </div>
    <div class="login_page_content col-md-6 col-sm-12 col-xs-12">
      <div class="register-info__container">
        <h1><span class="register_text tw-mb-4">Your very own Utiliko Business Management Trial in less than </span>
          <span class="register_text">60 seconds !</span></h1>
        <h4>
          14-day free trial 
        </h4>
        <hr class="txt-seperator">
        <h4 class="tw-mt-2 tw-mb-4">
          Trial Includes 
        </h4>
        <h4>
          <ul>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Full access to all apps</li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Add unlimited Users</li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Pre-configured for you to get up and running quickly</li>
            <li> <i class="fa fa-check" aria-hidden="true"></i> Zero obligation , no credit cards required </li>
          </ul>
        </h4>
      </div>
    </div>

</div>
<app-spinner></app-spinner>
