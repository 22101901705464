import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISubscriptionPayResponseCCList, ISubscriptionPayResponseAddedModuleList, ISubscriptionPayResponseSubModuleList } from '../interface/subscription-pay.interface';
import { API } from '../../../service/constant';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPayService {

  constructor(private http: HttpClient) { }

  paymentInfoListByUser(): Observable<ISubscriptionPayResponseCCList> {
    return this.http.get<ISubscriptionPayResponseCCList>(API.PAYMENT_LIST_BY_USER);
  }

  getSubscribedModules(): Observable<ISubscriptionPayResponseSubModuleList> {
    return this.http.get<ISubscriptionPayResponseSubModuleList>
      (API.SUBSCRIBED_MODULES);
  }

  getAlreadySubscribedModules(nextBillingDate: string):
    Observable<ISubscriptionPayResponseAddedModuleList> {
    return this.http.get<ISubscriptionPayResponseAddedModuleList>
      (API.MENU_ALREADY_SUBSCRIBED_MODULES + '?billingDate=' + nextBillingDate);
  }
}
