
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Menu Model</h2>
  <p><i><code>dynamicMenu</code></i></p> 
  <ul>
      <li>
            This API is used to fetch menu list from `tbl_menu` in tree structure based on the permission. 

      </li>
      <li>
            UserSubscribedModules method will return the userId, isOwner and Subscribed Apps list of that userId. 

      </li>
      <li>
            getMenus method will return the list of menus based on the below conditions: 
            <ul>
                <li>
                        isOwner == 1: It will fetch all the menus present in Subscribed Apps list. 

                </li>
                <li>
                        isOwner != 1: It will fetch the menus based on the permissions including policy, roles, resources and accessrules. 

                </li>
            </ul>
      </li>
      <li>
            getTree method is used to create the tree structure for the menu list. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Menu/Menu_dynamicMenu"><u>{{path}}explorer/#!/Menu/Menu_dynamicMenu</u></a> 

      </li>
  </ul>
   <br />
   <p><i><code>getSubscribedModulelist</code></i></p> 
   <ul>
       <li>
            This API will fetch the root modules list which are present in subscribed module list. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Menu/Menu_getSubscribedModuleList"><u>{{path}}explorer/#!/Menu/Menu_getSubscribedModuleList</u></a>&nbsp; 

       </li>
   </ul>
   </div>
</mat-card>
