<!-- <mat-dialog-content> -->
  <!-- <div class="col-sm-4" *ngFor="let mainMenu of request.moduleList">
    <div class="checkbox-fade fade-in-primary">
      <mat-checkbox name="selectModules" value="{{mainMenu.menuId}}" type="checkbox" [checked]="mainMenu.ischecked"
        [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)">
        {{mainMenu.label | titlecase}}
      </mat-checkbox>
    </div>
  </div> -->
<div>
<div class="close-div"><span style="cursor: pointer;" (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></span> </div>
<div class="add-company-dialog">
      <mat-horizontal-stepper name="billingGroup" linear #stepper>
        <mat-step [stepControl]="zeroAddCompanyForm">
          <ng-template matStepLabel>Tell us</ng-template>
          <form [formGroup]="zeroAddCompanyForm" (ngSubmit)="form0()" #formzero="ngForm">

            <div class="form-div">
              <!-- <select2 [data]="industryData" material name="industry" (search)="filterIndustry($event)"
                customSearchEnabled="true" formControlName="industry" placeholder="Industry" id="industry"
                (update)="updateIndustry()" class="mySelect2" [ngClass]="submit===true ? 'errorClass' :'noError'"
                required></select2> -->
                <ng-select [items]="industryData" bindLabel="label"
                bindValue="value" formControlName="industry" placeholder="Industry" id="industry" (update)="updateIndustry()" class="custom-select"  [ngClass]="submit===true ? 'errorClass' :'noError'"  required>
                </ng-select>
              <span class="formHint">We'll use this to create accounts common for your industry.</span>
            </div>

            <div class="form-div">
              <!-- <select2 [data]="companyData" material (update)="updateCompany()" class="mySelect2"
                [ngClass]="checkCompany===true ? 'errorClass' :'noError'" name="companyType"
                (search)="filterCompany($event)" customSearchEnabled="true" formControlName="companyType"
                placeholder="Company Type" class="companyType-css" id="companyType" required></select2> -->
                <ng-select [items]="companyData" bindLabel="label"
                bindValue="value" (update)="updateCompany()"
                [ngClass]="checkCompany===true ? 'errorClass' :'noError'" name="companyType" formControlName="companyType" placeholder="Company Type" class="companyType-css custom-select" id="companyType" required>
                </ng-select>
              <span class="formHint">We'll use this to select the right tax settings for your business</span>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>Tax ID</mat-label>
              <input matInput placeholder="Tax ID" formControlName="fein">
            </mat-form-field>
            <span class="formHint">We will use this on your tax forms</span>
            <div class="btn-class">
              <button name="continueBtnOne" mat-stroked-button color="primary" matStepperNext>Continue</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="firstAddCompanyForm">
          <ng-template matStepLabel>Contact Info</ng-template>
          <form novalidate [formGroup]="firstAddCompanyForm" (ngSubmit)="form1(stepper)" #formone="ngForm">
              <div class="card-block">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="Email" type="email" name="emailField" formControlName="email" (blur)="checkEmail($event.target.value)" required>
                </mat-form-field>
                <div class="messages text-danger" *ngIf="show">Email already exists!</div>
              </div>
              <div class="">
                <mat-form-field appearance="outline">
                  <mat-label>Company Name</mat-label>
                  <input matInput placeholder="Company Name" type="text" name="companyNameField" formControlName="companyName"
                    required>
                </mat-form-field>
              </div>
              <div class="">
                <mat-form-field appearance="outline">
                  <mat-label>Company Domain</mat-label>
                  <input matInput placeholder="Company Domain" type="text" name="domainField" (blur)="checkDomain($event.target.value)" formControlName="domain"
                    required>
                </mat-form-field>
                <div *ngIf="domainChecked">
                  <div class="messages text-danger" *ngIf="!showDomainAvailability">{{yourDomain}} is already registered!</div>
                  <div class="messages text-green" *ngIf="showDomainAvailability">{{yourDomain}} is available to use.</div>
                </div>
              </div>
              <div class="">
                <mat-form-field appearance="outline">
                  <mat-label>Company Phone Number</mat-label>
                  <input matInput placeholder="(123) 456-7890" type="text" id="contact" name="companyContact"
                    formControlName="companyContact" required>
                </mat-form-field>
              </div>
              <div class="">
                <mat-form-field appearance="outline">
                  <mat-label>Website</mat-label>
                  <input matInput placeholder="Website" formControlName="website">
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Address</mat-label>
                 <input matInput placeholder="Address" formControlName="address" name="address" ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" required>
                </mat-form-field>
                <div class="row">
                  <div class="col-md-6 input-box-padding">
                    <mat-form-field appearance="outline">
                      <mat-label>City, State, Zip</mat-label>
                      <input name="cityStateField" matInput placeholder="City, State, Zip" formControlName="cityStateZip" (blur)="checkbillingcityStateZip()" required>
                    </mat-form-field>
                    <div *ngIf="showError" class="alert-msg">{{errorMsg}}</div>
                    <div *ngIf="showError1" class="alert-msg">{{errorMsg1}}</div>
                  </div>
                  <div class="col-md-6 input-box-padding">
                    <mat-form-field appearance="outline">
                      <mat-label>Country</mat-label>
                      <input name="countryField" matInput placeholder="Country" formControlName="country" required>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row m-t-30">
                <div class="btn-class">
                  <button class="pull-left" mat-stroked-button color="primary" matStepperPrevious>Back</button>
                  <button name="continueBtnTwo" mat-stroked-button color="primary" [disabled]="isDisabled" type="submit">Continue</button>
                </div>
              </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondAddCompanyForm">
          <ng-template matStepLabel>Select Modules</ng-template>
          <form [formGroup]="secondAddCompanyForm" (ngSubmit)="form2()" #formtwo="ngForm">
            <div class="col-sm-12 centerAlign"> Select Apps that you want to subscribe (Default apps are already checked) </div>
            <div class="col-sm-6" *ngFor="let mainMenu of request.moduleList">
              <div class="checkbox-fade fade-in-primary">
                <mat-checkbox name="selectModules" value="{{mainMenu.menuId}}" formControlName="selectModules" type="checkbox" [indeterminate]="mainMenu.ischecked" [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)" > {{mainMenu.label | titlecase}}
                </mat-checkbox>
              </div>
            </div>

            <div class="btn-class row m-t-30 col-sm-12 btn-class clearfix">
              <button class="pull-left" mat-stroked-button color="primary" matStepperPrevious>Back</button>
              <button name="submitBtn" mat-stroked-button mat-raised-button type="button" color="primary" (click)="formzero.ngSubmit.emit();formone.ngSubmit.emit();formtwo.ngSubmit.emit();"> Submit </button>
            </div>

          </form>
        </mat-step>
      </mat-horizontal-stepper>
    <!-- /.form-box -->
</div>
<!-- </mat-dialog-content> -->
</div>