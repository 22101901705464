import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'mat-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [NgFor, MatIconButton, MatTooltip, MatIcon, NgIf, MatError]
})
export class StarRatingComponent implements OnInit {

    @Input('rating') public rating: number = 0;
    @Input('starCount') public starCount: number = 15;
    @Input('color') public color: string = 'accent';
    @Output() public ratingUpdated = new EventEmitter();

    public ratingArr = [];

    constructor() {
    }


    ngOnInit() {
        for (let index = 0; index < this.starCount; index++) {
            this.ratingArr.push(index);
        }
    }
    onClick(rating: number) {
        this.ratingUpdated.emit(rating);
        return false;
    }

    showIcon(index: number) {
        if (this.rating >= index + 1) {
            return 'star';
        } else {
            return 'star_border';
        }
    }

}
export enum StarRatingColor {
    primary = "primary",
    accent = "accent",
    warn = "warn"
}
