import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskService } from '../../../../service/task.service';
import { ExcelGenerator } from '../../../../service/Utils/ExcelGenerator';
import { AlertCommonDialogData, EStatusCode } from '../../../../service/Utils/Interfaces.class';
import { PDFGenerator } from '../../../../service/Utils/PDFGenerator';
import moment from "moment";
import { Router } from '@angular/router';
import { NgOption, NgSelectModule } from '@ng-select/ng-select';
import { ParticipantsDialogComponent } from '../participants-dialog/participants-dialog.component';
import { CommonDialogModelComponent } from '../../../../admin/shared/common-dialog-model/common-dialog-model.component';
import { COMMON } from '../../../../service/constant';
import { NgModel, FormsModule } from '@angular/forms';
import { AppComponentBase } from '../../../../admin/shared/AppComponentBase';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-test-cases-list',
    templateUrl: './test-cases-list.component.html',
    styleUrls: ['./test-cases-list.component.scss'],
    standalone: true,
    imports: [MatButton, MatTooltip, NgClass, ExtendedModule, MatFormField, MatSelect, FormsModule, MatOption, NgSelectModule, MatInput, NgFor, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgIf, MatMenu, MatMenuItem, MatMenuTrigger, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, TitleCasePipe]
})
export class TestCasesListComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  testCasesExcel = new ExcelGenerator();
  testCasesPDF = new PDFGenerator();
  visibilityColumns=[];
  searchValue: string = '';
  pageSizeArray = [15, 50, 100];
  showResultLabel = false;
  prevPageIndex = 0;
  tastCaseLength:any;
  start = 0;
  limit = 15;
  pageSize = 15;
  responsibleUserId: NgOption = [];
  event: any;
  selected:any;
  length:any
  // router: any;
  responsiblearr: Array<object> = [];
  responsibleUser: number;
  dropArr:any = [];
  dataSource= new MatTableDataSource<any>();
  pdfData:any;
  constructor(inject: Injector, public taskService:TaskService, public router: Router, public dialog: MatDialog) {super(inject)}
  @Input() tabGroup: MatTabGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sortData: MatSort;
  displayedColumns=this.visibilityColumns=["testCase_Code", "testCase_Title","Priority","status","Module Name","Project","Responsible User","Participant","Action"]
  usersArr = [];
  taskStatusArr = [];
  routerDialog: Router;
  editData:any;
  isEditMode:any;
  moduleArr=[];
  submodulearr=[];
  testcaseTypeData:any=[];
  testcasePriorityData:any=[];
  userVal:any[] = [];
  sunModVal:any[] = [];
  statusVal:any[] = [];
  typeVal:any[] = [];
  testCaseType:any[] = [];
  priority:any[] = [];
  filterApply:boolean=false;
  filter:any;
  userArray:any;
  subModName:any;
  statusArray:any;
  typeArray:any;
  project:any;
  projectArray:any;
  projectVal:any[] = [];
  projName:any[] = [];
  modelGroup: any[] = [];

  ngOnInit() {
  this.getModuleDropDownList();
  this.getSubModuleDropDownList(0)
  this.getAllTestCases(0,0);
  this.testcaseTypeData = [
    {
      value: "acceptance",
      label: "Acceptance",
    },
    {
      value: "accessibility",
      label: "Accessibility",
    },
    {
      value: "smoke&sanity",
      label: "Smoke & Sanity",
    },
    {
      value: "regression",
      label: "Regression",
    },
    {
      value: "usability",
      label: "Usability",
    },
    {
      value: "functional",
      label: "Functional",
    },
    {
      value: "compatibility",
      label: "Compatibility",
    },
    {
      value: "ui",
      label: "UI",
    },
    {
      value: "api",
      label: "API",
    },
    {
      value: "mobileapp",
      label: "Mobile App",
    },
  ];
  this.taskStatusArr = [
    {
      "value": "in_progress",
      "label": "In Progress",
    },
    {
      "value": "completed",
      "label": "Completed"
    },
    {
      "value": "waiting_for_someone",
      "label": "Waiting for Someone"
    },
    {
      "value": "approved",
      "label": "Approved"
    }
  ];
    this.project = [
      {
        value: 1,
        label: "Cloud app",
      },
      {
        value: 2,
        label: "Android app",
      },
      {
        value: 3,
        label: "iOS app",
      },
      {
        value: 4,
        label: "Other",
      },
    ];

if(this.taskService.testcaseEditData == undefined){
  var data = {
    isEditMode : false,
    editId : null
  }
  this.taskService.testcaseEditData = data;
}
  this.editData = this.taskService.testcaseEditData;
 if(this.editData.isEditMode == true){
  this.tabGroup.selectedIndex = 1 
  }else{
  var data = {
    isEditMode : false,
    editId : null
  }
  this.taskService.testcaseEditData = data;
  }
  if (this.taskService.testCaseFilterPatchList != undefined) {
    var filterData = this.taskService.testCaseFilterPatchList;
    this.userArray = filterData.moduleName;
    this.subModName = filterData.subModName;
    this.statusArray = filterData.statusName;
    this.typeArray = filterData.typeName;
    this.projectArray = filterData.projectName;
    this.filterApply = filterData.filterApply;
    this.userArray && this.userArray.length && this.userArray.forEach((val: any) => {
      this.userVal.push(val.value)
    })
    this.subModName && this.subModName.length && this.subModName.forEach((val: any) => {
      this.sunModVal.push(val.value)
    })
    this.statusArray && this.statusArray.length && this.statusArray.forEach((val: any) => {
      this.statusVal.push(val.value)
    })
    this.typeArray && this.typeArray.length && this.typeArray.forEach((val: any) => {
      this.typeVal.push(val.value)
    })
    this.projectArray && this.projectArray.length && this.projectArray.forEach((val: any) => {
      this.projectVal.push(val.value)
    })
    this.filter = { "moduleName": filterData.filterValData.moduleName ? filterData.filterValData.moduleName : null, "subModName": filterData.filterValData.subModName ? filterData.filterValData.subModName: null,"status": filterData.filterValData.status ? filterData.filterValData.status : null,"type": filterData.filterValData.type ? filterData.filterValData.type : null,"projectId":filterData.filterValData.projectId ? filterData.filterValData.projectId : null, filterApply:this.filterApply };

this.getAllTestCases(0,0)
  }else{
    this.filterApply = false;
    this.filter = { "moduleName": null, "subModName": null, "status": null,"type":null,"projectId":null, filterApply:this.filterApply}
  }
}
  getAllTestCases(count?, previousPageIndex?){
    if (count === 0) {
      if (count <= previousPageIndex) {
        this.start = 0;
      } else {
        this.start += this.pageSize;
      }
    } else {
      this.start = count * this.pageSize;
    }
    let filterType = { 
      search: this.searchValue,
      limit: this.limit ? this.limit :15,
      start: this.start ? this.start : 0}   
      if(this.filterApply == true){
        this.filter=this.filter;
      }else{ 
            this.filter = { "moduleName": null, "subModName": null, "status": null,"type":null, "projectId":null,  filterApply:this.filterApply };
      }
    const EmployesDDLi : Subscription = this.taskService.getAllTestCases(filterType, this.filter,).subscribe((result: any) => {
      if (result.info.code === EStatusCode.OK) {
         this.usersArr = result.data.data.userDetail;
         let _tempArr = result.data.data.listResult.map((e) => {
          let responsibleUserName = this.usersArr.find((el) => el.userId === e.responsibleUserId);
          e['responsibleUserName'] = responsibleUserName ? responsibleUserName.fullname : '';
          if(e.permission == 2) {
            e['participants'] = [];
          }

          if(e.permission == 1) {
            e['participants'] = [];
            let userExist = this.usersArr.find((el) => el.userId === e.responsibleUserId);
            if(userExist) {
              e['participants'].push(userExist);
            }
          }

          if(e.permission == 3) {
            let _tempParticipants = e.customUsers.split(',').map((user) => {
              let userExist = this.usersArr.find((el) => el.userId == user);
              return userExist ? userExist : null;
            });
            e['participants'] = _tempParticipants;
          }

          return e;
        });
        this.pdfData = result.data;
        this.dataSource.data = _tempArr;
        this.tastCaseLength = result.data.totalCount[0].count;
      }
    });
    this.subscriptions.push(EmployesDDLi);
  }
  deleteTestCase(id){
    const alertCommonDialogData: AlertCommonDialogData = {
      title: 'Confirm Delete',
      message: 'You are about to delete a record. This cannot be undone. Are you sure?',
      submitButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    };
    const dialogRef = this.dialog.open(CommonDialogModelComponent, {
      width: COMMON.DELETE_ALERT_WIDTH,
      height: COMMON.DELETE_ALERT_HEIGHT,
      data: alertCommonDialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
    const DeleteList : Subscription = this.taskService.deleteTestCasesById(id).subscribe((result: any) => {
      if (result.result.info.code === 200) {
        this.toastr.success(result.result.data.responseMsg);
        this.getAllTestCases();
      }    
    });
    this.subscriptions.push(DeleteList);
  }
})
  
 
  }
  editTestCase(id){
    var data = {
      isEditMode : true,
      editId : id
    }
    this.taskService.testcaseEditData= data;
    this.tabGroup.selectedIndex = 1 

  }
  search(searchValue) {
    this.showResultLabel = true;
    this.searchValue = searchValue;
    this.paginator.pageIndex = 0;
    this.getAllTestCases();
  }

  onChange(event) {
    this.event = event.pageIndex;
    if (this.limit !== event.pageSize) {
      this.start -= event.pageSize;
      this.limit = event.pageSize;
      this.pageSize = event.pageSize;
    }
    this.prevPageIndex = event.previousPageIndex;
    this.getAllTestCases(event.pageIndex, event.previousPageIndex);
  }


  viewDetailPage(testCaseId, type, $event) {
    this.router.navigate(['/admin/docs/test-and-qa/test-cases/details/', testCaseId]);
  }
  
  downloadPDF() {
    let pdfdata =[];
    let moduleName , tskStatus ;
    let customFieldWidth = {};
    this.pdfData.data.listResult.forEach(element => {
      if(element.bugId !=0){       
          moduleName = 'Bug'      
      }else if(element.clientId !=0){
         moduleName = 'Client'       
      }else if(element.leadsId !=0){
         moduleName = 'Leads'       
      }else if(element.milestonesId !=0){
         moduleName = 'Milestones'
     }else if(element.opportunitiesId !=0){
         moduleName = 'Opportunities'       
      }else if(element.projectId !=0){
         moduleName = 'Project'
      }else if(element.ticketsId !=0){
        moduleName = 'Tickets'
      }else if(element.goalTrackingId !=0){
        moduleName = 'Goal Tracking'
    }
    if (element.testCaseStatus !== null) {
      tskStatus = element.testCaseStatus.replace(/[_-]/g, " ");
    }
       let newpdfData = {
          "taskName": element.testCaseName,
          "status": tskStatus,
          "createddate": moment(element.createdDate).format('YYYY-MM-DD') ,
          'priority': element.testCasePriority,
          'moduleName':moduleName
       };
      pdfdata.push(newpdfData);
    })
    const rows = pdfdata;
    let columns = [
      { title: "Title", dataKey: "taskName"},
      { title: "Priority", dataKey: "priority" },
      { title: "Status", dataKey: "status" },
      { title: "Module Name", dataKey: "moduleName" },
      { title: "Created date", dataKey: "createddate" }
    ]
    customFieldWidth['Title'] = {columnWidth: 20}
    
  this.testCasesPDF.generatTestCasePDFInTable("Test cases List", columns, rows, "Test cases List");
  }
  downloadExcel() {
    let pdfdata =[];
    let moduleName , tskStatus ;

    this.pdfData.data.listResult.forEach(element => {
      if(element.bugId !=0){       
          moduleName = 'Bug'      
      }else if(element.clientId !=0){
         moduleName = 'Client'       
      }else if(element.leadsId !=0){
         moduleName = 'Leads'       
      }else if(element.milestonesId !=0){
         moduleName = 'Milestones'
     }else if(element.opportunitiesId !=0){
         moduleName = 'Opportunities'       
      }else if(element.projectId !=0){
         moduleName = 'Project'
      }else if(element.ticketsId !=0){
        moduleName = 'Tickets'
      }else if(element.goalTrackingId !=0){
        moduleName = 'Goal Tracking'
    }
    if (element.testCaseStatus !== null) {
      tskStatus = element.testCaseStatus.replace(/[_-]/g, " ");
    }
       let newpdfData = {
          "taskName": element.testCaseName,
          "status": tskStatus,
          "createddate": moment(element.createdDate).format('YYYY-MM-DD') ,
          'priority': element.testCasePriority,
          'moduleName':moduleName
       };
      pdfdata.push(newpdfData);
    })
    const rows = pdfdata;
    let columns = [
      { title: "Title", dataKey: "taskName" },
      { title: "Priority", dataKey: "priority" },
      { title: "Status", dataKey: "status" },
      { title: "Module Name", dataKey: "moduleName" },
      { title: "Created date", dataKey: "createddate" }
    ]
   

    this.testCasesExcel.generatExcel("Test Cases List", rows, "Location Sales List");
  }

  openParticipantsDialog(data){
    if (data.ticketLockStatus === 1) {
      this.toastr.error('Ticket has been locked and no changes can be made. Please unlock the ticket first');
    } else {
      const matDialogConfig: MatDialogConfig = {
        position: {
          top: '50px',
        },
        width: '800px',
        panelClass: ['animated', 'none', this.configService.userTheme],
        backdropClass: 'participantspopup'      
      };
      const dialogConfig = matDialogConfig;
      dialogConfig.data = { details: data, type: 'test-case' };
      const dialogRef = this.dialog.open(ParticipantsDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.data == true) {
          this.getAllTestCases(0,0);
        }
      });
    }
  }

  statusUpdate(testCaseId, value){
    const updateStatustestCase : Subscription = this.taskService.updateTestCasesStatus(testCaseId , value).subscribe((res: any) => {
      if (res.info.code === 200) {
        this.toastr.success(res.data.responseMsg);
        this.ngOnInit();
      }else{
        this.toastr.error('Test case status not updated due to some error, please contact admin');
      }
    })
  this.subscriptions.push(updateStatustestCase);

  }
  resetFilter(select: NgModel) {
    this.filterApply=false;
    this.statusVal=[],this.userVal =[],this.sunModVal=[],this.typeVal=[],this.projectVal=[];
    this.projectArray=null;
    this.userArray = null;
    this.subModName = null;
    this.statusArray = null;
    this.projName=null;
    this.typeArray=null;
    this.filter = { "moduleName": null, "subModName": null,"status": null,"type":null,"projectId":null, filterApply:this.filterApply };
    this.taskService.testCaseFilterPatchList = undefined;
this.getAllTestCases(0,0)
  }
  getModuleDetails(event){
    this.userVal=[]
    event.forEach((val => {
  this.userVal.push(val.value);
}));
}
getSubModDetails(event){
  this.sunModVal=[]
  event.forEach((val => {
this.sunModVal.push(val.value);
}));
}
getStatus(event){
  this.statusVal=[]
  event.forEach((val => {
this.statusVal.push(val.value);
}));
}
getType(event){
  this.typeVal=[]
  event.forEach((val => {
this.typeVal.push(val.value);
}));
}
getProject(event){
  this.projectVal=[]
  event.forEach((val => {
this.projectVal.push(val.value);
this.projName.push(val.label);
}));
}
applyTaskFilter(select: NgModel){
  this.filterApply=true;
var modVal = JSON.stringify(this.userVal)
var modFnlVal = modVal.replace(/[\[\]']+/g,'')
var subMod = JSON.stringify(this.sunModVal)
var subModVal = subMod.replace(/[\[\]']+/g,'')
var statusVal = JSON.stringify(this.statusVal)
var statusFnlVal = statusVal.replace(/[\[\]']+/g,'')
var typeVal = JSON.stringify(this.typeVal)
var typeFnlVal = typeVal.replace(/[\[\]']+/g,'')
var projectVal = JSON.stringify(this.projectVal)
var projFnlVal = projectVal.replace(/[\[\]']+/g,'')

  this.filter = { "moduleName": modFnlVal ? modFnlVal : null, "subModName": subModVal ? subModVal: null,"status": statusFnlVal ? statusFnlVal : null,filterApply:this.filterApply , "type": typeFnlVal ? typeFnlVal : null , "projectId": projFnlVal ? projFnlVal : null};
  var body = {
    moduleName:this.userVal,
    subModName:this.sunModVal,
    statusName:this.statusVal,
    typeName:this.typeVal,
    projectName:this.projName,
    filterApply:this.filterApply,
    filterValData:this.filter
  }
  this.taskService.testCaseFilterPatchList = body;
this.getAllTestCases(0,0)
} 
getModuleDropDownList(){
  const moduleDDLi : Subscription = this.taskService.getmoduleDDList().subscribe((res: any) => {
    if (res.info.code === EStatusCode.OK) {
      var myUsersObj = {};
      this.moduleArr = [];
      res.data.forEach(userObj => {
        myUsersObj = {'value': userObj.moduleName.toLowerCase(), 'label': userObj.moduleName};
        this.moduleArr.push(myUsersObj);
      });
  
    }
  })
  this.subscriptions.push(moduleDDLi);
}
getSubModuleDropDownList(id){
  const submoduleDDLi : Subscription = this.taskService.getsubModuleDDList(id).subscribe((res: any) => {
    if (res.info.code === EStatusCode.OK) {
      var myUsersObj = {};
      this.submodulearr=[];
      res.data.forEach(userObj => {
        myUsersObj = {'value': userObj.subModuleName.toLowerCase(), 'label': userObj.subModuleName};
        this.submodulearr.push(myUsersObj);
      });
    }
  })
  this.subscriptions.push(submoduleDDLi);
}


trackByuserFn(index:number, item){
  return item;
}
}

