import { Routes } from '@angular/router';

const clientRoutes: Routes = [
    {
        path: 'client',
        redirectTo: 'client/dashboard',
        pathMatch: 'full'
    },
    { 
        path: 'client/dashboard',
        loadChildren: () => import('./client-dashboard/client-dashboard.module').then((m: typeof import('./client-dashboard/client-dashboard.module')) => m.ClientDashboardModule)    
    },
    {
        path: 'client/bugs',
        loadChildren: () => import('./client-bugs/client-bugs.module').then((m: typeof import('./client-bugs/client-bugs.module')) => m.ClientBugsModule),
    },
    {
        path: 'client/estimates',
        loadChildren: () => import('./client-estimates/client-estimates.module').then((m: typeof import('./client-estimates/client-estimates.module')) => m.ClientEstimatesModule),
    },
    {
        path: 'client/invoice',
        loadChildren: () => import('./client-invoice/client-invoice.module').then((m: typeof import('./client-invoice/client-invoice.module')) => m.ClientInvoiceModule),
    },
    {
        path: 'client/payment',
        loadChildren: () => import('./client-payment/client-payment.module').then((m: typeof import('./client-payment/client-payment.module')) => m.ClientPaymentModule),
    },
    {
        path: 'client/project',
        loadChildren: () => import('./client-projects/client-projects.module').then((m: typeof import('./client-projects/client-projects.module')) => m.ClientProjectsModule),
    },
    {
        path: 'client/user',
        loadChildren: () => import('./client-user/client-user.module').then((m: typeof import('./client-user/client-user.module')) => m.ClientUserModule),
    },
    {
        path: 'client/tickets',
        loadChildren: () => import('./client-tickets/client-tickets.module').then((m: typeof import('./client-tickets/client-tickets.module')) => m.ClientTicketsModule),
    },
    {
        path: 'client/rma',
        loadChildren: () => import('./client-rma/client-rma.module').then((m: typeof import('./client-rma/client-rma.module')) => m.ClientRmaModule),
    }
];

export default clientRoutes;
