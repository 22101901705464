 <div class="testing-val-class">
<div class="global-release-notes">
<div class="side-content"> 
<img src="https://storage.googleapis.com/utiliko-app-bucket/vivant%2FUtiliko%20Business%20Management%2FGaurav%2FWebsite%20logo%2F1560915660262-logo.png?GoogleAccessId=utiliko-app-bucket%40utiliko-app.iam.gserviceaccount.com&amp;Expires=4671315661&amp;Signature=d44K41TDRivI0dj%2FdHlyBP%2BNT8ZGoIY8u70ID27q2vrYnNFgNiUks6Yv67ojdOwDBu%2BlB2D6jp9wZkhpXBccWXcKNBznnvnHbqp3foRCzJ%2B%2BM6oZlcWbZI8NmNERkpSxT4YppE3Mu3nczu5fYCckgfUaepzMy3bqKYfV1CHybA%2BXwnGnOkO1j%2Fetr3dyjT2GmNnjJfGylN2txESQRU0hZBKt%2FEQ8V%2FTQx%2BjmsLb%2BTvxc0MBmJ6ebUc4Ib6sRwN8iZvmQ6oGWPrYBb%2Fw7WRG%2BkkkZrqMMgRDi1KoAOfhAnv05dztayJ28O8olaFgfOqwFhD0vjn1qntuVarDBD6b%2Fzw%3D%3D" class="logoAlinmentClass" alt= "Utiliko">
  <ul class="yearClass">                  
        <li class="tip-nav-active-parent">  
           <div *ngFor='let releaseNoteYear of  finaljsonarray; index as j;'> 
            <a href="/release-notes#sectionnav{{releaseNoteYear.year}}"  (click)="tabClick($event,{year:releaseNoteYear.year})" class="activeclass sec parentYear{{releaseNoteYear.year}} sectionnav sectionnav{{releaseNoteYear.year}}">{{releaseNoteYear.year}}</a>
          <ul class="monthClass">
            <li  class="tip-nav-hash   tip-nav-active-parent">
              <div *ngFor='let releaseNotesMonth of releaseNoteYear.montharr;index as i'>
                <a href="/release-notes#sectionnav{{releaseNotesMonth.monthName}}" (click)="tabClick($event,{year:releaseNoteYear.year,month:releaseNotesMonth.monthName})" 
                class="activeclass sectionnav sectionnav{{releaseNotesMonth.monthName}}">{{releaseNotesMonth.monthName}}</a>
              <ul class="subMonth">
                <li class="tip-nav-hash tip-nav-active">
                   <div *ngFor="let releaseNoteDay of releaseNotesMonth.daydata;index as j">
                  <a href="/release-notes#sectionnav{{releaseNotesMonth.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}" (click)="tabClick($event,{year:releaseNoteYear.year,month:releaseNotesMonth.monthName,day:releaseNoteDay.day,version:releaseNoteDay.versionName})"
                      class="activeclass sectionnav sectionnav{{releaseNotesMonth.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">{{releaseNoteDay.monthName}} {{releaseNoteDay.day}}<code class="code">{{releaseNoteDay.versionName}}
                     </code></a>
                     </div>
                  </li>   
              </ul>
             </div>
              </li>
                </ul>  
             </div>  
           </li>   
          </ul>   
      
     </div>    

   <!-- Right Div-->
  
     <div id="releaseDetailsWrapper" (scroll)="scorllData($event)"> 
        <h1 class="head-class">
            <span>Release Notes</span>
            <mat-divider></mat-divider>
          </h1>      
          <div id="version">  
            <div *ngFor='let releaseNote of finaljsonarray'>
             <div *ngFor="let releaseNoteMonth of releaseNote.montharr;index as p">
              <div  class="yearborder sectionnav{{releaseNote.year}}"> 
              <span><h4  class="sectionnav sectionnav{{releaseNote.year}} secdiv" id="sectionnav{{releaseNote.year}}">{{releaseNoteMonth.monthName}} Release Notes {{releaseNote.year}}</h4></span>
           </div>
           <div class="releaseMonths secdiv sectionnav{{releaseNoteMonth.monthName}}" id="sectionnav{{releaseNoteMonth.monthName}}">
             <div class="sectionnav secdiv" id="sectionnav{{releaseNoteMonth.monthName}}">{{releaseNoteMonth.monthName}}</div>
           </div>
             <div>
            <div class="releaseDate"> 
             <div class="clearfix Icon-wrapper" *ngFor="let releaseNoteDay of releaseNoteMonth.daydata;index as k">
               <div class="releaseWrapper secdiv sectionnav" id="sectionnav{{releaseNoteDay.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">
               <div  class="monthDates secdiv" id="sectionnav{{releaseNoteDay.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">{{releaseNoteDay.monthName}} {{releaseNoteDay.day}}</div>
               <div class="secdiv sectionnav"><code class="code secdiv">{{releaseNoteDay.versionName}}</code></div>
              </div>
              <div class="releaseIssueWrapper">
                <div class="IssuesWrapper">
                 <span class="note-data" [innerHTML]="releaseNoteDay['newCommentFeature'] | safe: 'html'"></span>
                 </div>
               <br>
               <div class="IssuesWrapper">
                 <span class="note-data" [innerHTML]="releaseNoteDay['improveSprint'] | safe: 'html'"></span>
                 </div>
               <br>
               <div class="IssuesWrapper">
                 <span class="note-data" [innerHTML]="releaseNoteDay['bugFixes'] | safe: 'html'"></span>
                 </div>
               <br>
              <div class="IssuesWrapper">
                 <span class="note-data" [innerHTML]="releaseNoteDay['ubmNote'] | safe: 'html'"></span>
                 </div>
              </div>
               </div> 
           </div>
            
            
        </div>
       </div>
      </div>
    </div>
    <div class="footer-box-body">
      <div class="footer-copyright text-center">Copyright © <a style="color:#5d9cec" href="https://www.utiliko.io " target="_blank">Utiliko - Business Management Platform.</a> All rights reserved.
      </div>
    </div>
     </div>
    </div>
  </div>