<div class ="billing-dialog-modules">
  <div mat-dialog-title class="modal-title">Processing Payment of {{request.total | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}
    <div class="pull-right">
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="row">
      <mat-radio-group class="example-radio-group">
        <div class="col-md-6 default-options">
          <div class="defaultCardsTitle clearfix">
            <div style="margin-top:5px;">
              <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9">
                <span class="tsys-dialog-title">Your saved credit and debit cards</span>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
                <p class="tsys-dialog-title">Expires On</p>
              </div>
            </div>
          </div>
          <hr style="clear: both;margin: 0;">
          <div class="row defaultCards clearfix">
            <div class="scroll-div">
              <div>
                <form [formGroup]="cvvForm" novalidate>
                  <div style="max-height: 200px">
                    <mat-radio-group class="example-radio-group" formControlName="cardType" [(ngModel)]="setValue">
                      <div *ngIf="data.paymentInfo.length > 0">
                        <div *ngFor="let cardInfo of data.paymentInfo; let i index">
                          <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9 excluedepad">
                            <span class="cvv-back" *ngIf="cardInfo.cardType">
                              <mat-radio-button class="example-radio-button-new" formControlName="paymentId"
                                ngDefaultControl [value]="cardInfo.paymentInfoId"
                                (click)="setPaymentInfo(cardInfo.paymentInfoId,cardInfo.isDefaultCard)"
                                [checked]="cardInfo.isDefaultCard == '1' && showdefault">
                                <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9 cardname-type"><b class="card-type">{{cardInfo.cardType
                                        | uppercase}} </b>
                                  <p class="credit-card-info"
                                    *ngIf="cardInfo.cardType == 'Mastercard' || cardInfo.cardType == 'American Express'">
                                    ending with
                                    {{cardInfo.creditCardNumber}}</p>
                                  <span
                                    *ngIf="cardInfo.cardType == 'VISA' ||cardInfo.cardType == 'Discover' || cardInfo.cardType == 'JCB'">
                                    ending with
                                    {{cardInfo.creditCardNumber}}</span>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3">
                                  <input type="text"
                                    *ngIf="cvvForm.controls['cardType'].value === cardInfo.paymentInfoId || (cardInfo.isDefaultCard == 1 && !showCheckbox)"
                                    placeholder="CVV" class="cvv" formControlName="cvv"
                                    (keyup)="checkCVC($event.target.value)">
                                </div>
                              </mat-radio-button>
                            </span>
                          </div>
                          <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 excluedepad">
                            <p class="cvv-back1" *ngIf="cardInfo.cardType">
                              <span>{{cardInfo.expirationDate}}</span>
                            </p>
                          </div>
  
                        </div>
                      </div>
                      <div *ngIf="data.paymentInfo.length == 0" class="showMesg">
                        No cards are saved
                      </div>
                    </mat-radio-group>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <span class="default-credit-card-option"></span>
          <div class=" col-md-12 paymentSelectorBtns-checkbox">
            <mat-checkbox *ngIf="showCheckbox && !showOptions" name="isDefaultCard" (change)="defaultCcCheck($event)" id="checkbox3">
                <span class="field_label">Make this credit card as default</span>
              </mat-checkbox>
          </div>
        </div>
        <div class="col-md-6 add-cards">
          <div class="showfileds">
            <div>
              <mat-radio-button class="example-radio-button" [checked]="data.paymentInfo.length == 0 || showOptions"
                (change)="changeOption('new')" value="2">
              <h4 class="tsys-dialog-title title-add-cc">Add Debit/Credit/ATM Card</h4>
            </mat-radio-button>
            </div>
          
            <div [ngClass]="!showOptions ? 'disabled':'enabled'" >
              <html class="html-tsys">
  
              <head>
              </head>
  
              <body style="background:#ffffff;">
                <form method="POST" id="payment-form" class="clearfix">
                  <div class="col-md-12 pl-0">
                    <div class="label-field">Credit Card Number</div>
                    <div id="tsep-cardNumDiv" class="tsys-div"></div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="label-field">Zip Code</div>
                    <div id="tsep-zipcode" class="form-group mb tsys-div">
                      <input #textInput type="text" class="form-control" id="zipcode" name="zipcode" placeholder="00000"
                        (input)="SearchData(textInput.value)">
                    </div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="label-field">Exp Date</div>
                    <div id="tsep-datepickerDiv" class="tsys-div"></div>
                  </div>
                  <div class="col-md-3 pl-0">
                    <div class="label-field">CVV</div>
                    <div id="tsep-cvv2Div" class="tsys-div"></div>
                  </div>
                  <div id="div_console"></div>
                </form>
              </body>

              </html>
            </div>

          </div>
            <div class="card-checkboxes" *ngIf="showOptions">
              <div class="text-info">
                <i class="fa fa-info-circle"></i>
                <span>&nbsp; Hit the tab button or click outside the input box after filling out the CVV field.</span>
              </div>
  
              <!-- make card as default for first card -->
              <mat-checkbox name="isDefaultCard" (change)="defaultCcCheck($event)" 
                *ngIf="!isSavedCard && saveDefault && !isDefaultAvailable" [checked]="true"
                [disabled]="true" >
                <span class="field_label">Make this credit card as default</span>
              </mat-checkbox>
              <!-- make card as default for future card -->
              <mat-checkbox name="isDefaultCard" (change)="defaultCcCheck($event)" 
                *ngIf="!isSavedCard && saveDefault && isDefaultAvailable">
                <span class="field_label">Make this credit card as default</span>
              </mat-checkbox>

              &nbsp;&nbsp;
              <mat-checkbox name="isDefaultCard" *ngIf="!isSavedCard" (change)="saveCcCheck($event)" [checked]="true"
                [disabled]="true">
                <span class="field_label">Save card for future transactions</span>
              </mat-checkbox>
            </div>
 
        </div>
      </mat-radio-group>
    </div>
    <div class="col-md-12 paymentSelectorBtns">
        <div class="pull-right">
          <button mat-raised-button color="primary" *ngIf="isSavedCard" [disabled]="!activateBtn"  (click)="selectCC()"
          class="enable-btn">
          Pay
        </button>
        <button mat-raised-button color="primary" (click)="addUserCC()" *ngIf="!isSavedCard" [disabled]="currentStatus == '2'"
        [ngClass]="saveDefault ? 'enable-btn save-pay' : 'enable-btn'">{{btnText}}</button>
      </div>
    </div>
  </div>
</div>
