<div *ngIf = "!error" #docsign id = "loader-docsign" class = "adminView loader-docsign">

  <div class = "view_header">
    <mat-toolbar class = "themeColor" [ngClass] = "themeData">
      <img src = "{{getpdfdata?.companyLogo}}" onError = "this.src = '/assets/img/default_company_logo.png'">
      <span *ngIf = "themeData  === 'green-light'||themeData === 'green-dark'" class = "headerName themeColor"
        [ngClass] = "themeData" style = "color: black">{{getpdfdata?.companyName}} </span>
      <span *ngIf = "themeData !==  'green-light'&& themeData !== 'green-dark'" class = "headerName themeColor"
        [ngClass] = "themeData" style = "color: white">{{getpdfdata?.companyName}}</span>
    </mat-toolbar>

  </div>
  <div id = "doc-detail-content" class = "clearfix">
    <!--Icons options for placing box inside pdf -->
    <div class="pdf-action-btn" *ngIf = "getpdfdata?.signed === 0 &&  getpdfdata.isOwner === 0">
        <i class = "fa fa-highlighter initial-style" (click) = "isinitial()" matTooltip = "initial"
          matTooltipPosition  =  "above"></i>
        <i (click) = "isdate()" class = "far fa-calendar-alt icon-calender date-style" matTooltip = "Date"
          matTooltipPosition = "above"></i>
        <i (click) = "opensign()" class = "fa fa-file-signature signature-style" matTooltip = "Signature"
          matTooltipPosition = "above"></i>
        <i class = "fa fa-text-height text-style" (click) = "isdescript()" matTooltip = "text" matTooltipPosition = "above"></i>
    </div>
    <!--Display pdf inside the html start here -->
    <div style = "width: 100%;" id = "printPage">
      <button *ngIf = "getpdfdata?.signed === 0 && getpdfdata.isOwner === 0 && !merged " mat-raised-button class = "buttons-wrapper" color = "primary" (click) = "mergePdf()">Submit</button>
      <mat-card *ngIf = "isSrcLoaded" id = "pdfSend" class = "pdf-wrapper" [ngClass]="getpdfdata?.signed === 0 && getpdfdata.isOwner === 0 && !merged?'':'center-align'">
        <div *ngFor="let url of fileURL">
          <pdf-viewer id='contentToConvert' [src]="url" style="display: block;" [original-size]="false" [fit-to-page]="true"
            (after-load-complete)="callEditPdf($event)">
          </pdf-viewer>
        </div>
          <!-- Multiple box for each option start here -->
        <form>

          <!-- Signature option-->
          <div *ngIf = "issign " id = "containment-wrapper">
            <div *ngIf = " getpdfdata?.isOwner == 0">
              <div *ngFor = "let newinput of newinput; let i  =  index" class = "ui-widget-content draggable-sign"
                id = "singnatureBox" (cdkDragEnded) = "dragEnd($event,i)" cdkDrag>
                <img *ngIf = "isImage " src = "assets\img\signature-with-a-pen.svg" class="img">
                <button type = "button" style="margin-top:32px;" class = "close" (click) = "removeAddress(i)" aria-label = "Close">
                  <span aria-hidden = "true" [ngClass] = "isImage  ?  'close-sign' : 'close-data'">&times;</span>
                </button>
              </div>
            </div>
          </div>

          <!--Description option-->
          <div *ngIf = "isdesc" id = "containment-wrapper">
            <div *ngIf = "getpdfdata?.isOwner == 0">
              <div *ngFor = "let newdesc of newdesc; let i  =  index" class = "ui-widget-content draggable-desc"
                id = "singnaturedesc" (cdkDragEnded) = "dragEnddesc($event,i)" cdkDrag>
                <!-- <textarea *ngIf = "IsValue" [(ngModel)] = "newdesc.desc" name = "desc_{{newdesc.id}}" type = "text"
                  class = "desc-value" (keyup) = "autogrow()" id = "textarea">
                </textarea> -->
                <button type = "button" class = "close" (click) = "removedesc(i)" aria-label = "Close">
                  <span aria-hidden = "true" class = "close-data">&times;</span>
                </button>
              </div>
            </div>
          </div>

          <!--Initial option-->
          <div *ngIf = "isInitial" id = "containment-wrapper">
            <div *ngIf = "getpdfdata?.isOwner == 0">
              <div *ngFor = "let initial of initial; let i  =  index" class = "ui-widget-content text-filed"
                (cdkDragEnded) = "dragEndinitial($event,i)" id = "singnatureText" cdkDrag>
                <!-- <input *ngIf = "isInitialValue" [ngStyle] = "fontRuleInitial" [(ngModel)] = "initial.initial"
                  name = "initial_{{initial.id}}"  type = "text"
                  class = "initial-value" /> -->
                <button type = "button" class = "close" (click) = "removeinitial(i)" aria-label = "Close">
                  <span aria-hidden = "true" class = "close-data">&times;</span>
                </button>
              </div>
            </div>
          </div>

          <!--Date option-->
          <div *ngIf = "isDate" id = "containment-wrapper">
            <div *ngIf = "getpdfdata?.isOwner == 0">
              <div class = "date-field" (cdkDragEnded) = "dragEnddate($event)" cdkDrag>
                <span class = "sp-date">Date:</span>
                <button type = "button" class = "close" (click) = "removeDate()" aria-label = "Close">
                  <span aria-hidden = "true" class = "close-data">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </form>
          <!-- Multiple box for each option end here -->

   
      
        </mat-card>
    </div>


     <!--Display pdf inside the html end here -->
    <ngx-ui-loader fgsType = "ball-spin-clockwise" fgsColor = "#1976d2" pbThickness = "0" fgsSize = "40"
      overlayColor = "#00000024" [loaderId] = "'loader-docsign'"></ngx-ui-loader>

  </div>
<!-- <div class = "comment-data">
  <div *ngIf = "isComment && getpdfdata?.comment =  =  =  null">
    <mat-card class = "data">
      <textarea   type = "text"  [(ngModel)] = "comment" name = "comment"
      class = "comment-value" (keyup) = "autogrow()" id = "textarea">
    </textarea>
    <button  mat-raised-button class = "comment-button"(click) = "saveComment()">comment</button>
    <button  mat-raised-button class = "comment-button" (click) = "cancelComment()" >cancel</button>
    </mat-card>
  </div>
</div> -->
<!-- <div *ngIf = "!isaddedcomment && getpdfdata?.comment ! = null " class = "comments-container">
 
    <mat-card class = "data">
        <div class = "comment-avatar">
          <img  src = "this.src = 'assets/img/default_avatar.jpg'" >
          <span>{{commenttime | commonDate : 'date_time'}}</span>
        </div>
            <span class = "pull-right">
              <i class = "fa fa-check edit-comment" name = "edit" (click) = "editComment()"></i>
              <i name = "deleteIcon" class = "fai fa-trash-o text-danger delete-comment"(click) = "deleteComment()"></i>
            </span>
        
          <div class = "comment-content">
            <span [innerHTML] = "getpdfdata?.comment | safe: 'html'"></span>
          </div>
          <div *ngIf = "isedit">
            <textarea   type = "text"  [(ngModel)] = "replaycomment" name = "comment"
            class = "comment-value" (keyup) = "autogrow()" id = "textarea">
          </textarea>
          <button  mat-raised-button (click) = "replyComment()">comment</button>
          <button  mat-raised-button (click) = "cancelreplyComment()" >cancel</button>
          </div>
          <div *ngIf = "!isreplay && getpdfdata?.reply! = null">
            <div class = "comment-avatar">
              <img  src = "this.src = 'assets/img/default_avatar.jpg'" >
              <span>{{replycommenttime | commonDate : 'date_time'}}</span>
            </div>
                <span class = "pull-right">
                  <i class = "fa fa-check edit-comment" name = "edit" click = "savereplayComment()"></i>
                  <i name = "deleteIcon" class = "fai fa-trash-o text-danger delete-comment"(click) = "deletereplayComment()"></i>
                </span>
            
              <div class = "comment-content">
                <span [innerHTML] = "getpdfdata?.reply | safe: 'html'"></span>
              </div>
          </div>
        </mat-card>
</div> -->
  <footer class = "page-footer view_footer" style = "padding-top:5px;">
    <div class = "footer-copyright text-left">Copyright© <a style = "color:#5d9cec" href = "http://www.Utiliko.io "
        target = "_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.
      <p class = "alignright">{{version}}</p>
    </div>
  </footer>

</div>
<div *ngIf = "error">
  <div class = "center">
    <p style = "font-size: 50px">Oops!</p>
    <p style = "font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style = "font-size: 15px">Please check your entered Id.</p>
  </div>
</div>

<app-spinner></app-spinner>