
<div class="subscriptionPage clearfix">
  <mat-toolbar >
    <span *ngIf="permissionService.subscription.view || configService.userIsOwner === 1"><a href="https://www.utiliko.io/" target="_blank"> <img style="height:30px;" class="siteLogo" src="../../../assets/images/logo/utiliko-logo.png">
    </a></span>
    <span *ngIf="!permissionService.subscription.view && configService.userIsOwner !== 1">
      <a href="https://www.utiliko.io/" target="_blank"> <img style="height:30px;" class="siteLogo" src="../../../assets/images/logo/utiliko-logo.png">
      </a>
    </span>

    <span class="wizard-logout" (click)="logout()" matTooltip="Sign Out">
        <mat-icon>exit_to_app</mat-icon>
      </span>
  </mat-toolbar>
  <div class="subscription_box" *ngIf="permissionService.subscription.view || configService.userIsOwner === 1">
    <div class="inv-sub-detail-content clearfix">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="radioChange($event)">
            <mat-tab label="Monthly">
                <div class="plan-text col-md-8">Pricing shown below are according to <b>Monthly</b> plan</div>
                <div class="actual-total-amount col-md-4">Monthly Cost: <b>{{actualTotalOfPlan | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</b> including taxes</div>
                <div>
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table" matSort matSortActive="class" matSortDirection="desc"  matSortDisableClear>
                      <ng-container matColumnDef="class">
                        <th mat-header-cell *matHeaderCellDef [style.display]="'none'"></th>
                        <td mat-cell *matCellDef="let element" class="" [style.display]="'none'"></td>
                      </ng-container>
                      <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col0">
                           <input type="checkbox" class="checkSub" (click)="checkModule($event,element.savedItemsId,element)" name="websitecheck" [checked]="element.isChecked" [disabled]="element.unSelectable" value="{{element.savedItemsId}}">
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>App</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
                      </ng-container>
                      <ng-container matColumnDef="active_users">
                        <th mat-header-cell *matHeaderCellDef>Number of Users</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
                          <span *ngIf="element.name !== 'Seats Reserved'">
                            {{element.numberOfUsers}}
                          </span>
                          <span *ngIf="element.name == 'Seats Reserved'">
                            <button [disabled]="(totalSeats==1 || totalSeats == activeUsers)" [ngClass]=" (totalSeats==1 || totalSeats == activeUsers)? 'disableBtn' : ''" color="primary" (click)="subCount($event,i)" class="in-dec-btn dec-btn reduce-qty">
                              <mat-icon>minimize</mat-icon>
                            </button>
                            <input class="numField no-of-users" [min]="activeUsers" type="text" value="{{totalSeats}}" (keyup)="changeSeats($event)" >
                            <button color="primary" (click)="addCount($event,i)"  class="in-dec-btn in-btn add-qty">
                              <mat-icon>add</mat-icon>
                            </button>

                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="cost_pupm">
                        <th mat-header-cell *matHeaderCellDef>Cost per User per Month</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
                          {{element.costPerUPM | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="cost_pm">
                        <th mat-header-cell *matHeaderCellDef>Cost per Month</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
                          {{element.costPerMonth | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="row_tax">
                        <th mat-header-cell *matHeaderCellDef>Tax</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                          {{element.itemTax | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                      </ng-container>
                      <ng-container matColumnDef="row_cost">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                          {{element.totalRowCost | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
            </mat-tab>
            <mat-tab label="Yearly">
                <div class="plan-text col-md-8">Pricing shown below are according to <b >Yearly</b> plan</div>
                <div class="actual-total-amount col-md-4">Yearly Cost: <b>{{actualTotalOfPlan | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</b> including taxes & 10% discount.</div>
              <div>
                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table">
                    <ng-container matColumnDef="class">
                      <th mat-header-cell *matHeaderCellDef [style.display]="'none'"></th>
                      <td mat-cell *matCellDef="let element" class="" [style.display]="'none'"></td>
                    </ng-container>
                    <ng-container matColumnDef="checkbox">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col0">
                         <input type="checkbox" class="checkSub" (click)="checkModule($event,element.savedItemsId)" name="websitecheck" [checked]="element.isChecked" [disabled]="element.unSelectable" value="{{element.savedItemsId}}">
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>App</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="active_users">
                      <th mat-header-cell *matHeaderCellDef>Number of Users</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
                        <span *ngIf="element.name !== 'Seats Reserved'">
                          {{element.numberOfUsers}}
                        </span>
                        <span *ngIf="element.name == 'Seats Reserved'">
                          <button [disabled]="(totalSeats==1 || totalSeats == activeUsers)" [ngClass]=" (totalSeats==1 || totalSeats == activeUsers)? 'disableBtn' : ''" color="primary" (click)="subCount($event,i)" class="in-dec-btn dec-btn reduce-qty">
                            <mat-icon>minimize</mat-icon>
                          </button>
                          <input class="numField no-of-users" [min]="activeUsers" type="text" value="{{totalSeats}}" (keyup)="changeSeats($event)" [formControl]="seat">
                          <button  color="primary" (click)="addCount($event,i)"  class="in-dec-btn in-btn add-qty">
                            <mat-icon>add</mat-icon>
                          </button>
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="cost_pupm">
                      <th mat-header-cell *matHeaderCellDef>Cost per User per Yearly</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
                        {{element.costPerUPM | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="cost_pm">
                      <th mat-header-cell *matHeaderCellDef>Cost per Yearly</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
                        {{element.costPerMonth | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="row_tax">
                      <th mat-header-cell *matHeaderCellDef>Tax</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                        {{element.itemTax | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="row_cost">
                      <th mat-header-cell *matHeaderCellDef>Total</th>
                      <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
                        {{element.totalRowCost | commonDate: 'money_format' | commonDate :'currency_format'}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
            </mat-tab>
          </mat-tab-group>
      <!-- <div class="top_lable_outer">
        <div class="top_lable text-center">
          <div class="subscription-plan">
            <input type="radio" id="control_01" name="participants" name="select" (change)="radioChange(1)" [value]="1"
              [checked]="true">
            <label class="label-check" for="control_01">
              <h5>Monthly</h5>
            </label>
          </div>
        </div>
        <div class="top_lable text-center">
          <div class="subscription-plan">
            <input type="radio" id="control_03" name="select" (change)="radioChange(2)" [value]="2">
            <label class="label-check" for="control_03">
              <h5>Yearly</h5>
            </label>
          </div>
        </div>
      </div> -->


      <!-- <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 inv-table">
          <ng-container matColumnDef="item_name">
            <th mat-header-cell *matHeaderCellDef>Module</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col1"> {{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="active_users">
            <th mat-header-cell *matHeaderCellDef>Number of Users</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col2">
              {{element.numberOfUsers}}</td>
          </ng-container>
          <ng-container matColumnDef="cost_pupm">
            <th mat-header-cell *matHeaderCellDef>Cost per User per Month</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col3">
              {{element.cost_per_u_p_m}}</td>
          </ng-container>
          <ng-container matColumnDef="cost_pm">
            <th mat-header-cell *matHeaderCellDef>Cost per Month</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col4">
              {{element.cost_per_month}}</td>
          </ng-container>
          <ng-container matColumnDef="row_tax">
            <th mat-header-cell *matHeaderCellDef>Tax</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
              {{element.itemTax}}</td>
          </ng-container>
          <ng-container matColumnDef="row_cost">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let element" class="inv-item-table {{element.class}}_col5">
              {{element.total_row_cost}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div> -->
      <div class="inv-foot-detail clearfix">
        <div class="inv-notes col-md-7" *ngIf="isMonthly"><span class="text-danger">*</span> You are getting charged for <b>{{remainingDaysToEndMonth}} days</b> of this month & your monthly payment cycle will start at 1st of next month.</div>
        <div class="inv-payment-detail col-md-5 pull-right">
          <div class="inv-payment-sub-detail">
            <div>
              <div>
                <div class="left-panel">Sub Total</div>
                <div class="right-panel">{{sTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel">Tax</div>
                <div class="right-panel">{{displaytax | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel">Discount</div>
                <div class="right-panel">{{displaydiscount | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
            <div>
              <div>
                <div class="left-panel last">Total</div>
                <div class="right-panel last">{{displaytotal | number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay-now col-md-7">
          <button mat-raised-button color="primary" (click)="subscriptionDialog()" >Pay Now To Start Using
            Utiliko</button>
        </div>
      </div>

    </div>
  </div>
  <div class="background-clr" *ngIf="!permissionService.subscription.edit && configService.userIsOwner !== 1">
    <div class="subcError">
      <h2 style="text-align: center;">
        You do not have subscription permission<br/>
        Please contact your administrator to create or renew subscription<br/><br/>
        <i style="color: #f05050;" class="fa fa-exclamation-triangle fa-5x" aria-hidden="true"></i></h2>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
