
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
<div class="api-docs">
<h2 class="content-header-name">Lead Status Model</h2>
 <p><i><code>Add</code></i></p> 
<ul>
    <li>
            This API is used to create a new lead status into `tbl_lead_status`. 

    </li>
    <li>
            For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_add"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_add</u></a> 

    </li>
</ul>
<br />
  <p><i><code>Delete</code></i></p> 
  <ul>
      <li>
            It will delete the lead status from `tbl_lead_status` by leadStatusId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_delete"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_delete</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>List</code></i></p> 
  <ul>
      <li>
            This API will fetch all the lead status list from `tbl_lead_status` for dropdown purpose. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_list"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_list</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>listById</code></i></p> 
  <ul>
      <li>
            It will fetch the details of particular lead status from `tbl_lead_status` by leadStatusId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_listById"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_listById</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
      <li>
            This API is used to modify the lead status name and lead type into `tbl_lead_status` by leadStatusId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/LeadStatus/LeadStatus_modify"><u>{{path}}explorer/#!/LeadStatus/LeadStatus_modify</u></a> 

      </li>
  </ul>
</div>
</mat-card>

