
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Purchase Order Model</h2>
 <h4>Commonly used methods in invoice:</h4>
 <ul>
     <li>
            getPurchaseOrderNo method is used to fetch the last created purchaseorderId. 

     </li>
     <li>
            purchaseOrderReferenceNoExists method will create new purchase order reference number(unique) with po_prefix from `tbl_config` and last created poId+1. If referenceNo exists, it will generate new number else it will return the generated refNo. 

     </li>
 </ul> 
 <br />  
     <p><i><code>Add</code></i></p> 
     <ul>
         <li>
                This API is used to create new purchase order into `tbl_purchase_order` with vendorId. 

         </li>
         <li>
                Before creating a purchase order getPurchaseOrderNo and purchaseOrderReferenceNoExists method will be called. 

         </li>
         <li>
                After creation of PO, it will execute the steps below: 
                <ul>
                    <li>
                            If itemInfo exists, item details will be saved into `tbl_po_items` with poId and totalSalesPrice value updated into `tbl_chart_of_accounts` through iteration. 

                    </li>
                    <li>
                            Once the iteration has been completed, totalAmount for the purchase order will be calculated using calculateTo method. 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_add"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_add</u></a> 

         </li>
     </ul>
     <br />
     <p><i><code>Delete</code></i></p>
     <ul>
         <li>
                This API is used to delete the purchase order from `tbl_purchase_order` by using <strong>purchaseOrderIds</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_delete"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_delete</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>Details</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of purchase order from `tbl_purchase_order` by using <strong>purchaseOrderId</strong>. 

         </li>
         <li>
                getPurchaseOrderDetails method is used to fetch the PO details. 

         </li>
         <li>
                getPurchaseOrderDetails: 
                <ul>
                    <li>
                            First, it will fetch the basic details of purchase order. 

                    </li>
                    <li>
                            The following methods have been used to get poCost, taxAmount, discount etc.,  

                    </li>
                    <li>
                            <i><code>getPoCost</code></i> - it will fetch the <strong>total_cost</strong> of the purchase order using poId from `tbl_po_items`. 

                    </li>
                    <li>
                            <i><code>getPoTaxAmount</code></i> - it will fetch the <strong>SUM(item_tax_total)</strong> from `tbl_po_items` by poId. 

                    </li>
                    <li>
                            <i><code>getPoDiscount</code></i> - it will fetch the discountTotal from `tbl_purchase_order`. 

                    </li>
                    <li>
                            <i><code>getItemsData</code></i> - it will fetch the items present for that purchase order from `tbl_po_items` by <strong>purchaseOrderId</strong>. 

                    </li>
                    <li>
                            <i><code>getPoPaidAmount</code></i> - it will fetch the <strong>SUM(amount)</strong> from `tbl_payments` by <strong>purchaseOrderId</strong>. 

                    </li>
                    <li>
                            <i><code>getPoDueAmount</code></i> - it will return due amount of the <strong>purchaseOrderId</strong> by calling <i><code>getRefundsAmount</code></i> , <i><code>getPoDiscount</code></i> , <i><code>getPoCost</code></i> and <i><code>getPoPaidAmount</code></i>. 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_details"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_details</u></a> 

         </li>
     </ul>
     <br />
     <p><i><code>Email</code></i></p> 
     <ul>
         <li>
                This API is used to send an email of the purchase order using shootEmail method. Once email sent successfully, <strong>'emailed'</strong>: <strong>'Yes'</strong> will be updated in `tbl_purchase_order` for the given poId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_email"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_email</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of purchase order from `tbl_purchase_order`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_list"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listByVendor</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of purchase order by providing vendorId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_listByVendor"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_listByVendor</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p>
     <ul>
         <li>
                This API is used to modify the details of purchase order into `tbl_purchase_order` by using poId and referenceNo. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_modify"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_modify</u></a> 

         </li>
     </ul> 
 <br />  
     <p><i><code>poDetailPdf</code></i></p> 
     <ul>
         <li>
                This API is used to generate the purchase order detail page in pdf format using <i><code><strong>require</strong></code></i>(<strong>'html-pdf'</strong>). 
         </li>
         <li>
                Eg: https://storage.googleapis.com/milagro-test/vivant%2FPO%2FE%3A%2FUtiliko%2Futiliko_API%2F-%2Fvendor%2Fundefined%2FpurchaseOrder%2F%2F1561374247435-undefined_purchaseOrder_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4671774252&amp;Signature=S6eX8Jp35UWnldOnmMy-iYemK0xIvV1ZeI7yyB6%2B7ADUPNgKKu16pytvjzNDAxS8KaEfIEB4TxHoGhl64gKxsw7gEbiuqXhqWW1-7OomtdGdNxgDld7ReYFJO1qixHxL%2Bb05u2PuUpkYz6h513Re89A%2BEfQ9sL43ub70F-NQlX4VCDsDyXVRFaOW6EnK8T7LJH43z4sngYhfDOVA4wc-EjXa2vwlCrkP6FJnWYx6Gq0CMUBsggGS6vnuYJa7ktNDKW%2Bz433kUxhJm%2FeZJEPi046QbOvHnp43yezehN794AmFgH98G-Lgv4di1zc3MXhYyabMohVVaB3tFZLVw5PKF%2BEEP2A%3D%3D 

         </li>
         <li>
                Template for the purchase order details page will fetched from the `tbl_email_templates` using emailGroup: &lsquo;<strong>po_detail_pdf</strong>&rsquo;. 

         </li>
         <li>
                uploadFilesTOCloud method is used here. 

         </li>
         <li>
                Fs.unlink method will delete file from local path. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_poDetailPdf"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_poDetailPdf</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>updateStatus</code></i></p> 
     <ul>
         <li>
                This API is used to change the status of purchase orders by status type(<strong>received_in_full, accepted</strong>) and poId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_updateStatus"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_updateStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>View</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of purchase order by providing the poId in decrypted form. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/purchaseOrder/purchaseOrder_view"><u>{{path}}explorer/#!/purchaseOrder/purchaseOrder_view</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
