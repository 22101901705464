<div class="presence-monitoring">
  <div class="row">
    <div class="col-md-11 col-sm-10 col-xs-10">
      <h1 mat-dialog-title class="pm-header-label">Presence Monitoring</h1>
    </div>
    <div class="col-md-1 col-sm-2 col-xs-2 text-right">
      <button class="person-monitoring-close text-right" (click)="closeDiv()">
        <i class="material-icons">clear</i>
      </button>
    </div>
  </div>
  <mat-divider class="pm-divider"></mat-divider>
  <div class="person-monitoring-overflow">
    <div mat-dialog-content class="person-monitoring-r">
      <div class="person-monitoring-line" *ngFor="let data of dataSource; trackBy:trackByUserId">
        <div class="pm-img">
          <img class="pm-img-img" src="{{data.avatar}}" onError="this.src='/assets/img/default_avatar.jpg'">
          <span [ngClass]="data.activated == 1 ? 'pm-active-logo' : 'pm-active-logo-red' "></span>
        </div>
        <div class="pm-name">
          <p class="pm-font-weight-bold">{{data.fullname}}</p>
          <p class="pm-dept">{{data.department}} </p>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button class="btn-cancel" (click)="closeDiv()">Close</button>
  </div>
</div>