<div class="row releaseNote-section">
  <section>
 <header class="header vx_globalNav-main" id="header">
     <div class="row">
         <div id="rowshadow">
         <div class="contentheader header">
           <h1 class="headerName">
             <mat-icon>notes</mat-icon>Release Notes
           </h1>
         </div>
         </div>
       </div>
   </header>
 </section>        
<mat-card class="mainCard">
 <div class="">
  <main class="dxmain"> 
     <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup (selectedTabChange)="tabChanged($event)"> 
         <mat-tab label="Release Note">  
   <div class="side-content">  
   <ul class="yearClass">                  
         <li class="tip-nav-active-parent">  
            <div *ngFor='let releaseNoteYear of  finaljsonarray; index as j;'> 
             <a href="/admin/docs/release-notes#sectionnav{{releaseNoteYear.year}}"  (click)="tabClick($event,{year:releaseNoteYear.year})" class="activeclass sec parentYear{{releaseNoteYear.year}} sectionnav sectionnav{{releaseNoteYear.year}}">{{releaseNoteYear.year}}</a>
           <ul class="monthClass">
             <li  class="tip-nav-hash   tip-nav-active-parent">
               <div *ngFor='let releaseNotesMonth of releaseNoteYear.montharr;index as i'>
                 <a href="/admin/docs/release-notes#sectionnav{{releaseNotesMonth.monthName}}" (click)="tabClick($event,{year:releaseNoteYear.year,month:releaseNotesMonth.monthName})" 
                 class="activeclass sectionnav sectionnav{{releaseNotesMonth.monthName}}">{{releaseNotesMonth.monthName}}</a>
               <ul class="subMonth">
                 <li class="tip-nav-hash tip-nav-active">
                    <div *ngFor='let releaseNoteDay of releaseNotesMonth.daydata;index as j'>
                   <a href="/admin/docs/release-notes#sectionnav{{releaseNotesMonth.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}" (click)="tabClick($event,{year:releaseNoteYear.year,month:releaseNotesMonth.monthName,day:releaseNoteDay.day,version:releaseNoteDay.versionName})"
                       class="activeclass sectionnav sectionnav{{releaseNotesMonth.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">{{releaseNoteDay.monthName}} {{releaseNoteDay.day}}<code class="code">{{releaseNoteDay.versionName}}
                      </code></a>
                      </div>
                   </li>   
               </ul>
              </div>
               </li>
                 </ul>  
              </div>  
            </li>   
           </ul>   
       
      </div>    
 
    <!-- Right Div-->
      <div id="releaseDetailsWrapper" (scroll)="scorllData($event)"> 
           <div id="version">  
             <div *ngFor='let releaseNote of finaljsonarray'>
              <div *ngFor='let releaseNoteMonth of releaseNote.montharr;index as p'>
               <div  class="yearborder sectionnav{{releaseNote.year}}"> 
               <span><h4  class="sectionnav sectionnav{{releaseNote.year}} secdiv" id="sectionnav{{releaseNote.year}}">{{releaseNoteMonth.monthName}} Release Notes {{releaseNote.year}}</h4></span>
            </div>
            <div class="releaseMonths secdiv sectionnav{{releaseNoteMonth.monthName}}" id="sectionnav{{releaseNoteMonth.monthName}}">
              <div class="sectionnav secdiv" id="sectionnav{{releaseNoteMonth.monthName}}">{{releaseNoteMonth.monthName}}</div>
            </div>
              <div>
             <div class="releaseDate"> 
              <div class="clearfix Icon-wrapper" *ngFor='let releaseNoteDay of releaseNoteMonth.daydata;index as k'>
                <div class="releaseWrapper secdiv sectionnav" id="sectionnav{{releaseNoteDay.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">
                <div  class="monthDates secdiv" id="sectionnav{{releaseNoteDay.monthName}}{{releaseNoteDay.day}}{{releaseNoteDay.versionName | stripDots}}">{{releaseNoteDay.monthName}} {{releaseNoteDay.day}}</div>
                <div class="actionIcon"> 
                  <a class="btn btn-sm btn-action"> 
                        <div class="ripple" matTooltip="Edit" (click)="changeUpdate(releaseNoteDay.id)">
                          <i class="far fa-edit edit-icon"></i>
                        </div></a>
                       <a class="btn btn-sm">
                        <div class="ripple"  matTooltip="Delete" (click)="deleteNote(releaseNoteDay.id,releaseNoteDay.year,releaseNoteDay.monthName)">
                        <i class="fai fa-trash-o text-danger fa-lg "></i>
                        </div></a>
                </div> 
                <div class="secdiv sectionnav"><code class="code secdiv">{{releaseNoteDay.versionName}}</code></div>
               </div>
            <div class="releaseIssueWrapper">
              
               <!-- <div class="IssuesWrapper">
                   <span class="note-data" [innerHTML]="releaseNoteDay['ubmFixGitNewup'] | safe: 'html'"></span>
                   </div>
                 <br> -->
                 <div class="IssuesWrapper">
                  <span class="note-data" [innerHTML]="releaseNoteDay['newCommentFeature'] | safe: 'html'"></span>
                  </div>
                <br>
                <div class="IssuesWrapper">
                  <span class="note-data" [innerHTML]="releaseNoteDay['improveSprint'] | safe: 'html'"></span>
                  </div>
                <br>
                <div class="IssuesWrapper">
                  <span class="note-data" [innerHTML]="releaseNoteDay['bugFixes'] | safe: 'html'"></span>
                  </div>
                <br>
               <div class="IssuesWrapper">
                  <span class="note-data" [innerHTML]="releaseNoteDay['ubmNote'] | safe: 'html'"></span>
                  </div>
                </div>
                </div> 
            </div>
             
             
         </div>
        </div>
        </div>
           </div>
      </div>
         </mat-tab>
       <mat-tab [label]="!isChanged ? 'Add Release Notes' : 'Update Release Notes'">
        <ng-template matTabContent>
         <form  class="form-material releaseForm" [formGroup]="createReleaseForm">  
            <div class="field_group">
              <div class="col-md-2">
                <span class="field_label">Release Note Information</span> 
              </div>
              <div class="col-md-10 releaseInfo">
                <div class="col-md-4">
                    <div>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input  
                               matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)" name="releaseDate" formControlName="releaseDate" (click)="picker.open()" (keypress)="isNumberKey($event)" required>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-label>select date</mat-label>
                              <mat-datepicker #picker ></mat-datepicker>
                          </mat-form-field> 
                      </div> 
                </div> 
                <div class="col-md-4">
                    <div class="form-group form-default form-static-label" style="padding-top: 4px;">
                        <ng-select bindLabel="label" bindValue="value" required class=" custom-select" material  [items]="responsibleUserId"
                          placeholder="Responsible User *" formControlName="responsibleUsers" [ngClass]="reposeuser  ? 'errorClass' :'noError'"
                          class="custom-select" 
                          customSearchEnabled="true">
                        </ng-select> 
                      </div>
                </div>
                <div class="col-md-4" id="versionName">
                  <mat-form-field class="example-full-width" appearance="outline" [ngClass]="isvalidVersion == 1 ? 'versionErrorClass' :''">
                    <input type="text" placeholder="Version name" aria-label="Number" autocomplete="off"
                           matInput name="" formControlName="versionName"  (change)="getvalidVersion($event)" (keypress)="isNumberKey($event)" required>
                    <mat-label>version name</mat-label>
                  </mat-form-field>
                </div>
               </div>              
            </div>
            <div class="field_group">
            <div class="col-md-2">
              <span class="field_label">Maintenance Information</span> 
             </div>
              <div class="col-md-10 releaseInfo">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label >Start Time </mat-label>
                        <input matInput value="current"   autocomplete="off" placeholder="Start Time*" [owlDateTimeTrigger]="dt8" formControlName="startTime"
                               [owlDateTime]="dt8" required>
                        <owl-date-time [pickerType]="'timer'" #dt8></owl-date-time>
                    </mat-form-field>                  
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label >End Time </mat-label>
                        <input matInput value="current" autocomplete="off" placeholder="End Time*" [owlDateTimeTrigger]="dt9" formControlName="endTime"
                               [owlDateTime]="dt9" required>
                        <owl-date-time [pickerType]="'timer'" #dt9></owl-date-time>
                    </mat-form-field>                   
                </div>
                <div class="col-md-4"></div>
              </div>
              <div class="col-md-2"></div>
                <div class="col-md-10 releaseInfo">
                  <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Maintenance Title</mat-label>
                    <textarea matInput placeholder="Maintenance Title*" formControlName="title" required></textarea>
                  </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline">
                      <mat-label>Maintenance Message</mat-label>
                      <textarea matInput placeholder="Maintenance Message*" formControlName="Message" required></textarea>
                    </mat-form-field>
                    </div>
                    <div class="col-md-4"></div>
               </div>
            </div>
            <div class="field_group">
             <div class="col-md-2">
               <span class="field_label">Notification</span>
             </div>
             <div class="col-md-10 releaseInfo">
               <div class="col-md-2">
                 <div>
                   <mat-checkbox formControlName="api">API</mat-checkbox>
                 </div>
               </div>
               <div class="col-md-2">
                 <div>
                   <mat-checkbox formControlName="ui">UI</mat-checkbox>
                 </div>
               </div>
               <div class="col-md-2">
                 <div>
                   <mat-checkbox formControlName="mobile">Mobile</mat-checkbox>
                 </div>
               </div>
             </div>
           </div>
           <div class="field_group">
            <div class="col-md-2">
              <span class="field_label">Upload Main Image</span>
            </div>
            <div class="col-md-10 releaseInfo">
              <div class="col-md-2">
              <input type="file" (change)="onFileChanged($event)">
            </div>
          </div>
           </div>

              <div class="field_group">
                <div class="col-md-2">
                 <label class="field_label">New Comments Features</label>
                </div>
                <div class="col-md-10">
                  <div class="card-tasksection">
                    <div class="form-group form-default form-static-label">
                      <textarea appTinymceConfig [tinymceSettings]="tinyEditorConfig" name="new-comment-feature" 
                      formControlName="newCommentFeature" id="editor-new-comment"></textarea>
                    </div>
                  </div>
                </div>
                <!-- <div class="field_group" *ngIf="!isChanged" > -->
                  <div class="col-md-2">
                    <span class="field_label">Upload Image for New Comments Features</span>
                  </div>
                  <div class="col-md-10 releaseInfo">
                    <div class="col-md-2">
                    <input type="file" (change)="onSecondFileUpload($event)">
                  </div>
                </div>
              <!-- </div> -->
              </div>

              <div class="field_group">
                <div class="col-md-2">
                 <label class="field_label">Improved Sprint Features</label>
                </div>
                <div class="col-md-10">
                  <div class="card-tasksection">
                    <div class="form-group form-default form-static-label">
                      <textarea appTinymceConfig [tinymceSettings]="tinyEditorConfig" formControlName="improveSprint" name="improve-sprint" id="editor-improve-sprint"></textarea>
                    </div>
                  </div>
                </div>
                <!-- <div class="field_group" *ngIf="!isChanged" > -->
                  <div class="col-md-2">
                    <span class="field_label">Upload Image Improved Sprint Features</span>
                  </div>
                  <div class="col-md-10 releaseInfo">
                    <div class="col-md-2">
                    <input type="file" (change)="onThierdFileUpload($event)">
                  </div>
                </div>
              <!-- </div> -->
              </div>

              <div class="field_group">
                <div class="col-md-2">
                 <label class="field_label">More Bug Fixes</label>
                </div>
                <div class="col-md-10">
                  <div class="card-tasksection">
                    <div class="form-group form-default form-static-label">
                      <textarea appTinymceConfig [tinymceSettings]="tinyEditorConfig" formControlName="bugFixes" name="bug-fixes" id="editor-bug-fixes"></textarea>
                    </div>
                  </div>
                </div>
                <!-- <div class="field_group" *ngIf="!isChanged"> -->
                  <div class="col-md-2">
                    <span class="field_label">Upload Image for More Bug Fixes</span>
                  </div>
                  <div class="col-md-10 releaseInfo">
                    <div class="col-md-2">
                    <input type="file" (change)="onFourthFileUpload($event)">
                  </div>
                </div>
              <!-- </div> -->
              </div>

            <div class="field_group">
                  <div class="col-md-2">
                   <label class="field_label">Release Note Tenant</label>
                  </div>
                  <div class="col-md-10">
                    <div class="card-tasksection">
                      <div class="form-group form-default form-static-label">
                          <textarea appTinymceConfig [tinymceSettings]="tinyEditorConfig" name="ubm-note" formControlName="ubmNote" id="editor-ubm-note"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field_group">
                  <div class="col-md-2">
                    <mat-checkbox style="float: right;" formControlName="mailNotification" [(ngModel)]="checked"></mat-checkbox> 
                  </div>
                  <div class="col-md-10">
                    <div class="card-tasksection">
                      <div class="form-group form-default form-static-label">
                        <label> Send email notification for this release </label><br>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
            <div class="form-buttons"> 
              <button mat-raised-button class="btn-submit form-button-40" *ngIf="!isChanged" type="submit" (click)="saveTask()">Save</button>
              <button mat-raised-button class="btn-submit form-button-40" *ngIf="isChanged" type="submit" (click)="updateReleaseNote(updateId)">Update Note</button>
              <button mat-button class="cancel form-button-40" type="reset" (click)="cancelForm()">Cancel</button>
            </div>
            </form>
        </ng-template>
       </mat-tab>

       <mat-tab label="All Release Notes Notifications">
      
    
        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field> -->
        
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
        
            <ng-container matColumnDef="Version Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Version Name </th>
              <td mat-cell *matCellDef="let row"> {{row.versionName}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Release Date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Release Date </th>
              <td mat-cell *matCellDef="let row"> {{row.releaseDate}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Time">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
              <td mat-cell *matCellDef="let row"> {{row.startTime}} - {{row.endTime}} </td>
            </ng-container>
        
            <ng-container matColumnDef="Created Date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
              <td mat-cell *matCellDef="let row"> {{row.createdDate}} </td>
            </ng-container>

            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let row">

              <a class="btn btn-sm btn-action" (click)="updateNotification(row.id)" matTooltip="Edit">
                <div class="ripple">
                    <i class="far fa-edit edit-icon"></i>
                </div>
            </a>
            <a class="btn btn-sm btn-action"  (click)="deleteNotification(row.id)" matTooltip="Delete">
                <div class="ripple">
                    <i class="fai fa-trash-o text-danger fa-lg " aria-hidden="true"></i>
                </div>
            </a>
          </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

        </mat-tab>

       <mat-tab label="{{title}} Release Notes Notification">
        <form  class="form-material releaseForm" [formGroup]="notificationForm">  
        <div class="field_group">
          <div class="col-md-2">
            <span class="field_label">Release Note Information</span> 
          </div>
          <div class="col-md-10 releaseInfo">
            <div class="col-md-4">
                <div>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <input  
                           matInput [matDatepicker]="picker1" (dateChange)="addEvent1('change', $event)" name="releaseDate" formControlName="releaseDate1" (click)="picker1.open()" (keypress)="isNumberKey($event)" required>
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-label>select date</mat-label>
                          <mat-datepicker #picker1 ></mat-datepicker>
                      </mat-form-field> 
                  </div> 
            </div> 
            <div class="col-md-4" id="versionName">
              <mat-form-field class="example-full-width" appearance="outline" [ngClass]="isvalidVersion == 1 ? 'versionErrorClass' :''">
                <input type="text" placeholder="Version name" aria-label="Number" 
                       matInput name="" formControlName="versionName1" (keypress)="isNumberKey($event)" required>
                <mat-label>version name</mat-label>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <div>
                  <mat-form-field class="example-full-width" appearance="outline">
                      <input  
                         matInput [matDatepicker]="picker2" (dateChange)="addEvent1('change', $event)" name="releaseDate" formControlName="createdDate" (click)="picker2.open()" (keypress)="isNumberKey($event)"  [value]="today" [max]="tomorrow"[min]="minDate" [disabled]="isDesable">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-label>Created date</mat-label>
                        <mat-datepicker #picker2 ></mat-datepicker>
                    </mat-form-field> 
                </div> 
          </div> 
           </div>  
        </div>
        <div class="field_group">
        <div class="col-md-2">
          <span class="field_label">Maintenance Information</span> 
         </div>
          <div class="col-md-10 releaseInfo">
            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label >Start Time </mat-label>
                    <input matInput value="current"   autocomplete="off" placeholder="Start Time*" [owlDateTimeTrigger]="dt2" formControlName="startTime1"
                           [owlDateTime]="dt2" required>
                    <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>
                </mat-form-field>                  
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label >End Time </mat-label>
                    <input matInput value="current" autocomplete="off" placeholder="End Time*" [owlDateTimeTrigger]="dt3" formControlName="endTime1"
                           [owlDateTime]="dt3" required>
                    <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
                </mat-form-field>                   
            </div>
            <div class="col-md-4"></div>
          </div>
          <div class="col-md-2"></div>
            <div class="col-md-10 releaseInfo">
              <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Maintenance Title</mat-label>
                <textarea matInput placeholder="Maintenance Title*" formControlName="title1" required></textarea>
              </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Maintenance Message</mat-label>
                  <textarea matInput placeholder="Maintenance Message*" formControlName="Message1" required></textarea>
                </mat-form-field>
                </div>
                <div class="col-md-4"></div>
           </div>
        </div>
        <div class="form-buttons"> 
          <button *ngIf='!editNot' mat-raised-button class="btn-submit form-button-40"  type="submit" (click)="saveNotification()">Save</button>
          <button *ngIf='editNot' mat-raised-button class="btn-submit form-button-40"  type="submit" (click)="updateNotificationById()">Update</button>
          <button mat-button class="cancel form-button-40" type="reset" (click)="cancleNofifyForm()">Cancel</button>
        </div>
      </form>
        </mat-tab> 
</mat-tab-group>
</main> 
</div>
</mat-card>
 </div>
