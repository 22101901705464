<div class="testcase_participants_form">
    <div class="modal-dialogbox">
      <div class="detail-view-title ">
        <h4 class="detail-view-title-default"> All Users</h4>
        <a class="btn btn-sm btn-action" (click)="navigateMainPage()"><mat-icon>close</mat-icon></a>
      </div>
    </div>
    <mat-divider></mat-divider>
    <form novalidate  [formGroup]="participantsForm">
      <div class="participants_form_wrap">
        <div class="form_row">
          <div class="form_col">
            <span>Participants</span>
          </div>
          <div class="form_col">
            <div class="form-group user_options">
              <mat-radio-group formControlName="permission">
                <mat-radio-button [value]="1" [checked]="radioValue == 1" (change)="radioChange($event)">MySelf</mat-radio-button>
                <mat-radio-button [value]="2" [checked]="radioValue == 2" (change)="radioChange($event)">Everyone</mat-radio-button>
                <mat-radio-button [value]="3" [checked]="radioValue == 3" (change)="radioChange($event)">Custom</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="form-group" *ngIf="showUsers">
              <ng-select [items]="customUsersData" bindLabel="label"
                bindValue="value" multiple="true" formControlName="participants" name="participants"
                (change)="updateParticipants()"
                [ngClass]="showError===true ? 'errorClass' :'noError'"
                placeholder="Select Users *"
                class="custom-select participants_dropdown">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="text-right btns_wrapper">
          <button  type="submit" mat-raised-button class="save-btn theme_button_color" (click)="onSubmit()">Update</button>
          <button type="button" class="cancel-btn" mat-raised-button (click)="navigateMainPage()">Close</button>
        </div>
      </div>
      
    </form>
</div>
