<mat-list-item  #matListItem
               [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
               [ngClass]="{'active': checkCurrentUrlWithRoute(item), 'expanded': item.expanded}"
               class="menu-list-item"  id="{{item.label.split(' ').join('-')}}">
  <a class="tw-flex-1" href="{{item.link}}" (click)="onItemSelected(item, $event)">
    <mat-icon [ngClass]="item.icon"></mat-icon>
    <span #step7 class="tw-text-base">{{ item.label }}</span>
    
      <ng-container *ngIf="customerTourDataObject">
        <app-overlay-tour *ngIf="activeTourIndex === customerTourDataObject.id"  [connectedTo]="step7" data="{{customerTourDataObject.icon}}" title="{{customerTourDataObject.label}}"
          [text]="customerTourDataObject.text" 
          id="{{customerTourDataObject.id}}" routerLink="{{customerTourDataObject.link}}" position="right">
        </app-overlay-tour>
      </ng-container>
    <span fxFlex *ngIf="item.items && item.items.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="item.expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
  </a>
</mat-list-item>

<div *ngIf="item.expanded">
  <app-menu-list-item *ngFor="let child of item.items trackBy:trackByItems" [navItemList]="navItemList" [item]="child" [depth]="depth+1" (parentActiveEmit)="receiveMessage($event, matListItem)" (expandParentEmit)="receiveParentExpand($event)">
  </app-menu-list-item>  
</div>

