<div class="apps-integration">
<section class="content">
  <div class="main-block row">
    <div class="col-sm-3 parent-block">
      <div class="app">
        <div class="image-container"><img src='../../../../assets/images/logo/Group4612.svg' class="image-src"></div>
        <div class="add-new-section" (click)='openSettingForm()' name="add-app">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
          Add App
        </div>
      </div>
    </div>
    <div *ngFor="let app of appsData" class="col-sm-3 parent-block">
      <!-- <img  *ngIf = 'app.imageUrl' src={{app.imageUrl}} alt="WooCommerce" class="image"> -->
      <div class="app tw-relative">
        <div class="app-name">{{app.title}}</div>
        <div class="image-container"><img *ngIf='app.imageUrl' src={{app.imageUrl}} alt="WooCommerce" class="image-src">
        </div>
        <div class="update-btn">
          <div (click)='update(app)' class="seting-icon option-btns hand-cursor" name="setting-icon"></div>
          <div class="sync-icon option-btns hand-cursor" name="sync-icons"></div>
          <button mat-button type="button" name="submitBtn" class="activate-btn"><img
              src='../../../../assets/images/logo/check_circle-24px.svg'>
            Active</button>
        </div>
      </div>
    </div>
    
  </div>

</section>
</div>