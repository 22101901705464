<div class="app-dialog-form tw-p-2">
<div class="title-header">
  Add New App
</div>
<form [formGroup]="addAppSettingForm" class="row tw-p-2">
  <mat-form-field class="example-full-width col-sm-6 right-block" appearance="outline">
    <input matInput placeholder="Title" name="Title" formControlName="title" required>
    <mat-label>Title</mat-label>
  </mat-form-field>
  <mat-form-field class="example-full-width col-sm-6 left-block" appearance="outline">
    <input matInput placeholder="Public Key" name="Public_Key" formControlName="public_key">
    <mat-label>Public key</mat-label>
  </mat-form-field>
  <mat-form-field class="example-full-width col-sm-6 right-block" appearance="outline">
    <input matInput placeholder="Private Key" name="Client_Key" formControlName="client_key">
    <mat-label>Client Key</mat-label>
  </mat-form-field>
  <mat-form-field class="example-full-width col-sm-6 left-block" appearance="outline">
    <input matInput placeholder="Secret Key" name="Secret_Key" formControlName="secret_key">
    <mat-label>Secret Key</mat-label>
  </mat-form-field>
  <ng-select [items]="options" name="developement" bindLabel="label" bindValue="value" formControlName ='developement_env'
    placeholder="Select Developement Environment" class="custom-select col-sm-6 right-block">
  </ng-select>
  <mat-form-field class="example-full-width right-block" appearance="outline">
    <input matInput placeholder="URL" name="URL" formControlName="url">
    <mat-label>URL</mat-label>
  </mat-form-field>

  <span class='file-label'>Upload App Logo : </span>
  <div class="right-block">
  <span class="btn btn-default btn-file file-upload">
    <span class="fileinput-new">
      <input type="hidden" value="" name="">
      <input type="file" name="choose-file" (change)="uploadImage($event)" accept=".png, .jpg, .jpeg">
    </span>
  </span>
  </div>
 
  <div class="form-buttons">
    <button mat-raised-button  type="submit" name="submitBtn" class="btn-submit form-button-40" (click)="addSetting()">Save</button>
    <button mat-button (click)="closeDialog()" class="cancel" name="cancel-apps">Cancel</button>
  </div>
</form>
</div>
