<div class="softphone-container" tabindex="-1">
    <div class="dialpad_layout">
        <div *ngIf="!showSoftPhone">
            <button
                mat-fab
                class="dialpad_button"
                (click)="toggleSoftphone()">
                <i class="material-icons dialpad_key">dialpad</i>
            </button>
        </div>

        <div class="dialpad_block stand_by mat-elevation-z8" [class.hide]="!showSoftPhone">
            <div class="dialpad-header">
                <button
                    class="close-btn"
                    (click)="closeSoftphone()"
                    matTooltip="Close"
                    #tooltip="matTooltip"
                    [matTooltipClass]="'basic-tooltip'">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>

            <div class="iframe-container">
                <iframe
                    class="softphone-iframe"
                    frameborder="0"
                    id="softphoneFrame"
                    allow="microphone; autoplay; clipboard-read; clipboard-write; hid"
                    [src]="softPhoneUrl"
                    [width]="iframeWidth"
                    [height]="iframeHeight"
                ></iframe>
            </div>
        </div>
    </div>
</div>
