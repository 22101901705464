import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { Component, OnInit, Injector, ElementRef, Inject, ViewChild, OnDestroy } from '@angular/core';
import { AppComponentBase } from '../../../../shared/AppComponentBase';
import { FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { COMMON } from '../../../../../service/constant';
import ImageCompressor from 'image-compressor.js';
import { EStatusCode } from '../../../../../service/Utils/Interfaces.class';
import { Subscription, forkJoin } from "rxjs";
import { JobcircularService } from "../../../../../service/jobcircular.service";
import { TicketsService } from "../../../../../service/tickets.service";
import { JobApplicationService } from '../../../../../service/jobApplication.service';
import { jobPositionList, jobsSetttingsResponse } from '../../../../utilities/settings/setting-job-position/interfaces/job-position.interface';
import { IAddjobApplicationResponse, JobApplication } from '../../../../../core/career/job-application.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-application-form',
    templateUrl: './application-form.component.html',
    styleUrls: ['./application-form.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIcon, MatDivider, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSelect, NgFor, MatOption, MatButton, NgIf, TranslateModule]
})
export class ApplicationFormComponent extends AppComponentBase implements OnInit, OnDestroy {
  applyJobForm: FormGroup;
  @ViewChild('onlyPreviewGallery') onlyPreviewGallery: NgxGalleryComponent;
  galleryImages: NgxGalleryImage[] = [];
  fileCount: number = 0;
  public totalCompressedImg: Array<File> = [];
  public totalFileName = [];
  public _albums: Array<any> = [];
  myInputVariable: ElementRef;
  tenantName: any;
  galleryOptions: NgxGalleryOptions[] = [];
  hostname: string;
  domain: string;
  public jobPosArr = [];
  public jobPosition = [];
  selectedJobPosition: string;
  private readonly subscriptions: Subscription[] = [];
  compressor: ImageCompressor;

  constructor(inject: Injector, private ticketsService: TicketsService,
    private jobcircularService: JobcircularService, private jobApplicationService: JobApplicationService, public dialog: MatDialog, public dialogRef: MatDialogRef<ApplicationFormComponent>, private elementRef: ElementRef, @Inject(MAT_DIALOG_DATA) public data: any) {
    super(inject);
  }

  ngOnInit() {
    this.hostname = window.location.hostname;
    // this.hostname = 'utiliko.xyz'; // For local testing eg., 'utiliko.xyz'
    this.domain = this.hostname.replace(/ /g, "").toLowerCase().trim();
    this.getJobPositionList();
    this.galleryOptions = [{
      image: false,
      thumbnails: false,
      width: '0px',
      height: '0px'
    },
    {
      breakpoint: 500,
      width: '300px',
      height: '300px',
      thumbnailsColumns: 3
    },
    {
      breakpoint: 300,
      width: '50%',
      height: '200px',
      thumbnailsColumns: 2
    }];
    this.getValidations();
    this.tenantName = this.configService.tenantName;
  }

  getValidations() {
    this.applyJobForm = this.fb.group({
      fullName: ["", Validators.compose([Validators.pattern(COMMON.EMPTY_VALUE), Validators.required])],
      email: ["", Validators.compose([Validators.pattern(COMMON.EMAIL_PATTERN), Validators.required])],
      phone: ["", Validators.compose([Validators.pattern(COMMON.PHONE_PATTERN), Validators.required])],
      coverLetter: [""],
      jobPosition: [this.data.jobDetails.jobPosition, Validators.required],
      experience: ['', Validators.required],
      noticePeriod: ['', Validators.required],
      currentCTC: ['', Validators.required],
      expectedCTC: ['', Validators.required],
      hearUs: ['', Validators.required],
      linkedinUrl: ['', Validators.required],
      message: [''],
    });
  }

  closeModel() {
    var data1 = { isSuccess: 0, isModelClosed: 1 };
    this.dialogRef.close(data1);
  }

  applyJob() {
    var uploadFile = "";
    const jobCirId = Number(this.data.jobCircularId);
     var data: JobApplication = {
      name: this.applyJobForm.value.fullName,
      email: this.applyJobForm.value.email,
      mobile: this.applyJobForm.value.phone,
      coverLetter: this.applyJobForm.value.message,
      resume: "",
      id: jobCirId,
      files: "",
      url: "",
      size: "",
      dir: "",
      domainName: this.domain,
      jobPositionId: this.data.jobDetails.jobPosId,
      jobPosition: this.applyJobForm.get('jobPosition').value,
      currentCTC: this.applyJobForm.get('currentCTC').value,
      expectedCTC: this.applyJobForm.get('expectedCTC').value,
      hearAboutUs: this.applyJobForm.get('hearUs').value,
      linkedinUrl: this.applyJobForm.get('linkedinUrl').value,
      experience: this.applyJobForm.get('experience').value,
      noticePeriod: this.applyJobForm.get('noticePeriod').value,
    };
    if (this.applyJobForm.invalid) {
      return;
    } else {
      if (this.totalCompressedImg.length > 0) {
        var directory = this.tenantName + '/job-applications';
        let observables = new Array();
        let fileSize = new Array();
        let folder = { directory: directory };
        for (let fileIndex = 0; fileIndex < this.totalCompressedImg.length; fileIndex++) {
          const formData: FormData = new FormData();
          const file: File = <File>this.totalCompressedImg[fileIndex];
          let filename = this.totalCompressedImg[fileIndex].name;
          formData.append("directory", directory);
          formData.append("file", file, filename);
          fileSize.push(file.size / 1000);
          observables.push(this.ticketsService.uploadFileResume(formData));
        }
        let fileNameArr: any = [];
        forkJoin(observables).subscribe(
          (res: any) => {
            for (let index = 0; index < res.length; index++) {
              if (res[index].info.code === EStatusCode.OK) {
                fileNameArr.push(res[index].data.list[0]);
              }
            }
          },
          error => console.log("Error: ", error),
          () => {
            if (fileNameArr.length > 0) {
              const upfile = fileNameArr.map(function (ele) {
                return ele.fileName;
              }).join(",");
              const url = fileNameArr.map(function (ele) {
                return ele.url;
              }).join(",");
              const upSize = fileSize.map(function (ele) {
                return ele;
              }).join(",");
              data.resume = String(url);
              data.files = String(upfile);
              data.url = String(url);
              data.size = String(upSize);
              data.dir = directory;
            }

            const jobApplyForm: Subscription = this.jobcircularService.applyForJob(data).subscribe((res: IAddjobApplicationResponse) => {
              if (res.info.code === EStatusCode.OK) {
                var data1 = { isSuccess: 1, isModelClosed: 0 };
                this.dialogRef.close(data1);
              } else {
                var data1 = { isSuccess: 0, isModelClosed: 0 };
                this.dialogRef.close(data1);
              }
            });
            this.subscriptions.push(jobApplyForm);
          });
      } else {
        const jobApplyForm: Subscription = this.jobcircularService.applyForJob(data).subscribe((res: any) => {
          if (res.info.code === EStatusCode.OK) {
            var data1 = { isSuccess: 1, isModelClosed: 0 };
            this.dialogRef.close(data1);
          } else {
            var data1 = { isSuccess: 0, isModelClosed: 0 };
            this.dialogRef.close(data1);
          }
        });
        this.subscriptions.push(jobApplyForm);
      }
    }
  }

  showImage(fileName: string) {
    let newIndex = this.getImageIndex(fileName);
    this.onlyPreviewGallery.openPreview(newIndex);
  }

  getJobPositionList() {
    const jobPosList: Subscription = this.jobApplicationService.jobPositionByDomain(this.domain).subscribe(
      (res: jobsSetttingsResponse<jobPositionList[]>) => {
        if (res.info.code === EStatusCode.OK) {
          this.jobPosition = res.data.map(element => ({
            value: element.jobPosId,
            label: element.jobPosition
          }));

          this.selectedJobPosition = this.jobPosition.find(
            (position) => position.label === this.data.jobDetails.jobPosition
          );
        } else {
          this.jobPosition = [];
        }
      }
    );
    this.subscriptions.push(jobPosList);
  }

  removeMultipleFiles(index) {
    let self = this;
    let isImageDelete = false;
    this.totalCompressedImg.splice(index, 1);
    this.totalFileName.splice(index, 1);
    this.fileCount = this.totalCompressedImg.length;
    const deletedFile = this._albums.splice(index, 1);
    if (deletedFile[0]['src'] !== '') {
      this.galleryImages.forEach(function (value, galleryIndex) {
        if ((deletedFile[0]['caption'] === value['description']) && !isImageDelete) {
          isImageDelete = true;
          self.galleryImages.splice(galleryIndex, 1);
        }
      });
    }
    // this.myInputVariable.nativeElement.value = '';
  }

  getImageIndex(fileName) {
    let albumIndex = 0;
    this.galleryImages.every(function (albumImg, index) {
      if (albumImg && albumImg.description === fileName) {
        albumIndex = index;
        return false;
      }
      return true;
    });

    return albumIndex;
  }

  onSelectFile(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      let filesAmount = fileInput.target.files.length;
      this.fileCount = this.fileCount + filesAmount;
      if (this.fileCount > 10) {
        if (this.totalCompressedImg.length === 0) {
          this.fileCount = 0;
        } else {
          this.fileCount = this.totalCompressedImg.length;
        }
        this.toastr.error('Maximum 10 files are allowed to upload');
      } else {
        let totalFileCount = this.totalCompressedImg.length;
        for (let i = 0; i < filesAmount; i++) {
          let reader = new FileReader();
          reader.onload = (e: Event) => {
            this.totalFileName.push(reader.result);
            this.getCompressedImage(fileInput.target.files[i]).then((resFile: any) => {
              if (resFile.type === 'application/doc' || resFile.type === 'application/docx' || resFile.type === 'application/pdf') {
                let album = this.getAlbumData(resFile, reader.result);
                resFile.extension = resFile.name.substr(resFile.name.lastIndexOf('.') + 1);
                this._albums[totalFileCount + i] = album;
                if (this._albums[totalFileCount + i].src) {
                  this.galleryImages.push({
                    small: this._albums[totalFileCount + i].src,
                    medium: this._albums[totalFileCount + i].src,
                    big: this._albums[totalFileCount + i].src,
                    description: this._albums[totalFileCount + i].caption
                  });
                }
                this.totalCompressedImg[totalFileCount + i] = resFile;
              } else {
                this.toastr.error('Please select pdf/doc file');
              }
            });
          }
          reader.readAsDataURL(fileInput.target.files[i]);
        }
      }
    }
  }

  /* get preview images data after file select*/
  getAlbumData(files, reader) {
    let albumImg = {};
    if (files.type === 'image/png' || files.type === 'image/jpg' || files.type === 'image/jpeg') {
      albumImg = {
        src: reader,
        caption: files.name,
        thumb: reader,
        type: files.type
      };
    } else {
      albumImg = {
        src: '',
        caption: '',
        thumb: '',
        type: ''
      };
    }
    return albumImg;
  }

  /* get compressed image on select file*/
  getCompressedImage(file) {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        this.compressor = new ImageCompressor(file, {
          quality: .5,
          success(result: any) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      } else {
        resolve(file);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
}
