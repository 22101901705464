

  <section class="content-header">
    <div class="row">
      <div class="col-md-6 header" >
        <h1 class="headerName"><i class="fa fa-tasks" aria-hidden="true"></i>
          Test Cases</h1>
      </div>
    </div>          
</section>
<div id="testCaseMain">
<section class="content">

  <div id="test-case-cont">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup  (selectedTabChange)="onLinkClick($event)">
    <mat-tab label="All Test Cases"[@fadeInOutTranslate] >
      <ng-template matTabContent>
        
        <app-test-cases-list [tabGroup]="tabGroup"></app-test-cases-list>
        <!-- <app-task-list [tabGroup]="tabGroup" (isUpdate)="isTabChange($event)"></app-task-list> -->
      </ng-template>
    </mat-tab>
    <mat-tab label="{{title}} Test Case" [@fadeInOutTranslate]>
      <ng-template matTabContent>
    
        <app-test-cases-form [tabGroup]="tabGroup"></app-test-cases-form>
        <!-- <app-task-form [tabGroup]="tabGroup"  ></app-task-form> -->
      </ng-template>
    </mat-tab>

  </mat-tab-group>
  </div>
</section>
</div>