
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">opportunityPipelines Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create new opportunity pipeline into `tbl_opportunity_pipelines` by giving <strong>opportunityPipelineName</strong>. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_add"><u>{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_add</u></a> 

      </li>
  </ul>
   
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API will delete the opportunity pipeline from `tbl_opportunity_pipelines` by <strong>opportunityPipelineId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_delete"><u>{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_delete</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the list of opportunity pipelines from `tbl_opportunity_pipelines`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_list"><u>{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_list</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listById</code></i></p>
   <ul>
       <li>
            It is used to get the details of particular opportunity pipeline from `tbl_opportunity_pipelines` by <strong>opportunityPipelineId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_listById"><u>{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_listById</u></a> 
       </li>
   </ul> 
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the opportunity pipeline name into `tbl_opportunity_pipelines` by <strong>opportunityPipelineId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_modify"><u>{{path}}explorer/#!/OpportunityPipelines/OpportunityPipelines_modify</u></a> 

       </li>
   </ul>
   </div>
</mat-card>

