import { GlobalService } from './../../../../service/global.service';
import { TenantCompaniesService } from './../../../../service/tenant-companies.service';
import { MakeModulesPaymentDialogComponent } from './make-modules-payment-dialog/make-modules-payment-dialog.component';
import { MatDialog, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { COMMON } from './../../../../service/constant';
import { PaymentInfoService } from './../../../../service/payment-info.service';
import { ItemsService } from './../../../../service/items.service';
import { ConfigService } from './../../../../service/config.service';
import { Component, OnInit, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { SettingsService } from '../../../../service/settings.service';
import { Subscription } from 'rxjs';
import { EStatusCode } from '../../../../service/Utils/Interfaces.class';
import { SubscriptionPayService } from '../../service/subscription-pay.service';
import { ToastrService } from 'ngx-toastr';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgFor, NgClass, NgIf, UpperCasePipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

export interface DialogData {
  type: string;
  name: string;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, NgFor, MatCheckbox, NgClass, ExtendedModule, NgIf, MatDialogActions, MatButton, MatDialogClose, UpperCasePipe]
})
export class DialogComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  moduleList: any;
  subscribedModuleList: any;
  request: any;
  currency: any;
  trialPeriodEndDate: any;
  today: any;
  defaultItemsList: any;
  costToDisplay: any= 0;
  itemFlag: boolean = false;
  addModulesFlag: boolean = true;
  flag: boolean = true;
  paidFlag: boolean = false;
  costToBeCharged: number = 0;
  costOfModuleForMonth: number = 0;
  modulesCount: number = 0;
  daysToExpireSubscription: number = 0;
  addModulesArr: Array<any> = [];
  subscribedModulesArr: Array<any> = [];
  alredySubscribedModulesArr: Array<any> = [];
  newSelectedModulesArr: Array<any> = [];
  actualModuleAdded: Array<any> = [];
  itemInfoArray: Array<any> = [];
  removedModules: string;
  addedModules: string;
  defaultTax : number =0;

  public ubmDeviceId: number = 0;
  public ubmMid: number = 0;
  public ubmTransactionKey: string = '';
  public manifest: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private subscriptionPayService: SubscriptionPayService,
    private configService: ConfigService,
    private itemsService: ItemsService,
    private tenantCompaniesService: TenantCompaniesService,
    private settingService: SettingsService,
    private paymentInfoService: PaymentInfoService,
    private globalService: GlobalService,
    public dialogRef2: MatDialogRef<any>,
    public dialog: MatDialog
    ) {
    this.request = this.data;
    this.paidFlag = this.request.isPaidHave;
  }

  ngOnInit(){

    this.getUBMDetails();

    this.configService.getAllConfigDetails();
    this.currency = this.configService.defaultCurrencySymbol;
    this.getDefaultItemsList();
    this.getSubscribedModulesList();
    this.getAlreadySubsbedList();
    const getCompnyDtils : Subscription = this.tenantCompaniesService.getCompanyDetails().subscribe((result: any) => {
      if (result.info.code=== EStatusCode.OK) {
        this.trialPeriodEndDate = new Date(result.data.trialPeriodEndDate);
        this.today = new Date();
        if (this.trialPeriodEndDate.getTime() > this.today.getTime()) {
          this.paidFlag = false;
        }
      }
    })
    this.subscriptions.push(getCompnyDtils);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
  
  
  
  getSubscribedModulesList() {
    const getSubsbedModls : Subscription = this.subscriptionPayService.getSubscribedModules().subscribe((response: any) => {
      if (response.info.code=== EStatusCode.OK) {
        this.subscribedModuleList = response.data;
        response.data.forEach(module => {
          this.subscribedModulesArr.push(module.label)
        });        
      }
    });
    this.subscriptions.push(getSubsbedModls);
  }

  getDefaultItemsList(){
    const getDefutItmsList : Subscription = this.itemsService.getDefaultItemsList().subscribe((response: any) => {
      if (response.info.code=== EStatusCode.OK) {
        this.defaultItemsList = response.data.list;
      }
    });
    this.subscriptions.push(getDefutItmsList);
  }

  getAlreadySubsbedList(){
    let nextBillingDate = moment(this.request.subscriptionEndDate).format('YYYY-MM-DD');
    this.subscriptionPayService.getAlreadySubscribedModules(nextBillingDate).subscribe((response: any) => {
      if (response.info.code=== EStatusCode.OK) {
        response.data.menuResult.forEach(item => {
              this.alredySubscribedModulesArr.push({id: item.menuId, 
                billingDate: moment(this.request.subscriptionEndDate).format('YYYY-MM-DD')})
        })
      }
    })
  }

  onCheckboxChange(moduleInfo,checkboxState){
    var name = moduleInfo.label;
    var menuId = moduleInfo.menuId;
    // let ispaid = false;
    var moduleSelected = {
      billingDate: moment(this.request.subscriptionEndDate).format('YYYY-MM-DD'), 
      id: menuId 
    };
    if (checkboxState.checked){
        this.newSelectedModulesArr.push(moduleSelected)
    } else{
      this.newSelectedModulesArr = this.newSelectedModulesArr.filter((item) => item.id !== menuId);
    }

    if(!moduleInfo.isPaid){
      name = name.charAt(0).toUpperCase() + name.slice(1);
      this.defaultItemsList.forEach(itemInfo => {
        
        if (itemInfo.itemName == name) {
          if (checkboxState.checked) {
            this.addModulesArr.push(name.toLowerCase());
            if (itemInfo.pricingType == 1) {
              if (itemInfo.itemTaxRate != 0.00) {
                this.costOfModuleForMonth = itemInfo.unitCost;
                var obj = {
                  name: name,
                  costOfModuleForMonth: itemInfo.unitCost,
                  percentTax: itemInfo.itemTaxRate,
                  fixedTax: null,
                  pricingType: 1
                };
                this.itemInfoArray.push(obj);
              } else {
                this.costOfModuleForMonth = itemInfo.unitCost;
                var obj = {
                  name: name,
                  costOfModuleForMonth: itemInfo.unitCost,
                  percentTax: null,
                  fixedTax: itemInfo.itemTaxTotal,
                  pricingType: 1
                };
                this.itemInfoArray.push(obj);
              }
            } else if (itemInfo.pricingType == 0) {
              if (itemInfo.itemTaxRate != 0.00) {
                this.costOfModuleForMonth = itemInfo.unitCost;
                var obj = {
                  name: name,
                  costOfModuleForMonth: itemInfo.unitCost,
                  percentTax: itemInfo.itemTaxRate,
                  fixedTax: null,
                  pricingType: 0
                };
                this.itemInfoArray.push(obj);
              } else {
                var obj = {
                  name: name,
                  costOfModuleForMonth: itemInfo.unitCost,
                  percentTax: null,
                  fixedTax: itemInfo.itemTaxTotal,
                  pricingType: 0
                };
                this.itemInfoArray.push(obj);
              }
            }
            this.modulesCount++;
          } else {
            var index = this.subscribedModulesArr.indexOf(name.toLowerCase());
            if (index > -1) {
              this.subscribedModulesArr.splice(index, 1);
            }
            var index1 = this.addModulesArr.indexOf(name.toLowerCase());
            if (index1 > -1) {
              this.addModulesArr.splice(index1, 1);
            }
            for (let indexOfModule = 0; indexOfModule < this.itemInfoArray.length; indexOfModule++) {
              var moduleName = this.itemInfoArray[indexOfModule].name;
              if (moduleName.toLowerCase() == name.toLowerCase()) {
                this.itemInfoArray.splice(indexOfModule, 1);
              }
            }
            this.modulesCount--;
          }
        }
      });
      
      var today = new Date();
      
      //Get 1 day in milliseconds
      var one_day = 1000 * 60 * 60 * 24;
      var endDate = new Date(this.request.subscriptionEndDate);
  
      // Convert both dates to milliseconds
      var date1_ms = today.getTime();
      var date2_ms = endDate.getTime();
  
      // Calculate the difference in milliseconds
      var difference_ms = date2_ms - date1_ms;
      this.costToBeCharged = 0;
      this.daysToExpireSubscription = Math.round(difference_ms / one_day);
      this.itemInfoArray.forEach(individualItem => {
        var perDayCostOfModules = individualItem.costOfModuleForMonth/30;
        if (individualItem.pricingType) {
          var moduleCostForMonth = Number(perDayCostOfModules.toFixed(2)) * this.daysToExpireSubscription;
        } else {
          var moduleCostForMonth = Number(perDayCostOfModules.toFixed(2)) * this.request.totalSeats * this.daysToExpireSubscription;
        }
        var moduleTax = 0;
        if (individualItem.percentTax) {
          moduleTax = (Number(moduleCostForMonth.toFixed(2)) / 100) * individualItem.percentTax;
        } else if (individualItem.fixedTax) {
          moduleTax = individualItem.fixedTax;
        }
        this.costToBeCharged = this.sum(this.costToBeCharged, Number(moduleCostForMonth.toFixed(2)) + Number(moduleTax.toFixed(2)))
        this.costToDisplay = Math.floor((this.costToBeCharged) * 100) / 100;
      });
      const taxRateDetlsById : Subscription = this.settingService.taxRateDetailsById(Number(this.configService.defaultTax)).subscribe((data:any)=>{
        if(data.info.code === EStatusCode.OK){
          let taxType = data.data.details[0].taxRateIn;
          let taxPercent = data.data.details[0].taxRatePercent;
          if(taxType == 'percent')
          {
            this.defaultTax = (this.costToBeCharged / 100) * taxPercent;
            this.costToBeCharged = this.costToBeCharged+this.defaultTax;
            this.costToBeCharged = Math.round((this.costToBeCharged) * 100) / 100;
            this.costToDisplay = Math.round((this.costToBeCharged) * 100) / 100;
          }
          else if(taxType == 'amount'){
            this.costToBeCharged = this.costToBeCharged + taxPercent;
            this.costToBeCharged = Math.round((this.costToBeCharged) * 100) / 100;
            this.costToDisplay = Math.round((this.costToBeCharged) * 100) / 100;
          }
        }
      })
      this.subscriptions.push(taxRateDetlsById);
    } else {
      if(checkboxState.checked){
        this.addModulesArr.push(name.toLowerCase());
        this.modulesCount++;
      } else {
        var index = this.subscribedModulesArr.indexOf(name.toLowerCase());
        if (index > -1) {
          this.subscribedModulesArr.splice(index, 1);
        }
        var index1 = this.addModulesArr.indexOf(name.toLowerCase());
        if (index1 > -1) {
          this.addModulesArr.splice(index1, 1);
        }
        for (let indexOfModule = 0; indexOfModule < this.itemInfoArray.length; indexOfModule++) {
          var moduleName = this.itemInfoArray[indexOfModule].name;
          if (moduleName.toLowerCase() == name.toLowerCase()) {
            this.itemInfoArray.splice(indexOfModule, 1);
          }
        }
        this.modulesCount--;
      }
    }

    if (this.modulesCount > 0) {      
      this.addModulesFlag = false;
    } else {
      this.addModulesFlag = true;
    }    
    
    
    // Convert back to days 
    for (let index = 0; index < this.addModulesArr.length; index++) {
      const element = this.addModulesArr[index];
      
      var index1 = this.subscribedModulesArr.indexOf(element.toLowerCase());
      if (index1 == -1) {
        this.subscribedModulesArr.push(element);
        this.actualModuleAdded.push(element);
      }
    }
    this.addedModules = this.addModulesArr.join(',');    
  }

  addModules(){
    let setWidth;
    if(this.configService.tsysCcStatus == 'inactive'){
      setWidth = 'auto';
    }
    else{
      setWidth = COMMON.CC_PAYMENT_WIDTH
    }
    
    var ids = new Set(this.newSelectedModulesArr.map(d => d.id));
    var merged = [...this.newSelectedModulesArr, 
      ...this.alredySubscribedModulesArr.filter(d => !ids.has(d.id))];


    if (this.trialPeriodEndDate.getTime() > this.today.getTime() || Number(this.costToBeCharged) <= 0) {
      this.globalService.createPolicyAndRoles(this.subscribedModulesArr.join(','), 
        JSON.stringify(merged)).subscribe((RolesAndPolicyRes: any) => {
        
        var data = {
          isComplete: 1,
          reOpen: 0,
          isSuccess: 1,
          otherError: 0,
          otherErrorReason: null,
          isPaymentFailFromTsys: 0
        };

        this.toastr.success(
          "Apps added successfully"
        );
        this.settingService.menuUpdateEventListener.next(true);
        this.dialogRef.close(data);
      });
    } else {
      const payInfoListByUser : Subscription = this.paymentInfoService.paymentInfoListByUser().subscribe((result: any) => {
        
        if (result['info']['code']=== EStatusCode.OK) {
          const manifest = this.encryptManifest(this.configService.tsysMid, this.configService.tsysDeviceId, this.configService.tsysTransactionKey);
          this.dialogRef2 = this.dialog.open(MakeModulesPaymentDialogComponent, {
            width: setWidth,
            autoFocus: false,
            disableClose: true,
            panelClass: [
              "animated",
              "slideInDown",
            ],
            data: {
              eGroup: 'creditCard',
              msg: 'creditCard',
              title: "Processing Payment of " + this.currency + ' ' +this.costToDisplay,
              paymentInfo: result.data,
              userId: this.configService.userId,
              manifest: this.manifest ? this.manifest : manifest,
              fromSubscription: true, // only for subscription app
              ubmDeviceId: this.ubmDeviceId,
              total: this.costToBeCharged,
              totalSeats: this.request.totalSeats,
              subscribedModuleList: this.subscribedModulesArr,
              addedSubscribedModules: JSON.stringify(merged),
              noOfDaysToExpiry: this.daysToExpireSubscription,
              actualModuleAdded: this.addModulesArr
            }
          });
          this.dialogRef2.afterClosed().subscribe(result => {
  
            if (result.isComplete == 1 && result.reOpen == 0) {
              // this.addSeat(this.data.from, this.data.employeeData);
              this.dialogRef.close({ from: 'frombillings' });
              this.settingService.menuUpdateEventListener.next(true);
            } 
            else if (result.isComplete == 0 && result.reOpen == 0) {
            }
            else {
              this.toastr.error(result.errMsg);
              this.dialogRef2.close();
              this.dialogRef.close();
            }
          });
       }
      });
      this.subscriptions.push(payInfoListByUser);
     }
  }

  encryptManifest(mid, deviceId, transactionKey) {
    var dataString = this.pad(mid, 20) + this.pad(deviceId, 24) + '000000000000' + moment().format('MMDDYYYY');
    const key = CryptoJS.enc.Utf8.parse(transactionKey.substr(0, 16));
    const iv = key;
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding
    }).toString();
    var finalManifest = CryptoJS.enc.Base64.parse(encrypted);
    var hash = CryptoJS.HmacMD5(transactionKey, transactionKey);
    var finalhash = hash.toString(CryptoJS.enc.Hex)
    return finalhash.substr(0, 4) + finalManifest + finalhash.substr(-4);
  }
  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + " ";
    return s;
  }
  sum(total, num) {
    return total + num;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }

  // get UBM details
  getUBMDetails() {
    const UBMTsysData : Subscription = this.configService.tsysUBMData().subscribe((res: any) => {
    
      if (res.info.code === EStatusCode.OK) {
        res.data.forEach(resObject => {
          if(resObject.configKey === "tsys_mid"){
            this.ubmMid = resObject.value;
          }else if(resObject.configKey === "tsys_device_id"){
            this.ubmDeviceId = resObject.value;
          }else if(resObject.configKey === "tsys_transaction_key"){
            this.ubmTransactionKey = resObject.value;
          }
        });

        this.manifest = this.encryptManifest(this.ubmMid, this.ubmDeviceId, this.ubmTransactionKey);
      }
    });
    this.subscriptions.push(UBMTsysData);
  }

}
