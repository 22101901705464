import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EStatusCode, IResponse, IResponseList} from '../../../../service/Utils/Interfaces.class';
import {AppComponentBase} from "../../../shared/AppComponentBase";
import {COMMON, templateType} from '../../../../service/constant';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import {PayInvoicePopupComponent} from "../pay-invoice-popup/pay-invoice-popup.component";
import { Subscription } from 'rxjs';
import { InvoiceServiceService } from '../../../../service/invoice-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../shared/spinner.component';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass, NgFor, DecimalPipe, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-invoice-view',
    templateUrl: './invoice-view.component.html',
    styleUrls: ['./invoice-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatButton, MatTooltip, MatDivider, NgFor, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, SpinnerComponent, DecimalPipe, TitleCasePipe, CommonDatePipe, TranslateModule]
})
export class InvoiceViewComponent extends AppComponentBase implements OnInit {
  private invoiceId: any;
  private invoiceEncryptId: string;

  public invoiceDetList: any;
  public subTotal: any;
  public  tax: any;
  public discount: any;
  public paidAmount: any;
  public  invoiceDue: any;
  public  invoiceTotalAmount: any;
  public dataSource: any;
  public error: boolean = false;
  public showMessage: boolean= true;
  public version: string;
  public themeData: string;
  public companyName: string;
  private path: any;
  public show: boolean;
  public customFields: boolean;
  public fields = [];
  public customElements = [];
  public companyTemplate: string;
  public stopRecurButton: boolean;

  public groupTaxDisplayedColumns: string[];

  public isPayable = false;
  public address : string = ''
  public zip : string = ''

  // validate cc/ach
  tsysAch = false;
  tsysCC = false;

  // get CC/ACH payment info id
  public paymentMethodCC: number;
  public paymentMethodAch: number;

  private readonly invoiceViewSubscription: Subscription[] = [];

  constructor(inject: Injector, private route: ActivatedRoute,
    public InvoiceService: InvoiceServiceService,
    ) {
    super(inject);
    // this.displayedColumns = ['item_name', 'item_desc', 'quantity', 'unit_price', 'tax_name', 'subTotal', 'tax'];
    this.version = COMMON.VERSION;
    this.configService.updateIsUserLogged.next(false);
    this.setWindowTitle('Invoice');
  }

  ngOnInit() {
    this.invoiceId = this.invoiceEncryptId = this.route.snapshot.paramMap.get('id');
    this.getInvoiceDetails(this.invoiceId);
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.companyTemplate = this.configService.companyTemplate;
  }

  ngOnDestroy() {
    this.invoiceViewSubscription.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
    
  }
  getInvoiceDetails(invoiceId) {
    const invDetSub: Subscription =
    this.InvoiceService.invoiceViewDetail(invoiceId).subscribe((response: IResponse) => {
      if (response.info.code === EStatusCode.OK) {

        this.invoiceId = response.data.invoicesId;
        const str = JSON.stringify(response.data);

        this.invoiceDetList = JSON.parse(str);
        this.updateConfigDetailsForClientView(this.invoiceDetList.configDetails);
        this.updatePromoFields();

        this.dataSource = this.invoiceDetList.items;
        this.invoiceDue = this.invoiceDetList.balanceDue;
        this.paidAmount = this.invoiceDetList.paidAmount;
        this.subTotal = this.invoiceDetList.subTotal;
        this.tax = this.invoiceDetList.tax;
        this.discount = this.invoiceDetList.discount;
        this.invoiceTotalAmount = this.invoiceDetList.amount;

        this.fields = this.invoiceDetList.customFieldData;
        this.customElements =  this.getCustomFieldValueList(this.fields);
        this.customFields = !!(this.customElements.length);
        if(this.invoiceDetList.biilingZipCode && this.invoiceDetList.billingAddress){
          this.address = this.invoiceDetList.billingAddress
          this.zip = this.invoiceDetList.biilingZipCode
        }
        else{
          this.address = this.invoiceDetList.companyAddress
          this.zip = this.invoiceDetList.companyZipCode
        }

        this.stopRecurButton = !(this.invoiceDetList.recurEndDate &&
          moment().format('YYYY-MM-DD') > moment(this.invoiceDetList.recurEndDate).format('YYYY-MM-DD'));
        this.showMessage = (this.invoiceDetList.recurring === 'No' && this.invoiceDetList.statusValue !== 'PAID' &&
          Date.parse(this.invoiceDetList.dueDate) < Date.parse(moment().format('YYYY-MM-DD')));

        if (this.invoiceDetList.statusValue !== 'PAID' &&
          Date.parse(this.invoiceDetList.dueDate) < Date.parse(moment().format('YYYY-MM-DD'))) {
          this.invoiceDetList.statusValue = 'OVERDUE';
          this.showViewMessage();
        }
        if (this.invoiceTotalAmount < 0) {
          this.invoiceTotalAmount = 0.00;
        }

        this.isPayable = this.invoiceDetList.recurring === 'No' && this.invoiceDue > 0 && this.invoiceDetList.statusValue !== 'PAID';
        // this.validateCCAch();

      } else {
        this.error = true;
      }
    });
    this.invoiceViewSubscription.push(invDetSub);
  }
  showViewMessage() {
    setTimeout (() => {
      this.showMessage = false;
    }, 3000);
  }
  openPayInvoiceDialog(clientId) {

    if (this.tsysCC && this.tsysAch) {
      this.toastr.error('Please setup Credit Cards / ACH details for proceeding payment.!');
      return;
    } else {
      this.paymentMethodDDList();
      const tsysDetails = JSON.parse(this.decryptValue(this.invoiceDetList.viewData))

      const manifest = this.encryptManifest(tsysDetails.tsysMid,
        tsysDetails.tsysDeviceId, tsysDetails.tsysTransactionKey);

      const dialogRef = this.dialog.open(PayInvoicePopupComponent, {
        width: '40%',
        autoFocus: false,
        disableClose: true,
        panelClass: [
          "animated",
          "slideInDown",
          this.configService.userTheme,
        ],

        data: {
          eGroup: this.tsysAch ? 'creditCard' : 'achCard',
          title: 'Pay Invoice',
          clientId: clientId,
          manifest: manifest,
          tsysDeviceId : tsysDetails.tsysDeviceId,
          address : this.address,
          zip : this.zip
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          // if (result.isComplete == 0 && result.reOpen == 0) {
          //   this.toastr.error('Pay using credit card aborted');
          // } else  if(result.isComplete==0 && result.reOpen==1){
          //   this.toastr.error(result.errMsg);
          // } else if (result.isComplete === 1 && result.isSuccess === 1 && result.cardInfo) {
            if (result.paymentInfo) {
              // Online payment portal
              if (result.eGroup === 'creditCard') {
                // card info should be like this format todo
                // cardInfo = {
                //   creditCardNumber: '',
                //   expirationDate: '',
                //   cvc: '',
                //   billingAddress: '',
                //   billingZip: '',
                // };
                this.CreditCardPayment(result.paymentInfo);
              } else {
                this.AchPayment(result.paymentInfo);
              }
          } else if (result.errMsg) {
            this.toastr.error(result.errMsg);
            if (result.isComplete === 0 && result.reOpen === 1) {
              this.openPayInvoiceDialog(this.invoiceDetList.clientId);
            }
          }
        }
      });
    }
  }

  encryptManifest(mid, deviceId, transactionKey) {
    var dataString = this.pad(mid, 20) + this.pad(deviceId, 24) + '000000000000' + moment().format('MMDDYYYY');
    const key = CryptoJS.enc.Utf8.parse(transactionKey.substr(0, 16));
    const iv = key;
    const encrypted = CryptoJS.AES.encrypt(dataString, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding
    }).toString();
    var finalManifest = CryptoJS.enc.Base64.parse(encrypted);
    var hash = CryptoJS.HmacMD5(transactionKey, transactionKey);
    var finalhash = hash.toString(CryptoJS.enc.Hex)
    return finalhash.substr(0, 4) + finalManifest + finalhash.substr(-4);
  }
  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + " ";
    return s;
  }
  downloadPDF(invoiceId, clientName){
    const invPdf: Subscription =
    this.InvoiceService.getInvoicePdf(invoiceId, clientName).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        if(res.data){
          this.path = res.data.filename;
          window.open(res.data[0].url);
        }
      }
    });
    this.invoiceViewSubscription.push(invPdf);
  }
  printDetail(invoiceId, clientName) {
    var filename = 'client_' + clientName.toLowerCase() + '_invoice_' + invoiceId;
    this.show = true;
    var innerContents = document.getElementById('print').innerHTML;
    this.show = false;
    var popupWinindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html style="background-image: url(' + this.companyTemplate + ');background-repeat: no-repeat;background-size: cover">' +
    '<head><title>'+filename+'</title><style>@page { size: auto;  margin: 5mm; } h4{margin-bottom:10px}.page-footer {left: 238px;right: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;}' +
    '.inv-sub-header{padding: 10px;font-weight: bold;padding-left: 20px;color: black;text-align: right;border-bottom: 1px solid #4caf50;margin-top: 60px}.inv-sub-main-content {background-color: #f3f4f6;margin-top: 10px;} .inv-table.mat-table .mat-column-item_desc {width: 20%;padding-right: 20px;}' +
    '.headerName {display: inline-block;text-align: center;width: 90%;position: relative; bottom: 25px; text-transform: capitalize;}.inv-sub-detail-content{background-color: white;color: black;font-weight: 600;}.clientName{margin-bottom:5px;}.light-font{font-weight: lighter;}.tag-btn {font-size: 12px;font-weight: 500;background-color:transparent;text-transform: uppercase;padding:4px;line-height:5px}' +
    '.pull-left {float:left;} .title{font-weigth:bold;font-size:14px;padding-bottom:5px;padding-top:10px;}.middle-block{width:25%;}.sales-head{vertical-align:top;}.salesName{width:48%;word-break:break-all;}.addressAlign{float: left!important;margin-left: 14px;margin-bottom:12px;line-height:18px;width:30%;}.clearfix{width:100%;}.statusAlign{width: 32%;float: right!important;line-height:5px;}.lightFont{font-weight: lighter;}' +
    '.DateRight{float: right;}.invLine {border-top: solid 2px darkgreen;padding-top:20px;}.inv-foot-detail {width: 100%;min-height: 200px;display: inline-block;padding-right:5px}.inv-notes{border: solid 1px #c9c9c9;max-height: 250px;min-height: 180px;\n' +
    '\tpadding: 10px;font-weight: lighter;overflow-y: auto;}.left-notes{border: solid 1px #c9c9c9;max-height: 168px;min-height: 168px;padding: 10px;font-weight: lighter;overflow-y: auto;}' +
    '.inv-payment-detail{width: 33%;float: right;display: inline-block;}.inv-payment-sub-detail{border: 2px solid #c9c9c9;}.left-panel{display: inline-block;width:calc(100% - 122px);border-bottom: solid 2px #c9c9c9;\n' +
    '\t\tpadding: 7px;font-weight: lighter;}.baldue{border-bottom: none;}.baldueright{border: none;}.themeColor{-webkit-print-color-adjust: exact;-webkit-print-color-adjust: exact;}' +
    '.footer{position: fixed;bottom: 0;width: 100%;height: 55px;}.page{margin: 0 15px;font-size: 9.5px;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.bottomFooter{text-align: left; width: 30.0%; float: left;}.leftFooter{margin: 0 0 10px;}.rightFooter{text-align: right;width: 33.33333333%;float: left;}.centerFooter{width: 36.6666666%;float: left;}hr{width: 100%;}.notes{width:62%;padding-left: 15px;display: inline-block;}' +
    '.tableHead{font-family: "Open Sans", sans-serif;width: calc(100% - 30px);margin-left: 15px;box-shadow: none;font-weight: 600;border-spacing: 0px;margin-top:15px;}.tableRow{height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393;text-align: left; padding-left: 15px; color: #000000de; font-size: 12px; font-weight: 400;border-top:2px solid #ddd;}' +
    // 'tableColumn{padding-left: 15px;font-size:14px;background-color: #f9f9f9;border-bottom: 1px solid #939393; text-align: left; color: #000000de;font-family: "Open Sans", sans-serif;height: 40px;font-weight: 500 !important;}' +
    // '.tableColumn{padding-left: 15px;font-size:12px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left; color: #000000de;font-family: "Open Sans", sans-serif;height: 48px;font-weight: 400 !important;}' +
    '.inv-item-table{padding: 5px;}' +
    '.tableColumn{padding-left: 15px;font-size:12px;border-bottom: 2px solid #939393; text-align: left; color: #000000de;font-family: "Open Sans", sans-serif;height: 40px;font-weight: 400 !important;}' +
    '.inv-item-table{padding: 5px;}.right-panel{display: inline-block;text-align: right;width: 96px;margin-left: -3px;text-align: right;padding: 7px;border-bottom: solid 1px #c9c9c9;word-break: break-all;\n' +
    '\t\tbackground-color: #f1f5f8;-webkit-print-color-adjust: exact;border-left:1px solid #c9c9c9;}' +
    // '.mat-row:nth-child(even){ background-color:#f9f9f9; } .mat-row:nth-child(odd){ background-color:#fff; }' +
    '.item-desc-table{word-break: break-all}' +
    '.tableFirstRowCol{font-family: "Open Sans", sans-serif;height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left;color: #000000de; font-size: 12px; font-weight: 400;}' +
    '.nameAlign{display: inline-block}.clientAlign{display: inline-block;}.clearfix{clear: both}.border{border: 2px solid black}.waterMark{text-align: center;position: relative;top: 85px;height: 0;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.styleWtareMarkRed{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;right: 45px;top: 25px;left:2px;position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.7px);width:180px;border-color: red;color: red;}' +
    '.f-r {float:right;}.successButton {color:rgb(0, 150, 60);border:2px solid rgb(0, 150, 60);padding:8px;}.dangerButton{color:rgb(200, 16, 16);border:2px solid rgb(200, 16, 16);padding:8px;}.invdate{padding: 10px 0px;padding-bottom: 3px;font-size: 14px}.styleWtareMarkGreen{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;right: 45px;top: 25px;left:2px;position: relative;padding: -1px 20px;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.7px);width:180px;border-color: green;color: green;}' +
    '.page-footer, .page-footer-space {height: 50px;.page-footer {position: fixed;bottom: 0;width: 100%;border-top: 1px solid black;background: yellow;}@media print {tfoot {display: table-footer-group;}button {display: none;} body {margin: 0;}}' +
    '</style><script>function myFunction() {window.close();} </script></head><body style="background-image: url(' + this.companyTemplate + ');  background-repeat: no-repeat; background-size: cover" onafterprint="myFunction()" onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

  updatePromoFields() {
    // updating column based on template type and tax visibility
    if (this.invoiceDetList.recurring === 'Yes') {
      this.displayedColumns = this.configService.enableItemLevelTaxation === '1' ?
        (this.invoiceDetList.templateType === templateType.default ? 
          ['item_name', 'item_desc', 'quantity', 'unit_price', 'promo', 'promoPrice',
            'promoValidity', 'tax_name', 'subTotal', 'tax']:
          (this.invoiceDetList.templateType === templateType.summary ?
            ['item_name', 'item_desc', 'quantity', 'promo',
              'promoValidity']: 
            ['item_name', 'item_desc', 'quantity', 'promo',
              'promoValidity', 'tax_name'])
        ) :
        (this.invoiceDetList.templateType === templateType.default ? 
          ['item_name', 'item_desc', 'quantity', 'unit_price', 'promo', 'promoPrice', 'promoValidity', 'subTotal']:
          ['item_name', 'item_desc', 'quantity', 'promo', 'promoValidity']
        );
    } else {
      this.displayedColumns = this.configService.enableItemLevelTaxation === '1' ?
        (this.invoiceDetList.templateType === templateType.default ? 
          ['item_name', 'item_desc', 'quantity', 'unit_price', 'tax_name', 'subTotal', 'tax'] : 
          (this.invoiceDetList.templateType === templateType.summary ? 
            ['item_name', 'item_desc', 'quantity'] :
            ['item_name', 'item_desc', 'quantity', 'tax_name'])
        ) :
        (this.invoiceDetList.templateType === templateType.default ? 
          ['item_name', 'item_desc', 'quantity', 'unit_price', 'subTotal'] :
          ['item_name', 'item_desc', 'quantity']
        );
    }

    if (this.configService.enableItemLevelTaxation === '1') {
      this.groupTaxDisplayedColumns = this.invoiceDetList.templateType === templateType.default ? 
        ['main_group_tax_name', 'sub_tax_name', 'subTotal', 'tax'] : 
        ['main_group_tax_name', 'sub_tax_name'];
    }

  }

  validateCCAch() {
    // check payment setting
    if (this.isPayable && this.configService.tsysCcStatus === 'inactive')  {
      this.tsysCC = true;
    }
    if (this.isPayable && this.configService.tsysCcStatus === 'inactive') {
      this.tsysAch = true;
    }
  }

  CreditCardPayment(cardInfo) {
    let paymentData = {
      clientId: this.invoiceDetList.clientId,
      paymentMethod: this.paymentMethodCC,
      cardInfo: cardInfo,
      amount: Number(this.invoiceDue.toFixed(2)),
      invoiceInfo: [{id: this.invoiceId, amount: Number(this.invoiceDue.toFixed(2)), overPaidAmount: 0, creditDetails: []}],
      accountId: Number(this.invoiceDetList.defaultAccount), // todo deposit to
      dueDate: this.getCurrentDate(moment().format('YYYY-DD-MM')),
      invoiceLink: 'https://' + this.invoiceDetList.companyDomain + location.pathname
    };

    const invAddPay: Subscription =
    this.InvoiceService.addInvoicePayment(paymentData).subscribe((response: IResponse) => {
      if (response.info.code === EStatusCode.OK) {
        this.invoiceDetList.statusValue = 'PAID';
        this.isPayable = false;
        this.toastr.success('Payment processed successfully!');
        this.getInvoiceDetails(this.invoiceEncryptId);
      } else {
        this.toastr.error('Payment failed due to, ' + response.data.responseMsg);
      }
    }, (error) => {
      console.log(error);
    });
    this.invoiceViewSubscription.push(invAddPay);
  }

  AchPayment(ACHInfo) {
    let paymentData = {
      clientId: Number(this.invoiceDetList.clientId),
      accountId: Number(this.invoiceDetList.defaultAccount),  // todo deposit to
      invoiceInfo: [{id: this.invoiceId, amount: Number(this.invoiceDue.toFixed(2)), overPaidAmount: 0, creditDetails: []}],
      paymentMethod: Number(this.paymentMethodAch),
      amount: Number(this.invoiceDue.toFixed(2)),
      dueDate: this.getCurrentDate(moment().format('YYYY-DD-MM')),
      ACHInfo: ACHInfo,
      invoiceLink: 'https://' + this.invoiceDetList.companyDomain + location.pathname
    };

    const invAddAchPay: Subscription =
    this.InvoiceService.addViewAchPayment(paymentData).subscribe((response: IResponse) => {
      if (response.info.code === EStatusCode.OK) {
        this.invoiceDetList.statusValue = 'PAID';
        this.isPayable = false;
        this.toastr.success('Payment processed successfully!');
        this.getInvoiceDetails(this.invoiceEncryptId);
      } else {
        this.toastr.error(response.data.responseMsg);
      }
    }, (error) => {
      console.log(error);
    });
    this.invoiceViewSubscription.push(invAddAchPay);
  }

  // get payment method dropdown list
  paymentMethodDDList() {
    const invPayMethod: Subscription =
    this.InvoiceService.paymentMethodListInvoice(this.invoiceId).subscribe((response: IResponseList) => {
      if (response.info.code === EStatusCode.OK) {
        response.data.list.map(payment => {
          if (payment.methodName === 'Credit Card') {
            this.paymentMethodCC = payment.paymentMethodsId;
          } else if (payment.methodName === 'ACH') {
            this.paymentMethodAch = payment.paymentMethodsId;
          }
        });
      }
    });
    this.invoiceViewSubscription.push(invPayMethod);
  }

  getCurrentDate(dueDate?) {
    let date = new Date();
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }
}
