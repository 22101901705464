
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Milestones Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create new milestone into `tbl_milestones`. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_add"><u>{{path}}explorer/#!/Milestones/Milestones_add</u></a> 

      </li>
  </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API is used to delete the milestone and the tasks present in that milestone from `tbl_milestones` and `tbl_task` by milestonesId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_delete"><u>{{path}}explorer/#!/Milestones/Milestones_delete</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listById</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the details of particular milestone from `tbl_milestones` by milestonesId. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_listById"><u>{{path}}explorer/#!/Milestones/Milestones_listById</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the details of milestone into `tbl_milestones` by milestonesId if exists. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_modify"><u>{{path}}explorer/#!/Milestones/Milestones_modify</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>responsibleUserDDListMilestones</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the users list from `tbl_users` if the below condition satisfies. 

       </li>
       <li>
            <strong>(tbl_account_details.company IS NULL OR tbl_account_details.company = "0") AND(tbl_account_details.vendor_id IS NULL OR tbl_account_details.vendor_id = 0) AND `tbl_users`.`activated` = 1 AND `tbl_users`.`user_type`!= 3</strong> 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_responsibleUserDDListMilestones"><u>{{path}}explorer/#!/Milestones/Milestones_responsibleUserDDListMilestones</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>treeData</code></i></p> 
   <ul>
       <li>
            This API is used to fetch the list of milestones and related tasks in tree structure format. 

       </li>
       <li>
            getMilestoneProgress method is used to get the task progress. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/Milestones/Milestones_treeData"><u>{{path}}explorer/#!/Milestones/Milestones_treeData</u></a> 

       </li>
   </ul>
   <br />
   </div>
</mat-card>
