<div #invoice id="invCom" *ngIf="!error" class="clientView">
    <div class="view_header">
  <mat-toolbar class="themeColor" [ngClass]="themeData" >
    <img src="{{estimateDetList?.companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
    <mat-label *ngIf="themeData==='green-light'||themeData==='green-dark'"class="headerName themeColor" [ngClass]="themeData" style="color: black">{{estimateDetList?.companyName}}</mat-label>
    <mat-label *ngIf="themeData!=='green-light' && themeData!=='green-dark'"class="headerName themeColor" [ngClass]="themeData" style="color: white">{{estimateDetList?.companyName}}</mat-label>
  </mat-toolbar>
  </div>
 <section class="inv-main-content">
   <div id="inv-detail-content" class="clearfix">
     <!--<div class="custom-item-btn pull-right" mat-button #tooltip="matTooltip" matTooltipPosition="above"-->
          <!--matTooltip="{{ 'common.excel' | translate }}" (click)="downloadExcel()">-->
       <!--<i class="fa fa-file-text" style="color: black"></i></div>-->
     <div class="custom-item-btn pull-right view-print hand-cursor" mat-button #tooltip="matTooltip" matTooltipPosition="above"
          matTooltip="Print Estimate" (click)="printDetail(estimateId,estimateDetList?.clientName)">
       <i class="fas fa-print text-danger"></i>
     </div>
     <div class="custom-item-btn pull-right hand-cursor" mat-button #tooltip="matTooltip" matTooltipPosition="above"
          matTooltip="{{ 'common.pdf' | translate }}" (click)="downloadPDF(estimateId,estimateDetList?.clientName)" >
       <i class="fa fa-file-pdf-o colorPDF view-pdf"></i></div>
     <div class="example-button-row pull-right" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Decline Estimate"
          [ngClass]="estimateDetList?.inActiveStatus === 1 ? 'disabledClass': ''"
          *ngIf="estimateDetList?.status == 'PENDING' || estimateDetList?.status == 'EXPIRED'">
       <button mat-raised-button class="declineButton"  (click)="showEstimateDecline('Declined', estimateDetList?.estimatesId)"
               [disabled]=" estimateDetList?.inActiveStatus === 1">Decline</button>
     </div>
     <div class="example-button-row pull-right" #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Accept Estimate"
          [ngClass]="estimateDetList?.inActiveStatus === 1 ? 'disabledClass': ''" 
          *ngIf="estimateDetList?.status == 'DECLINED' || estimateDetList?.status == 'PENDING' || estimateDetList?.status == 'EXPIRED'">
       <button mat-raised-button class="applyButton" (click)="updateEstimateStatus('accepted', estimateDetList?.estimatesId)"
               [disabled]="estimateDetList?.inActiveStatus === 1">Accept</button>
     </div>

     <div class="inv-sub-main-content">
       <div *ngIf="estimateDetList?.status == 'ACCEPTED' && showMessage"  class="inv-sub-header messageBox" style="background-color: #b2eec1">
         Thank you for Accepting the Estimate.
       </div>
       <div *ngIf="estimateDetList?.status == 'DECLINED' && showMessage"  class="inv-sub-header messageBox" style="background-color: #f05050">
         <i class="fas fa-exclamation-triangle"></i>
         Estimate declined. You can accept the estimate any time before expiry date.
       </div>
       <div *ngIf="estimateDetList?.status == 'EXPIRED' && showMessage"  class="inv-sub-header messageBox" style="background-color: rgb(200, 16, 16)">
         <i class="fas fa-exclamation-triangle"></i>
         This Estimate is OverDue!.
       </div>
       <div class="inv-sub-header">{{estimateDetList?.refNo}}
       </div>
       <div class="inv-sub-detail-content clearfix">
         <div class="row tw-min-h[200px] tw-p-4">
           <div class="col-sm-4 col-md-4 col-xs-4 pull-left client_info">
             <div class="clearfix">
               <span class="pull-left"><h4>{{estimateDetList?.clientName}}</h4></span>
             </div>
             <div class="clearfix address">
               <span class="pull-left light-font">{{estimateDetList?.billingAddress}}</span>
             </div>
             <div class="clearfix address">
               <span class="pull-left light-font">{{estimateDetList?.city}}</span>
             </div>
             <div class="clearfix">
               <span class="pull-left light-font">{{estimateDetList?.cityStateZip}}</span>
             </div>
             <div class="clearfix">
               <span class="pull-left light-font">{{estimateDetList?.country}}</span>
             </div>
             <div class="clearfix" *ngIf="estimateDetList?.phoneNumber">
               <span class="pull-left light-font">Phone: {{estimateDetList?.phoneNumber | commonDate:'phone_format'}}</span>
               <!--&lt;!&ndash;displayPhoneFormat&ndash;&gt;-->
             </div>
           </div>
           <div class="col-sm-4 col-md-4 col-xs-4 col-lg-4 pull-left">
             <div class="clearfix" *ngIf="estimateDetList?.projectId || estimateDetList?.ticketsId || estimateDetList?.opportunitiesId" style="padding-top: 10px;">
               <p class="nameAlign" style="padding-right: 5px;">
                 Related To:  <a class="ClientAlign" style="cursor: pointer;" *ngIf="estimateDetList?.projectId">
                 {{estimateDetList?.projectName}}
               </a>
                 <a class="ClientAlign" style="cursor: pointer;" *ngIf="estimateDetList?.opportunitiesId">
                   {{estimateDetList?.opportunityName}}
                 </a>
                 <a class="ClientAlign" style="cursor: pointer" *ngIf="estimateDetList?.ticketsId">
                   {{estimateDetList?.ticketCode}}</a> </p>

             </div>
           </div>
           <div class="col-sm-4 col-md-4 col-xs-5 col-lg-4 pull-right p-t-10 client_status_detail">
             <div class="light-font clearfix">
               <span>Estimate Status:</span>
               <span class="tag-btn successButton f-r" *ngIf="estimateDetList?.status === 'ACCEPTED'">{{estimateDetList?.status }}</span>
               <span class="tag-btn warningButton f-r" *ngIf="estimateDetList?.status === 'PENDING'">{{estimateDetList?.status }}</span>
               <span class="tag-btn dangerButton f-r" *ngIf="estimateDetList?.status === 'EXPIRED'"> {{estimateDetList?.status }} </span>
               <span class="tag-btn dangerButton f-r" *ngIf="estimateDetList?.status === 'DECLINED'"> {{estimateDetList?.status }} </span>
             </div>
             <div class="clearfix invdate">
               <p class="pull-left light-font">Valid Until</p>
               <p class="pull-right mr">{{estimateDetList?.dueDate | commonDate: 'date' }}</p>
             </div>
             <div class="clearfix invdate">
              <p class="pull-left light-font">Estimate Date</p>
              <p class="pull-right mr">{{estimateDetList?.estimateDate | commonDate: 'date' }}</p>
            </div>

            <div class="clearfix invdate">
              <p class="pull-left light-font">Sales Rep</p>
              <p class="pull-right mr">{{estimateDetList?.fullName }}</p>
            </div>

             <div class="clearfix invdate" *ngFor="let field of customElements">
               <p class="pull-left light-font"> {{field.fieldLabel}}</p>
               <p class="pull-right mr">{{field.fieldValue}}</p>
             </div>
             <div class="divider"></div>
           </div>
         </div>

         <!--<div class="inv-line"></div>-->
           <table mat-table [dataSource]="dataSource" class="inv-table tw-p-4">
             <ng-container matColumnDef="item_name">
               <th mat-header-cell *matHeaderCellDef class="light-font">Item Name
               </th>
               <td mat-cell *matCellDef="let element"> {{element.savedItemName}}</td>
             </ng-container>
             <ng-container matColumnDef="item_desc">
               <th mat-header-cell *matHeaderCellDef class="light-font">Description</th>
               <td mat-cell *matCellDef="let element" class="item-desc-table inv-item-table" style="max-width: 290px;">
                 {{element.itemDesc}}
               </td>
             </ng-container>
             <ng-container matColumnDef="quantity">
               <th mat-header-cell *matHeaderCellDef class="light-font">Quantity
               </th>
               <td mat-cell *matCellDef="let element"> {{element.quantity}}</td>
             </ng-container>
             <ng-container matColumnDef="unit_price">
               <th mat-header-cell *matHeaderCellDef class="light-font">Unit Price
               </th>
               <td mat-cell *matCellDef="let element"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
             </ng-container>
             <!--<ng-container matColumnDef="markup">
               <th mat-header-cell *matHeaderCellDef class="light-font">Markup
               </th>
               <td mat-cell *matCellDef="let element"> {{element.markup}}</td>
             </ng-container>-->
             <ng-container matColumnDef="tax_name">
               <th mat-header-cell *matHeaderCellDef class="light-font">Tax
               </th>
               <td mat-cell *matCellDef="let element"> {{element.taxName}}</td>
             </ng-container>
             <ng-container matColumnDef="subTotal">
               <th mat-header-cell *matHeaderCellDef class="light-font">Sub Total
               </th>
               <td mat-cell *matCellDef="let element"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
             </ng-container>
             <ng-container matColumnDef="tax">
               <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.taxAmount' |
                 translate }}
               </th>
               <td mat-cell *matCellDef="let element"> {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
             </ng-container>
             <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           </table>

         <!--Group Tax details-->
         <div style="padding-bottom: 21px;" *ngIf="configService.enableItemLevelTaxation === '1'">
         <div class="inv-line" *ngIf="estimateDetList?.grouptax?.length"></div>
         <div class="payment-detail-table" *ngIf="estimateDetList?.grouptax?.length">
           <div class="recurredPadding">Group Tax Detailed Information : </div>
           <table mat-table [dataSource]="estimateDetList.grouptax" class="mat-elevation-z8 inv-table">
             <ng-container matColumnDef="main_group_tax_name">
               <th mat-header-cell *matHeaderCellDef class="light-font">Main Group Tax Name
               </th>
               <td mat-cell *matCellDef="let element">
                 <span>{{element.groupTaxRateName}}</span>
             </ng-container>
             <ng-container matColumnDef="sub_tax_name">
               <th mat-header-cell *matHeaderCellDef class="light-font">Sub Tax Name
               </th>
               <td mat-cell *matCellDef="let element">
                 <span>{{element.subTaxName}}</span>
               </td>
             </ng-container>
             <ng-container matColumnDef="subTotal">
               <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.subTotal' |
                 translate }}
               </th>
               <td mat-cell *matCellDef="let element" class="inv-item-table" style="max-width: 290px;">
                 {{element.totalCost | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}
               </td>
             </ng-container>
             <ng-container matColumnDef="tax">
               <th mat-header-cell *matHeaderCellDef class="light-font">{{ 'invoices.invoice-detail.taxAmount' |
                 translate }}
               </th>
               <td mat-cell *matCellDef="let element">
                 <span>{{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</span>
             </ng-container>
             <tr mat-header-row *matHeaderRowDef="groupTaxDisplayedColumns;"></tr>
             <tr mat-row *matRowDef="let row; columns: groupTaxDisplayedColumns;"></tr>
           </table>
           <div *ngIf="estimateDetList?.grouptax?.length === 0" class="no-data">{{configService.noData}}</div>
         </div>
         </div>

         <div class="inv-line"></div>
         <div class="inv-foot-detail row tw-p-4">
           <div class="inv-notes col-sm-7 col-md-7 col-xs-7 col-lg-7">
             <div class="left-notes tinyClass" [innerHTML]="estimateDetList?.notes"></div>
           </div>
           <div class="inv-payment-detail col-sm-5 col-md-5 col-xs-5 col-lg-5" 
            *ngIf="estimateDetList?.templateType !== 'packingSlip'" style="padding-right: 13px">
             <div class="inv-payment-sub-detail">
               <div>
                 <div>
                   <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                   <div class="right-panel">{{subTotal | number : '1.2-2' | commonDate: 'money_format'  | commonDate : 'currency_format'}}</div>
                 </div>
               </div>
               <div>
                 <div>
                   <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                   <div class="right-panel">{{tax | number : '1.2-2' | commonDate: 'money_format'  | commonDate : 'currency_format'}}</div>
                 </div>
               </div>
               <div>
                 <div>
                   <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                   <div class="right-panel">{{discount | number : '1.2-2' | commonDate: 'money_format'  | commonDate : 'currency_format'}}</div>
                 </div>
               </div>

               <div>
                 <div>
                   <div class="left-panel baldue">{{ 'Total' | translate }}</div>
                   <div *ngIf="estimateDetList?.discount !== 100; then content2 else other_content2"></div>
                   <ng-template #content2>
                     <div class="right-panel baldueright">{{estimateDue | number : '1.2-2' | commonDate: 'money_format'  | commonDate : 'currency_format'}}</div>
                   </ng-template>
                   <ng-template #other_content2>
                     <div class="right-panel baldueright">0.00</div>
                   </ng-template>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="signature_block" *ngIf="open" style="color: unset;">
           <div class="sign_img_block">
             <p class="sign_img">
            <img src="{{representativeSignature}}" height="80">
             </p>
             <p>Signature</p>
           </div>
           <div class="sign_name">
              <p class="sign_name_date">{{fullName}} <span style="float:right;margin-left:20px;">{{estAcceptedDate | commonDate: 'date'}} </span> </p>
              <p>Accepted by <span style="float:right">Date </span></p>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
  <footer class="page-footer view_footer" style="padding-top:5px;">
    <div class="footer-copyright text-left">Copyright© <a style="color:#5d9cec" href="http://www.Utiliko.io " target="_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.
      <p class="alignright">{{version}}</p>
    </div>
  </footer>
</div>
<div *ngIf="error">
  <div class="center">
    <p style="font-size: 50px">Oops!</p>
    <p style="font-size: 25px">Something went wrong and we couldn't process your request.</p>
    <p style="font-size: 15px;margin: 0px;">Please check your entered Id.</p>
  </div>
</div>
<div id="print" [hidden]="!check">
  <table style="width: 100%">
    <tbody>
    <tr>
      <td>
        <div>
          <div class="inv-sub-main-content">
            <div class="waterMark">
              <p  class="styleWtareMarkRed" *ngIf="estimateDetList?.status === 'DECLINED' || estimateDetList?.status === 'EXPIRED'">
                {{estimateDetList?.status}}</p>
              <p  class="styleWtareMarkGreen" *ngIf="estimateDetList?.status === 'ACCEPTED'">
                {{estimateDetList?.status}}</p>
              <p  class="styleWtareMarkOrange" *ngIf="estimateDetList?.status === 'PENDING'">
                {{estimateDetList?.status}}</p>
            </div>
            <div class="themeColor" style="background-color: white">
              <div style="display: inline-block">
                <img src="{{estimateDetList?.companyLogo}}"  width="90px;" style="height: 60px;"
                     onError="this.src='/assets/img/default_company_logo.png'"> </div>
              <div class="headerName themeColor ">{{estimateDetList?.companyName}}</div>
            </div>
            <div class="inv-line"></div>
            <div class="inv-sub-detail-content clearfix">
              <div style="min-height: 200px">
                <div class="addressAlign">
                  <div class="clearfix">
                    <a class="pull-left" style="cursor: pointer"><h4>{{estimateDetList?.clientName}}</h4></a>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{estimateDetList?.billingAddress}}</span>
                  </div>
                  <div class="clearfix address">
                    <span class="pull-left light-font">{{estimateDetList?.city}}</span>
                  </div>
                  <div class="clearfix">
                    <span class="pull-left light-font">{{estimateDetList?.cityStateZip}}</span>
                  </div>
                  <div class="clearfix">
                    <span class="pull-left light-font">{{estimateDetList?.country}}</span>
                  </div>
                  <div class="clearfix" *ngIf="estimateDetList?.phoneNumber">
                    <span class="pull-left light-font">Phone: {{estimateDetList?.phoneNumber | commonDate : 'phone_format'}}</span>
                    <!--&lt;!&ndash;displayPhoneFormat&ndash;&gt;-->
                  </div>
                </div>
                <div class="pull-left">
                  <div class="clearfix" *ngIf="estimateDetList?.projectId || estimateDetList?.ticketsId || estimateDetList?.opportunitiesId" style="padding-top: 15px;font-weight: lighter;margin-bottom: -20px">
                    <p class="nameAlign" style="padding-right: 5px;font-weight: lighter">
                      Related To: </p>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="estimateDetList?.projectId">
                      {{estimateDetList?.projectName}}
                    </a>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="estimateDetList?.opportunitiesId">
                      {{estimateDetList?.opportunityName}}
                    </a>
                    <a class="ClientAlign" style="cursor: pointer;font-weight: lighter" *ngIf="estimateDetList?.ticketsId">
                      {{estimateDetList?.ticketCode}}</a>
                  </div>
                </div>

                <div class="statusAlign">
                  <div class="clearfix">
                    <p class="pull-left light-font">Estimate # :</p>
                    <p class="DateRight">{{estimateDetList?.refNo}}</p>
                  </div>
                  <div class="clearfix">
                    <div class="lightFont">Estimate Status:
                      <span class="tag-btn successButton f-r" *ngIf="estimateDetList?.status === 'ACCEPTED'">{{estimateDetList?.status }}</span>
                      <span class="tag-btn warningButton f-r" *ngIf="estimateDetList?.status === 'PENDING'">{{estimateDetList?.status }}</span>
                      <span class="tag-btn dangerButton f-r" style="margin: 0;" *ngIf="estimateDetList?.status === 'DECLINED' || estimateDetList?.status === 'EXPIRED'"> {{estimateDetList?.status }} </span>
                    </div>
                  </div>
                  <div class="clearfix">
                    <p class="pull-left light-font">Valid Until</p>
                    <p class="DateRight">{{estimateDetList?.dueDate | commonDate: 'date' }}</p>
                  </div>
                  <div class="clearfix" *ngFor="let field of customElements">
                    <p class="pull-left light-font"> {{field.fieldLabel}}</p>
                    <p class="DateRight">{{field.fieldValue}}</p>
                  </div>
                  <div class="divider"></div>
                </div>
              </div>

              <!--<div class="inv-line"></div>-->
              <div style="padding-bottom: 21px;" class="tw-p-4">
                <table mat-table [dataSource]="dataSource" class="tableHead">
                  <ng-container matColumnDef="item_name">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Item Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableFirstRowCol"> {{element.savedItemName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="item_desc">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Description</th>
                    <td mat-cell *matCellDef="let element" class="item-desc-table inv-item-table tableColumn" style="max-width: 200px;">
                      {{element.itemDesc}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef class=" tableRow light-font">Qty
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"  style="text-align: center"> {{element.quantity}}</td>
                  </ng-container>
                  <ng-container matColumnDef="unit_price">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Unit Price
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.unitCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="markup">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Markup
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.markup}}</td>
                  </ng-container>
                  <ng-container matColumnDef="tax_name">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Tax
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.taxName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="subTotal">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">Sub Total
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.totalCost | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="tax">
                    <th mat-header-cell *matHeaderCellDef class="tableRow light-font">{{ 'invoices.invoice-detail.taxAmount' |
                      translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="tableColumn"> {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>

              <!--Group Tax details-->
              <div style="padding-bottom: 21px;" *ngIf="configService.enableItemLevelTaxation === '1'">
                <div class="payment-detail-table" *ngIf="estimateDetList?.grouptax?.length">
                  <div class="recurredPadding" style="padding-left: 15px;margin-top: 10px">Group Tax Detailed Information : </div>
                  <table mat-table [dataSource]="estimateDetList.grouptax" class="tableHead" style="background: none !important;">
                    <ng-container matColumnDef="main_group_tax_name">
                      <th mat-header-cell *matHeaderCellDef class="tableRow  light-font" style="background: none !important;">Main Group Tax Name
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableFirstRowCol" style="background: none !important;">
                        {{element.groupTaxRateName}}
                    </ng-container>
                    <ng-container matColumnDef="sub_tax_name">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">Sub Tax Name
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.subTaxName}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="subTotal">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">{{ 'invoices.invoice-detail.subTotal' |
                          translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.totalCost | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tax">
                      <th mat-header-cell *matHeaderCellDef class="tableRow light-font" style="background: none !important;">{{ 'invoices.invoice-detail.taxAmount' |
                          translate }}
                      </th>
                      <td mat-cell *matCellDef="let element" class="tableColumn" style="background: none !important;">
                        {{element.itemTaxTotal | number : '1.2-2' | commonDate : 'money_format' | commonDate : 'currency_format'}}
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="groupTaxDisplayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: groupTaxDisplayedColumns;"></tr>
                  </table>
                  <div *ngIf="estimateDetList?.grouptax?.length === 0" class="no-data">{{configService.noData}}</div>
                </div>
              </div>

              <div class="inv-foot-detail row tw-p-4">
                <div class="notes" style="padding-left: 15px">
                  <div class="inv-notes" [innerHTML]="estimateDetList?.notes"></div>
                </div>
                <div class="inv-payment-detail col-sm-4 col-md-4 col-xs-4 col-lg-4" 
                  *ngIf="estimateDetList?.templateType !== 'packingSlip'" style="padding-right: 13px">
                  <div *ngIf="estimateDetList?.templateType !== 'packingSlip'" class="inv-payment-sub-detail">
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.subTotal' | translate }}</div>
                        <div class="right-panel">{{subTotal | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.tax' | translate }}</div>
                        <div class="right-panel">{{tax | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="left-panel">{{ 'invoices.invoice-detail.discount' | translate }}</div>
                        <div class="right-panel">{{discount | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <div class="left-panel baldue">{{ 'Total' | translate }}</div>
                        <div *ngIf="estimateDetList?.discount !== 100; then content2 else other_content2"></div>
                        <ng-template #content2>
                          <div class="right-panel baldueright">{{estimateDue | number : '1.2-2' | commonDate: 'money_format' | commonDate : 'currency_format'}}</div>
                        </ng-template>
                        <ng-template #other_content2>
                          <div class="right-panel baldueright">0.00</div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <td>
        <!--place holder for the fixed-position footer-->
        <div class="page-footer-space"></div>
      </td>
    </tr>
    </tfoot>

  </table>
  <div class="view_footer" style="padding-top:5px;" >
    <footer class="footer">
      <hr>
      <div class="page">
        <div class="bottomFooter">
          <p class="leftFooter">{{estimateDetList?.companyEmail}}</p>
        </div>
        <div class="centerFooter">
          <p class="leftFooter"> {{estimateDetList?.companyAddress}}, {{estimateDetList?.companyCity}},{{estimateDetList?.companyState ? estimateDetList?.companyState + ',' : ''}}{{estimateDetList?.companyZipCode}}, {{estimateDetList?.companyCountry}} <br> {{estimateDetList?.companyPhone | commonDate: 'phone_format'}}</p>
        </div>
        <div class="rightFooter">
          <p class="leftFooter">{{estimateDetList?.companyWebsite ? estimateDetList?.companyWebsite : estimateDetList?.companyDomain}}</p>
        </div>
      </div>
    </footer>
  </div>

</div>
<app-spinner></app-spinner>
