import { Component } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-platform-docs-info',
    templateUrl: './platform-docs-info.component.html',
    styleUrls: ['./platform-docs-info.component.scss'],
    standalone: true,
    imports: [MatIcon, MatCard]
})
export class PlatformDocsInfoComponent {
}
