<div *ngIf="!automation"  class="deleteNote" [ngClass]="isCardDelete && showInvoice ? 'invoice-list-height' : ''">
  <h1 mat-dialog-title>{{alertCommonDialogData.title}}</h1>
  <div mat-dialog-content>
    <p>{{alertCommonDialogData.message}}</p>
  </div>
  <div *ngIf="isLinked">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Module Name</th>
          <th scope="col">Entity Name</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ alertCommonDialogData.moduleName }}</td>
          <td>{{ alertCommonDialogData.entityName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <span class="fa fa-plus-circle" matTooltip="Show invoice" *ngIf="isCardDelete && !showInvoice" (click)="showInvoice = true"></span>
    <span class="fa fa-minus-circle" matTooltip="Hide invoice" *ngIf="showInvoice" (click)="showInvoice = false">
       <p class="invoicelist-msg" *ngIf="showInvoice">
      Below are the invoices that are attached to the selected {{request?.cardType}}
    </p>
    </span>
  </div>
  <div *ngIf="activeTimerCount" align = "center">
    <div class="row userImg" *ngFor="let userDetails of activeTimerList;">
      <span *ngIf="userDetails.activated === 1">
                <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}"
                     title="{{userDetails.fullname}}"
                     class="profile-pic mat-icon-button list-avatar-img"
                     onError="this.src='assets/img/default_avatar.jpg'">
                <span style="margin: 0px 0 8px -10px;" class="circle circle-success circle-lg"></span>
              </span>
      <span *ngIf="userDetails.activated === 0">
                <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}"
                     title="{{userDetails.fullname}}"
                     class="profile-pic mat-icon-button list-avatar-img"
                     onError="this.src='assets/img/default_avatar.jpg'">
                <span class="circle circle-warning circle-lg timer-avatar"></span>
              </span>
    </div>
    <p class="timer-count" *ngIf="activeTimerCount > 1 ">({{activeTimerCount}} Active timers are running)</p>
    <p class="timer-count" *ngIf="activeTimerCount === 1 ">({{activeTimerCount}} Active timer is running)</p>
  </div>

  <div *ngIf="showInvoice" class="invoice-div">
    <div class="col-md-3" *ngFor="let invoice of alertCommonDialogData.invoiceList">
      <a (click)="openInvoiceDetail(invoice.invoicesId, $event)" href="/accounting/invoice/details/{{invoice.invoicesId}}">{{invoice.referenceNo}}</a></div>
  </div>
  <div mat-dialog-actions class="button-action form-buttons">
    <button [ngClass]="convert_to_client==true ? 'convert_cancel_btn' : 'convert_cancel'" mat-button (click)="cancelButton()" class="cancel" id="cancel">{{alertCommonDialogData.cancelButtonText}}</button>
    <button mat-button (click)="submitButton()" [ngClass]="request.submitButtonText == 'Stop timer & delete' ? 'redbtn' : 'green'" id="submit"
            cdkFocusInitial>{{alertCommonDialogData.submitButtonText}}
    </button>
  </div>
</div>

<div *ngIf="automation" class="buttonsWrapper">
  <h1 mat-dialog-title text-center class="center-head">{{alertCommonDialogData.title}}</h1>
  <div mat-dialog-actions class="button-action okbtn">
    <button mat-button (click)="submitButton()" class="auto-alert-btn"
            cdkFocusInitial>{{alertCommonDialogData.submitButtonText}}
    </button>
  </div>

</div>
