import {Component, Injector, OnInit} from '@angular/core';
import {AppComponentBase} from "../../../shared/AppComponentBase";
import {ActivatedRoute} from "@angular/router";
import {EStatusCode, IResponse} from "../../../../service/Utils/Interfaces.class";
import {ExcelGenerator} from "../../../../service/Utils/ExcelGenerator";
import {COMMON} from '../../../../service/constant';
import { Subscription } from 'rxjs';
import { PurchaseOrderService } from '../../../../service/purchase-order.service';
import { CommonDatePipe } from '../../../shared/pipes/common-date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '../../../shared/spinner.component';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { MatLabel } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';
@Component({
    selector: 'app-purchase-order-view',
    templateUrl: './purchase-order-view.component.html',
    styleUrls: ['./purchase-order-view.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ExtendedModule, MatLabel, MatTooltip, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, SpinnerComponent, DecimalPipe, TranslateModule, CommonDatePipe]
})
export class PurchaseOrderViewComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  private purchaseOrderId: any;
  private purchaseOrderDetList: any;
  private  tax: any;
  private  purchaseOrderTotalAmount: any;
  public dataSource: any;
  public purchaseOrderDetail: any;
  public status: string;
  PurchaseOrderListExcel = new ExcelGenerator();
  public error: boolean = false;
  private version: string;
  public themeData: string;
  private path: any;
  public show: boolean;
  constructor(inject: Injector, private route: ActivatedRoute,
              public purchaseOrderService: PurchaseOrderService) {
    super(inject);
    this.setWindowTitle('Purchase Order');
    this.displayedColumns = ['item_name', 'item_desc', 'quantity', 'unit_price', 'subTotal'];
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.configService.updateIsUserLogged.next(false);
  }

  ngOnInit() {
    this.purchaseOrderId = this.route.snapshot.paramMap.get('id');
    this.getPurchaseOrderDetails(this.purchaseOrderId);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
  getPurchaseOrderDetails(purchaseOrderId) {
    const getPurchaseOrderViewDetail :Subscription = this.purchaseOrderService.getPurchaseOrderViewDetail(purchaseOrderId).subscribe((response: IResponse) => {
      if (response.info.code === EStatusCode.OK) {
        const str = JSON.stringify(response.data.items);
        this.purchaseOrderDetList = JSON.parse(str);

        this.dataSource = this.purchaseOrderDetList;

        this.purchaseOrderDetail = JSON.parse(JSON.stringify(response.data));
        this.updateConfigDetailsForClientView(this.purchaseOrderDetail.configDetails);

        this.purchaseOrderTotalAmount = this.purchaseOrderDetail.totalAmount;
        this.tax = this.dataSource.taxName;

        this.status = this.purchaseOrderDetail.status;

        if (this.purchaseOrderTotalAmount < 0) {
          this.purchaseOrderTotalAmount = 0.00;
        }
      } else {
        this.error = true;
      }
    });
    this.subscriptions.push(getPurchaseOrderViewDetail);

  }
  downloadExcel(){
    let rows = [];
    this.dataSource.data.forEach(function (column) {
      let excelData = {
        'Purchase Order': column.referenceNumber,
        'Created Date': column.purchaseCreatedDate,
        'Due Date': column.dueDate,
        'Vendor Name': column.vendorName,
        'Amount': column.amount,
        'Due Amount': column.amountDue,
        'Client Name': column.name,
        'Memo': column.memo,
        'Status': column.statusValue,
      };
      rows.push(excelData);
    });
    this.PurchaseOrderListExcel.generatExcel("Manage Purchase Order",rows,"Manage Purchase Order");
  }
  downloadPDF(poId, name) {
    const getPoPdf :Subscription = this.purchaseOrderService.getPoPdf(poId, name).subscribe((res: IResponse) => {
      if (res.info.code === EStatusCode.OK) {
        if(res.data){
          this.path = res.data.filename;
          window.open(res.data[0].url);
        }
      }
    });
    this.subscriptions.push(getPoPdf);
  }
  printDetail(poId, name) {
    var filename = 'vendor_' + name.toLowerCase() + '_purchaseOrder_' + poId;
    this.show = true;
    var innerContents = document.getElementById('print').innerHTML;
    this.show = false;
    var popupWinindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><title>'+filename+'</title><style>@page { size: auto;  margin: 5mm; }.page-footer {left: 238px;right: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;}' +
      '.inv-sub-header{padding: 10px;font-weight: bold;padding-left: 20px;color: black;text-align: right;border-bottom: 1px solid #4caf50;margin-top: 60px}.inv-sub-main-content {background-color: #f3f4f6;margin-top: 10px;}' +
      '.headerName {    display: inline-block;text-align: center;width: 90%;position: relative;bottom: 25px;text-transform: capitalize;}.inv-sub-detail-content{background-color: white;min-height: 530px;color: black;font-weight: 600;}.light-font{font-weight: lighter;}.tag-btn {font-size: 12px;font-weight: 500;border-radius: 3px;padding: 0px 5px !important;background-color: transparent;text-transform: uppercase;}' +
      '.common-btn{padding: 8px;font-weight: lighter;font-size:12px;}.successButton{color:rgb(0, 150, 60);border:2px solid rgb(0, 150, 60);padding:8px;}' +
      '.warningButton {color: #f35d05;border: 1px solid #f35d05;float: right;}.pull-left {float:left;}.col-md-3 {width: 25%;}.addressAlign{float: left!important;width: 25%;margin-top: 5px;margin-left: 15px}.clearfix{width:100% margin-bottom: 5px;}.statusAlign{width: 35%;float: right!important;}.lightFont{font-weight: lighter;margin-top: 5px;}' +
      '.DateRight{float: right;margin: 20px -5px 6px 0;}.inv-line {border-top: solid 2px darkgreen;}.inv-foot-detail {width: 100%;min-height: 200px;display: inline-block;padding-right:5px}.inv-notes{border: solid 1px #c9c9c9;max-height: 250px;min-height: 216px;\n' +
      '\tpadding: 10px;font-weight: lighter;overflow-y: auto;}.left-notes{border: solid 1px #c9c9c9;max-height: 168px;min-height: 168px;padding: 10px;font-weight: lighter;overflow-y: auto;}.inv-table.mat-table .mat-column-item_desc {width: 20%;padding-right: 20px;}' +
      '.inv-payment-detail{width:33%;float: right;display: inline-block;}.inv-payment-sub-detail{border: solid 2px #c9c9c9;}.left-panel{display: inline-block;width:calc(100% - 135px);border-bottom: solid 1px #c9c9c9;\n' +
      '\t\tpadding: 10px;font-weight: lighter;}.right-panel{display: inline-block;text-align: right;width: 96px;border-left: solid 1px #c9c9c9 !important;margin-left: -3px;padding: 10px;border-bottom: solid 1px #c9c9c9;word-break: break-all;\n' +
      '\t\tbackground-color: #f1f5f8;-webkit-print-color-adjust: exact;}.baldue{border-bottom: none;color: #fa5858}.baldueright{border: none;color: #fa5858}.themeColor{-webkit-print-color-adjust: exact;height: 60px;-webkit-print-color-adjust: exact;padding-bottom: 15px}' +
      '.footer{background-color:#fff;position: fixed;bottom: 0;width: 100%;height: 55px;}.page{margin: 0 15px;font-size: 11px;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.bottomFooter{text-align: left; width: 33.33333333%; float: left;}.leftFooter{margin: 0 0 10px;}.rightFooter{text-align: right;width: 33.33333333%;float: left;}.centerFooter{width: 33.33333333%;float: left;}hr{width: 100%;}.notes{width:62%;padding-left: 15px;display: inline-block;}' +
      '.tableHead{width: calc(100% - 30px);margin-left: 15px;box-shadow: none;font-weight: 600;border-spacing: 0px}.tableRow{height:42px;background-color: #f9f9f9;border-bottom: 2px solid #939393; text-align: left; padding-left: 15px; color: #000000de; font-size: 12px; font-weight: 400;}.tableColumn{font-size:14px;border-bottom: 2px solid #939393; text-align: left; color: #000000de;font-family: "Open Sans", sans-serif;height: 48px;font-weight: 500 !important;padding-left: 15px;}.inv-item-table{padding: 5px;}' +
      '.mat-row:nth-child(even){background-color:#f9f9f9;   }.mat-row:nth-child(odd){ background-color:#fff; }' +
      '.nameAlign{display: inline-block}.clientAlign{margin: 16px 0;}.clearfix{clear: both}.border{border: 2px solid black}.waterMark{text-align: center;position: relative;top: 85px;height: 0;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.styleWtareMarkRed{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: red;color: red;}' +
      '.secondaryButton{color:#3a3a3a;border:1px solid #3a3a3a;}.voiletButton{font-size: 12px;color:#9b00e2;border:1px solid #9b00e2;}.f-r {float:right;}.invdate{padding: 10px 0px;padding-bottom: 3px;font-size: 14px}.styleWtareMarkGreen{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: rgb(0, 150, 60);color: rgb(0, 150, 60);}' +
      '.styleWtareMarkOrange{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: orange;color: orange;}.styleWtareMarkSkyblue{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: #9b00e2;color: #9b00e2;}' +
      '.styleWtareMarkSecondary{top:inherit;display: inline-block; border-radius: 20px;border-width: 2px;border-style:solid;text-transform: uppercase;margin: 0 auto;font-weight: 500; font-size:20px;letter-spacing: normal;line-height: 40px;padding: -1px 20px;right: 40px; position: relative;-webkit-transform: rotate(-15deg);-webkit-filter: blur(0.9px);width: 200px;border-color: 3a3a3a;color:#3a3a3a;}' +
      '.page-footer, .page-footer-space {height: 50px;.page-footer {position: fixed;bottom: 0;width: 100%;border-top: 1px solid black;background: yellow;}@media print {tfoot {display: table-footer-group;}button {display: none;} body {margin: 0;}}' +
      '.item-desc-table{word-break: break-all}' +
      '</style><script>function myFunction() {window.close();}</script></head><body onafterprint="myFunction()" onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();

  }

}
