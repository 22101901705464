
<div class="api-docs-back-btn">
     <section class="content-header">
       <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
             <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
               <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
           </a>    
       </button>
     </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Payment Method Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to add a new payment method into `tbl_payment_methods`. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/PaymentMethods/PaymentMethods_add"><u>{{path}}explorer/#!/PaymentMethods/PaymentMethods_add</u></a> 

      </li>
  </ul>
 <br />  
   <p><i><code>Delete</code></i></p> 
   <ul>
       <li>
            This API is used to delete the payment method from `tbl_payment_methods` if it is not default payment method. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/PaymentMethods/PaymentMethods_delete"><u>{{path}}explorer/#!/PaymentMethods/PaymentMethods_delete</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>List</code></i></p> 
   <ul>
       <li>
            It will fetch all the payment methods list from `tbl_payment_methods`. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/PaymentMethods/PaymentMethods_list"><u>{{path}}explorer/#!/PaymentMethods/PaymentMethods_list</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>listById</code></i></p> 
   <ul>
       <li>
            This API will fetch the details of particular payment method from `tbl_payment_methods` by <strong>paymentMethodId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/PaymentMethods/PaymentMethods_listById"><u>{{path}}explorer/#!/PaymentMethods/PaymentMethods_listById</u></a> 

       </li>
   </ul>
 <br />  
   <p><i><code>Modify</code></i></p> 
   <ul>
       <li>
            This API is used to modify the details of payment method by <strong>paymentMethodId</strong>. 

       </li>
       <li>
            For reference: <a href="{{path}}explorer/#!/PaymentMethods/PaymentMethods_modify"><u>{{path}}explorer/#!/PaymentMethods/PaymentMethods_modify</u></a> 

       </li>
   </ul>
   </div>
</mat-card>