
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Tickets Replies Model</h2>
 <p><i><code> Delete</code></i></p> 
 <ul>
     <li>
            This API is used to delete the ticket replies from `tbl_tickets_replies` by <strong>Ticket replies Id</strong>. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/TicketsReplies/TicketsReplies_delete"><u>{{path}}explorer/#!/TicketsReplies/TicketsReplies_delete</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of ticket replies from `tbl_tickets_replies` by ticketId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketsReplies/TicketsReplies_list"><u>{{path}}explorer/#!/TicketsReplies/TicketsReplies_list</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
