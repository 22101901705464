import { StarRatingColor } from './../../../admin/utilities/knowledgebase/star-rating/star-rating.component';
import { Component, OnInit, Injector, Input } from '@angular/core';
import { ConfigService } from '../../../service/config.service';
import { AppComponentBase } from '../../../admin/shared/AppComponentBase';
import { KnowledgebaseService } from '../../../service/knowledgebase.service';
import { Subscription } from 'rxjs';
import { EStatusCode, IResponse } from '../../../service/Utils/Interfaces.class';
import moment from 'moment';
import { LoginService } from '../../../service/login.service';
import {TenantExistsService} from "../../../service/tenant-exists.service";
import { COMMON } from '../../../service/constant';
import { DomSanitizer } from '@angular/platform-browser';
import { TagInputModule } from 'ngx-chips';
import { HighlightPipe } from '../../../admin/shared/pipes/highlight.pipe';
import { CommonDatePipe } from '../../../admin/shared/pipes/common-date.pipe';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { FormsModule } from '@angular/forms';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-article-details',
    templateUrl: './article-details.component.html',
    styleUrls: ['./article-details.component.scss'],
    standalone: true,
    imports: [MatToolbar, FormsModule, StarRatingComponent, NgIf, NgFor, RouterLink, CommonDatePipe, HighlightPipe, TagInputModule]
})
export class ArticleDetailsComponent extends AppComponentBase implements OnInit {
  subscription: Subscription;
  artId;
  artName: string;
  categoryName: string;
  artDetails;
  tags: Array<{ name: string }> = [];
  isTrimmed: boolean = false;
  privateFlag: boolean = false;
  @Input() text: string;
  @Input() limit: number = 40;
  fullText;
  rating: number = 0;
  artType: number;
  artCategoryId: number;
  dateAdded;
  dateUpdated;
  hostname: string;
  catCount: number = 0;
  companyName: string = "UTILIKO";
  message: string;
  starCount: number = 5;
  starColor: StarRatingColor = StarRatingColor.accent;
  starColorP: StarRatingColor = StarRatingColor.primary;
  starColorW: StarRatingColor = StarRatingColor.warn;
  private articleDetailsSubscription : Subscription[] = [];
  private readonly subscriptions: Subscription[] = [];
  companyKey: string;
  noArticleFlag: boolean;
  relatedArticlesFlag: boolean = false;
  articleList = [];
  companyLogo: string = "";
  relatedArticleList = [];
  sectionArticleList = [];
  searchText = '';
  public version;
  public currYear;
  catId;

  constructor(inject: Injector, private tenantExistsService: TenantExistsService, public configService: ConfigService, public knowledgebaseService: KnowledgebaseService, private loginService: LoginService, public sanitizer: DomSanitizer) {
    super(inject);
    this.hostname = window.location.hostname;
    // this.hostname = '<enter domain name>'; // For local testing eg., 'utiliko.xyz'

    this.configService.updateIsUserLogged.next(false);
    // this.artId = Number(this.knowledgebaseService.getArticleDetailsId());
    // this.catId = Number(this.knowledgebaseService.getCategoryId());
    this.artId = Number(this.activatedRoute.snapshot.queryParams['artId']);
    this.catId = Number(this.activatedRoute.snapshot.queryParams['catId']);
    this.categoryName = this.activatedRoute.snapshot.paramMap.get('categoryName');
    this.artName = this.activatedRoute.snapshot.paramMap.get('articleName');
    this.checkDomain(this.hostname);
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
   }

  ngOnInit() {
    this.setWindowTitle('Knowledgebase');
  }
  ngOnDestroy() {
    this.articleDetailsSubscription.forEach((subscription : Subscription) => {
      if(subscription) {
        subscription.unsubscribe();
      }
    })
    this.subscriptions.forEach((subscription : Subscription) => {
      if(subscription) {
        subscription.unsubscribe();
      }
    })
  }
  onRatingChanged(rating) {
    this.rating = rating;
    var obj = {
      artId: Number(this.artId),
      rating: Number(rating)
    }
    const addRatingSub : Subscription = this.knowledgebaseService.addRatingFromUser(obj).subscribe((res:any)=>{
      if (res.info.code === EStatusCode.OK) {
        this.toastr.success("Article rated successfully");
      } else {
        this.toastr.error("Something went wrong. Please try again later");
      }
    });
    this.articleDetailsSubscription.push(addRatingSub);
  }

  getSanitizeHTML(html){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getArticleDetails() {
    this.knowledgebaseService.detailsByCatAndArticle(this.catId,this.artName,this.artId).subscribe((res: IResponse) => {

      if (res.info.code === EStatusCode.OK) {
        this.artDetails = res.data[0];
        this.artId = this.artDetails.artId;
        this.catId && this.getArticlesInSection(res.data[0].artCategoryId);
        var maxLength = 300 // maximum number of characters to extract
        if (this.artDetails.artContent.length > 300) {
          var trimmedString = this.artDetails.artContent.substr(0, maxLength);
          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
          trimmedString = trimmedString + "...";
          this.isTrimmed = true;
        } else {
          this.isTrimmed = false;
          var trimmedString = this.artDetails.artContent;
        }
        this.text = trimmedString;
        this.fullText = this.getSanitizeHTML(this.artDetails.artContent);
        this.rating = res.data[0].totalPoints;
        this.artType = res.data[0].artType;
        this.artCategoryId = res.data[0].artCategoryId;
        this.relatedArticleList = res.data[0].relatedArticlesList.map((e) => {
          e['routerLink'] = this.getRoutingLink(this.artDetails.name, e.artName);
          e['queryParams'] = this.getQueryParams(this.artDetails.artCategoryId, e.artId);
          return e;
        });;
        if (this.relatedArticleList.length > 0) {
          this.relatedArticlesFlag = true;
        } else {
          this.relatedArticlesFlag = false;
        }
        this.dateAdded = moment(this.artDetails.dateAdded).format("YYYY-MM-DD");
        // this.dateUpdated = moment(this.artDetails.dateUpdated).format("YYYY-MM-DD");
        this.dateUpdated = this.artDetails.dateUpdated ? moment(this.artDetails.dateUpdated).format("YYYY-MM-DD") : moment(this.artDetails.dateAdded).format("YYYY-MM-DD");
        var arr = [];
        var value_or_null = (document.cookie.match(/^(?:.*;)?\s*isViewCounted\s*=\s*([^;]+)(?:.*)?$/) || [, null])[1]
        var elementExists = false;
        if (value_or_null == null) {
          arr.push(this.artId)
          elementExists = false;
        } else {
          if (value_or_null.includes(this.artId)) {
            elementExists = true;
          } else {
            elementExists = false;
            arr = JSON.parse(value_or_null)
            arr.push(this.artId)
          }
        }
        if (!elementExists) {
          var obj1 = JSON.stringify(arr);
          var d = new Date();
          d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
          var expires = "expires=" + d.toUTCString();
          document.cookie = "isViewCounted" + "=" + obj1 + ";" + expires + ";path=/";
          this.knowledgebaseService.addArticleViewCount({ artId: Number(this.artId), views: Number(res.data[0].views + 1)}).subscribe((resp: any) => {
          });
        }
        this.privateFlag = false;
      } else {
        this.toastr.error(res.data.responseMsg);
        this.privateFlag = true;
        this.message = res.data.responseMsg
      }
    });
  }

  changePage(categoryName,articleName,artId, categoryId) {
    this.categoryName = categoryName;
    this.artName = articleName;
    this.artId = artId;
    this.catId = Number(categoryId);
    this.getArticleDetails();
    this.knowledgebaseService.articleDetail.artId = artId;
    this.knowledgebaseService.articleDetail.artCategoryId = categoryId;
    this.knowledgebaseService.setArticleDetailsId(artId);
    this.knowledgebaseService.setCategoryId(categoryId);
    this.knowledgebaseService.articleDetail.url = 'knowledgebase/details/';
    this.knowledgebaseService.detailsArticleEventListener.next(artId);
    // this.routerDialog.navigateByUrl(escape('knowledgebase/' + categoryName.split(" ").join("_").toLowerCase()+'/'+articleName.split(" ").join("_").toLowerCase()));
  }

  checkDomain(domain) {
    if (domain) {
      domain = domain.replace(/ /g, "").toLowerCase().trim();
      this.tenantExistsService.domainExists(domain).subscribe((res: any) => {
        if (!res.data.isDomainExist) {
          this.router.navigate(["/page-not-found"], { replaceUrl: true });
        } else {
          this.companyKey = res.data.tenantCompanyKey
          this.getArticleDetails();
          this.companyName = res.data.companyName;
          this.companyLogo = res.data.companyLogo;
        }
      });
    }
  }

  goback(){
    this.routerDialog.navigateByUrl('knowledgebase');
  }

  getArticlesInSection(categoryId){
    var loginuser = 0
    const getOverview: Subscription = this.knowledgebaseService.getRelatedArticles(loginuser,categoryId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.sectionArticleList = res.data.map((e) => {
          e['routerLink'] = this.getRoutingLink(this.categoryName, e.artName);
          e['queryParams'] = this.getQueryParams(this.catId, e.artId);
          e['categotyName'] = this.categoryName;
          return e;
        });
      }
    });
    this.subscriptions.push(getOverview);
  }

  trackSectionArticles(index:number, item){
    return item.artId;
  }

  trackRelatedArticles(index:number, item) {
    return index;
  }

  getRoutingLink(categoryName, articleName){
     let path = '/knowledgebase/' + categoryName.toLowerCase()+'/'+articleName.toLowerCase();
     return path.trim().replace(/(\s*-\s*|\s+)/g, '-')
  }

  getQueryParams(catId, artId){
    return {catId:catId,artId:artId};
  }
}
