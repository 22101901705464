
<section class="content-header">
    <div class="row">
      <div class="col-md-6">
        <h1 class="content-header-name"><mat-icon>notes</mat-icon>Release Notes</h1>
      </div>
    </div>          
</section>

<ng-container >
    
  <div *ngIf="versionId=='6.1.2'">
      <div class="col-lg-12">
          <mat-card>
          <h1 id="jhipster-release-v612">JHipster release v6.1.2</h1>

        <p>This is a patch release to fix a liquibase exception. See <a href="https://github.com/jhipster/generator-jhipster/issues/9973">#9973</a> for more information.</p>

        <h2 id="closed-tickets-and-merged-pull-requests">Closed tickets and merged pull requests</h2>
        <p>As always, <strong><a href="https://github.com/jhipster/generator-jhipster/issues?q=milestone%3A6.1.2+is%3Aclosed">you can check all closed tickets and merged pull requests here</a></strong>.</p>

        <h2 id="how-to-upgrade">How to upgrade</h2>

        <p><strong>Automatic upgrade</strong></p>

        <p>For an automatic upgrade, use the <a href="https://www.jhipster.tech/upgrading-an-application/">JHipster upgrade sub-generator</a> on an existing application:</p>

        <p>Upgrade your version of JHipster:</p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>npm update -g generator-jhipster
        </code></pre></div></div>

        <p>And then run the upgrade sub-generator:</p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster upgrade
        </code></pre></div></div>

        <p><strong>Manual upgrades</strong></p>

        <p>For a manual upgrade, first upgrade your version of JHipster with:</p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>npm update -g generator-jhipster
        </code></pre></div></div>

        <p>If you have an existing project, it will still use the JHipster version with which it was generated.
        To upgrade your project, you must first delete its <code class="highlighter-rouge">node_modules</code> folder and then run:</p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster
        </code></pre></div></div>

        <p>You can also update your project and all its entities by running</p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster --with-entities
        </code></pre></div></div>

        <p>You can also update your entities one-by-one by running again the entity sub-generator, for example if your entity is named <em>Foo</em></p>

        <div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster entity Foo
        </code></pre></div></div>
        params
        <h2 id="hparamselp-and-bugs">Help and bugs</h2>
        params
        <p>If youparams find any issue with this release, don’t hesitate to:</p>

        <ul>
        <li>Add a bug on our <a href="https://github.com/jhipster/generator-jhipster/issues?state=open">bug tracker</a></li>
        <li>Post a question on <a href="https://stackoverflow.com/tags/jhipster/info">Stack Overflow</a></li>
        </ul>

        <p>If the issue you have is an urgent bug or security issue, please:</p>

        <ul>
        <li>Contact <a href="https://twitter.com/java_hipster">{{ '@java_hipster' }}</a> on Twitter</li>
        </ul>
      </mat-card>
        </div>
  </div>




  <div *ngIf="versionId=='6.1.1'">
  <div class="col-lg-12">
      <mat-card>
      <h1 id="jhipster-release-v611">JHipster release v6.1.1</h1>

<p>This is an emergency patch release because of a broken transitive dependency, that blocks our command line. see <a href="https://github.com/jhipster/generator-jhipster/issues/9952">#9952</a> for more information.</p>

<p>Apart from this blocker issue, this release also includes many improvements, here are the most important ones:</p>

<ul>
<li>Lots of work to improve how faker.js generates fake data  - <a href="https://github.com/jhipster/generator-jhipster/pull/9862">#9862</a> <a href="https://github.com/jhipster/generator-jhipster/pull/9663">#9663</a> <a href="https://github.com/jhipster/generator-jhipster/pull/9890">#9890</a></li>
<li>Update to Spring Boot 2.1.6 - <a href="https://github.com/jhipster/generator-jhipster/pull/9930">#9930</a></li>
</ul>

<h2 id="closed-tickets-and-merged-pull-requests">Closed tickets and merged pull requests</h2>
<p>As always, <strong><a href="https://github.com/jhipster/generator-jhipster/issues?q=milestone%3A6.1.1+is%3Aclosed">you can check all closed tickets and merged pull requests here</a></strong>.</p>

<h2 id="how-to-upgrade">How to upgrade</h2>

<p><strong>Automatic upgrade</strong></p>

<p>For an automatic upgrade, use the <a href="https://www.jhipster.tech/upgrading-an-application/">JHipster upgrade sub-generator</a> on an existing application:</p>

<p>Upgrade your version of JHipster:</p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>npm update -g generator-jhipster
</code></pre></div></div>

<p>And then run the upgrade sub-generator:</p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster upgrade
</code></pre></div></div>

<p><strong>Manual upgrades</strong></p>

<p>For a manual upgrade, first upgrade your version of JHipster with:</p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>npm update -g generator-jhipster
</code></pre></div></div>

<p>If you have an existing project, it will still use the JHipster version with which it was generated.
To upgrade your project, you must first delete its <code class="highlighter-rouge">node_modules</code> folder and then run:</p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster
</code></pre></div></div>

<p>You can also update your project and all its entities by running</p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster --with-entities
</code></pre></div></div>

<p>You can also update your entities one-by-one by running again the entity sub-generator, for example if your entity is named <em>Foo</em></p>

<div class="highlighter-rouge"><div class="highlight"><pre class="highlight"><code>jhipster entity Foo
</code></pre></div></div>

<h2 id="help-and-bugs">Help and bugs</h2>

<p>If you find any issue with this release, don’t hesitate to:</p>

<ul>
<li>Add a bug on our <a href="https://github.com/jhipster/generator-jhipster/issues?state=open">bug tracker</a></li>
<li>Post a question on <a href="https://stackoverflow.com/tags/jhipster/info">Stack Overflow</a></li>
</ul>

<p>If the issue you have is an urgent bug or security issue, please:</p>

<ul>
<li>Contact <a href="https://twitter.com/java_hipster">{{ '@java_hipster' }}</a> on Twitter</li>
</ul>
</mat-card>
  </div>
</div>

</ng-container>


