
<section class="content-header">
        <div class="row">
          <div class="col-md-6 header" >
            <h1 class="headerName"><mat-icon>notes</mat-icon>API Docs</h1>
          </div>
        </div>          
    </section>

<mat-grid-list cols="4"  rowHeight="80px" class="api-docs-list">
    <mat-grid-tile class="tiles" >
        <mat-card>           
            <div class="skill-card">        
                <section class="skill-card__body">
                  <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="intro" ><mat-icon>file_copy</mat-icon> Introduction </a></h2>
                </section>
              </div>            
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">                
                <section class="skill-card__body">
                  <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="config-files" ><mat-icon>file_copy</mat-icon> Configuration Files </a></h2>
                </section>
              </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="models" ><mat-icon>file_copy</mat-icon> Models </a></h2>
                </section>
              </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
      <mat-card>
          <div class="skill-card">
               
              <section class="skill-card__body">
                  <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="access-rules-models" ><mat-icon>file_copy</mat-icon> Access Rules  </a></h2>
                  </section>
            </div>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
      <mat-card>
          <div class="skill-card">
               
              <section class="skill-card__body">
                  <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="account-details-model" ><mat-icon>file_copy</mat-icon> Account Details  </a></h2>
              </section>
            </div>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="accounts-model" ><mat-icon>file_copy</mat-icon> Accounts  </a></h2>
                  </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="activities-model" ><mat-icon>file_copy</mat-icon> Activities  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
      <mat-card>
        <div class="skill-card">
             
            <section class="skill-card__body">
                <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="agreements-model" ><mat-icon>file_copy</mat-icon> Agreements  </a></h2>
            </section>
          </div>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="attachments-model" ><mat-icon>file_copy</mat-icon> Attachments  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="attendance-model" ><mat-icon>file_copy</mat-icon> Attendance  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="bills-model" ><mat-icon>file_copy</mat-icon> Bills  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="bugs-model" ><mat-icon>file_copy</mat-icon> Bugs  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="calls-model" ><mat-icon>file_copy</mat-icon> Calls  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="chart-of-accounts-model" ><mat-icon>file_copy</mat-icon> Chart Of Accounts  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="client-model" ><mat-icon>file_copy</mat-icon> Client  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="comments-model" ><mat-icon>file_copy</mat-icon> Comments  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="commission-calculation-model" ><mat-icon>file_copy</mat-icon> CommissionCalculation  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="config-model" ><mat-icon>file_copy</mat-icon> Config  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="contact-model" ><mat-icon>file_copy</mat-icon> Contact  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="credit-amount-model" ><mat-icon>file_copy</mat-icon> Credit Amount  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="currency-model" ><mat-icon>file_copy</mat-icon> Currency  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="currencystate-model" ><mat-icon>file_copy</mat-icon> Currency State  </a></h2>
                    </section>
                  </div>
            </mat-card>
        </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="deadline-model" ><mat-icon>file_copy</mat-icon> Deadline  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="departments-model" ><mat-icon>file_copy</mat-icon> Departments  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="email-templates-model" ><mat-icon>file_copy</mat-icon> Email Templates  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="employees-model" ><mat-icon>file_copy</mat-icon> Employees  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
        <mat-card>
            <div class="skill-card">
                 
                <section class="skill-card__body">
                    <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="estimates-model" ><mat-icon>file_copy</mat-icon> Estimates  </a></h2>
                </section>
              </div>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="global-model" ><mat-icon>file_copy</mat-icon> Global  </a></h2>
                    </section>
                  </div>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="goaltype-model" ><mat-icon>file_copy</mat-icon> Goal Type  </a></h2>
                        </section>
                      </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
            <mat-card>
                 <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="invoice-model" ><mat-icon>file_copy</mat-icon> Invoice  </a></h2>
                    </section>
                </div>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="languages-model" ><mat-icon>file_copy</mat-icon> Languages  </a></h2>
                    </section>
                </div>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="lead-contacts-model" ><mat-icon>file_copy</mat-icon> Lead Contacts  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="leads-model" ><mat-icon>file_copy</mat-icon> Leads  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="lead-source-model" ><mat-icon>file_copy</mat-icon> Lead Source  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="lead-status-model" ><mat-icon>file_copy</mat-icon> Lead Status  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="leave-category-model" ><mat-icon>file_copy</mat-icon> Leave Category  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="locale-model" ><mat-icon>file_copy</mat-icon> Locale  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="mailbox-model" ><mat-icon>file_copy</mat-icon> MailBox  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="meetings-model" ><mat-icon>file_copy</mat-icon> Meetings  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="menu-model" ><mat-icon>file_copy</mat-icon> Menu  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="milestones-model" ><mat-icon>file_copy</mat-icon> Milestones  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="models" ><mat-icon>file_copy</mat-icon> Models </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="notes-model" ><mat-icon>file_copy</mat-icon> Notes  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="opportunities-model" ><mat-icon>file_copy</mat-icon> Opportunities  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="opportunitiesstatereason-model" ><mat-icon>file_copy</mat-icon> Opportunities State </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="opportunitypipelines-model" ><mat-icon>file_copy</mat-icon> Pipelines  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="opportunitysource-model" ><mat-icon>file_copy</mat-icon>  Source  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="currentstateoppo-model" ><mat-icon>file_copy</mat-icon> Current State   </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="oppostatereasonparent-model" ><mat-icon>file_copy</mat-icon>  State Reason Parent  </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="payment-info-model" ><mat-icon>file_copy</mat-icon> Payment-Info </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="payment-method-model" ><mat-icon>file_copy</mat-icon> Payment-Method </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="payments-model" ><mat-icon>file_copy</mat-icon> Payments </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="pinaction-model" ><mat-icon>file_copy</mat-icon> PinAction </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
        <mat-grid-tile class="tiles">
                <mat-card>
                    <div class="skill-card">
                         
                        <section class="skill-card__body">
                            <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="policy-model" ><mat-icon>file_copy</mat-icon> Policy </a></h2>
                        </section>
                    </div>
                </mat-card>
        </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="priorities-model" ><mat-icon>file_copy</mat-icon> Priorities </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="project-model" ><mat-icon>file_copy</mat-icon> Project </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="project-category-model" ><mat-icon>file_copy</mat-icon> Project-Catgory </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="project-pipeline-model" ><mat-icon>file_copy</mat-icon> Project-Pipeline </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="project-reason-model" ><mat-icon>file_copy</mat-icon> Project-Reason </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="proposals-model" ><mat-icon>file_copy</mat-icon> Proposals </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="purchase-order-model" ><mat-icon>file_copy</mat-icon> Purchase Order </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="refunds-model" ><mat-icon>file_copy</mat-icon> Refunds </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="resources-model" ><mat-icon>file_copy</mat-icon> Resources </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="roles-model" ><mat-icon>file_copy</mat-icon> Roles </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="sales-tax-invoice-model" ><mat-icon>file_copy</mat-icon> Sales-Tax-Invoice </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="saved-items-model" ><mat-icon>file_copy</mat-icon> Saved-Items </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="status-model" ><mat-icon>file_copy</mat-icon> Status </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="subscribe-payment-info-model" ><mat-icon>file_copy</mat-icon> Subscribe-Payment </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="task-model" ><mat-icon>file_copy</mat-icon> Task </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="task-uploaded-files-model" ><mat-icon>file_copy</mat-icon> Task-Uploaded-Files </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tax-rates-model" ><mat-icon>file_copy</mat-icon> Tax-Rates </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tenant-companies-model" ><mat-icon>file_copy</mat-icon> Tenant-Companies </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tenants-model" ><mat-icon>file_copy</mat-icon> Tenants </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tickets-category-model" ><mat-icon>file_copy</mat-icon> Ticket-Category </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tickets-model" ><mat-icon>file_copy</mat-icon> Tickets </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="tickets-replies-model" ><mat-icon>file_copy</mat-icon> Tickets-Replies </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="timbar-model" ><mat-icon>file_copy</mat-icon> Timbar </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="timesheet-model" ><mat-icon>file_copy</mat-icon> Timesheet </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="transaction-model" ><mat-icon>file_copy</mat-icon> Transaction </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="types-of-coa-model" ><mat-icon>file_copy</mat-icon> Types-of-COA </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="users-model" ><mat-icon>file_copy</mat-icon> Users </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="vendor-model" ><mat-icon>file_copy</mat-icon> Vendor </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="visibility-model" ><mat-icon>file_copy</mat-icon> Visibility </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="write-check-model" ><mat-icon>file_copy</mat-icon> Write-Check </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
    <mat-grid-tile class="tiles">
            <mat-card>
                <div class="skill-card">
                     
                    <section class="skill-card__body">
                        <h2 class="skill-card__title"><a routerLinkActive="active-link" routerLink="helper-model" ><mat-icon>file_copy</mat-icon> Helper </a></h2>
                    </section>
                </div>
            </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
   
        <router-outlet></router-outlet>
    
  