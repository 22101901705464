
<app-global-search *ngIf="loginType !== 2"></app-global-search>
<div id="headLeft" [ngClass]="loginType === 2 ? 'bottom-style' : ''">
  <!--Help-->
  <button  mat-icon-button matTooltip="Help" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="SuportMenu">
    <mat-icon  #step1 class="header-icon buttonAlignPadding">help_outline</mat-icon>
    <app-overlay-tour *ngIf="activeTourIndex === 1" [connectedTo]="step1" title={{label}} data={{icon}} text={{text}} id="1"
      position="botttom"></app-overlay-tour>
  </button>
 
  <mat-menu  #SuportMenu="matMenu" xPosition="before" backdropClass="notification-backdropOne" >
    <div class="notification-area tw-p-2">
      <i class="fa fa-caret-up openpopupOne" aria-hidden="true"></i>
      <div class="header_labelOne " (click)="$event.stopPropagation(); false;">
        <b>Resource Center</b>  
        <p class="fontSize">Get started with these tools below.</p>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="resourceIconSize text-alin-class align_left">        
            <a href="/release-notes" target="_blank"><mat-icon matTooltip="Release Notes">note</mat-icon></a>
          </div>
          <div class="textAlignCent">
            <a href="/release-notes" target="_blank" >Release Notes</a>
          </div>   
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="resourceIconSize">
            <div class="container resourceIconSize align_left">        
              <a href="https://www.utiliko.io/knowledge-center/" target="_blank"><mat-icon  matTooltip="Knowledge Center">school</mat-icon></a>
            </div>
            <div class="textAlignCent">
              <a href="https://www.utiliko.io/knowledge-center/" target="_blank" >Knowledge Center</a>
            </div>
            
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="resourceIconSize">
            <div class="container resourceIconSize align_left">        
              <a href="https://www.utiliko.io/support/" target="_blank"><mat-icon  matTooltip="Contact US">contact_support</mat-icon></a>
            </div>
            <div class="textAlignCent">
              <a href="https://www.utiliko.io/support/" target="_blank" >Contact US</a>
            </div>    
          </div>
        </div>  
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="loginType === 1">
            <div class="resourceIconSize">
              <div class="container resourceIconSize align_left">        
                <a (click)="utilikoTour()"><mat-icon  matTooltip="Utiliko Tour">chat_bubble</mat-icon></a>
              </div>
              <div class="textAlignCent">
                <a class="hand-cursor" (click)="utilikoTour()">Utiliko Tour</a>
              </div>
              
            </div>
          </div>
      </div>
    </div>
  </mat-menu>
  
  <!-- Create new  -->
  
  <button mat-icon-button class="createNew-btn" matTooltip="Create New" [matMenuTriggerFor]="menu1">
    <i #step6 class="fas fa-plus-circle"></i>
    <app-overlay-tour *ngIf="activeTourIndex === 6" [connectedTo]="step6" [routerLink]="activeTourIndexLink" title={{label}}
      data={{icon}} text={{text}} id="6" position="botttom"></app-overlay-tour>
  </button>
    <mat-menu class="createDropdown" #menu1="matMenu" overlapTrigger="false">
      <div class="menuSplit" *ngIf="loginType === 1"
      [ngClass]="(!permissionService.client.edit && !permissionService.leads.edit && !permissionService.vendor.edit) ?'horizontal-line' : ''">
      <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('admin/client', $event)"
      *ngIf="permissionService.client.edit">
      <div mat-line><i class="fas fa-users"></i> &nbsp; New Client</div><br>
    </mat-list-item>
    <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('sales/leads', $event)"
    *ngIf="permissionService.leads.edit">
    <div mat-line><i class="fas fa-rocket"></i> &nbsp; New Lead</div><br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('admin/vendor', $event)"
  *ngIf="permissionService.vendor.edit">
  <div mat-line><i class="fas fa-user"></i> &nbsp; New Vendor</div><br>
</mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('admin/mailbox', $event)" *ngIf="permissionService.mailbox.edit">
    <div mat-line><i class="fas fa-envelope"></i> &nbsp; New Email</div><br>
  </mat-list-item>
</div>
<div class="menuSplit" *ngIf="loginType === 1"
[ngClass]="(loginType === 1 && !permissionService.opportunities.edit && !permissionService.estimates.edit && !permissionService.invoice.edit && !permissionService.rMA.edit && !permissionService.purchaseOrder.edit && !permissionService.proposals.edit) ? 'horizontal-line' : ''">
<mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('sales/opportunities', $event)"
*ngIf="permissionService.opportunities.edit">
<div mat-line><i class="fas fa-filter"></i> &nbsp; New Opportunity</div><br>
</mat-list-item>
<mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('sales/estimates', $event)"
*ngIf="permissionService.estimates.edit">
<div mat-line><i class="fas fa-equals"></i> &nbsp; New Estimate</div><br>
</mat-list-item>

<mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('support/rma', $event)"
*ngIf="permissionService.rMA.edit">
<div mat-line><i class="fas fa-copy"></i> &nbsp; New RMA</div><br>
</mat-list-item>

<mat-list-item class="headMenu col-md-6 hand-cursor" *ngIf="permissionService.proposals.edit" (click)="clickLink('sales/proposals/form', $event)">
  <div mat-line><i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp; New Proposal</div><br>
</mat-list-item>

</div>
<div class="menuSplit"
[ngClass]="((loginType === 1 && !permissionService.projects.edit && !permissionService.bugs.edit && !permissionService.tickets.edit && !permissionService.tasks.edit) || loginType === 2) ? 'horizontal-line' : ''">

<mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('client/rma', $event)"
*ngIf="loginType === 2">
<div mat-line><i class="fas fa-copy"></i> &nbsp; New RMA</div><br>
</mat-list-item>
<mat-list-item class="headMenu col-md-6 hand-cursor"
(click)="clickLink((loginType === 1 ? 'support/project': 'client/project'), $event)"
*ngIf="(loginType === 1 && permissionService.projects.edit) || (loginType === 2)">
<div mat-line><i class="fas fa-project-diagram"></i> &nbsp; New Project</div><br>
</mat-list-item>
<mat-list-item class="headMenu col-md-6 hand-cursor"
(click)="clickLink('support/bugs', $event)"
*ngIf="(loginType === 1 && permissionService.bugs.edit)">
<div mat-line><i class="fas fa-bug"></i> &nbsp; New Bug</div><br>
</mat-list-item>
<mat-list-item class="headMenu col-md-6 hand-cursor"
(click)="clickLink((loginType === 1 ? 'support/tickets' : 'client/tickets'), $event)"
*ngIf="(loginType === 1 && permissionService.tickets.edit) || (loginType === 2)">
<div mat-line><i class="fas fa-ticket-alt"></i> &nbsp; New Ticket</div><br>
</mat-list-item>
<mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('admin/tasks', $event)"
*ngIf="(loginType === 1 && permissionService.tasks.edit)">
<div mat-line><i class="fas fa-tasks"></i> &nbsp; New Task</div><br>
</mat-list-item>
</div>
<div class="menuSplit" *ngIf="loginType === 1">
  <!-- <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('admin/goal_tracking')"
    *ngIf="permissionService.goalTracking.edit">
    <div mat-line><i class="fab fa-galactic-republic"></i> &nbsp; Set New Goals</div><br>
  </mat-list-item> -->

  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/transactions/journal-entries/form', $event, 1)" *ngIf="permissionService.journalEntries.edit">
      <div mat-line><i class="fa fa-book"></i> 
        <a class="open-new" routerLink="accounting/transactions/journal-entries/form" rel="noreferrer;" >
          &nbsp; New Manual JE
        </a>
      </div><br>
  </mat-list-item>

  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('accounting/invoice/form', $event, 1)"
    *ngIf="permissionService.invoice.edit">
    <div mat-line><i class="fas fa-file-invoice"></i> 
      <a class="open-new" routerLink="/accounting/invoice/form" rel="noreferrer;" >
        &nbsp; New Invoice
      </a>
    </div><br>
  </mat-list-item>

  <mat-list-item class="headMenu col-md-6 hand-cursor"
    (click)="clickLink('accounting/paymentsReceived/make-payment', $event, 1)" *ngIf="permissionService.receivePayments.edit">
    <div mat-line><i class="fas fa-hand-holding-usd"></i> 
      <a class="open-new" routerLink="/accounting/paymentsReceived/make-payment" rel="noreferrer;" >
        &nbsp; Receive a Payment
      </a>
    </div><br>
  </mat-list-item>

  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('accounting/purchaseOrder/form', $event, 1)"
      *ngIf="permissionService.purchaseOrder.edit">
      <div mat-line><i class="fas fa-receipt"></i> 
        <a class="open-new" routerLink="/accounting/purchaseOrder/form" rel="noreferrer;" >
          &nbsp; New purchase Order
        </a>
      </div><br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('accounting/transactions/bills/form', $event, 1)"
    *ngIf="permissionService.bills.edit">
    <div mat-line>
      <i class="far fa-money-bill-alt"></i> 
      <a class="open-new" routerLink="/accounting/transactions/bills/form" rel="noreferrer;" >       
        &nbsp; New Bill
      </a>
    </div>
    <br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor" (click)="clickLink('/accounting/transactions/pay-bills/form', $event, 1)"
    *ngIf="permissionService.payBills.edit">
    <div mat-line>
      <i class="far fa-money-bill-alt"></i> 
      <a class="open-new" routerLink="/accounting/transactions/pay-bills/form" rel="noreferrer;" >       
        &nbsp; Pay a Bill
      </a>
    </div>
    <br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/transactions/write-checks/form', $event, 1)" *ngIf="permissionService.writeCheck.edit">
      <div mat-line><i class="fas fa-pen-alt"></i> 
        <a class="open-new" routerLink="/accounting/transactions/write-checks/form" rel="noreferrer;" >
          &nbsp; Write a Check
        </a>
    </div><br>
  </mat-list-item>

  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/transactions/credit-note/form', $event, 1)" *ngIf="permissionService.creditNote.edit">
      <div mat-line><i class="far fa-circle"></i> 
        <a class="open-new" routerLink="/accounting/transactions/credit-note/form" rel="noreferrer;" >
          &nbsp; New Client Credit Note
        </a>
      </div><br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/transactions/credit-note/vendor-credit-note-form', $event, 1)"
      *ngIf="permissionService.creditNote.edit">
      <div mat-line><i class="far fa-circle"></i> &nbsp; 
        <a class="open-new" routerLink="/accounting/transactions/credit-note/vendor-credit-note-form" rel="noreferrer;" >
          <span class="new-credit-item"> New Vendor Credit Note</span>
        </a>
      </div><br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/paymentsReceived/form', $event, 1)"
      *ngIf="permissionService.paymentsDetails.edit">
      <div mat-line><i class="fas fa-hand-holding-usd"></i> 
        <a class="open-new" routerLink="/accounting/paymentsReceived/form" rel="noreferrer;" >
          &nbsp; New Client Refund  
        </a>
      </div><br>
  </mat-list-item>
  <mat-list-item class="headMenu col-md-6 hand-cursor"
      (click)="clickLink('accounting/transactions/pay-bills/pay-bill-refund-form', $event, 1)"
      *ngIf="permissionService.payBills.edit">
      <div mat-line><i class="fas fa-receipt"></i> 
        <a class="open-new" routerLink="/accounting/transactions/pay-bills/pay-bill-refund-form" rel="noreferrer;" >
          &nbsp; New Vendor Refund
        </a>
      </div><br>
  </mat-list-item>

</div>
</mat-menu>

<!--Notifications-->
<button mat-icon-button matTooltip="Notifications" #menuTrigger="matMenuTrigger" (click)="notify(0, '', '')"
  [matMenuTriggerFor]="notifyAll">
  <mat-icon #step5 matBadge="{{unReadCount}}" class="m-r-20 header-icon index">notifications_none</mat-icon>
  <app-overlay-tour *ngIf="activeTourIndex === 5" [connectedTo]="step5" title={{label}} data={{icon}} text={{text}}
    id="5" position="botttom"></app-overlay-tour>
</button>
<mat-menu #notifyAll="matMenu" backdropClass="notification-backdrop">
  <div class="notification-area">
    <i class="fa fa-caret-up openpopup" aria-hidden="true"></i>
    <!--unread count-->
    <div class="header_label" >
      <span (click)="$event.stopPropagation(); false;">
        {{unReadCount}}<b> New</b> Notifications
      </span>
      <span>
        <a (click)="menuTrigger.closeMenu()">
          <i class="material-icons close-btn">clear</i>
        </a>
      </span>
    </div>
    <!--List of notification-->
    <section class="content" (click)="$event.stopPropagation(); false;">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup (selectedTabChange)="onChangeTab($event)">
        <!--All notification-->
        <mat-tab label="ALL ({{allCount}})" (click)="$event.stopPropagation(); false;">
          <div *ngIf="notifyScrollList.length" class="max-width-view" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50" (scrolled)="onScrollDown('all')" [scrollWindow]="false">
          <div class="flowWrapper clearfix notify-scroll-width">
            <div class="flowContainer focus notify-scroll">
              <mat-list-item class="notification-detail">
                <div *ngFor="let element of notifyScrollList">
                  <div class="clearfix nr-size"
                  *ngIf="(element.module === 'opportunity'&& permissionService.opportunities.view)||(element.module === 'task'&& permissionService.tasks.view)||(element.module === 'payment'&& permissionService.receivePayments.view)||(element.module === 'project'&& permissionService.projects.view)||(element.module === 'tickets'&& permissionService.tickets.view) 
                  || ( element.module === 'client'  && permissionService.clientAgreements.view) 
                  || (element.module === 'employee' && permissionService.employeesAgreements.view) 
                  || ( element.module === 'vendor' && permissionService.vendorAgreements.view)
                  ||(element.module === 'estimate' && permissionService.estimates.view) || (element.module === 'deadline'&& permissionService.opportunitiesDeadline.view) "
                  [ngClass]="element.isRead === '0' ? 'notification-unread' : ''" class="message-pad">
                  <span
                  *ngIf="( element.invRefNo||element.projectId||element.ticketId||element.opportunityId||element.taskId||element.agreementId||element.deadlineId||element.estRefNo)"
                  class="msgContainer"
                  (click)="viewNotificationDetails(element.module, element.notifyUserApplicationId, element.moduleId = element.module==='deadline' ? element.deadOppId:element.moduleId, 'all','details')">
                  <i class="fa fa-info-circle notify-icon refnoicon" aria-hidden="true"></i><a><span
                    class="ref_message">{{element.invRefNo||element.projectName||element.ticketWithCode||element.opportunityName||element.taskName||element.agreementName||element.deadlineName||element.estRefNo}}</span></a></span>
                    <span class="msgContainer"><i class="fa fa-exclamation-triangle notify-icon message"
                      aria-hidden="true"></i><span class="ref_message">{{element.title}}</span></span>
                      <span *ngIf="element.module === 'payment'" class="messageContainer"><i
                        class="far fa-comment-alt notify-icon message" aria-hidden="true"></i>
                        <span *ngIf="element.message.length > 100">{{element.message.substr(0,100)}}...</span>
                        <span *ngIf="element.message.length <= 100">{{element.message}}</span></span>
                        <span class="msgContainer"><i class="far fa-calendar notify-icon date-time"
                          aria-hidden="true"></i><span
                          class="notify-date">{{element.date | commonDate : 'date_time' }}</span></span>
                          <div class="msgContainer readstatus" *ngIf="element.isRead === '0'"
                          (click)="clearNotification(element.notifyUserApplicationId,'');"><i
                          *ngIf="element.isRead === '0'" class="far fa-circle notify-icon mark_read_icon"
                          aria-hidden="true"></i>Mark As Read
                        </div>
                      </div>
                    </div>
                  </mat-list-item>
                </div>
              </div>
            </div>
          </mat-tab>
          
          <!--un read notification list-->
          <mat-tab label="UNREAD ({{unReadCount}})" (click)="$event.stopPropagation(); false;">
            <div *ngIf="unReadScrollList.length" class="max-width-view" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50" (scrolled)="onScrollDown('unread')" [scrollWindow]="false">
            <div class="flowWrapper clearfix notify-scroll-width">
              <div class="flowContainer focus notify-scroll">
                <mat-list-item class="notification-detail">
                  <div *ngFor="let element of unReadScrollList">
                    <div class="clearfix nr-size"
                    *ngIf="((element.module === 'opportunity'&& permissionService.opportunities.view)||
                    (element.module === 'task' && permissionService.tasks.view)||
                    (element.module === 'payment'&& permissionService.receivePayments.view)||
                    (element.module === 'project'&& permissionService.projects.view)||
                    (element.module === 'tickets'&& permissionService.tickets.view) || 
                    (element.module === 'client'  && permissionService.clientAgreements.view) ||
                     (element.module === 'vendor' && permissionService.vendorAgreements.view) || 
                     (element.module === 'employee' && permissionService.employeesAgreements.view) || 
                     (element.module === 'estimate' && permissionService.estimates.view) || 
                     (element.module === 'deadline'&& permissionService.opportunitiesDeadline.view) ) && 
                     element.isRead ==='0'"
                    [ngClass]="element.isRead === '0' ? 'notification-unread' : ''" class="message-pad">
                    <span
                    *ngIf="( element.invRefNo||element.projectId||element.ticketId||element.opportunityId||element.taskId||element.agreementId||element.deadlineId||element.estRefNo )"
                    class="msgContainer"
                    (click)="viewNotificationDetails(element.module, element.notifyUserApplicationId, element.moduleId = element.module==='deadline' ? element.deadOppId:element.moduleId, 'unread','details');"><i
                    class="fa fa-info-circle notify-icon refnoicon" aria-hidden="true"></i>
                    <a><span
                      class="ref_message">{{element.invRefNo||element.projectName||element.ticketWithCode||element.opportunityName||element.taskName||element.agreementName||element.deadlineName||element.estRefNo}}</span></a>
                    </span>
                    <span class="msgContainer"><i class="fa fa-exclamation-triangle notify-icon message"></i><span
                      class="ref_message">{{element.title}}</span>
                    </span>
                    <span *ngIf="element.module === 'payment'" class="messageContainer"><i
                      class="far fa-comment-alt notify-icon message" aria-hidden="true"></i>
                      <span *ngIf="element.message.length > 100">{{element.message.substr(0,100)}}...</span>
                      <span *ngIf="element.message.length <= 100">{{element.message}}</span></span>
                      <span class="msgContainer">
                        <i class="far fa-calendar notify-icon date-time" aria-hidden="true"></i>
                        <span class="notify-date">{{element.date | commonDate : 'date_time' }}</span>
                      </span>
                      <span class="msgContainer readstatus"
                      (click)="clearNotification(element.notifyUserApplicationId,'')">
                      <i class="far fa-circle mark_read_icon notify-icon" aria-hidden="true">
                      </i>Mark As Read
                    </span>
                  </div>
                </div>
              </mat-list-item>
            </div>
          </div>
        </div>
      </mat-tab>
      
      <!--Read notification list-->
      <mat-tab label="READ ({{readCount}})" (click)="$event.stopPropagation(); false;">
        <div *ngIf="readNotifyScrollList.length" class="max-width-view" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" (scrolled)="onScrollDown('read')" [scrollWindow]="false">
        <div class="flowWrapper clearfix notify-scroll-width">
          <div class="flowContainer focus notify-scroll">
            <mat-list-item class="notification-detail">
              <div *ngFor="let element of readNotifyScrollList">
                
                <div class="clearfix nr-size"
                *ngIf="((element.module === 'opportunity'&& permissionService.opportunities.view)||(element.module === 'task'&& permissionService.tasks.view)||(element.module === 'payment'&& permissionService.receivePayments.view)||(element.module === 'project'&& permissionService.projects.view)||(element.module === 'tickets'&& permissionService.tickets.view)|| 
                ( element.module === 'client'  && permissionService.clientAgreements.view ) || 
                ( element.module === 'vendor' && permissionService.vendorAgreements.view ) || 
                (element.module === 'employee' && permissionService.employeesAgreements.view) || 
                (element.module === 'estimate' && permissionService.estimates.view) || (element.module === 'deadline'&& permissionService.opportunitiesDeadline.view) ) && element.isRead === '1'"
                [ngClass]="element.isRead === '0' ? 'notification-unread' : ''" class="message-pad"
                (click)="viewNotificationDetails(element.module, element.notifyUserApplicationId, element.moduleId = element.module === 'deadline' ? element.deadOppId : element.moduleId, 'read','details')">
                <span
                *ngIf="( element.invRefNo||element.projectId||element.ticketId||element.opportunityId||element.taskId||element.agreementId||element.deadlineId||element.estRefNo )"
                class="msgContainer">
                <i class="fa fa-info-circle notify-icon refnoicon" aria-hidden="true"></i>
                <a><span
                  class="ref_message">{{element.invRefNo||element.projectName||element.ticketWithCode||element.opportunityName||element.taskName||element.agreementName||element.deadlineName||element.estRefNo}}</span></a></span>
                  <span class="msgContainer"><i class="fa fa-exclamation-triangle notify-icon message"></i>
                    <span class="ref_message">{{element.title}}</span></span>
                    <span *ngIf="element.module === 'payment'" class="messageContainer"><i
                      class="far fa-comment-alt notify-icon message" aria-hidden="true"></i>
                      <span *ngIf="element.message.length > 100">{{element.message.substr(0,100)}}...</span>
                      <span *ngIf="element.message.length <= 100">{{element.message}}</span></span>
                      
                      <span class="msgContainer">
                        <i class="far fa-calendar notify-icon date-time" aria-hidden="true"></i>
                        <span class="notify-date">{{element.date | commonDate : 'date_time' }}</span>
                      </span>
                    </div>
                  </div>
                </mat-list-item>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      
      <!--no notification-->
      <div *ngIf="!notifyScrollList.length" class="no-notify">No notification found</div>
    </section>
  </div>
</mat-menu>

<button #step4  mat-icon-button class="tw-mx-2">
  <img *ngIf="userProfileImg; else defaultUserImg" [src]="userProfileImg" alt="{{userProfileImg}}" class="profile-pic"
  onError="this.src='../../../assets/img/default_user_profile.jpg'">
  <ng-template #defaultUserImg>
    <img src="../../../assets/img/default_user_profile.jpg" alt="No Image" class="profile-pic">
  </ng-template>
</button>

<app-overlay-tour *ngIf="activeTourIndex === 4" [connectedTo]="step4" title={{label}} data={{icon}} text={{text}} id="4"
  position="botttom"></app-overlay-tour>

    <button mat-button [matMenuTriggerFor]="profile">
        <span matTooltipPosition="above" matTooltipClass="basic-tooltip multiline-tooltip" [matTooltip]="userName">
            {{userName}}
        </span>

        <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
    </button>
</div>

<mat-menu #profile="matMenu" backdropClass="profile-dropdown">
  <form [formGroup]="changeLangComp">   
    <ng-select [items]="companyList" (click)="$event.stopPropagation(); false;"  class="custom-select company-dropdown" formControlName="compControl"
    (change)="changeCompany($event.value)" name="compControl" bindLabel="label" bindValue="value"
    placeholder="Change Company*" >
  </ng-select>
</form>
<div class="company-dropdown">
  <button (click)="userProfile()" mat-menu-item>
    <mat-icon>account_box</mat-icon>
    Profile
  </button>
  <button *ngIf="notification" mat-menu-item (click)="notificationToggle()">
    <mat-icon>notifications_off</mat-icon>
    Disable notifications
  </button>
  <button *ngIf="!notification" mat-menu-item (click)="notificationToggle()">
    <mat-icon>notifications</mat-icon>
    Enable notifications
  </button>
  <button *ngIf="loginType === 1 && presenceMonitoringFlag" (click)="openDialog()" mat-menu-item>
    <mat-icon class="presence_monitoring_icon">access_time</mat-icon>
    Presence Monitoring
  </button>
  <button *ngIf="showPreviousLogin" mat-menu-item (click)="previousLogin()">
    <mat-icon>arrow_back</mat-icon> 
    Previous Login
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>arrow_forward</mat-icon>
    Sign Out
  </button>
</div>
</mat-menu>
