
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
  </div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Chart-Of-Accounts Model </h2>

<p><i><code>List</code></i></p>
<ul>
<li>
    This API is used to fetch the list of chart of accounts 
    by accountType(optional) from `tbl_chart_of_accounts`.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_list" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_list</a>
</li>
</ul>
<br />

<p><i><code>listByIncomeAccount</code></i></p>
<ul>
<li>
    This API is used to fetch the list of income account if the below condition satisfies. 
    tbl_types_of_coa.account_name = 'Income' and tbl_chart_of_accounts.account_type = 1
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByIncomeAccount" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByIncomeAccount</a>
</li>
</ul>
<br />

<p><i><code>listByExpenseAccount</code></i></p>
<ul>
<li>
    This API is used to fetch the list of expense account from `tbl_chart_of_accounts`.
</li>
<li>
    Cond: tbl_types_of_coa.account_name = 'expense' and tbl_chart_of_accounts.account_type = 2
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByExpenseAccount" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByExpenseAccount</a>
</li>
</ul>
<br />

<p><i><code>treeData</code></i></p>
<ul>
<li>
    This API is used to create a tree structure for list of chart of accounts from 
    `tbl_chart_of_accounts` for front-end view purpose.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_treeData" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_treeData</a>
</li>
</ul>
<br />

<p><i><code>Delete</code></i></p>
<ul>
<li>
    Can’t able to delete the chart-of-accounts, once it has been created.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_treeData" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_treeData</a>
</li>
</ul>
<br />

<p><i><code>Add</code></i></p>
<ul>
<li>
    This API is used to create a new chart of accounts into 
    `tbl_chart_of_accounts` if accountName doesn’t exists.
</li>
<ul>
  <li>
      If accountType exists, it will add the data into `tbl_chart_of_accounts`.
  </li>
  <li>
      Else new account will be created in `tbl_accounts` and returns the accountId.
  </li>
</ul>
<li>
    Once account has been created successfully, it will create 
    a new chart of accounts with accountId in `tbl_chart_of_accounts`.
</li>
<li>
    Once chart of accounts has been created successfully, 
    it will update the coaId into `tbl_accounts` for the accountId returned by (2).
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_add" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_add</a>
</li>
</ul>
<br />

<p><i><code>Modify</code></i></p>
<ul>
<li>
    This API is used to modify/update the details of chart of accounts coaId.
</li>
<li>
    Error: accountName + ' already existing' - 
    when accountName exists and entered coaId != existingCOAResponse.coaId
</li>
<li>Condition:</li>
<ul>
  <li>
      If accountType exists, it will update the data into 
      `tbl_chart_of_accounts` and `tbl_accounts` if coaId exists.
  </li>
  <li>
      Else the details will be updated into `tbl_chart_of_accounts` if coaId exists.
  </li>
</ul>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_modify" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_modify</a>
</li>
</ul>
<br />

<p><i><code>Details</code></i></p>
<ul>
<li>
    This API will fetch the details of chart of accounts by coaId from `tbl_chart_of_accounts`.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_details" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_details</a>
</li>
</ul>
<br />

<p><i><code>getDashboardDetails</code></i></p> 
<ul>
<li>
    This API is used to list the chart of accounts for dashboard module, if 
    tbl_chart_of_accounts.account_type = 5 OR tbl_chart_of_accounts.account_type = 6 
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getDashboardDetails" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getDashboardDetails</a>
</li>
</ul>
<br />


<p><i><code>listByAccountType</code></i></p>
<ul>
<li>
    This API will fetch all the chart of accounts from `tbl_chart_of_accounts`.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByAccountType" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_listByAccountType</a>
</li>
</ul>
<br />

<p><i><code>getIncomeBreakdown</code></i></p>
<ul>
<li>
    This API will generate incomeBreakDown report for particular year.
</li>
<li>
    getIncomExpenseBreakdown1(res) - 
    method will return the incomeBreakDown values based on the below conditions.
</li>
<li>
    Query here - to fetch all the COA.
</li>
<li>
    Through the iteration, it will calculate the values if  
    item.account_type === '1' || item.account_type === '7' || item.account_type === '3' and  item.sub_account === 0(cond1).
</li>
<li>
    If cond1 satisfies getIncomeExpenseBreakdownByDate(mainAcc) 
    method will be called here(refer below).
</li>
<li>
    checkBy will be called here to get the subAccountData(refer below).
</li>
<li>
    If subAccountData is present, (it will call the getIncomeExpenseBreakdownByDate(subAcc) to get 
    the subAccount. If subAcc is present, it will merge the mainAcc and subAcc values to get the total income_amount for the particular COA) else it will get the income_amount from mainAccount(mainAcc).
</li>
<li>
    If res is there, it will add the color and income_amount for each account_name through iteration.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getIncomeBreakDown" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getIncomeBreakDown</a>
</li>
</ul>
<br />
<p><i><code>getExpenseBreakdown</code></i></p>
<ul>
<li>
     This API will generate incomeBreakDown report for particular year.
</li>
<li>
     This API will generate incomeBreakDown report for particular year.
</li>
<li>
     getIncomExpenseBreakdown(res1) - method will return the expenseBreakDown values based on the below conditions.
</li>
<li>
    This API will generate incomeBreakDown report for particular year.
</li>
<li>
    Query here - to fetch all the COA.
</li>
<li>
    Through the iteration, it will calculate the values if  
    item.account_type === 2 || item.account_type === 8 and  item.sub_account === 0(cond).
</li>
<li>
    If cond satisfies getIncomeExpenseBreakdownByDate(mainAcc) method will be called here(refer below).
</li>
<li>
    checkBy will be called here to get the subAccountData(refer below). 
</li>
<li>
    If subAccountData is present, (it will call the getIncomeExpenseBreakdownByDate(subAcc) to get the subAccount. If subAcc is present, it will merge the mainAcc and subAcc values to get the 
    total expense_amount for the particular COA) else it will get the expense_amount from mainAccount(mainAcc).
</li>
<li>
    If res1 is there, it will add the color and expense_amount for each account_name through iteration.
</li>
<li>
  For reference: <a href="{{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getExpenseBreakdown" >
    {{path}}explorer/#!/ChartOfAccounts/ChartOfAccounts_getExpenseBreakdown</a>
</li>
<li>
    getIncomeExpenseBreakdownByDate - Method will fetch the SUM(Income_amount) or SUM(Expense_amount) from `tbl_income_expense_record`
     if coa_income_id = coa_id or coa_expense_id=coa_id.  
</li>
<li>
    checkBy - method will fetch list of subAccounts by sub_account =  coa_id.
</li>
</ul>

</div>
</mat-card>
    