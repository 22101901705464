
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
        <h2 class="content-header-name">Write-Check-Model</h2>
        <p><i><code> Add</code></i></p>
        <ul>
            <li>
                This API is used to create a new check into `tbl_write_checks`.

            </li>
            <li>
                Before creating the check, below steps will be executed:
                <ul>
                    <li>
                        Check whether accountId exists in `tbl_chart_of_accounts`.

                    </li>
                    <li>
                        Default accountId will be fetched from `tbl_config` by <strong>configKey</strong><strong>:
                        </strong><strong>'default_account'</strong>.

                    </li>
                    <li>
                        Using the accountId balance amount will be updated into `tbl_chart_of_accounts`.

                    </li>
                </ul>
            </li>
            <li>
                Once check is created successfully, below steps will be executed:
                <ul>
                    <li>
                        Account and payee(client, vendor, user) details will be fetched from database.

                    </li>
                    <li>
                        Payments and transaction for this check will be created in `tbl_payments` and
                        `tbl_transactions`.

                    </li>
                    <li>
                        If expenseData exists, it will check whether expenseId is present in `tbl_chart_of_accounts` and
                        `tbl_expense_checks`. If exists balance will be updated into `tbl_chart_of_accounts` for that
                        expenseId through iteration.

                    </li>
                    <li>
                        Once expense data is updated into `tbl_chart_of_accounts`, expense check will be created into
                        `tbl_expense_checks`.

                    </li>
                </ul>
            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_add"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_add</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> getAllUsers</code></i></p>
        <ul>
            <li>
                This API is used to fetch all the users(client, vendor, user/tenant) list from `tbl_client`,
                `tbl_vendor` and `tbl_users` by tenantCompanyKey.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_getAllUsers"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_getAllUsers</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> getExpenseCategory</code></i></p>
        <ul>
            <li>
                This API will fetch the expense category list by accountType(2 or 3) from `tbl_chart_of_accounts`.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_getExpenseCategory"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_getExpenseCategory</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> getPayeeAddress</code></i></p>
        <ul>
            <li>
                This API is used to fetch the payee(client, vendor, user) address from `tbl_write_checks` by
                id(<strong>client_id_89, vendor_id_16, user_id_200</strong>).

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_getPayeeAddress"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_getPayeeAddress</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> List</code></i></p>
        <ul>
            <li>
                This API will fetch all the write checks list from `tbl_write_checks`.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_list"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_list</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> listById</code></i></p>
        <ul>
            <li>
                This API will fetch all the details of particular write check from `tbl_write_checks` by <strong>check
                    Id</strong>.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_listById"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_listById</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> Modify</code></i></p>
        <ul>
            <li>
                This API is used to modify the details of particular write check by <strong>check id</strong>.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_modify"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_modify</u></a>

            </li>
        </ul>
        <br />
        <p><i><code> viewCheckPDF</code></i></p>
        <ul>
            <li>
                This API is used to generate the write checks detail page in pdf format using 
                    <i><code> <strong>require</strong></code></i>(<strong>'html-pdf'</strong>). <br />
                
                    Eg:<br />https://storage.googleapis.com/milagro-test/vivant%2FCheck%2Faccounting%2FTes-t%20Sunita%2Fcheck%2F%2F1561643296201-test%20sunita_check_.pdf?GoogleAccessId=957890757836-compute%40developer.gserviceaccount.com&amp;Expires=4672043299&amp;Signature=E%2FiB7llPpnv3WJY0WeVQZrB4l7zcDxU2Thvsemx1VN3Pa0eAc2zQ-yTKtEDgVEJUXF0%2F1eybD%2ByMjh-BBgg8SetEt9hP5ZO4Rlg9rfehDcXCkMC-4MPQtzrpMCwoyeNTkgvBoWM59XgXJzmg%2Bodk%2B2Cy1lCECyqMJIX7N9bb-Z2rvK01hVhh6ZDVq%2FtNCIyAOtWpZAQPmXlSy8QcNkRlnqpj4GL-vd9dXizokVHgtrY54v%2FzUrq01KquhvvCvrxwGtVNcH-JJId4xRR6Wh5URlbkTKQ-VJG7K%2BUjp%2BXm1ByOyTq0EAwdXxq7dW-wuUuwCM2M38x8%2BoujR0dekkJwxd6qJ2VLCw%3D%3D

            </li>
            <li>
                Template for the purchase order details page will fetched from the `tbl_email_templates` using
                emailGroup: &lsquo;<strong>view_check_pdf</strong>&rsquo;.

            </li>
            <li>
                uploadFilesTOCloud method is used here.

            </li>
            <li>
                Fs.unlink method will delete file from local path.

            </li>
            <li>
                For reference: <a
                    href="{{path}}explorer/#!/WriteCheck/WriteCheck_viewCheckPDF"><u>{{path}}explorer/#!/WriteCheck/WriteCheck_viewCheckPDF</u></a>

            </li>
        </ul>
    </div>
</mat-card>