<div>
  <div mat-dialog-title class="modal-title">{{data.title}}
    <div class="pull-right">
      <mat-icon style="cursor: pointer;" (click)="closeModel()">close</mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <div class="card">
      <form novalidate [formGroup]="applyJobForm" class="md-float-material form-material job-form" action="javascript:">
        <div class="row">
          <div class="col-md-12">
            <div class="card-block" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'jobs.applications.apply.name' | translate }}</mat-label>
                <input matInput placeholder="{{ 'jobs.applications.apply.name' | translate }}" name="fullName"
                  formControlName="fullName" required>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card-block" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'jobs.applications.apply.email' | translate }}</mat-label>
                <input matInput placeholder="{{ 'jobs.applications.apply.email' | translate }}" name="email"
                  formControlName="email" required>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-block" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'jobs.applications.apply.mobile' | translate }}</mat-label>
                <input matInput placeholder="{{ 'jobs.applications.apply.mobile' | translate }}" name="phone"
                  formControlName="phone" required>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card-block tw-mt-2">
              <mat-form-field appearance="outline">
                <mat-label>Job Position</mat-label>
                <mat-select placeholder="Select Job Position" formControlName="jobPosition">
                  <mat-option *ngFor="let position of jobPosition" [value]="position.label" [attr.selected]="position.label === data.jobDetails.jobPosition ? true : null">{{ position.label }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-block tw-mt-2">
              <mat-form-field appearance="outline">
                <mat-label>Experience</mat-label>
                <mat-select placeholder="Select Experience" formControlName="experience">
                  <mat-option value="Fresher">Fresher</mat-option>
                  <mat-option value="1-2">1-2 years</mat-option>
                  <mat-option value="2-4">2-4 years</mat-option>
                  <mat-option value="4-6">4-6 years</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="card-block tw-mt-2" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>LinkedIn URL</mat-label>
                <input matInput placeholder="LinkedIn URL" name="linkedinUrl" formControlName="linkedinUrl">
                <!-- Add validation message if needed -->
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-block tw-mt-2">
              <mat-form-field appearance="outline">
                <mat-label>How did you hear about us</mat-label>
                <mat-select placeholder="Select Option" formControlName="hearUs">
                  <mat-option value="Facebook">Facebook</mat-option>
                  <mat-option value="Twitter">Twitter</mat-option>
                  <mat-option value="Linkedin">Linkedin</mat-option>
                  <mat-option value="JobBoard">Job Board</mat-option>
                  <mat-option value="Other">Other</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="card-block tw-mt-2">
              <mat-form-field appearance="outline">
                <mat-label>Notice Period</mat-label>
                <mat-select placeholder="Select Notice Period" formControlName="noticePeriod">
                  <mat-option value="Immediate">Immediate</mat-option>
                  <mat-option value="0-15Days">0-15 Days</mat-option>
                  <mat-option value="15-30Days">15-30 Days</mat-option>
                  <mat-option value="30-90Days">30-90 Days</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-block tw-mt-2" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>Current CTC</mat-label>
                <input matInput placeholder="Current CTC" name="currentCTC" formControlName="currentCTC">
                <!-- Add validation message if needed -->
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card-block tw-mt-2" id="namecheck">
              <mat-form-field appearance="outline">
                <mat-label>Expected CTC</mat-label>
                <input matInput placeholder="Expected CTC" name="expectedCTC" formControlName="expectedCTC">
                <!-- Add validation message if needed -->
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card-block tw-mt-2" id="namecheck">
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput formControlName="message" name="message"></textarea>
                <mat-label>Why should we hire you</mat-label>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group form-default m-b-5 form-static-label tw-mt-2">
              <span class="m-r-10">
                <button name="selectBtn" type="button" mat-raised-button color="primary" (click)="inputFile.click()">{{
                   'tickets.tickets-form.selectFile' |
                   translate
                   }}</button>
                <input #inputFile class="input-file" type="file" id="my-file" name="files" (change)="onSelectFile($event)" >
              </span>
            </div>
            <div *ngIf="totalCompressedImg.length > 0">
              <div class="form-group form-default m-b-5 form-static-label uploadAttacment"
                *ngFor="let upFile of totalCompressedImg; index as i">
                <span class="upFile" *ngIf="upFile?.extension === 'jpg' || upFile?.extension === 'jpeg' || upFile?.extension === 'png'">
                  <span class="mailbox-attachment-icon has-img clearfix">
                    <img [src]="_albums[i].thumb" (click)="showImage(upFile?.name)" alt="No image found"
                      class="uploadAttImg" />
                  </span>
                  <div class="mailbox-attachment-info clearfix">
                    <span class="mailbox-attachment-size clearfix m-t-5">
                      <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                      <a (click)="showImage(upFile?.name)" class="btn btn-default btn-xs m-r-5" title="View"><i
                          class=" fa fa-eye text-primary"></i></a>
                      <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                          class="fai fa-trash-o text-danger "></i></a>
                    </span>
                  </div>
                </span>

                <span class="" *ngIf="upFile?.extension === 'pdf'">
                  <span class="mailbox-attachment-icon has-img clearfix">
                    <img src="assets/img/pdf.png" alt="No File Found" class="uploadAttImg pdfImg" />
                  </span>
                  <div class="mailbox-attachment-info clearfix">
                    <span class="mailbox-attachment-size clearfix m-t-5">
                      <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                      <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                          class="fai fa-trash-o text-danger "></i></a>
                    </span>
                  </div>
                </span>

                <span class="" *ngIf="upFile?.extension === 'doc' || upFile?.extension === 'docx'">
                  <span class="mailbox-attachment-icon has-img clearfix">
                    <img src="assets/img/doc.png" alt="No File Found" class="uploadAttImg docImg" />
                  </span>
                  <div class="mailbox-attachment-info clearfix">
                    <span class="mailbox-attachment-size clearfix m-t-5">
                      <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                      <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i
                          class="fai fa-trash-o text-danger "></i></a>
                    </span>
                  </div>
                </span>

                <span class=""
                  *ngIf="(upFile?.extension !== 'doc') && (upFile?.extension !== 'docx') && (upFile?.extension !== 'jpg') && (upFile?.extension !== 'jpeg') && (upFile?.extension !== 'png') && (upFile?.extension !== 'pdf')">
                  <span class="mailbox-attachment-icon has-img clearfix">
                    <img src="assets/img/attachment.png" alt="No File Found" class="uploadAttImg" />
                  </span>
                  <div class="mailbox-attachment-info clearfix">
                    <span class="mailbox-attachment-size clearfix m-t-5">
                      <span class="text-primary uploadFileName">{{upFile?.name}}</span>
                      <a (click)="removeMultipleFiles(i)" class="btn btn-default btn-xs "><i class="fai fa-trash-o text-danger "></i></a>
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12" align="right">
            <div class="card-block">
              <button name="createLead" mat-raised-button class="btn-submit form-button-40" color="btn-createemployee" (click)="applyJob()">{{ 'jobs.applications.apply.apply-btn' | translate }}</button>
              <button name="cancelLead" mat-raised-button (click)="closeModel()" class="btn-cancel form-button-40">{{ 'jobs.add-job.cancel' | translate }}</button>
            </div>
          </div>
        </div>
      </form>
  </div>
</div>
