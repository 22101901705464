<div class="api-docs-back-btn">
        <section class="content-header">
          <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
                <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                  <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
              </a>    
          </button>
        </section>
      </div>

<mat-card>
<div class="api-docs">
<h2 class="content-header-name">Attendance Model </h2>
        <h4>Note</h4>
      <ul>
        <li>checkAttendanceAvailbleForCurrentDay - method will return the attendanceId if attendance already 
          created for that userId with current date. Otherwise, it will return 0.</li>
      </ul>
<br />

      <p><code><i>checkClockIn</i></code></p> 
  <ul>
  <li>
      This API will check whether attendance has been created for the current date for user.
  </li>
  <li>
      checkAttendanceAvailbleForCurrentDay will be called here.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/attendance/attendance_checkClockIn" >
        {{currentUrl}}explorer/#!/attendance/attendance_checkClockIn</a>
  </li>
</ul>
<br /> 


<p><code><i>clockInAndOutUpdate</i></code></p>
 
<ul>
<li>
    This API is used to clockIn(action = 1) and clockOut(action = 2) the time 
    for particular attendanceId in `tbl_clock`.
    <ul>
      <li>
          action = 1 : 
          checkAttendanceAvailbleForCurrentDay will be called here.
          If attendance id is present, createClockIN method will be called otherwise, createAttendance method will be called. 
      </li>
      <li>
          action = 2 : 
          checkAttendanceAvailbleForCurrentDay will be called here.
          If attendance id is present, it will update the current time for clockoutTime in `tbl_clock`.
      </li>
      <li>
          createClockIN: 
          checkClockInAlreadyDone - method will check whether clock is created for the 
          attendanceId. If not created, new clock will be created in `tbl_clock`.
      </li>
      <li>
          createAttendance - method will create the attendance for particular 
          userId with currentDate and attendanceStatus as `1`.
      </li>
    </ul>
</li>
<li>
    For reference: <a href="{{currentUrl}}explorer/#!/attendance/attendance_clockInAndOutUpdate" >
      {{currentUrl}}explorer/#!/attendance/attendance_clockInAndOutUpdate</a>
</li>
</ul>
<br /> 
</div>
</mat-card>
