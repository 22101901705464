<div class="main-div backG" fxLayoutAlign="start start" fxLayoutGap="20px">
  <mat-card class="mat-elevation-z8 outer-design ground-shadow" fxFlex="50">
    <mat-card-title class="text-center">{{title}}<span style="color: red;">{{name}}</span></mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
      <ng-container class="text-center">
        <div class="text-center tw-mt-4">
          <h6><div [innerHTML]="message"></div><br>
            <ng-container *ngIf="couponDisplay">Download your coupon here <a
                    class="glyphicon glyphicon-download-alt"
                    (click)="downloadCoupon()"
                    matTooltip="Click to download!" style="cursor: pointer;"></a><br><br>
              <span class="imp-note">Note</span> : Remember that your coupon is valid till <span
                      class="imp-note">{{expiry_time}}</span> only. Make sure that you will redeem it at
              any of our stores before it expires.<br><br></ng-container>
            Share with your friends :&nbsp;<i (click)="share('facebook')" class="fab fa-facebook"
                                              matTooltip="like on facebook!"
                                              style="cursor: pointer;color:#4267b2;"></i>
            &nbsp;<i (click)="share('twitter')" class="fab fa-twitter" matTooltip="tweet!"
                     style="cursor: pointer;color:#38A1F3;"></i>
            &nbsp;<i (click)="share('yelp')" class="fab fa-yelp" matTooltip="review on yelp!"
                     style="cursor: pointer;color:red;"></i>
            &nbsp;<i (click)="share('google')" class="fab fa-google"
                     matTooltip="review on google!"
                     style="cursor: pointer;"></i>
          </h6>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
<app-spinner></app-spinner>

