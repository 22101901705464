import { Component, OnInit } from '@angular/core';
import {COMMON} from '../../../../service/constant';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true
})
export class FooterComponent implements OnInit {
  public version: any;
  public currYear: any;
  constructor() {
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
  }

  ngOnInit() {
  }

}
