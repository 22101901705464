import {
  Component,
  inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { COMMON } from "../../../../service/constant";
import moment from "moment";
import {
  DateButton,
  DlDateTimePickerChange,
} from "angular-bootstrap-datetimepicker";
import { NgOption, NgSelectComponent, NgSelectModule } from "@ng-select/ng-select";
import { RemoveUnderscorePipe } from "../../../../admin/shared/pipes/remove-underscore-filter.pipe";
import { Subscription } from "rxjs";
import { Select2Data, Select2Option } from "ng-select2-component";
import { TaskService } from "../../../../service/task.service";
import { EStatusCode } from "../../../../service/Utils/Interfaces.class";
import { TinyMceConfigComponent } from "../../../../admin/tinymce-config";
import { LeadsService } from "../../../../service/leads.service";
import { MailboxService } from "../../../../admin/mailbox/mailbox-service/mailbox.service";
import { ProjectService } from "../../../../service/project.service";
import { DashboardService } from "../../../../service/dashboard.service";
import { OpportunitiesService } from "../../../../service/opportunities.service";
import { ClientService } from "../../../../service/client.service";
import { TicketsService } from "../../../../service/tickets.service";
import { ReleaseNoteService } from "../../../../service/release-note.service";
import { BugsService } from "../../../../service/bugs.service";
import { SettingsService } from "../../../../service/settings.service";
import {unitOfTime} from 'moment';
import { ConfigService } from "../../../../service/config.service";
import { ToastrService } from "ngx-toastr";
import { MatTabGroup } from '@angular/material/tabs';
import { MatButton } from "@angular/material/button";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { TinymceConfigDirective } from "../../../../admin/shared/custom-directives/tinymce-config.directive";
import { ExtendedModule } from "@angular/flex-layout/extended";
import { NgIf, NgClass } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
@Component({
    selector: "app-test-cases-form",
    templateUrl: "./test-cases-form.component.html",
    styleUrls: ["./test-cases-form.component.scss"],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        NgSelectModule,
        NgIf,
        NgClass,
        ExtendedModule,
        TinymceConfigDirective,
        MatRadioGroup,
        MatRadioButton,
        MatButton,
    ],
})
export class TestCasesFormComponent implements OnInit {
  public toastr: ToastrService = inject(ToastrService);
  private readonly subscriptions: Subscription[] = [];
  @Input() viewType: string;
  @Input() moduleId: number;
  createTestCaseForm: FormGroup;
  formattedEndDate: string;
  formattedStartDate: string;
  _isStartPickerOpen: boolean = false;
  testcaseHourFlag = false;
  startDate: Date;
  endDate: Date;
  redioOption:any;
  bugId: any;
  clientId: any;
  goalTrackingId: any;
  leadsId: any;
  milestonesId: any;
  opportunitiesId: any;
  projectId: any;
  ticketsId: any;
  public taskrelateselect;
  dropArr:any =[];
  subModuArr:any;
  typeArr:any;
  customParticipants:any;
  dropDownUsers:any;
  minDuration = 0;
  testCaseId :any;
  @ViewChild("picker") picker;
  @ViewChild("duepicker") duepicker;
  EndDateValue: string;
  StartDateValue: string;
  custmUsers:any;
  testcaseTypeData:any;
  userId:any;
  stake_holders:any;
  permissionType:any;
  visibleCustomeFields:boolean=false;
  open() {
    this.picker.open();
    this.duepicker.open();
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value > 100) {
      return Math.round(value / 100);
    }
    return value;
  }
  public selected;
  public selectPriority: boolean = false;
  participantsList = "";
  moduleName:any;
  salesrepCheck: boolean = false;
  project: NgOption = [];
  projectarr: Array<object> = [];
  projects: Select2Data = [];
  isOpportunity: boolean;
  milestone: NgOption = [];
  taskHourFlag = false;
  milestonearr: Array<object> = [];
  responsibleUserId: NgOption = [];
  responsibleUserIdB: any;
  responsibleUserIDN: string;
  responsiblearr: Array<object> = [];
  radioValue: number;
  projName:any;
  submit: boolean = false;
  showParticipants: boolean = false;
  color = "warn";
  value = 50;
  taskData:any;
  responsibleUser: number;
  update: boolean = true;
  fileName: any;
  event: string;
  data: string;
  departmentIds = [];
  public sel: string = "Related To";
  selectUserIdUpdate:boolean = false;
  selectedProject:boolean = false;
  selectDeptIdUpdate:boolean = false;
  selectTaskStatusUpdate:boolean = false;
  isEdit: boolean = false;
  selecttestcaseStatusUpdate: boolean = false;
  public testcaserelateselect;
  submitted = false;
  opportunity: NgOption = [];
  opportunityarr: Array<object> = [];
  bug: NgOption = [];
  bugarr: Array<object> = [];
  relatedtoarr = [];
  submodulearr = [];
  testcasePriorityData: NgOption = [];
  testcaseStatus: NgOption = [];
  projectNameDD: NgOption = [];
  selectDefaultRel:any;
  selectDefaultType:any;
  selectDefaultMod:any;
  goal: NgOption = [];
  goalarr: Array<object> = [];
  lead: NgOption = [];
  leadarr: Array<object> = [];
  client: NgOption = [];
  clientarr: Array<object> = [];
  ticket: NgOption = [];
  ticketarr: Array<object> = [];
  usersList1: NgOption = [];
  max = 100;
  min = 0;
  start = 0;
  limit = 15;
  searchValue: string;
  pageSizeArray = [15, 50, 100];
  pageSize = 15;
  showMessage: boolean = false;
  toResult: string;
  showResults = 0;
  // dataSource = new MatTableDataSource<any>();
  testcases = [];
  isLead: boolean;
  isproject: boolean;
  isRelatedTo: boolean;
  showResultLabel: boolean = false;
  allUsers: Array<object> = [];
  usersData: Select2Option[] = [];
  strNew: string = "New Test Case";
  strUpdate: string = "Update Test Case";
  datelimit: Date;
  private subscription: Subscription;
  public edittestcaseId: number;
  public isEditMode: boolean = false;
  private clientDetailId: number;
  startValue: string;
  customfieldsArray: Array<object>;
  isCustomFields: boolean = false;
  public customFieldValue: Array<object> = [];
  customFieldData: Array<object> = [];
  public showCustomFields: boolean = false;
  testcaseData: any;
  removeUnderScorePipe = new RemoveUnderscorePipe();
  private _isEndPickerOpen = false;
  disablePastDates = true;
  closeDD: any;
  config: any;
  tenant_name: any;
  imgListArr = [];
  imgCount: number = 0;
  imgArr = [];
  editData:any;
  @Input() tabGroup: MatTabGroup;
  @ViewChild("relatedTo") ngSelect_1: NgSelectComponent;
  @ViewChild("notproject") ngSelect_2: NgSelectComponent;
  @ViewChild("nomilestone") ngSelect_3: NgSelectComponent;
  @ViewChild("projectList") ngSelect_4: NgSelectComponent;
  @ViewChild("milestoneList") ngSelect_5: NgSelectComponent;
  @ViewChild("noopportunity") ngSelect_6: NgSelectComponent;
  @ViewChild("opportunityList") ngSelect_7: NgSelectComponent;
  @ViewChild("noleads") ngSelect_8: NgSelectComponent;
  @ViewChild("leads") ngSelect_9: NgSelectComponent;
  @ViewChild("bugs") ngSelect_10: NgSelectComponent;
  @ViewChild("goalList") ngSelect_11: NgSelectComponent;
  @ViewChild("clientList") ngSelect_12: NgSelectComponent;
  @ViewChild("ticketList") ngSelect_13: NgSelectComponent;
  @ViewChild("responsibleid") ngSelect_14: NgSelectComponent;
  @ViewChild("priorityNg") ngSelect_15: NgSelectComponent;
  @ViewChild("testcasestatusList") ngSelect_17: NgSelectComponent;
  @ViewChild("salesRep") ngSelect_18: NgSelectComponent;
  @ViewChild("projResp") ngSelect_19: NgSelectComponent;
  @Input() lockStatus: number;
  cities: NgOption = [];
  private onScroll = (event: any) => {
    this.closeDD =
      this.ngSelect_1 && this.ngSelect_1.isOpen
        ? this.ngSelect_1
        : this.ngSelect_2 && this.ngSelect_2.isOpen
        ? this.ngSelect_2
        : this.ngSelect_3 && this.ngSelect_3.isOpen
        ? this.ngSelect_3
        : this.ngSelect_4 && this.ngSelect_4.isOpen
        ? this.ngSelect_4
        : this.ngSelect_5 && this.ngSelect_5.isOpen
        ? this.ngSelect_5
        : this.ngSelect_6 && this.ngSelect_6.isOpen
        ? this.ngSelect_6
        : this.ngSelect_7 && this.ngSelect_7.isOpen
        ? this.ngSelect_7
        : this.ngSelect_8 && this.ngSelect_8.isOpen
        ? this.ngSelect_8
        : this.ngSelect_9 && this.ngSelect_9.isOpen
        ? this.ngSelect_9
        : this.ngSelect_10 && this.ngSelect_10.isOpen
        ? this.ngSelect_10
        : this.ngSelect_11 && this.ngSelect_11.isOpen
        ? this.ngSelect_11
        : this.ngSelect_12 && this.ngSelect_12.isOpen
        ? this.ngSelect_12
        : this.ngSelect_13 && this.ngSelect_13.isOpen
        ? this.ngSelect_13
        : this.ngSelect_14 && this.ngSelect_14.isOpen
        ? this.ngSelect_14
        : this.ngSelect_15 && this.ngSelect_15.isOpen
        ? this.ngSelect_15
        : this.ngSelect_17 && this.ngSelect_17.isOpen
        ? this.ngSelect_17
        : this.ngSelect_18 && this.ngSelect_18.isOpen
        ? this.ngSelect_18
        : this.ngSelect_19 && this.ngSelect_19.isOpen
        ? this.ngSelect_19
        : "";
    if (this.closeDD) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf("ng-dropdown-panel-items") >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.closeDD.close();
    }
  };

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    public testcaseService: TaskService,
    public taskService:TaskService, public leadsService:LeadsService,
    public projectService:ProjectService, public mailboxService:MailboxService, public dashboardService:DashboardService,
    public opportunitiesService:OpportunitiesService, public ticketsService:TicketsService, public clientService:ClientService,
    private releaseNoteService: ReleaseNoteService,
    public bugsService:BugsService, public settingService:SettingsService,
    public mceConfig: TinyMceConfigComponent,
    public configService : ConfigService
  ) {
    // super(injector);
  }

  ngOnInit() {
    this.getModuleDropDownList();
    this.project = [
      {
        value: 1,
        label: "Cloud app",
      },
      {
        value: 2,
        label: "Android app",
      },
      {
        value: 3,
        label: "iOS app",
      },
      {
        value: 4,
        label: "Other",
      },
    ];
    this.userId = localStorage.getItem('userId')
    this.employeedropdownList();
    this.editData=this.taskService.testcaseEditData ;
    this.isEditMode = this.editData.isEditMode;
    if(this.isEditMode == true){
      this.getSubModuleDropDownList(0);
      this.getTestCaseDetails(this.editData.editId);
    }else{
      this.radioValue = 1;
    }
  // Patching    date
    this.testcaseStatus = [];
    this.responsibleUserIdB =  this.configService.userId;
    window.addEventListener("scroll", this.onScroll, true);

    this.testcaseStatus = [
      {
        value: "completed",
        label: "Completed",
      },
      {
        value: "in_progress",
        label: "In Progress",
      },
      {
        value: "approved",
        label: "Approved",
      },
      {
        value: "waiting_for_someone",
        label: "Waiting for Someone",
      },
    ];

    this.testcasePriorityData = [
      {
        value: "low",
        label: "Low",
      },
      {
        value: "medium",
        label: "Medium",
      },
      {
        value: "high",
        label: "High",
      },
      {
        value: "critical",
        label: "Critical",
      },
    ];

    this.testcaseTypeData = [
      {
        value: "acceptance",
        label: "Acceptance",
      },
      {
        value: "accessibility",
        label: "Accessibility",
      },
      {
        value: "smoke&sanity",
        label: "Smoke & Sanity",
      },
      {
        value: "regression",
        label: "Regression",
      },
      {
        value: "usability",
        label: "Usability",
      },
      {
        value: "functional",
        label: "Functional",
      },
      {
        value: "compatibility",
        label: "Compatibility",
      },
      {
        value: "ui",
        label: "UI",
      },
      {
        value: "api",
        label: "API",
      },
      {
        value: "mobileapp",
        label: "Mobile App",
      },
    ];
    this.startValue = "in_progress";
    this.getValidations();
    this.startValue = "in_progress";
    this.getResponsibleUserList();
    this.createTestCaseForm.patchValue({
      testcaseStatus : this.testcaseStatus[3]['value'],
      testcasePriority : this.testcasePriorityData[0]['value'],
      projectName:this.project[0]['value'],
      typemodule:this.testcaseTypeData[0]['value'],
      // relatedtomodule:this.submodulearr[0]['value'],
      // relatedtomoduleMainMod:this.relatedtoarr[0]['value']
    })
    this.tenant_name = 'test';
    this.getTinymceConfig();
    this.onrelatedSelected(this.event);
    this.createTestCaseForm.controls['relatedtomodule'].reset();
  }

  getTinymceConfig() {
    this.config = this.mceConfig.getTinyMCEConfig(this.tenant_name);
  }
  getValidations() {
    this.createTestCaseForm = this.formBuilder.group({
      testName: [
        "",
        Validators.compose([
          Validators.pattern(COMMON.EMPTY_VALUE),
          Validators.required,
        ]),
      ],
      relatedtomodule: [""],
      relatedtomoduleMainMod: [""],
      submodule: [""],
      typemodule: [""],
      responsibleUserId: ["", Validators.required],
      testcaseStartDate: ["", Validators.required],
      testcaseHour: [""],
      testcaseStatus: ["", Validators.required],
      testcaseProgress: [""],
      testcaseDescription: [""],
      permission: [""],
      projectName:[""],
      customParticipants: [""],
      testcasePriority: ["", Validators.required],
      expectedresult:["", Validators.required],
      stepreproduce:["", Validators.required]
    });
  }

  getResponsibleUserList() {
    const responsibleUseAList : Subscription = this.taskService.responsibleUserList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myObj = {};
        res.data.forEach(element => {
          myObj = {'value': element.userId, 'label': element.fullname};
          this.responsiblearr.push(myObj);
          if (element.isOwner == 1) {
            this.responsibleUser = element.userId;
          }
        });
        this.responsibleUserId = this.responsiblearr;
      }
    });
    this.subscriptions.push(responsibleUseAList);
  }
  saveTestCase() {
    if(String(this.createTestCaseForm.value.testName) == ""){
      this.toastr.error('Please enter valid test case name.');
      return;
    }
    if(String(this.createTestCaseForm.value.stepreproduce) == ""){
      this.toastr.error('Please enter Step to reproduce.');
      return;
    }
    if(String(this.createTestCaseForm.value.expectedresult) == ""){
      this.toastr.error('Please enter expected result.');
      return;
    }
    if(this.radioValue === 1 ){
      this.permissionType = 1;
      this.stake_holders = this.userId
     }
     if(this.radioValue === 3 && this.visibleCustomeFields === true){
      this.permissionType = 3;
      this.stake_holders = this.createTestCaseForm.value.customParticipants;
     }

          this.taskData = {
            responsibleUserId: Number(this.createTestCaseForm.value.responsibleUserId) ? Number(this.createTestCaseForm.value.responsibleUserId) : null,
            testCaseName: String(this.createTestCaseForm.value.testName).trim(),
            testcaseDescription: this.createTestCaseForm.value.testcaseDescription?  String(this.createTestCaseForm.value.testcaseDescription) :'',
            testcaseStatus: String(this.createTestCaseForm.value.testcaseStatus),
            permission: this.permissionType,
            customFields: this.stake_holders ? this.stake_holders : null,
            testcasePriority: this.createTestCaseForm.value.testcasePriority ? this.createTestCaseForm.value.testcasePriority : null,
            subModuleName:String(this.createTestCaseForm.value.relatedtomodule),
            type:String(this.createTestCaseForm.value.typemodule),
            relatedtomodule:String(this.createTestCaseForm.value.relatedtomoduleMainMod),
            expectedResult:String(this.createTestCaseForm.value.expectedresult),
            stepToReproduce:String(this.createTestCaseForm.value.stepreproduce),
            projectId:Number(this.createTestCaseForm.value.projectName) ? Number(this.createTestCaseForm.value.projectName) : null
          };      
          this.testcaseService.createTastCase(this.taskData).subscribe((result: any) => {
          if (result.info.code === EStatusCode.OK) {
            this.toastr.success(result.data.responseMsg);
            this.createTestCaseForm.reset();
            this.tabGroup.selectedIndex=0;
          }            
      });       
  }


  updateTestCase() {
    if(String(this.createTestCaseForm.value.testName) == ""){
      this.toastr.error('Please enter valid test case name.');
      return;
    }
    if(String(this.createTestCaseForm.value.stepreproduce) == ""){
      this.toastr.error('Please enter Step to reproduce.');
      return;
    }
    if(String(this.createTestCaseForm.value.expectedresult) == ""){
      this.toastr.error('Please enter expected result.');
      return;
    }
          this.taskData = {
            testCaseId:this.testCaseId,
            responsibleUserId: Number(this.createTestCaseForm.value.responsibleUserId) ? Number(this.createTestCaseForm.value.responsibleUserId) : null,
            testCaseName: String(this.createTestCaseForm.value.testName).trim(),
            testcaseDescription: this.createTestCaseForm.value.testcaseDescription?  String(this.createTestCaseForm.value.testcaseDescription) :'',
            testcaseStatus: String(this.createTestCaseForm.value.testcaseStatus),
            permission: Number(this.permissionType),
            customFields: this.stake_holders,
            testcasePriority: this.createTestCaseForm.value.testcasePriority ? this.createTestCaseForm.value.testcasePriority : null,
            subModuleName:String(this.createTestCaseForm.value.relatedtomodule),
            type:String(this.createTestCaseForm.value.typemodule),
            relatedtomodule:String(this.createTestCaseForm.value.relatedtomoduleMainMod),
            expectedResult:String(this.createTestCaseForm.value.expectedresult),
            stepToReproduce:String(this.createTestCaseForm.value.stepreproduce),  projectId:Number(this.createTestCaseForm.value.projectName) ? Number(this.createTestCaseForm.value.projectName) : null
          };    
          this.testcaseService.updateTastCase(this.taskData).subscribe((result: any) => {
          if (result.info.code === EStatusCode.OK) {
            this.toastr.success(result.data.responseMsg);
            this.createTestCaseForm.reset();
            var data = {
              isEditMode : false,
              editId : null
            }
            this.taskService.testcaseEditData = data;
            this.tabGroup.selectedIndex=0;
          }            
      });       
  }


  getSelectedValue(event) {
    this.moduleName = event.value ? event.value : '';
  }
  getprojectValue(event) {
    this.projName = event.value ? event.value : '';
  }

  subModuleSelect(event){
    this.subModuArr=event.value
  }
  typeSelect(event){
        this.selectDefaultType=event.value  
      }
  onrelatedSelected(val) {
    this.taskrelateselect = val;
  }
  employeedropdownList() {
    const EmployesDDLi : Subscription = this.taskService.EmployesDDList().subscribe((SalesPersonRes: any) => {
      if (SalesPersonRes.info.code === EStatusCode.OK) {
        var myUsersObj = {};
        SalesPersonRes.data.forEach(userObj => {
          myUsersObj = {'value': userObj.userId, 'label': userObj.fullname};
          this.dropArr.push(myUsersObj);
        });
      }
    });
    this.subscriptions.push(EmployesDDLi);
  }

  cancelForm() {
    this.createTestCaseForm.reset();
    this.tabGroup.selectedIndex = 0;
  }
  getSelectPriority(event) {
    this.selectPriority = event === undefined;
  }

  isTimeRight(event){
    let check = event.target.value;
    this.taskHourFlag = check ? this.checkEstimationHour(check) : false;
  }

  checkEstimationHour(timeData) {
    timeData =  timeData.split('.') ;
    if (timeData.length > 1 && timeData[1] >= 1) {
      let minutes = timeData[1];
      let hours = timeData[0];
      if(minutes.length === 2){
        if(minutes< 60) {
          return false;
        } else {
          this.toastr.error('Please enter less than 60 minutes');
          return true;
        }
      } else {
        if (minutes.length ===1 && hours < 100) {
          minutes = '0'+minutes;
          hours = hours >=1 ? hours : '0';
          this.toastr.error('Please enter minutes in double digits \n Ex : ' +hours+'.'+minutes  + ' => '+hours+' Hours '+minutes+' Minutes');
          return true;
        } else {
          this.toastr.error('Please enter minutes in double digits \n Ex : 2.05 => 2 Hours 05 Minutes');
          return true;
        }
      }
    } else {
      return false;
    }
  }
  filterChangeStatus(event) {
    this.selectTaskStatusUpdate = event === undefined;
  }
  radioChange(event) {
    var tempArr = [];
     this.showParticipants = event.value === 3;
    this.redioOption =event.value 
    if(event.value === 1 ){
      this.permissionType = 1;
      this.visibleCustomeFields=false;
      this.stake_holders = [];
      this.radioValue = 1;
     this.stake_holders = this.userId
    } else if(event.value === 2 ){
      this.permissionType = 2;
      this.visibleCustomeFields=false;
      this.radioValue = 2;this.stake_holders = [];
      for(var i=0; i < this.responsibleUserId.length; i++){
        tempArr.push(this.responsibleUserId[i].value)
      }      
      this.stake_holders = tempArr.toString();
    } else if(event.value === 3 ){
      this.permissionType = 3;
      this.radioValue = 3;this.stake_holders = [];
      this.visibleCustomeFields=true;
    }else{
      this.visibleCustomeFields=false;
    }  
    
  }
  updateSalesRep() {
    this.salesrepCheck = false;
    this.stake_holders = this.createTestCaseForm.value.customParticipants;
  }

  getTestCaseDetails(editId){
    const customRoleId =[];
    const testCaseDDLi : Subscription = this.taskService.getTestCasesById(editId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var tempArr = JSON.parse(res.data.listResult[0].customUsers);
        const permission = res.data.listResult[0].permissionType;
        if(permission == 1){
          this.radioValue = 1;
          this.permissionType = 1;
          this.stake_holders = this.userId;
        }
        else if(permission == 2){
        this.radioValue = 2;
        this.permissionType = 2;
        }
        else if(permission == 3){
          this.radioValue = 3;
          this.permissionType = 3;
          this.visibleCustomeFields = true;
          if(res.data.listResult[0].customUsers != null){
            this.custmUsers = res.data.listResult[0].customUsers;
          let custUsers = tempArr;
          for(var i=0; i < custUsers.length; i++)
          customRoleId.push(parseInt(custUsers[i].id));
          this.stake_holders = customRoleId;
          this.createTestCaseForm.patchValue({
          customParticipants: customRoleId
          })
          }
        }
        this.testCaseId = res.data.listResult[0].testCaseId         
        this.createTestCaseForm.patchValue({
            testName: res.data.listResult[0].testCaseName,
            responsibleUserId: res.data.listResult[0].responsibleUserId,
            testcaseStatus: res.data.listResult[0].testCaseStatus,
            testcaseProgress: res.data.listResult[0].testCaseProgress,
            testcaseDescription: res.data.listResult[0].testCaseDescription,
            permission: res.data.listResult[0].permission,
            testcasePriority: res.data.listResult[0].testCasePriority,
            relatedtomodule:res.data.listResult[0].subModulesName,
            typemodule:res.data.listResult[0].testCaseType,
            relatedtomoduleMainMod: res.data.listResult[0].mainModuleName,
            expectedresult: res.data.listResult[0].expectedResult,
            stepreproduce: res.data.listResult[0].stepToReproduce,
            projectName: res.data.listResult[0].projectType
          })
      }
    });
    this.subscriptions.push(testCaseDDLi);
  }

  keepDropDownOpen(event: Event) {
    this._isStartPickerOpen = true;
    event.stopPropagation();
  }
  keepDropDownOpen2(event: Event) {
    this._isEndPickerOpen = true;
    event.stopPropagation();
  }
  endDateSelected(event: DlDateTimePickerChange<Date>) {
    if (this._isEndPickerOpen && event.value) {
      (<any>$('.date-dropdown1')).dropdown('toggle');
      this.formattedEndDate = moment(this.endDate).format("MM/DD/YYYY, hh:mm A")
    }
  }
  endDatePickerFilter = (dateButton: DateButton, viewName: string) => {
    const now = moment().startOf(viewName as unitOfTime.StartOf).valueOf();
    const startTime = (this.startDate ? moment(this.startDate).add(this.minDuration, 'minute').startOf(viewName as unitOfTime.StartOf).valueOf()
      : Number.MIN_SAFE_INTEGER);

    return this.disablePastDates
      ? dateButton.value >= startTime
      : dateButton.value <= startTime;
  }
  startDatePickerFilter = (dateButton: DateButton, viewName: string) => {
    return true;
  }
  getSelectUser($event: any) {
    this.selectUserIdUpdate = !$event;
  }
  getSelectProject(event) {
    this.selectedProject = event === undefined;
  }
  startDateSelected(event) {
    if (event.value) {
      if (this._isStartPickerOpen) {
        (<any>$('.date-dropdown')).dropdown('toggle');
        this.formattedStartDate = moment(this.startDate).format("MM/DD/YYYY, hh:mm A")
        this.formattedEndDate = undefined;
      }
    } 
    
    this.project = [
      {
        value: "1",
        label: "Cloud app",
      },
      {
        value: "2",
        label: "Android app",
      },
      {
        value: "3",
        label: "iOS app",
      },
      {
        value: "4",
        label: "Other",
      },
    ];
  }
  getModuleDropDownList(){
    const moduleDDLi : Subscription = this.taskService.getmoduleDDList().subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myUsersObj = {};
        this.relatedtoarr = [];
        res.data.forEach(userObj => {
          myUsersObj = {'value': userObj.moduleName.toLowerCase(), 'id' : userObj.id,'label': userObj.moduleName};
          this.relatedtoarr.push(myUsersObj);
        });
        this.createTestCaseForm.patchValue({
          relatedtomoduleMainMod:this.relatedtoarr[0]['value']
        })
      }
    })
    this.subscriptions.push(moduleDDLi);
  }

  getModuleNameDDClick(event){
this.getSubModuleDropDownList(event.id)
  }
  getSubModuleDropDownList(id){
    const submoduleDDLi : Subscription = this.taskService.getsubModuleDDList(id).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        var myUsersObj = {};
        this.submodulearr=[];
        res.data.forEach(userObj => {
          myUsersObj = {'value': userObj.subModuleName.toLowerCase(), 'label': userObj.subModuleName};
          this.submodulearr.push(myUsersObj);
        });
      }
    })
    this.subscriptions.push(submoduleDDLi);
  }
}
