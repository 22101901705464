    
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
    <mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Notes Model</h2>
  <p><i><code>Add</code></i></p> 
  <ul>
      <li>
            This API is used to create the new notes into `tbl_project_notes` by moduleId(<strong>Enter moduleId (bugId, projectId, taskId, clientId)</strong>), moduleType(<strong>Enter type (bug, project, task, client)</strong>) and notes. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Notes/Notes_add"><u>{{path}}explorer/#!/Notes/Notes_add</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p> 
  <ul> 
        <li>
            This API will delete the notes from `tbl_project_notes` by projectNotesId.
        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/Notes/Notes_delete"><u>{{path}}explorer/#!/Notes/Notes_delete</u></a> 
        </li>
</ul>
 <br />  
  <p><i><code>List</code></i></p>
  <ul>
      <li>
            This API is used to get the list of notes from `tbl_project_notes` by moduleId, moduleType. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/Notes/Notes_list"><u>{{path}}explorer/#!/Notes/Notes_list</u></a> 

      </li>
  </ul> 
  </div>
</mat-card>
