
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
   </div>
   
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Vendor Model</h2>
 <p><i><code> Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new vendor into `tbl_vendor`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_add"><u>{{path}}explorer/#!/Vendor/Vendor_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code> contactList</code></i></p> 
     <ul>
         <li>
                This API will fetch all the contacts of the particular vendor by <strong>vendor Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_contactList"><u>{{path}}explorer/#!/Vendor/Vendor_contactList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the vendor from `tbl_vendor` by <strong>vendor Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_delete"><u>{{path}}explorer/#!/Vendor/Vendor_delete</u></a> 

         </li>
     </ul>
     <p><i><code> Details</code></i></p> 
     <ul>
         <li>
                It will fetch the details of particular vendor from `tbl_vendor` by <strong>vendor Id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_details"><u>{{path}}explorer/#!/Vendor/Vendor_details</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> getOverdueCounts</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the overdue count for specific modules if the below conditions satisfied. 
                <ul>
                    <li>
                            calls : <strong>mark_as_done != 1 AND   STR_TO_DATE(tbl_calls.date, </strong><strong>\'</strong><strong>%Y-%m-%d</strong><strong>\'</strong><strong>)&lt;CURDATE()</strong> 

                    </li>
                    <li>
                            meetings : <strong>tbl_meetings.mark_as_done=0 AND CAST(tbl_meetings.end_date AS DATE)&lt;CURDATE()</strong> 

                    </li>
                    <li>
                            deadline : <strong>mark_as_done = 0 AND CAST(tbl_deadline.deadline_date AS DATE)&lt;CURDATE()</strong> 

                    </li>
                    <li>
                            tasks : <strong>tbl_task.task_progress &lt; 100 AND CURRENT_DATE() &gt; (CAST(tbl_task.due_date AS DATE)) AND tbl_task.task_status != "completed"  AND tbl_task.task_status != "cancel"</strong> 

                    </li>
                    <li>
                            estimates : <strong>CURRENT_DATE() &gt; (CAST(`estimates`.`due_date` AS DATE)) AND estimates.isDeleted= 0 AND estimates.status !="Accepted" AND estimates.status != "Declined"</strong> 

                    </li>
                    <li>
                            opportunities : <strong>tbl_opportunities.oppo_final_status != 1 AND tbl_opportunities.oppo_final_status != 2 AND `tbl_opportunities`.`probability` &lt; 100 AND CURRENT_DATE() &gt; (CAST(`tbl_opportunities`.`close_date` AS DATE))</strong> 

                    </li>
                    <li>
                            invoices : <strong>tbl_invoices.status != Fully Paid and CAST(tbl_invoices.due_date AS DATE) &lt; CURRENT_DATE()</strong> 

                    </li>
                    <li>
                            projects : <strong>tbl_project.project_status != "completed" OR tbl_project.project_status != "cancel"</strong> 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_getOverdueCounts"><u>{{path}}explorer/#!/Vendor/Vendor_getOverdueCounts</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the vendor list from `tbl_vendor`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_list"><u>{{path}}explorer/#!/Vendor/Vendor_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> listOnMap</code></i></p> 
     <ul>
         <li>
                This API will fetch the all vendor address for showing location in google map. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_listOnMap"><u>{{path}}explorer/#!/Vendor/Vendor_listOnMap</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Modify</code></i></p> 
     <ul>
         <li>
                This API will modify the details of particular vendor by <strong>vendor Id</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_modify"><u>{{path}}explorer/#!/Vendor/Vendor_modify</u></a> 

         </li>
     </ul>
      
     <p><i><code> vendorDropdown</code></i></p> 
     <ul>
         <li>
                This API will the list of vendorId and vendorName for dropdown. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_vendorDropdown"><u>{{path}}explorer/#!/Vendor/Vendor_vendorDropdown</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> vendorgetCounts</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details count of particular vendor by vendorId. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Vendor/Vendor_getCounts"><u>{{path}}explorer/#!/Vendor/Vendor_getCounts</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
