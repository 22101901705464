
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Lead Contacts Model</h2>
 <p><i><code>Add</code></i></p> 
    <ul>
        <li>
                This API is used to create a new contact for lead by leadId into `tbl_contacts` 
        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_add"><u>{{path}}explorer/#!/leadContacts/leadContacts_add</u></a> 
        </li>
    </ul>
 <br />  
  <p><i><code>DDlist</code></i></p> 
  <ul>
      <li>
            This API will fetch the list of contacts from `tbl_contacts` by leadId. 
      </li>
      <li>
            This response will be shown in dropdown for leads calls. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_DDlist"><u>{{path}}explorer/#!/leadContacts/leadContacts_DDlist</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p> 
  <ul>
      <li>
            It will delete the lead contact from the `tbl_contacts` by contactsId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_delete"><u>{{path}}explorer/#!/leadContacts/leadContacts_delete</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Details</code></i></p> 
  <ul>
      <li>
            This API will fetch the contact details from `tbl_contacts` by contactsId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_details"><u>{{path}}explorer/#!/leadContacts/leadContacts_details</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>List</code></i></p> 
  <ul>
      <li>
            This API will fetch the lead contacts list with pagination and totalCount of available list from `tbl_contacts` by leadId. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_list"><u>{{path}}explorer/#!/leadContacts/leadContacts_list</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>makePrimary</code></i></p> 
  <ul>
      <li>
            This API is used to make the contact as primary contact in `tbl_contacts` by leadId and contactsId(<strong>Enter Contact id that need to be primary</strong>). 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_makePrimary"><u>{{path}}explorer/#!/leadContacts/leadContacts_makePrimary</u></a> 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_makePrimary"><u>{{path}}explorer/#!/leadContacts/leadContacts_makePrimary</u></a> 

      </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
      <li>
            This API is used to modify the contact details by contactsId into `tbl_contacts`. 

      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/leadContacts/leadContacts_modify"><u>{{path}}explorer/#!/leadContacts/leadContacts_modify</u></a> 

      </li>
  </ul>
  </div>
</mat-card>

