import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { IResponse, EStatusCode } from '../../../../service/Utils/Interfaces.class';
import { SearchService } from './services/search.service';
import { ConfigService } from '../../../../service/config.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocompleteOrigin, MatAutocomplete } from '@angular/material/autocomplete';
import { RemoveUnderscorePipe } from '../../../shared/pipes/remove-underscore-filter.pipe';
import { RouterLink } from '@angular/router';
import { MatOption } from '@angular/material/core';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { NgIf, NgFor, LowerCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    standalone: true,
    imports: [MatAutocompleteOrigin, FlexModule, ExtendedModule, FormsModule, MatAutocompleteTrigger, MatAutocomplete, NgIf, MatTabGroup, MatTab, NgFor, MatOption, RouterLink, LowerCasePipe, RemoveUnderscorePipe]
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  panelStatusTicket: boolean = true;
  panelStatusProject: boolean = true;
  panelStatusBugs: boolean = true;
  panelStatusLeads: boolean = true;
  panelStatusEstimate: boolean = true;
  panelStatusOppo: boolean = true;
  panelStatusTask: boolean = true;
  panelStatusClient: boolean = true;
  panelStatusVendors: boolean = true;
  panelStatusPurchaseOrder: boolean = true;
  panelStatusInvoice: boolean = true;
  panelStatusPayment: boolean = true;
  showDataEstimate: boolean = false;
  showDataLead: boolean = false;
  showDataOppoData: boolean = false;
  showDataTck: boolean = false;
  showDataPro: boolean = false;
  showDataBug: boolean = false;
  showDataInv: boolean = false;
  showDataPay: boolean = false;
  showDataPo: boolean = false;
  showDataTas: boolean = false;
  showDataCli: boolean = false;
  showDataVen: boolean = false;
  showMsg: boolean = false;
  searchValue: string;
  searchModel: string;
  start: number;
  limit: number;
  resultSearch: any = [];
  halfTicketData: any = [];
  halfProjectData: any = [];
  clientData: any = [];
  vendorData: any = [];
  taskData: any = [];
  oppoData: any = [];
  estimateData: any = [];
  invoiceData: any = [];
  leadData: any = [];
  paymentData: any = [];
  purchaseOrderData: any = [];
  bugsData: any = [];
  @ViewChild('supportsearch') input;
  @ViewChild('supportsearchmodel') input_type;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  private readonly subscriptions: Subscription[] = [];
  private searchValue$ = new Subject<string>();
  
  constructor(
    private searchService: SearchService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.configService.globalSearchAutoComplete.subscribe((res: any) => {
      this.close();
    });

    this.searchValue$.pipe(
      debounceTime(800),
      distinctUntilChanged())
    .subscribe(data => {
      this.globalSearch();
    });
  }

  globalSearch() {
    this.panelStatusPayment = true;
    this.panelStatusInvoice = true;
    this.panelStatusPurchaseOrder = true;
    this.panelStatusVendors = true;
    this.panelStatusClient = true;
    this.panelStatusTask = true;
    this.panelStatusOppo = true;
    this.panelStatusEstimate = true;
    this.panelStatusLeads = true;
    this.panelStatusBugs = true;
    this.panelStatusProject = true;
    this.panelStatusTicket = true;
    this.start = 0;
    this.limit = 50;
    this.searchValue = this.input.nativeElement.value;
    this.searchModel = this.input_type.nativeElement.value;
    if (this.searchValue.length > 2) {
      const searchSubscribe : Subscription = this.searchService.search(this.input.nativeElement.value, this.input_type.nativeElement.value, this.start, this.limit).subscribe((res: IResponse) => {
        if (res.result.info.code === EStatusCode.OK || res.result.info.code == EStatusCode.CREATED) {
          this.resultSearch = res.result;
          this.showDataEstimate = false;
          this.showDataLead = false;
          this.showDataOppoData = false;
          this.showDataTck = false;
          this.showDataPro = false;
          this.showDataBug = false;
          this.showDataInv = false;
          this.showDataPay = false;
          this.showDataPo = false;
          this.showDataTas = false;
          this.showDataCli = false;
          this.showDataVen = false;
          if (this.searchValue != "" && this.searchModel === "all") {

            this.paymentData = this.resultSearch.data.paymentInfo;
            this.showDataPay = !!this.paymentData.length;
            
            this.purchaseOrderData = this.resultSearch.data.purchaseOrderInfo;
            this.showDataPo = !!this.purchaseOrderData.length;

            this.invoiceData = this.resultSearch.data.invoiceInfo;
            this.showDataInv = !!this.invoiceData.length;

            this.halfTicketData = this.resultSearch.data.tickets;
            this.showDataTck = !!this.halfTicketData.length;

            this.halfProjectData = this.resultSearch.data.projects;
            this.showDataPro = !!this.halfProjectData.length;

            this.bugsData = this.resultSearch.data.bugs;
            this.showDataBug = !!this.bugsData.length;
            
            this.oppoData = this.resultSearch.data.oppo;
            this.showDataOppoData = !!this.oppoData.length;

            this.estimateData = this.resultSearch.data.estimates;
            this.showDataEstimate = !!this.estimateData.length;
            
            this.leadData = this.resultSearch.data.leadResponse;
            this.showDataLead = !!this.leadData.length;

            this.taskData = this.resultSearch.data.taskInfo;
            this.showDataTas = !!this.taskData.length;
            
            this.vendorData = this.resultSearch.data.vendorInfo;
            this.showDataVen = !!this.vendorData.length;

            this.clientData = this.resultSearch.data.clientInfo;
            this.showDataCli = !!this.clientData.length;

          }
          if (this.searchValue != "" && this.searchModel === "client") {
            this.clientData = this.resultSearch.data.clientInfo;  
            this.showDataCli = !!this.clientData.length;
          }
          if (this.searchValue != "" && this.searchModel === "vendors") {
            this.vendorData = this.resultSearch.data.vendorInfo;
            this.showDataVen = !!this.vendorData.length;
          }
          if (this.searchValue != "" && this.searchModel === "task") {
            this.taskData = this.resultSearch.data.taskInfo;
            this.showDataTas = !!this.taskData.length;
          }

          if (this.searchValue != "" && this.searchModel === "sale" && "leads" in this.resultSearch.data) {
            this.leadData = this.resultSearch.data.leads;
            this.showDataLead = !!this.leadData.length;
          }

          if (this.searchValue != "" && this.searchModel === "sale" && "estimates" in this.resultSearch.data) {
            this.estimateData = this.resultSearch.data.estimates;
            this.showDataEstimate = !!this.estimateData.length;
          }

          if (this.searchValue != "" && this.searchModel === "sale" && "oppo" in this.resultSearch.data) {
            this.oppoData = this.resultSearch.data.oppo;
            this.showDataOppoData = !!this.oppoData.length;
          }

          if (this.searchValue != "" && this.searchModel === "support" && "bugs" in this.resultSearch.data) {
            this.bugsData = this.resultSearch.data.bugs;
            this.showDataBug = !!this.bugsData.length;
          }

          if (this.searchValue != "" && this.searchModel === "support" && "projects" in this.resultSearch.data) {
            this.halfProjectData = this.resultSearch.data.projects;
            this.showDataPro = !!this.halfProjectData.length;
          }

          if (this.searchValue != "" && this.searchModel === "support" && "tickets" in this.resultSearch.data) {
            this.halfTicketData = this.resultSearch.data.tickets;
            this.showDataTck = !!this.halfTicketData.length;
          }

          if (this.searchValue != "" && this.searchModel === "account" && "invoiceInfo" in this.resultSearch.data) {
            this.invoiceData = this.resultSearch.data.invoiceInfo;
            this.showDataInv = !!this.invoiceData.length;
          }

          if (this.searchValue != "" && this.searchModel === "account" && "purchaseOrderInfo" in this.resultSearch.data) {
            this.purchaseOrderData = this.resultSearch.data.purchaseOrderInfo;
            this.showDataPo = !!this.purchaseOrderData.length;
          }

          if (this.searchValue != "" && this.searchModel === "account" && "paymentInfo" in this.resultSearch.data) {
            this.paymentData = this.resultSearch.data.paymentInfo;
            this.showDataPay = !!this.paymentData.length;
          }

          if(
            this.halfTicketData.length || this.halfProjectData.length || this.bugsData.length || this.leadData.length || this.estimateData.length ||
            this.oppoData.length || this.taskData.length || this.clientData.length || this.vendorData.length || this.invoiceData.length ||
            this.purchaseOrderData.length || this.paymentData.length
          ) {
            this.showMsg = false;
          } else {
            this.showMsg = true;
          }          
        } else {
          this.showDataEstimate = false;
          this.showDataLead = false;
          this.showDataOppoData = false;
          this.showDataTck = false;
          this.showDataPro = false;
          this.showDataBug = false;
          this.showDataInv = false;
          this.showDataPay = false;
          this.showDataPo = false;
          this.showDataTas = false;
          this.showDataCli = false;
          this.showDataVen = false;
          this.showMsg = true;
        }
      });
      this.subscriptions.push(searchSubscribe);
    }
    else {
      console.error("no result in search")
      this.clearResult()
    }
  }

  search(searchString){
    this.searchValue$.next(searchString);
  }

  clearResult(){
    this.clientData = this.vendorData = this.paymentData = this.purchaseOrderData = this.invoiceData = this.halfTicketData =
    this.taskData = this.halfProjectData = this.bugsData = this.oppoData = this.estimateData = this.leadData = []
  }

  onScroll() {
    this.searchValue = this.input.nativeElement.value;
    this.searchModel = this.input_type.nativeElement.value;
    if (this.searchValue.length > 2) {
      this.start = this.limit + 1;
      this.limit = this.limit + 10;
      const searchSubscribe : Subscription = this.searchService.search(this.input.nativeElement.value, this.input_type.nativeElement.value, this.start, this.limit).subscribe((res: IResponse) => {
        if (res.result.info.code === EStatusCode.OK || res.result.info.CREATED) {
          this.resultSearch = res.result;
          if (this.searchValue != "" && this.searchModel === "sale" && "leads" in this.resultSearch.data) {
            this.leadData[this.leadData.length - 1] = this.resultSearch.data.leads
          }
          if (this.searchValue != "" && this.searchModel === "sale" && "estimates" in this.resultSearch.data) {
            this.estimateData[this.estimateData.length - 1] = this.resultSearch.data.estimates
          }
          if (this.searchValue != "" && this.searchModel === "sale" && "oppo" in this.resultSearch.data) {
            this.oppoData[this.oppoData.length - 1] = this.resultSearch.data.oppo
          }
          if (this.searchValue != "" && this.searchModel === "support" && "bugs" in this.resultSearch.data) {
            this.bugsData[this.bugsData.length - 1] = this.resultSearch.data.bugs
          }
          if (this.searchValue != "" && this.searchModel === "support" && "tickets" in this.resultSearch.data) {
            this.halfTicketData[this.halfTicketData.length - 1] = this.resultSearch.data.tickets
          }
          if (this.searchValue != "" && this.searchModel === "support" && "projects" in this.resultSearch.data) {
            this.halfProjectData[this.halfProjectData.length - 1] = this.resultSearch.data.projects
          }
          if (this.searchValue != "" && this.searchModel === "account" && "invoiceInfo" in this.resultSearch.data) {
            this.invoiceData[this.invoiceData.length - 1] = this.resultSearch.data.invoiceInfo
          }
          if (this.searchValue != "" && this.searchModel === "account" && "paymentInfo" in this.resultSearch.data) {
            this.paymentData[this.paymentData.length - 1] = this.resultSearch.data.paymentInfo
          }
          if (this.searchValue != "" && this.searchModel === "account" && "purchaseOrderInfo" in this.resultSearch.data) {
            this.purchaseOrderData[this.purchaseOrderData.length - 1] = this.resultSearch.data.purchaseOrderInfo
          }
        }
      });
      this.subscriptions.push(searchSubscribe);
    }
    else {
      return console.error("No more Data Found!");
    }
  }

  trackByTickets(index: number, ticket: any) {
    return ticket.ticketsId;
  }

  trackByLeads(index: number, lead: any){
    return lead.leadsId;
  }

  trackByClients(index: number, client: any){
    return client.clientId;
  }

  trackByInvoices(index: number, invoice: any){
    return invoice.invoicesId;
  }

  trackByEstimates(index: number, estimate: any){
    return estimate.estimatesId;
  }

  trackByVendors(index: number, vendor: any){
    return vendor.vendorId;
  }

  trackByPayments(index: number, payment: any){
    return payment.paymentsId;
  }

  trackByOpportunities(index: number, opportunity: any){
    return opportunity.opportunitiesId;
  }

  trackByPurchaseOrders(index: number, po: any){
    return po.purchaseOrderId;
  }

  trackByBugs(index: number, bug: any){
    return bug.bugId;
  }

  trackByProjects(index: number, project: any){
    return project.projectId;
  }

  trackByTasks(index: number, task: any){
    return task.taskId;
  }

  close() {
    this.autocomplete.closePanel();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
