
<div class="api-docs-back-btn">
      <section class="content-header">
        <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
              <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
            </a>    
        </button>
      </section>
</div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Email Templates Model</h2>
  <p><i><code>Add</code></i></p>
  <ul>
      <li>
            This API is used to create a new email template into `tbl_email_templates`. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_add"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_add</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p> 
  <ul>
      <li>
            This API is used to delete the email template from `tbl_email_templates` by emailTemplateId. 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_delete"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_delete</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>listByEGroup</code></i></p> 
  <ul>
      <li>
            This API will list the email templates from `tbl_email_templates` by emailGroup if exists. (Ex: email_group: `proposal_template`) 
      </li>
      <li>
            For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEGroup"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEGroup</u></a> 
      </li>
  </ul>
 <br />  
  <p><i><code>listByEGroupVendor</code></i></p>
  <ul>   
  <li>
        This API is used to get the list of email templates by emailGroup from `tbl_email_templates` for vendor module. 
  </li>
  <li>
        Cond: <strong>(email_group="system_default_agreement" AND SUBJECT="Default NDA Agreement Template")</strong> OR <strong>(email_group="custom_agreement_template" AND custom_agreement_template_type="3")</strong> 
  </li>
  <li>
        For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEGroupVendor"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEGroupVendor</u></a> 

  </li>
</ul>
<br />
  <p><i><code>listByEmailTemplateId</code></i></p> 
  <ul>
  <li>
        This API is used to get the details of email template by emailTemplateId from `tbl_email_templates`. 
  </li>
  <li>
        For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEmailTemplateId"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_listByEmailTemplateId</u></a> 
  </li>
</ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
  <li>
        This API will update the details of email templates into `tbl_email_templates` by emailTemplateId if exists. 
  </li>
  <li>
        For reference: <a href="{{path}}explorer/#!/EmailTemplates/EmailTemplates_modify"><u>{{path}}explorer/#!/EmailTemplates/EmailTemplates_modify</u></a> 
  </li>
</ul>
</div>
</mat-card>
