
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
  Departments Model
</h2>

  <p><i><code>Add</code></i></p> 
  <ul>
    <li>
        This API is used to create a new department into `tbl_department`. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_add"><u>{{path}}explorer/#!/Department/Department_add</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>Delete</code></i></p> 
  <ul>
    <li>
        This API will delete the particular department from `tbl_department` by using departmentId. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_delete"><u>{{path}}explorer/#!/Department/Department_delete</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>dropdownList</code></i></p> 
  <ul>
    <li>
        This API is used to fetch the list of departments from `tbl_department` by departmentId for dropdown purpose. 
    </li>
    <li>
        Cond: <strong>'parent'</strong><strong>: departmentId&nbsp; or </strong><strong>'deptId'</strong><strong>: departmentId</strong> 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_dropdownList"><u>{{path}}explorer/#!/Department/Department_dropdownList</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>List</code></i></p> 
  <ul>
    <li>
        This API is used to fetch all the departments list from `tbl_department`. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_list"><u>{{path}}explorer/#!/Department/Department_list</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>listById</code></i></p> 
  <ul>
    <li>
        This API is used to get details of particular department from `tbl_department` by departmentId. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_listById"><u>{{path}}explorer/#!/Department/Department_listById</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>Modify</code></i></p> 
  <ul>
    <li>
        This API is used to update the details of particular department into `tbl_department` by departmentId if exists. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_modify"><u>{{path}}explorer/#!/Department/Department_modify</u></a> 
    </li>
  </ul>
 <br /> 
  <p><i><code>Tree</code></i></p> 
  <ul>
    <li>
        This API is used to get the department list in tree structure(departments, sub-departments). 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_tree"><u>{{path}}explorer/#!/Department/Department_tree</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>userDDList</code></i></p> 
  <ul>
    <li>
        This API is used to fetch list of departmentHead details if <strong>`tbl_users`.`user_type` = 1 </strong><strong>AND</strong> <strong>`tbl_users`.`activated` = 1 </strong><strong>AND</strong> <strong>(`tbl_account_details`.`company` IS NULL </strong><strong>OR</strong> <strong>`tbl_account_details`.`company` = '0') </strong><strong>AND</strong> <strong>(`tbl_account_details`.`vendor_id` IS NULL </strong><strong>OR</strong> <strong>`tbl_account_details`.`vendor_id` = '0')</strong><strong>.</strong> 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_userDDList"><u>{{path}}explorer/#!/Department/Department_userDDList</u></a> 
    </li>
  </ul>
 <br />  
  <p><i><code>usersList</code></i></p> 
  <ul>
    <li>
        This API is used to get the list of users of particular department by departmentId if exists. 
    </li>
    <li>
        For reference: <a href="{{path}}explorer/#!/Department/Department_usersList"><u>{{path}}explorer/#!/Department/Department_usersList</u></a> 
    </li>
  </ul>
</div>
</mat-card>
