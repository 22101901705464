<div class="add_remove_module">
<h2 mat-dialog-title>Check apps that you want to unsubscribe</h2>

<mat-dialog-content>
  <div class="row">
  <div class="col-sm-4" *ngFor="let mainMenu of request.moduleList">
    <div class="checkbox-fade fade-in-primary">
      <mat-checkbox class="text-black-checkbox" name="selectModules" value="{{mainMenu.menuId}}" type="checkbox" [checked]="mainMenu.isSubscribed"
        [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)">
        {{mainMenu.label | uppercase}}
      </mat-checkbox>
    </div>
  </div>
  <div class="col-sm-12" *ngIf="flag">Your payment will decrease by {{currency}}{{costToDisplay}} from next billing cycle.</div>
</div>
</mat-dialog-content>

<mat-dialog-actions class="btn-row-group d-flex justify-content-between">
  <button mat-raised-button mat-dialog-close class="btn-cancel dialogBtns tw-mr-2">Cancel</button>
  <button name="removeModulesBtn" mat-raised-button [disabled]="removeModulesFlag" (click)="removeModules()" 
  class="btn-submit form-button-40"> Remove Apps </button>
</mat-dialog-actions>
</div>