
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
   <div class="api-docs">
<h2 class="content-header-name">Transaction Model</h2>
 <h4>Commonly used methods in transaction:</h4> 
 <ul>
     <li>
            getTransactionsList :  
            <ul>
                <li>
                        To get the transaction list per year for income &amp; expense from `tbl_transactions`. 

                </li>
            </ul>
     </li>
 </ul>
 <br />  
     <p><i><code> getDepositDashboard</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the income expense values(todayIncome, totalIncome, todayExpense, totalExpense) from `tbl_transactions` for dashboard. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_getDepositDashboard"><u>{{path}}explorer/#!/Transactions/Transactions_getDepositDashboard</u></a> 

         </li>
     </ul>
     <p><i><code> getInvoiceAndPaymentTodayDashboard</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the today&rsquo;s total invoice and payment amount for dashboard. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_getInvoiceAndPaymentTodayDashboard"><u>{{path}}explorer/#!/Transactions/Transactions_getInvoiceAndPaymentTodayDashboard</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> getInvoicePaidAndDueAmountDashboard</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the invoice paid amount and due amount using invoiceTotalsPerCurrency method for dashboard. 

         </li>
         <li>
                invoiceTotalsPerCurrency : 
                <ul>
                    <li>
                            It will return the invoice paid &amp; due amount for each currency through iteration by using invoice calculateTo method. 

                    </li>
                </ul>
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_getInvoicePaidAndDueAmountDashboard"><u>{{path}}explorer/#!/Transactions/Transactions_getInvoicePaidAndDueAmountDashboard</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> incomeExpense</code></i></p> 
     <ul>
         <li>
                This API will calculate the income &amp; expense transactions per year by getTransactionsList method. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_IncomeExpense"><u>{{path}}explorer/#!/Transactions/Transactions_IncomeExpense</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> listById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the transaction list for a particular client including totalCount, currency, totalIncome, totalExpense . 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_listById"><u>{{path}}explorer/#!/Transactions/Transactions_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> previousYearExpense</code></i></p> 
     <ul>
         <li>
                This API will fetch the previous year expense transactions from `tbl_transactions` by providing start year(<strong>[YYYY]</strong>) &amp; end year(<strong>[YYYY]</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_previousYearExpense"><u>{{path}}explorer/#!/Transactions/Transactions_previousYearExpense</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> previousYearIncomeComparision</code></i></p> 
     <ul>
         <li>
                This API will fetch the previous year income transactions from `tbl_transactions` by providing start year(<strong>[YYYY]</strong>) &amp; end year(<strong>[YYYY]</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_previousYearExpense"><u>{{path}}explorer/#!/Transactions/Transactions_previousYearExpense</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> transactionsReportList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the transaction list with debitTotal,creditTotal, balanceTotal from `tbl_transactions`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Transactions/Transactions_transactionsReportList"><u>{{path}}explorer/#!/Transactions/Transactions_transactionsReportList</u></a> 

         </li>
     </ul>
    </div>
</mat-card>
