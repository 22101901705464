
<div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
            <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
              <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
          </a>    
      </button>
    </section>
</div>

<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Types OF COA Model</h2>
 <p><i><code> coaTypeList</code></i></p> 
 <ul>
     <li>
            This API is used to fetch the types of COA list from `tbl_types_of_coa`. 

     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/TypesOfCoa/TypesOfCoa_coaTypeList"><u>{{path}}explorer/#!/TypesOfCoa/TypesOfCoa_coaTypeList</u></a> 

     </li>
 </ul>
 </div>  
</mat-card>
