import { Routes } from '@angular/router';
import { TaskComponent } from './task.component';
import { AuthGuard } from '../../app.authGuard.service';
import { TaskDialogEntryComponentComponent } from './task-dialog-entry-component/task-dialog-entry-component.component';

const tasksRoutes: Routes = [
    {
        path: 'admin/tasks',
        canActivate: [AuthGuard],
        data: { title: 'Admin', status: false },
        component: TaskComponent
    },
    {
        path: 'admin/tasks/details/:id',
        canActivate: [AuthGuard],
        data: { title: 'Admin', status: false },
        component: TaskDialogEntryComponentComponent
    }
];

export default tasksRoutes;
