
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
 
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Ticket Category Model</h2>
 <p><i><code> Add</code></i></p>
 <ul>
        <li>
                This API is used to add new ticket category into `tbl_ticket_category` using <strong>ticketCategoryName</strong> and <strong>prioritiesId</strong>. 

        </li>
        <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_add"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_add</u></a> 

        </li>
    </ul> 
 <br />  
     <p><i><code> Delete</code></i></p> 
     <ul>
         <li>
                This API will delete the ticket category from `tbl_ticket_category` by <strong>ticketCategoryId</strong> if exists. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_delete"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> List</code></i></p> 
     <ul>
         <li>
                This API will list all the ticket category from `tbl_ticket_category`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_list"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> listById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of particular ticket category using <strong>ticketCategoryId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_listById"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> Modify</code></i></p> 
     <ul>
         <li>
                It is used to update the details of ticket category into `tbl_ticket_category` by <strong>ticketCategoryId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_modify"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_modify</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code> paginationList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the ticket category list with pagination. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_PaginationList"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_PaginationList</u></a> 

         </li>
         </ul>
<br />      
     <p><i><code> updateActions</code></i></p> 
     <ul>
         <li>
                This API is used to update the action(<strong>category or auto assign</strong>) values into `tbl_ticket_category`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/TicketCategory/TicketCategory_updateActionButtons"><u>{{path}}explorer/#!/TicketCategory/TicketCategory_updateActionButtons</u></a> 

         </li>
     </ul>
    </div>
</mat-card>
