<button type="button" class="close" mat-dialog-close aria-label="Close">
  <span aria-hidden="true" class="close-style">&times;</span>
</button>
<!--Header name -->
<h2 *ngIf="data.name === 'write'" mat-dialog-title>Text Feild</h2>
<h2 *ngIf="data.name === 'sign'" mat-dialog-title>Signature</h2>
<h2 *ngIf="data.name === 'adminsign'" mat-dialog-title>Signature</h2>
<h2 *ngIf="data.name === 'initial'" mat-dialog-title>Initial/Description</h2>

<!--Option capturing-->
<mat-dialog-content class="mat-typography" style="width: 500px; height: 400px;">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">

    <!--Description -->
    <mat-tab label="Write" *ngIf="data.name === 'write'">
      <mat-form-field>
        <mat-select [(value)]="desc" (selectionChange)="changeFont()" (valueChange)="changeFont()">
          <mat-option value="Sofia">Sofia</mat-option>
          <mat-option value="Great Vibes">Great Vibes</mat-option>
          <mat-option value="Hanalei">Hanalei</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width" style="margin-top:3%;">
        <textarea matInput (click)="textValue()" [(ngModel)]="signatureText"
          style="height:50px;font-size:50px;font-stretch: expanded;text-align:center;">
            </textarea>
      </mat-form-field>
    </mat-tab>

    <!--Initial -->
    <mat-tab *ngIf="data.name === 'initial'">
      <mat-form-field>
        <ng-select #ngSelect [clearable]="false" appendTo="body" [items]="fontRuleIntial"   class="tag-btn" style= "width:100%" name="fontRuleIntial"
          bindLabel="label" bindValue="value" placeholder="Font Style" groupBy="options"
          (change)="changeFont($event)">
        </ng-select>
        <input matInput [ngStyle]="fontRule" (click)="Initialvalue()" [(ngModel)]="initialText"
          style="height:100% !important;font-size:25px;font-stretch: expanded;text-align:center">
      </mat-form-field>
    </mat-tab>

    <!--Signature-->
    <mat-tab  *ngIf="data.name === 'adminsign'">
      <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"
        style="background-color: aliceblue;"></signature-pad>
      <!-- <img [src]="adminsignatureImage" *ngIf="adminsignatureImage"> -->
    </mat-tab>

    <!--Upload-->
    <mat-tab label="Upload" *ngIf="data.name === 'sign'">
      <div class="col-md-12" style="margin-top: 10px;">
        <div class="form-group form-default m-b-5 form-static-label">
          <span class="m-r-10">
            <button name="selectBtn" type="button" mat-raised-button color="primary" style=" background-color: #4caf50"
              (click)="inputFile.click()">SelectFile</button>
            <input #inputFile class="input-file" style=" display: none !important;" type="file" id="file" name="files"
              (change)="onConvertFile()" multiple>
            <!-- <img [src]="signatureUpload" *ngIf="signatureUpload" style="height: 200px;width: 200px;"> -->
          </span>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button type="button" *ngIf="data.name === 'adminsign'" (click)="clearScreen()" class="btn-clear btn-cancel mat-raised-button">clear</button>
  <button mat-button mat-dialog-close style="background-color: #4caf50;
  border-radius: 19px;">Done</button>
</mat-dialog-actions>
