import { Component, OnInit } from '@angular/core';
import {environment} from './../../../../environments/environment';
import {OpportunitiesService} from "../../../service/opportunities.service";
import { MatCard } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
@Component({
    selector: 'app-opportunities-model',
    templateUrl: './opportunities-model.component.html',
    styleUrls: ['./opportunities-model.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, MatIcon, MatCard]
})
export class OpportunitiesModelComponent implements OnInit {
  public path:any;
  constructor(public opportunitiesService: OpportunitiesService) { }

  ngOnInit() {
    this.path = environment.BASE_URL;
  }

}
