
<div class="api-docs-back-btn">
       <section class="content-header">
         <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
               <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
                 <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
             </a>    
         </button>
       </section>
 </div>
  
<mat-card>
    <div class="api-docs">
<h2 class="content-header-name">Project Model</h2>
 <p><i><code>Add</code></i></p> 
 <ul>
     <li>
            This API is used to create a new project into `tbl_project`. 

     </li>
     <li>
            Before creating project, the steps below will be executed: 
            <ul>
                <li>
                        It will fetch the client details from `tbl_client` by clientId. 

                </li>
                <li>
                        checkGroups method will be called to check the permissions(returns groupNo). 

                </li>
            </ul>
     </li>
     <li>
            After creating the project successfully, steps below will be executed: 
            <ul>
                <li>
                        getProjectInfoById called to fetch the project details. 

                </li>
                <li>
                        <strong>sendProjectNotifyClient</strong> method will send an email to users related to that project. 

                </li>
                <li>
                        Timebar will be created for that project into `tbl_opportunities_no_days`. 

                </li>
                <li>
                        Finally, email will be sent to the participants of the project if exists. 

                </li>
            </ul>
     </li>
     <li>
            For reference: <a href="{{path}}explorer/#!/Project/Project_add"><u>{{path}}explorer/#!/Project/Project_add</u></a> 

     </li>
 </ul>
 <br />  
     <p><i><code>changeBillStatus</code></i></p> 
     <ul>
         <li>
                This API is used to change the bill status of the project into `tbl_project` by <strong>Project Id</strong> and <strong>Bill Status (1 - Billable, 2- Already Billable)</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_changeBillStatus"><u>{{path}}explorer/#!/Project/Project_changeBillStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Changestatus</code></i></p>
     <ul>
         <li>
                This API is used to change the project status into `tbl_project` by <strong>Project Id</strong> and <strong>projectStatus (cancel,in_progress,started,completed)</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_changestatus"><u>{{path}}explorer/#!/Project/Project_changestatus</u></a>  

         </li>
     </ul> 
 <br />  
     <p><i><code>Clone</code></i></p> 
     <ul>
         <li>
                This API is used to clone the project by using existing <strong>projectId</strong>. 

         </li>
         <li>
                Once the project is cloned successfully, it will create new milestones, tasks, timebar with the new <strong>projectId</strong> if data exists for the old <strong>projectId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_clone"><u>{{path}}explorer/#!/Project/Project_clone</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Delete</code></i></p> 
     <ul>
         <li>
                This API is used to delete the project from `tbl_project` by <strong>projectId</strong>. 

         </li>
         <li>
                Before deleting the project, it will delete the comments, attachments, milestones, tasks, bugs, timebar, pinaction details related to the <strong>projectId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_delete"><u>{{path}}explorer/#!/Project/Project_delete</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>departmentListByParentId</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the department list from `tbl_department` by departmentId of that project when converting opportunity into project. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_departmentListByParentId"><u>{{path}}explorer/#!/Project/Project_departmentListByParentId</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>detailCount</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the count of records of each submodule in the project by <strong>projectId</strong>. 

         </li>
         <li>
                <strong>project_detail_count</strong> is a procedure to get details count. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_detailCount"><u>{{path}}explorer/#!/Project/Project_detailCount</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>dropdownList</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the list of projectId and projectName from `tbl_project` for dropdown purpose. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_dropdownList"><u>{{path}}explorer/#!/Project/Project_dropdownList</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>dropdownListByClientId</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of projects for particular client by  <strong>client id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_dropdownListByClientID"><u>{{path}}explorer/#!/Project/Project_dropdownListByClientID</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getDashboardDetails</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of projects of particular client from `tbl_project` if the condition satisfied: <strong>tbl_client.client_id = tbl_project.client_id WHERE tbl_project.project_status != 'completed' AND tbl_project.project_status != 'cancel'.</strong> 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_getDashboardDetails"><u>{{path}}explorer/#!/Project/Project_getDashboardDetails</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getProjectsUsersById</code></i></p> 
     <ul>
         <li>
                It is used to fetch list of users(owner, projectManager) participated in the project. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_getProjectsUsersById"><u>{{path}}explorer/#!/Project/Project_getProjectsUsersById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>getTimerClockStatus</code></i></p> 
     <ul>
         <li>
                This API is used to get the timer status(&lsquo;on&rsquo;, &lsquo;off&rsquo;) of the project by <strong>projectId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_getTimerClockStatus"><u>{{path}}explorer/#!/Project/Project_getTimerClockStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>inProgressProjectDashboard</code></i></p> 
     <ul>
         <li>
                This API is used to get the count of projects which are in inProgress status. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_inProgressProjectDashboard"><u>{{path}}explorer/#!/Project/Project_inProgressProjectDashboard</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>List</code></i></p> 
     <ul>
         <li>
                This API is used to fetch all the list of project from `tbl_project` by providing type (<strong>Enter Project List type ie. 1) archive - (for Archive List with Completed Project Status) 2) projectlist -(Project List with uncompleted or overdue Project Status) 3) clientList - (Get all project client list) 4) overdue - Get all overdue project list</strong>). 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_list"><u>{{path}}explorer/#!/Project/Project_list</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>listById</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the details of project getProjectInfoById. 

         </li>
         <li>
                getProjectInfoById method will be called when creating or updating the project. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_listById"><u>{{path}}explorer/#!/Project/Project_listById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>Modify</code></i></p> 
     <ul>
         <li>
                This API is used to modify the details of project into `tbl_project` same as like adding new project. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_modify"><u>{{path}}explorer/#!/Project/Project_modify</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>projectPersonInfo</code></i></p> 
     <ul>
         <li>
                This API will fetch the contact details of project person from `tbl_account_details`. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_projectPersonInfo"><u>{{path}}explorer/#!/Project/Project_projectPersonInfo</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>projectsByPipeline</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of projects for the particular pipeline by using <strong>project pipeline id</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_projectsByPipeline"><u>{{path}}explorer/#!/Project/Project_projectsByPipeline</u></a>  

         </li>
     </ul>
 <br />  
     <p><i><code>projectsInfoInCountDays</code></i></p> 
     <ul>
         <li>
                This API is used to fetch project information in days("inactiveDays", "openDays") count. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_projectsInfoInCountDays"><u>{{path}}explorer/#!/Project/Project_projectsInfoInCountDays</u></a> 

         </li>
     </ul>
     <p><i><code>projectTaskStatus</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the count of tasks("TaskCount", "ClosedCount", "OpenCount") exists for that project by <strong>ProjectId</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_projectTaskStatus"><u>{{path}}explorer/#!/Project/Project_projectTaskStatus</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>timebarStagesData</code></i></p> 
     <ul>
         <li>
                This API is used to fetch the list of timebar stages for the particular project. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_timebarStagesData"><u>{{path}}explorer/#!/Project/Project_timebarStagesData</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>totalTimeById</code></i></p> 
     <ul>
         <li>
                It will fetch the total time of the project in hours, minutes and seconds by using <strong>projectId</strong>. 
 
         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_totalTimeById"><u>{{path}}explorer/#!/Project/Project_totalTimeById</u></a> 

         </li>
     </ul>
 <br />  
     <p><i><code>updateProjectStage</code></i></p> 
     <ul>
         <li>
                This API is used to update the timebar(opportunity stages) values using <strong>Project id</strong> and <strong>stage id</strong> if <strong>projectStatus </strong>!== <strong>'completed'</strong>. 

         </li>
         <li>
                For reference: <a href="{{path}}explorer/#!/Project/Project_updateProjectStage"><u>{{path}}explorer/#!/Project/Project_updateProjectStage</u></a> 

         </li>
     </ul>
     </div>
</mat-card>
