
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">Bugs Model </h2>
        <h4>Note</h4>
      <ul>
        <li> sendbugEmail - method will fetch companyName from `tbl_config` 
          and username, userId from getUserName method.
          </li>
      </ul>
      <br />
      <p><i><code>Add</code></i></p>
      <ul>
      <li>
          This API is used to create a new bug.
      </li>
      <li>
          Once the bug is created, an email will be sent to the reporter 
          and to the selected participants of the created bug using sendbugEmail.
      </li>
      <li>
          For sending email to users template will be retrieved 
          from the `tbl_email_templates` by email_group ="bug_reported" .
      </li>
      <li>
          sendbugEmail method is used here.
      </li>
      <li>
          Once userDetails are fetched successfully, an email will be sent using shootEmail method.
      </li>
    
      <li>
          For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_add" >
            {{currentUrl}}explorer/#!/Bugs/Bugs_add</a>
      </li>
    </ul>
    <br />
    <p><i><code>Modify</code></i></p>    
    <ul>
    <li>
        This API is used to update the bug details by bugId if exists.
    </li>
    <li>
        Once the bug is updated, an email will be sent to the reporter and to 
        the selected participants of the same bug using sendbugEmail.
    </li>
    <li>
        For sending email to users template will be retrieved 
        from the `tbl_email_templates` by email_group ="bug_updated" .
    </li>
    <li>
        sendbugEmail method is used here.
    </li>
    <li>
        Once userDetails are fetched successfully, 
        an email will be sent to users using shootEmail method.
    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_modify" >
          {{currentUrl}}explorer/#!/Bugs/Bugs_modify</a>
    </li>
  </ul>
  <br />
  <p><i><code>Details</code></i></p>   
    <ul>
    <li>
        This API is used to get bug details by bugId, moduleId(optional) and moduleType(optional).
    </li>
    <li>
        getBugDetails method is used to get the bug details while 
        adding and updating the bugs as well as for detail page,.
    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_details" >
          {{currentUrl}}explorer/#!/Bugs/Bugs_details</a>
    </li>
  </ul>
  <br />

  <p><i><code>List</code></i></p> 
    <ul>
    <li>
        This API is used to get the list of bugs based on the userPermissions.
    </li>
    <li>
        isUserDepartmentHead method is used here.
    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_list" >
          {{currentUrl}}explorer/#!/Bugs/Bugs_list</a>
    </li>
  </ul>
  <br />

  <p><i><code>Status</code></i></p>  
    <ul>
    <li>
        This API is used to update the status of the bug by bugId and bugStatus.
    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_status" >
          {{currentUrl}}explorer/#!/Bugs/Bugs_status</a>
    </li>
  </ul>
  <br />
  <p><i><code>Delete</code></i></p>    
    <ul>
    <li>
        This API is used to delete the bug by using bugId.
    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_delete">
          {{currentUrl}}explorer/#!/Bugs/Bugs_delete</a>
    </li>
  </ul>
  <br />
  <p><i><code>Dropdownlist</code></i></p>   
    <ul>
    <li>
        This API is used to get the list of bugs with respect to clientId(optional).    </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_dropdownList">
          {{currentUrl}}explorer/#!/Bugs/Bugs_dropdownList</a>
    </li>
  </ul>
  <br />
  <p><i><code>inProgressBugDashboard</code></i></p>    
    <ul>
    <li>
        This API is used to fetch the count of bugs by bug_status = in_progress from `tbl_bug`.   
      </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_inProgressBugDashboard">
          {{currentUrl}}explorer/#!/Bugs/Bugs_inProgressBugDashboard</a>
    </li>
  </ul>
  <br />
  <p><i><code>detailCount</code></i></p>    
    <ul>
    <li>
        This API is used to get count of records of each submodule by using bugId.   
      </li>
      <li>
          call bug_detail_count(?, ?, ?) - bug_detail_count is the procedure to get the count by bugId.
      </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_detailCount">
          {{currentUrl}}explorer/#!/Bugs/Bugs_detailCount</a>
    </li>
  </ul>
  <br />

  <p><i><code>viewDetail</code></i></p>     
    <ul>
    <li>
        This API is used to fetch the bug details for bug view detail page by using bugId.  
      </li>
      <li>
          call bug_detail_count(?, ?, ?) - bug_detail_count is the procedure to get the count by bugId.
      </li>
    <li>
        For reference: <a href="{{currentUrl}}explorer/#!/Bugs/Bugs_viewDetail">
          {{currentUrl}}explorer/#!/Bugs/Bugs_viewDetail</a>
    </li>
  </ul>
</div>
</mat-card>
