<div class="testCase_detail">
  <div class="api-docs-back-btn">
    <section class="content-header">
      <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary" (click)="goBack()"> 
          <mat-icon>arrow_back_ios</mat-icon> Back to Test Cases   
      </button>
    </section>
  </div>

<section class="content">
  <div id="content">
    <div id="main-content">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup class="mat-tab-header-noborder">
        <mat-tab label="Details">
          <ng-template matTabContent>
            <app-testcase-detail [testCaseId]="testCaseId"></app-testcase-detail>
          </ng-template>
        </mat-tab>
        <mat-tab label="Comments">
          <ng-template mat-tab-label>
            <span>Comments</span>
            <span class="taskDetailCount"></span>
          </ng-template>
          <ng-template matTabContent>
            <!-- <app-task-comment [moduleId]="taskId" [activated]="activated"[viewType]="'task'" [isClientActive]="inActiveStatus" [ticketLockStatus]="taskDeatails.ticketLockStatus" (calculateCount)="getTaskCount()"></app-task-comment> -->
            <app-testcase-comments></app-testcase-comments>
          </ng-template>
        </mat-tab>
        <mat-tab label="Attachments">
          <ng-template mat-tab-label>
            <span>Attachments</span>
            <!-- <span class="taskDetailCount">{{taskCount?.attachments ? taskCount?.attachments : ''}}</span> -->
            <span class="taskDetailCount"></span>
          </ng-template>
          <ng-template matTabContent>
            <!-- <app-task-attachments [taskId]="taskId" [activated]="activated" [viewType]="'task'" [isClientActive]="inActiveStatus" [ticketLockStatus]="taskDeatails.ticketLockStatus" (taskDetailCount)="getTaskCount()"></app-task-attachments> -->
            <app-testcase-attachments [testCaseId]="testCaseId"></app-testcase-attachments>
          </ng-template>
        </mat-tab>
        <mat-tab label="Activities">
          <ng-template mat-tab-label>
            <span>Activities </span>
            <span class="taskDetailCount">{{totalCount ? totalCount : '0'}}</span>
            <span class="taskDetailCount"></span>
          </ng-template>
          <ng-template matTabContent >
            <!-- <app-common-audit-trial-popup [moduleType]="'tasks'" [moduleId]="taskId" [referenceCode]="dataForPermission.taskName"></app-common-audit-trial-popup> -->
            <app-testcase-activities></app-testcase-activities>
          </ng-template>
        </mat-tab>

        <mat-tab >
          <ng-template mat-tab-label>
            <h3 class="all-caps">Responsible User</h3>
            <div class="row userImg res-img" style="margin-left: auto;" *ngFor="let user of testCaseData.responsibleUsersArr; let i = index" (click)="$event.stopPropagation()">
              <span *ngIf="i <= 4">
                  <img  src="{{user.avatar}}" alt="{{user.fullname}}" title="{{user.fullname}}" class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'"
                  (click)="$event.stopPropagation()">
                  <span *ngIf="user.activated == 1" style="margin: 0px 0 8px -10px;" class="circle circle-success circle-lg"></span>
                  <span *ngIf="user.activated == 0" style="margin: 0px 0 8px -10px;" class="circle circle-warning circle-lg"></span>
               </span>
             </div>
          </ng-template>
        </mat-tab>
        <mat-tab class="task_participants">
          <ng-template mat-mdc-tab class="task_participants">
            <div class="content-left-area-tickets-single organization-details">
              <h3 class="all-caps">Participants</h3>
              <span class="everyone_participants" *ngIf="testCaseData.permissionType === 2" (click)="$event.stopPropagation()">Everyone<i class="fas fa-exclamation" matTooltip="Permission to all department Users" (click)="$event.stopPropagation()"></i></span>
              <div class="example-viewport-list cdk-list _participants_users_img">
                <ng-container *ngIf="testCaseData.permissionType === 1 || testCaseData.permissionType === 3">
                   <div class="userImg row" *ngFor="let user of testCaseData.users;  trackBy: trackByVisibleFn" (click)="$event.stopPropagation()">
                    <img  src="{{user.avatar}}" alt="{{user.fullname}}" title="{{user.fullname}}" class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'"
                    (click)="$event.stopPropagation()">
                    <span *ngIf="user.activated == 1" style="margin: 0px 0 8px -10px;" class="circle circle-success circle-lg"></span>
                    <span *ngIf="user.activated == 0" style="margin: 0px 0 8px -10px;" class="circle circle-warning circle-lg"></span>
                  </div> 
                  <!-- <div class="userImg row" *ngFor="let userDetails of testCaseData.users; let i = index" (click)="$event.stopPropagation()">
                    <span *ngIf="userDetails.activated == 1">
    
                      <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
                      class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'">
                      <span style="margin: 0px 0 8px -10px;" class="circle circle-success circle-lg"></span>
                    </span>
                    <span *ngIf="userDetails.activated == 0">
                      <img  src="{{userDetails.avatar}}" alt="{{userDetails.fullname}}" title="{{userDetails.fullname}}"
                      class="profile-pic mat-icon-button list-avatar-img" onError="this.src='assets/img/default_avatar.jpg'">
                      <span style="margin: 0px 0 8px -10px;" class="circle circle-warning circle-lg"></span>
                    </span>
                  </div> -->
                </ng-container>
                <div class="userImg row" style="margin-left: 5px;">
                    <a class="btn btn-sm btn-action plus_icon plus-btn" (click)="openParticipantsDialog(testCaseData)">
                    <div class="ripple">
                      <mat-icon class="mat-icon material-icons" role="img" aria-hidden="true" color="primary">add</mat-icon>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template matTabContent>
            <app-testcase-detail [testCaseId]="testCaseId"></app-testcase-detail>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>

</div>
