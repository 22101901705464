import { ConfigService } from './../../../service/config.service';
import { LoginService } from './../../../service/login.service';
import { Component, OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '../../../admin/shared/AppComponentBase';
import { KnowledgebaseService } from '../../../service/knowledgebase.service';
import { ActivatedRoute, Params, RouterLink } from "@angular/router";
import { EStatusCode } from '../../../service/Utils/Interfaces.class';

import { Subject, Subscription } from 'rxjs';
import {TenantExistsService} from "../../../service/tenant-exists.service";
import { COMMON } from '../../../service/constant';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatOption, MatOptgroup } from '@angular/material/core';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    standalone: true,
    imports: [MatToolbar, MatAutocompleteTrigger, FormsModule, MatAutocomplete, NgIf, MatOption, NgFor, MatOptgroup, RouterLink, SlicePipe]
})
export class OverviewComponent extends AppComponentBase implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  panelOpenState = false;
  hostname: string;
  catCount: number = 0;
  companyName: string = "UTILIKO";
  companyLogo: string = "";
  companyKey: string;
  items = [];
  noArticleFlag: boolean;
  articleList = [];
  public version;
  public currYear;
  mostViewedArticles = [];
  limit = 20;
  start = 0;
  searchText;
  isLoading = false;
  private searchValue$ = new Subject<string>();

  constructor(inject: Injector,private tenantExistsService: TenantExistsService, public configService: ConfigService, public knowledgebaseService: KnowledgebaseService, private loginService: LoginService) {
    super(inject);
    this.configService.updateIsUserLogged.next(false);
    this.version = COMMON.VERSION;
    this.currYear = COMMON.CURRENT_YEAR;
  }

  ngOnInit() {
    this.hostname = window.location.hostname;
    // this.hostname = '<enter domain name>'; // For local testing eg., 'utiliko.xyz'

    this.checkDomain(this.hostname);

    this.searchValue$.pipe(
      debounceTime(800),
      distinctUntilChanged())
    .subscribe(data => {
      this.start = 0; this.items = []; this.articleList = []; this.getKbOverview();
    });
    
  }
  search(searchString){
    this.isLoading = true;
    this.searchValue$.next(searchString);
  }
  checkDomain(domain) {
    if (domain) {
      domain = domain.replace(/ /g, "").toLowerCase().trim();
      const isDomainExists : Subscription = this.tenantExistsService.domainExists(domain).subscribe((res: any) => {
        if (!res.data.isDomainExist) {
          this.router.navigate(["/page-not-found"], { replaceUrl: true });
        } else {
          this.companyKey = res.data.tenantCompanyKey
          this.isLoading = true;
          this.getKbOverview();
          this.loginService.setTenantCompanyKey(res.data.tenantCompanyKey);
          this.companyName = res.data.companyName;
          this.companyLogo = res.data.companyLogo;
        }
      });
      this.subscriptions.push(isDomainExists);
    }
  }

  getKbOverview(){
    if (this.companyKey) {
      var isUbm = 0;
    } else {
      var isUbm = 1;
    }
    let filter = {start: this.start, limit: this.limit};
    const getPublcOvr : Subscription = this.knowledgebaseService.getPublicOverview(this.companyKey, isUbm, this.searchText, filter).subscribe((res: any) => {
      if (res.info.code == EStatusCode.OK) {
        if (res.data.articleList.length > 0) {
          this.catCount = res.data.categoryCount;
           let itemsArr = res.data.articleList.map((e) => { 
            e.limit = 4;
            let articlesArr = e.articles.length && e.articles.map((el) => {
              el['routerLink'] = this.getroutingLink(e.label, el.artName);
              el['queryParams'] = this.getQueryParams(e.id, el.artId);
              return el;
            });
            e.articles = articlesArr;
            return e;
          });
          this.items = this.items.concat(itemsArr);
          this.articleList = this.items;
          this.noArticleFlag = false;
          this.mostViewedArticles = res.data.mostViewedArticles.map((e) => {
            e['routerLink'] = this.getroutingLink(e.name, e.artName);
            e['queryParams'] = this.getQueryParams(e.artCategoryId, e.artId);
            return e;
          });
        } else {
          this.catCount = 0;
          this.items = [];
          this.noArticleFlag = true;
          this.articleList = [];
        }
        this.isLoading = false;
      } else {
        this.toastr.error("No information Found");
        this.isLoading = false;
      }
    });
    this.subscriptions.push(getPublcOvr);
  }

  changePage(categoryName,articleName,artId, catId) {
    this.knowledgebaseService.articleDetail.artId = artId;
    this.knowledgebaseService.setArticleDetailsId(artId);
    this.knowledgebaseService.setCategoryId(catId);
    this.knowledgebaseService.articleDetail.url = 'knowledgebase/details/';
    this.knowledgebaseService.detailsArticleEventListener.next(artId);    
    // this.routerDialog.navigateByUrl(escape('knowledgebase/' + catId+'/'+artId));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    })
  }
  seeAll(index) {
    this.items[index].limit = this.items[index].limit == this.items[index].articles.length ? 4 : this.items[index].articles.length;
  }
  
  loadMoreArticles() {
    this.isLoading = true;
    this.start = this.items.length;
    this.getKbOverview();
  }

  trackArticlesById(index: number, item){
    return item.artId;
  }

  trackArticleList(index: number, item){
    return item.id;
  }

  trackMostViewedArticles(index: number, item){
    return item.artId;
  }

  trackItems(index: number, item){
    return item.id;
  }

  trackArticles(index: number, item){
    return item.artId;
  }

  getroutingLink(categoryName, articleName){
    let path = categoryName.toLowerCase() +'/'+ articleName.toLowerCase();
    return path.trim().replace(/(\s*-\s*|\s+)/g, '-');
  }
  getQueryParams(catId, artId){
    return {catId:catId,artId:artId};
  }
}
