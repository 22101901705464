import { Component, Injector, OnDestroy, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';

import { AppComponentBase } from "../../AppComponentBase";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { EStatusCode, IResponse } from "../../../../service/Utils/Interfaces.class";
import ImageCompressor from 'image-compressor.js';
import { BugsService } from "../../../../service/bugs.service";
import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from "ngx-gallery";
import { SignaturePad } from 'angular2-signaturepad';
import { TicketsService } from '../../../../service/tickets.service';
import { ProjectService } from '../../../../service/project.service';
import { NgOption, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { MatButton } from '@angular/material/button';
import { SignaturePad as SignaturePad_1 } from '../../../../../../../libs/third-party-deprecated/src/lib/signaturepad/angular2-signaturepad.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'docsign-dialog-popup',
    templateUrl: 'docsign-dialog-popup.html',
    standalone: true,
    imports: [
        MatDialogClose,
        NgIf,
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        MatTabGroup,
        MatTab,
        MatFormField,
        MatSelect,
        MatOption,
        MatInput,
        FormsModule,
        NgSelectModule,
        NgStyle,
        ExtendedModule,
        SignaturePad_1,
        MatButton,
        MatDialogActions,
    ],
})
export class DocsignDialogPopup extends AppComponentBase implements OnDestroy, AfterViewInit {

    @ViewChild(SignaturePad) signaturePad: SignaturePad;
    @ViewChild("ngSelect") ngSelect: NgSelectComponent;
    font: NgOption = [];
    fontArr: Array<object> = [];

    // font style
    public fontRule = { "font-family": "Sofia" };
    public fontRuleIntial : NgOption = []
    public selected = "Sofia";
    public initial = "Sofia";
    public desc = "Sofia";
    public adminselected = "Sofia";

    // varaiable declaration for options
    public signatureText: any = "";
    public signatureImage;
    public adminsignatureImage;
    public initialText: any = "";
    public isImage = false;
    public IsValue = false;
    public signatureUpload;
    public url: any;

    // passed through to szimek/signature_pad constructor
    private signaturePadOptions: Object = {
        'minWidth': 5,
        'canvasWidth': 450,
        'canvasHeight': 250,
        'canvasBackground': 'aliceblue',
        'backgroundColor': 'aliceblue'
    };
    public fileCount: any;
    public totalCompressedImg: any;
    public totalFileName: any;
    public myInputVariable: ElementRef;
    public _albums: Array<any> = [];
    public albumArr: Array<any> = [];

    @ViewChild('onlyPreviewGallery') onlyPreviewGallery: NgxGalleryComponent;
    public galleryOptions: NgxGalleryOptions[] = [];
    public galleryImages: NgxGalleryImage[] = [];
    public fileAttachmets: any;
    public isInitial: boolean;
    public isInitialValue: any;
    public isadminImage: boolean;
    closeDD: any;

    private onScroll = (event: any) => {
        if (this.ngSelect && this.ngSelect.isOpen) {
            const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
            if (isScrollingInScrollHost) { return; }
            this.ngSelect.close();
        }
    }


    constructor(inject: Injector, public ticketsService: TicketsService, public bugsService: BugsService, public projectService: ProjectService, public dialogRef: MatDialogRef<DocsignDialogPopup>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        super(inject);
        this.configService.updateIsUserLogged.next(false);

        // cheching which option
        if (this.data.name === "write") {
            if (this.data.value)
                this.signatureText = this.data.value;
            this.isImage = false;
            this.IsValue = true;
            this.isInitialValue = false;
        }
        if (this.data.name === "sign") {
            this.selected = this.data.value;
            this.isImage = true;
            this.IsValue = false;
            this.isInitialValue = false;
            this.isadminImage = false;
        }
        if (this.data.name === "adminsign") {
            this.adminselected = this.data.value;
            this.isadminImage = true;
            this.IsValue = false;
            this.isImage = false;
            this.isInitialValue = false;
        }
        if (this.data.name === "initial") {
            if (this.data.value)
                this.initialText = this.data.value;
            this.isImage = false;
            this.IsValue = false;
            this.isInitialValue = true;
        }
        this.fontRuleIntial = [{ "value": "Sofia", "label": "Sofia" },
        { "value": "Great Vibes", "label": "Great Vibes" },
        { "value": "'Hanalei', cursive", "label": "Hanalei" },
        ]
    }
    ngAfterViewInit() {

        if (this.data.value) {
            if (this.data.name === 'initial') {
                this.signaturePad.fromDataURL(this.data.value);
            }
            if (this.data.name === 'sign') {
                this.signaturePad.fromDataURL(this.data.value);
                this.projectService.selected = this.data.value;
            }
            if (this.data.name === 'adminsign') {
                this.signaturePad.fromDataURL(this.data.value);
                this.projectService.adminselected = this.data.value;
            }
        }
        else {
            if (this.data.name === 'sign') {
                this.signaturePad.set('minWidth', 5);
                this.signaturePad.clear();
            }
            if (this.data.name === 'adminsign') {
                this.signaturePad.set('minWidth', 5);
                this.signaturePad.clear();
            }
        }
    }

    ngOnDestroy() {
        if (this.isImage) {
            this.projectService.selected = this.signatureImage;
        }
        if (this.isadminImage) {
            this.projectService.adminselected = this.adminsignatureImage;
        }
        if (this.IsValue) {
            this.projectService.desc = this.signatureText;
            this.projectService.fontStyle = this.fontRule;
        }
        if (this.isInitialValue) {
            this.projectService.initial = this.initialText;
            this.projectService.fontStyleInitial = this.fontRule;
        }
        this.projectService.isImage = this.isImage;
        this.projectService.isadminImage = this.isadminImage;
        this.projectService.IsValue = this.IsValue;
        this.projectService.isInitialValue = this.isInitialValue;
        window.removeEventListener('scroll', this.onScroll, true);
    }

    // clear the screen
    clearScreen() {
        this.signaturePad.clear();
    }

    onFileChanged(event) {
        /* if (event.target.files && event.target.files[0]) {
           var reader = new FileReader();

           reader.readAsDataURL(event.target.files[0]); // read file as data url

           reader.onload = (event) => {
             this.url = event.target.result;
           }
         }*/
    }

    // Getting  signature
    drawComplete() {
        this.signatureImage = this.signaturePad.toDataURL();
        this.isImage = true;
        //this.IsValue=true;
        this.signaturePad.fromDataURL("");
        this.signaturePad.toData();
        if (this.data.name === 'adminsign') {
            this.adminsignatureImage = this.signaturePad.toDataURL();
            this.isadminImage = true;
            //this.IsValue=true;
            this.signaturePad.fromDataURL("");
            this.signaturePad.toData();
        }
    }

    drawStart() {
        // console.log('begin drawing');
    }

    // changing style for options
    changeFont(event) {
        if (event.label == "Sofia")
            this.fontRule = { "font-family": "Sofia" };
        if (event.label == "Great Vibes")
            this.fontRule = { "font-family": "'Great Vibes', cursive" };
        if (event.label == "Hanalei")
            this.fontRule = { "font-family": "'Hanalei', cursive" };
        this.projectService.fontStyle = this.fontRule;

    }
    changeFont1() {
        if (this.initial == "Sofia")
            this.fontRuleIntial = { "font-family": "Sofia" };
        if (this.initial == "Great Vibes")
            this.fontRuleIntial = { "font-family": "'Great Vibes', cursive" };
        if (this.initial == "Hanalei")
            this.fontRuleIntial = { "font-family": "'Hanalei', cursive" };
    }

    // changing condition for options
    textValue() {
        this.isImage = false;
        this.IsValue = true;
    }
    Initialvalue() {
        this.isImage = false;
        this.isInitialValue = true;
    }

    //Convert image to file url
    onConvertFile() {
        let img: any = document.querySelector("#file");
        if (typeof (FileReader) !== 'undefined') {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                this.signatureUpload = e.target.result;
                this.signatureImage = this.signatureUpload;
                this.isImage = true;
                //  this.IsValue=true;
            }
            reader.readAsDataURL(img.files[0]);
        }
    }

    // Select file
    onSelectFile(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            let filesAmount = fileInput.target.files.length;
            this.fileCount = this.fileCount + filesAmount;
            if (this.fileCount > 10) {
                if (this.totalCompressedImg.length === 0) {
                    this.fileCount = 0;
                } else {
                    this.fileCount = this.totalCompressedImg.length;
                }
                this.toastr.error('Maximum 10 files are allowed to upload');
            } else {
                let totalFileCount = this.totalCompressedImg.length;
                for (let i = 0; i < filesAmount; i++) {
                    let reader = new FileReader();
                    reader.onload = (e: Event) => {
                        this.totalFileName.push(reader.result);
                        this.getCompressedImage(fileInput.target.files[i]).then((resFile: any) => {
                            let album = this.getAlbumData(resFile, reader.result);
                            resFile.extension = resFile.name.substr(resFile.name.lastIndexOf('.') + 1);
                            resFile.isEdit = false;
                            this._albums[totalFileCount + i] = album;
                            if (this._albums[totalFileCount + i].src) {
                                this.galleryImages.push({
                                    small: this._albums[totalFileCount + i].src,
                                    medium: this._albums[totalFileCount + i].src,
                                    big: this._albums[totalFileCount + i].src,
                                    description: this._albums[totalFileCount + i].caption
                                });
                            }
                            this.totalCompressedImg[totalFileCount + i] = resFile;
                        });
                    }
                    reader.readAsDataURL(fileInput.target.files[i]);
                }
            }
        }
    }


    /*get compressed image on select file*/
    getCompressedImage(file) {
        return new Promise((resolve, reject) => {
            if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
                new ImageCompressor(file, {
                    quality: .5,
                    success(result: any) {
                        resolve(result);
                    },
                    error(e) {
                        console.log(e.message);
                    },
                });
            } else {
                resolve(file);
            }
        });
    }

    /* get preview images data after file select*/
    getAlbumData(files, reader) {
        let albumImg = {};
        if (files.type === 'image/png' || files.type === 'image/jpg' || files.type === 'image/jpeg') {
            albumImg = {
                src: reader,
                caption: files.name,
                thumb: reader,
                type: files.type
            };
        } else {
            albumImg = {
                src: '',
                caption: '',
                thumb: '',
                type: ''
            };
        }
        return albumImg;
    }
    removeMultipleFiles(index) {
        let self = this;
        let isImageDelete = false, fileDetails;
        fileDetails = this.totalCompressedImg[index];
        this.removeUploadedFile(fileDetails).then(function (response) {
            self.totalCompressedImg.splice(index, 1);
            self.totalFileName.splice(index, 1);
            self.fileCount = self.totalCompressedImg.length;
            const deletedFile = self._albums.splice(index, 1);
            if (deletedFile[0]['src'] !== '') {
                self.galleryImages.forEach(function (value, galleryIndex) {
                    if ((deletedFile[0]['caption'] === value['description']) && !isImageDelete) {
                        isImageDelete = true;
                        self.galleryImages.splice(galleryIndex, 1);
                    }
                });
            }
        }).catch(function (error) {
            this.toastr.error(error);
        });
        this.myInputVariable.nativeElement.value = '';
    }
    /* remove uploaded files from preview */
    removeUploadedFile(fileData) {
        return new Promise((resolve, reject) => {
            if (fileData.isEdit) {
                let fileName = fileData.filePath.replace('-/', '');
                this.ticketsService.attachmentsDeleteCloud(fileName).subscribe((res: IResponse) => {
                    if (res.info.code === EStatusCode.OK || res.info.code === EStatusCode.FAILURE) {
                    // alllowing to delete from DB, even it is not deleted from cloud
                        this.bugsService.deleteUploadFile(fileData.uploadedFileId).subscribe((response: IResponse) => {
                            if (response.info.code === EStatusCode.OK) {
                                resolve('success');
                            } else {
                                reject('Failed to delete uploaded file');
                            }
                        });
                    } else {
                        reject('Failed to delete uploaded file');
                    }
                });
            } else {
                resolve('success');
            }
        });
    }

    // get attachments details by attachment id
    getAttachmentData(id) {
        var fileList = [], fileObj, album;
        this.bugsService.getAttachmentsById(id).subscribe((response: IResponse) => {
            if (response.info.code === EStatusCode.OK) {
                this.fileAttachmets = response.data[0];
                fileList = this.fileAttachmets.fileattachement;
                fileList.map(file => {
                    fileObj = {
                        extension: file.ext,
                        isEdit: true,
                        name: file.fileName,
                        url: file.uploadedPath,
                        uploadedFileId: file.uploadedFilesId,
                        filePath: file.files
                    };

                    /*  add image url in gallerybox */
                    album = {
                        src: file.isImage === 1 ? file.uploadedPath : '',
                        caption: file.isImage === 1 ? file.fileName : '',
                        thumb: file.isImage === 1 ? file.uploadedPath : '',
                        type: file.isImage === 1 ? file.ext : ''
                    };
                    this._albums.push(album);
                    if (album.src) {
                        this.galleryImages.push({
                            small: album.src,
                            medium: album.src,
                            big: album.src,
                            description: album.caption
                        });
                    }
                    this.totalCompressedImg.push(fileObj);
                });

            } else {
                this.totalCompressedImg = [];
            }
        });
    }

}
