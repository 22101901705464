import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatCard } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-api-docs',
    templateUrl: './api-docs.component.html',
    styleUrls: ['./api-docs.component.scss'],
    standalone: true,
    imports: [MatIcon, MatGridList, MatGridTile, MatCard, RouterLinkActive, RouterLink, RouterOutlet]
})
export class ApiDocsComponent implements OnInit {
    path = '';

    ngOnInit() {
        this.path = environment.BASE_URL;
        console.log('path:' + this.path);
    }
}
