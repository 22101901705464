import { ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppComponentBase } from '../../../../shared/AppComponentBase';
import { EStatusCode } from '../../../../../service/Utils/Interfaces.class';
import { SignatureComponent } from '../../../signature/signature.component';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { COMMON } from '../../../../../service/constant';
import { Subscription } from 'rxjs';
import { AgreementService } from '../../../../../service/agreement.service';
import { InvoiceServiceService } from '../../../../../service/invoice-service.service';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { CommonDatePipe } from '../../../../shared/pipes/common-date.pipe';
import { SpinnerComponent } from '../../../../shared/spinner.component';
import { ScrollTopButtonComponent } from '../../../../shared/scroll-top-button/scroll-top-button.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatToolbar } from '@angular/material/toolbar';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-admin-client-agreement-view',
    templateUrl: './admin-client-agreement-view.component.html',
    styleUrls: ['./admin-client-agreement-view.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbar, NgClass, ExtendedModule, MatLabel, MatTooltip, FormsModule, ReactiveFormsModule, MatFormField, MatInput, MatButton, ScrollTopButtonComponent, SpinnerComponent, DatePipe, CommonDatePipe, SafePipe]
})
export class AdminClientAgreementViewComponent extends AppComponentBase implements OnInit, OnDestroy {
  @ViewChild('custom_agreement_body') customAgreementBody: ElementRef;

  private readonly subscriptions: Subscription[] = [];
  private agreementId: any;
  ipAddr: any = '';
  public agreementDetList: any = [];
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyCountry: string;
  companyLogo: string;
  companyContact: string;
  public error: boolean;
  private agreementLink: string;
  public agreementForm: FormGroup;
  private pcSignatureDate: any = new Date();
  private version: string;
  themeData: string;
  private encryptedId: string;
  public agreementValidation: boolean;
  public show: boolean;
  public isdefault: boolean;

  private listeners = [];
  clientId: any;

  public isEmployee = false;

  constructor(inject: Injector, private agreementService: AgreementService, public InvoiceService: InvoiceServiceService,
    private cdRef: ChangeDetectorRef, private elementRef: ElementRef, private renderer: Renderer2
  ) {
    super(inject);
    this.configService.updateIsUserLogged.next(false);

    const changeListener = this.renderer.listen(
      this.elementRef.nativeElement,
      'change',
      (event) => {
        this.changePayment();
      });

    const keyupListener = this.renderer.listen(
      this.elementRef.nativeElement,
      'keyup',
      (event) => {
        this.googleAddress();
      });

    const clickListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      // avoided unwanter rendering click function
      if (event.target.className.includes('custom-sign-capture')) {
        this.customizableSign(event);
      }

    });

    this.listeners.push(changeListener);
    this.listeners.push(keyupListener);
    this.listeners.push(clickListener);

  }

  ngOnInit() {
    this.setWindowTitle('Agreement');
    this.getIPAddress();
    this.agreementValidation = false;
    this.version = COMMON.VERSION;
    this.themeData = this.configService.userTheme ? this.configService.userTheme : 'red-light';
    this.error = false;
    this.agreementId = this.activatedRoute.snapshot.paramMap.get('id');
    this.agreementLink = location.origin + '/agreement/' + this.agreementId;
    this.getAgreementForm();
    this.getAgreementDetails(this.agreementId);
    this.encryptedId = this.agreementId;
  }

  ngOnDestroy() {
    this.listeners.forEach(listener => listener());
  }

  getAgreementDetails(agreementId) {
    const getAgreementDetailList: Subscription = this.agreementService.getAgreementDetailsById(agreementId).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.agreementId = Number(res.data.dec);
        this.isdefault = !(res.data.agreementInfo && !res.data.templateBody);

        // update agreement type
        this.isEmployee = !!res.data.agreementInfo.userId;

        this.agreementForm.patchValue({
          companyRepresentativeName: res.data.agreementInfo.companyRepresentativeName ? res.data.agreementInfo.companyRepresentativeName : '',
          companyRepresentativeSignature: res.data.agreementInfo.companyRepresentativeSignature ? res.data.agreementInfo.companyRepresentativeSignature : '',
          companyRepresentativeDesignation: res.data.agreementInfo.companyRepresentativeDesignation ? res.data.agreementInfo.companyRepresentativeDesignation : '',
          companyRepresentativeSignatureDate: res.data.agreementInfo.companyRepresentativeSignatureDate
            ? res.data.agreementInfo.companyRepresentativeSignatureDate : new Date(),
          pcName: res.data.agreementInfo.pcName ? res.data.agreementInfo.pcName : '',
          varName: res.data.agreementInfo.varName ? res.data.agreementInfo.varName : '',
          pcSignature: res.data.agreementInfo.pcSignature ? res.data.agreementInfo.pcSignature : '',
          pcSignatureDate: res.data.agreementInfo.pcSignatureDate
            ? res.data.agreementInfo.pcSignatureDate : new Date(),
          pcDesignation: res.data.agreementInfo.pcDesignation ? res.data.agreementInfo.pcDesignation : ''
        });
        this.companyName = res.data.companyDetails.companyName;
        this.companyAddress = res.data.companyDetails.companyAddress;
        this.companyCity = res.data.companyDetails.companyCity;
        this.companyCountry = res.data.companyDetails.companyCountry;
        this.companyLogo = res.data.companyDetails.companyLogo;
        this.agreementDetList = res.data;
        this.companyContact = res.data.agreementInfo.representativeMobile;
        this.agreementDetList['subject'] = res.data.subject;

        if (res.data.agreementInfo) {
          this.agreementForm.patchValue({
            template1: res.data.customTemplate.agreementBody,
            template2: res.data.customTemplate.loaBody,
          });
        } else {
          this.agreementForm.patchValue({
            template1: res.data.templateBody,
          });
        }

        if (res.data.customTemplate.loaBody || res.data.subject === 'Default NDA Agreement Template'
          || res.data.subject === 'Default Employee NDA Agreement' || this.agreementDetList.customAgreementTemplateType === '5') {
          this.agreementForm.controls['pcDesignation'].clearValidators();
          this.agreementForm.controls['pcDesignation'].updateValueAndValidity();
        }

        // Remove all validation for customizable template
        if (this.agreementDetList.customAgreementTemplateType === '5') {

          this.agreementForm.controls['pcSignature'].clearValidators();
          this.agreementForm.controls['pcSignature'].updateValueAndValidity();

        }

        if (res.data.agreementInfo.pcSignature !== null) {
          $('input[name=payment_type]').prop('disabled', true);
        }

        const userId = [];
        userId.push({ id: res.data.agreementInfo.clientId });

      } else {
        this.error = true;
      }
    });
    this.subscriptions.push(getAgreementDetailList);
  }

  clickSign() {
    let existSign;
    if (this.agreementForm.value.pcSignature) {
      existSign = this.agreementForm.value.pcSignature;
    } else {
      existSign = '';
    }
    const dialogRef = this.dialog.open(SignatureComponent, {
      width: '500',
      autoFocus: false,
      disableClose: true,
      panelClass: [
        'animated',
        'slideInDown',
      ],
      data: { title: 'Capture Signature', existSign: existSign, sign: 'pcSignature' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.cdRef.detectChanges();
      if (result.sign === 'pcSignature') {
        this.agreementForm.patchValue({
          pcSignature: result.signature
        });
      }
    });
  }

  saveCustomerSign() {

    if (this.agreementForm.invalid) {
      if (this.agreementForm.controls['pcDesignation'].invalid) {
        this.toastr.error('Please enter customer designation');
        this.agreementValidation = true;
      } else if (this.agreementForm.controls['pcSignature'].invalid) {
        this.toastr.error('Please sign the agreement before submitting');
        this.agreementValidation = true;
      }
      return;
    } else if (this.agreementDetList.customAgreementTemplateType === '1' ||
      this.agreementDetList.customAgreementTemplateType === '2') {
      if (!$('input[name=payment_type][value=1]').is(':checked') && !$('input[name=payment_type][value=2]').is(':checked') && !$('input[name=payment_type][value=3]').is(':checked') && !$('input[name=payment_type][value=4]').is(':checked') && !$('input[name=payment_type][value=5]').is(':checked') && !$('input[name=payment_type][value=6]').is(':checked')) {
        this.toastr.error('Please select the payment type');
        this.agreementValidation = true;
        return;
      } else if ($('input[name=payment_type][value=1]').is(':checked') || $('input[name=payment_type][value=2]').is(':checked')) {
        if ($('input[name=payment_bank_name]').val() === '' || $('input[name=payment_account_name]').val() === '' || $('input[name=payment_routing_number]').val() === '' || $('input[name=payment_account_number]').val() === '') {
          this.toastr.error('Please fill the bank details');
          this.agreementValidation = true;
          return;
        } else if ($('input[name=payment_billing_address]').val() === '') {
          this.toastr.error('Please fill the billing address');
          this.agreementValidation = true;
          return;
        }
      } else if ($('input[name=payment_type][value=3]').is(':checked') || $('input[name=payment_type][value=4]').is(':checked') || $('input[name=payment_type][value=5]').is(':checked') || $('input[name=payment_type][value=6]').is(':checked')) {
        if ($('input[name=payment_expiration_date]').val() === '' || $('input[name=payment_cvc_number]').val() === '' || $('input[name=payment_account_number]').val() === '') {
          this. toastr.error('Please fill the card details');
          this.agreementValidation = true;
          return;
        } else if ($('input[name=payment_billing_address]').val() === '') {
          this.toastr.error('Please fill the billing address');
          this.agreementValidation = true;
          return;
        } else {
          this.agreementValidation = false;
        }
      }
    }

    // Phonr update
    const phoneListArray = document.getElementsByName(`phone_list[]`);
    const PhoneList = [];
    let isPhoneUpdate = false;

    for (let i = 0; i < phoneListArray.length / 2; i++) {
      const inp = phoneListArray[i];
      const phone = (<HTMLInputElement>inp).value;

      if (phone) {
        isPhoneUpdate = true;
      }

      PhoneList.push(phone);
    }
    const phoneList = PhoneList.join(',');

    // customizableValue signature update
    let customizableValue = JSON.parse(this.agreementDetList.agreementInfo.customizableValue);

    // custom-signature
    let singleLine = document.getElementsByName('custom_signature_value');
    let singleLineArray = [];

    for (let i = 0; i < (singleLine.length / 2); i++) { // dividing by 2 to avoid printable body element's tag
      const inp = singleLine[i];
      const lineText = (<HTMLImageElement>inp).src ?
        ((<HTMLImageElement>inp).src.includes(window.location.origin) ? '' : (<HTMLImageElement>inp).src) : '';
      singleLineArray.push(lineText);
    }

    customizableValue['custom_signature'] = singleLineArray;

    // user note
    singleLine = document.getElementsByName('user_note');
    singleLineArray = [];

    for (let i = 0; i < singleLine.length / 2; i++) {
      const inp = singleLine[i];
      const lineText = (<HTMLInputElement>inp).value;
      singleLineArray.push(lineText);
    }

    customizableValue['user_note'] = singleLineArray;

    // single_line_date
    singleLine = document.getElementsByName('single_line_date');
    singleLineArray = [];

    for (let i = 0; i < singleLine.length / 2; i++) {
      const inp = singleLine[i];
      const lineText = (<HTMLInputElement>inp).value;
      singleLineArray.push(lineText);
    }

    customizableValue['single_line_date'] = singleLineArray;
    customizableValue = { ...customizableValue, ...this.updateInnerHtml() };

    this.agreementDetList.agreementInfo.customizableValue = customizableValue;

    const body = {
      'agreementLink': this.agreementLink,
      'agreementId': Number(this.agreementId),
      'paymentBankName': $('input[name=payment_bank_name]').val(),
      'paymentType': $('input[name=payment_type]:checked').val(),
      'paymentAccountName': $('input[name=payment_account_name]').val(),
      'paymentRoutingNumber': $('input[name=payment_routing_number]').val(),
      'paymentAccountNumber': $('input[name=payment_account_number]').val(),
      'paymentExpirationDate': $('input[name=payment_expiration_date]').val(),
      'paymentCvcNumber': $('input[name=payment_cvc_number]').val(),
      'paymentBillingAddress': $('input[name=payment_billing_address]').val(),
      'pcSignature': this.agreementDetList.customAgreementTemplateType !== '5' ?
        this.agreementForm.value.pcSignature : '-',
      'pcDesignation': this.agreementForm.value.pcDesignation,
      'phoneList': isPhoneUpdate ? phoneList : this.agreementDetList.agreementInfo.phoneList,
      customizableValue: this.agreementDetList.agreementInfo.customizableValue,
      ipAddress: this.ipAddr ? this.ipAddr : ''
    };
    this.agreementService.saveViewAgreement(body).subscribe((res: any) => {
      if (res.info.code === EStatusCode.OK) {
        this.getAgreementDetails(this.encryptedId);
        this.toastr.success('Thank you for executing the agreement. Agreement Updated Successfully');
      } else {
        this.toastr.error('Agreement Not Updated');
      }
    });
  }

  changePayment() {

    if ($('input[name=payment_type][value=1],' +
      'input[name=payment_type][value=2]').is(':checked')) {

      $('input[name=payment_bank_name], input[name=payment_account_name], ' +
        'input[name=payment_routing_number], input[name=payment_account_number]').
        removeAttr('disabled').attr('required', 'required');

      $('input[name=payment_expiration_date], ' +
        'input[name=payment_cvc_number]').attr('disabled', 'disabled').val('');

    } else if ($('input[name=payment_type][value=3],' +
      'input[name=payment_type][value=4],input[name=payment_type][value=5],' +
      'input[name=payment_type][value=6]').is(':checked')) {

      $('input[name=payment_expiration_date], input[name=payment_cvc_number], ' +
        'input[name=payment_account_number]').removeAttr('disabled').attr('required', 'required');

      $('input[name=payment_bank_name], input[name=payment_account_name],' +
        ' input[name=payment_routing_number]').attr('disabled', 'disabled').val('');
    }

    $('input[name=payment_billing_address]').removeAttr('disabled');
    $('input[name=payment_type]').attr('required', 'required');
  }
  getAgreementForm() {
    this.agreementForm = this.fb.group({
      template1: new FormControl('', [Validators.required]),
      template2: new FormControl(''),
      companyRepresentativeSignature: new FormControl(''),
      companyRepresentativeSignatureDate: new FormControl(''),
      companyRepresentativeName: new FormControl(''),
      companyRepresentativeDesignation: new FormControl(''),
      pcSignature: new FormControl('', [Validators.required]),
      pcSignatureDate: new FormControl(this.pcSignatureDate, [Validators.required]),
      pcDesignation: new FormControl('', [Validators.required]),
      agreementCompleteStatus: new FormControl(''),
      varName: new FormControl(''),
      pcName: new FormControl(''),
    });
  }
  googleAddress() {
    const autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById('payment_billing_address'));
    if (autocomplete) {
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace();
        document.getElementById('payment_billing_address').innerHTML = place.formatted_address;
      });
    }
  }
  printDetail() {
    const filename = 'Agreement_' + this.agreementId;
    this.show = true;
    let innerContents = document.getElementById('print').innerHTML;
    this.show = false;
    if (innerContents.indexOf('name="payment_bank_name"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_bank_name"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_account_name"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_account_name"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_routing_number"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_routing_number"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_account_number"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_account_number"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_expiration_date"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_expiration_date"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_cvc_number"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_cvc_number"[^>]+>', 'ig'), '-');
    }
    if (innerContents.indexOf('name="payment_billing_address"') !== -1) {
      innerContents = innerContents.replace(new RegExp('<input type="text" name="payment_billing_address"[^>]+>', 'ig'), '-');
    }

    // Replace phone number list
    if (innerContents.indexOf('name="phone_list[]"') !== -1) {
      const phoneList = this.agreementDetList.agreementInfo.phoneList ? this.agreementDetList.agreementInfo.phoneList.split(',') : [];
      if (phoneList.length) {
        for (let index = 0; index < phoneList.length; index++) {
          if (phoneList[index]) {
            innerContents = innerContents.replace(new RegExp('<input type="text" name="phone_list[[]]"[^>]+>', 'i'), phoneList[index]);
          } else {
            innerContents = innerContents.replace(new RegExp('<input type="text" name="phone_list[[]]"[^>]+>', 'i'), '-');
          }
        }
      }
      innerContents = innerContents.replace(new RegExp('<input type="text" name="phone_list[[]]"[^>]+>', 'ig'), '-');
    }

    const popupWinindow = window.open('', '_blank', 'width=1000,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><title>' + filename + '</title><style>div.page { page-break-after: always }.template1{padding: 30px;padding-top: 0px;}.company-info{width: 25%;display: inline-block;}' +
      '.subject-info{width: 50%;display: inline-block;text-align: center;vertical-align: top; margin-top: 10px;}.company-logo{width: 25%; display: inline-block;text-align: center;vertical-align: top;margin-top: 15px;}' +
      '.agreement-pcname {border:0; border-bottom: 1px solid #CCC;color: #000;}.agreement-pcsign{border:0; border-bottom: 1px solid #CCC;}' +
      '.admin-sign{float:right; width:30%; padding-top: 12%;padding-bottom: 2%; color: #000;}.admin-status{float:left;width:70%;padding-top: 3%; padding-bottom: 2%;}' +
      '.agreement-detail{width:100%;}.signature{float:left;width:70%; padding-top: 0%;padding-bottom: 0%;}.signature-date{float:left; width:30%; padding-top: 8%;padding-bottom: 0%; font-weight: bold;color: #000;}' +
      '.paddingSpan{padding: 15px}.authorization{\n' +
      '  width: 100%;\n' +
      '  padding:0 15px;\n' +
      '}.agree-default{\n' +
      '    display: block;\n' +
      '}.agreement-template td.default-agree-img {\n' +
      '  text-align: right;\n' +
      '}.agreement-template li {\n' +
      '  margin-bottom: 15px;\n' +
      '}.agreement-template .spn-custom{display: inline-block}' +
      '@media print html,body {height: 99%;} {tr{ page-break-inside: avoid;page-break-after: always} tfoot{display: table-footer-group;   } button{display: none;} body{margin: 0;}}' +
      '.page-footer {left: 0;bottom: 0;border-top: 1px solid #cfdbe2;padding: 10px;z-index: 109;font-weight: 400;}' +
      '.footer{position: fixed;bottom: 0;width: 100%;height: 90px;margin-bottom: 10px}.page{margin: 0 15px;font-size: 14px;font-family: "Open Sans", sans-serif;line-height: 1.42857143;}.bottomFooter{text-align: left; width: 33.33333333%; float: left;}.leftFooter{margin: 0 0 10px;}.rightFooter{text-align: right;width: 33.33333333%;float: right;}.centerFooter{width: 33.33333333%;float: left;}hr{width: 100%;}' +
      '.pc-name { margin-top: 85px;}.logo{  max-height: 85px;max-width: 100px;}' +
      '</style><script>function myFunction() {window.close()}</script></head><body onafterprint="myFunction()" onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

  // customizable field signature
  customizableSign(event) {

    let existSign;

    if (event.target.innerHTML) {
      existSign = event.target.innerHTML.replace(new RegExp('(<img.*?src=")|(" height="80">)', 'g'), '');
    } else {
      existSign = '';
    }

    const dialogRef = this.dialog.open(SignatureComponent, {
      width: '500',
      autoFocus: false,
      disableClose: true,
      panelClass: ['animated', 'slideInDown'],
      data: { title: 'Capture Signature', existSign: existSign, sign: 'pcSignature' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.sign === 'pcSignature') {

        if (event.target.innerHTML) {
          event.target.innerHTML = event.target.innerHTML.replace(event.target.innerHTML,
            `<img name="custom_signature_value" src="${result.signature}" height="80">`);
        } else {
          event.target.innerHTML = `<img name="custom_signature_value" src="${result.signature}" height="80">`;
        }
      }
    });
  }

  getIPAddress() {
    this.agreementService.getIPAddress().subscribe((res: any) => {
      this.ipAddr = res ? res.origin : '';
      console.log(this.ipAddr);
    });
  }

  private updateInnerHtml(): any {
    const element = this.customAgreementBody.nativeElement;
    const customizableValue = {};

    const inputs = element.querySelectorAll('input');
    inputs.forEach(input => {
      // get the name attribute
      const nameAttribute = input.getAttribute('name');
      let value = '';

      // encrypt the value
      if (this.agreementDetList.customAgreementTemplateType === '5' && (nameAttribute === 'payment_account_number' || nameAttribute === 'payment_cvc_number')) {
        value = this.encryptValue(input.value);
      } else {
        value = input.value;
      }
      if (customizableValue[nameAttribute]) {
        customizableValue[nameAttribute].push(value);
      } else {
        customizableValue[nameAttribute] = [value];
      }
    });

    const textAreas = element.querySelectorAll('textarea');
    textAreas.forEach(textArea => {
      // get the name attribute
      const nameAttribute = textArea.getAttribute('name');
      if (customizableValue[nameAttribute]) {
        customizableValue[nameAttribute].push(textArea.value);
      } else {
        customizableValue[nameAttribute] = [textArea.value];
      }
    });

    return customizableValue;
  }
}
