<div class="pbx-consent-page fullscreen-page">
    <div class="consent-screen-wrapper">
      <mat-toolbar class="theme-bg-color" [ngClass]="themeData">
        <div class="logo-container">
          <img src="{{companyDetails?.companyLogo}}" onError="this.src='/assets/img/default_company_logo.png'">
        </div>
      </mat-toolbar>
      <div class="consent-screen-card">
        <div class="consent-screen-content text-center">
          <div class="consent-img m-b-20">
            <img src="assets/img/pbx-consent.png" class="img-responsive">
          </div>
          <div class="consent-text m-t-20">
            <h3 class="consent-title theme-text-color m-t-0">Welcome to Utiliko!</h3>
            <p>We're excited to optimize your communication experience. Before we proceed, may we have your permission to connect with your PBX system? Your consent ensures seamless integration and enhanced functionality. Thank you for choosing Utiliko!</p>
            <div class="actions-buttons-wrapper m-t-20">
              <button mat-raised-button class="btn-submit form-button-40 button-accept" name="allowBtn" (click)="onAllow()">Allow</button>
              <button mat-button  class="mat-mdc-tab mat-ripple button-decline" name="declineBtn" (click)="onDeny()">Deny</button>
            </div>
        </div>
        </div>
      </div>
      <footer class="page-footer view_footer">
        <div class="footer-copyright">
          <p class="m-b-0">Copyright© <a style="color:#5d9cec" href="https://www.utiliko.io" target="_blank">Utiliko Corporation {{currYear}}.</a>All rights reserved.</p>
          <p class="m-b-0">{{version}}</p>
        </div>
      </footer>
    </div>
</div>
