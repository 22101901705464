
<div class="api-docs-back-btn">
    <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
    </section>
    </div>  

<mat-card>
  <div class="api-docs">
<h2 class="content-header-name">
    AccountDetails Model
  </h2>
  <p><code><i>listByUserId</i></code></p>
  <ul>
  <li>
      This API will fetch the account details of particular user by userId from 
      `tbl_account_details` and `tbl_users`.
  </li>
  <li>
      For reference: <a href="{{cureentUrl}}explorer/#!/Accountdetails/listByUserId">
        {{cureentUrl}}explorer/#!/Accountdetails/listByUserId</a>
  </li>
  </ul>
   
  <p><code><i>Update</i></code></p>
<ul>
  <li>
    This API is used to update the account details of the employee in 
    `tbl_account_details` by accountDetailsId.
  </li>
  <li>
      For reference: <a href="{{cureentUrl}}explorer/#!/Accountdetails/Accountdetails_updateAll">
        {{cureentUrl}}explorer/#!/Accountdetails/Accountdetails_updateAll</a>
  </li>
</ul>
 
<h4>Note</h4>
<ul>
  <li>For all the API tenantCompanyKey and id should be unique.</li>
  <li>Check isDeleted = 0 for all the APIs.</li>
  <li>For every I/O operations (add, delete, update API), activity will 
    be added in `tbl_activities`.</li>
  <li>
        require('crypto') - 'aes-128-cbc' - Algorithm is used for encryption and 
        decryption purposes.
  </li>
  <li>For all the list API’s, list will fetched with pagination of limit 0, 15. 
      We can change the limit manually in filter for testing and from 
      UI limit will be 15, 50 and 100.
  </li>
</ul>
</div>
</mat-card>
