
<div class="api-docs-back-btn">
  <section class="content-header">
    <button color="primary" class="mat-button mat-primary" ng-reflect-color="primary"> 
          <a  routerLinkActive="active-link" routerLink="/admin/docs/api-docs">
            <mat-icon>arrow_back_ios</mat-icon> Back to API Docs
        </a>    
    </button>
  </section>
</div>

<mat-card>
<div class="api-docs">
    <h2 class="content-header-name">
    Accounts Model
  </h2> 
  <p><code><i>Dropdown</i></code></p>
  <ul>
  <li>
      This API will fetch all the accounts from `tbl_accounts` by tenantCompanyKey.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Accounts/Accounts_dropdown">
        {{currentUrl}}explorer/#!/Accounts/Accounts_dropdown</a> 
  </li>
  </ul>
  <br />
  <p><code><i>Add</i></code></p>
  <ul>
  <li>
      This API will create a new account in `tbl_accounts` and 
      `tbl_chart_of_accounts` if it does not exists.
  </li>
  <li>
      Once account has been created successfully in `tbl_chart_of_accounts`, that coa_id
      will be updated in `tbl_accounts` and this activity will be added in `tbl_activities`.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Accounts/Accounts_add">
        {{currentUrl}}explorer/#!/Accounts/Accounts_add</a> 
  </li>
  </ul>
  <br />
  <p><code><i>updateAccount</i></code></p>
  <ul>
  <li>
      This API is used to update the existing account details 
      in `tbl_accounts` and `tbl_chart_of_accounts` by accountId.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Accounts/Accounts_updateAccount">
        {{currentUrl}}explorer/#!/Accounts/Accounts_updateAccount</a> 
  </li>
  </ul>
  <br />
  <p><code><i>Delete</i></code></p>
  <ul>
  <li>
      Cannot able to delete the account once it has been created.
  </li>
  </ul>
  <br />
  <p><code><i>getBalance</i></code></p>
  <ul>
  <li>
      This API will fetch balance amount by accountId from `tbl_accounts`.

  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Accounts/Accounts_getBalance">
        {{currentUrl}}explorer/#!/Accounts/Accounts_getBalance</a> 
  </li>
  </ul>
  <br />
  <p><code><i>List</i></code></p>
  <ul>
  <li>
      This API is used to fetch the list of active accounts from `tbl_accounts` by tenantCompanyKey.
  </li>
  <li>
      For reference: <a href="{{currentUrl}}explorer/#!/Accounts/Accounts_list">
        {{currentUrl}}explorer/#!/Accounts/Accounts_list</a> 
  </li>
  </ul>
</div>
  </mat-card>
